import React, { useState, useEffect, useContext, useRef, Fragment } from 'react'
import PropTypes from 'prop-types'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import Box from '@material-ui/core/Box'
import AllProducts from '../../../Component/MyBids/AllProducts'
import { useLocation, useHistory } from 'react-router-dom'
import { handleRedirectInternal } from '../../../Product/common/components'
import { commonPaths } from '../../commonFunctions'
import StripeCustomerContext from '../../../Product/context/stripe/customer/customerContext'
import AuthContext from '../../../Product/context/auth/authContext'
import { useTranslation } from 'react-i18next'
import UserActivity from '../UserActivity'
import buyerContext from '../../../Product/context/buyer/buyerContext'
import axios from 'axios'
import { socket } from '../../../Product/common/socket'
import OrderPickup from '../../../Component/MyBids/OrderPickup'
import InvoiceRecords from '../../../Component/MyBids/InvoiceRecords'
const TabPanel = ({ children, value, index, ...other }) => {
    return (
        <div
            className="customTabsInner dashboard-tabs"
            // component="div"
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && <Box p={3}>{children}</Box>}
        </div>
    )
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
}

function a11yProps(index) {
    return {
        id: `buyer-dashboard-${index}`,
        'aria-controls': `buyer-dashboard-tabpanel-${index}`,
    }
}

const InvoicePanel = (props) => {
    const { t } = useTranslation()
    const { buyer_allproducts } = useContext(buyerContext)

    const authContext = useContext(AuthContext)

    const { user, loadUser } = authContext

    const {
        createStripeCustomer,
        addCustomerProfile,
        responseStatus: responseStatusStripeCustomer,
    } = useContext(StripeCustomerContext)
    const buyerAllProductsRef = useRef(buyer_allproducts)
    useEffect(() => {
        buyerAllProductsRef.current = buyer_allproducts
    })

    const cancelToken = axios.CancelToken.source()
    const [cancelRequest, setCancelRequest] = useState(undefined)
    useEffect(() => {
        if (
            user &&
            !user.card_paymentid &&
            global.storeConfigration?.credit_card_no_need?.value == 1
        ) {
            createStripeCustomer({
                account_id: process.env.REACT_APP_AUCTIONPAYID,
                description: 'TEST CUSTOMER',
                email: user.email,
                name: user.first_name + ' ' + user.last_name,
            })
        }
    }, [user])

    useEffect(() => {
        if (
            responseStatusStripeCustomer &&
            global.storeConfigration?.credit_card_no_need?.value == 1
        ) {
            if (responseStatusStripeCustomer.status === 'success') {
                if (responseStatusStripeCustomer.from === 'create') {
                    //console.log('responseStatusStripeCustomer', responseStatusStripeCustomer)
                    addCustomerProfile({
                        customer_id: responseStatusStripeCustomer.data.id,
                    })
                }
                loadUser()
                // setIsLoading(false)
            }
        }
    }, [responseStatusStripeCustomer])

    return (
        <div>
            <InvoiceRecords
                page="bidwon"
                currency={props.currency}
                sliderTheme={props.sliderTheme}
                shippingauction={props.shippingauction}
                storeTheme={props.storeTheme}
                checkout_but_pos={props.checkout_but_pos}
                redirect_product_view={props.redirect_product_view}
                pickupCode={props.pickupCode}
                schedule={true}
                searchbar={true}
                cancelRequest={cancelRequest}
                cancelToken={cancelToken}
                setCancelRequest={setCancelRequest}
                from="invoice_panel"
                sch_type={1}
            />
        </div>
    )
}

export default InvoicePanel
