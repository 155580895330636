import React, { useState, useEffect } from 'react'
import moment from 'moment-timezone'
import { currencyFormat } from '../../Product/common/components'
import { Link } from '@material-ui/core'
import ReactHTMLParser from 'react-html-parser'

const Terms = (props) => {
    const auctionData = props.auctionData
    return (
        <div className="date-time-container">
            <div className="dates-time-wrapper">
                {ReactHTMLParser(
                    auctionData?.store_comment_two
                        ?.replace(
                            '{{event.start_date}}',
                            moment(auctionData?.date_added)
                                .tz(moment.tz.guess())
                                .format('MMM Do YYYY, h:mm a z'),
                        )
                        ?.replace(
                            '{{event.end_date}}',
                            moment(auctionData.date_closed)
                                .tz(moment.tz.guess())
                                .format('MMM Do YYYY, h:mm a z'),
                        ),
                )}
                {ReactHTMLParser(
                    auctionData?.store_comment_three
                        ?.replace(
                            '{{event.auction_location}}',
                            `${auctionData?.address} ${auctionData?.city} ${auctionData?.state} ${auctionData?.zipcode}`,
                        )
                        ?.replace(
                            '{{event.returns}}',
                            moment(auctionData.date_closed)
                                .add(7, 'days')
                                .tz(moment.tz.guess())
                                .format('MM/DD/YYYY'),
                        ),
                )}
                {ReactHTMLParser(auctionData?.store_comment_four)}
            </div>
            <div className="dates-time-wrapper">
                {ReactHTMLParser(
                    auctionData?.store_comment_five
                        ?.replace(
                            '{{event.auction_location}}',
                            `${auctionData?.address} ${auctionData?.city} ${auctionData?.state} ${auctionData?.zipcode}`,
                        )
                        ?.replace(
                            '{{event.received_date}}',
                            moment(auctionData.date_closed)
                                .add(7, 'days')
                                .tz(moment.tz.guess())
                                .format('MM/DD/YYYY'),
                        )
                        ?.replace(
                            '{{event.picked}}',
                            moment(auctionData.date_closed)
                                .add(7, 'days')
                                .tz(moment.tz.guess())
                                .format('MM/DD/YYYY'),
                        ),
                )}
                {ReactHTMLParser(auctionData?.whole_comment_two)}
            </div>
        </div>
    )
}

export default Terms
