import React, { useState, useEffect, useContext } from 'react'
import Dialog from '@material-ui/core/Dialog'
import CustomDialog from '../../Product/components/organisms/Dialog'
import schedulePickupContext from '../../Product/context/plugin/schedulePickup/schedulePickupContext'
import PrimaryButton from '../../Product/components/atoms/PrimaryButton'
function Pickupfunction(props) {
    const [popup, setPopup] = useState(true)
    const [loading, setLoading] = useState(false)
    const [allLocations, setAllLocations] = useState({})
    const [number_of_days, SetNumberdays] = useState([])
    var options = { weekday: 'long' }
    const {
        getAllTimings,
        updateDefaultTimings,
        addSchedule,
        responseStatus,
        all_timings,
        clearResponse,
    } = useContext(schedulePickupContext)

    useEffect(() => {
        if (props.status == 'open') {
            setPopup(true)
        } else {
            setPopup(false)
        }
    }, [props.status])

    useEffect(() => {
        getAllTimings({
            site_id: global?.storeDetails?.site_id ? global.storeDetails.site_id : '',
            random_id: '',
            id: '',
        })
    }, [])

    useEffect(() => {
        if (all_timings.all_locations.length > 0 && parseInt(props.loc_id) > 0) {
            if (
                all_timings.all_locations.find((val) => parseInt(val.id) == parseInt(props.loc_id))
                    ?.range_key?.length > 0
            ) {
                setAllLocations(
                    all_timings.all_locations.find(
                        (val) => parseInt(val.id) == parseInt(props.loc_id),
                    ).range_key[0],
                )
            } else {
                setLoading(true)
            }
        }
        if (all_timings.all_locations.length == 0) {
            setLoading(true)
        }
    }, [all_timings.all_locations])

    useEffect(() => {
        if (allLocations.business_address1) {
            var end_limit = global.pluginConfiguration?.limit_pickup_days?.client_key
                ? global.pluginConfiguration.limit_pickup_days.client_key
                : 3
            var date_pick = []
            var date_ar = ''

            var count = 0,
                day = '',
                flag = 0
            for (var i = 1; i < 8; i++) {
                date_ar = new Date(props.date_closed)
                date_ar.setDate(date_ar.getDate() + i)
                console.log('vvvvvvvvvvvvvvvvvvvvvvvvvvv', date_ar, i)
                day = date_ar.toLocaleDateString('en-US', options)
                flag = allLocations.general.filter((val) => val.Day == day && val.Status == 'Open')

                if (flag.length > 0 && count < end_limit) {
                    date_pick.push({
                        date_now: date_ar,
                        start_time: flag[0].Day_Times[0].start_time,
                        end_time: flag[0].Day_Times[0].end_time,
                    })
                    count = count + 1
                }
            }
            SetNumberdays(date_pick)
            console.log('kkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkk', date_pick)
        }
    }, [allLocations])

    return (
        <div>
            <CustomDialog
                open={popup}
                title={
                    <address>
                        {allLocations.business_address1}
                        {allLocations.business_address2
                            ? ', ' + allLocations.business_address2
                            : ''}
                        <br />
                        {`${allLocations.business_state}, ${allLocations.business_city}, ${allLocations.business_zip}`}
                        <br />
                        {allLocations.business_country}
                    </address>
                }
                className="modifyPopup"
                onClose={(e) => props.onClose()}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                {allLocations?.business_address1 ? (
                    <>
                        <div className="row">
                            {number_of_days.map((data) => (
                                <div className="col-12 text-center">
                                    <p>
                                        {data.date_now.getMonth() + 1}
                                        {'/' + data.date_now.getDate() + ' '}

                                        {data.date_now.toLocaleDateString('en-US', options) + ' '}
                                        <b> {data.start_time + '-' + data.end_time}</b>
                                    </p>
                                </div>
                            ))}
                        </div>
                        <div className="col-12 text-center">
                            <p>Please note, ALL pickup hours are by appointment only</p>
                            <div className="m-auto" style={{ width: 'fit-content' }}>
                                <PrimaryButton
                                    label={'ok'}
                                    onClick={(e) => props.onClose()}
                                ></PrimaryButton>
                            </div>
                        </div>
                    </>
                ) : loading ? (
                    <div>No pickup details found!</div>
                ) : (
                    <div>Loading...</div>
                )}
            </CustomDialog>
        </div>
    )
}
export default Pickupfunction
