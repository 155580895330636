import React, { useState, useEffect, useContext } from 'react'
import { Link, useHistory } from 'react-router-dom'
import './Login.css'
import { useFormik } from 'formik'
import * as Yup from 'yup'

import { handleRedirectInternal, mapData } from '../../../Product/common/components'
import PrimaryButton from '../../../Product/components/atoms/PrimaryButton'

import AuthContext from '../../../Product/context/auth/authContext'
import { Copyrightinfo, Logo } from '../../../utils'
import Layout from '../Layout'
import LoginFunction from '../../../utils/CommonFunctionality/LoginFunction'

const LoginComponent = () => {
    return (
        <Layout>
            <div className="clearfix row vcaLogin">
                <div className="col-12">
                    <div className="loginFormBox">
                        <LoginFunction
                            title="Login"
                            after_login_redirect_path={
                                localStorage.getItem('auction_id')
                                    ? `search/product-buyer-auction/${localStorage.getItem(
                                          'auction_id',
                                      )}`
                                    : ''
                            }
                            subTitle="Continue logging to your account"
                        />
                    </div>
                </div>
            </div>
        </Layout>
    )
}

export default LoginComponent
