import React, { useContext, useEffect, useState, useRef } from 'react'
import pluginContext from '../../../Product/context/plugin/pluginContext'
import authContext from '../../../Product/context/auth/authContext'
import { useHistory } from 'react-router-dom'
import axios from 'axios'
import { Paper, List, ListItem, ListItemText } from '@material-ui/core'

const CustomAlgoliaSearch = ({ id, searchbar }) => {
    const [options, setOptions] = useState([])
    const [cancelRequest, setCancelRequest] = useState(undefined)
    const [isDropdownVisible, setIsDropdownVisible] = useState(false)
    const cancelToken = axios.CancelToken.source()
    const { search_products, searchProductAlgolia } = useContext(pluginContext)
    const { user } = useContext(authContext)
    const history = useHistory()
    const dropdownRef = useRef(null)

    const setProducts = () => {
        if (search_products?.hits) {
            let temp = search_products.hits.slice(0, 5).map((val) => ({
                title: val.title,
                url: `/search?q=${val.title}`,
            }))
            setOptions(temp)
        }
    }

    const searchProducts = () => {
        const body = {
            user_id: user?.id,
            site_id: global?.storeDetails?.site_id,
            searchbar,
        }
        if (cancelRequest) {
            cancelRequest.cancel()
        }
        setCancelRequest(cancelToken)
        searchProductAlgolia(body, cancelToken)
    }

    const handleOptionClick = (url) => {
        history.push(url)
        setIsDropdownVisible(false)
    }

    const handleClickOutside = (event) => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
            setIsDropdownVisible(false)
        }
    }

    useEffect(() => {
        document.addEventListener('click', handleClickOutside)
        return () => {
            document.removeEventListener('click', handleClickOutside)
        }
    }, [])

    useEffect(() => {
        if (searchbar.trim()) {
            setIsDropdownVisible(true)
            searchProducts()
        } else {
            setOptions([])
            setIsDropdownVisible(false)
        }
    }, [searchbar])

    useEffect(() => {
        setProducts()
    }, [search_products])

    return (
        isDropdownVisible &&
        options.length > 0 && (
            <Paper className="dropdownContainer" ref={dropdownRef}>
                <List>
                    {options.map((option, index) => (
                        <ListItem
                            key={index}
                            className="listItem"
                            onClick={() => handleOptionClick(option.url)}
                        >
                            <ListItemText primary={option.title} />
                        </ListItem>
                    ))}
                </List>
            </Paper>
        )
    )
}

export default CustomAlgoliaSearch
