/*eslint-disable*/
import React, { useContext, useState, useEffect, useRef } from 'react'
import { useHistory } from 'react-router-dom'
import { currencyFormat, handleRedirectInternal } from '../../../../Product/common/components'
import ProductContext from '../../../../Product/context/product/productContext'
import AuthContext from '../../../../Product/context/auth/authContext'
import CartContext from '../../../../Product/context/cart/cartContext'
import { Pagination } from '@material-ui/lab'
import MultisellerSlider from '../../../../Component/SlidePanel/MultisellerSlider'
import NoRecordsFound from '../../../../Product/components/atoms/NoRecordsFound'
import { imageError } from '../../../commonFunctions'
import Loader from '../../../../assets/loader'
import CustomSelect from '../../../../Product/components/atoms/Inputs/CustomSelect'
import PrimaryButton from '../../../../Product/components/atoms/PrimaryButton'
import AddToCart from '../../Cart/AddToCart'
import GridProduct from '../../../../Component/ProductCard/gridProduct'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import 'react-lazy-load-image-component/src/effects/blur.css'
import { Button, Tooltip } from '@material-ui/core'

const PosStock = (props) => {
    const productContext = useContext(ProductContext)
    const { seller_allproducts, getAllSellerProducts, getAllUnpaidUsers, all_unpaid_users } =
        productContext

    const authContext = useContext(AuthContext)
    const { isAuthenticated } = authContext
    const [selectedProduct, setSelectedProduct] = useState(null)
    const [productdetails, setProductDetails] = useState([])
    const [loading, setLoading] = useState(true)
    const history = useHistory()
    const [searchValue, setSearchValue] = useState('')
    const [buynowId, setBuynowId] = useState([])
    const [unpaidUsers, setUnpaidUsers] = useState([])
    const [customer, setCustomer] = useState({})
    const [search, setSearch] = useState({
        search: '',
        status: 'sold',
        limit: 12,
        page: 1,
        paid: 0,
        buyer_id: '',
    })

    const onHandlePage = (event, value) => {
        setSearch({ ...search, page: value })
    }

    const handleCustomerChange = (e) => {
        setBuynowId([])
        setCustomer(unpaidUsers.find((val) => val.id == e.target.value && val))
    }

    const handleClose = () => setSelectedProduct(null)

    const handleSearch = (event) => {
        event.preventDefault()
        setSearch({
            ...search,
            search: searchValue,
        })
    }

    useEffect(() => {
        if (isAuthenticated && search.buyer_id) {
            setLoading(true)
            getAllSellerProducts(search)
        }
    }, [search.search, search.page, search.buyer_id, isAuthenticated])
    useEffect(() => {
        setLoading(false)
        if (seller_allproducts.result.length > 0) {
            seller_allproducts.result.map((val) => {
                val.checked = false
            })
            setProductDetails(seller_allproducts.result)
        } else {
            setProductDetails([])
        }
    }, [seller_allproducts])

    const handleClick = (id) => {
        var arr = buynowId
        var temp = []
        if (arr.includes(id)) {
            temp = arr.filter((val) => val != id)
        } else {
            temp = [...arr, id]
        }
        setBuynowId(temp)
    }
    useEffect(() => {
        getAllUnpaidUsers()
    }, [])
    useEffect(() => {
        setUnpaidUsers(all_unpaid_users)
    }, [all_unpaid_users])
    useEffect(() => {
        if (customer.id) {
            setSearch({ ...search, buyer_id: customer.id })
        }
    }, [customer])
    const redirectCheckout = () => {
        handleRedirectInternal(history, `cart?pos=1`)
    }
    return (
        <div className="mt-3">
            <CustomSelect
                label="Customer Email *"
                id="customer"
                value={customer.id}
                name="customer"
                onChange={handleCustomerChange}
                placeholder="Select Customer Email"
            >
                <option value="">Select Customer Email</option>
                {unpaidUsers.map((val, key) => (
                    <option key={key} value={val.id}>
                        {val.email}
                    </option>
                ))}
            </CustomSelect>

            <div className="posMain">
                <div className="posMainLt">
                    <form className="nav-standard-top-area-search">
                        <div className="nav-standard-top-area-search-inner">
                            <input
                                type="text"
                                onChange={(e) => setSearchValue(e.target.value)}
                                name="searchValue"
                                value={searchValue}
                                className="nav-standard-top-area-search-input open-sans"
                                placeholder="Lot ID/Title"
                            />
                            <button
                                onClick={handleSearch}
                                className="lin lin-magnifier subLotSearch nav-standard-top-area-search-sumbit"
                                type="submit"
                            >
                                <span className="material-icons">search</span>
                            </button>
                        </div>
                    </form>
                    {loading ? (
                        <div className="text-center">
                            <Loader />
                        </div>
                    ) : productdetails.length ? (
                        <>
                            <div className="table-responsive">
                                <table className="table table-hover">
                                    <thead>
                                        <tr>
                                            <th className="numCell">Id #</th>
                                            <th>Image</th>
                                            <th className="pdtNameCol">Product Name</th>
                                            <th>Price</th>
                                            <th className="actnBtnCol">Action</th>
                                        </tr>
                                    </thead>
                                    {productdetails.map((item, index) => {
                                        return (
                                            <>
                                                <tbody>
                                                    <tr>
                                                        <td>{item.id}</td>
                                                        <td>
                                                            <img
                                                                className="pro-img"
                                                                src={`${global.images_url}product/${item.avatar}`}
                                                                alt={item.title}
                                                                width="65"
                                                                height="65"
                                                                onError={(e) =>
                                                                    (e.target.src = imageError())
                                                                }
                                                            />
                                                        </td>
                                                        <td>{item.title}</td>

                                                        <td>
                                                            <span className="currencyAmt">
                                                                {currencyFormat(item.wprice)}
                                                            </span>
                                                        </td>
                                                        <td className="text-center">
                                                            {buynowId.find(
                                                                (val) => val.id == item.id,
                                                            ) ? (
                                                                <Tooltip title="Remove from cart">
                                                                    <Button
                                                                        className="addToCartBtn inCart"
                                                                        onClick={() =>
                                                                            handleClick(item)
                                                                        }
                                                                    >
                                                                        <span className="material-icons">
                                                                            remove_shopping_cart
                                                                        </span>
                                                                    </Button>
                                                                </Tooltip>
                                                            ) : (
                                                                <Tooltip title="Add to cart">
                                                                    <Button
                                                                        className="addToCartBtn "
                                                                        onClick={() =>
                                                                            handleClick(item)
                                                                        }
                                                                    >
                                                                        <span className="material-icons">
                                                                            add_shopping_cart
                                                                        </span>
                                                                    </Button>
                                                                </Tooltip>
                                                            )}
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </>
                                        )
                                    })}
                                </table>
                            </div>
                            <div className="tableInfo">
                                <div className="">
                                    {'Showing'} {search.limit * (search.page - 1) + 1} -{' '}
                                    {search.limit * search.page > seller_allproducts.length
                                        ? seller_allproducts.length
                                        : search.limit * search.page}{' '}
                                    of {seller_allproducts.length}
                                </div>
                                <Pagination
                                    count={Math.ceil(seller_allproducts.length / search.limit)}
                                    page={search.page}
                                    onChange={onHandlePage}
                                />
                            </div>
                            <MultisellerSlider
                                selectedLot={selectedProduct}
                                handleClose={handleClose}
                            />
                        </>
                    ) : (
                        <>
                            <NoRecordsFound />
                        </>
                    )}
                </div>
                <div className="posMainRt">
                    {buynowId.length ? (
                        <>
                            {' '}
                            <h3>
                                Ready to checkout({buynowId.length}{' '}
                                {`${buynowId.length > 1 ? 'items' : 'item'}`})
                            </h3>
                            <div className="posItems">
                                {buynowId.map((data, index) => (
                                    <div className="posProduct">
                                        <LazyLoadImage
                                            src={`${
                                                data.content_head1 === '0' || data.store_id === 0
                                                    ? process.env.REACT_APP_BASE_URL +
                                                      'uploads/product/'
                                                    : process.env.REACT_APP_PRODUCT_IMAGE_URL
                                            }${data.avatar}`}
                                            effect="blur"
                                            placeholderSrc="/assets/svg/imageLoading.svg"
                                            height="100%"
                                            width="100%"
                                            className="gridImage"
                                            alt={data.title}
                                            onError={(e) => (e.target.src = imageError())}
                                        />
                                        <div>
                                            {' '}
                                            <h6>{data.id}</h6>
                                            <h5>{data.title}</h5>
                                        </div>
                                    </div>
                                ))}
                            </div>
                            <PrimaryButton
                                label="Proceed to checkout"
                                onClick={redirectCheckout}
                                disabled={!buynowId.length}
                            />
                        </>
                    ) : (
                        <div className="noRecords">
                            <img src="/assets/svg/searchIcon.svg" alt="Search icon" />
                            <h6>Please add items to proceed with checkout.</h6>
                        </div>
                    )}
                </div>
            </div>
        </div>
    )
}

export default PosStock
