import React from 'react'
import './invoice.css'
import InvoiceComponent from '../../../utils/CommonFunctionality/Invoice/InvoiceFunction'
import Layout from '../Layout'

const Invoice = (props) => {
    return (
        <Layout props={props}>
            <InvoiceComponent
                props={props}
                downloadInvoice={true}
                showAuctionId={true}
                buyersProtection={true}
                savingMessage={true}
                lpnNumber={true}
                hide_trasaction_detail={1}
                showSavings={true}
            />
        </Layout>
    )
}

export default Invoice
