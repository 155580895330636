import React, { useReducer, useState } from 'react'
import BuyerContext from './buyerContext'
import BuyerReducer from './buyerReducer'
import { apiCall } from '../../common/api'

import {
    GET_VIEW_PRODUCT,
    BID_PRODUCT,
    GET_ALL_BUYER,
    GET_ALL_TRANSACTION,
    GET_BUYERS_LIST,
    GET_BID_HISTORY,
    RESPONSE_STATUS,
    CLEAR_RESPONSE,
    GET_CHAT_HISTORY,
    GET_SHIPPING_RATE,
    GET_MAKEREQUEST_LIST,
    CLEAR_BUYER_STATE,
    GET_MORE_VIEW_PRODUCT,
    SET_IMAGES,
    CLEAR_IMAGES,
    SET_GLOBAL_CHAT_USER,
    GET_ALL_AUCTIONS_DETAILS,
    GET_ALL_WAREHOUSE,
    GET_ALL_BUYER_TEMP,
} from './buyerTypes'

const BuyerState = (props) => {
    const initialState = {
        activeInterval: [],
        buyer_alltransaction: {
            results: [],
            total_pagecnt: 0,
            total_only: 0,
        },
        product_view: {},
        product_bidhistory: {},
        buyer_allproducts: {
            results: [],
            total_pagecnt: 0,
            total_only: 0,
        },
        buyer_allproducts_temp: {
            results: [],
            total_pagecnt: 0,
            total_only: 0,
        },
        responseStatus: null,
        list_buyers: [],
        chat_history: [],
        shipping_rate_calculation: {},
        list_make_request: {},
        product_more_view: [],
        message_images: '',
        globalChatUser: {},
        all_auctions: [],
        all_locations: [],
    }

    const [state, dispatch] = useReducer(BuyerReducer, initialState)
    const [progress, setProgress] = useState(0)

    const setGlobalChatUser = (data) => {
        dispatch({
            type: SET_GLOBAL_CHAT_USER,
            payload: data,
        })
    }
    // Post Product
    const bidConfirm = async (formData) => {
        try {
            formData.auction_io = 1
            const [res] = await Promise.all([apiCall('post', 'mobileconfirmForward', formData)])
            if (res.data.status === 'true') {
                dispatch({
                    type: BID_PRODUCT,
                    payload: 'successfully_completed',
                })
            } else {
                dispatch({
                    type: RESPONSE_STATUS,
                    payload: res.data.failedreason,
                })
            }
        } catch (err) {
            dispatch({
                type: RESPONSE_STATUS,
                payload: 'Something went wrong!',
            })
        }
    }

    const bidReverseConfirm = async (formData) => {
        try {
            formData.auction_io = 1
            const [res] = await Promise.all([apiCall('post', 'mobileconfirmreverse', formData)])
            if (res.data.status === 'true') {
                dispatch({
                    type: BID_PRODUCT,
                    payload: 'successfully_completed',
                })
            } else {
                dispatch({
                    type: RESPONSE_STATUS,
                    payload: res.data.failedreason,
                })
            }
        } catch (err) {
            dispatch({
                type: RESPONSE_STATUS,
                payload: 'Something went wrong!',
            })
        }
    }

    // Bid Confirm
    const bidConfirmAuction = async (formData) => {
        try {
            const [res] = await Promise.all([apiCall('post', 'addbidoffer', formData, '', 'api')])
            if (res.data && res.data.status === 'success') {
                return true
            } else {
                return false
            }
        } catch (err) {
            resp.commonErrorResponse('bid')
        }
    }
    const preAuctionBid = async (formData) => {
        try {
            await Promise.all([apiCall('post', 'biddingAPI', formData, '', 'api_bidding')])
            return true
        } catch (err) {
            resp.commonErrorResponse('prebid')
        }
    }
    // Get view Product
    const getViewProduct = async (formData) => {
        try {
            formData.auction_io = 1
            if (global?.pluginConfiguration?.return_assurance?.enable) {
                formData.return_assurance = 1
            }
            const [res] = await Promise.all([apiCall('post', 'getprodetails', formData)])
            if (res.data.success === 'yes') {
                if (res.data.biddetails.bidtopwinner) {
                    if (
                        !res.data.biddetails.bidtopstatus.includes('!') &&
                        !res.data.biddetails.bidtopstatus.includes('unfortunately,')
                    ) {
                        res.data.results.rprice = res.data.results.rprice || 0
                        let reserveCheck = res.data.biddetails.bidtopstatus.split('. ')
                        if (reserveCheck.length > -1) {
                            var autobid = res.data.biddetails.bidtopstatus
                                .split('. ')[0]
                                .replace(/[^\d.]/g, '')
                            var autobid1 = res.data.biddetails.bidtopstatus
                                .split('. ')[1]
                                .replace(/[^\d.]/g, '')
                            if (parseFloat(autobid) < parseFloat(res.data.results.rprice)) {
                                if (parseFloat(autobid) === parseFloat(autobid1)) {
                                    res.data.biddetails.bidtopstatus = `Your bid of ${
                                        global.storeConfigration?.currency_format?.value || '$'
                                    } 
                                    ${parseFloat(
                                        autobid1,
                                    ).toUSFormat()} on this item placed, but reserve price not yet met.`
                                } else {
                                    res.data.biddetails.bidtopstatus = `Your bid of ${
                                        global.storeConfigration?.currency_format?.value || '$'
                                    }
                                    ${parseFloat(
                                        autobid,
                                    ).toUSFormat()} and auto bid of $${parseFloat(
                                        autobid1,
                                    ).toUSFormat()} on this item placed, but reserve price not yet met.`
                                }
                            } else {
                                if (parseFloat(autobid) === parseFloat(autobid1)) {
                                    res.data.biddetails.bidtopstatus = `You are winning this item at ${
                                        global.storeConfigration?.currency_format?.value || '$'
                                    }
                                    ${parseFloat(autobid1).toUSFormat()}.`
                                } else {
                                    res.data.biddetails.bidtopstatus = `You are winning this item at ${
                                        global.storeConfigration?.currency_format?.value || '$'
                                    }
                                    ${parseFloat(
                                        autobid,
                                    ).toUSFormat()}. Your autoBid of $${parseFloat(
                                        autobid1,
                                    ).toUSFormat()} has been placed successfully.`
                                }
                            }
                        }
                    } else {
                        if (res.data.biddetails.bidtopstatus.includes('losing')) {
                            res.data.biddetails.bidtopstatus = `You've been outbid on this item. Current leading bid is $${parseFloat(
                                res.data.results.wprice,
                            ).toUSFormat()}`
                        } else if (res.data.biddetails.bidtopstatus.includes('unfortunately,')) {
                            res.data.biddetails.bidtopstatus = `unfortunately you lost this item`
                        } else if (res.data.biddetails.bidtopstatus.includes('!')) {
                            res.data.biddetails.bidtopstatus = `Congratulations, you have bought this item for $${parseFloat(
                                res.data.results.wprice,
                            ).toUSFormat()}`
                        }
                    }
                } else {
                    if (res.data.biddetails.bidtopstatus.includes('losing')) {
                        res.data.biddetails.bidtopstatus = `You've been outbid on this item. Current leading bid is $${parseFloat(
                            res.data.results.wprice,
                        ).toUSFormat()}`
                    }
                }
                dispatch({
                    type: GET_VIEW_PRODUCT,
                    payload: res.data,
                })
            } else {
                dispatch({
                    type: RESPONSE_STATUS,
                    payload: res.data.failedreason,
                })
            }
        } catch (err) {
            //console.log('err', err)
            dispatch({
                type: RESPONSE_STATUS,
                payload: 'Something went wrong!',
            })
        }
    }

    const setSocketProduct = (res) => {
        console.log(res, 'ressss')
        if (res.data.success === 'yes') {
            if (res.data.biddetails.bidtopwinner) {
                if (
                    !res.data.biddetails.bidtopstatus.includes('!') &&
                    !res.data.biddetails.bidtopstatus.includes('unfortunately,')
                ) {
                    res.data.results.rprice = res.data.results.rprice || 0
                    let reserveCheck = res.data.biddetails.bidtopstatus.split('. ')
                    if (reserveCheck.length > -1) {
                        var autobid = res.data.biddetails.bidtopstatus
                            .split('. ')[0]
                            .replace(/[^\d.]/g, '')
                        var autobid1 =
                            res.data.biddetails.bidtopstatus
                                ?.split('. ')[1]
                                ?.replace(/[^\d.]/g, '') || 0
                        if (parseFloat(autobid) < parseFloat(res.data.results.rprice)) {
                            if (parseFloat(autobid) === parseFloat(autobid1)) {
                                res.data.biddetails.bidtopstatus = `Your bid of ${
                                    global.storeConfigration?.currency_format?.value || '$'
                                } 
                                    ${parseFloat(
                                        autobid1,
                                    ).toUSFormat()} on this item placed, but reserve price not yet met.`
                            } else {
                                res.data.biddetails.bidtopstatus = `Your bid of ${
                                    global.storeConfigration?.currency_format?.value || '$'
                                }
                                    ${parseFloat(
                                        autobid,
                                    ).toUSFormat()} and auto bid of $${parseFloat(
                                    autobid1,
                                ).toUSFormat()} on this item placed, but reserve price not yet met.`
                            }
                        } else {
                            if (
                                parseFloat(autobid) === parseFloat(autobid1) ||
                                parseFloat(autobid1) === 0
                            ) {
                                res.data.biddetails.bidtopstatus = `You are winning this item at ${
                                    global.storeConfigration?.currency_format?.value || '$'
                                }
                                    ${parseFloat(autobid1).toUSFormat()}.`
                            } else {
                                res.data.biddetails.bidtopstatus = `You are winning this item at ${
                                    global.storeConfigration?.currency_format?.value || '$'
                                }
                                    ${parseFloat(
                                        autobid,
                                    ).toUSFormat()}. Your autoBid of $${parseFloat(
                                    autobid1,
                                ).toUSFormat()} has been placed successfully.`
                            }
                        }
                    }
                } else {
                    if (res.data.biddetails.bidtopstatus.includes('losing')) {
                        res.data.biddetails.bidtopstatus = `You've been outbid on this item. Current leading bid is $${parseFloat(
                            res.data.results.wprice,
                        ).toUSFormat()}`
                    } else if (res.data.biddetails.bidtopstatus.includes('unfortunately,')) {
                        res.data.biddetails.bidtopstatus = `unfortunately you lost this item`
                    } else if (res.data.biddetails.bidtopstatus.includes('!')) {
                        res.data.biddetails.bidtopstatus = `Congratulations, you have bought this item for $${parseFloat(
                            res.data.results.wprice,
                        ).toUSFormat()}`
                    }
                }
            } else {
                if (res.data.biddetails.bidtopstatus.includes('losing')) {
                    res.data.biddetails.bidtopstatus = `You've been outbid on this item. Current leading bid is $${parseFloat(
                        res.data.results.wprice,
                    ).toUSFormat()}`
                }
            }
            dispatch({
                type: GET_VIEW_PRODUCT,
                payload: res.data,
            })
        } else {
            dispatch({
                type: RESPONSE_STATUS,
                payload: res.data.failedreason,
            })
        }
    }
    const getPolicslist = async (formData) => {
        try {
            const [res] = await Promise.all([apiCall('post', 'get_auction_poliscs', formData)])
            if (res.data.success) {
                dispatch({
                    type: GET_MORE_VIEW_PRODUCT,
                    payload: res.data.message,
                })
            }
        } catch (err) {
            dispatch({
                type: RESPONSE_STATUS,
                payload: 'Something went wrong!',
            })
        }
    }
    const deleteMessage = async (formData) => {
        try {
            const [res] = await Promise.all([apiCall('post', 'delete_msg', formData)])
            // response handled in socket
        } catch (err) {
            dispatch({
                type: RESPONSE_STATUS,
                payload: 'Something went wrong!',
            })
        }
    }
    const bidLikeSnag = async (formData) => {
        try {
            formData.auction_io = 1
            const [res] = await Promise.all([
                apiCall('post', 'snagnowbidding', formData, '', 'snag_time'),
            ])
            // if (res.data.status) {
            dispatch({
                type: BID_PRODUCT,
                payload: {
                    from: 'bidlikesnag',
                    ...res.data,
                },
            })
            // } else {
            //     dispatch({
            //         type: RESPONSE_STATUS,
            //         payload: res.data,
            //     })
            // }
        } catch (err) {
            dispatch({
                type: RESPONSE_STATUS,
                payload: 'Something went wrong!',
            })
        }
    }

    const getBidHistoryProduct = async (formData) => {
        try {
            const [res] = await Promise.all([apiCall('post', 'getbiddetails', formData)])
            if (res.data.success === 'yes') {
                dispatch({
                    type: GET_BID_HISTORY,
                    payload: res.data.results,
                })
            } else {
                dispatch({
                    type: RESPONSE_STATUS,
                    payload: res.data.failedreason,
                })
            }
        } catch (err) {
            //console.log('err', err)
            dispatch({
                type: RESPONSE_STATUS,
                payload: 'Something went wrong!',
            })
        }
    }

    const getAllBuyerProducts = async (formData, cancelToken) => {
        try {
            console.log('cancelToken', cancelToken)
            if (
                formData.status == 'watchlist' &&
                global?.pluginConfiguration?.return_assurance?.enable
            ) {
                formData.return_assurance = 1
            }
            if (formData.warehouse_location == '1') {
                formData.warehouse_location = ''
            }
            const [res] = await Promise.all([
                apiCall('post', 'buyerDashboard', formData, '', '', cancelToken),
            ])
            if (res.data.success === 'yes') {
                dispatch({
                    type: formData.store_another == 1 ? GET_ALL_BUYER_TEMP : GET_ALL_BUYER,
                    payload: res.data,
                })
                return res.data
            } else {
                console.log('No Data available.. Need to set the state as Empty ')
                dispatch({
                    type: formData.store_another == 1 ? GET_ALL_BUYER_TEMP : GET_ALL_BUYER,
                    payload: {
                        result: [],
                        length: 0,
                    },
                })
            }
        } catch (err) {
            dispatch({
                type: RESPONSE_STATUS,
                payload: 'Something went wrong!',
            })
        }
    }

    const getAllAuctionProducts = async (formData) => {
        try {
            if (formData.warehouse_location == '1') {
                formData.warehouse_location = ''
            }
            const [res] = await Promise.all([apiCall('post', 'get_all_auction_details', formData)])
            if (res.data.status) {
                dispatch({
                    type: GET_ALL_AUCTIONS_DETAILS,
                    payload: { auctions: res.data.auctions },
                })
            } else {
                dispatch({
                    type: GET_ALL_AUCTIONS_DETAILS,
                    payload: [],
                })
            }
        } catch (err) {
            dispatch({
                type: RESPONSE_STATUS,
                payload: 'Something went wrong!',
            })
        }
    }
    const getAllWarehouseLocations = async (formData) => {
        try {
            const [res] = await Promise.all([
                apiCall('post', 'get_all_warehouse_locations', formData),
            ])
            if (res.data.status) {
                dispatch({
                    type: GET_ALL_WAREHOUSE,
                    payload: { locations: res.data.locations },
                })
            } else {
                dispatch({
                    type: GET_ALL_WAREHOUSE,
                    payload: [],
                })
            }
        } catch (err) {
            dispatch({
                type: RESPONSE_STATUS,
                payload: 'Something went wrong!',
            })
        }
    }

    const updateVideoCount = (payload) => {
        dispatch({
            type: GET_ALL_BUYER,
            payload,
        })
    }
    const getAllTransaction = async (formData) => {
        try {
            const [res] = await Promise.all([apiCall('post', 'buyerTransaction', formData)])
            if (res.data.success === 'yes') {
                dispatch({
                    type: GET_ALL_TRANSACTION,
                    payload: res.data,
                })
            } else {
                dispatch({
                    type: GET_ALL_TRANSACTION,
                    payload: {
                        result: [],
                        length: 0,
                    },
                })
            }
        } catch (err) {
            dispatch({
                type: RESPONSE_STATUS,
                payload: 'Something went wrong!',
            })
        }
    }

    const listAllBuyers = async (formData) => {
        try {
            const [res] = await Promise.all([apiCall('post', 'list_buyer', formData)])
            if (res.data.status === 'yes') {
                dispatch({
                    type: GET_BUYERS_LIST,
                    payload: res.data.data,
                })
            } else {
                dispatch({
                    type: GET_BUYERS_LIST,
                    payload: [],
                })
            }
        } catch (err) {
            dispatch({
                type: RESPONSE_STATUS,
                payload: 'Something went wrong!',
            })
        }
    }
    const updateUserMessageCount = (payload) => {
        dispatch({
            type: GET_BUYERS_LIST,
            payload,
        })
    }
    const uploadImages = async (formData) => {
        try {
            const [res] = await Promise.all([
                apiCall('post', 'upload_images', formData, '', '', '', false, setProgress),
            ])
            if (res.data.success) {
                dispatch({
                    type: SET_IMAGES,
                    payload: res.data.result || '',
                })
            } else {
                dispatch({
                    type: SET_IMAGES,
                    payload: '',
                })
            }
            setProgress(0)
        } catch (err) {
            dispatch({
                type: RESPONSE_STATUS,
                payload: 'Something went wrong!',
            })
        }
    }
    const clearImages = async () => {
        dispatch({
            type: CLEAR_IMAGES,
            payload: '',
        })
    }
    const getChatHistory = async (formData) => {
        try {
            const [res] = await Promise.all([apiCall('post', 'get_chathistory', formData)])
            if (res.data.status === 'yes') {
                dispatch({
                    type: GET_CHAT_HISTORY,
                    payload: res.data.data.reverse(),
                })
            } else {
                dispatch({
                    type: GET_CHAT_HISTORY,
                    payload: [],
                })
            }
        } catch (err) {
            dispatch({
                type: RESPONSE_STATUS,
                payload: 'Something went wrong!',
            })
        }
    }

    const get_ship_station_amount = async (formData) => {
        try {
            const [res] = await Promise.all([
                apiCall('post', 'calculator', formData, {}, 'plugin/shipstation/service/getRate'),
            ])
            dispatch({
                type: GET_SHIPPING_RATE,
                payload: res.data?.data ? res.data.data : 'Please Try Again Later!',
            })
        } catch (err) {
            dispatch({
                type: RESPONSE_STATUS,
                payload: 'Something went wrong!',
            })
        }
    }

    const clear_ship_station_amount = () => {
        dispatch({
            type: GET_SHIPPING_RATE,
            payload: {},
        })
    }

    const check_get_shipping_rate_api = async (formData) => {
        try {
            const [res] = await Promise.all([
                apiCall(
                    'post',
                    'checkoutCalculator',
                    formData,
                    {},
                    'plugin/shipstation/service/getRate',
                ),
            ])
            dispatch({
                type: GET_SHIPPING_RATE,
                payload: res.data?.data ? res.data : 'Please Try Again Later!',
            })
        } catch (err) {
            dispatch({
                type: RESPONSE_STATUS,
                payload: 'Something went wrong!',
            })
        }
    }

    const getAllMakeRequest = async (formData) => {
        try {
            const [res] = await Promise.all([apiCall('post', 'get_make_an_offer', formData)])
            if (res.data.success) {
                dispatch({
                    type: GET_MAKEREQUEST_LIST,
                    payload: { data: res.data.results, totalcount: res.data.totalrow },
                })
            } else {
                dispatch({
                    type: GET_MAKEREQUEST_LIST,
                    payload: {},
                })
            }
        } catch (err) {
            dispatch({
                type: RESPONSE_STATUS,
                payload: 'Something went wrong!',
            })
        }
    }

    const updatemakeofferreq = async (formData) => {
        try {
            const [res] = await Promise.all([apiCall('post', 'make_an_offer_update', formData)])
            if (res.data.success) {
                dispatch({
                    type: RESPONSE_STATUS,
                    payload: res.data.results,
                })
            } else {
                dispatch({
                    type: RESPONSE_STATUS,
                    payload: 'Something went wrong!',
                })
            }
        } catch (err) {
            dispatch({
                type: RESPONSE_STATUS,
                payload: 'Something went wrong!',
            })
        }
    }

    const markasrecived = async (formData) => {
        try {
            const [res] = await Promise.all([
                apiCall('post', 'createTransfer', formData, {}, 'plugin/flutterwave/payment/bank'),
            ])
            dispatch({
                type: RESPONSE_STATUS,
                payload: {
                    ...{ from: 'marke_as_recived', status: res.data.status },
                    ...res.data.data,
                },
            })
        } catch (err) {
            dispatch({
                type: RESPONSE_STATUS,
                payload: 'Something went wrong!',
            })
        }
    }

    const markasassurance = async (formData) => {
        try {
            const [res] = await Promise.all([
                apiCall(
                    'post',
                    'buyer-assurance',
                    formData,
                    {},
                    'plugin/return_assurance/site/assurance',
                ),
            ])
            if (res.data.status == 'success') {
                dispatch({
                    type: RESPONSE_STATUS,
                    payload: {
                        status: 'success',
                        message: 'Updated successfully!',
                    },
                })
            } else {
                dispatch({
                    type: RESPONSE_STATUS,
                    payload: 'Something went wrong!',
                })
            }
        } catch (err) {
            dispatch({
                type: RESPONSE_STATUS,
                payload: 'Something went wrong!',
            })
        }
    }

    const clearResponse = () =>
        dispatch({
            type: CLEAR_RESPONSE,
        })
    const clearBuyerState = () => {
        dispatch({ type: CLEAR_BUYER_STATE })
    }
    return (
        <BuyerContext.Provider
            value={{
                product_view: state.product_view,
                activeInterval: state.activeInterval,
                product_bidhistory: state.product_bidhistory,
                buyer_allproducts: state.buyer_allproducts,
                responseStatus: state.responseStatus,
                buyer_alltransaction: state.buyer_alltransaction,
                list_buyers: state.list_buyers,
                chat_history: state.chat_history,
                shipping_rate_calculation: state.shipping_rate_calculation,
                list_make_request: state.list_make_request,
                product_view_more: state.product_more_view,
                message_images: state.message_images,
                globalChatUser: state.globalChatUser,
                all_auctions: state.all_auctions,
                all_locations: state.all_locations,
                buyer_allproducts_temp: state.buyer_allproducts_temp,
                progress,
                markasassurance,
                setGlobalChatUser,
                updateVideoCount,
                deleteMessage,
                clearResponse,
                getAllBuyerProducts,
                getAllTransaction,
                getViewProduct,
                getPolicslist,
                getBidHistoryProduct,
                bidConfirm,
                bidConfirmAuction,
                listAllBuyers,
                getChatHistory,
                preAuctionBid,
                bidReverseConfirm,
                bidLikeSnag,
                get_ship_station_amount,
                clear_ship_station_amount,
                check_get_shipping_rate_api,
                getAllMakeRequest,
                updatemakeofferreq,
                clearBuyerState,
                markasrecived,
                uploadImages,
                clearImages,
                updateUserMessageCount,
                getAllAuctionProducts,
                setSocketProduct,
                getAllWarehouseLocations,
            }}
        >
            {props.children}
        </BuyerContext.Provider>
    )
}

export default BuyerState
