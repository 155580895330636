export const GET_ALL_TIMINGS = 'GET_ALL_TIMINGS'
export const GET_ALL_LOCATIONS = 'GET_ALL_LOCATIONS'
export const GET_ALL_MESSAGES = 'GET_ALL_MESSAGES'
export const GET_ITEM_COUNT = 'GET_ITEM_COUNT'
export const SET_DEFAULT_TIMINGS = 'SET_DEFAULT_TIMINGS'
export const CLEAR_ALL_MESSAGES = 'CLEAR_ALL_MESSAGES'
export const GET_SCHEDULE_SLOTS = 'GET_SCHEDULE_SLOTS'

export const CLEAR_RESPONSE = 'CLEAR_RESPONSE'
export const RESPONSE_STATUS = 'RESPONSE_STATUS'
