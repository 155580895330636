import React, { useContext, useState, useEffect } from 'react'
import BioCard from './BioCard'
import PrimaryButton from '../../../Product/components/atoms/PrimaryButton'
import { CircularProgress } from '@material-ui/core'
import { useParams, Link } from 'react-router-dom'
import ProductContext from '../../../Product/context/product/productContext'
import MultisellerSlider from '../../../Component/SlidePanel/MultisellerSlider'
import { useTranslation } from 'react-i18next'
import axios from 'axios'
const Gallery = ({ searchbar }) => {
    const { search_allproducts, getAllSearchProducts } = useContext(ProductContext)
    const [loading, setLoading] = useState(false)
    const { type } = useParams()
    const [productParams, setProductParams] = useState({
        sh_limit: 10,
        page: 1,
        orderby: '6',
        searchbar: '',
        from: 'auction_only',
        closed_auction_list: 0,
        art_type: 3,
    })
    const [products, setProducts] = useState({
        data: [],
        total: 0,
    })
    const [selectedProduct, setSelectedProduct] = useState(null)
    const [cancelRequest, setCancelRequest] = useState(undefined)
    const { t } = useTranslation()
    const cancelToken = axios.CancelToken.source()

    const handleClose = () => setSelectedProduct(null)

    const handleClickpopup = async (id) => {
        setSelectedProduct(id)
    }

    const handleClick = () => {
        setProductParams((prev) => ({ ...prev, page: prev.page + 1 }))
    }

    useEffect(() => {
        setProductParams({ ...productParams, searchbar })
    }, [searchbar])

    useEffect(() => {
        // setLoading(true)
        if (cancelRequest) {
            cancelRequest.cancel()
        }
        setCancelRequest(cancelToken)
        getAllSearchProducts(productParams, cancelToken)
    }, [productParams])

    useEffect(() => {
        // setLoading(false)
        setProducts({ data: search_allproducts.results, total: search_allproducts.total_pagecnt })
    }, [search_allproducts])

    return (
        <div>
            {products.data?.length > 0 ? (
                <div className="artstCardGrid">
                    {products.data.map((data, index) => (
                        <BioCard
                            title={data.title}
                            avatar={`${process.env.REACT_APP_BASE_URL}uploads/product/${data.file_name}`}
                            index={index}
                            redirectUrl={`bio/${data.id}`}
                            handleClick={() => handleClickpopup(data.id)}
                        />
                    ))}
                </div>
            ) : (
                <div className="no-artist-o1">{t('no_artist_found')}</div>
            )}
            {products.data?.length && products.total > productParams.page * productParams.limit ? (
                <div className="row">
                    <div className="col-md-3 col-12 mx-auto d-block">
                        <PrimaryButton
                            disabled={loading}
                            label={
                                loading ? (
                                    <>
                                        <CircularProgress color="inherit" />
                                    </>
                                ) : (
                                    <>
                                        <span className="material-icons">arrow_circle_down</span>{' '}
                                        {t('load_more')}
                                    </>
                                )
                            }
                            onClick={handleClick}
                            className="text-center"
                        />
                    </div>
                </div>
            ) : null}
            <MultisellerSlider
                storeTheme="ArtAuction"
                type="right"
                selectedLot={selectedProduct}
                handleClose={handleClose}
                from={''}
            />
        </div>
    )
}

export default Gallery
