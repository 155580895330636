/* eslint-disable */
import React, { useEffect, useState, useContext } from 'react'
import HeaderSearch from './HeaderSearch'
import './Header.css'
import axios from 'axios'
import { Link, NavLink, useHistory } from 'react-router-dom'
import {
    Button,
    Popover,
    ListItem,
    Divider,
    SwipeableDrawer,
    MenuItem,
    Badge,
    IconButton,
} from '@material-ui/core'
import { handleRedirectInternal } from '../../../Product/common/components'
import AuthContext from '../../../Product/context/auth/authContext'
import AlertContext from '../../../Product/context/alert/alertContext'
import ProductContext from '../../../Product/context/product/productContext'
import { useMediaQuery } from 'react-responsive'
import { Logo } from '../../../utils/index'
import CartIcon from '../../../utils/CommonFunctionality/Cart/CartIcon'
import Headernavmenu from '../../../utils/CommonFunctionality/HeaderNavigaton/headernavigation'
import PaymentPendingHeader from '../../../utils/CommonFunctionality/PaymentPendingHeader'
const Header = (props) => {
    const iOS = process.browser && /iPad|iPhone|iPod/.test(navigator.userAgent)
    const contactPhone = global?.storeDetails?.phone
    const facebookLink = global?.storeDetails?.facebook
    const twitterLink = global?.storeDetails?.twitter
    const googleLink = global?.storeDetails?.google
    const instagramLink = global?.storeDetails?.instagram

    const history = useHistory()
    const [anchormenu, setanchormenu] = useState(null)
    const { user, isAuthenticated, logout, userCount } = useContext(AuthContext)
    const productContext = useContext(ProductContext)
    const { search_allauctions, getAllSearchAuctions } = productContext
    const { setAlert } = useContext(AlertContext)

    const [auctionSearch, setAuctionSearch] = useState({
        page: 1,
        sh_limit: '10',
        orderby: 9,
        searchbar: '',
        auc_type: '2',
        is_auction: 1,
        type: '',
        //is_buynow: 1,
    })
    const [totalViewProduct, setTotalViewProduct] = useState(0)
    useEffect(() => {
        getAllSearchAuctions(auctionSearch, 'auctionSearch_header')
    }, [auctionSearch])

    useEffect(() => {
        if (search_allauctions.from === 'auctionSearch_header') {
            if (search_allauctions.results.length > 0) {
                var auction_id = []
                auction_id = search_allauctions.results.filter((val) => val.market_status == 'open')

                if (auction_id.length > 0) {
                    localStorage.setItem('auction_id', auction_id[0].id)
                } else {
                    if (localStorage.getItem('auction_id')) {
                        localStorage.removeItem('auction_id')
                    }
                }
            }
        }
    }, [search_allauctions])

    const isDesktop = useMediaQuery({
        query: '(min-width: 991px)',
    })
    const [state, setState] = useState({
        top: false,
        right: false,
    })

    const [listingAnchor, setListingAnchor] = useState(null)
    const listingOpen = Boolean(listingAnchor)

    useEffect(() => {
        if (window.location.hostname === 'localhost') {
            axios.defaults.headers.common['domain'] = 'auction_preview.ecommerce.auction'
        }
    }, [])

    const handleMenu = (event) => {
        setanchormenu(event.currentTarget)
    }

    const handleClose = (history, path) => {
        setanchormenu(null)
        if (history && path && path !== 'backdropClick') {
            handleRedirectInternal(history, path)
        }
    }

    const handleListingClick = (event) => {
        if (!user.card_profileid) {
            setAlert('Kindly add account id to continue!', 'warning')
            return handleRedirectInternal(history, 'dashboard/profile')
        }
        setListingAnchor(event.currentTarget)
    }

    const handleListingClose = () => {
        setListingAnchor(null)
    }

    const logoutUser = () => {
        logout()
        history.push('/login')
        window.location.reload()
    }

    const toggleDrawer = (anchor, open) => (event) => {
        setState({ ...state, [anchor]: open })
    }

    return (
        <>
            {isDesktop ? (
                /* DESKTOP NAVIGATION */
                <header className="mainHeader noPrint">
                    <div className="customContainer d-flex justify-content-between align-items-center h-100">
                        <div className="headLt d-flex justify-content-between align-items-center">
                            <Logo className="headerLogo" />
                            {global.storeConfigration?.hide_charity_header?.value == '1' ? null : (
                                <HeaderSearch />
                            )}
                        </div>
                        <div className="headRt d-flex justify-content-end align-items-center">
                            <ul className="d-flex justify-content-start align-items-center">
                                <Headernavmenu type="desk" />
                                {global.storeConfigration?.disable_buynow?.value !== '1' ? (
                                    <li>
                                        <NavLink
                                            className="d-flex align-items-center"
                                            activeClassName="active"
                                            exact
                                            to="/gallery"
                                        >
                                            Buy Now
                                        </NavLink>
                                    </li>
                                ) : null}
                                {isAuthenticated ? (
                                    <>
                                        {global.storeConfigration?.hide_charity_header?.value ==
                                        '1' ? null : (
                                            <li>
                                                <NavLink
                                                    className="d-flex align-items-center"
                                                    activeClassName="active"
                                                    exact
                                                    to="/auctions"
                                                >
                                                    <span className="material-icons mr-1">
                                                        local_activity
                                                    </span>{' '}
                                                    Events
                                                </NavLink>
                                            </li>
                                        )}
                                        {facebookLink && (
                                            <li className="facebook">
                                                <a
                                                    href={facebookLink}
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                    title="Facebook"
                                                >
                                                    <i className="fab fa-facebook"></i>
                                                </a>
                                            </li>
                                        )}
                                        {global.storeConfigration?.hide_charity_header?.value ==
                                        '1' ? null : (
                                            <li>
                                                <IconButton
                                                    onClick={() =>
                                                        handleRedirectInternal(
                                                            history,
                                                            'dashboard/favorites',
                                                        )
                                                    }
                                                >
                                                    <Badge
                                                        badgeContent={userCount.watchlistCount}
                                                        color="primary"
                                                    >
                                                        <span class="material-icons-outlined">
                                                            favorite_border
                                                        </span>
                                                    </Badge>
                                                </IconButton>
                                            </li>
                                        )}
                                        {/* <li>
                                            <IconButton
                                                onClick={() =>
                                                    handleRedirectInternal(history, 'cart')
                                                }
                                            >
                                                <Badge badgeContent={4} color="primary">
                                                    <span class="material-icons-outlined">
                                                        shopping_cart
                                                    </span>
                                                </Badge>
                                            </IconButton>
                                        </li> */}
                                        {global.storeConfigration?.disable_buynow?.value !== '1' ? (
                                            <li>
                                                <CartIcon />
                                            </li>
                                        ) : null}
                                        <li>
                                            <IconButton
                                                aria-controls="simple-menu"
                                                aria-haspopup="true"
                                                onClick={handleMenu}
                                            >
                                                <span class="material-icons-outlined">
                                                    account_circle
                                                </span>
                                            </IconButton>

                                            <Popover
                                                id={'simple-menu'}
                                                open={Boolean(anchormenu)}
                                                anchorEl={anchormenu}
                                                onClose={handleClose}
                                                anchorOrigin={{
                                                    vertical: 'bottom',
                                                    horizontal: 'right',
                                                }}
                                                transformOrigin={{
                                                    vertical: 'top',
                                                    horizontal: 'right',
                                                }}
                                            >
                                                <ul className="headerDropMenu">
                                                    {global.storeConfigration?.hide_charity_header
                                                        ?.value == '1' ? null : (
                                                        <ListItem
                                                            button
                                                            onClick={() =>
                                                                handleClose(
                                                                    history,
                                                                    `${
                                                                        user && user.role == 1
                                                                            ? 'dashboard/overview'
                                                                            : 'dashboard/mybids'
                                                                    }`,
                                                                )
                                                            }
                                                        >
                                                            Dashboard
                                                        </ListItem>
                                                    )}
                                                    {/* <ListItem
                                                        button
                                                        onClick={() => handleClose(history, 'cart')}
                                                    >
                                                        Cart
                                                    </ListItem> */}

                                                    <ListItem
                                                        button
                                                        onClick={() =>
                                                            handleClose(
                                                                history,
                                                                'dashboard/profile',
                                                            )
                                                        }
                                                    >
                                                        Profile
                                                    </ListItem>
                                                    <ListItem button onClick={logoutUser}>
                                                        Logout
                                                    </ListItem>
                                                </ul>
                                            </Popover>
                                        </li>
                                    </>
                                ) : (
                                    <>
                                        {/* <li>
                                            <NavLink
                                                className="d-flex align-items-center"
                                                activeClassName="active"
                                                exact
                                                to="/auctions"
                                            >
                                                <span className="material-icons mr-1">
                                                    local_activity
                                                </span>
                                                Events
                                            </NavLink>
                                        </li> */}
                                        {facebookLink && (
                                            <li className="facebook">
                                                <a
                                                    href={facebookLink}
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                    title="Facebook"
                                                >
                                                    <i className="fab fa-facebook"></i>
                                                </a>
                                            </li>
                                        )}
                                        <li className="headerAuthBtn signUpBtn mr-2">
                                            <NavLink activeClassName="active" to="/login">
                                                Login
                                            </NavLink>{' '}
                                        </li>
                                        <li className="headerAuthBtn">
                                            <NavLink activeClassName="active" to="/sign-up">
                                                Sign up
                                            </NavLink>
                                        </li>
                                    </>
                                )}
                            </ul>
                        </div>
                    </div>
                </header>
            ) : (
                <>
                    {/* MOBILE / TAB NAVIGATION */}
                    <header className="mainHeader mobHeader customContainer noPrint d-flex justify-content-between align-items-center">
                        <div className="headLt d-flex justify-content-start">
                            <Logo className="headerLogo" />
                        </div>
                        <div className="headRt">
                            <ul className="d-flex justify-content-start ml-auto align-items-center">
                                {global.storeConfigration?.hide_charity_header?.value ==
                                '1' ? null : (
                                    <li className="headCart">
                                        <Button
                                            className="respNavBtn"
                                            onClick={toggleDrawer('top', true)}
                                        >
                                            <span className="material-icons">search</span>
                                        </Button>
                                    </li>
                                )}

                                {/* <li className="headCart">
                                    <Button
                                        className="respNavBtn"
                                        onClick={() => handleRedirectInternal(history, 'cart')}
                                    >
                                        <span className="material-icons">shopping_cart</span>
                                    </Button>
                                </li> */}

                                <li className="headCart">
                                    <Button
                                        className="respNavBtn"
                                        onClick={toggleDrawer('right', true)}
                                    >
                                        <span className="material-icons">menu</span>
                                    </Button>
                                </li>
                            </ul>
                        </div>
                    </header>
                    {global.storeConfigration?.hide_charity_header?.value == '1' ? null : (
                        <React.Fragment>
                            <SwipeableDrawer
                                className="respHeaderSearch"
                                anchor={'top'}
                                open={state['top']}
                                disableBackdropTransition={!iOS}
                                disableDiscovery={iOS}
                                disableSwipeToOpen={false}
                                onClose={toggleDrawer('top', false)}
                                onOpen={toggleDrawer('top', true)}
                            >
                                <HeaderSearch />
                            </SwipeableDrawer>
                        </React.Fragment>
                    )}
                    <React.Fragment>
                        <SwipeableDrawer
                            className="headerDrawer"
                            anchor={'right'}
                            open={state['right']}
                            disableBackdropTransition={!iOS}
                            disableDiscovery={iOS}
                            disableSwipeToOpen={false}
                            onClose={toggleDrawer('right', false)}
                            onOpen={toggleDrawer('right', true)}
                        >
                            <div className="headRt respNav d-flex justify-content-start align-items-center">
                                <div className="naLogoHead d-flex justify-content-between align-items-center">
                                    <Link to="/">
                                        <img
                                            className="headerLogo"
                                            src={global?.storeDetails?.logoValue}
                                            alt={`${global?.storeDetails?.name} logo`}
                                        />{' '}
                                    </Link>
                                    <Button
                                        className="headDrawerClose"
                                        onClick={toggleDrawer('right', false)}
                                    >
                                        <span className="material-icons">clear</span>
                                    </Button>
                                </div>
                                <Divider />
                                <ul className="navRespLinks" onClick={toggleDrawer('right', false)}>
                                    <ListItem button>
                                        <NavLink activeClassName="active" to="/" exact>
                                            <span className="material-icons">home</span> Home
                                        </NavLink>
                                    </ListItem>

                                    {/* <ListItem button>
                                        <NavLink activeClassName="active" exact to="/auctions">
                                            <span class="material-icons">manage_search</span>
                                            Events
                                        </NavLink>
                                    </ListItem> */}
                                    {facebookLink && (
                                        <ListItem button>
                                            <a
                                                href={facebookLink}
                                                target="_blank"
                                                rel="noopener noreferrer"
                                                title="Facebook"
                                            >
                                                <i className="fab fa-facebook"></i>
                                            </a>
                                        </ListItem>
                                    )}

                                    {isAuthenticated && (
                                        <>
                                            {user && user.role == 1 ? (
                                                <>
                                                    <ListItem button>
                                                        <NavLink
                                                            activeClassName="active"
                                                            to="/dashboard/overview"
                                                        >
                                                            <span className="material-icons">
                                                                dashboard
                                                            </span>
                                                            Dashboard
                                                        </NavLink>
                                                    </ListItem>
                                                    <ListItem button>
                                                        <NavLink
                                                            activeClassName="active"
                                                            to="/sellerdashboard"
                                                        >
                                                            <span className="material-icons">
                                                                gavel
                                                            </span>
                                                            My Auctions
                                                        </NavLink>
                                                    </ListItem>
                                                    <ListItem button>
                                                        <NavLink
                                                            activeClassName="active"
                                                            to="/dashboard/reports"
                                                        >
                                                            <span className="material-icons">
                                                                description
                                                            </span>
                                                            Reports
                                                        </NavLink>
                                                    </ListItem>
                                                    <ListItem button>
                                                        <NavLink
                                                            activeClassName="active"
                                                            to="/onboard"
                                                        >
                                                            <span className="material-icons">
                                                                payments
                                                            </span>
                                                            Payment Gateway Onboard
                                                        </NavLink>
                                                    </ListItem>
                                                </>
                                            ) : global.storeConfigration?.hide_charity_header
                                                  ?.value == '1' ? null : (
                                                <ListItem button>
                                                    <NavLink
                                                        activeClassName="active"
                                                        to="/dashboard/mybids"
                                                    >
                                                        <span className="material-icons">
                                                            gavel
                                                        </span>
                                                        My Bids
                                                    </NavLink>
                                                </ListItem>
                                            )}

                                            <ListItem button>
                                                <NavLink
                                                    activeClassName="active"
                                                    to="/dashboard/profile"
                                                >
                                                    <span className="material-icons">person</span>
                                                    Profile
                                                </NavLink>
                                            </ListItem>
                                            {global.storeConfigration?.hide_charity_header?.value ==
                                            '1' ? null : (
                                                <ListItem button>
                                                    <NavLink
                                                        activeClassName="active"
                                                        to="/dashboard/favorites"
                                                    >
                                                        <span className="material-icons">
                                                            favorite
                                                        </span>
                                                        Favorites
                                                    </NavLink>
                                                </ListItem>
                                            )}
                                            <ListItem button>
                                                <NavLink
                                                    activeClassName="active"
                                                    to="/dashboard/cards"
                                                >
                                                    <span className="material-icons">
                                                        credit_card
                                                    </span>
                                                    Saved Cards
                                                </NavLink>
                                            </ListItem>
                                            {global.storeConfigration?.hide_charity_header?.value ==
                                            '1' ? null : (
                                                <>
                                                    <ListItem button>
                                                        <NavLink
                                                            activeClassName="active"
                                                            to="/dashboard/savedSearch"
                                                        >
                                                            <span className="material-icons">
                                                                bookmark
                                                            </span>
                                                            Saved Search
                                                        </NavLink>
                                                    </ListItem>
                                                    <ListItem button>
                                                        <NavLink
                                                            activeClassName="active"
                                                            to="/dashboard/preference"
                                                        >
                                                            <span className="material-icons">
                                                                notifications
                                                            </span>
                                                            Notification Preferences
                                                        </NavLink>
                                                    </ListItem>
                                                    <ListItem button>
                                                        <NavLink
                                                            activeClassName="active"
                                                            to="/dashboard/transactions"
                                                        >
                                                            <span className="material-icons">
                                                                receipt
                                                            </span>
                                                            Transactions
                                                        </NavLink>
                                                    </ListItem>
                                                </>
                                            )}
                                        </>
                                    )}

                                    <ListItem button>
                                        <NavLink activeClassName="active" to="/aboutus">
                                            <span className="material-icons">groups</span>
                                            About Us
                                        </NavLink>
                                    </ListItem>
                                    <ListItem button>
                                        <NavLink activeClassName="active" to="/faq">
                                            <span className="material-icons">quiz</span>
                                            FAQs
                                        </NavLink>
                                    </ListItem>
                                    <ListItem button>
                                        <NavLink activeClassName="active" to="/termsOfService">
                                            <span className="material-icons">auto_stories</span>
                                            Terms & Conditions
                                        </NavLink>
                                    </ListItem>

                                    <ListItem button>
                                        <NavLink activeClassName="active" exact to="/contact_us">
                                            <span className="material-icons">contact_support</span>
                                            Contact Us
                                        </NavLink>
                                    </ListItem>

                                    <Headernavmenu type="mobile" />

                                    {!isAuthenticated ? (
                                        <>
                                            <ListItem button>
                                                <NavLink activeClassName="active" to="/login">
                                                    <span className="material-icons">login</span>
                                                    Login
                                                </NavLink>
                                            </ListItem>
                                            <ListItem button>
                                                <NavLink activeClassName="active" to="/sign-up">
                                                    <span className="material-icons">
                                                        how_to_reg
                                                    </span>
                                                    Register
                                                </NavLink>
                                            </ListItem>
                                        </>
                                    ) : (
                                        <ListItem button onClick={logoutUser}>
                                            <span className="material-icons">
                                                power_settings_new
                                            </span>
                                            Logout
                                        </ListItem>
                                    )}
                                </ul>
                            </div>
                        </SwipeableDrawer>
                    </React.Fragment>
                </>
            )}
            <PaymentPendingHeader />
        </>
    )
}

export default React.memo(Header)
