import React, { useState, useEffect, useContext } from 'react'
import Inputfield from '../../../Component/Inputfield/Inputfield'
import { useHistory } from 'react-router-dom'
import moment from 'moment'
import './makePayment.css'
import _ from 'lodash'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import Popup from '../../../Product/components/organisms/Popup'
import AuthContext from '../../../Product/context/auth/authContext'
import CartContext from '../../../Product/context/cart/cartContext'
import PayContext from '../../../Product/context/payment/payContext'
import StripeCardContext from '../../../Product/context/stripe/card/cardContext'
import DirectStripeContext from '../../../Product/context/directStripe/directStripeContext'
import StripeCustomerContext from '../../../Product/context/stripe/customer/customerContext'
import { currencyFormat, handleRedirectInternal, mapData } from '../../../Product/common/components'
import UserContext from '../../../Product/context/user/userContext'
import CustomSelect from '../../../Product/components/atoms/Inputs/CustomSelect'
import AlertContext from '../../../Product/context/alert/alertContext'
import { Button } from '@material-ui/core'
import PrimaryButton from '../../../Product/components/atoms/PrimaryButton'
import AddressBook from '../AddressBook'
import axios from 'axios'
import AddCard from '../StripeIntegration/AddCard'
import CustomDialog from '../../../Product/components/organisms/Dialog'
import CurrencyConverterFormat from '../CurrencyConverter/CurrencyConverterFormat'
import { useTranslation } from 'react-i18next'
import { swapCountry } from '../../commonFunctions'
import DummyPaypal from './DummyPaypal'
import { useFlutterwave, FlutterWaveButton } from 'react-flutterwave'
import CheckBox from '../../../Product/components/atoms/CheckBox'
import CardDetails from '../../../Product/components/organisms/CardDetails/index_skyflow'

const MakePaymentComponent = ({
    amount,
    cartCount,
    cart_ids,
    invoice_id,
    type,
    buyers_premium,
    transaction_fee,
    total_payable_amount,
    local_pick_total_payable_amount,
    shipping_fee,
    discount_amount,
    sales_tax,
    local_pick_sales_tax,
    currency,
    redirectUrl,
    items,
    setItems,
    deposit_amount,
    transfer_amount,
    line_item_fee,

    project_id_buynow,
}) => {
    const authContext = useContext(AuthContext)
    const cartContext = useContext(CartContext)
    const payContext = useContext(PayContext)
    const userContext = useContext(UserContext)
    const alertContext = useContext(AlertContext)
    const { setAlert } = alertContext
    const { static_page, getStaticPage, getAllShippingAddress, all_shipping_address } = userContext
    const { user, loadUser } = authContext
    const { getUserCart } = cartContext
    const {
        successAfterPay,
        successAfterPayAuction,
        successAfterPayupdateforbank,
        successAfterPayAuctionforbank,
        successAfterPayCalculation,
        successAfterPayAuctionCalculation,
        successAfterPaypaypalAuction,
        successAfterPaypaypalBuynow,
        buynow_make_payment,
        buynow_reset_payment,
        clearResponse,
        responseStatus: responseStatusPayment,
    } = payContext

    const {
        get_skyflow_cards,
        getSkyflowCards,
        updateSkyflowCard,
        savePaymentMethodSkyflow,
        getAllStripeCards,
        createStripeCard,
        responseStatus: responseStatusCard,
        clearResponse: clearResponseCard,
    } = useContext(StripeCardContext)
    const { usercards } = useContext(DirectStripeContext)
    const {
        customer_details,
        createStripeCustomer,
        getStripeCustomer,
        addCustomerProfile,
        CraeteBankCustomer,
        BuyerAddBank,
        getBankDetails,
        verifyBankdetails,
        transferamount,
        responseStatus: responseStatusStripeCustomer,
        clearResponse: clearbankpayment,
    } = useContext(StripeCustomerContext)
    const [open, setOpen] = useState(false)
    const [cvv, setCvv] = useState('')
    const [loading, setLoading] = useState(false)
    const [saveCard, setSaveCard] = useState(false)
    const [isOpen, setIsOpen] = useState(false)
    const [bank_verify_open, setBankVarify] = useState(false)
    const [addAddressOpen, setAddAddressOpen] = useState(false)
    const [addOpen, setAddOpen] = useState(false)
    const [btnLoading, setBtnLoading] = useState(false)
    const [walletChecked, setWalletChecked] = useState(false)
    const [amountNeedTopay, setAmountNeedTopay] = useState(0)
    const [walletSpend, setWalletSpend] = useState(0)
    const [paymentoption, setPaymentoption] = useState(1)
    const [selectAddress, setSelectAddress] = useState(0)
    const [shipping, setShipping] = useState('yes')
    const [stateList, setStateList] = useState([])
    const [countryList, setCountryList] = useState([])
    let history = useHistory()
    const [cardDetails, setCardDetails] = useState({
        cardName: '',
        cardNumber: '',
        expiryMonth: '',
        expiryYear: '',
        cvv: '',
        cardAddress: '',
        cardAddress1: '',
        cardCountry: '',
        cardState: '',
        cardCity: '',
        cardZip: '',
    })
    const [allData, setAllData] = useState([])
    const [defaultCard, setDefaultCard] = useState(false)
    const [allBankData, setAllBankData] = useState([])
    const [cardData, setCardData] = useState('nocard')
    const [error, setError] = useState({})
    const handleClose = () => {
        setOpen(false)
        setLoading(false)
        setCardDetails({})
        clearResponse()
        clearbankpayment()
        getUserCart()
        // handleRedirectInternal(history, `dashboard/mybids?s=${type === 'buynow' ? 3 : 1}`)
        // window.location.reload()
        window.location.href = window.location.href.includes(global?.storeDetails?.id)
            ? `/${global?.storeDetails?.id}/dashboard/mybids?s=${
                  type === 'buynow' ? 3 : move_to_make_an_offer ? 4 : 1
              }`
            : `/dashboard/mybids?s=${type === 'buynow' ? 3 : move_to_make_an_offer ? 4 : 1}`
    }

    const [manageCard, setManageCard] = useState({
        popup: false,
        status: 'new',
        data: null,
    })

    const toggleFullScreenCardPopup = (popup, status, data) => {
        setManageCard({ popup, status, data })
    }

    const onCheckBoxChange = () => setSaveCard((prevState) => !prevState)
    const onChange = (e) => {
        if (e.target.checked) {
            const id = e.target.value
            const card = allData.filter((data) => data.id === id)
            console.log(card[0].id, id)
            setCardData({
                id: card[0].id,
                is_cvv_available: card[0].is_cvv_available,
            })
            setDefaultCard({
                ...defaultCard,
                currDefault: false,
            })
            cvvFormik.values.cvv = ''
        } else {
            setDefaultCard({
                ...defaultCard,
                currDefault: false,
            })
            setCardData('nocard')
        }
        // e.target.checked ? setCardData(e.target.value) : setCardData('nocard')
    }

    const handleWalletChange = () => {
        setWalletChecked(!walletChecked)
        var total_pay_amount =
            parseFloat(deposit_amount ? deposit_amount : 0) > 0
                ? deposit_amount
                : shipping_fee != 0
                ? total_payable_amount
                : local_pick_total_payable_amount
        var amount_need_to_pay = parseFloat(total_pay_amount) - parseFloat(user?.wallet_amount)
        var wallet_amount = parseFloat(user?.wallet_amount) - parseFloat(total_pay_amount)
        amount_need_to_pay = amount_need_to_pay <= 0 ? 0 : amount_need_to_pay
        wallet_amount =
            wallet_amount <= 0
                ? user?.wallet_amount
                : parseFloat(user?.wallet_amount) - parseFloat(wallet_amount)
        console.log(amount_need_to_pay, wallet_amount)
        setAmountNeedTopay(amount_need_to_pay)
        setWalletSpend(wallet_amount)
    }

    useEffect(() => {
        if (items?.length > 0) {
            if (items[0].make_an_offer_io == 1) {
                setMovemake(true)
            }
        }
    }, [items])

    useEffect(() => {
        if (!cartCount && user && global.storeConfigration?.skyflow_integration?.value == 1) {
            // const getData = async () => {
            //     getAllStripeCards({
            //         account_id: process.env.REACT_APP_AUCTIONPAYID,
            //         customer_id: user.card_paymentid,
            //         object: 'card',
            //     })
            // }
            // getData()
            getSkyflowCards()
        }
    }, [user, cartCount, history])

    useEffect(() => {
        if (user) {
            getBankDetails({
                site_id: global.storeDetails.site_id ? global.storeDetails.site_id : '',
                email: user.email ? user.email : '',
                user_id: user.id ? user.id : '',
            })
        }
    }, [user])

    useEffect(() => {
        getStaticPage(
            {
                page_id: type === 'buynow' ? 'buynow_success_msg' : 'auction_success_msg',
            },
            1,
        )
    }, [])

    // useEffect(() => {
    //     console.log('innnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnn', static_page)
    // }, [])

    // useEffect(() => {
    //     if (get_all_card_details) {
    //         setAllData(get_all_card_details.records)
    //     }
    // }, [get_all_card_details])
    // useEffect(() => {
    //     if (usercards.length) {
    //         setAllData(usercards)
    //     }
    // }, [usercards])

    useEffect(() => {
        if (get_skyflow_cards) {
            if (get_skyflow_cards.length) {
                get_skyflow_cards.forEach((card) => {
                    if (card.isDefault) {
                        setDefaultCard({ ...card, currDefault: true })
                        setCardData(card.id)
                    }
                })
            }

            setAllData(get_skyflow_cards)
        }
    }, [get_skyflow_cards])

    useEffect(() => {
        if (responseStatusStripeCustomer) {
            if (responseStatusStripeCustomer.from === 'get_bank_buyer') {
                if (responseStatusStripeCustomer.status === 'success') {
                    setAllBankData(
                        responseStatusStripeCustomer.data.data
                            ? responseStatusStripeCustomer.data.data
                            : [],
                    )
                }
            }
            // console.log('++++++++++++++++++++++1', responseStatusStripeCustomer)
            if (responseStatusStripeCustomer.from === 'transfer_amount_buyer') {
                if (
                    responseStatusStripeCustomer.status === 'success' &&
                    responseStatusStripeCustomer.message === 'Payment successfully!'
                ) {
                    if (type === 'buynow') {
                        successAfterPayupdateforbank({
                            cart_ids: JSON.stringify(cart_ids),
                            transfer_id: responseStatusStripeCustomer.data.transfer,
                        })
                    }
                    if (type === 'auction') {
                        successAfterPayAuctionforbank({
                            invoice_id,
                            transid: responseStatusStripeCustomer.data.transfer,
                        })
                    }
                } else {
                    setLoading(false)
                    setAlert(responseStatusStripeCustomer.message, 'error')
                }
            }

            if (responseStatusStripeCustomer.from === 'add_bank_customer') {
                if (
                    responseStatusStripeCustomer.status === 'success' ||
                    responseStatusStripeCustomer.message === 'Customer account already created!'
                ) {
                    var bank_data = {
                        site_id: global.storeDetails.site_id ? global.storeDetails.site_id : '',
                        user_id: user.id ? user.id : '',
                        account_no: bankformik.values.bank_account_number,
                        rounting_no: bankformik.values.bank_routing_number,
                        holdername: bankformik.values.bank_account_holder_name,
                    }
                    BuyerAddBank(bank_data)
                } else {
                    setAlert(responseStatusStripeCustomer.message, 'error')
                }
            }
            if (responseStatusStripeCustomer.from === 'add_bank_buyer') {
                if (responseStatusStripeCustomer.status === 'success') {
                    setAlert('Bank Details Added Sucessfully.please verify', 'success')

                    setIsOpen(false)
                    setBankVarify(true)
                    getBankDetails({
                        site_id: global.storeDetails.site_id ? global.storeDetails.site_id : '',
                        email: user.email ? user.email : '',
                        user_id: user.id ? user.id : '',
                    })
                } else {
                    setAlert(responseStatusStripeCustomer.message, 'error')
                }
                setBtnLoading(false)
            }

            if (responseStatusStripeCustomer.from === 'verify_bank_buyer') {
                if (responseStatusStripeCustomer.status === 'success') {
                    getBankDetails({
                        site_id: global.storeDetails.site_id ? global.storeDetails.site_id : '',
                        email: user.email ? user.email : '',
                        user_id: user.id ? user.id : '',
                    })
                    setBankVarify(false)
                } else {
                    setAlert(responseStatusStripeCustomer.message, 'error')
                }
                setBtnLoading(false)
            }
            clearbankpayment()
        }
    }, [responseStatusStripeCustomer])
    useEffect(() => {
        getAllShippingAddress()
    }, [])
    useEffect(() => {
        if (all_shipping_address.length) {
            setSelectAddress(all_shipping_address[0].id)
        }
    }, [all_shipping_address])

    // const flutterWaveConfig = {
    //     public_key: global?.pluginConfiguration?.flutterwave_payment_gateway?.client_key,
    //     tx_ref: `flutter-${new Date().valueOf()}`,
    //     amount:
    //         walletChecked && amountNeedTopay
    //             ? amountNeedTopay
    //             : parseFloat(
    //                   parseFloat(
    //                       shipping_fee != 0
    //                           ? total_payable_amount
    //                           : local_pick_total_payable_amount,
    //                   ).toFixed(2),
    //               ),
    //     currency: 'NGN',
    //     payment_options: 'card, ussd',
    //     redirect_url: `${
    //         global?.pluginConfiguration?.flutterwave_payment_gateway?.redirect_url
    //     }/checkout/${type}${type === 'buynow' ? '' : '?id=' + invoice_id}`,
    //     meta: {
    //         token: 54,
    //     },
    //     customer: {
    //         name: `${user?.first_name} ${user?.last_name}`,
    //         email: user?.email,
    //     },
    // }

    // const handleFlutterPayment = useFlutterwave(flutterWaveConfig)
    // const flutPay = () => {
    //     var ship_local = [],
    //         discount_details = [],
    //         need_discount = 0,
    //         transfer_amount_details = [],
    //         transfer_location = 0

    //     items.map((val) => {
    //         ship_local.push({
    //             id: type === 'buynow' ? val.cart_id : val.buynow_id,
    //             hasshipping: val.temp_hasshipping || val.hasshipping,
    //         })
    //         if (parseInt(val?.discount_amount ? val.discount_amount : 0) > 0) {
    //             discount_details.push({
    //                 project_id: val.product_id,
    //                 amount: val.discount_amount,
    //             })
    //             need_discount = 1
    //         }
    //         if (parseInt(val?.transfer_fee_location ? val.transfer_fee_location : 0) > 0) {
    //             transfer_amount_details.push({
    //                 project_id: val.product_id,
    //                 amount: val.transfer_fee_location,
    //             })
    //             transfer_location = 1
    //         }
    //     })
    //     if (
    //         global?.pluginConfiguration?.referral?.enable == 1 &&
    //         amountNeedTopay == 0 &&
    //         walletChecked
    //     ) {
    //         if (type === 'buynow') {
    //             successAfterPayCalculation({
    //                 cart_ids: JSON.stringify(cart_ids),
    //                 payment_details: {},
    //                 shipping_addr_id: selectAddress,
    //                 user_country: user?.country == '38' ? 'CA' : 'US',
    //                 ship_local,
    //                 shipping_inside: shipping,
    //                 transfer_location: transfer_location,
    //                 transfer_amount_details: transfer_amount_details,
    //                 shipping_amount_from_shipstation: global.pluginConfiguration?.shipping?.enable
    //                     ? items.map((val) => {
    //                           return {
    //                               id: type === 'buynow' ? val.cart_id : val.buynow_id,
    //                               shipping_fee: val.shipping_fee,
    //                           }
    //                       })
    //                     : [],
    //                 user_id: user?.id,
    //                 transaction_id: params.get('transaction_id'),
    //                 payment_name: 'Wallet',
    //                 wallet: walletChecked ? 1 : 0,
    //                 wallet_spend: walletSpend,
    //             })
    //             return false
    //         }
    //         if (type === 'auction') {
    //             successAfterPayAuctionCalculation({
    //                 invoice_id,
    //                 payment_details: {},
    //                 shipping_addr_id: selectAddress,
    //                 user_country: user?.country == '38' ? 'CA' : 'US',
    //                 ship_local,
    //                 shipping_inside: shipping,
    //                 need_discount: need_discount,
    //                 discount_details: discount_details,
    //                 transfer_location: transfer_location,
    //                 transfer_amount_details: transfer_amount_details,
    //                 user_id: user?.id,
    //                 transaction_id: params.get('transaction_id'),
    //                 payment_name: 'Wallet',
    //                 wallet: walletChecked ? 1 : 0,
    //                 wallet_spend: walletSpend,
    //             })
    //             return false
    //         }
    //     }
    //     if (type === 'buynow') {
    //         successAfterPayCalculation({
    //             cart_ids: JSON.stringify(cart_ids),
    //             payment_details: {},
    //             shipping_addr_id: selectAddress,
    //             user_country: user?.country == '38' ? 'CA' : 'US',
    //             ship_local,
    //             shipping_inside: shipping,
    //             transfer_location: transfer_location,
    //             transfer_amount_details: transfer_amount_details,
    //             shipping_amount_from_shipstation: global.pluginConfiguration?.shipping?.enable
    //                 ? items.map((val) => {
    //                       return {
    //                           id: type === 'buynow' ? val.cart_id : val.buynow_id,
    //                           shipping_fee: val.shipping_fee,
    //                       }
    //                   })
    //                 : [],
    //             user_id: user?.id,
    //             transaction_id: params.get('transaction_id'),
    //             payment_name: 'Flutterwave',
    //             wallet: walletChecked,
    //             wallet_spend: walletSpend,
    //         })
    //     }
    //     if (type === 'auction') {
    //         successAfterPayAuctionCalculation({
    //             invoice_id,
    //             payment_details: {},
    //             shipping_addr_id: selectAddress,
    //             user_country: user?.country == '38' ? 'CA' : 'US',
    //             ship_local,
    //             shipping_inside: shipping,
    //             need_discount: need_discount,
    //             discount_details: discount_details,
    //             transfer_location: transfer_location,
    //             transfer_amount_details: transfer_amount_details,
    //             user_id: user?.id,
    //             transaction_id: params.get('transaction_id'),
    //             payment_name: 'Flutterwave',
    //             wallet: walletChecked,
    //             wallet_spend: walletSpend,
    //         })
    //     }
    // }

    // const usingwallettoupdatepayment = () => {
    //     setLoading(true)
    //     var ship_local = [],
    //         discount_details = [],
    //         need_discount = 0,
    //         transfer_amount_details = [],
    //         transfer_location = 0

    //     items.map((val) => {
    //         ship_local.push({
    //             id: type === 'buynow' ? val.cart_id : val.buynow_id,
    //             hasshipping: val.temp_hasshipping || val.hasshipping,
    //         })
    //         if (parseInt(val?.discount_amount ? val.discount_amount : 0) > 0) {
    //             discount_details.push({
    //                 project_id: val.product_id,
    //                 amount: val.discount_amount,
    //             })
    //             need_discount = 1
    //         }
    //         if (parseInt(val?.transfer_fee_location ? val.transfer_fee_location : 0) > 0) {
    //             transfer_amount_details.push({
    //                 project_id: val.product_id,
    //                 amount: val.transfer_fee_location,
    //             })
    //             transfer_location = 1
    //         }
    //     })
    //     if (
    //         global?.pluginConfiguration?.referral?.enable == 1 &&
    //         amountNeedTopay == 0 &&
    //         walletChecked
    //     ) {
    //         if (type === 'buynow') {
    //             successAfterPayCalculation({
    //                 cart_ids: JSON.stringify(cart_ids),
    //                 payment_details: {},
    //                 shipping_addr_id: selectAddress,
    //                 user_country: user?.country == '38' ? 'CA' : 'US',
    //                 ship_local,
    //                 shipping_inside: shipping,
    //                 transfer_location: transfer_location,
    //                 transfer_amount_details: transfer_amount_details,
    //                 shipping_amount_from_shipstation: global.pluginConfiguration?.shipping?.enable
    //                     ? items.map((val) => {
    //                           return {
    //                               id: type === 'buynow' ? val.cart_id : val.buynow_id,
    //                               shipping_fee: val.shipping_fee,
    //                           }
    //                       })
    //                     : [],
    //                 user_id: user?.id,
    //                 transaction_id: params.get('transaction_id'),
    //                 payment_name: 'Wallet',
    //                 wallet: walletChecked ? 1 : 0,
    //                 wallet_spend: walletSpend,
    //             })
    //             return false
    //         }
    //         if (type === 'auction') {
    //             successAfterPayAuctionCalculation({
    //                 invoice_id,
    //                 payment_details: {},
    //                 shipping_addr_id: selectAddress,
    //                 user_country: user?.country == '38' ? 'CA' : 'US',
    //                 ship_local,
    //                 shipping_inside: shipping,
    //                 need_discount: need_discount,
    //                 discount_details: discount_details,
    //                 transfer_location: transfer_location,
    //                 transfer_amount_details: transfer_amount_details,
    //                 user_id: user?.id,
    //                 transaction_id: params.get('transaction_id'),
    //                 payment_name: 'Wallet',
    //                 wallet: walletChecked ? 1 : 0,
    //                 wallet_spend: walletSpend,
    //             })
    //             return false
    //         }
    //     }
    // }
    const handleSubmit = async (buynow_check = 0) => {
        // e.preventDefault()
        // if (buynow_check == 0) {
        //     event.preventDefault()
        // }
        if (
            global?.storeConfigration?.default_delivery_state?.value &&
            all_shipping_address.length > 0
        ) {
            console.log('Place 1')
            if (
                !global.storeConfigration.default_delivery_state.value.includes(
                    all_shipping_address.filter((val) => val.id == selectAddress)[0].state,
                )
            ) {
                setAlert(
                    `Selected Shipping State Shoud be ${global.storeConfigration.default_delivery_state.value}.`,
                    'error',
                )
                return false
            }
        }
        if (
            global?.pluginConfiguration?.referral?.enable == 1 &&
            amountNeedTopay == 0 &&
            walletChecked
        ) {
            console.log('Place 2')
            if (global?.pluginConfiguration?.flutterwave_payment_gateway?.enable == 1) {
                console.log('Place 3')
                return flutPay()
            } else {
                console.log('Place 4')
                return usingwallettoupdatepayment()
            }
        }
        if (global?.pluginConfiguration?.flutterwave_payment_gateway?.enable == 1) {
            console.log('Place 5')
            return handleFlutterPayment({
                callback: (response) => {
                    localStorage.setItem('flutterwave', JSON.stringify(response))
                },
                onClose: () => {},
            })
        }
        const regex = /^[a-zA-Z ]*$/g
        let error = {}
        // console.log(cardData, saveCard, type)
        if (cardData === 'nocard') {
            // if (
            //     isNaN(cardDetails.cardNumber) ||
            //     cardDetails.cardNumber.length < 14 ||
            //     cardDetails.cardNumber.length > 16
            // ) {
            //     error['cardNumber'] = 'Enter valid card number'
            // }
            // if (!cardDetails.cardName) {
            //     error['cardName'] = 'Enter valid card name'
            // }
            // if (!cardDetails.cardCountry) {
            //     error['cardCountry'] = 'Invalid!'
            // }
            // if (!cardDetails.cardCity) {
            //     error['cardCity'] = 'Invalid!'
            // }
            // if (!regex.test(cardDetails.cardCity)) {
            //     error['cardCity'] = 'Only characters allowed!'
            // }
            // if (!cardDetails.cardState) {
            //     error['cardState'] = 'Invalid!'
            // }
            // if (!cardDetails.cardAddress) {
            //     error['cardAddress'] = 'Invalid!'
            // }
            // if (!cardDetails.cardZip || cardDetails.cardZip.length > 6) {
            //     error['cardZip'] = 'Invalid!'
            // }
            // if (
            //     cardDetails.expiryMonth.length === 0 ||
            //     isNaN(cardDetails.expiryMonth) ||
            //     cardDetails.expiryMonth.length > 2 ||
            //     cardDetails.expiryMonth <= 0 ||
            //     cardDetails.expiryMonth > 12
            // ) {
            //     error['expiryMonth'] = 'Enter valid expiry month'
            // }
            // if (
            //     cardDetails.cvv.length === 0 ||
            //     isNaN(cardDetails.cvv) ||
            //     cardDetails.cvv.length > 4 ||
            //     cardDetails.cvv.length < 3
            // ) {
            //     error['cvv'] = 'Enter valid CVV'
            // }
            // if (
            //     isNaN(cardDetails.expiryYear) ||
            //     cardDetails.expiryYear.length === 0 ||
            //     (cardDetails.expiryYear.length < 2 && cardDetails.expiryYear.length > 4)
            // ) {
            //     error['expiryYear'] = 'Enter valid expiry year'
            // }
            // if (!error['expiryMonth'] && !error['expiryYear']) {
            //     let month =
            //         cardDetails.expiryMonth.length === 1
            //             ? `${0}${cardDetails.expiryMonth}`
            //             : cardDetails.expiryMonth
            //     let year = cardDetails.expiryYear.length === 2 ? `YY` : 'YYYY'
            //     if (moment(`${month}/${cardDetails.expiryYear}`, `MM/${year}`).isBefore(moment())) {
            //         error['expiryYear'] = 'Invalid year'
            //     }
            // }
            // setError(error)
        }
        if (_.isEmpty(error)) {
            let body = {}
            if (cardData === 'nocard') {
                body = {
                    // amount: parseFloat(
                    //     ((parseFloat(amount) + parseFloat(buyers_premium)) * 100).toFixed(2),
                    // ),
                    customer: user.card_paymentid,
                    currency: user?.country == 38 ? 'cad' : 'usd',
                    description: 'Amount paid from checkout page!',
                    card_name: cardDetails.cardName,
                    card_number: cardDetails.cardNumber,
                    card_cvc: cardDetails.cvv,
                    card_exp_month: cardDetails.expiryMonth,
                    card_exp_year: cardDetails.expiryYear,
                    card_token: true,
                    message: 'Manual Payment!',
                    card_address_line1: cardDetails.cardAddress,
                    card_address_line2: cardDetails.cardAddress1,
                    card_address_city: cardDetails.cardCity,
                    card_address_state: cardDetails.cardState,
                    card_address_country: cardDetails?.cardCountry == '38' ? 'CA' : 'US',
                    card_address_zip: cardDetails.cardZip,
                    billing_details: {
                        email: user.email,
                        name: user.first_name,
                        address: {
                            line1: user.address1,
                            line2: user.address2,
                            city: user.city,
                            state: user.state,
                            country: user?.country == '38' ? 'CA' : 'US',
                            postal_code: user.zip,
                        },
                    },
                }
            } else {
                body = {
                    skyflow_id: cardData.id,
                    customer: user.card_paymentid,
                    currency: user?.country == 38 ? 'cad' : 'usd',
                    description: 'Card checkout',
                    project: 'skyflow',
                }
            }
            if (saveCard) {
                let newCard = {
                    account_id: process.env.REACT_APP_AUCTIONPAYID,
                    customer_id: user ? user.card_paymentid : '',
                    cvv: '',
                    card_address_zip: cardDetails.cardZip,
                    card_address_country: cardDetails.cardCountry == '38' ? 'CA' : 'US',
                    is_primary: '',
                    user_id: '',
                    id: '',
                    card_token: true,
                    card_name: cardDetails.cardName,
                    card_number: cardDetails.cardNumber,
                    card_cvc: cardDetails.cvv,
                    card_exp_month: cardDetails.expiryMonth,
                    card_exp_year: cardDetails.expiryYear,
                    card_address_line1: cardDetails.cardAddress,
                    card_address_line2: cardDetails.cardAddress1,
                    card_address_city: cardDetails.cardCity,
                    card_address_state: cardDetails.cardState,
                }
                createStripeCard(newCard)
            }

            const ship_local = []
            const discount_details = []
            let need_discount = 0
            const transfer_amount_details = []
            let transfer_location = 0
            let wallet_amount = 0
            let referel_plugin = global?.pluginConfiguration?.referral?.enable == 1 ? 1 : 0

            items.map((val) => {
                ship_local.push({
                    id: type === 'buynow' ? val.cart_id : val.buynow_id,
                    hasshipping: val.temp_hasshipping || val.hasshipping,
                })
                if (parseInt(val?.discount_amount ? val.discount_amount : 0) > 0) {
                    discount_details.push({
                        project_id: val.product_id,
                        amount: val.discount_amount,
                    })
                    need_discount = 1
                }
                if (parseInt(val?.transfer_fee_location ? val.transfer_fee_location : 0) > 0) {
                    transfer_amount_details.push({
                        project_id: val.product_id,
                        amount: val.transfer_fee_location,
                    })
                    transfer_location = 1
                }
            })

            if (
                global?.pluginConfiguration?.referral?.enable == 1 &&
                parseFloat(amountNeedTopay) > 0.5 &&
                walletChecked
            ) {
                wallet_amount = parseFloat(user?.wallet_amount)
                referel_plugin = 1
            } else {
                if (
                    parseFloat(amountNeedTopay) < 0.5 &&
                    parseFloat(amountNeedTopay) > 0.0 &&
                    global?.pluginConfiguration?.referral?.enable == 1 &&
                    walletChecked
                ) {
                    setAlert(
                        'Total amount should be greater than or equal to $0.50.Your total amount is $' +
                            parseFloat(amountNeedTopay).toFixed(2) +
                            'after deduction from walletamount.',
                        'error',
                    )
                    setLoading(false)
                    return false
                }
            }

            if (type === 'buynow') {
                console.log('Place 6')
                if (buynow_check == 1) {
                    var first_time_out = Math.floor(Math.random() * (4000 - 2000))
                    setTimeout(() => {
                        buynow_make_payment({ product_id: project_id_buynow })
                    }, first_time_out)

                    return false
                } else {
                    console.log('Place 7')
                    var second_time_out = Math.floor(Math.random() * (4000 - 2000))
                    setTimeout(() => {
                        successAfterPay({
                            cart_ids: JSON.stringify(cart_ids),
                            payment_details: body,
                            shipping_addr_id: selectAddress,
                            user_country: user?.country == '38' ? 'CA' : 'US',
                            ship_local,
                            shipping_inside: shipping,
                            transfer_location: transfer_location,
                            transfer_amount_details: transfer_amount_details,
                            wallet_amount: wallet_amount,
                            referel_plugin: referel_plugin,
                            wallet: walletChecked ? 1 : 0,
                            wallet_spend: walletSpend,
                            user_id: user?.id,
                            need_discount: need_discount,
                            discount_details: discount_details,
                            shipping_amount_from_shipstation: global.pluginConfiguration?.shipping
                                ?.enable
                                ? items.map((val) => {
                                      return {
                                          id: type === 'buynow' ? val.cart_id : val.buynow_id,
                                          shipping_fee: val.shipping_fee,
                                      }
                                  })
                                : [],
                        })
                    }, second_time_out)
                }
            }
            if (type === 'auction') {
                successAfterPayAuction({
                    invoice_id,
                    payment_details: body,
                    shipping_addr_id: selectAddress,
                    user_country: user?.country == '38' ? 'CA' : 'US',
                    ship_local,
                    shipping_inside: shipping,
                    need_discount: need_discount,
                    discount_details: discount_details,
                    transfer_location: transfer_location,
                    transfer_amount_details: transfer_amount_details,
                    wallet_amount: wallet_amount,
                    referel_plugin: referel_plugin,
                    wallet: walletChecked ? 1 : 0,
                    wallet_spend: walletSpend,
                    user_id: user?.id,
                })
            }
        }
    }

    const paypalSubmit = async (event) => {
        event.preventDefault()
        if (
            global?.storeConfigration?.default_delivery_state?.value &&
            all_shipping_address.length > 0
        ) {
            if (
                !global.storeConfigration.default_delivery_state.value.includes(
                    all_shipping_address.filter((val) => val.id == selectAddress)[0].state,
                )
            ) {
                setAlert(
                    `Selected Shipping State Shoud be ${global.storeConfigration.default_delivery_state.value}.`,
                    'error',
                )
                return false
            }
        }
        // if (
        //     global?.pluginConfiguration?.referral?.enable == 1 &&
        //     amountNeedTopay == 0 &&
        //     walletChecked
        // ) {
        //     if (global?.pluginConfiguration?.flutterwave_payment_gateway?.enable == 1) {
        //         return flutPay()
        //     } else {
        //         return usingwallettoupdatepayment()
        //     }
        // }
        // if (global?.pluginConfiguration?.flutterwave_payment_gateway?.enable == 1) {
        //     return handleFlutterPayment({
        //         callback: (response) => {
        //             localStorage.setItem('flutterwave', JSON.stringify(response))
        //         },
        //         onClose: () => {},
        //     })
        // }

        // const regex = /^[a-zA-Z]+$/
        // const regexZip = /^[a-zA-Z0-9]*$/g
        // let error = {}
        // if (cardData === 'nocard') {
        //     if (
        //         isNaN(cardDetails.cardNumber) ||
        //         cardDetails.cardNumber.length < 14 ||
        //         cardDetails.cardNumber.length > 16
        //     ) {
        //         error['cardNumber'] = t('enter_val_number')
        //     }
        //     if (!cardDetails.cardName || !regex.test(cardDetails.cardName)) {
        //         error['cardName'] = t('enter_val_name')
        //     }
        //     if (!cardDetails.cardCountry) {
        //         error['cardCountry'] = t('invalid') + '!'
        //     }
        //     if (!cardDetails.cardCity || !regex.test(cardDetails.cardCity)) {
        //         error['cardCity'] = t('invalid') + '!'
        //     }
        //     // if (!regex.test(cardDetails.cardCity)) {
        //     //     error['cardCity'] = 'Only characters allowed!'
        //     // }
        //     if (!cardDetails.cardState) {
        //         error['cardState'] = t('invalid') + '!'
        //     }
        //     if (!cardDetails.cardAddress) {
        //         error['cardAddress'] = t('invalid') + '!'
        //     }
        //     if (
        //         cardDetails.cardZip.length === 0 ||
        //         !(
        //             regexZip.test(cardDetails.cardZip) &&
        //             cardDetails.cardZip.length < 8 &&
        //             cardDetails.cardZip.length > 4
        //         )
        //     ) {
        //         error['cardZip'] = t('invalid') + '!'
        //     }
        //     if (
        //         cardDetails.expiryMonth.length === 0 ||
        //         isNaN(cardDetails.expiryMonth) ||
        //         cardDetails.expiryMonth.length > 2 ||
        //         cardDetails.expiryMonth.length < 2 ||
        //         cardDetails.expiryMonth <= 0 ||
        //         cardDetails.expiryMonth > 12
        //     ) {
        //         error['expiryMonth'] = t('enter_valid_month')
        //     }
        //     if (
        //         cardDetails.cvv.length === 0 ||
        //         isNaN(cardDetails.cvv) ||
        //         cardDetails.cvv.length > 4 ||
        //         cardDetails.cvv.length < 3
        //     ) {
        //         error['cvv'] = t('enter_valid_cvv')
        //     }
        //     if (
        //         isNaN(cardDetails.expiryYear) ||
        //         cardDetails.expiryYear.length === 0 ||
        //         (cardDetails.expiryYear.length < 2 && cardDetails.expiryYear.length > 4)
        //     ) {
        //         error['expiryYear'] = t('enter_valid_year')
        //     }
        //     if (!error['expiryMonth'] && !error['expiryYear']) {
        //         let month =
        //             cardDetails.expiryMonth.length === 1
        //                 ? `${0}${cardDetails.expiryMonth}`
        //                 : cardDetails.expiryMonth
        //         let year = cardDetails.expiryYear.length === 2 ? `YY` : 'YYYY'
        //         if (moment(`${month}/${cardDetails.expiryYear}`, `MM/${year}`).isBefore(moment())) {
        //             error['expiryYear'] = t('invalid_year')
        //         }
        //     }
        //     setError(error)
        // }
        //if (_.isEmpty(error)) {
        setLoading(true)
        let body = {}
        // if (cardData === 'nocard') {
        //     body = {
        // amount: parseFloat(
        //     ((parseFloat(amount) + parseFloat(buyers_premium)) * 100).toFixed(2),
        // ),
        //         customer: user.card_paymentid,
        //         currency: user?.country == 38 ? 'cad' : 'usd',
        //         description: 'Amount paid from checkout page!',
        //         card_name: cardDetails.cardName,
        //         card_number: cardDetails.cardNumber,
        //         card_cvc: cardDetails.cvv,
        //         card_exp_month: cardDetails.expiryMonth,
        //         card_exp_year: cardDetails.expiryYear,
        //         card_token: true,
        //         message: 'Manual Payment!',
        //         card_address_line1: cardDetails.cardAddress,
        //         card_address_line2: cardDetails.cardAddress1,
        //         card_address_city: cardDetails.cardCity,
        //         card_address_state: cardDetails.cardState,
        //         card_address_country: cardDetails?.cardCountry == '38' ? 'CA' : 'US',
        //         card_address_zip: cardDetails.cardZip,
        //         billing_details: {
        //             email: user.email,
        //             name: user.first_name,
        //             address: {
        //                 line1: user.address1,
        //                 line2: user.address2,
        //                 city: user.city,
        //                 state: user.state,
        //                 country: user?.country == '38' ? 'CA' : 'US',
        //                 postal_code: user.zip,
        //             },
        //         },
        //     }
        // } else {
        //     body = {
        //         source: cardData,
        //         customer: user.card_paymentid,
        //         currency: user?.country == 38 ? 'cad' : 'usd',
        //         description: 'Card checkout',
        //     }
        //}
        // if (saveCard) {
        //     let newCard = {
        //         account_id: process.env.REACT_APP_AUCTIONPAYID,
        //         customer_id: user ? user.card_paymentid : '',
        //         cvv: '',
        //         card_address_zip: cardDetails.cardZip,
        //         card_address_country: cardDetails.cardCountry == '38' ? 'CA' : 'US',
        //         is_primary: '',
        //         user_id: '',
        //         id: '',
        //         card_token: true,
        //         card_name: cardDetails.cardName,
        //         card_number: cardDetails.cardNumber,
        //         card_cvc: cardDetails.cvv,
        //         card_exp_month: cardDetails.expiryMonth,
        //         card_exp_year: cardDetails.expiryYear,
        //         card_address_line1: cardDetails.cardAddress,
        //         card_address_line2: cardDetails.cardAddress1,
        //         card_address_city: cardDetails.cardCity,
        //         card_address_state: cardDetails.cardState,
        //     }
        //     createStripeCard(newCard)
        // }
        var ship_local = [],
            discount_details = [],
            need_discount = 0,
            transfer_amount_details = [],
            transfer_location = 0,
            wallet_amount = 0,
            referel_plugin = global?.pluginConfiguration?.referral?.enable == 1 ? 1 : 0

        items.map((val) => {
            ship_local.push({
                id: type === 'buynow' ? val.cart_id : val.buynow_id,
                hasshipping: val.temp_hasshipping || val.hasshipping,
            })
            if (parseInt(val?.discount_amount ? val.discount_amount : 0) > 0) {
                discount_details.push({
                    project_id: val.product_id,
                    amount: val.discount_amount,
                })
                need_discount = 1
            }
            if (parseInt(val?.transfer_fee_location ? val.transfer_fee_location : 0) > 0) {
                transfer_amount_details.push({
                    project_id: val.product_id,
                    amount: val.transfer_fee_location,
                })
                transfer_location = 1
            }
        })

        if (
            global?.pluginConfiguration?.referral?.enable == 1 &&
            parseFloat(amountNeedTopay) > 0.5 &&
            walletChecked
        ) {
            wallet_amount = parseFloat(user?.wallet_amount)
            referel_plugin = 1
        } else {
            if (
                parseFloat(amountNeedTopay) < 0.5 &&
                parseFloat(amountNeedTopay) > 0.0 &&
                global?.pluginConfiguration?.referral?.enable == 1 &&
                walletChecked
            ) {
                setAlert(
                    'Total amount should be greater than or equal to $0.50.Your total amount is $' +
                        parseFloat(amountNeedTopay).toFixed(2) +
                        'after deduction from walletamount.',
                    'error',
                )
                setLoading(false)
                return false
            }
        }

        if (type === 'buynow') {
            successAfterPaypaypalBuynow({
                cart_ids: JSON.stringify(cart_ids),
                payment_details: body,
                shipping_addr_id: selectAddress,
                user_country: user?.country == '38' ? 'CA' : 'US',
                ship_local,
                shipping_inside: shipping,
                transfer_location: transfer_location,
                transfer_amount_details: transfer_amount_details,
                wallet_amount: wallet_amount,
                referel_plugin: referel_plugin,
                wallet: walletChecked ? 1 : 0,
                wallet_spend: walletSpend,
                user_id: user?.id,
                shipping_amount_from_shipstation: global.pluginConfiguration?.shipping?.enable
                    ? items.map((val) => {
                          return {
                              id: type === 'buynow' ? val.cart_id : val.buynow_id,
                              shipping_fee: val.shipping_fee,
                          }
                      })
                    : [],
                success_redirect_url: window.location.href + '/paypalsuccess',
                cancel_redirect_url: window.location.href + '/paypalcancel',
            })
        }
        if (type === 'auction') {
            successAfterPaypaypalAuction({
                invoice_id,
                payment_details: body,
                shipping_addr_id: selectAddress,
                user_country: user?.country == '38' ? 'CA' : 'US',
                ship_local,
                shipping_inside: shipping,
                need_discount: need_discount,
                discount_details: discount_details,
                transfer_location: transfer_location,
                transfer_amount_details: transfer_amount_details,
                wallet_amount: wallet_amount,
                referel_plugin: referel_plugin,
                wallet: walletChecked ? 1 : 0,
                wallet_spend: walletSpend,
                user_id: user?.id,
                success_redirect_url: window.location.href.includes('?')
                    ? window.location.href.split('?')[0] + '/paypalsuccess'
                    : '',
                cancel_redirect_url: window.location.href.includes('?')
                    ? window.location.href.split('?')[0] + '/paypalcancel'
                    : '',
            })
        }
        //}
    }

    const cvvVal = Yup.object({
        cvv: Yup.string()
            .matches(/^[0-9]+$/, 'Invalid CVV')
            .required('Please enter CVV')
            .min(3, 'Please enter correct format')
            .max(4, 'Please enter correct format'),
    })

    const cvvFormik = useFormik({
        initialValues: {
            cvv: '',
        },
        validationSchema: cvvVal,
        onSubmit: async (values) => {
            console.log(cardData)
            setLoading(true)
            const records = {
                record: {
                    fields: {
                        cvv: values.cvv,
                        is_cvv_available: true,
                    },
                },
            }

            await updateSkyflowCard({
                cvv: true,
                records,
                skyflow_id: cardData.id,
            })

            await savePaymentMethodSkyflow({ skyflow_id: cardData.id, project: 'skyflow' })
            handleSubmit()
        },
    })

    const cvvForm = {
        formik: cvvFormik,
        data: [
            {
                name: 'cvv',
                type: 'text',
                placeholder: 'Enter your CVV',
                class: 'col-6',
                autoFocus: true,
            },
        ],
    }

    useEffect(() => {
        if (responseStatusCard) {
            if (responseStatusCard.from === 'skyflow_add') {
                if (responseStatusCard.status === 'success') {
                    getSkyflowCards()
                }
            }
        }
    }, [responseStatusCard])

    const handleSubmitBank = async (event) => {
        event.preventDefault()
        var index_pos
        allBankData.map((val, index) => {
            if (val.id == cardData) {
                index_pos = index
            }
        })
        if (cardData === 'nocard') {
            setAlert('Please Select Bank Details', 'error')
            return false
        } else if (allBankData[index_pos].status != 'verified') {
            setAlert('Please Verifiy Bank Details', 'error')
            setBankVarify(true)
            return false
        } else {
            setLoading(true)
            transferamount({
                site_id: global.storeDetails.site_id ? global.storeDetails.site_id : '',
                user_id: user.id ? user.id : '',
                payAmount: parseInt(parseFloat(total_payable_amount) * 100),
            })
        }
    }

    useEffect(() => {
        if (responseStatusPayment) {
            if (
                responseStatusPayment.from === 'successAfterPay' ||
                responseStatusPayment.from === 'successAfterPayAuction'
            ) {
                if (responseStatusPayment.status === 'success') {
                    loadUser()
                    setOpen(true)
                } else {
                    setAlert(responseStatusPayment.message, 'error')
                    setLoading(false)
                }
            }
        }
    }, [responseStatusPayment])

    const onTextChange = (e) => {
        setCardDetails({ ...cardDetails, [e.target.name]: e.target.value })
        let errorR = error
        delete errorR[e.target.name]
        setError({ ...errorR })
    }

    const bankvalidationarray = Yup.object({
        bank_account_holder_name: Yup.string().required('Required'),
        bank_account_holder_type: Yup.string().required('Required'),
        bank_routing_number: Yup.string().required('Required').min(9, 'Minimum 9 digits'),
        bank_account_number: Yup.string().required('Required'),
    })
    const bank_verfication_popup = Yup.object({
        payAmount1: Yup.string().required('Required'),
        payAmount2: Yup.string().required('Required'),
    })

    const bank_verification_formik = useFormik({
        initialValues: {
            payAmount1: '',
            payAmount2: '',
            site_id: '',
            user_id: '',
        },
        validationSchema: bank_verfication_popup,
        onSubmit: (values) => {
            setBtnLoading(true)
            values.site_id = global.storeDetails.site_id ? global.storeDetails.site_id : ''
            values.user_id = user.id ? user.id : ''
            verifyBankdetails(values)
        },
    })

    const bankformik = useFormik({
        initialValues: {
            account_id: user?.company_name ? user.company_name : '',
            bank_country: user?.country == 38 ? 'CA' : 'us',
            bank_currency: user?.country == 38 ? 'cad' : 'usd',
            bank_account_holder_name: '',
            bank_account_holder_type: '',
            bank_routing_number: '',
            bank_account_number: '',
            bank_token: true,
        },
        validationSchema: bankvalidationarray,
        onSubmit: (values) => {
            setBtnLoading(true)
            var data = {
                site_id: global.storeDetails.site_id ? global.storeDetails.site_id : '',
                email: user.email ? user.email : '',
                user_id: user.id ? user.id : '',
                holdername: values.bank_account_holder_name,
            }
            CraeteBankCustomer(data)
        },
    })
    const bank_details = {
        formik: bankformik,
        data: [
            {
                label: 'Holder Name',
                name: 'bank_account_holder_name',
                type: 'text',
                placeholder: 'Enter your bank holder name',
                class: 'col-12 col-sm-6',
                autoFocus: true,
            },
            {
                label: 'Account Type',
                placeholder: 'Select your type',
                class: 'col-12 col-sm-6',
                type: 'select',
                options: [
                    {
                        show: 'Individual',
                        value: 'individual',
                    },
                    {
                        show: 'Company',
                        value: 'company',
                    },
                ],
                name: 'bank_account_holder_type',
            },
            {
                label: 'Routing Number',
                name: 'bank_routing_number',
                type: 'text',
                placeholder: 'Enter routing number',
                class: 'col-12 col-sm-6',
            },
            {
                label: 'Account Number',
                name: 'bank_account_number',
                type: 'text',
                placeholder: 'Enter account number',
                class: 'col-12 col-sm-6',
            },
        ],
    }
    const bank_verification = {
        formik: bank_verification_formik,
        data: [
            {
                label: 'Amount 1',
                name: 'payAmount1',
                type: 'text',
                placeholder: 'Enter amount1',
                class: 'col-6',
                autoFocus: true,
            },
            {
                label: 'Amount 2',
                name: 'payAmount2',
                type: 'text',
                placeholder: 'Enter your amount1',
                class: 'col-6',
            },
        ],
    }
    const Openpopup = (e) => {
        bankformik.values.bank_account_holder_name = ''
        bankformik.values.bank_account_holder_type = ''
        bankformik.values.bank_routing_number = ''
        bankformik.values.bank_account_number = ''
        setOpen(true)
    }
    const Verifypopup = (e) => {
        setBankVarify(true)
    }
    const getLocationData = () => {
        var body = {
            countryCode: cardDetails.cardCountry,
        }

        return axios
            .post(`${process.env.REACT_APP_URL}fetchLocation`, body)
            .then(function (response) {
                if (response.data.success) {
                    setStateList(response.data.state)
                    setCountryList(response.data.country)
                }
            })
            .catch(function (error) {
                // console.log(error)
            })
    }

    useEffect(() => {
        getLocationData()
    }, [cardDetails.cardCountry])
    return (
        <div className="ptp-wrapper">
            {user ? (
                <>
                    <h2 className="od-head">Payment Details</h2>
                    <div className="chkcard">
                        <span>
                            <p>Total Amount</p>
                            <p>: {currencyFormat(amount, currency)}</p>
                        </span>
                        {buyers_premium != 0 ? (
                            <span>
                                <p>{"Buyer's Premium"}</p>
                                <p>: {currencyFormat(buyers_premium, currency)}</p>
                            </span>
                        ) : null}
                        <span>
                            <p>Sales Tax</p>
                            <p>
                                :{' '}
                                {currencyFormat(
                                    shipping_fee != 0 ? sales_tax : local_pick_sales_tax,
                                    currency,
                                )}
                            </p>
                        </span>
                        {transaction_fee != 0 ? (
                            <span>
                                <p>Transaction Fee</p>
                                <p>: {currencyFormat(transaction_fee, currency)}</p>
                            </span>
                        ) : null}
                        {shipping_fee != 0 ? (
                            <span>
                                <p>Shipping Fee</p>
                                <p>: {currencyFormat(shipping_fee, currency)}</p>
                            </span>
                        ) : null}
                        {parseFloat(discount_amount ? discount_amount : 0) > 0 &&
                        global.pluginConfiguration?.coupon_code?.enable ? (
                            <span>
                                <p>Discount Amount</p>
                                <p>: {'-' + currencyFormat(discount_amount, currency)}</p>
                            </span>
                        ) : null}
                        <span>
                            <p>Grand Total </p>
                            <p>
                                :{' '}
                                {currencyFormat(
                                    shipping_fee != 0
                                        ? total_payable_amount + transaction_fee
                                        : local_pick_total_payable_amount + transaction_fee,
                                    currency,
                                )}
                            </p>
                        </span>
                    </div>

                    {global.storeConfigration?.address_book?.value == 1 &&
                    Array.isArray(items) &&
                    items.find((val) => (val.temp_hasshipping || val.hasshipping) == 1) ? (
                        <div className="od-box">
                            <CustomSelect
                                label={'Shipping Inside Metro Vancouver Region'}
                                value={shipping}
                                onChange={(e) => {
                                    var temp = items
                                    temp.map((val) => {
                                        if (val.shipping_fee != 0) {
                                            val.temp_shipping_fee = val.shipping_fee
                                            val.total_payable_amount =
                                                val.total_payable_amount - val.temp_shipping_fee
                                        } else {
                                            val.total_payable_amount =
                                                val.total_payable_amount + val.temp_shipping_fee
                                        }
                                        val.inside_vancouver = e.target.value
                                        val.shipping_fee =
                                            e.target.value == 'yes' ? val.temp_shipping_fee : 0
                                        setItems([...temp])
                                    })
                                    setShipping(e.target.value)
                                }}
                            >
                                <option value="yes">Yes</option>
                                <option value="no">No</option>
                            </CustomSelect>
                            {shipping === 'no' && (
                                <p>{global.storeConfigration?.outside_vancouver_message?.value}</p>
                            )}
                        </div>
                    ) : null}
                    {global.storeConfigration?.address_book?.value == 1 &&
                    shipping_fee &&
                    shipping == 'yes' ? (
                        <>
                            <h2 className="od-head">Shipping Address</h2>
                            <div className="od-box">
                                <CustomSelect
                                    label={'Select Shipping Address'}
                                    value={selectAddress}
                                    onChange={(e) => setSelectAddress(e.target.value)}
                                >
                                    {all_shipping_address.map((val) => (
                                        <option value={val.id}>{val.address}</option>
                                    ))}
                                </CustomSelect>
                                <div className="form-group mt-2">
                                    <button
                                        type="submit"
                                        className="pink-btn"
                                        onClick={() => setAddAddressOpen(true)}
                                    >
                                        Add Address
                                    </button>
                                </div>
                            </div>
                        </>
                    ) : null}
                    <div className="od-box mt-2">
                        {global.storeDetails.theme == 4 ? (
                            <CustomSelect
                                label={'Select Payment Option'}
                                // id={data.id}
                                value={paymentoption}
                                //autoFocus={data.autoFocus}
                                //name={data.filter ? `filters.${data.name}.value` : data.name}
                                // size={data.size}
                                onChange={(e) => {
                                    if (allBankData.length == 0 && e.target.value == 2) {
                                        setIsOpen(true)
                                        setPaymentoption(e.target.value)
                                    } else {
                                        setPaymentoption(e.target.value)
                                    }
                                }}
                                // placeholder={data.placeholder}
                                // disabled={data.disabled}
                                // onBlur={formik.handleBlur}
                                // type={data.type}
                                // error={errorCheck(data, formik)}
                                // helperText={helperText(data, formik)}
                                // upperLabel={data.upperLabel}
                            >
                                <option value={1}>Credit Card</option>
                                <option value={2}>Bank</option>
                            </CustomSelect>
                        ) : (
                            ''
                        )}
                        {paymentoption == 1 ? (
                            <form onSubmit={handleSubmit}>
                                {allData &&
                                    allData.map((data, index) => (
                                        <div key={index} className="cardSelect">
                                            <label htmlFor={`ritemb${index}`} className="cardmain">
                                                <div className="flx">
                                                    <p>{'xxxx-xxxx-xxxx-' + data.last4}</p>
                                                    <div className="radio-item">
                                                        <input
                                                            type="checkbox"
                                                            id={`ritemb${index}`}
                                                            name="ritem"
                                                            value={data.id}
                                                            checked={
                                                                cardData.id === data.id ||
                                                                (defaultCard?.id === data.id &&
                                                                    defaultCard?.currDefault)
                                                            }
                                                            onChange={onChange}
                                                        />
                                                        {/* <span className="vcRad"></span> */}
                                                    </div>
                                                </div>
                                                <div className="flx">
                                                    <p>{data.brand}</p>
                                                    <p>
                                                        {data.exp_month}/{data.exp_year}
                                                    </p>
                                                </div>
                                                {/* {(cardData === data.id ||
                                                    (defaultCard?.id === data.id &&
                                                        defaultCard?.currDefault)) && (
                                                    // <div className="row">
                                                    //     <div className="col-6 form-group">
                                                    //         <Inputfield
                                                    //             title="CVV"
                                                    //             name="cvv"
                                                    //             type="text"
                                                    //             placeholder="Enter your CVV"
                                                    //             onChange={(e) =>
                                                    //                 setCvv(e.target.value)
                                                    //             }
                                                    //             value={cvv}
                                                    //             autoFocus={true}
                                                    //             msg={error.cvv || ''}
                                                    //         />
                                                    //     </div>
                                                    // </div>
                                                    <div className="row">{mapData(cvvForm)}</div>
                                                )} */}
                                            </label>
                                        </div>
                                    ))}
                                {cardData === 'nocard' &&
                                global.storeConfigration?.skyflow_integration?.value == 1 ? (
                                    <>
                                        <div
                                            className="emptyCard d-flex justify-content-between flex-column"
                                            onClick={() =>
                                                toggleFullScreenCardPopup(true, 'new', null)
                                            }
                                        >
                                            <div>
                                                <span>
                                                    <img src="/assets/svg/emptyCard.svg" />
                                                </span>
                                                <h2>ADD A NEW CARD</h2>
                                            </div>
                                        </div>
                                    </>
                                ) : (
                                    ''
                                )}
                                {cardData !== 'nocard' && !cardData.is_cvv_available && (
                                    <>
                                        <h5>
                                            For security reasons, you are required to enter CVV
                                            once. This is an one time requirement for already added
                                            cards.
                                        </h5>
                                        <div>{mapData(cvvForm)}</div>
                                    </>
                                )}
                                <div className="text-center form-group mt-2">
                                    <button
                                        className="pink-btn"
                                        onClick={cvvFormik.handleSubmit}
                                        disabled={
                                            loading ||
                                            cardData === 'nocard' ||
                                            (cardData.is_cvv_available === false &&
                                                cvvFormik.values.cvv === '')
                                        }
                                    >
                                        {loading
                                            ? 'Loading...'
                                            : `Pay $ ${
                                                  shipping_fee != 0
                                                      ? total_payable_amount.toUSFormat()
                                                      : local_pick_total_payable_amount.toUSFormat()
                                              }`}
                                    </button>
                                </div>
                            </form>
                        ) : (
                            <form onSubmit={handleSubmitBank}>
                                {allBankData &&
                                    allBankData.map((data, index) => (
                                        <div key={index} className="cardSelect">
                                            <label htmlFor="ritemb" className="cardmain">
                                                <div className="flx">
                                                    <p>{data.bank_name}</p>
                                                    <div className="radio-item">
                                                        <input
                                                            type="checkbox"
                                                            id="ritemb"
                                                            name="ritem"
                                                            value={data.id}
                                                            checked={cardData === data.id}
                                                            onChange={onChange}
                                                        />
                                                        {/* <span className="vcRad"></span> */}
                                                    </div>
                                                </div>
                                                <div className="flx">
                                                    <p>{data.account_holder_name}</p>
                                                    <p>
                                                        {data.status == 'new' ||
                                                        data.status == 'verification_failed' ? (
                                                            <Button
                                                                onClick={(e) => Verifypopup(e)}
                                                                className="edit"
                                                            >
                                                                <span className="material-icons">
                                                                    verify
                                                                </span>
                                                                VERIFY
                                                            </Button>
                                                        ) : data.status == 'verified' ? (
                                                            <Button className="edit">
                                                                <span className="material-icons">
                                                                    verify
                                                                </span>
                                                                VERIFIED
                                                            </Button>
                                                        ) : (
                                                            ''
                                                        )}
                                                    </p>
                                                </div>
                                            </label>
                                        </div>
                                    ))}
                                {/* {cardData === 'nocard' && (
                                    <div>
                                        <div className="form-group">
                                            <Inputfield
                                                title="Card Number"
                                                name="cardNumber"
                                                type="text"
                                                placeholder="Enter your card number"
                                                length="16"
                                                onChange={onTextChange}
                                                value={cardDetails.cardNumber || ''}
                                                autoFocus={true}
                                                msg={error.cardNumber || ''}
                                            />
                                        </div>
                                        <div className="row">
                                            <div className="col-6 form-group">
                                                <Inputfield
                                                    title="Expiry Month"
                                                    name="expiryMonth"
                                                    type="text"
                                                    length="2"
                                                    onChange={onTextChange}
                                                    placeholder="Expiration Month"
                                                    value={cardDetails.expiryMonth || ''}
                                                    msg={error.expiryMonth || ''}
                                                />
                                            </div>
                                            <div className="col-6 form-group">
                                                <Inputfield
                                                    title="Expiry Year"
                                                    name="expiryYear"
                                                    type="text"
                                                    length="4"
                                                    onChange={onTextChange}
                                                    placeholder="Expiration Year"
                                                    value={cardDetails.expiryYear || ''}
                                                    msg={error.expiryYear || ''}
                                                />
                                            </div>
                                            <div className="col-6 form-group">
                                                <Inputfield
                                                    title="CVV"
                                                    name="cvv"
                                                    onChange={onTextChange}
                                                    type="text"
                                                    length="4"
                                                    placeholder="Enter your CVV"
                                                    value={cardDetails.cvv || ''}
                                                    msg={error.cvv || ''}
                                                />
                                            </div>
                                        </div>
                                        <input
                                            id="saveCard"
                                            type="checkbox"
                                            onChange={onCheckBoxChange}
                                            checked={saveCard}
                                        />
                                        <label style={{ marginLeft: 10 }} htmlFor="saveCard">
                                            Save Card
                                        </label>
                                    </div>
                                )} */}
                                <div className="text-center form-group mt-2">
                                    <button type="submit" className="pink-btn" disabled={loading}>
                                        {loading
                                            ? 'Loading...'
                                            : `Pay $ ${
                                                  shipping_fee != 0
                                                      ? total_payable_amount.toUSFormat()
                                                      : local_pick_total_payable_amount.toUSFormat()
                                              }`}
                                    </button>
                                </div>
                            </form>
                        )}
                    </div>
                    {open && (
                        <Popup
                            open={open}
                            size="md"
                            className="save-search-modal"
                            handleClose={handleClose}
                            modaltitle="Order Successful"
                        >
                            <div className="order-success-wrap orPlcdSs text-center">
                                <img src="/image/check_circle.svg" alt="" />
                                <p className="os-text">
                                    Congratulations - You have purchased&nbsp;
                                </p>
                                <ul className="os-text pdtNmLst">
                                    {items.map((data, index) => (
                                        <li>
                                            <b>
                                                <span key={index}>{data.title}</span>
                                            </b>
                                        </li>
                                    ))}
                                </ul>
                                {static_page.content ? (
                                    <div
                                        dangerouslySetInnerHTML={{
                                            __html: static_page.content,
                                        }}
                                    ></div>
                                ) : (
                                    <>
                                        <p>
                                            Shipping
                                            {items.length &&
                                            items.filter((ele) => ele.localpickup == 1)?.length != 0
                                                ? '/Pick-up'
                                                : ''}{' '}
                                            Information will be sent to your emails inbox.
                                        </p>
                                        <p>To download your invoice, visit your Profile.</p>
                                    </>
                                )}
                            </div>
                        </Popup>
                    )}
                </>
            ) : null}
            <Popup
                open={isOpen}
                handleClose={(e) => setIsOpen(false)}
                size="md"
                className="save-search-modal"
                modaltitle="Bank Details"
            >
                <>
                    <div className="row">{mapData(bank_details)}</div>

                    <div className="actionWrapper">
                        <div className="row">
                            <div className="col-sm-3 col-12"></div>
                            <div className="col-md-6">
                                <PrimaryButton
                                    onClick={(e) => bankformik.handleSubmit(e)}
                                    type="button"
                                    disabled={btnLoading}
                                    label={btnLoading ? 'Loading...' : 'ADD'}
                                />
                            </div>
                        </div>
                    </div>
                </>
            </Popup>
            <Popup
                open={addAddressOpen}
                handleClose={(e) => setAddAddressOpen(false)}
                size="md"
                className="save-search-modal"
                modaltitle="Add Shipping Address"
            >
                <>
                    <AddressBook
                        closePopup={1}
                        closePopupFunction={(e) => setAddAddressOpen(false)}
                        refreshAddress={1}
                    />
                </>
            </Popup>
            <Popup
                open={bank_verify_open}
                handleClose={(e) => setBankVarify(false)}
                size="md"
                className="save-search-modal"
                modaltitle="Verify Bank Details"
            >
                <>
                    <div className="row">{mapData(bank_verification)}</div>

                    <div className="actionWrapper">
                        <div className="row">
                            <div className="col-sm-3 col-12"></div>
                            <div className="col-md-6">
                                <PrimaryButton
                                    onClick={(e) => bank_verification_formik.handleSubmit(e)}
                                    type="button"
                                    disabled={btnLoading}
                                    label={btnLoading ? 'Loading...' : 'VERIFY'}
                                />
                            </div>
                        </div>
                    </div>
                </>
            </Popup>
            <div className="PaymentModal">
                <CardDetails
                    allCards={allData}
                    data={manageCard}
                    function={toggleFullScreenCardPopup}
                ></CardDetails>
            </div>
        </div>
    )
}

export default MakePaymentComponent
