import React, { useRef, useState, useContext } from 'react'
import Popup from '../Product/components/organisms/Popup'
import { Link, useHistory } from 'react-router-dom'
import SecondaryButton from '../Product/components/atoms/SecondaryButton'
import AuthContext from '../Product/context/auth/authContext'
// import { useIdleTimer } from 'react-idle-timer'
import { handleRedirectInternal } from '../Product/common/components'
const IdleTimerContainer = (props) => {
    const idleTimerRef = useRef(null)
    const sessionTimeOutRef = useRef(null)
    const [isOpen, setIsOpen] = useState(false)
    const authContext = useContext(AuthContext)
    let history = useHistory()
    const { user, logout, isAuthenticated } = authContext
    const onIdle = () => {
        if (isAuthenticated) {
            setIsOpen(true)
            sessionTimeOutRef.current = setTimeout(logout1, 60000)
        }
    }
    const time_out = parseInt(global.storeConfigration.session_timeout.value)
    const time_out_value = 60000 * time_out
    const stayActive = () => {
        setIsOpen(false)
        clearTimeout(sessionTimeOutRef.current)
    }
    const logout1 = () => {
        clearTimeout(sessionTimeOutRef.current)
        logout()
        history.push('/login')
        window.location.reload()
    }
    // const idleTimer = useIdleTimer({
    //     onIdle,
    //     timeout: time_out_value,
    // })
    return (
        <div>
            {/* <IIdleTimer
                ref={idleTimerRef}
                timeout={
                    parseInt(60000) *
                    parseInt(
                        global?.storeDetails?.session_timeout
                            ? global.storeDetails.session_timeout
                            : 0,
                    )
                }
                onIdle={onIdle}
            ></IIdleTimer> */}

            <Popup open={isOpen} modaltitle="Inactive warning!" handleClose={stayActive}>
                <div>
                    <div>
                        <h2>{"You've been idle for a while!"}</h2>
                        <p>You will be logged out soon</p>
                    </div>
                    <div className="modalCTA d-flex justify-content-end align-items-center">
                        <a className="modCancelBtn" onClick={stayActive}>
                            Keep me signed in
                        </a>
                        <SecondaryButton label="Log me out" onClick={() => logout1()} />
                    </div>
                </div>
            </Popup>
        </div>
    )
}

export default IdleTimerContainer
