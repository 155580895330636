import React from 'react'
import Layout from '../Layout'
import DashboardLayout from '../DashboardLayout'
import SASLoginFunction from '../../../utils/CommonFunctionality/SASLoginFunction'

const SASLogin = (props) => {
    return (
        <Layout props={props}>
            <DashboardLayout title="SAS Auction Account">
                <SASLoginFunction props={props} />
            </DashboardLayout>
        </Layout>
    )
}

export default SASLogin
