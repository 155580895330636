import React, { useState, useRef, useContext, useEffect } from 'react'
import { Button } from '@material-ui/core'
import { Formik } from 'formik'
import * as Yup from 'yup'
import _ from 'lodash'
import './Addnewcard.css'
import customerContext from '../../../Product/context/stripe/customer/customerContext'
import authContext from '../../../Product/context/auth/authContext'
import alertContext from '../../../Product/context/alert/alertContext'
import PrimaryButton from '../../../Product/components/atoms/PrimaryButton'
import CustomInput from '../../../Product/components/atoms/Inputs/CustomInput'
import CustomSelect from '../../../Product/components/atoms/Inputs/CustomSelect'
import Popup from '../../../Product/components/organisms/Popup/achpopup'

const Addnewcard = (props) => {
    const Data = ['Card Number', 'MM', 'YYYY', 'Name on the Card', 'Cvv']

    const [modalOpen, setmodalOpen] = useState(false)
    const [Id, setID] = useState('')
    const [sId, setStateId] = useState('')
    const [Countrycode, setCountrycode] = useState('')
    const [formikValues, setFormikValues] = useState({})
    const [loading, setLoading] = useState(false)
    const { createBankCustomer, BuyerAddBank, verifyBankdetails, responseStatus } =
        useContext(customerContext)
    const { user } = useContext(authContext)
    const { setAlert } = useContext(alertContext)
    const modalClose = () => {
        setmodalOpen(false)
    }

    const formRef = useRef()

    const addcardapi = async (values) => {
        setLoading(true)
        try {
            var data = {
                site_id: global?.storeDetails?.site_id,
                email: user.email ? user.email : '',
                user_id: user.id ? user.id : '',
                holdername: values.bank_account_holder_name,
            }
            setFormikValues(values)
            createBankCustomer(data)
        } catch (error) {
            console.log(error)
        }
    }
    useEffect(() => {
        if (responseStatus) {
            console.log(responseStatus, 'responseStatus')

            if (responseStatus.from === 'add_bank_customer') {
                if (responseStatus.status === 'success') {
                    if (responseStatus.message == 'Customer account already created!') {
                        var bank_data = {
                            site_id: global?.storeDetails?.site_id,
                            user_id: user.id ? user.id : '',
                            account_no: formikValues.bank_account_number,
                            rounting_no: formikValues.bank_routing_number,
                            holdername: formikValues.bank_account_holder_name,
                        }
                        BuyerAddBank(bank_data)
                        setLoading(true)
                    }
                } else {
                    setLoading(false)
                }
            }
            if (responseStatus.from === 'add_bank_buyer') {
                if (responseStatus.status === 'success') {
                    props.upd()
                    setmodalOpen(false)
                    setAlert('Bank Details Added Successfully', 'success')
                } else {
                    setmodalOpen(false)
                    setAlert(responseStatus.message, 'error')
                }
                setLoading(false)
            }
            if (responseStatus.from === 'verify_bank_buyer') {
                if (responseStatus.status === 'success') {
                    props.setBankVerifyPopup(false)
                    props.update_card()
                    setAlert('Bank Verified Successfully', 'success')
                } else {
                    props.setBankVerifyPopup(false)
                    setAlert(responseStatus.message, 'error')
                }
                setLoading(false)
            }
        }
    }, [responseStatus])
    const verifyapi = async (values) => {
        setLoading(true)
        try {
            values.site_id = global?.storeDetails?.site_id
            values.user_id = user?.id
            verifyBankdetails(values)
        } catch (error) {
            console.log(error)
        }
    }

    const addcard = Yup.object({
        bank_account_holder_name: Yup.string().required('Required'),
        bank_account_holder_type: Yup.string().required('Required'),
        bank_routing_number: Yup.string().required('Required').min(9, 'Minimum 9 digits'),
        bank_account_number: Yup.string().required('Required'),
    })

    const bankcard = Yup.object({
        payAmount1: Yup.string().required('Required'),
        payAmount2: Yup.string().required('Required'),
    })

    return (
        <div className="addNewcard-parent adBankPopup">
            <Button onClick={() => setmodalOpen(true)} className={`adBnkBtns`}>
                <div className="Addcard-Payment w-100">
                    <h6 className="m-0 d-flex align-items-center">
                        <span className="material-icons mr-2">account_balance</span>ADD A NEW BANK
                        DETAILS
                    </h6>
                </div>
            </Button>
            <Popup modaltitle="Add Bank Details" open={modalOpen} handleClose={() => modalClose()}>
                <Formik
                    initialValues={{
                        bank_account_holder_name: '',
                        bank_account_holder_type: '',
                        bank_routing_number: '',
                        bank_account_number: '',
                    }}
                    validationSchema={addcard}
                    innerRef={formRef}
                    onSubmit={(values) => {
                        addcardapi(values)
                    }}
                >
                    {({
                        errors,
                        touched,
                        values,
                        handleChange,
                        handleBlur,
                        handleSubmit,
                        setFieldValue,
                    }) => (
                        <form onSubmit={handleSubmit}>
                            <div className="AddCard-Popup-Banner">
                                <div className="Addcard-Popup-Input">
                                    <div className="row">
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <CustomInput
                                                    label="Holder Name"
                                                    variant="standard"
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    name="bank_account_holder_name"
                                                    helperText={
                                                        errors.bank_account_holder_name &&
                                                        touched.bank_account_holder_name
                                                            ? 'Please Enter Name'
                                                            : ''
                                                    }
                                                    type="text"
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            {console.log(
                                                'ppppppppppppppppppppppppppppppppppppppppppppppppppppppp',
                                                values.bank_account_holder_type,
                                            )}
                                            <div className="form-group cstmActSlct">
                                                <CustomSelect
                                                    variant={'standard'}
                                                    //label={'Account Type'}
                                                    value={values.bank_account_holder_type}
                                                    onChange={(e) =>
                                                        setFieldValue(
                                                            'bank_account_holder_type',
                                                            e.target.value,
                                                        )
                                                    }
                                                    helperText={
                                                        errors.bank_account_holder_type &&
                                                        touched.bank_account_holder_type
                                                            ? 'Select Account Type'
                                                            : ''
                                                    }
                                                >
                                                    <option value={''}>Select Account Type</option>

                                                    <option value={'personalcheckings'}>
                                                        {'Personal Checkings'}
                                                    </option>
                                                    <option value={'personalsavings'}>
                                                        {'Personal Savings'}
                                                    </option>
                                                    <option value={'businesschecking'}>
                                                        {'Business Checking'}
                                                    </option>
                                                    <option value={'personalsavings'}>
                                                        {'Business Savings'}
                                                    </option>
                                                </CustomSelect>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            {/* <div className="row"> */}
                                            <div className="form-group">
                                                <CustomInput
                                                    label="Routing Number"
                                                    variant="standard"
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    name="bank_routing_number"
                                                    //inputProps={{ maxLength: 2 }}
                                                    helperText={
                                                        errors.bank_routing_number &&
                                                        touched.bank_routing_number
                                                            ? 'Enter Valid Routing Number'
                                                            : ''
                                                    }
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <CustomInput
                                                    label="Account Number"
                                                    variant="standard"
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    name="bank_account_number"
                                                    //inputProps={{ maxLength: 4 }}
                                                    helperText={
                                                        errors.bank_account_number &&
                                                        touched.bank_account_number
                                                            ? 'Invalid Account Number'
                                                            : ''
                                                    }
                                                />
                                            </div>
                                            {/* </div> */}
                                        </div>
                                    </div>
                                </div>
                                <PrimaryButton disabled={loading} label="Add" type="submit" />
                            </div>
                        </form>
                    )}
                </Formik>
            </Popup>
            <Popup
                modaltitle="Account Verification"
                open={props.bankverifypopup}
                handleClose={() => props.setBankVerifyPopup(false)}
            >
                <Formik
                    initialValues={{
                        payAmount1: '',
                        payAmount2: '',
                        site_id: '',
                        user_id: '',
                    }}
                    validationSchema={bankcard}
                    innerRef={formRef}
                    onSubmit={(values) => {
                        verifyapi(values)
                    }}
                >
                    {({
                        errors,
                        touched,
                        values,
                        handleChange,
                        handleBlur,
                        handleSubmit,
                        setFieldValue,
                    }) => (
                        <form onSubmit={handleSubmit}>
                            <div className="AddCard-Popup-Banner">
                                <div className="Addcard-Popup-Input">
                                    <div className="row">
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <CustomInput
                                                    label="Amount 1"
                                                    variant="standard"
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    name="payAmount1"
                                                    helperText={
                                                        errors.payAmount1 && touched.payAmount1
                                                            ? 'Please Enter Amount'
                                                            : ''
                                                    }
                                                    type="text"
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <CustomInput
                                                    label="Amount 2"
                                                    variant="standard"
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    name="payAmount2"
                                                    helperText={
                                                        errors.payAmount2 && touched.payAmount2
                                                            ? 'Please Enter Amount'
                                                            : ''
                                                    }
                                                    type="text"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <PrimaryButton disabled={loading} label="Verify" type="submit" />
                            </div>
                        </form>
                    )}
                </Formik>
            </Popup>
        </div>
    )
}

export default Addnewcard
