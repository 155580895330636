import React, { useState, useEffect, useContext, useRef } from 'react'
import { useLocation } from 'react-router-dom'
import * as Yup from 'yup'
import ImageGallery from 'react-image-gallery'
import 'react-image-gallery/styles/css/image-gallery.css'
import moment from 'moment-timezone'
import Skeleton from 'react-loading-skeleton'
import ReadMoreReact from 'read-more-react'
import PrimaryButton from '../../../Product/components/atoms/PrimaryButton'
import Timer from '../../../Product/common/timer'
import { capitalize, handleRedirectInternal } from '../../../Product/common/components'
import ReactHtmlParser from 'react-html-parser'
import AuthContext from '../../../Product/context/auth/authContext'
import { useHistory } from 'react-router-dom'
import ProductContext from '../../../Product/context/product/productContext'
import { socket } from '../../../Product/common/socket'
const ProductAuctionTopComponent = (props) => {
    const [image, setImage] = useState([])
    const [livedate, setLiveData] = useState({ private_auction: 0, user_count: 0 })
    const { user, isAuthenticated } = useContext(AuthContext)
    const [auc_date_closed, setAuctiondateclosed] = useState('')
    const { lotauctionreg } = useContext(ProductContext)
    const viewAuctionRef = useRef(null)
    const history = useHistory()
    useEffect(() => {
        viewAuctionRef.current = props.auctionData
    })
    useEffect(() => {
        let imageUrl = `${process.env.REACT_APP_PRODUCT_IMAGE_URL}${props.auctionData.avatar}`
        setImage([{ original: imageUrl, thumbnail: imageUrl }])
        if (props.auctionData?.dynamic_fields?.includes('[{')) {
            var data_obj = {}
            JSON.parse(props.auctionData.dynamic_fields).map((data) => {
                if (data.name == 'private_auction') {
                    data_obj.private_auction = 0
                    if (data.values.length > 0) {
                        if (data.values.filter((v) => v.selected == 'true').length > 0) {
                            data_obj.private_auction = data.values.filter(
                                (v) => v.selected == 'true',
                            )[0].value
                        }
                    }
                }
                if (data.name == 'private_auction_user_limit') {
                    data_obj.private_auction_count = data.value
                }
            })
            setLiveData({
                private_auction: data_obj.private_auction,
                user_count: data_obj.private_auction_count,
            })
        } else {
            setLiveData({
                private_auction: 0,
                user_count: 0,
            })
        }
        setAuctiondateclosed(props.auctionData.date_closed)
    }, [props.auctionData])

    const register_reverse_auction = async () => {
        if (!isAuthenticated) {
            handleRedirectInternal(history, 'login')
        } else {
            let userip = ''
            var send_data = {
                auction_id: props.auctionData.id,
                user_id: user.id,
                status: 'active',
                site_id: global?.storeDetails?.site_id,
                ipaddress: userip,
            }
            lotauctionreg(send_data, props.setIsRegistered)
        }
    }

    useEffect(() => {
        socket.on('reverse_auc_closed', (data) => {
            if (parseInt(data.aid) == parseInt(viewAuctionRef.current.id)) {
                setAuctiondateclosed(data.date_closed)
            }
        })
        return () => {
            socket.off('reverse_auc_closed', (data) => {
                if (parseInt(data.aid) == parseInt(viewAuctionRef.current.id)) {
                    setAuctiondateclosed(data.date_closed)
                }
            })
        }
    }, [])

    return (
        <div className="media pv-media product-media-wrapper flex-wrap">
            <div className="media-left">
                {props.auctionData.avatar ? (
                    <ImageGallery
                        items={image}
                        thumbnailPosition="bottom"
                        showNav={false}
                        showBullets={false}
                        showFullscreenButton={true}
                        showPlayButton={false}
                        originalClass="pro-img img-fluid"
                        showThumbnails={false}
                    />
                ) : (
                    // <Skeleton  height={345} width={475} />

                    //  src = `${global?.storeDetails?.logoValue}`
                    <img src={`${global?.storeDetails?.logoValue}`} className="pro-img img-fluid" />
                )}
                {/* <img src={ `https://forwardapi.auctionsoftware.com/uploads/product/${props.auctionData.avatar}`} className="pro-img img-fluid"/> */}
            </div>
            {props.auctionData.title !== undefined && (
                <div className="media-body">
                    <div className="row">
                        <div className="col-md-8">
                            <h2
                                className="pv-title mb-4"
                                dangerouslySetInnerHTML={{
                                    __html: props.auctionData.title,
                                }}
                            />
                            {props.auctionData.date_added ? (
                                <div className="pv-con">
                                    <span>Auction Start Date: </span>
                                    {moment(props.auctionData.date_added)
                                        .tz(moment.tz.guess())
                                        .format('MMM Do YYYY, h:mm a z')}{' '}
                                </div>
                            ) : (
                                <Skeleton width={80} />
                            )}
                            {!props.auctionData.auction_type ? (
                                <div className="pv-con">
                                    <span>Auction Begins To End: </span>
                                    {moment(props.minMaxDateClosed.min_date_closed)
                                        .tz(moment.tz.guess())
                                        .format('MMM Do YYYY, h:mm a z')}
                                </div>
                            ) : (
                                ''
                            )}
                            <div className="pv-con">
                                <span>Last Item Approx Ends On: </span>
                                {moment(
                                    !props.auctionData.auction_type
                                        ? props.minMaxDateClosed.max_date_closed
                                        : auc_date_closed
                                        ? auc_date_closed
                                        : props.auctionData.date_closed,
                                )
                                    .tz(moment.tz.guess())
                                    .format('MMM Do YYYY, h:mm a z')}
                            </div>

                            {global.storeConfigration?.show_buyer_premium?.value == 1 ? (
                                <div className="pv-con">
                                    <span>{"Buyer's Premium: "}</span>
                                    {props.auctionData.la_seller_id + ' %'}
                                </div>
                            ) : (
                                ''
                            )}
                            <div className="pv-con aucDesc">
                                <span>Auction Description:</span>
                            </div>
                            {/* <ReadMoreReact */}
                            <div className="proDesct">
                                {ReactHtmlParser(props.auctionData.description)}
                            </div>
                            {/* //     min={240}
                            //     ideal={300}
                            //     max={600}
                            //     readMoreText={'READ MORE'}
                            // /> */}
                        </div>
                        <div className="col-md-4 text-center-md">
                            <div className="timer-wrapper">
                                <div>
                                    {parseInt(livedate.private_auction) == 1 ? (
                                        <>
                                            {props.is_registered == 0 &&
                                            props.auctionData.market_status == 'open' ? (
                                                <PrimaryButton
                                                    label="Register For Bidding"
                                                    onClick={(e) => register_reverse_auction()}
                                                ></PrimaryButton>
                                            ) : props.is_registered == 1 ? (
                                                <PrimaryButton
                                                    label="Enter Auction"
                                                    onClick={(e) => props.executeScroll(e)}
                                                ></PrimaryButton>
                                            ) : (
                                                ''
                                            )}
                                        </>
                                    ) : (
                                        ''
                                    )}
                                </div>
                                <div className="li-timer">
                                    <Timer
                                        date_added={props.auctionData.date_added}
                                        date_closed={
                                            auc_date_closed
                                                ? auc_date_closed
                                                : props.auctionData.date_closed
                                        }
                                        withText={1}
                                        icon={true}
                                        endText={'Ends in' + ': '}
                                        startText={'Starts in' + ': '}
                                        setUpcomming={props.setUpcomming}
                                    ></Timer>
                                </div>
                            </div>

                            {parseInt(props.auctionData.stage_id) > 0 ? (
                                <div className="ndCTA">
                                    <PrimaryButton
                                        label="Buy Tickets"
                                        onClick={() =>
                                            handleRedirectInternal(
                                                history,
                                                `ticketing/${props.auctionData.stage_id}/${props.auctionData.id}`,
                                            )
                                        }
                                    />
                                    <br></br>
                                </div>
                            ) : null}

                            <div className="ndCTA">
                                <PrimaryButton label="Browse lots" onClick={props.executeScroll} />
                                <div className="arrow">
                                    <span></span>
                                    <span></span>
                                    <span></span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    )
}

export default ProductAuctionTopComponent
