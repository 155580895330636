import React, { Component } from 'react'
import Slider from 'react-slick'
import GridAuction from '../../Component/ProductCard/gridAuction'
import './VerticalSlider.css'

const VerticalSlider = (props) => {
    const settings = {
        dots: false,
        arrows: true,
        infinite: false,
        slidesToShow: 2,
        slidesToScroll: 2,
        speed: 500,
        rows: 2,
        /* slidesPerRow: 2, */
        /* fade: true, */
        centerPadding: '60px',
        className: 'center',
        nextArrow: (
            <div>
                <span className="material-icons slCsBtn">expand_more</span>
            </div>
        ),
        prevArrow: (
            <div>
                <span className="material-icons slCsBtn">expand_less</span>
            </div>
        ),
    }

    return (
        <div>
            <Slider {...settings}>
                {props.auctionData &&
                    props.auctionData.map((data, index) => (
                        <div className="p-2">
                            <GridAuction
                                key={index}
                                data={data}
                                details={`search/product-buyer-auction/${data.id}`}
                                timerTheme="multiseller"
                                // lotCount={data.lotCount ? data.lotCount : 0}
                            />
                        </div>
                    ))}
            </Slider>
        </div>
    )
}

export default VerticalSlider
