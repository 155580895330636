import React from 'react'
import Container from '@material-ui/core/Container'
import './subscription.css'
import moment from 'moment'
import StripeCardContext from '../../../Product/context/stripe/card/cardContext'
import { useContext } from 'react'
import authContext from '../../../Product/context/auth/authContext'
import { useEffect } from 'react'
import { useState } from 'react'
import pluginContext from '../../../Product/context/plugin/pluginContext'
import alertContext from '../../../Product/context/alert/alertContext'
import { handleRedirectInternal } from '../../../Product/common/components'
import { Link, useHistory } from 'react-router-dom'
import CheckBox from '../../../Product/components/atoms/CheckBox'

const BidderSuspension = (props) => {
    const { get_all_card_details, getAllStripeCards, createStripeCard } =
        useContext(StripeCardContext)
    const { reactivateFee, responseStatus } = useContext(pluginContext)
    const { setAlert } = useContext(alertContext)
    const { user, loadUser } = useContext(authContext)
    const [allData, setAllData] = useState([])
    const [selectedCard, setSelectedCard] = useState('')
    const [loading, setLoading] = useState(false)
    const [autoPay, setAutoPay] = useState(true)
    const history = useHistory()
    const handleChange = (e) => {
        setSelectedCard(e.target.value)
    }
    const handleSubmit = () => {
        if (!selectedCard) {
            return setAlert('Kindly select card for payment!', 'warning')
        }
        reactivateFee({
            user_id: user?.id,
            site_id: global?.storeDetails?.site_id,
            payment_details: {
                source: selectedCard,
                customer: user.card_paymentid,
                currency: user?.country == 38 ? 'cad' : 'usd',
                description: 'Re-activate Fee',
                amount: parseFloat(
                    (
                        parseFloat(global?.pluginConfiguration?.bidder_suspension?.access_key) * 100
                    ).toFixed(2),
                ),
            },
        })
        setLoading(true)
    }

    // useEffect(() => {
    //     if (user && global.storeConfigration?.stripe_integration?.value != 1) {
    //         const getData = async () => {
    //             getAllStripeCards({
    //                 account_id: process.env.REACT_APP_AUCTIONPAYID,
    //                 customer_id: user.card_paymentid,
    //                 object: 'card',
    //             })
    //         }
    //         getData()
    //     }
    // }, [user])
    useEffect(() => {
        if (get_all_card_details) {
            setAllData(get_all_card_details.records)
        }
    }, [get_all_card_details])
    useEffect(() => {
        if (responseStatus?.from === 'reactivateFee') {
            setLoading(false)
            if (responseStatus?.status === 'success') {
                setAlert('Paid Successfully!', 'success')
                loadUser()
            }
        }
    }, [responseStatus])
    useEffect(() => {
        if (user) {
            if (user?.status === 'active') {
                handleRedirectInternal(history, 'dashboard/profile')
            }
        }
    }, [user])
    return (
        <Container className="db-bids-wrapper mt-4">
            <h2 className="mp-head">Re-activate Fee</h2>
            <div className="row">
                <div className="col-md-6">
                    <div className="thisc">
                        <div className="my-5">
                            <h5 className="amount">
                                Amount Due <b>(USD)</b>
                            </h5>
                            <h4 className="amnt">
                                ${global?.pluginConfiguration?.bidder_suspension?.access_key}{' '}
                            </h4>

                            <div className="payMthd">
                                <label>Payment Method</label>
                                <select
                                    className="paycard"
                                    onChange={handleChange}
                                    value={selectedCard}
                                >
                                    <option value="">Please Select Credit/Debit Card</option>
                                    {allData.map((data) => (
                                        <option value={data.id}>
                                            {'xxxx-xxxx-xxxx-' + data.last4} ({data.brand})
                                        </option>
                                    ))}
                                </select>
                            </div>
                            <div className="cardDetls">
                                <Link to="/dashboard/cards" className="atag">
                                    Add New Card
                                </Link>
                            </div>

                            <button
                                type="button"
                                className="primaryBtn make_payment"
                                disabled={loading}
                                onClick={handleSubmit}
                            >
                                {loading ? 'Loading...' : 'Make Payment'}
                            </button>
                        </div>
                    </div>
                </div>
                <div className="col-md-6"></div>
            </div>
        </Container>
    )
}

export default BidderSuspension
