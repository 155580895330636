import React from 'react'
import Layout from '../Layout'
import { Accordion, AccordionDetails, AccordionSummary } from '@material-ui/core'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
        flexBasis: '100%',
        flexShrink: 0,
        marginBottom: 0,
        padding: '8px',
        color: '#000',
        fontWeight: 500,
    },
}))

export default function faq(props) {
    const baseStyle = {
        fontWeight: 700,
        marginBottom: '12px',
    }

    const h1Style = {
        ...baseStyle,
        // Additional or overriding styles for h2 can go here if needed
    }

    const h4Style = {
        ...baseStyle,
        marginTop: '20px',
        marginBottom: '20px',
        // Additional or overriding styles for h4 can go here if needed
    }
    const classes = useStyles()
    const [expanded, setExpanded] = React.useState(false)

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false)
    }
    return (
        <Layout props={props}>
            <div className="px-5 py-4 stctPgWrpr faq">
                <h1 style={h1Style}>FAQs</h1>
                <h4 style={h4Style}>General FAQs</h4>
                <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1bh-content"
                        id="panel1bh-header"
                    >
                        <p className={classes.heading}>
                            1. What type of product(s) can I buy from Lots of Auctions?
                        </p>
                    </AccordionSummary>
                    <AccordionDetails>
                        <p>
                            Lots of Auctions offers a large selection of merchandise from a wide
                            range of categories. Available categories can be viewed by location in
                            the dropdown menu at the top of our website. Interested bidders can view
                            available lot(s) in each auction by clicking &ldquo;BID NOW&ldquo; on
                            the auction.
                        </p>
                    </AccordionDetails>
                </Accordion>
                <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel2bh-content"
                        id="panel2bh-header"
                    >
                        <p className={classes.heading}>2. What is the condition of the product?</p>
                    </AccordionSummary>
                    <AccordionDetails>
                        <p>
                            The lots offered through our online auctions include shelf pulls,
                            overstock, and customer returns. The description for each lot will state
                            if the item is brand new.
                        </p>
                    </AccordionDetails>
                </Accordion>
                <Accordion expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel3bh-content"
                        id="panel3bh-header"
                    >
                        <p className={classes.heading}>
                            3. Can I buy a vehicle from Lots of Auctions?
                        </p>
                    </AccordionSummary>
                    <AccordionDetails>
                        <p>
                            We&apos;re sorry, Lots of Auctions does not offer cars, trucks, or other
                            vehicles for sale through our online auctions at this time.
                        </p>
                    </AccordionDetails>
                </Accordion>
                <h4 style={h4Style}>Website FAQs</h4>
                <Accordion expanded={expanded === 'panel4'} onChange={handleChange('panel4')}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel4bh-content"
                        id="panel4bh-header"
                    >
                        <p className={classes.heading}>1. How do I view auctions by location?</p>
                    </AccordionSummary>
                    <AccordionDetails>
                        <p>
                            You can view all of our auctions on the homepage of our website or by
                            clicking the links on our live auction page.
                        </p>
                    </AccordionDetails>
                </Accordion>
                <Accordion expanded={expanded === 'panel5'} onChange={handleChange('panel5')}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel5bh-content"
                        id="panel5bh-header"
                    >
                        <p className={classes.heading}>
                            2.How do I view available lot(s) in each auction?
                        </p>
                    </AccordionSummary>
                    <AccordionDetails>
                        <p>
                            You can view all available lot(s) by clicking the &ldquo;BID NOW&ldquo;
                            button under the description on each auction. Please note: clicking
                            &ldquo;BID NOW&ldquo; on our website does not automatically enter a bid
                            on the auction.
                        </p>
                    </AccordionDetails>
                </Accordion>
                <Accordion expanded={expanded === 'panel6'} onChange={handleChange('panel6')}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel6bh-content"
                        id="panel6bh-header"
                    >
                        <p className={classes.heading}>3. Where are your auctions located?</p>
                    </AccordionSummary>
                    <AccordionDetails>
                        <p>
                            We currently offer online auctions in Denton, TX and Dallas, TX. We will
                            add additional locations in the future. Please check back often. You can
                            view our auction locations by clicking
                            https://lotsofauctions.com/live-auctions/
                        </p>
                    </AccordionDetails>
                </Accordion>
                <Accordion expanded={expanded === 'panel7'} onChange={handleChange('panel7')}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel7bh-content"
                        id="panel7bh-header"
                    >
                        <p className={classes.heading}>4. How does the auction work?</p>
                    </AccordionSummary>
                    <AccordionDetails>
                        <p>
                            After registering to bid and confirming your account, bidders can click
                            &ldquo;BID NOW&ldquo; to view all available lots in each auction.
                            Bidders can place bids on any lot(s) they choose. There is no limit to
                            bidding. Bidders may choose to place a &ldquo;Quick Bid&ldquo; or submit
                            a highest bid on each lot. The system sends notification when bidders
                            get outbid. At this time, the bidder may choose to increase their bid or
                            forfeit the auction. Winning bidders will r eceive notification once
                            they win the auction. Following the auction, winning bidders must
                            pick-up their lot(s) from the location listed on the auction(s).
                            Additional information about bidding can be found in the Bidder FAQ
                            section below.
                        </p>
                    </AccordionDetails>
                </Accordion>
                <Accordion expanded={expanded === 'panel8'} onChange={handleChange('panel8')}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel8bh-content"
                        id="panel8bh-header"
                    >
                        <p className={classes.heading}>5. Why can&apos;t I access my account?</p>
                    </AccordionSummary>
                    <AccordionDetails>
                        <p>
                            If you are a new bidder, please check your email and confirm the
                            registration email that was sent. You may need to check your spam
                            filter. Please contact us if you did not receive the registration email.
                        </p>
                    </AccordionDetails>
                </Accordion>
                <h4 style={h4Style}>Bidding FAQ – Lots</h4>
                <Accordion expanded={expanded === 'panel9'} onChange={handleChange('panel9')}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel9bh-content"
                        id="panel9bh-header"
                    >
                        <p className={classes.heading}>
                            1.What&apos;s the difference between pallets & individual items?
                        </p>
                    </AccordionSummary>
                    <AccordionDetails>
                        <p>
                            Pallets are pre-built and contain a selection of products. These pallets
                            contain up to 15-20% fallout (unusable products). Our pallets are an
                            excellent option for resellers and open-air market vendors that require
                            lots of product. Individual items are sold separately. It is recommended
                            that everyday shoppers bid on our individual items, not pallets.Pallets
                            are pre-built and contain a selection of products. These pallets contain
                            up to 15-20% fallout (unusable products). Our pallets are an excellent
                            option for resellers and open-air market vendors that require lots of
                            product. Individual items are sold separately. It is recommended that
                            everyday shoppers bid on our individual items, not pallets.
                        </p>
                    </AccordionDetails>
                </Accordion>
                <Accordion expanded={expanded === 'panel10'} onChange={handleChange('panel10')}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel10bh-content"
                        id="panel10bh-header"
                    >
                        <p className={classes.heading}>
                            2. Can I pick the items included on my pallet?
                        </p>
                    </AccordionSummary>
                    <AccordionDetails>
                        <p>
                            No. The pallets available through our pallet auction are pre-built.
                            Winning bidders accept all items on the pallet at purchase.
                        </p>
                    </AccordionDetails>
                </Accordion>
                <Accordion expanded={expanded === 'panel11'} onChange={handleChange('panel11')}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel11bh-content"
                        id="panel11bh-header"
                    >
                        <p className={classes.heading}>3. Is there a fall-out guarantee?</p>
                    </AccordionSummary>
                    <AccordionDetails>
                        <p>
                            The pallets included in our pallet auctions include a 15-20% fallout
                            guarantee. Fallout refers to damaged/missing/non-functional product.
                        </p>
                    </AccordionDetails>
                </Accordion>
                <Accordion expanded={expanded === 'panel12'} onChange={handleChange('panel12')}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel12bh-content"
                        id="panel12bh-header"
                    >
                        <p className={classes.heading}>4. What is the return policy for pallets?</p>
                    </AccordionSummary>
                    <AccordionDetails>
                        <p>
                            Winning bidders will have an opportunity to inspect their pallet(s)
                            on-site before breakdown and removal. Pallet(s) that do not meet the
                            winning bidders satisfaction can be returned for a refund immediately
                            following on-site inspection.
                        </p>
                    </AccordionDetails>
                </Accordion>
                <Accordion expanded={expanded === 'panel13'} onChange={handleChange('panel13')}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel13bh-content"
                        id="panel13bh-header"
                    >
                        <p className={classes.heading}>5. How do I pay for the pallet(s) I win?</p>
                    </AccordionSummary>
                    <AccordionDetails>
                        <p>
                            Please visit our Terms of Use for details about payment. Full payment
                            from the winning bidder must be received prior to the removal or
                            transportation of purchased pallets.
                        </p>
                    </AccordionDetails>
                </Accordion>
                <Accordion expanded={expanded === 'panel14'} onChange={handleChange('panel14')}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel14bh-content"
                        id="panel14bh-header"
                    >
                        <p className={classes.heading}>6. How do I get the pallet(s) I win?</p>
                    </AccordionSummary>
                    <AccordionDetails>
                        <p>
                            Winning bidders must pick-up their pallet(s) within the pick-up window
                            posted on the auction. Lots of Auctions offers convenient contact-less
                            pick-up in Texas. Please contact us for information about shipping items
                            outside of the area.
                        </p>
                    </AccordionDetails>
                </Accordion>
                <h4 style={h4Style}>Selling FAQ</h4>
                <Accordion expanded={expanded === 'panel15'} onChange={handleChange('panel15')}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel15bh-content"
                        id="panel15bh-header"
                    >
                        <p className={classes.heading}>
                            1. How do I register to sell with Lots of Auctions?
                        </p>
                    </AccordionSummary>
                    <AccordionDetails>
                        <p>
                            Interested parties can apply to sell by filling out the form located
                            here. Applying to sell with Lots of Auctions is fast and easy!
                        </p>
                    </AccordionDetails>
                </Accordion>
                <Accordion expanded={expanded === 'panel16'} onChange={handleChange('panel16')}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel16bh-content"
                        id="panel16bh-header"
                    >
                        <p className={classes.heading}>2. What happens after I apply to sell?</p>
                    </AccordionSummary>
                    <AccordionDetails>
                        <p>
                            After applying to sell with Lots of Auctions, one of our representatives
                            will review your information and contact you directly to discuss next
                            steps. Please note: you will not be able to log-in to list your lot(s)
                            immediately after applying to sell.
                        </p>
                    </AccordionDetails>
                </Accordion>
                <Accordion expanded={expanded === 'panel17'} onChange={handleChange('panel17')}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel17bh-content"
                        id="panel17bh-header"
                    >
                        <p className={classes.heading}>
                            3. Can I sell through Lots of Auctions if I live outside the United
                            States?
                        </p>
                    </AccordionSummary>
                    <AccordionDetails>
                        <p>At this time we only accept sellers located within the United States.</p>
                    </AccordionDetails>
                </Accordion>
                <Accordion expanded={expanded === 'panel18'} onChange={handleChange('panel18')}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel18bh-content"
                        id="panel18bh-header"
                    >
                        <p className={classes.heading}>
                            4. Why should I sell my lot(s) at auction?
                        </p>
                    </AccordionSummary>
                    <AccordionDetails>
                        <p>
                            Our sellers choose to sell their lot(s) through our online auctions for
                            a number of reasons. Businesses may sell to reduce inventory or earn
                            extra revenue on the side. Online auctions increase visibility and allow
                            resellers, business owners, and entrepreneurs to reach a high number of
                            interested buyers fast.
                        </p>
                    </AccordionDetails>
                </Accordion>
                <Accordion expanded={expanded === 'panel19'} onChange={handleChange('panel19')}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel19bh-content"
                        id="panel19bh-header"
                    >
                        <p className={classes.heading}>5.Why Lots of Auctions?</p>
                    </AccordionSummary>
                    <AccordionDetails>
                        <p>
                            Lots of Auctions uses targeted advertising to ensure the right people
                            see our auctions. In addition to increasing the number of people that
                            view our listings, this also helps our sellers get top dollar for their
                            items. We offer convenient and free registration for bidders.
                        </p>
                    </AccordionDetails>
                </Accordion>
                {/* <span
                    dangerouslySetInnerHTML={{
                        __html: global.storeDetails.terms,
                    }}
                ></span> */}
            </div>
        </Layout>
    )
}
