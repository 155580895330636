import React from 'react'
import Radio from '@material-ui/core/Radio'
import { Button } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import './paymentCards.css'
import FormControlLabel from '@material-ui/core/FormControlLabel'
export const GreenRadio = withStyles({
    root: {
        color: 'var(--primColor)',
        '&$checked': {
            color: 'var(--primColor)',
        },
    },
    checked: {},
})((props) => <Radio color="default" {...props} />)
const PaymentCards = ({ data, del, value, cc, from }) => {
    return (
        <div className="parentCard">
            <>
                <div className="addNewcard-parent">
                    <div className="Addcard-Payment bankDtlCard">
                        {/* <img src="/images/Master.png" alt="cards" /> */}
                        <div className="cardsData">
                            <p className="d-flex align-items-center justify-content-between">
                                Bank Name:{' '}
                                <span>
                                    <b>{data.bank_name}</b>
                                </span>
                            </p>
                            {/* <h2></h2> */}
                            <p className="d-flex align-items-center justify-content-between mb-0">
                                Account Holder Name:{' '}
                                <span>
                                    <b>{data.account_holder_name}</b>
                                </span>
                            </p>
                        </div>
                        <div className="vrfyStatus">
                            {data.status == 'new' ? (
                                <Button
                                    onClick={() => {
                                        del(data.id)
                                    }}
                                >
                                    VERIFY
                                </Button>
                            ) : data.status == 'verified' ? (
                                <>
                                    {from == 'checkout' ? (
                                        <FormControlLabel
                                            onClick={() => {
                                                // const data = {
                                                //     source: data.id,
                                                // }
                                                // console.log(data)
                                                cc(data.id)
                                            }}
                                            checked={value == data.id ? true : false}
                                            value={data.id}
                                            control={<GreenRadio />}
                                        />
                                    ) : (
                                        ''
                                    )}
                                    <Button
                                        // onClick={() => {
                                        //     props.del(data.id)
                                        // }}
                                        className="btn card-del deleteCard material-icons"
                                    >
                                        VERIFIED
                                    </Button>
                                </>
                            ) : (
                                ''
                            )}
                        </div>
                    </div>
                </div>
                <div className="d-flex justify-content-between">
                    {/* <PrimaryButton
                        label={
                            props.selectedcard.token_id === props.value.id
                                ? 'Primary Card'
                                : 'Make Primary'
                        }
                        type="button"
                        onClick={() => {
                            const mkprData = {
                                cardId: props.value.id,
                                card: `xxxx-xxxx-xxxx-${props.value.last4}`,
                                activate: 'activate',
                                expiration_month: props.value.exp_month,
                                year: props.value.exp_year,
                                month: props.value.exp_month,
                                cardType: props.value.brand,
                                mode: process.env.REACT_APP_PAYMENTMODE,
                                id: props.selectedcard.id,
                            }

                            if (props.pr) {
                                props.pr(mkprData)
                            }
                        }}
                    /> */}
                </div>
            </>
        </div>
    )
}

export default PaymentCards
