import React from 'react'
import Barcode from 'react-barcode'

const BarcodeGenerator = () => {
    return (
        <div className="barcode">
            <Barcode
                value={global.storeDetails?.user_id}
                width={2}
                height={100}
                displayValue={false}
                background="#f8f8f8"
                lineColor="#333"
            />
        </div>
    )
}

export default BarcodeGenerator
