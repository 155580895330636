import React, { useEffect, useState, useContext, useRef } from 'react'
import Layout from '../Layout'
import SearchAuctionList from '../../../Component/Search/SearchAuctionList'
import ProductContext from '../../../Product/context/product/productContext'
import AuthContext from '../../../Product/context/auth/authContext'
import { Link, useHistory } from 'react-router-dom'
import NoRecordsFound from '../../../Product/components/atoms/NoRecordsFound'
import './home.css'
import SecondaryButton from '../../../Product/components/atoms/SecondaryButton'
import SearchProductList from '../../../Component/ProductCard/product'
import PrimaryButton from '../../../Product/components/atoms/PrimaryButton'
import moment from 'moment'
import { getAdsImage, handleRedirectInternal } from '../../../Product/common/components'
import AlertContext from '../../../Product/context/alert/alertContext'
import BuyerContext from '../../../Product/context/buyer/buyerContext'
import UserContext from '../../../Product/context/user/userContext'
import { useLocation } from 'react-router-dom'
import { messageHandler } from '../../../Product/common/socketHandler'
import { socket, socketAuction } from '../../../Product/common/socket'
import GridAuction from '../../../Component/ProductCard/gridAuction'
import GridBuynow from '../../../Component/ProductCard/GridBuynow'
import { AppBar, Button, Tab, Tabs } from '@material-ui/core'
import Loaders from '../../../Product/components/molecules/Loaders'
import GridProduct from '../../../Component/ProductCard/gridProduct'
import Slider from 'react-slick'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import axios from 'axios'
import staticImg from '../../../assets/images/carback.jpg'
import staticTwoImg from '../../../assets/images/artback.jpg'
import staticThreeImg from '../../../assets/images/blowfish.png'
import staticFourImg from '../../../assets/images/donationback.png'
import shopLocal from '../../../assets/images/shop_local.png'
import payRetail from '../../../assets/images/pay_retail.png'
import environment from '../../../assets/images/environment.png'
import { useMediaQuery } from 'react-responsive'
import { TabPanel } from '../../../Component/SlidePanel/Standalone'
import { Swiper, SwiperSlide, useSwiper, useSwiperSlide } from 'swiper/react/swiper-react'
import 'swiper/swiper-bundle.css'
import { EffectCoverflow, Navigation, Autoplay, Pagination } from 'swiper'
// import Bidhistory from '../../../Component/Bidhistory'

const Home = (props) => {
    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' })
    const location = useLocation()
    const params = new URLSearchParams(location.search)
    const productContext = useContext(ProductContext)
    const alertContext = useContext(AlertContext)
    const userContext = useContext(UserContext)
    // const [historyData, setHistoryData] = useState({})
    const { static_page, getStaticPage } = userContext
    const [selectedProduct, setSelectedProduct] = useState(null)
    const [value, setValue] = useState(0)
    const {
        search_allauctionproducts,
        search_allproducts,
        search_allauctions,
        getLotList,
        getAllSearchAuctions,
        getAllSearchProducts,
    } = productContext
    const authContext = useContext(AuthContext)
    const { isAuthenticated, user } = authContext
    const { setAlert } = alertContext

    const history = useHistory()

    const [lotsPageLoading, setlotsPageLoading] = useState(true)
    const [galleryPageLoading, setgalleryPageLoading] = useState(true)
    const [closedHomeLoading, setclosedHomeLoading] = useState(true)
    const [activeHomeLoading, setactiveHomeLoading] = useState(true)
    const [activereverseLoading, setactiveReverseLoading] = useState(true)
    const [viewProduct, setViewProduct] = useState([])
    const [buyNowProduct, setBuyNowProduct] = useState([])
    const [activeAuctions, setActiveAuctions] = useState([])
    const [reverseAuctions, setReverseAuctions] = useState([])
    const [activeHome, setActiveHome] = useState([])
    const sessionTimeOutRef = useRef(null)
    const [viewClosedAuction, setViewClosedAuction] = useState([])
    const [listview, setlistview] = useState(false)
    const [clearRequest, setClearTimeout] = useState(null)
    const [cancelRequest, setCancelRequest] = useState(undefined)
    const cancelToken = axios.CancelToken.source()
    const [auctionSearch] = useState({
        page: 1,
        sh_limit: '10',
        orderby:
            isTabletOrMobile && global?.storeConfigration?.auction_close_soon_mobile?.value
                ? global?.storeConfigration?.auction_close_soon_mobile?.value
                : global.storeConfigration?.auction_search_order?.value
                ? global.storeConfigration.auction_search_order.value
                : '2',
        searchbar: '',
        is_auction: 1,
    })
    const storeID = global?.storeDetails?.id
    var new_cat = global.searchCategories
    console.log(new_cat, 'lki')
    const [closedSearch] = useState({
        page: 1,
        sh_limit: '10',
        orderby: '1',
        type: 'closed',
        is_auction: 1,
    })

    const [productSearch] = useState({
        sh_limit: 20,
        from: 'buynow',
        page: 1,
        orderby: '1',
    })
    const [filterValues, setFilterValues] = useState([])
    const [lotSearch, setLotSearch] = useState({
        sh_limit: 20,
        from: 'auction_only',
        page: 1,
        orderby: '12',
        most_viewed: 1,
        viewers: 1,
    })

    const getAllProducts = async (type) => {
        // getAllSearchProducts(productSearch, 'galleryPage')
        //     .then((res) => {
        //         if (res.success === 'yes' && params.get('product'))
        //             getViewProduct({ product_id: Number(params.get('product')) })
        //     })
        //     .catch((err) => console.log(err, 'watchlist get products err'))
        // setLoading({
        //     activeLoading: false,
        //     buyNowLoading: false,
        //     closedLoading: false,
        // })
        // getAllSearchAuctions(auctionSearch, 'activeHome')
        //     .then((res) => {
        //         if (res.success === 'yes' && params.get('product'))
        //             getViewProduct({ product_id: Number(params.get('product')) })
        //     })
        //     .catch((err) => console.log(err, 'watchlist get products err'))
    }

    useEffect(() => {
        setgalleryPageLoading(true)
        getAllSearchProducts(productSearch, 'galleryPage')
    }, [productSearch, isAuthenticated])

    useEffect(() => {
        if (search_allproducts.from === 'galleryPage') {
            setBuyNowProduct(search_allproducts.results.length ? search_allproducts.results : [])
            setgalleryPageLoading(false)
        }
        if (search_allproducts.from === 'lotsPage') {
            clearTimeout(sessionTimeOutRef.current)
            setViewProduct(search_allproducts.results.length ? search_allproducts.results : [])
            if (lotSearch.most_viewed_feature && !search_allproducts.results.length) {
                setLotSearch({
                    ...lotSearch,
                    most_viewed: 1,
                    most_viewed_feature: 0,
                })
            } else {
                setlotsPageLoading(false)
            }
        }
    }, [search_allproducts])
    const handleChange = (event, newValue) => {
        setValue(newValue)
    }
    const checkThemeClassName = document.body.className == 'LiquidationThree'
    useEffect(() => {
        setlotsPageLoading(true)
        if (cancelRequest) {
            cancelRequest.cancel()
        }

        setCancelRequest(cancelToken)
        getAllSearchProducts(lotSearch, 'lotsPage')
        sessionTimeOutRef.current = setTimeout(() => {
            getAllSearchProducts(lotSearch, 'lotsPage')
        }, 8000)
    }, [lotSearch, isAuthenticated])
    function a11yProps(index) {
        return {
            id: `home-auctions-${index}`,
            'aria-controls': `home-auctions-tabpanel-${index}`,
        }
    }
    useEffect(() => {
        setclosedHomeLoading(true)
        if (global?.storeConfigration?.remove_recently_closed?.value != 1) {
            getAllSearchAuctions(closedSearch, 'closedHome')
        }
    }, [closedSearch])
    useEffect(() => {
        console.log(filterValues, global.searchCategories, 'filterValues')
        setFilterValues(
            global.searchCategories.sort((a, b) => a.description.localeCompare(b.description)),
        )
    }, [global.searchCategories])

    useEffect(() => {
        setactiveHomeLoading(true)
        getAllSearchAuctions(auctionSearch, 'activeHome')

        if (global?.storeConfigration?.buy_now_home_changes?.value) {
            var body_request = auctionSearch
            body_request.is_auction = 0
            body_request.is_buynow = 1
            getAllSearchAuctions(body_request, 'activeHome_buynow')
        }

        if (global?.pluginConfiguration?.reverse_auction?.enable) {
            var body_request = auctionSearch
            body_request.auction_io_type = 1
            getAllSearchAuctions(body_request, 'reverse_auction')
        }
    }, [auctionSearch])

    useEffect(() => {
        if (search_allauctions.from === 'activeHome') {
            let activeAuctions = search_allauctions.results.length ? search_allauctions.results : []
            setActiveAuctions(
                activeAuctions.filter(
                    (data) => data.market_status == 'open' && data.remainingDay >= 0,
                ),
            )
            // setTimeout(() => {
            setactiveHomeLoading(false)
            // }, 1500)
        }
        if (search_allauctions.from === 'reverse_auction') {
            setReverseAuctions(search_allauctions.results.length ? search_allauctions.results : [])
            setactiveReverseLoading(false)
        }

        if (search_allauctions.from === 'closedHome') {
            setViewClosedAuction(
                search_allauctions.results.length ? search_allauctions.results : [],
            )
            setclosedHomeLoading(false)
        }

        if (search_allauctions.from == 'activeHome_buynow') {
            setActiveHome(search_allauctions.results.length ? search_allauctions.results : [])
        }
    }, [search_allauctions])

    const viewProductRef = useRef(viewProduct)
    const userRef = useRef(user)

    // Sets product and user refrences on page load
    useEffect(() => {
        viewProductRef.current = viewProduct
        userRef.current = user
    })

    const handler = (message, type) => {
        messageHandler(
            message,
            viewProductRef.current,
            userRef.current,
            setAlert,
            setViewProduct,
            type,
        )
    }

    useEffect(() => {
        socket.on('realclosedupdates', (data) => {
            handler(data, 'realclosedupdates')
        })
        socket.on('bidAddtime', (data) => {
            handler(data, 'bidAddtime')
        })

        socketAuction.on('bidAddtime', (data) => {
            handler(data, 'bidAddtime')
        })

        return () => {
            socket.off('realclosedupdates', (data) => {
                handler(data, 'realclosedupdates')
            })
            socket.off('bidAddtime', (data) => {
                handler(data, 'bidAddtime')
            })

            socketAuction.off('bidAddtime', (data) => {
                handler(data, 'bidAddtime')
            })
        }
    }, [])

    useEffect(() => {
        if (global?.storeConfigration?.liq_home_page_banner_changes?.value == 1) {
            getStaticPage(
                {
                    page_id: 'home_banner_text',
                },
                1,
            )
        }
    }, [])

    const settings = {
        dots: true,
        fade: false,
        arrows: false,
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        speed: 1000,
        autoplaySpeed: 4000,
        cssEase: 'linear',
    }
    const shopSettings = {
        className: '',
        dots: true,
        fade: false,
        arrows: true,
        infinite: true,
        slidesToShow: 5,
        slidesToScroll: 1,
        autoplay: true,
        speed: 1000,
        autoplaySpeed: 4000,
        cssEase: 'linear',
        adaptiveHeight: true,
        initialSlide: 0,
        responsive: [
            {
                breakpoint: 1350,
                settings: {
                    slidesToShow: 4,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: true,
                },
            },
            {
                breakpoint: 1100,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: true,
                },
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    initialSlide: true,
                },
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },
        ],
    }
    const imageChangeInterval = global?.pluginConfiguration?.advertisement?.refresh_key * 60000 // Convert minutes to milliseconds

    const isMobile = useMediaQuery({
        query: '(max-width: 500px)',
    })

    const [homeAds, setHomeAds] = useState({
        deskHomeTop: '',
        deskHomeMid: '',
        mobHomeTop: '',
        mobHomeMid: '',
        deskHomeBottom: '',
        mobHomeBottom: '',
    })

    const [activeHomeAdsIndex, setActiveHomeAdsIndex] = useState({
        deskHomeTop: 0,
        deskHomeMid: 0,
        mobHomeTop: 0,
        mobHomeMid: 0,
        deskHomeBottom: 0,
        mobHomeBottom: 0,
    })

    function updateRandomIndex(array) {
        const randomIndex = Math.floor(Math.random() * array.length)
        return randomIndex
    }

    useEffect(() => {
        setHomeAds((prevAucAds) => ({
            deskHomeTop: getAdsImage(1, 1, 1),
            deskHomeMid: getAdsImage(1, 1, 2),
            mobHomeTop: getAdsImage(2, 1, 1),
            mobHomeMid: getAdsImage(2, 1, 2),
            deskHomeBottom: getAdsImage(1, 1, 5),
            mobHomeBottom: getAdsImage(2, 1, 5),
        }))
    }, [])

    useEffect(() => {
        setActiveHomeAdsIndex((prevActiveIndex) => ({
            deskHomeTop: updateRandomIndex(homeAds?.deskHomeTop),
            deskHomeMid: updateRandomIndex(homeAds?.deskHomeMid),
            mobHomeTop: updateRandomIndex(homeAds?.mobHomeTop),
            mobHomeMid: updateRandomIndex(homeAds?.mobHomeMid),
            deskHomeBottom: updateRandomIndex(homeAds?.deskHomeBottom),
            mobHomeBottom: updateRandomIndex(homeAds?.mobHomeBottom),
        }))
    }, [homeAds])

    return (
        <Layout props={props}>
            <div className="home">
                {global?.storeConfigration?.liq_home_page_banner_changes?.value == 1 ? (
                    <div className="siteBanner multiple auctionListCnt">
                        <div
                            dangerouslySetInnerHTML={{
                                __html: static_page.content,
                            }}
                        ></div>
                    </div>
                ) : global.multipleBannersFields.length ? (
                    <div className="siteBanner multiple auctionListCnt">
                        {
                            <Slider {...settings}>
                                {global.multipleBannersFields?.map((data, index) => (
                                    <>
                                        <img
                                            src={
                                                process.env.REACT_APP_AUCTION_IMAGE_URL +
                                                '/' +
                                                data?.image_name
                                            }
                                        />
                                        <div className="bannerContent">
                                            <h2 className="">
                                                {/* Spring Savings Are Here */}
                                                {JSON.parse(data.fields ? data.fields : '{}').title}
                                            </h2>
                                            <p className="pb-4">
                                                {/* Save up to 70% off MSRP
                                                <br />
                                                Bidding start at $2.50 */}
                                                {
                                                    JSON.parse(data.fields ? data.fields : '{}')
                                                        .description
                                                }
                                            </p>
                                            {JSON.parse(data.fields ? data.fields : '{}').btn_text
                                                ?.length > 0 && (
                                                <PrimaryButton
                                                    label={
                                                        isAuthenticated
                                                            ? JSON.parse(
                                                                  data.fields ? data.fields : '{}',
                                                              ).btn_text
                                                            : 'Sign Up'
                                                    }
                                                    type="button"
                                                    onClick={() =>
                                                        !isAuthenticated
                                                            ? handleRedirectInternal(
                                                                  history,
                                                                  'sign-up',
                                                              )
                                                            : (window.location.href = JSON.parse(
                                                                  global.multipleBannersFields[
                                                                      index
                                                                  ].fields
                                                                      ? global
                                                                            .multipleBannersFields[
                                                                            index
                                                                        ].fields
                                                                      : '{}',
                                                              ).btn_link)
                                                    }
                                                />
                                            )}
                                        </div>
                                    </>
                                ))}
                            </Slider>
                        }
                    </div>
                ) : (
                    <div className="siteBanner auctionListCnt">
                        <LazyLoadImage
                            src={global?.storeDetails?.bannerValue}
                            alt={`${global?.storeDetails?.name} banner`}
                            effect="blur"
                            height="100%"
                            width="100%"
                        />
                        <div className="bannerContent">
                            <h2>{global?.storeDetails?.description}</h2>
                            <PrimaryButton
                                label="Explore Now"
                                onClick={() =>
                                    handleRedirectInternal(
                                        history,
                                        `${isAuthenticated ? 'search' : 'sign-up'}`,
                                    )
                                }
                            />
                        </div>
                    </div>
                )}
                {/* <div className="siteBanner">
                    <img
                        src={global?.storeDetails?.bannerValue}
                        alt={`${global?.storeDetails?.name} banner`}
                    />
                    <div className="bannerContent">
                        <h2>{global?.storeDetails?.name}</h2>
                        <p>{global?.storeDetails?.description}</p>
                        <SecondaryButton
                            label="Get Started"
                            onClick={() =>
                                handleRedirectInternal(
                                    history,
                                    `${isAuthenticated ? 'search' : 'sign-up'}`,
                                )
                            }
                        />
                    </div>
                </div> */}
                <div className="auctionShop auctionListCnt">
                    <div className="shopByCat">
                        <h3>Shop by Category</h3>
                        <Link to="/search">
                            View all categories
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="31"
                                height="30"
                                viewBox="0 0 31 30"
                                fill="none"
                            >
                                <path
                                    fillRule="evenodd"
                                    clipRule="evenodd"
                                    d="M16.8238 8.49582C17.0816 8.23802 17.4996 8.23802 17.7574 8.49582L23.7325 14.4709C23.9903 14.7287 23.9903 15.1467 23.7325 15.4045L17.7574 21.3796C17.4996 21.6374 17.0816 21.6374 16.8238 21.3796C16.566 21.1217 16.566 20.7038 16.8238 20.4459L21.6719 15.5979H8.32806C7.96346 15.5979 7.6679 15.3023 7.6679 14.9377C7.6679 14.5731 7.96346 14.2775 8.32806 14.2775L21.6719 14.2775L16.8238 9.42943C16.566 9.17162 16.566 8.75363 16.8238 8.49582Z"
                                    fill="#222222"
                                />
                            </svg>
                        </Link>
                    </div>
                    {/* {checkThemeClassName && ( */}
                    {/* <Slider {...shopSettings}>
                        {global.searchCategories?.map((data, index) => (
                            <div className="shopCategory" key={index}>
                                <Link to={`search?q=&z=&m=&view=0&cat=${data.id}`}>
                                    <img
                                        src={
                                            data.image != 'noimg'
                                                ? process.env.REACT_APP_PRODUCT_IMAGE_URL +
                                                  data.image
                                                : process.env.REACT_APP_AUCTION_IMAGE_URL +
                                                  global?.storeDetails?.logo
                                        }
                                    />
                                </Link>
                                <span className="catgLabel">{data.description}</span>
                            </div>
                        ))}
                    </Slider> */}
                    <div className="PmCategoryBase-root">
                        {global.searchCategories?.map((data, index) => (
                            <div
                                className="PmCategoryList-root"
                                key={index}
                                onClick={() =>
                                    handleRedirectInternal(
                                        history,
                                        'search?q=&z=&m=&view=0&cat=' + data.id,
                                    )
                                }
                            >
                                <picture className="PmCategoryImage-root">
                                    <img
                                        className="PmCategoryImage lazy"
                                        src={
                                            data.image != 'noimg'
                                                ? process.env.REACT_APP_PRODUCT_IMAGE_URL +
                                                  data.image
                                                : process.env.REACT_APP_AUCTION_IMAGE_URL +
                                                  global?.storeDetails?.logo
                                        }
                                        data-src="image.jpg"
                                        alt={data.description_without_count}
                                    />
                                </picture>
                                <h4 className="PmCategoryTitle m-0">
                                    {data.description_without_count}
                                </h4>
                            </div>
                        ))}
                    </div>
                    {/* )} */}
                </div>

                {storeID === 67 ? (
                    ''
                ) : (
                    <div className="auctionListCnt">
                        <div className="homeProds">
                            <div className="search-wrapper">
                                <div className="shopByCat">
                                    <h3>
                                        Trending Now in{' '}
                                        {global?.storeConfigration?.glc_loc?.value
                                            ? global?.storeConfigration.glc_loc.value
                                            : 'Dallas'}
                                    </h3>
                                    <Link to="/search">
                                        View all trending
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="31"
                                            height="30"
                                            viewBox="0 0 31 30"
                                            fill="none"
                                        >
                                            <path
                                                fillRule="evenodd"
                                                clipRule="evenodd"
                                                d="M16.8238 8.49582C17.0816 8.23802 17.4996 8.23802 17.7574 8.49582L23.7325 14.4709C23.9903 14.7287 23.9903 15.1467 23.7325 15.4045L17.7574 21.3796C17.4996 21.6374 17.0816 21.6374 16.8238 21.3796C16.566 21.1217 16.566 20.7038 16.8238 20.4459L21.6719 15.5979H8.32806C7.96346 15.5979 7.6679 15.3023 7.6679 14.9377C7.6679 14.5731 7.96346 14.2775 8.32806 14.2775L21.6719 14.2775L16.8238 9.42943C16.566 9.17162 16.566 8.75363 16.8238 8.49582Z"
                                                fill="#222222"
                                            />
                                        </svg>
                                    </Link>
                                </div>
                                {/* <h2 className="auctionListTitle">Trending Now in Dallas</h2> */}
                                <div className="appBarWrapper">
                                    <AppBar position="static">
                                        <Tabs
                                            value={value}
                                            onChange={handleChange}
                                            aria-label="simple tabs example"
                                        >
                                            {/* <Tab label="Featured" {...a11yProps(0)} /> */}
                                            <Tab label="Active Lots" {...a11yProps(0)} />
                                            {/* <Tab label="Buy Now" {...a11yProps(2)} /> */}
                                            {/* <Tab label="Pick Up Only" {...a11yProps(3)} /> */}
                                        </Tabs>
                                    </AppBar>
                                </div>
                                <TabPanel value={value} index={0}>
                                    <>
                                        {global?.storeConfigration?.hide_most_viewed?.value ==
                                        1 ? null : (
                                            <>
                                                {lotsPageLoading ? (
                                                    <Loaders
                                                        name="product_grid_view"
                                                        isLoading={lotsPageLoading}
                                                        loop={5}
                                                    />
                                                ) : viewProduct.length > 0 ? (
                                                    <>
                                                        <div className="homeGridProducts">
                                                            <SearchProductList
                                                                listview={listview}
                                                                searchResult={viewProduct}
                                                                bidHistry={true}
                                                                className="LiquidationThreeBuyCard"
                                                                getAllProducts={() =>
                                                                    getAllProducts('auctions')
                                                                }
                                                                type="auction"
                                                                fromComponent="home"
                                                            />
                                                            <Button
                                                                className="viewAll"
                                                                onClick={() =>
                                                                    handleRedirectInternal(
                                                                        history,
                                                                        `search`,
                                                                    )
                                                                }
                                                            >
                                                                View All{' '}
                                                                <span className="material-icons">
                                                                    arrow_right_alt
                                                                </span>
                                                            </Button>
                                                        </div>
                                                    </>
                                                ) : (
                                                    ''
                                                )}
                                            </>
                                        )}
                                    </>
                                </TabPanel>
                                {/* <TabPanel value={value} index={1}>
                                <>
                                    <h1>Tab 2</h1>
                                </>
                            </TabPanel>
                            <TabPanel value={value} index={2}>
                                <>
                                    <h1>Tab 3</h1>
                                </>
                            </TabPanel>
                            <TabPanel value={value} index={2}>
                                <>
                                    <h1>Tab 4</h1>
                                </>
                            </TabPanel> */}
                            </div>
                        </div>

                        {/* {global?.pluginConfiguration?.advertisement?.enable == 1 ? (
                        <div className="siteAds adsHome">
                            {isMobile
                                ? homeAds?.mobHomeTop[activeHomeAdsIndex?.mobHomeTop]
                                : homeAds?.deskHomeTop[activeHomeAdsIndex?.deskHomeTop]}
                        </div>
                    ) : null}
                    {global?.storeConfigration?.buy_now_home_changes?.value ? (
                        <>
                            {activeHomeLoading ? (
                                <Loaders
                                    name="product_grid_view"
                                    isLoading={activeHomeLoading}
                                    loop={5}
                                />
                            ) : activeAuctions.length > 0 ? (
                                <div className="twoCart">
                                    <div className="search-wrapper">
                                        <h2 className="auctionListTitle">
                                            {isTabletOrMobile &&
                                            global?.storeConfigration?.auction_close_soon_mobile
                                                ?.value
                                                ? 'Current Auctions'
                                                : 'Recently Listed Auctions'}
                                        </h2>

                                        <div className="gridContainer LiquidationThreeAuctions">
                                            {activeAuctions.map((data, index) => (
                                                <GridAuction
                                                    key={index}
                                                    data={data}
                                                    details={`search/product-buyer-auction/${data.id}`}
                                                    timerTheme="multiseller"
                                                    theme="multiseller"
                                                    // lotCount={data.lotCount ? data.lotCount : 0}
                                                />
                                            ))}
                                        </div>

                                        <Button
                                            className="viewAll"
                                            onClick={() =>
                                                handleRedirectInternal(history, `auctions`)
                                            }
                                        >
                                            View All{' '}
                                            <span className="material-icons">arrow_right_alt</span>
                                        </Button>
                                    </div>
                                    <div>
                                        <h2 className="auctionListTitle mobiusMart">MobiusMart</h2>

                                        <div className="gridContainer LiquidationThreeAuctions mobiusMartCard">
                                            {activeHome.map((data, index) => (
                                                <>
                                                    {index == 0 ? (
                                                        <GridBuynow
                                                            key={index}
                                                            data={data}
                                                            details={`search/product-buyer-auction/${data.id}`}
                                                            timerTheme="multiseller"
                                                            theme="multiseller"
                                                            type="buynow"
                                                            // lotCount={data.lotCount ? data.lotCount : 0}
                                                            remove_description={0}
                                                        />
                                                    ) : (
                                                        ''
                                                    )}
                                                </>
                                            ))}
                                        </div>
                                    </div>
                                </div>
                            ) : (
                                ''
                            )}
                        </>
                    ) : (
                        <>
                            {activeHomeLoading ? (
                                <Loaders
                                    name="product_grid_view"
                                    isLoading={activeHomeLoading}
                                    loop={5}
                                />
                            ) : activeAuctions.length > 0 ? (
                                <div className="search-wrapper">
                                    <h2 className="auctionListTitle">
                                        {isTabletOrMobile &&
                                        global?.storeConfigration?.auction_close_soon_mobile?.value
                                            ? 'Current Auctions'
                                            : 'Recently Listed Auctions'}
                                    </h2>

                                    <div className="gridContainer LiquidationThreeAuctions">
                                        {activeAuctions.map((data, index) => (
                                            <GridAuction
                                                key={index}
                                                data={data}
                                                details={`search/product-buyer-auction/${data.id}`}
                                                timerTheme="multiseller"
                                                theme="multiseller"
                                                // lotCount={data.lotCount ? data.lotCount : 0}
                                            />
                                        ))}
                                    </div>
                                    <Button
                                        className="viewAll"
                                        onClick={() => handleRedirectInternal(history, `auctions`)}
                                    >
                                        View All{' '}
                                        <span className="material-icons">arrow_right_alt</span>
                                    </Button>
                                </div>
                            ) : (
                                ''
                            )}
                        </>
                    )}
                    {global?.pluginConfiguration?.reverse_auction?.enable == 1 ? (
                        <>
                            {activereverseLoading ? (
                                <Loaders
                                    name="product_grid_view"
                                    isLoading={activereverseLoading}
                                    loop={5}
                                />
                            ) : reverseAuctions.length > 0 ? (
                                <div className="twoCart">
                                    <div className="search-wrapper">
                                        <h2 className="auctionListTitle">Reverse Auctions</h2>

                                        <div className="gridContainer LiquidationThreeAuctions">
                                            {reverseAuctions.map((data, index) => (
                                                <GridAuction
                                                    key={index}
                                                    data={data}
                                                    details={`search/product-buyer-auction/${data.id}`}
                                                    timerTheme="multiseller"
                                                    theme="multiseller"
                                                    // lotCount={data.lotCount ? data.lotCount : 0}
                                                />
                                            ))}
                                        </div>
                                    </div>
                                </div>
                            ) : (
                                ''
                            )}
                        </>
                    ) : (
                        ''
                    )} */}

                        {/* {global?.storeConfigration?.hide_most_viewed?.value == 1 ? null : (
                        <>
                            {lotsPageLoading ? (
                                <Loaders
                                    name="product_grid_view"
                                    isLoading={lotsPageLoading}
                                    loop={5}
                                />
                            ) : viewProduct.length > 0 ? (
                                <div className="search-wrapper">
                                    <>
                                        <h2 className="auctionListTitle">Most Viewed Lots</h2>
                                        <SearchProductList
                                            listview={listview}
                                            searchResult={viewProduct}
                                            className="LiquidationThreeBuyCard"
                                            getAllProducts={() => getAllProducts('auctions')}
                                            type="auction"
                                            fromComponent="home"
                                        />
                                        <Button
                                            className="viewAll"
                                            onClick={() =>
                                                handleRedirectInternal(history, `search`)
                                            }
                                        >
                                            View All{' '}
                                            <span className="material-icons">arrow_right_alt</span>
                                        </Button>
                                    </>
                                </div>
                            ) : (
                                ''
                            )}
                        </>
                    )}
                    {galleryPageLoading ? (
                        <Loaders name="product_grid_view" isLoading={galleryPageLoading} loop={5} />
                    ) : buyNowProduct.length > 0 ? (
                        <>
                            {global.storeConfigration?.disable_buynow?.value !== '1' ? (
                                <>
                                    <h2 className="auctionListTitle">
                                        {global.storeConfigration?.buy_now_change_text?.value
                                            ? global.storeConfigration?.buy_now_change_text?.value
                                            : 'Shop Our Gallery'}
                                    </h2>
                                    <div className="search-wrapper">
                                        <SearchProductList
                                            listview={listview}
                                            searchResult={buyNowProduct}
                                            className="LiquidationThreeBuyCard"
                                            type="buynow"
                                            noTimer={true}
                                            getAllProducts={() => getAllProducts('buynow')}
                                            fromComponent="home"
                                        />
                                        <Button
                                            className="viewAll"
                                            onClick={() =>
                                                handleRedirectInternal(history, `gallery`)
                                            }
                                        >
                                            View All{' '}
                                            <span className="material-icons">arrow_right_alt</span>
                                        </Button>
                                    </div>
                                </>
                            ) : null}
                        </>
                    ) : (
                        ''
                    )}
                    {global?.pluginConfiguration?.advertisement?.enable == 1 ? (
                        <div className="siteAds adsHome">
                            {isMobile
                                ? homeAds?.mobHomeMid[activeHomeAdsIndex?.mobHomeMid]
                                : homeAds?.deskHomeMid[activeHomeAdsIndex?.deskHomeMid]}
                        </div>
                    ) : null}
                    {global?.storeConfigration?.remove_recently_closed?.value == 1 ? null : (
                        <>
                            {closedHomeLoading ? (
                                <Loaders
                                    name="product_grid_view"
                                    isLoading={closedHomeLoading}
                                    loop={5}
                                />
                            ) : viewClosedAuction.length > 0 ? (
                                <div className="search-wrapper">
                                    <h2 className="auctionListTitle">Recently Closed Auctions</h2>

                                    <div className="gridContainer LiquidationThreeAuctions">
                                        {viewClosedAuction.map(
                                            (data, index) =>
                                                index <
                                                    (global?.storeConfigration
                                                        ?.recently_closed_auctions_count?.value ||
                                                        1) && (
                                                    <GridAuction
                                                        key={index}
                                                        data={data}
                                                        details={`search/product-buyer-auction/${data.id}`}
                                                        timerTheme="multiseller"
                                                        theme="multiseller"
                                                    />
                                                ),
                                        )}
                                    </div>
                                    <Button
                                        className="viewAll"
                                        onClick={() =>
                                            handleRedirectInternal(
                                                history,
                                                `auctions?closed=${closedSearch.type}`,
                                            )
                                        }
                                    >
                                        View All{' '}
                                        <span className="material-icons">arrow_right_alt</span>
                                    </Button>
                                </div>
                            ) : (
                                ''
                            )}
                        </>
                    )}
                    {viewClosedAuction.length == 0 &&
                    buyNowProduct.length == 0 &&
                    viewProduct.length == 0 &&
                    activeAuctions.length == 0 ? (
                        <div className="noContent">
                            <img src="/assets/svg/noContent.svg" alt="No Content" />
                            <h3>We are getting our inventory ready.</h3>
                            <h3>Please visit back soon!</h3>
                        </div>
                    ) : (
                        ''
                    )}

                    {global?.pluginConfiguration?.advertisement?.enable == 1 ? (
                        <div className="siteAds adsHome">
                            {isMobile
                                ? homeAds?.mobHomeBottom[activeHomeAdsIndex?.mobHomeBottom]
                                : homeAds?.deskHomeBottom[activeHomeAdsIndex?.deskHomeBottom]}
                        </div>
                    ) : null} */}
                    </div>
                )}
                <div className="bottomSec">
                    <div className="container">
                        <h2 className="auctionListTitle text-center">Why Choose Us</h2>
                        <div className="bottomGrid">
                            <div className="partLiq">
                                <img src={shopLocal} />
                                <h3>Shop local No Shipping Charges</h3>
                                <p>
                                    Shopping local means you pick up your items to save on shipping
                                    charges.
                                </p>
                            </div>
                            <div className="partLiq">
                                <img src={payRetail} />
                                <h3>Same Quality, Pay Less</h3>
                                <p>Pay a friction of retail with bids starting at $1.</p>
                            </div>
                            <div className="partLiq">
                                <img src={environment} />
                                <h3>Environmental Impact</h3>
                                <p>
                                    Give good quality items a second chance after they have been
                                    returned.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    )
}

export default Home
