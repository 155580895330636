import React, { useReducer } from 'react'
import AuthContext from './authContext'
import AuthReducer from './authReducer'
import { apiCall, setAuthToken } from '../../common/api'
import { response } from '../common'

import {
    REGISTER_SUCCESS,
    REGISTER_FAIL,
    USER_LOADED,
    USER_LOADED_INITAL,
    CLEAR_INITIAL,
    ONLY_ONCE,
    AUTH_ERROR,
    LOGIN_SUCCESS,
    LOGIN_FAIL,
    ADD_RECENT_SEARCH,
    LOGOUT,
    CLEAR_ERROR,
    RESPONSE_STATUS,
    CLEAR_RESPONSE,
    RECENT_SEARCH,
    COUNT_LOADED,
    USER_DETAILS_LOADED,
    GET_ACTIVE_PLUGINS,
} from './authTypes'
import { socketSeller } from '../../common/socket'

const AuthState = (props) => {
    const initialState = {
        token: localStorage.getItem('token'),
        initialLogin: false,
        isAuthenticated: null,
        onlyOnce: false,
        isAdmin: null,
        loading: true,
        user: null,
        active_plugins: null,
        userDetails: {},
        userProducts: { data: [], total: 0 },
        recent_search: {
            results: [],
            total_pagecnt: 0,
            total_only: 0,
        },
        userCount: {
            watchlistCount: 0,
            itemwonCount: 0,
            itemlostCount: 0,
            savesearchCount: 0,
            itemunPaidCount: 0,
            itemsoldCount: 0,
            messageCount: 0,
            messageCountProjects: 0,
            total_bids: 0,
        },
        membership: {},
        unapid_inv: [],
        error: null,
        responseStatus: null,
    }

    const [state, dispatch] = useReducer(AuthReducer, initialState)
    let resp = new response(dispatch, RESPONSE_STATUS)
    // Register User
    const register = async (formData) => {
        const from = 'register'
        formData.community = 'auction_io'
        try {
            const [res] = await Promise.all([apiCall('post', 'register', formData)])
            if (res.data.status === 'yes') {
                resp.commonResponse(
                    {
                        responseData: res.data.data,
                        status: 'success',
                        message:
                            global.storeConfigration?.need_admin_verification?.value == 1 &&
                            formData.status == 'unverified'
                                ? 'You have registered successfully. Please wait for admin approval.'
                                : global.storeConfigration?.need_reg_verification_email?.value ==
                                      1 && formData.status == 'unverified'
                                ? 'You have registered successfully. Please verify email and continue.'
                                : 'You have registered successfully. Please login to continue!',
                    },
                    from,
                )
            } else {
                resp.commonResponse({ status: 'error', message: res.data.failedreason }, from)
            }
        } catch (err) {
            resp.commonErrorResponse(from)
        }
    }

    const verifyemailandsmscode = async (formData) => {
        const from = 'verify_user_mail_phone'
        formData.community = 'auction_io'
        try {
            const [res] = await Promise.all([apiCall('post', 'checkEmailAndSMSCode_io', formData)])

            if (res.data.success === 'yes') {
                resp.commonResponse(
                    {
                        responseData: res.data,
                        status: 'success',
                        message: 'verification code sent successfully!',
                    },
                    from,
                )
            } else {
                resp.commonResponse({ status: 'error', message: res.data.failedreason }, from)
            }
        } catch (err) {
            //console.log('oooooooooooooooooooooooooooooooooooo', err)
            resp.commonErrorResponse(from)
        }
    }

    // Login User
    const login = async (formData, pos) => {
        const from = 'login'
        try {
            const [res] = await Promise.all([
                apiCall('post', pos == 1 ? 'auction-io-pos/login' : 'login', formData),
            ])
            if (res?.data.status === 'yes') {
                if (
                    global.storeConfigration?.redirect_login_auctionio?.value &&
                    formData.username
                ) {
                    window.location.href =
                        global.storeConfigration?.redirect_login_auctionio?.value +
                        '/login/autologin/' +
                        formData.username
                    return false
                }

                dispatch({
                    type: LOGIN_SUCCESS,
                    payload: res.data,
                })
                resp.commonResponse({ status: 'success', message: res.data.message }, from)
                if (pos == 1) {
                    localStorage.setItem('pos', 'yes')
                }
                loadUser('initial')
            } else {
                resp.commonResponse({ status: 'error', message: res.data.message }, from)
            }
        } catch (err) {
            resp.commonErrorResponse(from)
        }
    }

    // checkReturnUser
    const checkReturnUser = async (formData) => {
        try {
            const [res] = await Promise.all([apiCall('post', 'verifylogin', formData)])
            if (res?.data.status === 'yes') {
                console.log(res.data)
                return true
            } else {
                return false
            }
        } catch (err) {}
    }

    // Load User
    const loadUser = async (type) => {
        if (localStorage.token) {
            setAuthToken(localStorage.token)
        }
        const [res] = await Promise.all([
            apiCall('post', 'getUserProfileDetails', {
                subscribe: 1,
                buyer_subscribe: 1,
                site_id: localStorage.getItem('pos') == 'yes' ? 'AS0131' : undefined,
                bidder_suspension: global.pluginConfiguration?.bidder_suspension?.enable
                    ? 1
                    : undefined,
            }),
        ])
        if (res.data.success === 'yes') {
            if (global.getUserCount) {
                const [resCount] = await Promise.all([
                    apiCall(
                        'get',
                        global?.storeDetails?.theme == 4
                            ? `userDashboardCount/${res.data.data.id}?auction_io=1`
                            : `userDashboardCount/${res.data.data.id}`,
                    ),
                ])
                getActivePlugins()
                if (resCount.data.success === 'yes') {
                    dispatch({
                        type: COUNT_LOADED,
                        payload: resCount.data,
                    })
                }
            }
            getActivePlugins()

            if (type === 'initial') {
                dispatch({
                    type: USER_LOADED_INITAL,
                    payload: res.data,
                })
            } else {
                dispatch({
                    type: USER_LOADED,
                    payload: res.data,
                })
            }
        } else {
            dispatch({
                type: AUTH_ERROR,
            })
        }
    }
    const updateMessageCount = (messageCount, messageCountProjects) => {
        dispatch({
            type: COUNT_LOADED,
            payload: {
                ...state.userCount,
                messageCount,
                messageCountProjects,
            },
        })
    }
    const updateUnpaidCount = (unpaidCount) => {
        dispatch({
            type: COUNT_LOADED,
            payload: {
                ...state.userCount,
                itemunPaidCount: unpaidCount,
            },
        })
    }
    const updateReferral = async () => {
        const [res] = await Promise.all([apiCall('post', 'update-referral')])
        if (res.data.success) {
            dispatch({
                type: COUNT_LOADED,
                payload: {
                    ...state.userCount,
                    total_bids: 1,
                },
            })
        }
    }

    const getActivePlugins = async (type) => {
        const [res] = await Promise.all([apiCall('post', 'get_active_plugins')])
        if (res.data.success === 'yes') {
            dispatch({
                type: GET_ACTIVE_PLUGINS,
                payload: res.data,
            })
        }
    }

    const loadUserDetails = async (formData) => {
        try {
            const [res] = await Promise.all([apiCall('post', 'getUserProfile', formData)])
            if (res.data.success === 'yes') {
                dispatch({
                    type: USER_DETAILS_LOADED,
                    payload: res.data,
                })
            } else {
                resp.commonErrorResponse('loadUserDetails')
            }
        } catch (error) {
            //console.log(error)
            resp.commonErrorResponse('loadUserDetails')
        }
    }

    const getGlobalVariable = async (type) => {
        const [res] = await Promise.all([apiCall('post', 'getSettings')])
        if (res.data.success === 'yes') {
            res.data.settings.forEach((valdata) => {
                if (valdata.type === 'number') {
                    global[valdata.variable] = parseInt(valdata.value)
                } else {
                    global[valdata.variable] = valdata.value
                }
            })
            global['salestax_settings'] = res.data.salestax
            global['admin_bid_increment'] = res.data.bid_increment
            global['admin_bid_default'] = res.data.default_value
        }
    }

    const deleteSearch = async (formData) => {
        const from = 'deleteSearch'
        try {
            const [res] = await Promise.all([apiCall('post', 'delete_save_search', formData)])
            if (res.data.success === 'yes') {
                resp.commonResponse(
                    { status: 'success', message: 'Search Successfully Deleted' },
                    from,
                )
            } else {
                resp.commonResponse(
                    { status: 'error', message: 'Error deleting saved search' },
                    from,
                )
            }
        } catch (err) {
            resp.commonErrorResponse(from)
        }
    }
    const saveSearch = async (formData) => {
        const from = 'saveSearch'
        try {
            const [res] = await Promise.all([apiCall('post', 'add_save_search', formData)])
            if (res.data.success === 'yes') {
                resp.commonResponse(
                    { status: 'success', message: 'Search Saved Successfully' },
                    from,
                )
            } else {
                resp.commonResponse({ status: 'success', message: 'Search already Saved!' }, from)
            }
        } catch (err) {
            resp.commonErrorResponse(from)
        }
    }

    // get Recent Search
    const recentSearch = async (formData) => {
        try {
            const [res] = await Promise.all([
                apiCall('post', 'recent_searchwithpagination', formData),
            ])
            if (res.data.success === 'yes') {
                dispatch({
                    type: RECENT_SEARCH,
                    payload: res.data,
                })
            } else {
                dispatch({
                    type: RECENT_SEARCH,
                    payload: [],
                })
            }
        } catch (err) {
            dispatch({
                type: RECENT_SEARCH,
                payload: [],
            })
        }
    }

    const freeResellerSubscription = async (formData) => {
        const from = 'freeResellerSubscription'
        try {
            const [res] = await Promise.all([
                apiCall('post', 'free_reseller_subscription', formData),
            ])
            if (res.data.success) {
                resp.commonResponse({ status: 'success', message: res.data.message }, from)
            } else {
                resp.commonResponse({ status: 'error', message: res.data.message }, from)
            }
        } catch (err) {
            resp.commonErrorResponse(from)
        }
    }
    const clearResponse = () =>
        dispatch({
            type: CLEAR_RESPONSE,
        })

    // get Recent Search
    const addRecentSearch = async (data) => {
        if (!state.recent_search.find((o) => o.searchtext === data)) {
            dispatch({
                type: ADD_RECENT_SEARCH,
                payload: { searchtext: data },
            })
        }
    }

    const userActivityAuctionIo = async (params) => {
        await Promise.all([apiCall('post', 'userActivityAuctionIO', params)])
    }

    // Log out
    const logout = () => {
        console.log('Im out')
        let socket_data = {
            action: 'userDisconnected',
            session: state.user?.id || '',
            site_id: global.storeDetails.site_id,
        }
        socketSeller.emit('userWatch', socket_data)
        dispatch({
            type: LOGOUT,
        })
    }
    // Clear Error
    const clearErrors = () =>
        dispatch({
            type: CLEAR_ERROR,
        })

    const clearInitial = () =>
        dispatch({
            type: CLEAR_INITIAL,
        })

    const setOnlyOnce = () =>
        dispatch({
            type: ONLY_ONCE,
        })
    return (
        <AuthContext.Provider
            value={{
                token: state.token,
                initialLogin: state.initialLogin,
                isAuthenticated: state.isAuthenticated,
                isAdmin: state.isAdmin,
                loading: state.loading,
                user: state.user,
                active_plugins: state.active_plugins,
                userDetails: state.userDetails,
                userProducts: state.userProducts,
                membership: state.membership,
                error: state.error,
                recent_search: state.recent_search,
                responseStatus: state.responseStatus,
                onlyOnce: state.onlyOnce,
                userCount: state.userCount,
                unapid_inv: state.unapid_inv,
                updateUnpaidCount,
                updateReferral,
                updateMessageCount,
                userActivityAuctionIo,
                register,
                login,
                addRecentSearch,
                saveSearch,
                deleteSearch,
                logout,
                loadUser,
                getActivePlugins,
                loadUserDetails,
                getGlobalVariable,
                recentSearch,
                clearErrors,
                clearInitial,
                clearResponse,
                setOnlyOnce,
                freeResellerSubscription,
                verifyemailandsmscode,
                checkReturnUser,
            }}
        >
            {props.children}
        </AuthContext.Provider>
    )
}

export default AuthState
