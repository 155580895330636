import React from 'react'
import Layout from '../Layout'
import Container from '@material-ui/core/Container'
import DashboardHeader from '../DashboardHeader'
import SASLoginFunction from '../../../utils/CommonFunctionality/SASLoginFunction'

const SASLogin = (props) => {
    return (
        <Layout props={props}>
            <DashboardHeader />
            <Container className="dashboard-wrapper">
                <h2 className="mp-head">SAS Auction Account</h2>
                <SASLoginFunction props={props} />
            </Container>
        </Layout>
    )
}

export default SASLogin
