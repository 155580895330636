import React, { useState, useEffect, useContext } from 'react'
import Layout from '../Layout'
import DashboardHeader from '../DashboardHeader'
import CheckoutComponent from '../../../utils/CommonFunctionality/Checkout/'

const Checkout = (props) => {
    return (
        <Layout props={props}>
            <DashboardHeader />
            <CheckoutComponent props={props} storeTheme="Lotsofauctions" />
        </Layout>
    )
}

export default Checkout
