import React, { useContext, useEffect, useState } from 'react'

import AuthContext from '../../../Product/context/auth/authContext'
import { dateFormatFront } from '../../../Product/common/components'
import AlertContext from '../../../Product/context/alert/alertContext'
import CustomDialog from '../../../Product/components/organisms/Dialog'
import PrimaryButton from '../../../Product/components/atoms/PrimaryButton'
import { Pagination } from '@material-ui/lab'
import DashboardLayout from '../DashboardLayout'
import Layout from '../Layout'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { handleRedirectInternal, mapData } from '../../../Product/common/components'
import { Button } from '@material-ui/core'
import './SavedSearch.css'
import SecondaryButton from '../../../Product/components/atoms/SecondaryButton'
import { useHistory } from 'react-router-dom'

const SavedSearch = (props) => {
    const authContext = useContext(AuthContext)
    const alertContext = useContext(AlertContext)

    const { setAlert } = alertContext
    const history = useHistory()
    const [search, setSearch] = useState({
        sh_limit: 10,
        page: 1,
    })

    const {
        isAuthenticated,
        recentSearch,
        responseStatus,
        clearResponse,
        recent_search,
        saveSearch,
        deleteSearch,
    } = authContext
    const [saveSearchs, setSaveSearchs] = useState(false)

    const validationArray = Yup.object({
        searchbar: Yup.string().trim().required('Required'),
    })

    const formik = useFormik({
        initialValues: {
            searchbar: '',
        },
        validationSchema: validationArray,
        onSubmit: async (values) => {
            saveSearch(values)
            console.log(values, 'checkVals')
        },
    })

    const saveSearchTerm = {
        formik: formik,
        data: [
            {
                label: 'Search Term' + '*',
                name: 'searchbar',
                type: 'text',
                placeholder: 'Enter the search value',
                class: 'col-12',
                autoFocus: true,
            },
        ],
    }

    useEffect(() => {
        if (responseStatus) {
            if (responseStatus.status === 'success') {
                if (responseStatus.from === 'deleteSearch') {
                    recentSearch(search)
                }
                if (responseStatus.from === 'saveSearch') {
                    setSaveSearchs(false)
                    recentSearch(search)
                }
            }
        }
    }, [responseStatus])

    const deleteRecent = (id) => {
        deleteSearch({ id: id })
    }

    const onHandlePage = (event, value) => {
        setSearch({ ...search, page: value })
    }

    useEffect(() => {
        if (isAuthenticated) {
            recentSearch(search)
        }
    }, [isAuthenticated, search])

    const openSaveSearchs = () => {
        setSaveSearchs(!saveSearchs)
    }

    return (
        <Layout props={props}>
            <DashboardLayout title="My Saved Searches">
                <div className="dashboard-wrapper savedSearches">
                    <SecondaryButton btnSize="ssBtn" onClick={openSaveSearchs}>
                        Add a new saved search
                    </SecondaryButton>
                    <div className="table-responsive">
                        <table className="table stab savesearch">
                            <thead>
                                <tr>
                                    <th>S.no</th>
                                    <th>Title</th>
                                    <th>Date added</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {recent_search.results.length ? (
                                    <>
                                        {recent_search.results.map((item, index) => {
                                            return (
                                                <tr key={index}>
                                                    <td data-label="S.no">{index + 1}</td>
                                                    <td data-label="Title">{item.searchtext}</td>
                                                    <td data-label="Date added">
                                                        {dateFormatFront(item.date_added)}
                                                    </td>
                                                    <td data-label="Action">
                                                        <div className="d-flex justify-content-center ssActBtn">
                                                            <Button
                                                                onClick={() =>
                                                                    handleRedirectInternal(
                                                                        history,
                                                                        `search?q=${item.searchtext}`,
                                                                    )
                                                                }
                                                            >
                                                                <span className="material-icons">
                                                                    open_in_new
                                                                </span>
                                                            </Button>
                                                            <Button
                                                                onClick={() =>
                                                                    deleteRecent(item.id)
                                                                }
                                                            >
                                                                <span className="material-icons">
                                                                    delete
                                                                </span>
                                                            </Button>
                                                        </div>
                                                    </td>
                                                </tr>
                                            )
                                        })}
                                    </>
                                ) : (
                                    <tr>
                                        <td colSpan="4">
                                            <div className="np-pro-box">{'No Saved Searches'} </div>
                                        </td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                    </div>
                    {recent_search.results.length ? (
                        <>
                            <div className="d-flex mt-3">
                                <div className="ml-auto mt-2">
                                    Showing {search.sh_limit * (search.page - 1) + 1} -{' '}
                                    {search.sh_limit * search.page > recent_search.total_pagecnt
                                        ? recent_search.total_pagecnt
                                        : search.sh_limit * search.page}{' '}
                                    of {recent_search.total_pagecnt}
                                </div>
                                <Pagination
                                    count={Math.ceil(recent_search.total_pagecnt / search.sh_limit)}
                                    page={search.page}
                                    onChange={onHandlePage}
                                />
                            </div>
                        </>
                    ) : null}
                </div>
                <CustomDialog
                    title={'Saved Searches'}
                    open={saveSearchs}
                    function={openSaveSearchs}
                >
                    <p>
                        You can now save this search into an organized folder in Profile Saved
                        Search letting you manage your results more easily.
                    </p>
                    <form className="login-ip-box" onSubmit={formik.handleSubmit}>
                        <div className="row">{mapData(saveSearchTerm)}</div>

                        <PrimaryButton label="Save Search" type="submit" />
                    </form>
                </CustomDialog>
            </DashboardLayout>
        </Layout>
    )
}

export default SavedSearch
