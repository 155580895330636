import React, { useEffect, useState, useContext, useCallback, useRef } from 'react'
import Layout from '../Layout'
import ProductContext from '../../../Product/context/product/productContext'
import AuthContext from '../../../Product/context/auth/authContext'
import { useHistory, NavLink } from 'react-router-dom'
import NoRecordsFound from '../../../Product/components/atoms/NoRecordsFound'
import './home.css'
import PrimaryButton from '../../../Product/components/atoms/PrimaryButton'
import { handleRedirectInternal, mapData } from '../../../Product/common/components'
import { Box, Button, ListItem, Tab, Tabs, Typography } from '@material-ui/core'
import PropTypes from 'prop-types'
import Loaders from '../../../Product/components/molecules/Loaders'
import jsonp from 'jsonp'
import AlertContext from '../../../Product/context/alert/alertContext'
import queryString from 'query-string'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import 'react-lazy-load-image-component/src/effects/blur.css'
import GridProduct from '../../../Component/ProductCard/gridProduct'
import { messageHandler } from '../../../Product/common/socketHandler'
import { socket, socketAuction } from '../../../Product/common/socket'
import MultisellerSlider from '../../../Component/SlidePanel/MultisellerSlider'
import { useTranslation } from 'react-i18next'

const TabPanel = ({ children, value, index, ...other }) => {
    return (
        <Typography
            component="div"
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && <Box>{children}</Box>}
        </Typography>
    )
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
}

function a11yProps(index) {
    return {
        id: `home-auctions-${index}`,
        'aria-controls': `home-auctions-tabpanel-${index}`,
    }
}

const Home = (props) => {
    const { user } = useContext(AuthContext)
    const alertContext = useContext(AlertContext)
    const productContext = useContext(ProductContext)
    const { search_allauctions, getAllSearchAuctions, search_allproducts, getAllSearchProducts } =
        productContext
    const { setAlert } = alertContext

    const authContext = useContext(AuthContext)
    const { isAuthenticated } = authContext
    const history = useHistory()
    const [loading, setLoading] = useState(true)
    const { t } = useTranslation()
    const [viewProduct, setViewProduct] = useState([])
    const [buyNowProduct, setBuyNowProduct] = useState([])
    const [selectedProduct, setSelectedProduct] = useState(null)
    const [value, setValue] = useState(0)
    const [inpData, setinpData] = useState('')
    const [totalItems, setTotalItem] = useState(0)

    const viewProductRef = useRef(viewProduct)
    const userRef = useRef(user)

    useEffect(() => {
        viewProductRef.current = viewProduct
        userRef.current = user
    })
    const [subLotSearch, setSubLotSearch] = useState({
        page: 1,
        orderby: '2',
        lotof: '',
        limit: 12,
        market_status: 'open',
        auction_io: 1,
        from: 'auction_only',
    })

    const [buynowSearch, setBuynowSearch] = useState({
        sh_limit: 12,
        from: 'buynow',
        page: 1,
        orderby: '1',
    })

    useEffect(() => {
        setSubLotSearch({ ...subLotSearch, type: 'active', auc_type: '2' })
        setBuynowSearch({ ...buynowSearch, type: 'active' })
    }, [value])

    useEffect(() => {
        setLoading(true)
        getAllSearchProducts(subLotSearch, 'activeHome')
        getAllSearchProducts(buynowSearch, 'galleryPage')
    }, [subLotSearch, buynowSearch])

    const handler = (message, type) => {
        messageHandler(
            message,
            viewProductRef.current,
            userRef.current,
            setAlert,
            setViewProduct,
            type,
        )
    }
    useEffect(() => {
        socket.on('realclosedupdates', (data) => {
            handler(data, 'realclosedupdates')
        })
        socket.on('bidAddtime', (data) => {
            handler(data, 'bidAddtime')
        })

        socketAuction.on('realclosedupdates', (data) => {
            handler(data, 'realclosedupdates')
        })
        socketAuction.on('bidAddtime', (data) => {
            handler(data, 'bidAddtime')
        })

        return () => {
            socket.off('realclosedupdates', (data) => {
                handler(data, 'realclosedupdates')
            })
            socket.off('bidAddtime', (data) => {
                handler(data, 'bidAddtime')
            })

            socketAuction.off('realclosedupdates', (data) => {
                handler(data, 'realclosedupdates')
            })
            socketAuction.off('bidAddtime', (data) => {
                handler(data, 'bidAddtime')
            })
        }
    }, [])

    useEffect(() => {
        setLoading(false)
        if (search_allproducts.from === 'activeHome') {
            setViewProduct(search_allproducts.results.length ? search_allproducts.results : [])
            setTotalItem(search_allproducts.total_pagecnt)
        } else if (search_allproducts.from === 'galleryPage') {
            setBuyNowProduct(search_allproducts.results.length ? search_allproducts.results : [])
        }
    }, [search_allproducts])

    useEffect(() => {
        setViewProduct([])
        setBuyNowProduct([])
        return () => {
            setViewProduct([])
            setBuyNowProduct([])
        }
    }, [])

    const filterValues = [
        {
            title: 'Category',
            type: 'check',
            name: 'category',
            options: global.searchCategories.filter((main) => {
                return true
            }),
        },
    ]

    const handleClose = () => setSelectedProduct(null)

    const handleClick = async (id) => {
        setSelectedProduct(id)
    }

    const handleChange = (event, newValue) => {
        setValue(newValue)
    }

    const [state, setState] = React.useState({
        bottom: false,
    })

    const toggleDrawer = (anchor, open) => (event) => {
        setState({ ...state, [anchor]: open })
    }

    const subscribe = () => {
        if (inpData) {
            if (!/\S+@\S+\.\S+/.test(inpData)) {
                return setAlert('Invalid email address', 'error')
            }

            const data = {
                EMAIL: inpData,
            }

            jsonp(
                `//${global.storeConfigration?.subscribe_code.value}${queryString.stringify(data)}`,
                { param: 'c' },
                (err, data) => {
                    //console.log('display', data.result)
                    if (err) {
                        return false
                    }
                    if (data.result === 'success') {
                        setinpData('')
                        return setAlert(data.msg, 'success')
                    }
                    if (data.result === 'error') {
                        return setAlert(data.msg, 'warning')
                    }
                },
            )
        }
        setinpData('')
    }
    return (
        <Layout props={props}>
            <div className="home">
                <div className="customContainer auctionListCnt">
                    <div className="homeCnt">
                        <>
                            <h2 className="auctionListTitle">Active Lots</h2>

                            {loading ? (
                                <div className="homeLoader">
                                    <Loaders
                                        name="product_grid_view"
                                        isLoading={loading}
                                        loop={6}
                                    />
                                </div>
                            ) : viewProduct.filter(
                                  (data) => data.market_status == 'open' && data.remainingDay >= 0,
                              ).length === 0 ? (
                                <NoRecordsFound />
                            ) : (
                                <div className="homeAuctions mobileView liquidationBuyCard">
                                    <>
                                        {viewProduct.map((data, index) => (
                                            <GridProduct
                                                data={data}
                                                key={index}
                                                type="auction"
                                                selectedProduct={selectedProduct}
                                                link={`/gallery/product-view/${data.id}`}
                                                handleClick={handleClick}
                                                timerTheme=""
                                                sliderTheme="multiseller"
                                                currencyType="cad"
                                                cardTheme=""
                                                bidBtnLabel="Place Bid"
                                            />
                                        ))}
                                    </>
                                </div>
                            )}
                            <div
                                className="viewAllBtn"
                                onClick={() => handleRedirectInternal(history, `search`)}
                            >
                                <hr />
                                <Button>View All</Button>
                                <hr />
                            </div>
                        </>

                        <>
                            <h2 className="auctionListTitle">Buy Now Products</h2>

                            {loading ? (
                                <div className="homeLoader">
                                    <Loaders
                                        name="product_grid_view"
                                        isLoading={loading}
                                        loop={6}
                                    />
                                </div>
                            ) : buyNowProduct.length === 0 ? (
                                <NoRecordsFound />
                            ) : (
                                <div className="homeAuctions mobileView liquidationBuyCard">
                                    <>
                                        {buyNowProduct.map((data, index) => (
                                            <GridProduct
                                                data={data}
                                                key={index}
                                                type="buynow"
                                                selectedProduct={selectedProduct}
                                                link={`/gallery/product-view/${data.id}`}
                                                handleClick={handleClick}
                                                timerTheme=""
                                                sliderTheme="multiseller"
                                                noTimer={true}
                                                currencyType="cad"
                                                cardTheme=""
                                                bidBtnLabel="Place Bid"
                                            />
                                        ))}
                                    </>
                                </div>
                            )}
                            <div
                                className="viewAllBtn"
                                onClick={() => handleRedirectInternal(history, `gallery`)}
                            >
                                <hr />
                                <Button>View All</Button>
                                <hr />
                            </div>
                        </>
                    </div>
                </div>

                {global?.storeConfigration?.subscribe?.value && (
                    <div className="homeContact">
                        <h2>Get the latest deals</h2>
                        <p>Subscribe us to get latest updates</p>
                        <div className="subsBox">
                            <input
                                type="text"
                                name="q"
                                value={inpData}
                                placeholder="Enter email address"
                                onChange={(e) => {
                                    setinpData(e.target.value)
                                }}
                            />
                            <Button>
                                <span className="material-icons" onClick={subscribe}>
                                    send
                                </span>
                            </Button>
                        </div>
                    </div>
                )}
            </div>
            <MultisellerSlider
                storeTheme="ArtAuction"
                selectedLot={selectedProduct}
                handleClose={handleClose}
                // currency="cad"
                type={props.type ? props.type : ''}
                conditions={'Collectibles'}
            />
        </Layout>
    )
}

export default Home
