import React, { useEffect } from 'react'
import Slider from 'react-slick'
import ReactImageMagnify from 'react-image-magnify'
const MagnifyImageGallery = ({ images }) => {
    const settings = {
        customPaging: function (i) {
            return (
                <a>
                    <img src={images[i]?.thumbnail} className="img-fluid img-thumbnail" />
                </a>
            )
        },
        dots: true,
        // dotsClass: 'slick-dots slick-thumb',
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
    }

    return (
        <div className="slider-container">
            <Slider {...settings}>
                {images.map((val, key) => (
                    <div key={key}>
                        <ReactImageMagnify
                            {...{
                                smallImage: {
                                    alt: '',
                                    isFluidWidth: true,
                                    src: val?.thumbnail,
                                    sizes: '(max-width: 480px) 100vw, (max-width: 1200px) 30vw, 360px',
                                },
                                largeImage: {
                                    src: val?.original,
                                    width: 3840,
                                    height: 2160,
                                },
                                lensStyle: {
                                    background: 'hsla(0, 0%, 100%, .3)',
                                    border: '1px solid #ccc',
                                },
                                isHintEnabled: true,
                                shouldHideHintAfterFirstActivation: false,
                                enlargedImagePosition: 'over',
                                className: 'slidImg img-fluid',
                            }}
                        />
                    </div>
                ))}
            </Slider>
        </div>
    )
}

export default MagnifyImageGallery
