import React, { useState, useEffect, useContext } from 'react'
import ProductContext from '../../../Product/context/product/productContext'
import { useFormik } from 'formik'
import { currencyFormat, mapData } from '../../../Product/common/components'
import PrimaryButton from '../../../Product/components/atoms/PrimaryButton'
import * as Yup from 'yup'
import Switch from '@material-ui/core/Switch'
import FormGroup from '@material-ui/core/FormGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'

const ShippingRates = ({ width, height, length, weight, fromzipcode }) => {
    const productContext = useContext(ProductContext)
    const { getShippingRates, shipping_rates, responseStatus } = productContext
    const [rate, setRate] = useState('')
    const [toggle, setToggle] = useState(false)

    const validation = Yup.object({
        zipcode: Yup.string().required('Required!'),
    })
    const formik = useFormik({
        initialValues: {
            zipcode: '',
            width,
            height,
            length,
            weight,
            fromzipcode,
            mode:
                process.env.REACT_APP_URL.includes('forwardapidev') ||
                process.env.REACT_APP_URL.includes(':8080')
                    ? 'sandbox'
                    : 'live',
        },
        validationSchema: validation,
        onSubmit: (values) => {
            getShippingRates(values)
        },
    })
    const field = [
        {
            label: 'Enter your postal code',
            name: 'zipcode',
            type: 'text',
            placeholder: 'Enter your postal code',
            class: '',
            size: 'small',
            formik: formik,
        },
    ]

    const handleToggle = () => {
        setToggle(!toggle)
    }

    useEffect(() => {
        if (shipping_rates.ship_rate) {
            setRate(`Estimated charges: ${currencyFormat(shipping_rates.ship_rate)}`)
        }
    }, [shipping_rates])
    useEffect(() => {
        if (responseStatus) {
            if (responseStatus.from === 'getshippingrates') {
                if (responseStatus.status === 'error') {
                    setRate('')
                }
            }
        }
    }, [responseStatus])
    return (
        <div className="shippingCnt">
            <FormGroup row>
                <FormControlLabel
                    control={
                        <Switch
                            checked={toggle}
                            onChange={handleToggle}
                            name="toggle"
                            inputProps={{ 'aria-label': 'secondary checkbox' }}
                        />
                    }
                    label="Calculate shipping charges"
                />
            </FormGroup>

            {toggle ? (
                <form onSubmit={formik.handleSubmit}>
                    <h3 className="shippingTitle"></h3>
                    <div
                        className={`shippingCntInner d-flex justify-content-start align-items-start`}
                    >
                        {Object.values(mapData(field))}
                        <PrimaryButton type="submit" btnSize="small">
                            <span className="material-icons">done</span>{' '}
                        </PrimaryButton>
                    </div>
                    {rate ? <h6 className="shippingCharges">{rate}</h6> : ''}
                </form>
            ) : null}
        </div>
    )
}

export default ShippingRates
