import { Button } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import { Link, NavLink, useHistory } from 'react-router-dom'
import PrimaryButton from '../../../Product/components/atoms/PrimaryButton'
import { Copyrightinfo, Logo } from '../../../utils'
import DonationModal from '../DonationModal'
import Footernav from '../../../utils/FooterNavigation/footernav'
import './Footer.css'
import { handleRedirectInternal } from '../../../Product/common/components'

function Footer(props) {
    const contactEmail = global?.storeDetails?.contact_email
    const contactPhone = global?.storeDetails?.phone
    const storename = global?.storeDetails?.name
    const storeaddress = global?.storeDetails?.address
    const facebookLink = global?.storeDetails?.facebook
    const twitterLink = global?.storeDetails?.twitter
    const googleLink = global?.storeDetails?.google
    const instagramLink = global?.storeDetails?.instagram
    const [donationModal, setDonationModal] = useState(false)
    const history = useHistory()
    const [bottomFab, setBottomFab] = useState(false)

    const scrollFunction = () => {
        var pageHeight = Math.max(
            document.body.scrollHeight,
            document.body.offsetHeight,
            document.documentElement.clientHeight,
            document.documentElement.scrollHeight,
            document.documentElement.offsetHeight,
        )
        if (window.innerHeight + window.scrollY >= pageHeight - 100) {
            //console.log('You are at the bottom of the page.')
            setBottomFab(true)
        } else {
            setBottomFab(false)
        }
    }

    useEffect(() => {
        window.addEventListener('scroll', scrollFunction)
    }, [])
    const globalGirlsTheme = global.storeConfigration?.bodyClass.value === 'globalgirls'

    return (
        <footer className="footer-main-standard noPrint">
            <DonationModal open={donationModal} handleClose={() => setDonationModal(false)} />
            {!window.location.href.includes('/product-buyer-auction/') ? (
                <PrimaryButton
                    onClick={() =>
                        handleRedirectInternal(
                            history,
                            localStorage.getItem('auction_id')
                                ? `search/product-buyer-auction/${localStorage.getItem(
                                      'auction_id',
                                  )}?value=2`
                                : 'auctions',
                        )
                    }
                    btnSize={`ftrFAB ${bottomFab ? 'fxdFab' : ''}`}
                    label={<span className="material-icons">volunteer_activism</span>}
                    // label="Make a Donation"
                />
            ) : (
                ''
            )}
            <div className="customContainer">
                <div className="row">
                    <div className="col-12 ftrDtlCntnr pb-4">
                        <div className="footLogoCnt">
                            <Logo className="footerLogo" />
                            <div className="adrsInfo">
                                <p>
                                    {storename ? storename : 'Your Store LLC'}
                                    <br />
                                    {globalGirlsTheme ? (
                                        ''
                                    ) : (
                                        <>
                                            {storeaddress
                                                ? storeaddress
                                                : '1221 West Campbell Road, Suite 181,'}
                                        </>
                                    )}

                                    {/* <br />
                                    {contactPhone ? 'Phone :' + contactPhone : ''} */}
                                </p>
                            </div>
                        </div>
                        <div className="footerMisc">
                            <ul className="footerLinks globalFooter">
                                {/* <li>
                                    <NavLink activeClassName="active" to="/termsOfService">
                                        How it works
                                    </NavLink>
                                </li> */}
                                <li>
                                    <NavLink activeClassName="active" to="/aboutus">
                                        About Us
                                    </NavLink>
                                </li>
                                <li>
                                    <NavLink activeClassName="active" to="/faq">
                                        FAQs
                                    </NavLink>
                                </li>
                                {/* <li>
                                    <NavLink activeClassName="active" to="/aboutus">
                                        Contact Us
                                    </NavLink>
                                </li> */}
                                {/* <li>
                                    <NavLink activeClassName="active" to="/termsOfService">
                                        Terms & Conditions
                                    </NavLink>
                                </li> */}
                                <Footernav />
                            </ul>
                            <ul className="footerLinks">
                                {globalGirlsTheme ? (
                                    ''
                                ) : (
                                    <>
                                        <li>Contact Us</li>
                                        <li>
                                            <a
                                                href={`mailto:${contactEmail}`}
                                                className="d-flex align-items-center cursorDecoy"
                                            >
                                                <span className="material-icons mr-2">mail</span>
                                                {contactEmail ? contactEmail : 'support@auction.io'}
                                            </a>
                                        </li>
                                        <li>
                                            <p className="d-flex align-items-center cursorDecoy">
                                                <span className="material-icons mr-2">call</span>
                                                {contactPhone ? contactPhone : '+1 972-200-551'}
                                            </p>
                                        </li>
                                    </>
                                )}
                            </ul>
                        </div>
                    </div>
                    <div className="col-12">
                        <Copyrightinfo />
                    </div>
                </div>
            </div>
        </footer>
    )
}

export default Footer
