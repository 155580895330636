import React, { useState, useContext, useEffect } from 'react'
import PaginationComponent from '../../../Component/Pagination'
import { Link, useHistory } from 'react-router-dom'
import Bidhistory from '../../../Component/Bidhistory'
import './product.css'
import 'react-confirm-alert/src/react-confirm-alert.css'
import AuthContext from '../../../Product/context/auth/authContext'
import LotListProduct from '../../../Component/ProductCard/lotListProduct'
import { Button } from '@material-ui/core'
import { getAdsImage, shuffleArray } from '../../../Product/common/components'
import { useMediaQuery } from 'react-responsive'
import NoRecordsFound from '../../../Product/components/atoms/NoRecordsFound'

const SubLotsComponent = (props) => {
    const authContext = useContext(AuthContext)
    const [selectedHistory, setSelectedHistory] = useState({})
    const { user, isAuthenticated } = authContext
    const [auctionTimer, setAuctionTimer] = useState('')
    const history = useHistory()
    const params = new URLSearchParams(window.location.search.substring(1))
    const [auctionView, setAuctionView] = useState(params.get('view') ? params.get('view') : 'List')
    const openHistory = (property) => {
        setSelectedHistory(property)
    }
    const handleAuctionView = (type) => {
        let currentUrlParams = new URLSearchParams(window.location.search)
        currentUrlParams.set('view', type)
        history.push(history.location.pathname + '?' + currentUrlParams.toString())
    }

    // useEffect(() => {
    //     if (socket) {
    //         socket.on('sliservertime', (data) => {
    //             if (moment(data.dTime).isValid()) {
    //                 setAuctionTimer(data.dTime)
    //             }
    //         })
    //     }
    // }, [socket])

    const isMobile = useMediaQuery({
        query: '(max-width: 500px)',
    })

    useEffect(() => {
        setAuctionView(params.get('view') ? params.get('view') : '')
    }, [window.location.search])

    const imageChangeInterval = global?.pluginConfiguration?.advertisement?.refresh_key * 60000 // Convert minutes to milliseconds

    const [aucAds, setAucAds] = useState({
        deskAucRight: '',
        deskAucLeft: '',
        mobAucTop: '',
        mobAucBottom: '',
    })

    const [activeAucAdsIndex, setActiveAucAdsIndex] = useState({
        deskAucRight: 0,
        deskAucLeft: 0,
        mobAucTop: 0,
        mobAucBottom: 0,
    })
    useEffect(() => {
        setAucAds((prevAucAds) => ({
            deskAucRight: shuffleArray(getAdsImage(1, 2, 4)),
            deskAucLeft: shuffleArray(getAdsImage(1, 2, 3)),
            mobAucTop: shuffleArray(getAdsImage(2, 2, 1)),
            mobAucBottom: shuffleArray(getAdsImage(2, 2, 5)),
        }))
    }, [])

    function updateRandomIndex(array) {
        const randomIndex = Math.floor(Math.random() * array.length)
        return randomIndex
    }

    useEffect(() => {
        const interval = setInterval(() => {
            setActiveAucAdsIndex((prevActiveIndex) => ({
                deskAucRight: updateRandomIndex(aucAds?.deskAucRight),
                deskAucLeft: updateRandomIndex(aucAds?.deskAucLeft),
                mobAucTop: updateRandomIndex(aucAds?.mobAucTop),
                mobAucBottom: updateRandomIndex(aucAds?.mobAucBottom),
            }))
        }, imageChangeInterval)
        return () => clearInterval(interval)
    }, [aucAds])

    console.log(props?.lotData.length, 'totalCount')

    return (
        <div className="auctionsAdsCnt">
            {global?.pluginConfiguration?.advertisement?.enable == 1 ? (
                <div className="siteAds adsAuc mobileView">
                    {isMobile
                        ? aucAds?.mobAucTop[activeAucAdsIndex?.mobAucTop]
                        : aucAds?.deskAucLeft[activeAucAdsIndex?.deskAucLeft]}
                </div>
            ) : null}
            <div
                className="table-responsive lineItemBidTable mt-2 sublots-table mySubmitTable"
                id="sublot"
            >
                <div className="results-wrap mb-3">
                    <div>
                        {props.subLotDataCount !== 0
                            ? `1 - ${props.subLotSearch.limit} of ${props.subLotDataCount} `
                            : `${props.subLotDataCount} `}
                        Results
                    </div>
                    <div className="rpp-wrap">
                        {props.showviews ? (
                            <div className="d-flex justify-content-end align-items-center flex-wrap mr-3">
                                <div className="auctionsMiscFilter">
                                    <div className="gridListToggle">
                                        <Button
                                            className={auctionView != 'List' ? 'active' : ''}
                                            onClick={() => handleAuctionView('Grid')}
                                        >
                                            <img src="/lots-images/loa-grid-icon.svg" alt="" />
                                        </Button>
                                        <Button
                                            className={auctionView === 'List' ? 'active' : ''}
                                            onClick={() => handleAuctionView('List')}
                                        >
                                            <img src="/lots-images/loa-list-icon.svg" alt="" />
                                        </Button>
                                    </div>
                                </div>
                            </div>
                        ) : (
                            ''
                        )}
                        <select
                            className="pr-5"
                            onChange={(e) => {
                                props.setSubLotSearch({
                                    ...props.subLotSearch,
                                    limit: e.target.value,
                                    page: 1,
                                })
                            }}
                            value={props.subLotSearch.limit}
                        >
                            <option value={''}>Per Page</option>
                            <option value={36}>36</option>
                            <option value={72}>72</option>
                            <option value={108}>108</option>
                        </select>
                    </div>
                </div>
                {props.subLotDataCount > 0 ? (
                    auctionView == 'List' ? (
                        <>
                            <LotListProduct
                                listview={true}
                                searchResult={props.lotData}
                                className="liquidationBuyCard"
                                cardType={'list'}
                                setViewProduct={props.setSubLotData}
                                auction_city={props.auction_city}
                            />
                        </>
                    ) : (
                        <LotListProduct
                            listview={false}
                            searchResult={props.lotData}
                            className="liquidationBuyCard"
                            cardType={'grid'}
                            setViewProduct={props.setSubLotData}
                            auction_city={props.auction_city}
                        />
                    )
                ) : (
                    <NoRecordsFound></NoRecordsFound>
                )}
                <PaginationComponent
                    onPageChange={(pageNumber) => {
                        document.getElementById('sublot').scrollIntoView()
                        props.setSubLotSearch({ ...props.subLotSearch, page: pageNumber })
                    }}
                    totalProductCount={props.subLotDataCount}
                    currentPage={props.subLotSearch.page}
                    resultToShow={props.subLotSearch.limit}
                />
                <Bidhistory
                    open={Object.keys(selectedHistory).length !== 0}
                    value={selectedHistory}
                    serverTime={props.serverTimeSocket}
                    handleClose={() => openHistory({})}
                />
            </div>
            {global?.pluginConfiguration?.advertisement?.enable == 1 ? (
                <div className="siteAds adsAuc">
                    {isMobile
                        ? aucAds?.mobAucBottom[activeAucAdsIndex?.mobAucBottom]
                        : aucAds?.deskAucRight[activeAucAdsIndex?.deskAucRight]}
                </div>
            ) : null}
        </div>
    )
}

export default SubLotsComponent
