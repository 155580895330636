import React from 'react'

const BidStatus = ({ bidTopStatus }) => {
    const isBidDepotProject = document.querySelector('body').classList.contains('BidAuctionDepot')

    return (
        <div
            className={`productStatus ${
                global?.storeConfigration?.hasOwnProperty('bid_box_bidding_message')
                    ? 'hwaiPosiChange productStatus'
                    : ''
            }`}
        >
            {bidTopStatus ? (
                <h4
                    className={`p4 ${
                        bidTopStatus.includes('bought') || bidTopStatus.includes('won')
                            ? 'won'
                            : bidTopStatus.includes('lost')
                            ? 'lost'
                            : bidTopStatus.includes('losing')
                            ? 'losing'
                            : bidTopStatus.includes('outbid')
                            ? 'outbid'
                            : bidTopStatus.includes('reserve')
                            ? 'reserve'
                            : bidTopStatus.includes('winning')
                            ? 'winning'
                            : bidTopStatus.includes('proxy')
                            ? 'proxy'
                            : ''
                    }`}
                >
                    {isBidDepotProject
                        ? bidTopStatus.replace(/autoBid/g, 'max bid')
                        : bidTopStatus.replace(/autoBid/g, 'proxy bid')}
                </h4>
            ) : (
                ''
            )}
        </div>
    )
}

export default BidStatus
