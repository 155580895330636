/*eslint-disable*/
import React, { useContext, useState, useEffect, useRef } from 'react'
import Box from '@material-ui/core/Box'
import Container from '@material-ui/core/Container'
import { Link, useHistory } from 'react-router-dom'
import {
    currencyFormat,
    dateFormatFront,
    handleRedirectInternal,
    mapData,
    dateTimeFormatFunction,
} from '../../../Product/common/components'
import { Redirect } from 'react-router-dom'
import ProductContext from '../../../Product/context/product/productContext'
import AuthContext from '../../../Product/context/auth/authContext'
import { Pagination } from '@material-ui/lab'
import Timer from '../../../Product/common/timer'
import { Button, IconButton } from '@material-ui/core'
import MultisellerSlider from '../../../Component/SlidePanel/MultisellerSlider'
import NoRecordsFound from '../../../Product/components/atoms/NoRecordsFound'
import { getImageurl, imageError } from '../../../utils/commonFunctions'
import Loader from '../../../assets/loader'
import CustomSelect from '../../../Product/components/atoms/Inputs/CustomSelect'
import AlertContext from '../../../Product/context/alert/alertContext'
import PrimaryButton from '../../../Product/components/atoms/PrimaryButton'
import CustomDialog from '../../../Product/components/organisms/Dialog'
import CheckBox from '../../../Product/components/atoms/CheckBox'
import { socket, socketAuction } from '../../../Product/common/socket'
import { messageHandler } from '../../../Product/common/socketHandler'

import { useFormik } from 'formik'
import EditReservePrice from '../EditReservePrice'
import BuynowToAuction from '../BuynowToAuction'
import CurrencyConverterFormat from '../CurrencyConverter/CurrencyConverterFormat'
import UserMessage from '../Message'
import { useTranslation } from 'react-i18next'
import ListingFee from '../PostProduct/ListingFee'
import Popup from '../../../Product/components/organisms/Popup'
import UserContext from '../../../Product/context/user/userContext'
import * as Yup from 'yup'
import moment from 'moment'
import SlidePanel from '../../../Component/SlidePanel'
const SubLots = ({
    status,
    lotof,
    storeTheme,
    type,
    searchBar,
    resultsPerPage,
    statusValue,
    changeStatus,
    searchType,
    withoutAuction,
    viewPage,
    multipleChangeStatus,
    copyItem,
    auction_status,
    edit_need_after_bid,
    allAuctions,
    setAllAuctions,
    theme,
    set_start_and_end_date,
    multiple_relist,
    date_input_change,
    need_auction_type,
    sliderTheme,
    relist_automatic,
}) => {
    const productContext = useContext(ProductContext)
    const {
        seller_allproducts,
        getAllSellerProducts,
        changeMarketStatus,
        responseStatus,
        relist,
        repost,
        deleteItem,
    } = productContext

    const authContext = useContext(AuthContext)
    const alertContext = useContext(AlertContext)
    const userContext = useContext(UserContext)
    const { getAllShippingAddress, all_shipping_address } = userContext
    const [popup_open, setPopupopup] = useState(false)
    const [selectAddress, setSelectAddress] = useState({})
    const [display_address, setDisplayAddress] = useState({})
    const { isAuthenticated, isAdmin, user } = authContext
    const { setAlert } = alertContext
    const { t } = useTranslation()
    const [selectedProduct, setSelectedProduct] = useState(null)
    const [productdetails, setProductDetails] = useState([])
    const [loading, setLoading] = useState(true)
    const [btnLoading, setBtnLoading] = useState(false)
    const history = useHistory()
    const [toInvoice, settoInvoice] = useState(false)
    const [lotId, setLotId] = useState([])
    const [deleteLotId, setDeleteLotId] = useState('')
    const [changeStatusValue, setChangeStatusValue] = useState('')
    const [checked, setChecked] = useState(false)
    const [open, setOpen] = useState(false)
    const [openDelete, setOpenDelete] = useState(false)
    const [openRelist, setOpenRelist] = useState(false)
    const [openRepost, setOpenRepost] = useState(false)
    const [item] = seller_allproducts.result
    const [searchValue, setSearchValue] = useState('')
    const [messagePopup, setMessagePopup] = useState('')
    const [backImage, setBackImage] = useState('')
    const [toInvoiceID, settoInvoiceID] = useState({
        inv_id: 0,
        user_id: 0,
    })
    const [listingFeePopup, setListingFeePopup] = useState(false)
    const [search, setSearch] = useState({
        status: statusValue ? statusValue : '',
        limit: 12,
        page: 1,
        lotof: lotof ? lotof : '',
        search: '',
        type: searchType,
        without_auction: withoutAuction,
    })
    const viewProductRef = useRef(productdetails)
    const userRef = useRef(user)
    useEffect(() => {
        setLoading(false)
    }, [seller_allproducts])
    const onHandlePage = (event, value) => {
        if (global.storeDetails?.theme !== 7) {
            history.push(`/dashboard/mylots?page=${value}`)
        } else {
            history.push(`/dashboard/buynow?page=${value}`)
        }
    }

    useEffect(() => {
        const params = new URLSearchParams(window.location.search.substring(1))
        setSearch({ ...search, page: params.get('page') || 1 })
    }, [window.location.search])
    // useEffect(() => {
    //     window.scrollTo(0, 0)
    // })
    const handleClose = () => setSelectedProduct(null)
    const toggleOpen = () => {
        setOpen(!open)
    }
    const toggleRelistOpen = (id) => {
        if (relist_automatic) {
            var send_data = {
                start_date: new Date(),
                end_date: new Date(new Date().setFullYear(new Date().getFullYear() + 10)),
                lot_id: id,
                lot_id_multiple: [],
            }
            relist(send_data)
        } else {
            formik.setFieldValue('lot_id', id)
            setOpenRelist(!openRelist)
        }
    }
    const toggleRelistOpenMultiple = (id) => {
        formik.setFieldValue('lot_id_multiple', id)
        setOpenRelist(!openRelist)
    }
    const toggleRepostOpenMultiple = (id) => {
        formik.setFieldValue('lot_id_multiple', id)
        setOpenRepost(!openRepost)
    }
    const categoryTypes = {}
    global.allCategories.map((catgeory) => (categoryTypes[catgeory.id] = catgeory))
    const handleClick = async (product) => {
        if (viewPage) {
            if (theme === 'auctioneer') {
                return handleRedirectInternal(
                    history,
                    `productview/${product.id}/${product.auction ? 'auction' : 'buynow'}/${
                        (product?.custom_field_9?.includes('}')
                            ? JSON.parse(product.custom_field_9)
                            : {}
                        )?.game_theme
                            ?.toLowerCase()
                            ?.split(' ')
                            ?.join('-') +
                        '-' +
                        product?.subcategory
                            ?.split(',')
                            ?.map((val) => categoryTypes[val]?.description?.toLowerCase() ?? '')
                            ?.join('-')
                            .split(' ')
                            .join('-')
                    }`,
                )
            }
            return handleRedirectInternal(history, `productView/${product.id}`)
        }
        setSelectedProduct(product.id)
    }
    const handleSearch = (event) => {
        event.preventDefault()
        setSearch({
            ...search,
            page: 1,
            search: searchValue,
        })
    }

    const checkBoxToggle = (value) => !value
    useEffect(() => {
        if (isAuthenticated) {
            setLoading(true)
            getAllSellerProducts({
                ...search,
                serial_number: global?.storeConfigration?.unique_slot4?.value == 1 ? 1 : undefined,
            })
        }
    }, [search, isAuthenticated])
    useEffect(() => {
        if (seller_allproducts.result.length > 0) {
            seller_allproducts.result.map((val) => {
                val.checked = false
            })
            setProductDetails(seller_allproducts.result)
        } else {
            setProductDetails([])
        }
    }, [seller_allproducts])

    useEffect(() => {
        if (responseStatus) {
            if (responseStatus.from === 'changeMarketStatus') {
                setBtnLoading(false)
                if (responseStatus.status === 'success') {
                    setLotId([])
                    setChangeStatusValue('')
                    setOpen(false)
                    setLoading(true)
                    getAllSellerProducts(search)
                }
            }
            if (responseStatus.from === 'relist') {
                setBtnLoading(false)
                if (responseStatus.status === 'success') {
                    formik.resetForm()
                    setOpenRelist(false)
                    setLoading(true)
                    getAllSellerProducts(search)
                }
            }
            if (responseStatus.from === 'repost') {
                setBtnLoading(false)
                if (responseStatus.status === 'success') {
                    formik.resetForm()
                    setOpenRepost(false)
                    setLoading(true)
                    getAllSellerProducts(search)
                }
            }
            if (responseStatus.from === 'deleteItem') {
                setBtnLoading(false)
                if (responseStatus.status === 'success') {
                    setDeleteLotId('')
                    setOpenDelete(false)
                    setLoading(true)
                    getAllSellerProducts(search)
                }
            }
            if (responseStatus.from === 'buynow_to_auction') {
                if (responseStatus.status === 'success') {
                    getAllSellerProducts(search)
                }
            }
        }
    }, [responseStatus])
    useEffect(() => {
        viewProductRef.current = productdetails
        userRef.current = user
    })
    const handler = (message, type) => {
        messageHandler(
            message,
            viewProductRef.current,
            userRef.current,
            setAlert,
            setProductDetails,
            type,
        )
    }
    useEffect(() => {
        socket.on('realclosedupdates', (data) => {
            handler(data, 'realclosedupdates')
        })
        socket.on('bidAddtime', (data) => {
            handler(data, 'bidAddtime')
        })

        return () => {
            socket.off('realclosedupdates', (data) => {
                handler(data, 'realclosedupdates')
            })
            socket.off('bidAddtime', (data) => {
                handler(data, 'bidAddtime')
            })
        }
    }, [])
    const changeStatusSubmit = () => {
        if (!changeStatusValue) {
            return setAlert('Kindly select status!', 'warning')
        }
        setBtnLoading(true)
        if (
            global.pluginConfiguration?.reseller_listing_fee?.enable == 1 &&
            changeStatusValue == 'open'
        ) {
            setOpen(false)
            setListingFeePopup(lotId)
        } else {
            changeMarketStatus({
                lot_id: lotId,
                status: changeStatusValue,
            })
        }
    }

    const start_max_date_config = (date, days) => {
        var s_d = new Date(date)
        s_d.setDate(s_d.getDate() + parseInt(days))
        return s_d
    }

    const validationArray = Yup.object({
        start_date: Yup.string().nullable().required('Incorrect Start Date'),
        end_date: Yup.string().nullable().required('Incorrect End Date'),
    })

    const formik = useFormik({
        initialValues: {
            start_date: set_start_and_end_date ? dateTimeFormatFunction(new Date()) : null,
            end_date: null,
            lot_id: '',
            lot_id_multiple: [],
        },
        validationSchema: validationArray,
        onSubmit: async (values) => {
            console.log('iiiiinnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnn')
            setBtnLoading(true)
            // if (date_input_change) {
            //     values.start_date = new Date(values.start_date).toString()
            //     values.end_date = new Date(values.end_date).toString()
            // }
            //values.lot_id
            if (openRepost) {
                return repost(values)
            }
            console.log('LLLLLLLLLLLLLLLLLLL', values)
            relist(values)
            formik.values.start_date = null
            formik.values.end_date = null
        },
    })

    useEffect(() => {
        if (formik.values.end_date && formik.values.start_date) {
            if (moment(formik.values.end_date).isSameOrBefore(formik.values.start_date)) {
                formik.values.end_date = null
            }
        }
    }, [formik.values.start_date, formik.values.end_date])

    useEffect(() => {
        const params = new URLSearchParams(window.location.search.substring(1))
        if (productdetails.length > 0 && params.get('moveto')) {
            var lot_id = []

            productdetails.map((data) => {
                if (data.market_status == 'draft') {
                    lot_id.push(data.id)
                }
            })
            if (lot_id.length > 0) {
                changeMarketStatus({
                    lot_id: lot_id,
                    status: 'open',
                })
                handleRedirectInternal(history, 'dashboard/mylots')
            }
        }
        if (allAuctions?.result && productdetails.length) {
            let temp_arr = allAuctions.result
            temp_arr.map((val) => {
                if (val.id == lotof) {
                    val.activecount = productdetails.filter(
                        (value) => value.market_status === 'open',
                    ).length
                    val.draftcount = productdetails.filter(
                        (value) => value.market_status === 'draft',
                    ).length
                    //console.log(val.activecount, val.draftcount)
                }
            })
            setAllAuctions({
                ...allAuctions,
                result: temp_arr,
            })
        }
    }, [productdetails])

    const repostHours = (closedDate, hrs) =>
        new Date(closedDate).addHours(parseInt(hrs)) < new Date()
    const pageOptions = [
        {
            value: '12',
            show: 'Results 12 per page',
        },
        {
            value: '24',
            show: 'Results 24 per page',
        },
        {
            value: '36',
            show: 'Results 36 per page',
        },
    ]

    const statusShow = [
        {
            value: '',
            show: 'All',
        },
        {
            value: 'open',
            show: 'Active',
        },
        {
            value: 'sold',
            show: 'Sold',
        },
        {
            value: 'closed',
            show: 'Unsold',
        },
        {
            value: 'draft',
            show: 'Draft',
        },
    ]
    const changeStatusShow = [
        {
            value: '',
            show: 'Select Status',
        },
        {
            value: 'open',
            show: 'Live',
        },
        {
            value: 'draft',
            show: 'Draft',
        },
    ]
    const fields = [
        {
            label: 'Start date *',
            placeholder: 'Enter Start date',
            type: date_input_change ? 'date_text' : 'datetime',
            disablePast: true,
            class: 'col-12 dates',
            name: 'start_date',
            formik: formik,
            upperLabel: date_input_change ? 1 : 0,
            maxDate: set_start_and_end_date
                ? dateTimeFormatFunction(
                      start_max_date_config(dateTimeFormatFunction(new Date()), 14),
                  )
                : '',
        },
        {
            label: 'End date *',
            placeholder: 'Enter End date',
            type: date_input_change ? 'date_text' : 'datetime',
            disablePast: true,
            class: 'col-12 dates',
            name: 'end_date',
            formik: formik,
            upperLabel: date_input_change ? 1 : 0,
            minDate: set_start_and_end_date
                ? dateTimeFormatFunction(
                      start_max_date_config(dateTimeFormatFunction(formik.values.start_date), 1),
                  )
                : '',
            maxDate: set_start_and_end_date
                ? dateTimeFormatFunction(
                      start_max_date_config(dateTimeFormatFunction(formik.values.start_date), 5),
                  )
                : '',
        },
    ]

    useEffect(() => {
        if (all_shipping_address.length && global.storeConfigration?.ship_track_info?.value == 1) {
            // setSelectAddress(all_shipping_address)
            setDisplayAddress(all_shipping_address[0])
            setPopupopup(true)
        }
    }, [all_shipping_address])

    const handleClosepopup = () => {
        setOpenRelist(false)
        formik.values.start_date = null
        formik.values.end_date = null
    }

    const openshippingtracking = (data) => {
        // selectAddress.map((val) => {
        //     if (parseInt(val.id) === parseInt(data.shipping_addr_id)) {
        //         setDisplayAddress({ ...val, ...data })
        //     }
        // })
        setSelectAddress(data)
        getAllShippingAddress({ shipping_addrid_io: data.shipping_addr_id })
    }

    const tracking_formik = Yup.object({
        tracking_id: Yup.string().required('Required!'),
    })

    const formik_shipping_form = useFormik({
        initialValues: {
            tracking_id: '',
        },
        validationSchema: tracking_formik,
        onSubmit: async (values) => {},
    })

    const form_ship_data = [
        {
            label: '',
            placeholder: 'Enter tracking number',
            type: 'text',
            class: 'col-sm-6 col-12',
            name: 'tracking_id',
            formik: formik_shipping_form,
        },
    ]

    return (
        <div>
            {toInvoice ? (
                <Redirect to={`invoice/${toInvoiceID.inv_id}/${toInvoiceID.user_id}`} />
            ) : null}

            <div className="row mt-5 mb-2">
                <div className="col-md-3 col-sm-12 mb-2 mb-md-0">
                    {searchBar && (
                        <form className="nav-standard-top-area-search">
                            <div className="nav-standard-top-area-search-inner">
                                <input
                                    type="text"
                                    onChange={(e) => setSearchValue(e.target.value)}
                                    name="searchValue"
                                    value={searchValue}
                                    className="nav-standard-top-area-search-input open-sans"
                                    placeholder={
                                        `${
                                            global?.storeConfigration?.unique_slot4?.value == 1
                                                ? 'Serial No./'
                                                : ''
                                        }` +
                                        t('lot_id') +
                                        '/' +
                                        t('title')
                                    }
                                />
                                <button
                                    onClick={handleSearch}
                                    className="lin lin-magnifier subLotSearch nav-standard-top-area-search-sumbit"
                                    type={t('submit')}
                                >
                                    <span className="material-icons">search</span>
                                </button>
                            </div>
                        </form>
                    )}
                </div>
                <div className="col-md-3 col-sm-12 mb-4 mb-md-0">
                    {changeStatus &&
                        multipleChangeStatus &&
                        (item?.market_status !== 'closed' ? (
                            <Button
                                className="chngStsBtn"
                                variant="outlined"
                                onClick={() => {
                                    if (!lotId.length) {
                                        return setAlert(
                                            'Kindly Select Atleast One Lot Id!',
                                            'warning',
                                        )
                                    }
                                    setOpen(true)
                                }}
                            >
                                Change Status
                            </Button>
                        ) : null)}
                    {multiple_relist ? (
                        <Button
                            className="chngStsBtn"
                            variant="outlined"
                            onClick={() => {
                                if (!lotId.length) {
                                    return setAlert('Kindly Select Atleast One Lot Id!', 'warning')
                                }
                                toggleRelistOpenMultiple(lotId)
                            }}
                        >
                            Relist
                        </Button>
                    ) : null}
                    {global?.storeConfigration?.enable_multiple_repost?.value == 1 ? (
                        <Button
                            className="chngStsBtn"
                            variant="outlined"
                            onClick={() => {
                                if (!lotId.length) {
                                    return setAlert('Kindly Select Atleast One Lot Id!', 'warning')
                                }
                                toggleRepostOpenMultiple(lotId)
                            }}
                        >
                            Repost
                        </Button>
                    ) : null}
                </div>
                <div className="col-md-3 col-sm-12">
                    {resultsPerPage && (
                        <CustomSelect
                            label={t('result_per_page')}
                            name="resultsPerPage"
                            selectType="noBorder"
                            value={search.limit}
                            size="small"
                            onChange={(e) => {
                                setSearch({ ...search, limit: e.target.value, page: 1 })
                            }}
                        >
                            {pageOptions.map((opt) => (
                                <option value={opt.value}>{opt.show}</option>
                            ))}
                        </CustomSelect>
                    )}
                </div>
                <div className="col-md-3 col-sm-12">
                    {status && (
                        <CustomSelect
                            label={t('status')}
                            value={search.status}
                            size="small"
                            selectType="noBorder"
                            name="status"
                            onChange={(e) => {
                                setSearch({ ...search, status: e.target.value, page: 1 })
                            }}
                        >
                            {statusShow.map((opt) => (
                                <option value={opt.value}>{opt.show}</option>
                            ))}
                        </CustomSelect>
                    )}
                </div>
            </div>

            {loading ? (
                <div className="text-center">
                    <Loader />
                </div>
            ) : productdetails.length ? (
                <>
                    <div className="table-responsive">
                        <table className="table table-hover">
                            <thead>
                                <tr>
                                    {changeStatus && multipleChangeStatus ? (
                                        <th className="chkBxCol">
                                            <div>
                                                <CheckBox
                                                    name="lotId"
                                                    label=""
                                                    // checked={lotId.find((val) => val.id === item.id)}
                                                    // value={item.id}
                                                    onChange={(e) => {
                                                        var temp = []
                                                        if (e.target.checked) {
                                                            productdetails.map((val) => {
                                                                if (
                                                                    !val.bidcount &&
                                                                    (val.market_status === 'open' ||
                                                                        val.market_status ===
                                                                            'draft')
                                                                ) {
                                                                    temp.push(val.id)
                                                                    val.checked = true
                                                                }
                                                            })
                                                        } else {
                                                            productdetails.map((val) => {
                                                                if (
                                                                    !val.bidcount &&
                                                                    (val.market_status === 'open' ||
                                                                        val.market_status ===
                                                                            'draft')
                                                                )
                                                                    val.checked = false
                                                            })
                                                        }

                                                        setLotId(temp)
                                                        //console.log(temp, 'checkTemp')
                                                        setProductDetails([...productdetails])
                                                    }}
                                                />
                                            </div>
                                        </th>
                                    ) : null}
                                    {multiple_relist ? (
                                        <th className="chkBxCol">
                                            <div>
                                                <CheckBox
                                                    name="lotId"
                                                    label=""
                                                    // checked={lotId.find((val) => val.id === item.id)}
                                                    // value={item.id}
                                                    onChange={(e) => {
                                                        var temp = []
                                                        if (e.target.checked) {
                                                            productdetails.map((val) => {
                                                                if (
                                                                    val.market_status ===
                                                                        'unsold' ||
                                                                    val.market_status === 'closed'
                                                                ) {
                                                                    temp.push(val.id)
                                                                    val.checked = true
                                                                }
                                                            })
                                                        } else {
                                                            productdetails.map((val) => {
                                                                if (
                                                                    val.market_status ===
                                                                        'unsold' ||
                                                                    val.market_status === 'closed'
                                                                ) {
                                                                    val.checked = false
                                                                }
                                                            })
                                                        }

                                                        setLotId(temp)
                                                        setProductDetails([...productdetails])
                                                    }}
                                                />
                                            </div>
                                        </th>
                                    ) : null}
                                    {global?.storeConfigration?.enable_multiple_repost?.value ==
                                    1 ? (
                                        <th className="chkBxCol">
                                            <div>
                                                <CheckBox
                                                    name="lotId"
                                                    label=""
                                                    // checked={lotId.find((val) => val.id === item.id)}
                                                    // value={item.id}
                                                    onChange={(e) => {
                                                        var temp = []
                                                        if (e.target.checked) {
                                                            productdetails.map((val) => {
                                                                temp.push(val.id)
                                                                val.checked = true
                                                            })
                                                        } else {
                                                            productdetails.map((val) => {
                                                                val.checked = false
                                                            })
                                                        }

                                                        setLotId(temp)
                                                        setProductDetails([...productdetails])
                                                    }}
                                                />
                                            </div>
                                        </th>
                                    ) : null}
                                    {search.type == 'buynow' ? (
                                        <th className="numCell">Id #</th>
                                    ) : (
                                        <th className="numCell">{t('lot') + ' #'}</th>
                                    )}
                                    <th>{t('Image')}</th>
                                    <th className="pdtNameCol">{t('product_name')}</th>
                                    {statusValue !== 'draft' && search.type != 'buynow' ? (
                                        <th>{t('time_left')}</th>
                                    ) : null}
                                    <th>{t('price')}</th>
                                    {need_auction_type ? <th>Type</th> : ''}
                                    <th>{t('status')}</th>
                                    <th className="actnBtnCol">{t('action')}</th>
                                </tr>
                            </thead>
                            {productdetails.map((item, index) => {
                                return (
                                    <>
                                        <tbody>
                                            <tr>
                                                {changeStatus && multipleChangeStatus ? (
                                                    <td>
                                                        {!item.bidcount &&
                                                        (item.market_status === 'open' ||
                                                            item.market_status === 'draft') ? (
                                                            <CheckBox
                                                                name="lotId"
                                                                label=""
                                                                checked={item.checked}
                                                                value={item.id}
                                                                onChange={() => {
                                                                    var temp = lotId
                                                                    var index = temp.indexOf(
                                                                        item.id,
                                                                    )
                                                                    if (index > -1) {
                                                                        temp.splice(index, 1)
                                                                        item.checked = false
                                                                    } else {
                                                                        temp.push(item.id)
                                                                        item.checked = true
                                                                    }
                                                                    setLotId(temp)
                                                                    setProductDetails([
                                                                        ...productdetails,
                                                                    ])
                                                                }}
                                                            />
                                                        ) : null}
                                                    </td>
                                                ) : null}
                                                {multiple_relist ? (
                                                    <td>
                                                        {item.market_status === 'unsold' ||
                                                        item.market_status === 'closed' ? (
                                                            <CheckBox
                                                                name="lotId"
                                                                label=""
                                                                checked={item.checked}
                                                                value={item.id}
                                                                onChange={() => {
                                                                    var temp = lotId
                                                                    var index = temp.indexOf(
                                                                        item.id,
                                                                    )
                                                                    if (index > -1) {
                                                                        temp.splice(index, 1)
                                                                        item.checked = false
                                                                    } else {
                                                                        temp.push(item.id)
                                                                        item.checked = true
                                                                    }
                                                                    setLotId(temp)
                                                                    setProductDetails([
                                                                        ...productdetails,
                                                                    ])
                                                                }}
                                                            />
                                                        ) : null}
                                                    </td>
                                                ) : null}
                                                {global?.storeConfigration?.enable_multiple_repost
                                                    ?.value == 1 ? (
                                                    <td>
                                                        <CheckBox
                                                            name="lotId"
                                                            label=""
                                                            checked={item.checked}
                                                            value={item.id}
                                                            onChange={() => {
                                                                var temp = lotId
                                                                var index = temp.indexOf(item.id)
                                                                if (index > -1) {
                                                                    temp.splice(index, 1)
                                                                    item.checked = false
                                                                } else {
                                                                    temp.push(item.id)
                                                                    item.checked = true
                                                                }
                                                                setLotId(temp)
                                                                setProductDetails([
                                                                    ...productdetails,
                                                                ])
                                                            }}
                                                        />
                                                    </td>
                                                ) : null}
                                                {search.type != 'buynow' ? (
                                                    <td>{item.deed_document || item.id}</td>
                                                ) : (
                                                    <td>{item.id}</td>
                                                )}
                                                <td>
                                                    <img
                                                        className="pro-img"
                                                        src={`${global.images_url}product/${item.avatar}`}
                                                        alt={item.title}
                                                        width="65"
                                                        height="65"
                                                        onError={(e) =>
                                                            (e.target.src = imageError())
                                                        }
                                                    />
                                                </td>
                                                <td className="prodTitleHere">{item.title}</td>
                                                {statusValue !== 'draft' &&
                                                search.type != 'buynow' ? (
                                                    item.auction ? (
                                                        <td className="new-timer">
                                                            {item.market_status === 'open' ||
                                                            item.market_status === 'draft' ? (
                                                                <>
                                                                    <Timer
                                                                        date_added={item.date_added}
                                                                        date_closed={
                                                                            item.date_closed
                                                                        }
                                                                        withText={1}
                                                                    ></Timer>
                                                                </>
                                                            ) : (
                                                                <>
                                                                    {t('date_closed')} :<br />
                                                                    {dateFormatFront(
                                                                        item.date_closed,
                                                                    )}
                                                                </>
                                                            )}
                                                        </td>
                                                    ) : global?.storeConfigration
                                                          ?.show_timer_for_buynow?.value == 1 ? (
                                                        <td className="new-timer">
                                                            {item.market_status === 'open' ||
                                                            item.market_status === 'draft' ? (
                                                                <>
                                                                    <Timer
                                                                        date_added={item.date_added}
                                                                        date_closed={
                                                                            item.date_closed
                                                                        }
                                                                        withText={1}
                                                                    ></Timer>
                                                                </>
                                                            ) : (
                                                                <>
                                                                    {item.market_status ===
                                                                        'closed' ||
                                                                    item.market_status === 'unsold'
                                                                        ? t('date_closed')
                                                                        : t('purchased_date')}{' '}
                                                                    :<br />
                                                                    {dateFormatFront(
                                                                        item.market_status ===
                                                                            'closed' ||
                                                                            item.market_status ===
                                                                                'unsold'
                                                                            ? item.date_closed
                                                                            : item.buynowpaid_date,
                                                                    )}
                                                                </>
                                                            )}
                                                        </td>
                                                    ) : (
                                                        <td>-</td>
                                                    )
                                                ) : null}
                                                <td className="new-timer">
                                                    {item.market_status === 'sold' ? (
                                                        <>
                                                            <div>
                                                                <small>
                                                                    {item.buynow == 1
                                                                        ? 'Buynow Price'
                                                                        : 'Hammer Price'}{' '}
                                                                    :
                                                                </small>{' '}
                                                                <br />
                                                                <b>
                                                                    {currencyFormat(
                                                                        parseInt(
                                                                            item.content_head1,
                                                                        ),
                                                                    ) > 0 ? (
                                                                        <>
                                                                            {parseFloat(
                                                                                parseInt(
                                                                                    item.buynowamount,
                                                                                ) *
                                                                                    parseInt(
                                                                                        item.qty,
                                                                                    ),
                                                                            ).toFixed(2)}
                                                                            <CurrencyConverterFormat
                                                                                amount={
                                                                                    parseFloat(
                                                                                        item.buynowamount,
                                                                                    ) *
                                                                                    parseInt(
                                                                                        item.qty,
                                                                                    )
                                                                                }
                                                                            />
                                                                        </>
                                                                    ) : (
                                                                        <>
                                                                            {currencyFormat(
                                                                                item.buynowamount,
                                                                            )}
                                                                            <CurrencyConverterFormat
                                                                                amount={
                                                                                    item.buynowamount
                                                                                }
                                                                            />
                                                                        </>
                                                                    )}
                                                                </b>
                                                                {item.buynowpaid === 1
                                                                    ? ' (Paid)'
                                                                    : ' (Unpaid)'}
                                                            </div>
                                                        </>
                                                    ) : (
                                                        <>
                                                            {item.auction === 1 ? (
                                                                <>
                                                                    {parseInt(item.bidcount) ===
                                                                    0 ? (
                                                                        <>
                                                                            <small>
                                                                                {t('start_price')} :
                                                                            </small>

                                                                            <div>
                                                                                <strong>
                                                                                    {currencyFormat(
                                                                                        item.sprice,
                                                                                    )}
                                                                                </strong>
                                                                                <strong>
                                                                                    {
                                                                                        <CurrencyConverterFormat
                                                                                            amount={
                                                                                                item.sprice
                                                                                            }
                                                                                        />
                                                                                    }
                                                                                </strong>
                                                                            </div>
                                                                        </>
                                                                    ) : (
                                                                        <>
                                                                            <small>
                                                                                Current Price
                                                                                :&nbsp;
                                                                            </small>
                                                                            <div>
                                                                                <strong>
                                                                                    {currencyFormat(
                                                                                        item.wprice,
                                                                                    )}
                                                                                </strong>
                                                                                <strong>
                                                                                    {
                                                                                        <CurrencyConverterFormat
                                                                                            amount={
                                                                                                item.wprice
                                                                                            }
                                                                                        />
                                                                                    }
                                                                                </strong>
                                                                            </div>
                                                                        </>
                                                                    )}
                                                                </>
                                                            ) : item.buynow === 1 ? (
                                                                <>
                                                                    <small>
                                                                        Buy Now Price :&nbsp;
                                                                    </small>
                                                                    <div>
                                                                        <strong>
                                                                            {currencyFormat(
                                                                                item.bprice,
                                                                            )}
                                                                        </strong>
                                                                        <strong>
                                                                            {
                                                                                <CurrencyConverterFormat
                                                                                    amount={
                                                                                        item.bprice
                                                                                    }
                                                                                />
                                                                            }
                                                                        </strong>
                                                                    </div>
                                                                </>
                                                            ) : (
                                                                '--'
                                                            )}
                                                        </>
                                                    )}

                                                    {/* {item.buynow !== 0 ? (
                                                        <>
                                                            <div>
                                                                {'Quantity'} :<b>{item.qty}</b>
                                                            </div>
                                                        </>
                                                    ) : null} */}
                                                </td>
                                                {need_auction_type ? (
                                                    <td className="prodTitleHere">
                                                        {item.auction &&
                                                        item.buynow &&
                                                        item.make_an_offer_io
                                                            ? 'Auction/ Buy Now/ Make An Offer'
                                                            : item.auction && item.buynow
                                                            ? 'Auction/ Buy Now'
                                                            : item.buynow && item.make_an_offer_io
                                                            ? 'Buy Now/ Make An Offer'
                                                            : item.auction
                                                            ? 'Auction'
                                                            : 'Buy Now'}
                                                    </td>
                                                ) : (
                                                    ''
                                                )}
                                                <td>
                                                    <span style={{ textTransform: 'capitalize' }}>
                                                        {item.market_status === 'open'
                                                            ? 'live'
                                                            : item.market_status == 'unsold' ||
                                                              item.market_status == 'closed'
                                                            ? 'Unsold'
                                                            : item.market_status}
                                                    </span>
                                                </td>
                                                <td className="actions-btn">
                                                    {global.pluginConfiguration?.messages?.enable ==
                                                        1 && (
                                                        <Button
                                                            className="ml-2"
                                                            variant="outlined"
                                                            onClick={() => (
                                                                setMessagePopup(item.id),
                                                                setBackImage(
                                                                    `${global.images_url}product/${item.avatar}`,
                                                                )
                                                            )}
                                                        >
                                                            Messages
                                                        </Button>
                                                    )}
                                                    {global?.storeConfigration
                                                        ?.enable_buynow_to_auction?.value == 1 &&
                                                    item.market_status === 'open' &&
                                                    item.auction === 0 &&
                                                    item.buynow === 1 ? (
                                                        <BuynowToAuction
                                                            product_id={item.id}
                                                            title={item.title}
                                                        />
                                                    ) : null}
                                                    {global?.storeConfigration?.enable_edit_reserve
                                                        ?.value == 1 &&
                                                    item.market_status === 'open' ? (
                                                        <EditReservePrice
                                                            product_id={item.id}
                                                            title={item.title}
                                                            oldReserveprice={item.rprice}
                                                        />
                                                    ) : null}
                                                    {(item.market_status === 'open' ||
                                                        item.market_status === 'draft') &&
                                                    parseInt(item.bidcount) <= 0 &&
                                                    (auction_status == 'open' ||
                                                        auction_status == undefined) ? (
                                                        <>
                                                            {theme === 'CarAuction' ? (
                                                                <>
                                                                    <Button
                                                                        className="m-2"
                                                                        variant="outlined"
                                                                        onClick={() =>
                                                                            handleRedirectInternal(
                                                                                history,
                                                                                !item.lotof
                                                                                    ? `edit-project/${item.qcdby}/?id=${item.id}&back=1`
                                                                                    : item.buynow ===
                                                                                      0
                                                                                    ? `edit-auction/${item.lotof}?id=${item.id}`
                                                                                    : `edit-buynow/${item.lotof}?id=${item.id}`,
                                                                            )
                                                                        }
                                                                    >
                                                                        Edit
                                                                    </Button>
                                                                </>
                                                            ) : (
                                                                <>
                                                                    <Button
                                                                        className="m-2"
                                                                        variant="outlined"
                                                                        onClick={() =>
                                                                            handleRedirectInternal(
                                                                                history,
                                                                                !item.lotof
                                                                                    ? `edit-project?id=${item.id}&back=1`
                                                                                    : item.buynow ===
                                                                                      0
                                                                                    ? `edit-auction/${item.lotof}?id=${item.id}`
                                                                                    : `edit-buynow/${item.lotof}?id=${item.id}`,
                                                                            )
                                                                        }
                                                                    >
                                                                        {t('edit')}
                                                                    </Button>
                                                                </>
                                                            )}
                                                        </>
                                                    ) : null}
                                                    {/* {parseInt(item.bidcount) >= 0 &&
                                                        edit_need_after_bid == 1 &&
                                                        item.market_status === 'open' ? (
                                                        <Button
                                                            className="ml-2"
                                                            variant="outlined"
                                                            onClick={() =>
                                                                handleRedirectInternal(
                                                                    history,
                                                                    !item.lotof
                                                                        ? `edit-project?id=${item.id}`
                                                                        : item.buynow === 0
                                                                            ? `edit-auction/${item.lotof}?id=${item.id}`
                                                                            : `edit-buynow/${item.lotof}?id=${item.id}`,
                                                                )
                                                            }
                                                        >
                                                            Edit
                                                        </Button>
                                                    ) : null} */}
                                                    <Button
                                                        className="m-2"
                                                        variant="outlined"
                                                        onClick={() => handleClick(item)}
                                                    >
                                                        {t('view')}
                                                    </Button>

                                                    {changeStatus &&
                                                        !multipleChangeStatus &&
                                                        (item?.market_status !== 'closed' &&
                                                        item?.market_status !== 'sold' &&
                                                        (auction_status == 'open' ||
                                                            auction_status == undefined) ? (
                                                            <Button
                                                                className="m-2"
                                                                variant="outlined"
                                                                onClick={() => {
                                                                    setLotId(item?.id)
                                                                    setOpen(true)
                                                                }}
                                                            >
                                                                {t('change_status')}
                                                            </Button>
                                                        ) : null)}
                                                    {global.storeConfigration?.ship_track_info
                                                        ?.value == 1 && item.ss_orderid ? (
                                                        <Button
                                                            className="m-2"
                                                            variant="outlined"
                                                            onClick={() => {
                                                                openshippingtracking(item)
                                                            }}
                                                        >
                                                            {'View Shipping Tracking'}
                                                        </Button>
                                                    ) : (
                                                        ''
                                                    )}
                                                    {(item.market_status === 'draft' ||
                                                        item.market_status === 'open') &&
                                                    (auction_status == 'open' ||
                                                        auction_status == undefined) &&
                                                    item.bidcount == 0 ? (
                                                        <Button
                                                            className="m-2"
                                                            variant="outlined"
                                                            onClick={() => {
                                                                setOpenDelete(true)
                                                                setDeleteLotId(item.id)
                                                            }}
                                                        >
                                                            {t('delte')}
                                                        </Button>
                                                    ) : (
                                                        ''
                                                    )}
                                                    {item.market_status === 'sold' ? (
                                                        <>
                                                            {/* {item.buynowpaid === 1 ? ( */}
                                                            <>
                                                                <Button
                                                                    variant="outlined"
                                                                    className="m-2"
                                                                    onClick={() =>
                                                                        handleRedirectInternal(
                                                                            history,
                                                                            item.auction
                                                                                ? `invoice/auction?id=${item.common_invoice}&bid=${item.buynowuser_id}`
                                                                                : `invoice/buynow?id=${item.common_invoice}&bid=${item.buynowuser_id}`,
                                                                        )
                                                                    }
                                                                >
                                                                    {t('view_invoice')}
                                                                </Button>
                                                            </>
                                                            {/* ) : (
                                                                <></>
                                                            )} */}
                                                        </>
                                                    ) : null}
                                                    {/* Live Auction Duplicate */}

                                                    {global?.storeConfigration?.duplicate_live
                                                        ?.value == 1 ? (
                                                        <>
                                                            {item.market_status === 'open' && (
                                                                <Button
                                                                    variant="outlined"
                                                                    className="m-2"
                                                                    onClick={() =>
                                                                        handleRedirectInternal(
                                                                            history,
                                                                            `edit-project?id=${item.id}&repost=1`,
                                                                        )
                                                                    }
                                                                >
                                                                    Duplicate
                                                                </Button>
                                                            )}
                                                        </>
                                                    ) : (
                                                        ''
                                                    )}
                                                    {copyItem ? (
                                                        <Button
                                                            variant="outlined"
                                                            className="m-2"
                                                            onClick={() =>
                                                                handleRedirectInternal(
                                                                    history,
                                                                    `edit-project?id=${item.id}&repost=1`,
                                                                )
                                                            }
                                                        >
                                                            {t('copy_item')}
                                                        </Button>
                                                    ) : null}
                                                    {item.lotof ||
                                                    global.storeConfigration?.remove_repost_relist
                                                        ?.value == 1 ? null : (
                                                        <>
                                                            {global.storeConfigration?.repost_hours
                                                                ?.value > 0 ? (
                                                                <>
                                                                    {item.market_status ===
                                                                        'sold' &&
                                                                        repostHours(
                                                                            item.date_closed,
                                                                            global.storeConfigration
                                                                                ?.repost_hours
                                                                                ?.value,
                                                                        ) && (
                                                                            <Button
                                                                                variant="outlined"
                                                                                className="m-2"
                                                                                onClick={() =>
                                                                                    handleRedirectInternal(
                                                                                        history,
                                                                                        `edit-project?id=${item.id}&repost=1`,
                                                                                    )
                                                                                }
                                                                            >
                                                                                {t('repost')}
                                                                            </Button>
                                                                        )}
                                                                </>
                                                            ) : (
                                                                <>
                                                                    {item.market_status ===
                                                                        'sold' && (
                                                                        <Button
                                                                            variant="outlined"
                                                                            className="m-2"
                                                                            onClick={() =>
                                                                                handleRedirectInternal(
                                                                                    history,
                                                                                    `edit-project?id=${item.id}&repost=1`,
                                                                                )
                                                                            }
                                                                        >
                                                                            {t('repost')}
                                                                        </Button>
                                                                    )}
                                                                </>
                                                            )}
                                                            {(item.market_status === 'closed' ||
                                                                item.market_status === 'unsold') &&
                                                                (global.storeConfigration
                                                                    ?.relist_hours?.value > 0 ? (
                                                                    repostHours(
                                                                        item.date_closed,
                                                                        global.storeConfigration
                                                                            ?.relist_hours?.value,
                                                                    ) && (
                                                                        <Button
                                                                            variant="outlined"
                                                                            className="m-2"
                                                                            onClick={() =>
                                                                                toggleRelistOpen(
                                                                                    item.id,
                                                                                )
                                                                            }
                                                                        >
                                                                            {t('relist')}
                                                                        </Button>
                                                                    )
                                                                ) : (
                                                                    <Button
                                                                        variant="outlined"
                                                                        className="m-2"
                                                                        onClick={() =>
                                                                            toggleRelistOpen(
                                                                                item.id,
                                                                            )
                                                                        }
                                                                    >
                                                                        {t('relist')}
                                                                    </Button>
                                                                ))}
                                                        </>
                                                    )}
                                                </td>
                                            </tr>
                                        </tbody>
                                    </>
                                )
                            })}
                        </table>
                    </div>
                    <div className="d-flex mt-3 pagination-wrapper se">
                        <div className="ml-auto page-showing">
                            {t('showing')} {search.limit * (search.page - 1) + 1} -{' '}
                            {search.limit * search.page > seller_allproducts.length
                                ? seller_allproducts.length
                                : search.limit * search.page}{' '}
                            of {seller_allproducts.length}
                        </div>
                        <Pagination
                            count={Math.ceil(seller_allproducts.length / search.limit)}
                            page={search.page}
                            onChange={onHandlePage}
                        />
                    </div>
                    {sliderTheme == 'singleSeller' ? (
                        <SlidePanel
                            type={type ? type : ''}
                            selectedLot={selectedProduct}
                            handleClose={handleClose}
                            storeTheme={''}
                        />
                    ) : (
                        <MultisellerSlider
                            selectedLot={selectedProduct}
                            handleClose={handleClose}
                            type={type ? type : ''}
                            storeTheme={storeTheme}
                        />
                    )}

                    <CustomDialog title={'Change Status'} open={open} function={toggleOpen}>
                        <CustomSelect
                            label="Select Status"
                            name="selectStatus"
                            selectType="noBorder"
                            value={changeStatusValue}
                            size="small"
                            onChange={(e) => {
                                setChangeStatusValue(e.target.value)
                            }}
                        >
                            {changeStatusShow.map((opt) => (
                                <option value={opt.value}>{opt.show}</option>
                            ))}
                        </CustomSelect>
                        <div className="actionWrapper">
                            <Button onClick={() => setOpen(false)} disabled={btnLoading}>
                                {'Cancel'}
                            </Button>
                            {(!user?.card_profileid &&
                                global.storeConfigration?.posting_without_onboard?.value != 1) ||
                            (global.pluginConfiguration?.reseller_subscription?.enable &&
                                !user?.subscribed) ? (
                                <PrimaryButton
                                    // label={
                                    //     !user?.card_profileid && !user.subscribed
                                    //         ? 'Subscribe and Setup bank account'
                                    //         : !user.subscribed
                                    //         ? 'Subscribe'
                                    //         : 'Setup bank account'
                                    // }
                                    label={'Publish Live'}
                                    onClick={() => {
                                        if (
                                            global.pluginConfiguration?.reseller_subscription
                                                ?.enable &&
                                            !user?.subscribed
                                        ) {
                                            setAlert(
                                                'Kindly subscribe for move to product in live!',
                                                'warning',
                                            )
                                            return handleRedirectInternal(
                                                history,
                                                'dashboard/subscription',
                                            )
                                        } else {
                                            // skip paypal link when publish auction for lotofcards
                                            if (
                                                global.storeDetails.site_id == 'AS0456' ||
                                                global.storeDetails.site_id == 'AS0349'
                                            ) {
                                                return changeStatusSubmit()
                                            } else {
                                                setAlert(
                                                    'Kindly add account id for move to product in live',
                                                    'warning',
                                                )
                                                return handleRedirectInternal(
                                                    history,
                                                    'dashboard/profile?moveto="view"',
                                                )
                                            }
                                        }
                                    }}
                                />
                            ) : (
                                <PrimaryButton
                                    onClick={changeStatusSubmit}
                                    type="button"
                                    disabled={btnLoading}
                                    label={btnLoading ? 'Loading...' : 'Confirm'}
                                />
                            )}
                        </div>
                    </CustomDialog>

                    <CustomDialog
                        title={'Relist'}
                        open={openRelist}
                        className="RelistBox"
                        function={toggleRelistOpenMultiple}
                    >
                        <div className="row">{mapData(fields)}</div>
                        <div className="actionWrapper mt-5">
                            <Button onClick={() => handleClosepopup()} disabled={btnLoading}>
                                {'Cancel'}
                            </Button>
                            <PrimaryButton
                                onClick={formik.handleSubmit}
                                type="button"
                                disabled={btnLoading}
                                label={btnLoading ? 'Loading...' : 'Confirm'}
                            />
                        </div>
                    </CustomDialog>
                    <CustomDialog
                        title={'Repost'}
                        open={openRepost}
                        function={toggleRepostOpenMultiple}
                    >
                        <div className="row">{mapData(fields)}</div>
                        <div className="actionWrapper">
                            <Button onClick={() => setOpenRepost(false)} disabled={btnLoading}>
                                {'Cancel'}
                            </Button>
                            <PrimaryButton
                                onClick={formik.handleSubmit}
                                type="button"
                                disabled={btnLoading}
                                label={btnLoading ? 'Loading...' : 'Confirm'}
                            />
                        </div>
                    </CustomDialog>
                    <CustomDialog title={'Delete'} open={openDelete} function={toggleRelistOpen}>
                        <h5>Are you sure ? Do you want to delete this item ?</h5>
                        <div className="actionWrapper">
                            <Button onClick={() => setOpenDelete(false)} disabled={btnLoading}>
                                {'Cancel'}
                            </Button>
                            <PrimaryButton
                                onClick={() => deleteItem({ project_id: deleteLotId })}
                                type="button"
                                disabled={btnLoading}
                                label={btnLoading ? 'Loading...' : 'Confirm'}
                            />
                        </div>
                    </CustomDialog>
                    <CustomDialog
                        className="mnMsgPopup"
                        title={
                            <span className="d-flex align-items-center">
                                <span className="material-icons mr-1">question_answer</span>{' '}
                                Messages
                            </span>
                        }
                        open={messagePopup}
                        maxWidth={'lg'}
                        function={() => setMessagePopup('')}
                    >
                        <div className="actionWrapper">
                            <IconButton className="clsVw" onClick={() => setMessagePopup('')}>
                                <span className="material-icons">clear</span>
                            </IconButton>
                            <UserMessage project_id={messagePopup} backImage={backImage} />
                        </div>
                    </CustomDialog>
                    <Popup
                        open={listingFeePopup}
                        handleClose={(e) => setListingFeePopup(false)}
                        size="md"
                        className="save-search-modal"
                        modaltitle="Listing Fee"
                    >
                        {' '}
                        <ListingFee
                            product_id={listingFeePopup}
                            setListingFeePopup={setListingFeePopup}
                        />
                    </Popup>
                    <Popup
                        open={popup_open}
                        handleClose={(e) => setPopupopup(false)}
                        size="md"
                        className="save-search-modal"
                        modaltitle="Shipping Info"
                    >
                        {Object.keys(display_address).length > 0 ? (
                            <>
                                <div className="row">
                                    <div className="col-6">
                                        <b>{'Shipping User Info: '}</b>
                                        {display_address.first_name +
                                            ' ' +
                                            display_address.last_name}
                                    </div>
                                    <div className="col-6">
                                        <b>{'Shipping Address: '}</b>
                                        {display_address.address}
                                    </div>
                                </div>
                                <br></br>
                                <div className="row">
                                    <div className="col-6">
                                        <b>{'Email: '}</b>
                                        {display_address.email}
                                    </div>
                                    <div className="col-6">
                                        <b>{'Tracking Number: '}</b>
                                        {selectAddress.tracking_number
                                            ? selectAddress.tracking_number
                                            : mapData(form_ship_data)}
                                        <PrimaryButton
                                            label={`Update`}
                                            className="mbv-btn"
                                            onClick={(e) => formik_shipping_form.handleSubmit()}
                                        />
                                    </div>
                                </div>
                                <br></br>
                                <div className="row">
                                    <div className="col-6">
                                        <b>{'Phone: '}</b>
                                        {display_address.phone}
                                    </div>
                                </div>
                                <br></br>
                                <div className="row">
                                    <div className="col-6">
                                        <b>{'Tracking Service: '}</b>
                                        {global.pluginConfiguration?.shipping?.service_key
                                            ? global.pluginConfiguration.shipping.service_key
                                            : '-'}
                                    </div>
                                </div>
                                <br></br>
                                <div className="row">
                                    <div className="col-6">
                                        <b>{'Tracking Info: '}</b>
                                        {selectAddress.localpickup ? 'Local-Pick-Up' : 'Shipping'}
                                    </div>
                                </div>
                                <br></br>
                                <div className="actionWrapper">
                                    <div className="row">
                                        <div className="col-3">
                                            {' '}
                                            <PrimaryButton
                                                label={`Close`}
                                                className="mbv-btn"
                                                onClick={(e) => setPopupopup(false)}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </>
                        ) : (
                            <>Loading...</>
                        )}
                    </Popup>
                </>
            ) : (
                <>
                    <NoRecordsFound />
                </>
            )}
        </div>
    )
}

export default SubLots
