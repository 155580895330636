import React, { useState, useEffect, useContext } from 'react'
import { Link, useLocation, useHistory } from 'react-router-dom'
import ImageGallery from 'react-image-gallery'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import 'react-image-gallery/styles/css/image-gallery.css'
import moment from 'moment-timezone'
import PrimaryButton from '../../../Product/components/atoms/PrimaryButton'
import Timer from '../../../Product/common/timer'
import { capitalize, currencyFormat } from '../../../Product/common/components'
// import ReactHtmlParser from 'react-html-parser'
import { handleRedirectInternal } from '../../../Product/common/components'
import { imageError } from '../../../utils/commonFunctions'
import Facebook from '../../../utils/CommonFunctionality/Share/Facebook'
import Whatsapp from '../../../utils/CommonFunctionality/Share/Whatsapp'
import Twitter from '../../../utils/CommonFunctionality/Share/Twitter'
import Email from '../../../utils/CommonFunctionality/Share/Email'
import Pinterest from '../../../utils/CommonFunctionality/Share/Pinterest'
import DonationModal from '../DonationModal'
import AuthContext from '../../../Product/context/auth/authContext'
import pluginContext from '../../../Product/context/plugin/pluginContext'
import { Breadcrumbs, Drawer, Button } from '@material-ui/core'
import DonorHistory from '../../../Component/DonarHistory'
import AuctionContext from '../../../Product/context/auctionio/auction/auctionContext'
import AddToCalendar from '../../../Product/components/molecules/AddToCalendar'

const ProductAuctionTopComponent = (props) => {
    const authContext = useContext(AuthContext)
    const [image, setImage] = useState([])
    const [donModal, setDonModal] = useState(false)
    const [donar_list, setDonarList] = useState({})
    const [popup_open, setPopupopen] = useState(false)
    const [showDescription, setShowDescription] = useState(false)
    const [sellerInfo, setSellerInfo] = useState({})
    const { user, isAuthenticated } = authContext
    const {
        getdonarexistingcard,
        getdonarlist,
        responseStatus: donar_response,
        clearResponse: donar_clearresponse,
    } = useContext(pluginContext)

    const { auctionSellerDtls, getSellerInfo } = useContext(AuctionContext)

    const history = useHistory()

    const matcheMedia = useMediaQuery('(max-width:600px)')

    useEffect(() => {
        let imageUrl = `${
            props.auctionData.store_id
                ? process.env.REACT_APP_PRODUCT_IMAGE_URL
                : process.env.REACT_APP_BASE_URL + 'uploads/product/'
        }${props.auctionData.avatar}`
        setImage([{ original: imageUrl, thumbnail: imageUrl }])
        getdonarlist({ auctionid: props.auctionData.id, page: 1, perpage: 5 })
        getSellerInfo({ auctionid: props.auctionData.id })
    }, [props.auctionData])

    useEffect(() => {
        if (Object.keys(auctionSellerDtls).length != 0) {
            setSellerInfo(auctionSellerDtls)
        }
    }, [auctionSellerDtls])

    useEffect(() => {
        if (donar_response) {
            if (donar_response.from == 'donorlist_bidderdetails_page') {
                //console.log('llllllllllllllllllllllllllllllll', donar_response)
                if (donar_response.message) {
                    donar_response.message.totpercentage = parseInt(
                        parseFloat(
                            parseInt(donar_response.message.donationamt) /
                                parseInt(props.auctionData.expected_amount),
                        ) * 100,
                    )
                    var given = moment(props.auctionData.date_closed, 'YYYY-MM-DD')
                    var current = moment().startOf('day')
                    donar_response.message.total_days = moment
                        .duration(given.diff(current))
                        .asDays()
                }
                setDonarList(donar_response.message)
            }
            donar_clearresponse()
        }
    }, [donar_response])
    const globalGirlsTheme = global.storeConfigration?.bodyClass.value === 'globalgirls'

    return (
        <>
            {!globalGirlsTheme ? (
                <div className="pvRespTimer d-none">
                    <Timer
                        date_added={props.auctionData.date_added}
                        date_closed={props.auctionData.date_closed}
                        withText={1}
                        endText={'Ends in' + ':'}
                        startText={'Starts in' + ':'}
                        theme="multiseller"
                    ></Timer>
                </div>
            ) : null}

            {!globalGirlsTheme ? (
                <div className="bnrImgOtrWrpr">
                    <div className="media-left">
                        {props.auctionData.avatar ? (
                            <ImageGallery
                                items={image}
                                thumbnailPosition="bottom"
                                showNav={false}
                                showBullets={false}
                                showFullscreenButton={true}
                                showPlayButton={false}
                                originalClass="pro-img img-fluid"
                                showThumbnails={false}
                                onErrorImageURL={imageError()}
                            />
                        ) : (
                            <img
                                src={`${global?.storeDetails?.logoValue}`}
                                className="pro-img img-fluid"
                            />
                        )}
                    </div>
                </div>
            ) : null}
            <div className="btmDescContainer">
                <div className="lftDscCntnr">
                    {props.auctionData.title !== undefined && (
                        <div className="media-body">
                            {!globalGirlsTheme ? (
                                <div className="pdtDscLftInfo">
                                    <h4 className="ttlVw mb-4">EVENT DETAILS</h4>
                                    <div className="evtInfo">
                                        <label>Who</label>
                                        <p>{sellerInfo?.shipper_username}</p>
                                    </div>
                                    <div className="evtInfo">
                                        <label>Where</label>
                                        <p>
                                            {props.auctionData.city},&nbsp;{props.auctionData.state}
                                            ,&nbsp;
                                            {props.auctionData.country}
                                        </p>
                                    </div>
                                    <div className="evtInfo">
                                        <label>Attire</label>
                                        <p>
                                            {props.auctionData.custom_field_5
                                                ? props.auctionData.custom_field_5
                                                : '-'}
                                        </p>
                                    </div>
                                    <div className="evtInfo">
                                        <label>Starts</label>
                                        {props.auctionData.date_added ? (
                                            <>
                                                <p>
                                                    {moment(props.auctionData.date_added)
                                                        .tz('America/New_York')
                                                        .format('MMM Do YYYY, h:mm a')}{' '}
                                                </p>
                                                <div className="adToClndr">
                                                    <AddToCalendar data={props.auctionData} />
                                                </div>
                                            </>
                                        ) : (
                                            ''
                                        )}
                                    </div>
                                    <div className="evtInfo">
                                        <label>Ends</label>
                                        <p>
                                            {moment(props.auctionData.date_closed)
                                                .tz('America/New_York')
                                                .format('MMM Do YYYY, h:mm a')}
                                        </p>
                                    </div>
                                </div>
                            ) : null}

                            {matcheMedia && !globalGirlsTheme && (
                                <div className="rtDescContianer">
                                    <h2
                                        className="pv-title mb-4"
                                        dangerouslySetInnerHTML={{
                                            __html: capitalize(props.auctionData?.title),
                                        }}
                                    />
                                    <div className="">
                                        <p
                                            className="mb-1"
                                            dangerouslySetInnerHTML={{
                                                __html: props.auctionData?.description,
                                            }}
                                        ></p>
                                    </div>
                                </div>
                            )}

                            {props.auctionData.event_enable ? (
                                <div className="prgrsContainer mt-4">
                                    {donar_list?.donationamt ? (
                                        <h3>{currencyFormat(donar_list.donationamt)}</h3>
                                    ) : (
                                        <h3>{'US $0'}</h3>
                                    )}
                                    <p>
                                        raised of{' '}
                                        {currencyFormat(props.auctionData.expected_amount)}
                                    </p>
                                    <div className="prgrsBrCntnr">
                                        <div className="progress">
                                            <div
                                                className="progress-bar"
                                                role="progressbar"
                                                style={{
                                                    width: donar_list?.donationamt
                                                        ? donar_list.totpercentage.toString() + '%'
                                                        : '0%',
                                                }}
                                                aria-valuenow="25"
                                                aria-valuemin="0"
                                                aria-valuemax="100"
                                            ></div>
                                        </div>
                                        <div className="stsCntnr d-flex align-items-center justify-content-between">
                                            <p className="m-0">
                                                {donar_list?.donationcnt2 ? (
                                                    <>
                                                        {' '}
                                                        <span>{donar_list.donationcnt2}</span>
                                                        {parseInt(donar_list.donationcnt2) == 1
                                                            ? 'supporter'
                                                            : 'supporters'}
                                                    </>
                                                ) : (
                                                    ''
                                                )}{' '}
                                            </p>
                                            {donar_list.total_days > 0 ? (
                                                <p className="m-0">
                                                    <span>{donar_list.total_days}</span>{' '}
                                                    {donar_list.total_days == 1
                                                        ? 'day left'
                                                        : 'days left'}
                                                </p>
                                            ) : (
                                                ''
                                            )}
                                        </div>
                                    </div>
                                </div>
                            ) : (
                                ''
                            )}

                            {/* <div className="text-center-md">
                                {props?.auctionData?.market_status == 'open' &&
                                props?.auctionData?.event_enable ? (
                                    <PrimaryButton
                                        btnSize="mt-3"
                                        onClick={() => {
                                            // if (isAuthenticated) {
                                            props.makeDonation()
                                            // } else {
                                            //     handleRedirectInternal(history, 'login')
                                            // }
                                        }}
                                        label={
                                            <>
                                                <span className="material-icons mr-3">
                                                    volunteer_activism
                                                </span>{' '}
                                                Make a donation
                                            </>
                                        }
                                    />
                                ) : (
                                    ''
                                )}
                            </div> */}
                            <div className="text-center-md">
                                <PrimaryButton
                                    onClick={() => setDonModal(true)}
                                    label={
                                        <>
                                            <span className="material-icons mr-3">
                                                volunteer_activism
                                            </span>{' '}
                                            Make a donation
                                        </>
                                    }
                                />
                            </div>

                            {props.auctionData.event_enable ? (
                                <div className={!globalGirlsTheme ? 'dnrList ml-0' : 'd-none'}>
                                    <hr />
                                    <div
                                        className={
                                            !globalGirlsTheme
                                                ? 'hdrCntnr d-flex align-items-center justify-content-between'
                                                : 'd-none'
                                        }
                                    >
                                        <h5>
                                            <span className="material-icons mr-2">
                                                favorite_border
                                            </span>
                                            Recent Donors
                                        </h5>
                                        {donar_list?.donationlist?.length > 0 ? (
                                            <Button
                                                className="shlAlBtn m-0"
                                                onClick={(e) => {
                                                    setPopupopen(true)
                                                }}
                                            >
                                                Show all
                                            </Button>
                                        ) : (
                                            ''
                                        )}
                                    </div>
                                    <div className={!globalGirlsTheme ? 'dnrDtlCntnr' : 'd-none'}>
                                        {donar_list?.donationlist?.length > 0 ? (
                                            <>
                                                {donar_list.donationlist.map((data) => (
                                                    <div className="dnrCntnr d-flex align-items-center justify-content-between">
                                                        <h5>
                                                            <span className="material-icons mr-3">
                                                                account_circle
                                                            </span>
                                                            {data.first_name + ' ' + data.last_name}
                                                        </h5>
                                                        <p className="amtVl m-0">
                                                            {currencyFormat(data.total_amount)}
                                                        </p>
                                                    </div>
                                                ))}
                                            </>
                                        ) : (
                                            <div className="noDonors">
                                                <p>No details found!</p>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            ) : (
                                ''
                            )}
                            <hr />
                            <div className="sclMedia d-flex align-items-center">
                                <p className="text-center mb-0 mr-1">Spread the word</p>
                                <div className="d-flex align-items-center justify-content-between">
                                    <div className="mx-1">
                                        <Facebook
                                            path={`/product/${props.auctionData.id}`}
                                            // title={selectedProduct.title}
                                        />
                                    </div>
                                    <div className="mx-1">
                                        <Whatsapp
                                            path={`/product/${props.auctionData.id}`}
                                            // title={selectedProduct.title}
                                        />
                                    </div>
                                    <div className="mx-1">
                                        <Twitter
                                            path={`/product/${props.auctionData.id}`}
                                            // title={selectedProduct.title}
                                        />
                                    </div>
                                    <div className="mx-1">
                                        <Email
                                            path={`/product/${props.auctionData.id}`}
                                            // subject={selectedProduct.title}
                                            // body={selectedProduct.description}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                    <div className="pvRespTimer pvBtnsShr d-none">
                        <div className="ndCTA">
                            {/* <PrimaryButton
                                onClick={() => setDonModal(true)}
                                label={
                                    <>
                                        <span className="material-icons mr-3">
                                            volunteer_activism
                                        </span>{' '}
                                        Make a donation
                                    </>
                                }
                            /> */}
                        </div>
                        <div className="sclMedia">
                            <p>Spread the word</p>
                            <div className="d-flex align-items-center justify-content-between">
                                <Facebook path={`/product/${props.auctionData.id}`} />
                                <Whatsapp path={`/product/${props.auctionData.id}`} />
                                <Twitter path={`/product/${props.auctionData.id}`} />
                                <Email path={`/product/${props.auctionData.id}`} />
                            </div>
                        </div>
                    </div>
                </div>
                {!matcheMedia && !globalGirlsTheme && (
                    <div className="rtDescContianer">
                        <h2
                            className="pv-title mb-4"
                            dangerouslySetInnerHTML={{
                                __html: capitalize(props.auctionData?.title),
                            }}
                        />
                        <div className="">
                            <p
                                className="mb-1"
                                dangerouslySetInnerHTML={{
                                    __html: props.auctionData?.description,
                                }}
                            ></p>
                        </div>
                    </div>
                )}
            </div>
            <DonationModal
                open={donModal}
                id={props?.product_id ? props.product_id : ''}
                handleClose={() => setDonModal(false)}
            />
            <DonorHistory
                popup={popup_open}
                id={props?.product_id ? props.product_id : ''}
                setPopup={setPopupopen}
            />
        </>
    )
}

export default ProductAuctionTopComponent
