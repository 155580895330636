import React, { useContext, useEffect, useState } from 'react'
import './AnonymousFeedback.css'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { useHistory } from 'react-router-dom'
import { mapData, handleRedirectInternal } from '../../../Product/common/components'
import PrimaryButton from '../../../Product/components/atoms/PrimaryButton'
import AuthContext from '../../../Product/context/auth/authContext'
import UserContext from '../../../Product/context/user/userContext'
import Layout from '../Layout'
import Popup from '../../../Product/components/organisms/Popup'

function AnonymousFeedback(props) {
    const authContext = useContext(AuthContext)
    const userContext = useContext(UserContext)
    const { send_contactus, responseStatus } = userContext
    const { user, loadUser } = authContext
    const history = useHistory()
    const [isInfoWindowOpen, setIsInfoWindowOpen] = useState(true)

    const validationArray = Yup.object({
        subject: Yup.string().required('Required'),
        messages: Yup.string().trim().required('Required'),
    })

    const formik = useFormik({
        initialValues: {
            name: 'Anonymous',
            last_name: '',
            from_email: 'no-reply@gmail.com',
            phone_number: '',
            subject: '',
            messages: '',
            is_send_email: 1,
            is_anonymous: 1,
        },
        validationSchema: validationArray,
        onSubmit: (values) => {
            send_contactus(values)
        },
    })

    useEffect(() => {
        if (responseStatus) {
            if (responseStatus.from === 'send_contactus') {
                if (responseStatus.status === 'success') {
                    props.handleClose()
                }
            }
        }
    }, [responseStatus])

    const contactInfo = [
        {
            label: 'Subject',
            name: 'subject',
            type: 'text',
            placeholder: 'Enter your subject',
            class: 'col-12',
            formik: formik,
        },
        {
            label: 'Message',
            name: 'messages',
            type: 'textarea',
            placeholder: 'Enter your message',
            class: 'col-12',
            formik: formik,
        },
    ]
    useEffect(() => {
        loadUser()
    }, [])

    useEffect(() => {
        if (user) {
            formik.values.name = user?.first_name + ' ' + user?.last_name || ''
            formik.values.phone_number = user?.phone || ''
            formik.values.from_email = user?.email || ''
            formik.values.subject = 'General Enquiry'
        }
    }, [user])

    return (
        <Popup
            {...props}
            size="md"
            modalClass={`save-search-modal ${props.classNames}`}
            modaltitle="Anonymous feedback form"
        >
            <div className="contact-main-container">
                <div className="contactform">
                    <div className="cu-head">Send us a message</div>
                    <div className="text-center scontact-us-wrap flex-btnscol">
                        <form onSubmit={formik.handleSubmit}>
                            <div className="row">
                                {Object.values(mapData(contactInfo))}
                                <div className="col-12">
                                    <PrimaryButton label="Submit" type="submit" />
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </Popup>
    )
}

export default AnonymousFeedback
