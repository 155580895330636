import React from 'react'
import DashboardLayout from '../DashboardLayout'
import Layout from '../Layout'
import Cards from '../../../utils/CommonFunctionality/Achpayment/cards'

const ACH = (props) => {
    return (
        <Layout props={props}>
            <DashboardLayout title="ACH">
                <Cards />
            </DashboardLayout>
        </Layout>
    )
}

export default ACH
