import { AddToCalendarButton } from 'add-to-calendar-button-react'
import moment from 'moment'
import React from 'react'

const NewSchedulePickup = ({ invoice, pickupData, auctionData }) => {
    return (
        <div className="card">
            {/* <div className="card-header">
                <h4 className="text-center my-1">
                    {pickupData?.status && pickupData?.status == 'ready'
                        ? 'Your order is ready for pick up please come pick up your items'
                        : 'Your invoice is paid - we will notify you once your order is Ready for pick up.'}
                </h4>
            </div> */}
            <div className="card-body">
                {/* <AddToCalendarButton
                    name={`${invoice.common_invoice} (Pickup)`}
                    options={[
                        'Apple',
                        'Google',
                        'iCal',
                        'Microsoft365',
                        'MicrosoftTeams',
                        'Outlook.com',
                        'Yahoo',
                    ]}
                    location={auctionData?.address}
                    startDate={moment(pickupData?.booking_date).format('YYYY-MM-DD')}
                    timeZone={Intl.DateTimeFormat().resolvedOptions().timeZone}
                    hideBackground={true}
                    buttonStyle={'date'}
                /> */}
                {/* <div className="mt-4">
                    <div className="row">
                        <div className="form-group col-md">
                            <label className="form-label">Date</label>
                            <p>
                                {moment(pickupData?.booking_date).format(
                                    'dddd, MMM Do [at] h:mm A',
                                )}
                            </p>
                        </div>
                        <div className="form-group col-md">
                            <label className="form-label">Name</label>
                            <p>{pickupData?.name}</p>
                        </div>
                        <div className="form-group col-md">
                            <label className="form-label">Email</label>
                            <p>{pickupData?.email}</p>
                        </div>
                        <div className="form-group col-md">
                            <label className="form-label">Phone</label>
                            <p>{pickupData?.phone}</p>
                        </div>
                        <div className="form-group col-md">
                            <label className="form-label">Pickup Address</label>
                            <address>
                                {auctionData?.address}
                                <br />
                                {auctionData?.city}
                                <br />
                                {auctionData?.state}, {auctionData?.country}, {auctionData?.zipcode}
                            </address>
                        </div>
                    </div>
                </div> */}
            </div>
        </div>
    )
}

export default NewSchedulePickup
