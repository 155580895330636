import React from 'react'
import Layout from '../Layout'
import { Style } from '@material-ui/icons'

export default function estateLiquidations(props) {
    const baseStyle = {
        fontWeight: 700,
        marginBottom: '12px',
    }

    const h1Style = {
        ...baseStyle,
        // Additional or overriding styles for h2 can go here if needed
    }

    const h4Style = {
        ...baseStyle,
        // Additional or overriding styles for h4 can go here if needed
    }

    const pStyle = {
        fontWeight: 400,
        marginBottom: '24px',
    }
    return (
        <Layout props={props}>
            <div className="px-5 py-4 stctPgWrpr">
                <h1 style={h1Style}>Estate Liquidations</h1>
                <p style={pStyle}>
                    At Lots of Auctions, we understand that downsizing or liquidating a loved one’s
                    home can be a stressful and emotional experience. Our team of professional
                    liquidation experts work directly with our sellers to ensure the process goes as
                    smooth and painless as possible.
                </p>
                <p style={pStyle}>
                    Lots of Auctions offers comprehensive estate liquidation services to
                    municipalities, individuals, family trusts, fiduciaries, and trustees. Our
                    estate auctions take place entirely online, eliminating the need for strangers
                    to visit your home or search through your loved one’s belongings. This discreet
                    and convenient option allows sellers to liquidate multiple items in a short
                    amount of time while reducing the amount of damage to property.
                </p>
                <p style={pStyle}>
                    Lots of Auctions handles all parts of the selling process, allowing sellers to
                    focus on other important matters. This frees up time and removes much of the
                    stress involved with downsizing and estate liquidations.
                </p>
                <h4 style={h4Style}>Online Estate Auctions</h4>
                <p style={pStyle}>
                    Lots of Auctions’ estate auctions allow homeowners and second-hand resellers to
                    obtain quality items at deeply discounted prices. Our online estate auctions
                    eliminate many of the hassles and inconveniences associated with in-person
                    estate sales. Bidders do not need to visit a physical location, deal with estate
                    sale personnel, locate parking, or work around other buyers. Bidders can bid on
                    numerous items online from the comfort of their home or office. Buyers only pay
                    for the auctions they win.
                </p>
                <p style={pStyle}>
                    Lots of Auctions offers convenient pick-up in Dallas and Denton, Texas.
                </p>
                <h4 style={h4Style}>Maximize Return on Your Items</h4>
                <p style={pStyle}>
                    When sellers list their items with Lots of Auctions, they gain access to
                    thousands of online bidders. Lots of Auctions uses targeted marketing to ensure
                    sellers get top dollar for their items. We strive to exceed our customer’s
                    expectations. We work diligently and provide a high level of support throughout
                    the entire process.
                </p>
                <p style={pStyle}>
                    Please contact us to discuss your estate liquidation options with one of our
                    experienced estate auction specialists. We look forward to hearing from you.
                </p>
            </div>
        </Layout>
    )
}
