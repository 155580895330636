import React, { useContext, useEffect } from 'react'
import { NavLink, useHistory } from 'react-router-dom'
import { Badge } from '@material-ui/core'
import ShoppingCartOutlinedIcon from '@material-ui/icons/ShoppingCartOutlined'
import CartContext from '../../../Product/context/cart/cartContext'
import './cartIcon.css'
import { ShoppingCart } from '@material-ui/icons'
import { withStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import authContext from '../../../Product/context/auth/authContext'

const DualCart = (props) => {
    const cartContext = useContext(CartContext)
    const { buyer_cartitems, getUserCart, responseStatus, clearResponse } = cartContext
    const { userCount } = useContext(authContext)
    const [anchorEl, setAnchorEl] = React.useState(null)
    const history = useHistory()
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget)
    }

    const handleClose = () => {
        setAnchorEl(null)
    }
    useEffect(() => {
        if (responseStatus) {
            if (responseStatus.from === 'addToCart') {
                if (responseStatus.status === 'success') {
                    getUserCart()
                    clearResponse()
                }
            }
            if (responseStatus.from === 'removeFromCart') {
                if (responseStatus.status === 'success') {
                    getUserCart()
                    clearResponse()
                }
            }
        }
    }, [responseStatus])
    return (
        <>
            {/* <Button> */}
            <Badge
                badgeContent={buyer_cartitems.results.length + userCount.itemunPaidCount}
                aria-controls="customized-menu"
                aria-haspopup="true"
                variant="contained"
                color="primary"
                onClick={handleClick}
                style={{ cursor: 'pointer' }}
            >
                {props.fillIcon ? <ShoppingCart /> : <ShoppingCartOutlinedIcon />}
            </Badge>
            {/* </Button> */}
            <Menu
                id="customized-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
            >
                <MenuItem onClick={() => history.push('/auctioncart')}>
                    <ListItemIcon>
                        <Badge badgeContent={userCount.itemunPaidCount} color="primary">
                            {props.fillIcon ? (
                                <ShoppingCart fontSize="small" />
                            ) : (
                                <ShoppingCartOutlinedIcon fontSize="small" />
                            )}
                        </Badge>
                    </ListItemIcon>
                    <ListItemText primary="Auction" />
                </MenuItem>
                <MenuItem onClick={() => history.push('/cart')}>
                    <ListItemIcon>
                        <Badge badgeContent={buyer_cartitems.results.length} color="primary">
                            {props.fillIcon ? (
                                <ShoppingCart fontSize="small" />
                            ) : (
                                <ShoppingCartOutlinedIcon fontSize="small" />
                            )}
                        </Badge>
                    </ListItemIcon>
                    <ListItemText primary="Buy Now" />
                </MenuItem>
            </Menu>
        </>
    )
}

export default DualCart
