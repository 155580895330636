import React, { useState, useContext, useEffect } from 'react'
import AuthContext from '../../Product/context/auth/authContext'
import axios from 'axios'

const SASLoginFunction = (props) => {
    const authContext = useContext(AuthContext)
    const [Loading, setLoading] = useState(true)
    const { user, isAuthenticated } = authContext
    const [encEmail, setEncEmail] = useState('')
    const [SASBaseURL, setSASBaseURL] = useState('')

    useEffect(async () => {
        if (user) {
            let SASBaseURL = ''
            if (global.storeDetails?.site_id == 'AS0729') {
                SASBaseURL = 'https://auctions.helmarbrewing.com'
            } else if (global.storeDetails?.site_id == 'AS0604') {
                SASBaseURL = 'https://helmar-test.sasazure.com'
            } else if (global.storeDetails?.site_id == 'AS0732') {
                SASBaseURL = 'https://auctions.cincycardshowsauctions.com'
            } else if (global.storeDetails?.site_id == 'AS0605') {
                SASBaseURL = 'https://auctions.cincycardshowsauctions.com'
            }
            setSASBaseURL(SASBaseURL)
            login(user.email, SASBaseURL)
        }
    }, [user])

    const login = async (email, baseURL) => {
        if (email && baseURL) {
            setLoading(true)
            let u = 'ASUser'
            let pw = 'puccini'
            delete axios.defaults.headers.common['authorization']
            const instance = axios.create({
                baseURL: baseURL,
                headers: {
                    common: {
                        authorization: 'Basic ' + btoa(u + ':' + pw),
                    },
                },
            })
            // Step 1: Ajax call to encrypt user's email address
            let { data } = await instance.get(
                `/AjaxLibSA.aspx?command=Encryptem&em=${email}&id=${new Date().toTimeString()}`,
            )

            if (data && data.length > 5) {
                // Step 2: Use encrypted email to navigate to customer's SAS site
                setEncEmail(data)
                setLoading(false)
                window.open(baseURL + '/myaccount.aspx?uid=' + data, '_blank')
            } else {
                setEncEmail('')
                setLoading(false)
            }
        } else {
            setLoading(false)
        }
    }

    return (
        <div className="dashboard-wrapper">
            {Loading ? (
                <div className="np-pro-box"> {'Loading...'} </div>
            ) : encEmail && SASBaseURL ? (
                <div className="np-pro-box">
                    {' '}
                    <a
                        target={'_blank'}
                        rel="noreferrer"
                        href={SASBaseURL + '/myaccount.aspx?uid=' + encEmail}
                    >
                        <b>Go to SAS AUCTION</b>
                    </a>
                </div>
            ) : (
                <div className="np-pro-box"> {'Email not found'} </div>
            )}
        </div>
    )
}

export default SASLoginFunction
