import React from 'react'
import '../Login/Login.css'
import { Logo } from '../../../utils'
import ForgotPasswordFunction from '../../../utils/CommonFunctionality/ForgotPasswordFunction'

const ForgotPassword = () => {
    return (
        <div className="clearfix row vcaLogin">
            <div className="col-lg-6 d-none d-lg-block pad-0">
                {global.storeDetails?.site_id == 'AS0605' ||
                global.storeDetails?.site_id == 'AS0732' ? (
                    <div className="loginImgBox loginImgBoxCincy d-flex justify-content-between flex-column"></div>
                ) : (
                    <div className="loginImgBox  d-flex justify-content-between flex-column"></div>
                )}
            </div>
            <div className="col-lg-6 col-12">
                <div className="loginFormBox">
                    <Logo className="m-auto" />
                    <ForgotPasswordFunction />
                </div>
            </div>
        </div>
    )
}

export default ForgotPassword
