import React from 'react'
import DashboardLayout from '../DashboardLayout'
import Layout from '../Layout'
import BidderSuspension from '../../../utils/CommonFunctionality/BidderSuspension/Billing'

const Billing = (props) => {
    return (
        <Layout props={props}>
            <BidderSuspension />
        </Layout>
    )
}

export default Billing
