import React, { useState, useRef, cloneElement, useEffect } from 'react'

const ImageZoom = ({
    src,
    width,
    height,
    magnifierHeight = 100,
    magnifierWidth = 100,
    children,
    zoomLevel = 1.5,
}) => {
    const [[x, y], setXY] = useState([0, 0])
    const [[imgWidth, imgHeight], setSize] = useState([0, 0])
    const [isHovered, setIsHovered] = useState(false)

    const handleMouseMove = (e) => {
        const elem = e.currentTarget
        const { top, left } = elem.getBoundingClientRect()
        const x = e.pageX - left - window.scrollX
        const y = e.pageY - top - window.scrollY

        setXY([x, y])
    }

    const handleMouseEnter = (e) => {
        const elem = e.currentTarget
        const { width, height } = elem.getBoundingClientRect()
        setSize([width, height])
        setIsHovered(true)
    }
    const handleMouseLeave = () => setIsHovered(false)

    const childWithProps = cloneElement(children, {
        onMouseMove: handleMouseMove,
        onMouseLeave: handleMouseLeave,
        onMouseOver: handleMouseEnter,
        className: `${children.props.className}`,
    })

    return (
        <>
            {childWithProps}
            {isHovered && (
                <div
                    style={{
                        height: `${magnifierHeight}px`,
                        width: `${magnifierHeight}px`,
                        top: `${y - magnifierHeight / 2}px`,
                        left: `${x - magnifierHeight / 2}px`,
                        display: isHovered ? '' : 'none',
                        pointerEvents: 'none',
                        backgroundImage: `url('${src}')`,

                        backgroundSize: `${imgWidth * zoomLevel}px ${imgHeight * zoomLevel}px`,
                        backgroundPositionX: `${-x * zoomLevel + magnifierWidth / 2}px`,
                        backgroundPositionY: `${-y * zoomLevel + magnifierHeight / 2}px`,
                        position: 'absolute',
                        border: '3px solid black',
                        borderRadius: '50%',
                        pointerEvents: 'none',
                        backgroundRepeat: 'no-repeat',
                        zIndex: 10,
                    }}
                />
            )}
        </>
    )
}

export default ImageZoom
