import React from 'react'
import TextField from '@material-ui/core/TextField'
import Tooltip from '@material-ui/core/Tooltip'
import { InputAdornment } from '@material-ui/core'

const CustomInput = (props) => {
    const shrink = props.shrink ? props.shrink.toString() : 'false'
    return (
        <div className="customInput" style={props.inputStyle || props.inputStyle}>
            {props.upperLabel ? (
                <>
                    <label>{props.label}</label>
                    <TextField
                        value={props.value}
                        autoFocus={props.autoFocus}
                        name={props.name}
                        onChange={props.onChange || props.onChange}
                        onWheel={props.onWheel}
                        onBlur={props.onBlur || props.onBlur}
                        inputProps={{
                            inputProps: props.inputProps,
                            shrink: shrink,
                            step: props.step,
                            min: props.min,
                        }}
                        InputProps={{
                            pattern: props.pattern,
                            startAdornment: props.startAdornment,
                            endAdornment: (
                                <InputAdornment position="end">{props.endAdornment}</InputAdornment>
                            ),
                        }}
                        id={props.id}
                        type={props.type}
                        size={props.size}
                        disabled={props.disabled}
                        variant={props.variant ? props.variant : 'outlined'}
                        placeholder={props.placeholder}
                        error={props.error}
                        helperText={props.helperText}
                        autoComplete={props.autoComplete ? 'new-password' : ''}
                        onPaste={(e) => {
                            props.disablePaste ? e.preventDefault() : null
                        }}
                    />
                </>
            ) : (
                <Tooltip
                    title={props.tooltiptitle}
                    arrow={props.tooltiptitle ? true : false}
                    placement="top"
                    disableHoverListener={true}
                    disableFocusListener={props.tooltiptitle ? false : true}
                >
                    <TextField
                        value={props.value}
                        autoFocus={props.autoFocus}
                        name={props.name}
                        onChange={props.onChange || props.onChange}
                        onWheel={props.onWheel}
                        onBlur={props.onBlur || props.onBlur}
                        inputProps={{
                            inputProps: props.inputProps,
                            startAdornment: props.startAdornment,
                            endAdornment: (
                                <InputAdornment position="end">{props.endAdornment}</InputAdornment>
                            ),
                            shrink: shrink,
                            step: props.step,
                            min: props.min,
                        }}
                        InputProps={{
                            startAdornment: props.startAdornment,
                            endAdornment: (
                                <InputAdornment position="end">{props.endAdornment}</InputAdornment>
                            ),
                        }}
                        id={props.id}
                        label={props.label}
                        type={props.type}
                        size={props.size}
                        disabled={props.disabled}
                        variant={props.variant ? props.variant : 'outlined'}
                        placeholder={props.placeholder}
                        error={props.error}
                        helperText={props.helperText}
                        autoComplete={props.autoComplete ? 'new-password' : ''}
                        onPaste={(e) => {
                            props.disablePaste ? e.preventDefault() : null
                        }}
                        onBlurCapture={(e) => {
                            props?.onBlurCapture ? props?.onBlurCapture(e) : ''
                        }}
                    />
                </Tooltip>
            )}
        </div>
    )
}

export default CustomInput
