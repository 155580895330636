/* eslint-disable */
import React, { useContext, useState, useEffect } from 'react'
import Box from '@material-ui/core/Box'
import Container from '@material-ui/core/Container'
import { Link, useHistory } from 'react-router-dom'
import {
    currencyFormat,
    dateFormatFront,
    handleRedirectInternal,
    mapData,
} from '../../../Product/common/components'
import { Redirect } from 'react-router-dom'
import ProductContext from '../../../Product/context/product/productContext'
import AuthContext from '../../../Product/context/auth/authContext'
import { Pagination } from '@material-ui/lab'
import Timer from '../../../Product/common/timer'
import { Button, Tooltip } from '@material-ui/core'
import MultisellerSlider from '../../../Component/SlidePanel/MultisellerSlider'
import NoRecordsFound from '../../../Product/components/atoms/NoRecordsFound'
import { getImageurl, imageError } from '../../../utils/commonFunctions'
import Loader from '../../../assets/loader'
import PrimaryButton from '../../../Product/components/atoms/PrimaryButton'
import CustomDialog from '../../../Product/components/organisms/Dialog'
import SecondaryButton from '../../../Product/components/atoms/SecondaryButton'
import userContext from '../../../Product/context/user/userContext'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import pluginContext from '../../../Product/context/plugin/pluginContext'
import InvoiceMessage from '../../../utils/CommonFunctionality/Message/InvoiceMessage'
import Popup from '../../../Product/components/organisms/Popup'
import alertContext from '../../../Product/context/alert/alertContext'

const CurrentOrders = (props) => {
    const productContext = useContext(ProductContext)
    const {
        seller_allproducts,
        getAllSellerProducts,
        get_seller_dashboard,
        seller_dashboard_data,
        update_marke_as_paid,
        buynowApprove,
        responseStatus,
    } = productContext
    const {
        static_page,
        getStaticPage,
        responseStatus: responseStatusUser,
    } = useContext(userContext)
    const {
        updateRefund,
        responseStatus: responseStatusPlugin,
        clearResponse: clearResponsePlugin,
    } = useContext(pluginContext)
    const authContext = useContext(AuthContext)
    const { setAlert } = useContext(alertContext)
    const { isAuthenticated, isAdmin, user } = authContext
    const [selectedProduct, setSelectedProduct] = useState(null)
    const [data, setData] = useState([])
    const [userData, setUserData] = useState({})
    const [sellerData, setSellerData] = useState({})
    const [loading, setLoading] = useState(true)
    const [approveLoading, setApproveLoading] = useState(false)
    const history = useHistory()
    const [toInvoice, settoInvoice] = useState(false)
    const [toInvoiceID, settoInvoiceID] = useState({
        inv_id: 0,
        user_id: 0,
    })
    const [popupchecked, setPopupchecked] = useState(false)
    const [messageChecked, setMessageChecked] = useState(false)
    const [buyerName, setBuyerName] = useState('')

    const [buynowId, setBuynowId] = useState({})
    const [termscondtions, setTermscondition] = useState('')
    const [verify, setVerify] = useState(false)
    const [poupppaid, setPopupPaid] = useState(false)
    const [search, setSearch] = useState({
        status: 'sold,open',
        limit: 12,
        page: 1,
        paid: global.pluginConfiguration?.offline_payment_buynow?.enable ? undefined : 1,
        need_payment_type: 1,
    })
    useEffect(() => {
        setLoading(false)
    }, [seller_allproducts])
    const onHandlePage = (event, value) => {
        setSearch({ ...search, page: value })
    }

    const handleClose = () => setSelectedProduct(null)

    const handleClick = async (id) => {
        setSelectedProduct(id)
    }

    const formik_paid = useFormik({
        initialValues: { trans_id: '', invoice: '', buyer_email: '', buyer_phno: '', pay_type: '' },
        enableReinitialize: true,
        onSubmit: async (val) => {
            update_marke_as_paid({
                trans_id: formik_paid.values.trans_id ? formik_paid.values.trans_id : '-',
                invoice: formik_paid.values.invoice,
            })
            formik.values.trans_id = ''
            formik.values.invoice = ''
        },
    })
    const mark_as_paid = [
        {
            label: 'Transaction Number (or) Notes',
            name: 'trans_id',
            type: 'text',
            placeholder: 'Enter details here..',
            class: 'col-12',
            autoFocus: true,
            formik: formik_paid,
        },
    ]
    useEffect(() => {
        setPopupchecked(buyerName ? true : false)
    }, [buyerName])
    const handleApprove = (approve) => {
        setApproveLoading(true)
        if (approve == 2) {
            return updateRefund({
                lot_id: `${buynowId?.id}`,
                invoice_id: `${buynowId?.common_invoice}`,
                user_id: buynowId?.buyer_id,
                refund_paytype: 'org_pay',
                first_name_emp: user?.first_name,
                is_without_plugin: 1,
                is_reseller: 1,
            })
        }
        buynowApprove({ approve, buynow_id: buynowId?.buynowid })
    }

    useEffect(() => {
        if (responseStatusPlugin) {
            if (responseStatusPlugin.from === 'updateRefund') {
                setApproveLoading(false)
                if (responseStatusPlugin.status === 'success') {
                    setApproveLoading(true)
                    buynowApprove({ approve: 2, buynow_id: buynowId?.buynowid })
                }
                clearResponsePlugin()
            }
        }
    }, [responseStatusPlugin])

    useEffect(() => {
        if (isAuthenticated) {
            setLoading(true)
            if (props.page === 'paid') {
                setLoading(true)
                getAllSellerProducts(search)
            } else if (props.page === 'unpaid') {
                setLoading(true)
                getAllSellerProducts(search)
            }
        }
    }, [search, isAuthenticated])

    useEffect(() => {
        if (responseStatus) {
            if (responseStatus.from === 'buynow_approve') {
                setApproveLoading(false)
                if (responseStatus.status === 'success') {
                    setLoading(true)

                    setBuynowId({})
                    setVerify(false)
                    getAllSellerProducts(search)
                }
            }
            if (responseStatus.from == 'inv_update') {
                if (responseStatus.status == 'success') {
                    setAlert('Invoice Details Updated!', 'success')
                    getAllSellerProducts(search)
                    setPopupPaid(false)
                }
            }
        }
    }, [responseStatus])
    useEffect(() => {
        if (global?.storeConfigration?.checkout_terms_and_condtions?.value == 1) {
            getStaticPage(
                {
                    page_id: 'checkout_terms_and_condtions',
                },
                1,
            )
        }
    }, [])
    useEffect(() => {
        if (global?.storeConfigration?.checkout_terms_and_condtions?.value == 1) {
            if (static_page.content) {
                setTermscondition(static_page.content)
            }
        }
    }, [static_page])
    const formik = useFormik({
        initialValues: { buyer_document_exist: [] },
    })
    const documentShow = [
        {
            type: 'previousImages',
            formik: formik,
            name: 'buyer_document_exist',
            class: 'col-12 thisInput',
            folder: '',
            hide_delete: 1,
        },
    ]
    useEffect(() => {
        if (Object.keys(buynowId).length) {
            formik.setFieldValue(
                'buyer_document_exist',
                buynowId?.buyer_taxfile_org?.includes('buyer_2')
                    ? buynowId?.buyer_taxfile_org?.includes('[')
                        ? JSON.parse(buynowId.buyer_taxfile_org)?.buyer_2
                        : JSON.parse(buynowId.buyer_taxfile_org)?.buyer_2?.split(',')
                    : [],
            )
        }
    }, [buynowId])

    return (
        <div>
            {toInvoice ? (
                <Redirect to={`invoice/${toInvoiceID.inv_id}/${toInvoiceID.user_id}`} />
            ) : null}
            {loading ? (
                <div className="text-center">
                    <Loader />
                </div>
            ) : seller_allproducts.result.length ? (
                <>
                    <div className="table-responsive">
                        <table className="table table-hover">
                            <thead>
                                <tr>
                                    <th className="numCell">#</th>
                                    <th className="numCell">Invoice Id</th>
                                    <th className="numCell">Lot Id</th>
                                    <th>Image</th>
                                    <th>Product Name</th>
                                    <th>Uploads</th>
                                    <th>Amount</th>
                                    {props.page === 'paid' &&
                                    global.storeConfigration.unique_slot4.value != 1 ? (
                                        <Tooltip
                                            // open={true}
                                            title={
                                                'The Payout Is Typically Paid Out 3-7 Business Days After A Successful Payment Is Received!'
                                            }
                                            arrow={true}
                                            placement="top"
                                            // disableHoverListener={true}
                                            // disableFocusListener={true}
                                        >
                                            <th>Payout</th>
                                        </Tooltip>
                                    ) : null}
                                    {/* <th>Type</th> */}
                                    <th>Action</th>
                                </tr>
                            </thead>
                            {seller_allproducts.result.map((item, index) => {
                                return (
                                    <>
                                        <tbody>
                                            <tr>
                                                <td>{index + 1}</td>
                                                <td>{item.common_invoice}</td>
                                                <td>{item.id}</td>
                                                <td>
                                                    <img
                                                        className="pro-img"
                                                        src={getImageurl(
                                                            item.store_id.toString(),
                                                            item.avatar,
                                                        )}
                                                        alt={item.title}
                                                        width="65"
                                                        height="65"
                                                        onError={(e) =>
                                                            (e.target.src = imageError())
                                                        }
                                                    />
                                                </td>
                                                <td>{item.title}</td>
                                                <td>
                                                    {/* <div className="uploadingFileCurrent">
                                                        <label for="file-input">
                                                            <span
                                                                className="material-icons"
                                                                onClick={uploadingFileCurrent()}
                                                            >
                                                                upload
                                                            </span>
                                                        </label>
                                                        <input type="file" id="file-input" />
                                                    </div> */}
                                                    <SecondaryButton
                                                        btnSize="minMaxContent"
                                                        className="seeVideoBtn"
                                                        onClick={() => {
                                                            setMessageChecked(true)
                                                            setBuynowId(item)
                                                        }}
                                                    >
                                                        See Video
                                                    </SecondaryButton>
                                                </td>
                                                <td>
                                                    <b>
                                                        {currencyFormat(
                                                            parseFloat(
                                                                item.buynowpaid
                                                                    ? item.buynowamount
                                                                    : item.total_invoice,
                                                            ),
                                                        )}
                                                    </b>
                                                </td>

                                                {props.page === 'paid' &&
                                                global.storeConfigration.unique_slot4.value != 1 ? (
                                                    <td>
                                                        <b>
                                                            {currencyFormat(
                                                                parseFloat(
                                                                    item.additional_amount_3,
                                                                ),
                                                            )}
                                                        </b>
                                                    </td>
                                                ) : null}
                                                {/* <td>{item.isbuynow ? 'Buy Now' : 'Auction'}</td> */}
                                                <td className="orderBtns">
                                                    {item.buynowpaid ? (
                                                        <>
                                                            {item.paid_approved == 0 ? (
                                                                <>
                                                                    <PrimaryButton
                                                                        btnSize="minMaxContent"
                                                                        onClick={() => {
                                                                            setVerify(true)
                                                                            setBuynowId(item)
                                                                        }}
                                                                    >
                                                                        Verify
                                                                    </PrimaryButton>
                                                                </>
                                                            ) : null}
                                                            {item.paid_approved == 1 && (
                                                                <>
                                                                    <p className="approved">
                                                                        Approved!
                                                                    </p>
                                                                    <PrimaryButton
                                                                        btnSize="minMaxContent"
                                                                        onClick={() =>
                                                                            handleRedirectInternal(
                                                                                history,
                                                                                item.auction
                                                                                    ? `invoice/auction?id=${item.common_invoice}&bid=${item.buynowuser_id}`
                                                                                    : `invoice/buynow?id=${item.common_invoice}&bid=${item.buynowuser_id}`,
                                                                            )
                                                                        }
                                                                    >
                                                                        View Invoice
                                                                    </PrimaryButton>
                                                                    {global?.storeConfigration
                                                                        ?.checkout_terms_and_condtions
                                                                        ?.value == 1 ? (
                                                                        <SecondaryButton
                                                                            btnSize="minMaxContent"
                                                                            className="termsAndConditions"
                                                                            onClick={() => {
                                                                                setBuyerName(
                                                                                    `${item.buyer_first_name} ${item.buyer_last_name}`,
                                                                                )
                                                                            }}
                                                                        >
                                                                            Terms & Conditions
                                                                        </SecondaryButton>
                                                                    ) : null}
                                                                </>
                                                            )}
                                                            {item.paid_approved == 2 && (
                                                                <p className="denied">Denied!</p>
                                                            )}
                                                        </>
                                                    ) : (
                                                        <PrimaryButton
                                                            btnSize="minMaxContent"
                                                            onClick={() => {
                                                                formik_paid.setFieldValue(
                                                                    'invoice',
                                                                    item.common_invoice,
                                                                )
                                                                formik_paid.setFieldValue(
                                                                    'buyer_email',
                                                                    item.buyer_email,
                                                                )
                                                                formik_paid.setFieldValue(
                                                                    'buyer_phno',
                                                                    item.buyer_phone,
                                                                )

                                                                formik_paid.setFieldValue(
                                                                    'pay_type',
                                                                    item.pay_type,
                                                                )
                                                                setPopupPaid(true)
                                                            }}
                                                        >
                                                            Mark As Paid
                                                        </PrimaryButton>
                                                    )}

                                                    <PrimaryButton
                                                        btnSize="minMaxContent"
                                                        onClick={() =>
                                                            handleRedirectInternal(
                                                                history,
                                                                `dashboard/message?user=${item.buynowuser_id}`,
                                                            )
                                                        }
                                                    >
                                                        Message
                                                    </PrimaryButton>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </>
                                )
                            })}
                        </table>
                    </div>
                    <div className="d-flex mt-3">
                        <div className="ml-auto mt-2">
                            {'Showing'} {search.limit * (search.page - 1) + 1} -{' '}
                            {search.limit * search.page > seller_allproducts.length
                                ? seller_allproducts.length
                                : search.limit * search.page}{' '}
                            of {seller_allproducts.length}
                        </div>
                        <Pagination
                            count={Math.ceil(seller_allproducts.length / search.limit)}
                            page={search.page}
                            onChange={onHandlePage}
                        />
                    </div>
                    <MultisellerSlider
                        selectedLot={selectedProduct}
                        handleClose={handleClose}
                        type={props.type ? props.type : ''}
                    />
                </>
            ) : (
                <>
                    <NoRecordsFound />
                </>
            )}
            <CustomDialog
                open={verify}
                function={() => {
                    setVerify(false)
                    setBuynowId({})
                }}
                size="md"
                className="save-search-modal"
                title="Buyer Details"
                closeBtn={true}
            >
                <div>
                    <p>Driving License</p>
                    {mapData(documentShow)}
                    <p>Buyer Details</p>
                    <div className="table-responsive">
                        <table className="table table-borderless">
                            <tr>
                                <th>Name</th>
                                <td>{`${buynowId?.buyer_first_name} ${buynowId?.buyer_last_name}`}</td>
                            </tr>
                            <tr>
                                <th>Email</th>
                                <td>{buynowId?.buyer_email}</td>
                            </tr>
                            <tr>
                                <th>Phone</th>
                                <td>{buynowId?.buyer_phone}</td>
                            </tr>
                            <tr>
                                <th>Address</th>
                                <td>
                                    {buynowId?.buyer_address1},{buynowId?.buyer_city},
                                    {buynowId?.buyer_state}, {buynowId?.buyer_zip}
                                </td>
                            </tr>
                            {/* <tr>
                                <th></th>
                                <td>{buynowId?.buyer_address2}</td>
                            </tr>
                            <tr>
                                <th></th>
                                <td>{buynowId?.buyer_city}</td>
                            </tr>
                            <tr>
                                <th></th>
                                <td>
                                    {buynowId?.buyer_state}, {buynowId?.buyer_zip}
                                </td>
                            </tr> */}
                        </table>
                    </div>
                    <div className="buyerDetailsButtons">
                        <PrimaryButton
                            btnSize="minMaxContent"
                            onClick={() => handleApprove(1)}
                            disabled={approveLoading}
                        >
                            {approveLoading ? 'Loading...' : 'Approve'}
                        </PrimaryButton>
                        <PrimaryButton
                            btnSize="minMaxContent"
                            onClick={() => handleApprove(2)}
                            disabled={approveLoading}
                        >
                            {approveLoading ? 'Loading...' : 'Deny'}
                        </PrimaryButton>
                    </div>
                </div>
            </CustomDialog>
            <CustomDialog
                open={popupchecked}
                function={() => setBuyerName('')}
                size="md"
                className="save-search-modal termsMod"
                title="Terms and Conditions"
                closeBtn={true}
            >
                <div
                    dangerouslySetInnerHTML={{
                        __html: `${global?.storeDetails?.terms_condition}${termscondtions}<p><b>Terms and conditions accepted by the ${buyerName}.</b</p>`,
                    }}
                ></div>
            </CustomDialog>
            <CustomDialog
                open={poupppaid}
                function={() => {
                    formik_paid.setFieldValue('invoice', '')
                    formik_paid.setFieldValue('trans_id', '')
                    formik_paid.setFieldValue('pay_type', '')
                    formik_paid.setFieldValue('buyer_email', '')
                    formik_paid.setFieldValue('buyer_phno', '')
                    setPopupPaid(false)
                }}
                size="md"
                className="save-search-modal termsMod"
                title="Transaction details"
                closeBtn={true}
            >
                <div className="row">
                    <p>This Buyer Requested Offline Payment.</p>
                </div>
                <div className="row">
                    <p>Payment Type: {formik_paid.values.pay_type}</p>
                </div>
                <div className="row">
                    <p>Buyer Email: {formik_paid.values.buyer_email}</p>
                </div>
                <div className="row">
                    {' '}
                    <p>Buyer Phno: {formik_paid.values.buyer_phno}</p>
                </div>
                <div>{mapData(mark_as_paid)}</div>
                <div className="row">
                    <div className="col-4"></div>
                    <div className="col-5">
                        <PrimaryButton
                            onClick={formik_paid.handleSubmit}
                            btnSize="mt-3"
                            label="Submit"
                            type="submit"
                        />
                    </div>
                </div>
            </CustomDialog>
            <Popup
                open={messageChecked}
                handleClose={() => {
                    setMessageChecked(false)
                    setBuynowId({})
                }}
                size="md"
                className="save-search-modal"
                modaltitle="Upload Files"
            >
                <InvoiceMessage
                    project_id={buynowId?.common_invoice}
                    chatUser={{
                        first_name: `${buynowId?.title} -`,
                        last_name: ` ${buynowId?.common_invoice}`,
                        id: buynowId?.buyer_id,
                    }}
                    hide_header={1}
                />
            </Popup>
        </div>
    )
}

export default CurrentOrders
