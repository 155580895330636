import React, { useState, useEffect, useContext } from 'react'
import { Redirect } from 'react-router-dom'
import ProductContext from '../../../Product/context/product/productContext'
import AlertContext from '../../../Product/context/alert/alertContext'
import AuthContext from '../../../Product/context/auth/authContext'
import PluginContext from '../../../Product/context/plugin/pluginContext'
import PrimaryButton from '../../../Product/components/atoms/PrimaryButton'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import {
    mapData,
    dateTimeFormatFunction,
    handleRedirectInternal,
    jsonToFormData,
} from '../../../Product/common/components'
import { Link, useHistory } from 'react-router-dom'
import axios from 'axios'
import moment from 'moment'
import CustomDialog from '../../../Product/components/organisms/Dialog'
import { Button } from '@material-ui/core'
import BidIncrement from './bidincrement'
import { Cancel, CheckCircle } from '@material-ui/icons'
import { swapCountry } from '../../commonFunctions'
import { useTranslation } from 'react-i18next'
const Step1 = (props) => {
    const productContext = useContext(ProductContext)
    const alertContext = useContext(AlertContext)
    const { setAlert, clearAlert, alerts } = alertContext
    const history = useHistory()
    const { setActiveStep, auction_id } = props
    const {
        postAuction,
        edit_auction,
        auction_lots,
        getEditAuction,
        responseStatus,
        seller_allauctions,
        getAllSellerAuctions,
        clearResponse,
    } = productContext
    const { responseStatus: responseStatusPlugin, requestdiscount } = useContext(PluginContext)
    const editType = new URLSearchParams(window.location.search.substring(1)).get('type')
    const authContext = useContext(AuthContext)
    const { isAuthenticated, user, isAdmin } = authContext
    const [toHome, settoHome] = useState(false)
    const [loading, setLoading] = useState(false)
    const [reload, setReload] = useState(true)
    const [endAdornment, setEndAdornment] = useState('')
    const [loaders, setLoaders] = useState(!auction_id ? true : false)
    const [load, setLoad] = useState(!auction_id ? true : false)
    const [stateList, setStateList] = useState([])
    const [bidincarr, setBidIncarr] = useState([])
    const [countryList, setCountryList] = useState([])
    const [item] = seller_allauctions?.result
    const [timezone, setTimezone] = useState({
        local: moment().format('MM-DD-YYYY h:mm:ss A'),
        utc: moment().utc().format('MM-DD-YYYY h:mm:ss A'),
    })
    const [open, setOpen] = useState(false)
    const [sendData, setSendData] = useState(false)
    const { t } = useTranslation()
    const [completedEditing, setCompleteEditing] = useState(false)
    useEffect(() => {
        if (isAuthenticated) {
            if (user.role === '1') {
            } else {
                handleRedirectInternal(history, '')
            }
        }
    }, [isAuthenticated])
    const toggleOpen = () => {
        setOpen(!open)
    }
    const validationArray = Yup.object({
        title: Yup.string().required('Required!'),
        description: Yup.string().trim().required('Required!'),
        start_date: Yup.string().required('Required!').nullable(),
        end_date: Yup.string().required('Required!').nullable(),
        ending_enable: Yup.boolean(),
        ending_items: Yup.number().when('ending_enable', {
            is: (end) => end === true,
            then: Yup.number().min(0, 'Not valid!').required('Required!'),
        }),
        ending_mints: Yup.number().when('ending_enable', {
            is: (end) => end === true,
            then: Yup.number().min(0, 'Not valid!').required('Required!'),
        }),
        // lot_id: Yup.array().required('Required!'),
        product_image: Yup.array(),
        // product_imagefile: Yup.array()
        //     .min(1, t('Image') + ' ' + t('required') + '!')
        //     .required(t('Image') + ' ' + t('required') + '!'),
        product_imagefile: Yup.array()
            .test('at-least-one-image', t('Image') + ' ' + t('required') + '!', function (val) {
                const product_existingimage = formik.values.product_existingimage
                return (
                    (val && val.length > 0) ||
                    (product_existingimage && product_existingimage.length > 0)
                )
            })
            .required(t('Image') + ' ' + t('required') + '!'),
        address: Yup.string().required('Required!'),
        country: Yup.string().required('Required!'),
        state: Yup.string().required('Required!'),
        /*eslint-disable*/
        zipcode: Yup.string().when([], {
            is: () => formik?.values.country != 156,
            then: Yup.string().required('Required!'),
            otherwise: Yup.string().notRequired(),
        }),
        city: Yup.string()
            .matches(/^[a-zA-Z\s]+$/, 'Only letters are allowed')
            .required('Required!'),
        phone: Yup.string()
            .trim()
            .matches(/^[0-9- +()]*$/g, 'Only numbers are allowed')
            .max(15, 'Maximum 15 characters')
            .required('Required'),
        la_seller_id:
            props.theme !== 'FoodIndustry'
                ? Yup.number().typeError('Invalid number!').required('Required!')
                : Yup.number().typeError('Invalid number!'),
    })

    useEffect(() => {
        const check_auction_limit = () => {
            var items_limit = global.storeConfigration?.global_auction_items?.value
                ? global.storeConfigration?.global_auction_items?.value
                : 0
            if (parseInt(items_limit) > 0) {
                var data_product = {
                    limit: 10,
                    page: 1,
                    status: 'open',
                }
                getAllSellerAuctions(data_product)
            }
        }
        check_auction_limit()
    }, [])

    useEffect(() => {
        var items_limit = global.storeConfigration?.global_auction_items?.value
            ? global.storeConfigration?.global_auction_items?.value
            : 0

        if (seller_allauctions.result.length > 0 && parseInt(items_limit) > 0) {
            var total_items = seller_allauctions.result.length
            // //console.log('items+++++++', items_limit, total_items)
            if (parseInt(items_limit) <= parseInt(total_items)) {
                setAlert('Your Auction Limit Is Reached!', 'error')
                handleRedirectInternal(history, `dashboard/overview`)
            }
        }
    }, [seller_allauctions])

    useEffect(() => {
        if (loading && (global?.storeDetails.theme === 7 || global?.storeDetails.theme === 3)) {
            setAlert('Please Wait While We Setup Your Auction', 'warnning')
        }
    }, [loading])

    const formik = useFormik({
        initialValues: {
            title: '',
            description: '',
            start_date: null,
            end_date: null,
            lot_id: 0,
            status: 'open',
            auctionid: 0,
            private_auction: 0,
            selectedbuyers: [],
            grouping: 1,
            ending_enable: global.storeConfigration?.global_stagger_bidding?.value ? true : 0,
            ending_items: global.storeConfigration?.global_stagger_items?.value
                ? global.storeConfigration?.global_stagger_items?.value
                : 0,
            ending_mints: global.storeConfigration?.global_stagger_bidding?.value
                ? global.storeConfigration?.global_stagger_bidding?.value
                : 0,
            la_seller_id:
                global.storeConfigration?.global_buyer_premium?.value > 0 &&
                props.theme == 'multiseller_two'
                    ? global.storeConfigration.global_buyer_premium.value
                    : 0,
            product_image: [],
            product_existingimage: [],
            product_imagefile: [],
            localpickup: 1,
            shipping: 0,
            custom_field_5:
                global?.storeDetails.theme === 7 ? global?.storeDetails.terms_condition : '',
            custom_field_6:
                !auction_id && global.storeConfigration?.pickup_details_global?.value
                    ? global.storeConfigration?.pickup_details_global?.value
                    : '',
            //address
            address: '',
            country: props.defaultCountryCode ? props.defaultCountryCode : '',
            state: '',
            city: '',
            zipcode: '',
            phone: '+1',
            enable_bid_increment: 0,
            custom_text_field: '',
            custom_type: 'auction_io',
            store_id: 0,
            coupon_code: '',
            repost: editType === 'repost' ? 1 : undefined,
            repost_status: 'draft',
        },
        validationSchema: validationArray,
        onSubmit: async (values) => {
            // setOpen(true)
            // setSendData(values)
            if (values.enable_bid_increment) {
                var bid_inc_arr = [],
                    big_error = 0,
                    pos = 0
                //console.log('innnnnnnnnnnnnnnnnnnnnnnn', values.custom_text_field)
                values.custom_text_field.map((val, index) => {
                    pos = parseInt(index) + 1
                    if (val.from == '') {
                        setAlert('Invalid Input for from field value at row' + pos, 'error')
                        big_error = 1
                        return false
                    } else if (val.to == '') {
                        setAlert('Invalid Input for to field value at row' + pos, 'error')
                        big_error = 1
                        return false
                    } else if (val.price == '') {
                        setAlert('Invalid Input for price field value at row' + pos, 'error')
                        big_error = 1
                        return false
                    } else {
                        bid_inc_arr.push({
                            bprice_range_from: parseInt(val.from),
                            bprice_range_to: parseInt(val.to),
                            per_price: parseInt(val.price),
                        })
                    }
                })
                if (big_error) {
                    return false
                } else {
                    if (auction_id) {
                        values.custom_text_field = JSON.stringify(bid_inc_arr)
                        values.custom_text_field = JSON.stringify(values.custom_text_field)
                    } else {
                        values.custom_text_field = JSON.stringify(bid_inc_arr)
                    }
                }
            } else {
                values.custom_text_field = ''
            }
            const value = values
            if (!value.product_existingimage.length) {
                if (!value.product_imagefile.length) {
                    return setAlert('Image is required!', 'warning')
                }
            }
            value.product_image = value.product_imagefile[0]

            let formDataSend = jsonToFormData(value)

            if (auction_id) {
                postAuction(formDataSend)
                setLoading(true)
            } else {
                postAuction(formDataSend)
                setLoading(true)
            }
        },
    })
    // const postAnAuction = () => {
    //     const value = sendData
    //     if (!value.product_existingimage.length) {
    //         if (!value.product_imagefile.length) {
    //             return setAlert('Image is required!', 'warning')
    //         }
    //     }
    //     value.product_image = value.product_imagefile[0]

    //     let formDataSend = jsonToFormData(value)

    //     if (auction_id) {
    //         postAuction(formDataSend)
    //         setLoading(true)
    //     } else {
    //         postAuction(formDataSend)
    //         setLoading(true)
    //     }
    // }
    const cancelToken = axios.CancelToken.source()
    const [cancelRequest, setCancelRequest] = useState(undefined)
    useEffect(() => {
        if (cancelRequest) {
            cancelRequest.cancel()
        }
        setCancelRequest(cancelToken)
        requestdiscount(
            {
                inputcpn: formik.values.coupon_code,
                storeId: global?.storeDetails?.id,
                validate_only: 1,
            },
            cancelToken,
        )
    }, [formik.values.coupon_code])
    useEffect(() => {
        if (responseStatus) {
            if (responseStatus.from === 'postAuction') {
                setLoading(false)
                if (responseStatus.status === 'success') {
                    if (editType !== 'repost' && !completedEditing) {
                        handleRedirectInternal(history, `post-auction?id=${responseStatus.data}`)
                        handleRedirectInternal(
                            history,
                            `edit-auction/${responseStatus.data}?type=auction`,
                        )
                        setActiveStep(1)
                    } else {
                        handleRedirectInternal(history, `sellerdashboard`)
                    }
                }
            }
        }
    }, [responseStatus])

    useEffect(() => {
        if (isAuthenticated) {
            if (auction_id) {
                getEditAuction({ auctionid: auction_id })
            }
        }
    }, [isAuthenticated])

    // for image rendering
    // useEffect(() => {
    //     async function fetchData() {
    //         if (formik.values.product_imagefile.length) {
    //             await Promise.all([toReRenderImages(formik, setAuction)])
    //         }
    //     }
    //     fetchData()
    // }, [formik.values.product_imagefile])
    // //console.log(formik.values.start_date)
    useEffect(() => {
        if (edit_auction && auction_id) {
            let editAuctionDetails = edit_auction.auction_details
            let lot_id = auction_lots
                .filter((i) => (i.lotof === auction_id ? true : false))
                .map((dat) => dat.id)
            formik.values.title = editAuctionDetails.title
            formik.values.description = editAuctionDetails.description
            formik.values.start_date = editAuctionDetails.date_added

            formik.values.end_date = editAuctionDetails.date_closed

            formik.values.lot_id = lot_id.length ? lot_id : 0
            formik.values.private_auction =
                editAuctionDetails.private_auction &&
                parseInt(editAuctionDetails.private_auction) > 0
                    ? 1
                    : 0
            formik.values.selectedbuyers = editAuctionDetails.selectedbuyers
                ? editAuctionDetails.selectedbuyers.split(',').map((i) => parseInt(i))
                : []
            formik.values.status = editAuctionDetails.market_status
            formik.values.ending_enable = editAuctionDetails.ending_enable
            formik.values.ending_items = editAuctionDetails.ending_items
            formik.values.ending_mints = editAuctionDetails.ending_mints
            formik.values.auctionid = auction_id
            formik.values.la_seller_id = editAuctionDetails.la_seller_id
            formik.values.product_existingimage = [editAuctionDetails.avatar]
            formik.values.product_imagefile = []

            formik.values.address = editAuctionDetails.address
            formik.values.country = editAuctionDetails.country
            formik.values.state = editAuctionDetails.state
            formik.values.city = editAuctionDetails.city
            formik.values.zipcode = editAuctionDetails.zipcode
            formik.values.phone = editAuctionDetails.custom_field_1
            formik.values.custom_field_5 = editAuctionDetails.custom_field_5
            formik.values.custom_field_6 = editAuctionDetails.custom_field_6
            formik.values.coupon_code = editAuctionDetails.coupon_code
            formik.values.enable_bid_increment = editAuctionDetails.custom_text_field ? 1 : 0
            setBidIncarr(
                editAuctionDetails.custom_text_field
                    ? JSON.parse(editAuctionDetails.custom_text_field)
                    : [],
            )
            setReload(!reload)
            setLoaders(true)
            setTimeout(() => {
                setLoad(true)
            }, 1500)
        }
    }, [edit_auction, auction_lots])
    const getLocationData = () => {
        var body = {
            countryCode: formik.values.country,
        }

        return axios
            .post(`${process.env.REACT_APP_URL}fetchLocation`, body)
            .then(function (response) {
                if (response.data.success) {
                    setStateList(response.data.state)
                    setCountryList(response.data.country)
                }
            })
            .catch(function (error) {
                //console.log(error)
            })
    }

    useEffect(() => {
        getLocationData()
    }, [formik.values.country])
    var productListing = [
        {
            label: 'Coupon Code',
            type: 'text',
            placeholder: 'HDHDG4523GJD',
            class: global.pluginConfiguration?.coupon_code?.enable == 1 ? 'col-12' : 'd-none',
            name: 'coupon_code',
            formik: formik,
            endAdornment,
        },
        {
            label: 'Auction Title *',
            type: 'text',
            placeholder: 'Enter your Auction Title',
            class: 'col-12',
            name: 'title',
            formik: formik,
        },
        {
            label: 'Description*',
            placeholder: 'Enter Description',
            type: 'ckeditor1',
            class: 'col-12',
            name: 'description',
            formik: formik,
        },
        {
            label: 'Terms & conditions',
            placeholder: 'Enter terms & conditions',
            type: 'ckeditor1',
            readOnly: global?.storeDetails.theme === 7 ? true : false,
            class: 'col-12',
            name: 'custom_field_5',
            formik: formik,
        },
        {
            label: global?.storeDetails.theme === 7 ? 'Pickup details' : 'Shipping details',
            placeholder: 'Enter shipping details',
            type: 'ckeditor1',
            class: `${
                global.storeConfigration?.remove_shipping_fields?.value != 1 ? 'col-12' : 'd-none'
            }`,
            name: 'custom_field_6',
            formik: formik,
        },
    ]
    useEffect(() => {
        if (responseStatusPlugin) {
            if (responseStatusPlugin.from === 'requestDiscount') {
                if (responseStatusPlugin.status === 'success') {
                    setEndAdornment(
                        <span className="text-success">
                            <CheckCircle />
                        </span>,
                    )
                } else {
                    if (formik.values.coupon_code === '') {
                        setEndAdornment('')
                    } else {
                        setEndAdornment(
                            <span className="text-danger">
                                <Cancel />
                            </span>,
                        )
                    }
                }
            }
        }
    }, [responseStatusPlugin])
    // if (
    //     global.storeConfigration?.shipping_need?.value == 0 ||
    //     global.storeConfigration?.shipping_need == undefined
    // ) {
    //     productListing.push({
    //         label: 'Shipping details',
    //         placeholder: 'Enter shipping details',
    //         type: 'ckeditor',
    //         class: 'col-12',
    //         name: 'custom_field_6',
    //         formik: formik,
    //     })
    // }
    productListing.push(
        {
            label: 'Start date *',
            placeholder: 'Enter Start date',
            type: 'datetime',
            disablePast: true,
            class: 'col-sm-6 col-12',
            name: 'start_date',
            formik: formik,
            // utc: true,
        },
        {
            label: 'End Date *',
            placeholder: 'Enter End Date',
            disablePast: true,
            type: 'datetime',
            class: 'col-sm-6 col-12',
            name: 'end_date',
            disabled: item?.market_status === 'closed' ? true : false,
            // minDate: dateTimeFormatFunction(formik.values.date_added),
            // minDateMessage: `Date should not be before minimal date ${dateTimeFormatFunction(
            //     formik.values.date_added,
            // )}`,
            formik: formik,
            // utc: true,
        },
        {
            label: 'Address*',
            placeholder: 'Enter Address',
            type: global?.storeDetails.theme === 7 ? 'text' : 'textarea',
            class: 'col-12',
            name: 'address',
            tooltiptitle: 'Address where buyers will pick up from',
            formik: formik,
        },
        {
            label: 'Country' + '*',
            placeholder: 'Select your country',
            class: 'col-12 col-sm-6',
            type: 'select',
            options: swapCountry(countryList).map((country) => {
                let busCategoryChanged = {}
                busCategoryChanged.show = country.name
                busCategoryChanged.value = country.location_id
                return busCategoryChanged
            }),
            name: 'country',
        },
        {
            label: `${formik.values.country == 38 ? 'Province' : 'State'} *`,
            placeholder: `Select your ${formik.values.country == 38 ? 'province' : 'state'}`,
            class: 'col-12 col-sm-6',
            type: 'select',
            options: stateList.map((buscat) => {
                let busCategoryChanged = {}
                busCategoryChanged.show = buscat.name
                busCategoryChanged.value = buscat.name
                return busCategoryChanged
            }),
            name: 'state',
        },
        {
            label: 'City *',
            type: 'text',
            placeholder: 'Enter your city',
            class: 'col-sm-6 col-12',
            name: 'city',
            formik: formik,
        },
        {
            label: `${formik.values.country == 38 ? 'Postal' : 'Zip'} code`,
            type: 'text',
            placeholder: `Enter your ${formik.values.country == 38 ? 'postal' : 'zip'}`,
            class: `col-12 col-sm-6 ${formik?.values?.country == 156 ? 'd-none' : ''}`,
            name: 'zipcode',
            formik: formik,
        },
    )
    if (
        global.storeConfigration?.disabled_phone_from_posting?.value == 0 ||
        global.storeConfigration?.disabled_phone_from_posting == undefined
    ) {
        productListing.push({
            label: 'Phone *',
            type: 'text',
            placeholder: 'Enter your phone number',
            class: 'col-sm-6 col-12',
            name: 'phone',
            formik: formik,
        })
    }

    if (
        (global.storeConfigration?.global_buyer_premium?.value > 0 ||
            global.storeConfigration?.global_buyer_premium == undefined) &&
        props.theme !== 'FoodIndustry'
    ) {
        if (
            (global.storeConfigration?.global_buyer_premium?.value == 0 &&
                props.theme == 'multiseller_two') ||
            props.theme != 'multiseller_two'
        ) {
            productListing.push({
                label: "Buyer's Premium (%) *",
                type: 'number',
                placeholder: "Enter buyer's premium (%)",
                class: 'col-sm-6 col-12',
                name: 'la_seller_id',
                formik: formik,
            })
        }
    } else {
        productListing.push({
            type: 'misc',
            class: 'col-sm-6 col-12',
        })
    }
    if (
        global.storeConfigration?.global_stagger_bidding?.value == 0 ||
        global.storeConfigration?.global_stagger_bidding == undefined
    ) {
        productListing.push(
            {
                label: 'Staggered Bidding',
                type: 'checkbox',
                class: 'col-6',
                name: 'ending_enable',
                formik: formik,
                tooltipTitle:
                    global?.storeDetails.theme === 3
                        ? 'Each item (or lot) closes sequentially until all items are closed.'
                        : 'All lots start bidding at the same time but close sequentially. Lots in the auction will close in mentioned time intervals. This allows you to watch each lot as it is set to close.',
            },
            {
                label: 'Items',
                placeholder: 'Enter items',
                type: 'number',
                class: 'col-md-3 col-sm-6 col-12',
                name: 'ending_items',
                disabled: formik.values.ending_enable ? false : true,
                formik: formik,
            },

            {
                label: 'Minutes',
                placeholder: 'Enter minutes',
                type: 'number',
                class: 'col-md-3 col-sm-6 col-12',
                name: 'ending_mints',
                disabled: formik.values.ending_enable ? false : true,
                formik: formik,
            },
        )
    }
    if (global?.storeDetails.theme === 3) {
        productListing.push({
            label: 'Enable BidIncrement',
            type: 'checkbox',
            class: 'col-6',
            name: 'enable_bid_increment',
            formik: formik,
        })
    }
    // {
    //     title: '',
    //     type: 'radio',
    //     noLabel: true,
    //     name: 'shipping',
    //     int: 1,
    //     class: 'col-12 mb-3',
    //     item: [
    //         { id: 0, description: 'Local Pickup' },
    //         { id: 1, description: 'Shipping' },
    //     ],
    //     formik: formik,
    // },
    var productListing1 = [
        {
            type: 'uploadDropZone',
            formik: formik,
            name: 'product_imagefile',
            titleText: 'Upload product images',
            innerText: (
                <div className="text-center">
                    <span className="material-icons">add_photo_alternate</span>
                    <br />
                    <p className="mb-2">
                        <b>
                            <span>Upload Preview Images *</span>
                        </b>
                    </p>
                    <span>Recommended dimensions: 500px * 500px</span>
                </div>
            ),
            class: 'col-12',
            folder: 'product',
            multiple: false,
            fileName: props.fileName,
            accept: 'image/*',
        },
        {
            type: 'previousImages',
            formik: formik,
            name: 'product_existingimage',
            class: 'col-12',
            folder: 'product',
        },
    ]

    useEffect(() => {
        if (formik.values.end_date && formik.values.start_date) {
            if (moment(formik.values.end_date).isSameOrBefore(formik.values.start_date)) {
                // formik.setErrors({
                //     start_date: 'Start date must be lower than end date!',
                //     end_date: 'End date must be higher than start date!',
                // })
                formik.values.end_date = null
                return setReload(!reload)
            }
            // formik.setErrors({
            //     start_date: '',
            //     end_date: '',
            // })
        }
    }, [formik.values.start_date, formik.values.end_date])

    useEffect(() => {
        if (global.storeConfigration?.prefill_address?.value == '1') {
            formik.setFieldValue('address', user?.address1 + ', ' + user?.address2)
            formik.setFieldValue('country', user?.country)
            formik.setFieldValue('state', user?.state)
            formik.setFieldValue('city', user?.city)
        }
    }, [user])

    useEffect(() => {
        const tick = setInterval(() => {
            setTimezone({
                local: moment().format('MM-DD-YYYY h:mm:ss A'),
                utc: moment().utc().format('MM-DD-YYYY h:mm:ss A'),
            })
        }, 1000)
        return () => {
            clearInterval(tick)
        }
    }, [])

    return (
        <>
            {loaders ? (
                <form className="pp-form">
                    <div>
                        {/* <div className="text-center m-3">
                        <strong className="text-danger">
                            Kindly select Start & End Date as per UTC Timezone
                        </strong>
                        <br />
                        <b>Local Time Now: {timezone.local}</b>
                        <br />
                        <b>UTC Time Now: {timezone.utc}</b>
                    </div> */}
                        {auction_id ? (
                            <div className="row">
                                {load ? mapData(productListing) : 'Loading...'}
                            </div>
                        ) : (
                            <div className="row">{mapData(productListing)}</div>
                        )}
                        {formik.values.enable_bid_increment ? (
                            <BidIncrement formik={formik} bidincarr1={bidincarr}></BidIncrement>
                        ) : (
                            ''
                        )}
                        {auction_id ? (
                            <div className="row">{load ? mapData(productListing1) : ''}</div>
                        ) : (
                            <div className="row">{mapData(productListing1)}</div>
                        )}
                        <div className="row">
                            {/* <div className="col-md-6">
                            <PrimaryButton
                                type="button"
                                label={loading ? 'Loading...' : 'Save As Draft & Move Next'}
                                disabled={loading}
                                onClick={() => {
                                    formik.setFieldValue('status', 'draft')
                                    formik.handleSubmit()
                                }}
                            />
                        </div> */}
                            {load ? (
                                <div className="col-md-6">
                                    <PrimaryButton
                                        type="button"
                                        label={
                                            loading
                                                ? 'Loading...'
                                                : `${
                                                      auction_id
                                                          ? global?.storeDetails?.theme === 3
                                                              ? 'Finish & Add Lot'
                                                              : editType === 'repost'
                                                              ? 'Save Draft For Preview'
                                                              : 'Edit & Add Lot'
                                                          : 'Save & Add Lot'
                                                  }`
                                        }
                                        disabled={loading}
                                        onClick={() => {
                                            formik.setFieldValue('status', 'open')
                                            formik.values.repost == 1
                                                ? formik.setFieldValue('repost_status', 'draft')
                                                : ''
                                            formik.handleSubmit()
                                        }}
                                    />
                                </div>
                            ) : (
                                ''
                            )}

                            {load &&
                            props.theme == 'multiseller_two' &&
                            editType !== 'repost' &&
                            auction_id ? (
                                <div className="col-md-6">
                                    <PrimaryButton
                                        type="button"
                                        label={
                                            loading
                                                ? 'Loading...'
                                                : `${
                                                      auction_id
                                                          ? global?.storeDetails?.theme === 3
                                                              ? 'Finish'
                                                              : 'Edit'
                                                          : 'Edit'
                                                  }`
                                        }
                                        disabled={loading}
                                        onClick={() => {
                                            setCompleteEditing(true)
                                            formik.setFieldValue('status', 'open')
                                            formik.handleSubmit()
                                        }}
                                    />
                                </div>
                            ) : (
                                ''
                            )}
                            {load && editType == 'repost' ? (
                                <div className="col-md-6">
                                    <PrimaryButton
                                        type="button"
                                        label={loading ? 'Loading...' : `${`Publish For Live`}`}
                                        disabled={loading}
                                        onClick={() => {
                                            formik.setFieldValue('status', 'open')
                                            formik.values.repost == 1
                                                ? formik.setFieldValue('repost_status', 'open')
                                                : ''
                                            formik.handleSubmit()
                                        }}
                                    />
                                </div>
                            ) : (
                                ''
                            )}
                        </div>
                    </div>
                </form>
            ) : (
                ''
            )}
            {/* / <CustomDialog title={'Confirm Start Date & End Date'} open={open} function={toggleOpen}>
                <p>Here is the reference for your Local & UTC start date and end date</p>
                <h5>Local Time (Actual Start And End Date)</h5>
                <p>
                    Start Date - {moment(formik.values.start_date).format('MM-DD-YYYY h:mm:ss A')} |
                    End Date - {moment(formik.values.end_date).format('MM-DD-YYYY h:mm:ss A')}
                </p>
                <h5>UTC Time</h5>
                <p>
                    Start Date -{' '}
                    {moment(formik.values.start_date).utc().format('MM-DD-YYYY h:mm:ss A')} | End
                    Date - {moment(formik.values.end_date).utc().format('MM-DD-YYYY h:mm:ss A')}
                </p>

                <div className="actionWrapper">
                    <Button onClick={() => setOpen(false)} disabled={loading}>
                        {'Cancel'}
                    </Button>
                    <PrimaryButton
                        onClick={postAnAuction}
                        type="button"
                        disabled={loading}
                        label={loading ? 'Loading...' : 'Confirm'}
                    />
                </div>
            </CustomDialog>  */}{' '}
        </>
    )
}

export default Step1
