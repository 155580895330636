import React, { useState, useEffect, useContext } from 'react'
import Addnewcard from './Addnewcard'
import PaymentCards from './paymentCards'
import _, { isEmpty } from 'lodash'
import './cards.css'
import authContext from '../../../Product/context/auth/authContext'
import customerContext from '../../../Product/context/stripe/customer/customerContext'
const Cards = (props) => {
    const [AllCard, setAllCard] = useState([])
    const [selected, setSelected] = useState([])
    const [pay_index, setPayindex] = useState(0)
    const [bankverifypopup, setBankVerifyPopup] = useState(false)
    const { user } = useContext(authContext)
    const { getBankDetails, responseStatus } = useContext(customerContext)

    useEffect(() => {
        if (user) {
            getBankDetails({
                site_id: global?.storeDetails?.site_id,
                email: user.email,
                user_id: user.id,
            })
        }
    }, [user])

    useEffect(() => {
        if (responseStatus) {
            if (responseStatus.from === 'get_bank_buyer') {
                if (responseStatus.status === 'success') {
                    setAllCard(responseStatus.data?.data)
                } else {
                    setAllCard([])
                }
            }
        }
    }, [responseStatus])

    const updateCards = () => {
        getBankDetails({
            site_id: global?.storeDetails?.site_id,
            email: user.email,
            user_id: user.id,
        })
    }

    const makeVerify = async (index) => {
        setPayindex(index)
        setBankVerifyPopup(true)
    }

    const update_card_new = () => {
        AllCard[parseInt(pay_index)].status = 'verified'
        setAllCard([...AllCard])
    }

    return (
        <div>
            <div className={`cardContainer ${props.from == 'checkout' ? 'adBankWrpr' : ''}`}>
                {props.from == 'checkout' ? (
                    <>
                        <div className="card-1-Container bankPaymentWrpr">
                            <Addnewcard
                                upd={updateCards}
                                bankverifypopup={bankverifypopup}
                                setBankVerifyPopup={setBankVerifyPopup}
                                pay_index={pay_index}
                                setAllCard={setAllCard}
                                AllCard={AllCard}
                                update_card={update_card_new}
                            />
                        </div>
                        {!isEmpty(AllCard) &&
                            AllCard.map((data, index) => (
                                <div className="card-2-Container bankDetailsContainer">
                                    <PaymentCards
                                        data={data}
                                        // selectedcard={selected}
                                        //setAllCard={setAllCard}
                                        del={() => {
                                            makeVerify(index)
                                        }}
                                        cc={(id) => props.cc_id(id)}
                                        value={props.selectedbank}
                                        from="checkout"
                                    />
                                </div>
                            ))}
                    </>
                ) : (
                    <>
                        <div className="card-1-Container">
                            <Addnewcard
                                upd={updateCards}
                                bankverifypopup={bankverifypopup}
                                setBankVerifyPopup={setBankVerifyPopup}
                                pay_index={pay_index}
                                setAllCard={setAllCard}
                                AllCard={AllCard}
                                update_card={update_card_new}
                            />
                        </div>
                        {!isEmpty(AllCard) &&
                            AllCard.map((data, index) => (
                                <div className="card-2-Container">
                                    <PaymentCards
                                        data={data}
                                        // selectedcard={selected}
                                        //setAllCard={setAllCard}
                                        del={() => {
                                            makeVerify(index)
                                        }}
                                    />
                                </div>
                            ))}
                    </>
                )}
            </div>
        </div>
    )
}

export default Cards
