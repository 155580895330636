import React from 'react'
import Layout from '../Layout'
import { Link } from '@material-ui/core'

export default function inventoryReduction(props) {
    const baseStyle = {
        fontWeight: 700,
        marginBottom: '12px',
    }

    const h1Style = {
        ...baseStyle,
        // Additional or overriding styles for h2 can go here if needed
    }

    const h4Style = {
        ...baseStyle,
        // Additional or overriding styles for h4 can go here if needed
    }

    const pStyle = {
        fontWeight: 400,
        marginBottom: '24px',
    }
    return (
        <Layout props={props}>
            <div className="px-5 py-4 stctPgWrpr">
                <h1 style={h1Style}>Inventory Reduction</h1>
                <p style={pStyle}>
                    While many business owners believe that having too much inventory is better than
                    having too little, this isn’t necessarily true. Businesses with too much
                    inventory must pay to store, maintain, and protect these items. This can add up
                    to a lot of money over time. In addition to increased warehousing costs, having
                    too much overstock means that capital is tied up. This reduces the business
                    owner’s ability to maintain the business or acquire the things needed to succeed
                    and excel.
                </p>
                <p style={pStyle}>
                    Overstock inventory costs businesses around the U.S. billions of dollars each
                    year. Although storing overstock hurts large corporations, it can have a
                    significant impact on small businesses. Even having slightly more overstock than
                    necessary can cut into a business’s bottom line.
                </p>
                <p style={pStyle}>
                    While it’s important that retail businesses keep a small amount of overstock,
                    having too much makes it difficult to properly manage inventory. Reducing
                    inventory allows business owners to better understand consumer demand, while
                    reducing warehouse costs and freeing up their resources.
                </p>
                <h4 style={h4Style}>Reducing Overstock, Excess, Outdated, and Surplus Inventory</h4>
                <p style={pStyle}>
                    Business owners often struggle to figure out how to get rid of excess inventory
                    without losing out on potential profits. Selling overstock inventory through
                    online auctions allows businesses to collect top dollar for their items. As
                    bidders compete online, they effectively drive prices up. This helps business
                    owners earn significant income while reducing the amount of inventory they need
                    to store.
                </p>
                <h4 style={h4Style}>
                    An Increasing Number of Businesses Sell Overstock Inventory Through Our Online
                    Auctions
                </h4>
                <p style={pStyle}>
                    Lots of Auctions helps businesses turn overstock, excess, and outdated inventory
                    into profit. Our online auctions provide an excellent solution for businesses
                    looking to sell a lot of inventory fast. We use targeted marketing to ensure the
                    right people reach our auctions. Because our auctions take place entirely
                    online, bidders can participate at any time, from any location. We offer
                    convenient pick-up in Texas.
                </p>
                <p style={pStyle}>
                    Lots of Auctions helps businesses liquidate overstock, outdated, and surplus
                    inventory. Applying to sell on our user-friendly platform is fast and easy. Our
                    inventory liquidation specialists work directly with each of our clients to
                    ensure a smooth and pain-free experience. Please{' '}
                    <Link path="/ContactUs">contact</Link> us to discuss our inventory reduction
                    options.
                </p>
            </div>
        </Layout>
    )
}
