import openSocket from 'socket.io-client'
import openSocketAuction from 'socket.io-client-latest'

let socket = openSocketAuction(`${process.env.REACT_APP_SOCKET_URL}`, {
    reconnection: true,
    reconnectionDelay: 500,
    reconnectionAttempts: 10,
    transports: ['websocket', 'polling'],
})
let socketAuction = openSocketAuction(`${process.env.REACT_APP_AUCTION_SOCKET_URL}/`, {
    reconnection: true,
    reconnectionDelay: 500,
    reconnectionAttempts: 10,
    transports: ['websocket', 'polling'],
})
let socketSeller = openSocketAuction(`${process.env.REACT_APP_SELLER_SOCKET_URL}/`, {
    reconnection: true,
    reconnectionDelay: 500,
    reconnectionAttempts: 10,
    transports: ['websocket', 'polling'],
})
console.log('socketAuction', `${process.env.REACT_APP_AUCTION_SOCKET_URL}/`, socketAuction)
socketAuction.on('connect', function () {
    console.log('Auction Socket Connected')
})

console.log('socket', socket)
socket.on('connect', function () {
    console.log('Socket Connected', socket.id)
})
socketSeller.on('connect', function () {
    console.log('Socket Seller Connected', socket.id)
})
socketAuction.on('disconnect', (reason) => {
    console.log('Auction Socket Disconnected', reason)
})

socket.on('disconnect', (reason) => {
    console.log('Socket Disconnected', reason)
    socket.emit('userWatch', 'userDisconnected')
})
socketSeller.on('disconnect', (reason) => {
    console.log('Socket Disconnected', reason)
    socket.emit('userWatch', 'userDisconnected')
})

function reInitializeSocket() {
    socket.close()
    socket = openSocketAuction(`${process.env.REACT_APP_SOCKET_URL}/`)
}

export { socket, socketAuction, socketSeller, reInitializeSocket }
