import React, { useState, useEffect, useContext } from 'react'
import { connect } from 'react-redux'
import './checkout.css'
import CartContext from '../../Product/context/cart/cartContext'
import AuthContext from '../../Product/context/auth/authContext'
import PluginContext from '../../Product/context/plugin/pluginContext'
import AlertContext from '../../Product/context/alert/alertContext'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import 'react-lazy-load-image-component/src/effects/blur.css'
import { currencyFormat } from '../../Product/common/components'
import RadioBox from '../../Product/components/atoms/RadioBox'
import CheckBox from '../../Product/components/atoms/CheckBox'
import CurrencyConverterFormat from '../../utils/CommonFunctionality/CurrencyConverter/CurrencyConverterFormat'
import { useTranslation } from 'react-i18next'
import productContext from '../../Product/context/product/productContext'
import { Link } from 'react-router-dom'

const CheckoutCardCompound = ({
    items,
    currency,
    setItems,
    type,
    calculatorLoading,
    storeTheme,
}) => {
    const cartContext = useContext(CartContext)
    const authContext = useContext(AuthContext)
    const pluginContext = useContext(PluginContext)
    const alertContext = useContext(AlertContext)
    const { setAlert } = alertContext
    const { t } = useTranslation()
    const { addToCart, buyer_cartitems, getUserCart, removeFromCart, responseStatus } = cartContext
    const {
        responseStatus: responseStatusPlugin,
        clearResponse,
        requestShipping,
        requestdiscount,
    } = pluginContext
    const { user } = authContext
    const { similar_products_qty, getSimilarProductsQty } = useContext(productContext)
    const [requestLoading, setRequestLoading] = useState(false)
    const [requestId, setRequestId] = useState('')
    const [viewCartItems, setViewCartItems] = useState(items)
    const [changeShipping, setChangeShipping] = useState(false)
    const [similarQty, setSimilarQty] = useState([])
    const [initial, setInitial] = useState(true)
    // useEffect(() => {
    //     if (buyer_cartitems.results) {
    //         setViewCartItems(buyer_cartitems.results)
    //     }
    // }, [buyer_cartitems.results])
    useEffect(() => {
        if (items.length && initial) {
            let temp_arr = []
            items.map((val) => {
                if (val.sallertype) {
                    temp_arr.push(val.sallertype)
                }
            })
            if (temp_arr.length) getSimilarProductsQty({ id: temp_arr })
            setInitial(true)
        }
    }, [items])
    useEffect(() => {
        if (similarQty?.length && initial) {
            let temp_pro = items
            temp_pro.map((val) => {
                similarQty?.map((qty) => {
                    if (val.sallertype == qty.id) {
                        val.contact_seller = (
                            <Link to="/contact_us" target="_blank" rel="noopener noreferrer">
                                {qty.available_qty} Qty available. If you are still intrested,
                                Kindly contact seller.
                            </Link>
                        )
                    }
                })
            })
            setItems([...temp_pro])
            console.log(temp_pro, 'temp_pro')
            setInitial(false)
        }
    }, [similarQty, items])
    useEffect(() => {
        if (items?.length) setSimilarQty(similar_products_qty)
    }, [similar_products_qty, items])
    useEffect(() => {
        if (responseStatusPlugin) {
            if (responseStatusPlugin.from === 'requestShipping') {
                setRequestLoading(false)
                if (responseStatusPlugin.status === 'success') {
                    setAlert('Shipping fee requested successfully!', 'success')
                    if (requestId) {
                        document.getElementById(requestId).value = ''
                        document.getElementById(`${requestId}_message`).value = ''
                    }
                }
                clearResponse()
            }
            if (responseStatusPlugin.from === 'requestDiscount') {
                setRequestLoading(false)
                if (responseStatusPlugin.status === 'success') {
                    setAlert('Code Valid!', 'success')
                    if (requestId) {
                        var prdt = items
                        var req_data = responseStatusPlugin.message

                        prdt.map((val) => {
                            if (parseInt(val.product_id) == parseInt(requestId)) {
                                if (req_data.discountType == 'percentage') {
                                    if (
                                        parseInt(val.user_id) ==
                                            parseInt(
                                                global?.storeDetails?.user_id
                                                    ? global.storeDetails.user_id
                                                    : 0,
                                            ) ||
                                        parseInt(val.sell_id) ==
                                            parseInt(
                                                global?.storeDetails?.user_id
                                                    ? global.storeDetails.user_id
                                                    : 0,
                                            )
                                    ) {
                                        var discount_amount = parseFloat(
                                            parseFloat(
                                                val.amount * parseFloat(req_data.amount / 100),
                                            ),
                                        ).toFixed(2)
                                    } else {
                                        if (parseFloat(val.custom_seller_commission) > 0) {
                                            val.custom_seller_commission =
                                                val.custom_seller_commission
                                        } else {
                                            val.custom_seller_commission = global?.storeConfigration
                                                ?.commission_fee?.value
                                                ? global.storeConfigration.commission_fee.value
                                                : 0
                                        }

                                        var commision_fee_amount = parseFloat(
                                            parseFloat(
                                                val.amount *
                                                    parseFloat(val.custom_seller_commission / 100),
                                            ),
                                        ).toFixed(2)
                                        var discount_amount = parseFloat(
                                            parseFloat(
                                                commision_fee_amount *
                                                    parseFloat(req_data.amount / 100),
                                            ),
                                        ).toFixed(2)
                                    }
                                } else if (req_data.discountType == 'fixedamount') {
                                    var discount_amount = req_data.amount
                                }
                                val.total_payable_amount_without_discount = val.total_payable_amount
                                val.local_pick_total_payable_amount_without_discount =
                                    val.local_pick_total_payable_amount
                                val.total_payable_amount =
                                    parseFloat(val.total_payable_amount) -
                                    parseFloat(discount_amount)
                                val.local_pick_total_payable_amount =
                                    parseFloat(val.local_pick_total_payable_amount) -
                                    parseFloat(discount_amount)
                                val.discount_amount = discount_amount
                                val.discount_type = req_data.discountType
                                val.discount_id = req_data.discount_id
                                document.getElementById(`${requestId}_cpcn`).disabled = true
                                document.getElementById(`${requestId}_cpcn`).style.backgroundColor =
                                    'rgba(65, 206, 65, 1)'
                            }
                        })
                        setItems([...prdt])
                    }
                }
                if (responseStatusPlugin.status === 'error') {
                    setAlert(
                        responseStatusPlugin.message
                            ? responseStatusPlugin.message
                            : 'Invalid Code!',
                        'error',
                    )
                }
                clearResponse()
            }
        }
    }, [responseStatusPlugin])
    const handleSubmit = async (e, val) => {
        e.preventDefault()
        setRequestId(`${val.id}`)
        const body = {
            site_id: global?.storeDetails?.site_id,
            from_id: user?.id,
            from_email: user?.email,
            customer_name: user?.first_name + ' ' + user?.last_name,
            to_id: val.user_id,
            lotid: val.product_id,
            auctionid: val.auctionid,
            invoiceid: val.common_invoice,
            shipping_fee: document.getElementById(`${val.id}`).value,
            message: document.getElementById(`${val.id}_message`).value,
        }
        setRequestLoading(true)
        requestShipping(body)
    }

    const aplaycouponcode = async (e, val) => {
        e.preventDefault()
        setRequestId(`${val.product_id}`)
        var input_value = document.getElementById(`${val.product_id}_cpcn`).value
        if (input_value != '') {
            const body = {
                inputcpn: input_value,
                storeId: global?.storeDetails?.id,
                pid: val.product_id,
                amount: val.bprice ? val.bprice : val.amount,
            }
            setRequestLoading(true)
            requestdiscount(body)
        } else {
            setAlert('Invalid Code!', 'error')
        }
    }

    const applay_change_transfer = (e, index) => {
        var item = items,
            transfer_loc_fee = parseFloat(global.storeConfigration?.transfer_location_fee?.value)
        item[index].transfer_fee_location = e.target.checked ? transfer_loc_fee : 0
        item[index].total_payable_amount = e.target.checked
            ? parseFloat(item[index].total_payable_amount) + parseFloat(transfer_loc_fee)
            : parseFloat(item[index].total_payable_amount) - parseFloat(transfer_loc_fee)
        item[index].local_pick_total_payable_amount = e.target.checked
            ? parseFloat(item[index].local_pick_total_payable_amount) + parseFloat(transfer_loc_fee)
            : parseFloat(item[index].local_pick_total_payable_amount) - parseFloat(transfer_loc_fee)
        setItems([...item])
    }

    return (
        <>
            {items && items.length ? (
                <>
                    {items.map((item, index) => {
                        return (
                            <div
                                className="media"
                                key={`co-${index}`}
                                style={{ marginTop: '20px' }}
                            >
                                <div className="media-left">
                                    <div className="grid-img-wrap">
                                        <LazyLoadImage
                                            effect="blur"
                                            placeholderSrc="/assets/svg/imageLoading.svg"
                                            height="100%"
                                            width="100%"
                                            src={`${
                                                item.content_head1 == '0' || item.store_id === 0
                                                    ? process.env.REACT_APP_BASE_URL +
                                                      'uploads/product/'
                                                    : process.env.REACT_APP_PRODUCT_IMAGE_URL
                                            }${item.is_avatar || item.avatar}`}
                                            onError={(e) =>
                                                (e.target.src = `${global?.storeDetails?.logoValue}`)
                                            }
                                            alt={item.title}
                                            className="co-img"
                                        />
                                    </div>
                                </div>
                                <div className="media-body co-box">
                                    {global?.storeConfigration?.lot_sequence_order?.value == 1 ? (
                                        <div className="cob-top">
                                            <div className="co-label">{'Lot #'}</div>
                                            <div className="co-text">
                                                {item.lot_number ? item.lot_number : '-'}
                                            </div>
                                        </div>
                                    ) : (
                                        ''
                                    )}

                                    <div className="cob-top">
                                        <div className="co-label">{t('title')}</div>
                                        <div className="co-text">{item.title}</div>
                                    </div>

                                    {global?.storeConfigration?.buy_now_with_qty?.value == 1 &&
                                    type === 'buynow' ? (
                                        <div className="cob-top">
                                            <div className="co-label">{t('Qty')}</div>
                                            <div className="co-text">{item.qty}</div>
                                        </div>
                                    ) : (
                                        ''
                                    )}

                                    <div className="cob-top">
                                        <div className="co-label">{t('amount')}</div>
                                        <div className="co-text">
                                            {currencyFormat(
                                                parseFloat(item.bprice ? item.bprice : item.amount),
                                                currency,
                                            )}
                                        </div>
                                    </div>

                                    {item.shipping_price != 0 &&
                                    global.storeDetails?.theme == 14 ? (
                                        <div className="cob-top">
                                            <div className="co-label">Shipping Price</div>
                                            <div className="co-text">
                                                {currencyFormat(
                                                    parseFloat(item.shipping_price),
                                                    currency,
                                                )}
                                            </div>
                                        </div>
                                    ) : null}

                                    {item.buyers_premium_percentage != 0 ? (
                                        <div className="cob-top">
                                            <div className="co-label">{`${t('buyer_premium')} (${
                                                item.buyers_premium_percentage +
                                                item.charge_percentage
                                            } %)`}</div>
                                            <div className="co-text">
                                                {currencyFormat(
                                                    parseFloat(
                                                        item.buyers_premium + item.charge_fee,
                                                    ),
                                                    currency,
                                                )}
                                            </div>
                                        </div>
                                    ) : null}

                                    <div className="cob-top">
                                        {item.sales_tax_per_state ? (
                                            <div className="co-label">{`Sales Tax + Local Tax (${item.sales_tax_per_state} % + ${item.local_sales_tax_per_state} %)`}</div>
                                        ) : global.pluginConfiguration?.avalara?.enable ? (
                                            <div className="co-label">{`${t('sales_tax')}`}</div>
                                        ) : (
                                            <div className="co-label">{`${t('sales_tax')} (${
                                                item.sales_tax_percentage
                                            } %)`}</div>
                                        )}
                                        <div className="co-text">
                                            {currencyFormat(
                                                parseFloat(
                                                    item.shipping_fee !== 0 && item.hasshipping == 1
                                                        ? item.sales_tax
                                                        : item.local_pick_sales_tax,
                                                ),
                                                currency,
                                            )}
                                        </div>
                                    </div>

                                    {parseFloat(item.transfer_fee_location) > 0 ? (
                                        <div className="cob-top">
                                            <div className="co-label">Transfer Fee</div>
                                            <div className="co-text">
                                                {currencyFormat(
                                                    parseFloat(item.transfer_fee_location),
                                                    currency,
                                                )}
                                            </div>
                                        </div>
                                    ) : null}
                                    {parseFloat(item.assurance_valu) > 0 ? (
                                        <div className="cob-top">
                                            <div className="co-label">Buyer Protection Value</div>
                                            <div className="co-text">
                                                {currencyFormat(
                                                    parseFloat(item.assurance_valu),
                                                    currency,
                                                )}
                                            </div>
                                        </div>
                                    ) : null}

                                    {item.transaction_fee != 0 &&
                                    global.storeDetails.site_id !== 'AS0658' &&
                                    global.storeDetails.site_id !== 'AS0766' ? (
                                        <div className="cob-top">
                                            <div className="co-label nowrap">{`${t(
                                                'transaction_fee',
                                            )} (${item.transaction_label})`}</div>
                                            <div className="co-text">
                                                {currencyFormat(
                                                    parseFloat(item.transaction_fee),
                                                    currency,
                                                )}
                                            </div>
                                        </div>
                                    ) : null}
                                    {item.line_item_fee ? (
                                        <div className="cob-top">
                                            <div className="co-label">{`${t(
                                                'line_item_fee',
                                            )}`}</div>
                                            <div className="co-text">
                                                {currencyFormat(
                                                    parseFloat(item.line_item_fee),
                                                    currency,
                                                )}
                                            </div>
                                        </div>
                                    ) : null}
                                    {item.shipping_fee !== 0 &&
                                    item.hasshipping == 1 &&
                                    item.inside_vancouver !== 'no' ? (
                                        <>
                                            <div className="cob-top">
                                                <div className="co-label">{`${t('shipping_fee')} ${
                                                    item.shipping_type == 1
                                                        ? `(${item.shipping_percentage} %)`
                                                        : ''
                                                }`}</div>
                                                <div className="co-text d-flex align-items-center justify-content-start">
                                                    {currencyFormat(
                                                        parseFloat(item.shipping_fee),
                                                        currency,
                                                    )}
                                                    {item.custom_field_4 == 1 &&
                                                    type === 'auction' &&
                                                    global.pluginConfiguration?.shipping?.enable ? (
                                                        <>
                                                            {changeShipping === `chg_${index}` ? (
                                                                <p
                                                                    className="ml-auto mb-0 colorPrimary cursorDecoy"
                                                                    onClick={() =>
                                                                        setChangeShipping(false)
                                                                    }
                                                                >
                                                                    <span className="material-icons">
                                                                        <small>clear</small>
                                                                    </span>
                                                                </p>
                                                            ) : (
                                                                <p
                                                                    className="ml-3 mb-0 colorPrimary cursorDecoy"
                                                                    onClick={() =>
                                                                        setChangeShipping(
                                                                            `chg_${index}`,
                                                                        )
                                                                    }
                                                                >
                                                                    <small>
                                                                        Change Shipping Fee
                                                                    </small>
                                                                </p>
                                                            )}
                                                        </>
                                                    ) : null}
                                                </div>
                                            </div>
                                            {item.custom_field_4 == 1 &&
                                            type === 'auction' &&
                                            global.pluginConfiguration?.shipping?.enable ? (
                                                <>
                                                    {changeShipping === `chg_${index}` ? (
                                                        <div className="cob-top">
                                                            <>
                                                                <form
                                                                    onSubmit={(e) =>
                                                                        handleSubmit(e, item)
                                                                    }
                                                                >
                                                                    <div className="input-group chkShpngMsg">
                                                                        <input
                                                                            id={`${item.id}_message`}
                                                                            type="text"
                                                                            placeholder="Type your message here..."
                                                                            className="form-control w-100 mb-2"
                                                                        />
                                                                        <input
                                                                            id={`${item.id}`}
                                                                            type="number"
                                                                            min="1"
                                                                            placeholder="Request shipping fee"
                                                                            className="form-control mr-2"
                                                                        />
                                                                        <div className="input-group-btn">
                                                                            <button
                                                                                type="submit"
                                                                                className="btn pink-btn"
                                                                                disabled={
                                                                                    requestLoading
                                                                                }
                                                                            >
                                                                                {requestLoading
                                                                                    ? 'Sending...'
                                                                                    : 'Send'}
                                                                            </button>
                                                                        </div>
                                                                    </div>
                                                                </form>
                                                            </>
                                                        </div>
                                                    ) : (
                                                        ''
                                                    )}
                                                </>
                                            ) : null}
                                        </>
                                    ) : null}

                                    {parseFloat(item?.discount_amount ? item.discount_amount : 0) >
                                        0 && global.pluginConfiguration?.coupon_code?.enable ? (
                                        <>
                                            <div className="co-label">{t('sub_total')}</div>
                                            <div className="co-text">
                                                {currencyFormat(
                                                    parseFloat(
                                                        item.shipping_fee !== 0 &&
                                                            item.hasshipping == 1
                                                            ? item.total_payable_amount
                                                            : item.local_pick_total_payable_amount_without_discount,
                                                    ),
                                                    currency,
                                                )}
                                            </div>
                                            <div className="co-label">{t('discount_amount')}</div>
                                            <div className="co-text">
                                                {'-' +
                                                    currencyFormat(
                                                        parseFloat(item.discount_amount),
                                                        currency,
                                                    )}
                                            </div>
                                            <div className="co-label">{t('total')}</div>
                                            <div className="co-text">
                                                {currencyFormat(
                                                    parseFloat(
                                                        item.shipping_fee !== 0 &&
                                                            item.hasshipping == 1
                                                            ? item.total_payable_amount
                                                            : item.local_pick_total_payable_amount,
                                                    ),
                                                    currency,
                                                )}
                                                <CurrencyConverterFormat
                                                    amount={
                                                        item.shipping_fee !== 0 &&
                                                        item.hasshipping == 1
                                                            ? item.total_payable_amount
                                                            : item.local_pick_total_payable_amount
                                                    }
                                                />
                                            </div>
                                        </>
                                    ) : (
                                        <>
                                            <div className="co-label">{t('total')}</div>
                                            <div className="co-text">
                                                {currencyFormat(
                                                    parseFloat(
                                                        item.shipping_fee !== 0 &&
                                                            item.hasshipping == 1
                                                            ? item.total_payable_amount
                                                            : item.local_pick_total_payable_amount,
                                                    ),
                                                    currency,
                                                )}
                                                <CurrencyConverterFormat
                                                    amount={
                                                        item.shipping_fee !== 0 &&
                                                        item.hasshipping == 1
                                                            ? item.total_payable_amount
                                                            : item.local_pick_total_payable_amount
                                                    }
                                                />
                                            </div>
                                        </>
                                    )}
                                    {item.transfer_item_enable && item.shipping_fee == 0 ? (
                                        <div className="cob-top">
                                            <CheckBox
                                                checked={item.transfer_fee_location ? true : false}
                                                name="lotId"
                                                label={`Transfer Item From ${item.auction_city} To ${global.storeConfigration?.transfer_city_location?.value}`}
                                                onChange={(e) => applay_change_transfer(e, index)}
                                            />
                                        </div>
                                    ) : (
                                        ''
                                    )}
                                    {global.storeConfigration?.transfer_item_location?.value == 1 &&
                                    item.transferable?.toLowerCase() == 'yes' &&
                                    item.localpickup ? (
                                        <div className="cob-top">
                                            <CheckBox
                                                checked={item.transfer_fee_location ? true : false}
                                                name="lotId"
                                                label={`Transfer This Item`}
                                                onChange={(e) => applay_change_transfer(e, index)}
                                            />
                                        </div>
                                    ) : null}
                                    {(item.hasshipping == 1 || item.temp_hasshipping == 0) &&
                                    item.localpickup == 1 ? (
                                        <div className="cob-top">
                                            {calculatorLoading ? (
                                                'Calculating shipping fee...'
                                            ) : (
                                                <RadioBox
                                                    title=""
                                                    name={`shipping${index}`}
                                                    items={[
                                                        { id: 0, description: 'Local Pickup' },
                                                        { id: 1, description: 'Shipping' },
                                                    ]}
                                                    value={
                                                        item.temp_hasshipping || item.hasshipping
                                                    }
                                                    onChange={(e) => {
                                                        var temp = items
                                                        temp[index].temp_hasshipping =
                                                            e.target.value
                                                        if (e.target.value == 1) {
                                                            temp[index].hasshipping = 1
                                                        } else {
                                                            temp[index].hasshipping = 0
                                                        }
                                                        if (
                                                            e.target.value == 1 &&
                                                            item.transfer_item_enable
                                                        ) {
                                                            applay_change_transfer(
                                                                { target: { checked: false } },
                                                                index,
                                                            )
                                                        }
                                                        if (
                                                            e.target.value == 0 &&
                                                            item.transfer_item_enable
                                                        ) {
                                                            applay_change_transfer(
                                                                { target: { checked: true } },
                                                                index,
                                                            )
                                                        }
                                                        if (
                                                            temp[index].shipping_fee != 0 &&
                                                            temp[index].inside_vancouver !== 'no'
                                                        ) {
                                                            temp[index].temp_shipping_fee =
                                                                temp[index].shipping_fee
                                                            temp[index].total_payable_amount =
                                                                temp[index].total_payable_amount -
                                                                temp[index].temp_shipping_fee
                                                        } else {
                                                            if (
                                                                temp[index].inside_vancouver !==
                                                                'no'
                                                            )
                                                                temp[index].total_payable_amount =
                                                                    temp[index]
                                                                        .total_payable_amount +
                                                                    temp[index].temp_shipping_fee
                                                        }
                                                        if (
                                                            e.target.value == 1 &&
                                                            temp[index].inside_vancouver === 'no'
                                                        ) {
                                                        } else {
                                                            temp[index].shipping_fee =
                                                                e.target.value == 1
                                                                    ? temp[index].temp_shipping_fee
                                                                    : 0
                                                        }
                                                        setItems([...temp])
                                                    }}
                                                    int={1}
                                                />
                                            )}
                                        </div>
                                    ) : null}
                                    {item.temp_hasshipping == 0 &&
                                    item.localpickup == 1 &&
                                    global.storeConfigration?.checkout_local_pickup_message
                                        ?.value ? (
                                        <>
                                            <p className="text-danger lclPckupLctn">
                                                <small>({item.city})</small>
                                            </p>
                                            <p>
                                                {
                                                    global.storeConfigration
                                                        ?.checkout_local_pickup_message?.value
                                                }
                                            </p>
                                        </>
                                    ) : null}
                                    {item.hasshipping == 0 &&
                                    item.localpickup == 1 &&
                                    global.storeConfigration?.checkout_local_pickup_message
                                        ?.value ? (
                                        <>
                                            <p className="text-danger lclPckupLctn">
                                                <small>({item.city})</small>
                                            </p>
                                            <p>
                                                {
                                                    global.storeConfigration
                                                        ?.checkout_local_pickup_message?.value
                                                }
                                            </p>
                                        </>
                                    ) : null}

                                    {global.pluginConfiguration?.coupon_code?.enable ? (
                                        <form onSubmit={(e) => aplaycouponcode(e, item)}>
                                            <div className="input-group chkShpngMsg">
                                                <input
                                                    id={`${item.product_id}_cpcn`}
                                                    type="text"
                                                    min="1"
                                                    placeholder={t('enter_coupon_code')}
                                                    className="form-control mr-2"
                                                />
                                                <div className="input-group-btn">
                                                    <button
                                                        id={`${item.product_id}_cpcn_btn`}
                                                        type="submit"
                                                        className="btn pink-btn"
                                                        disabled={
                                                            parseInt(
                                                                item.discount_amount
                                                                    ? item.discount_amount
                                                                    : 0,
                                                            ) > 0 || requestLoading
                                                                ? true
                                                                : false
                                                        }
                                                    >
                                                        {requestLoading
                                                            ? 'Loading...'
                                                            : t('apply_code')}
                                                    </button>
                                                </div>
                                            </div>
                                        </form>
                                    ) : (
                                        ''
                                    )}
                                    {item.contact_seller && (
                                        <div className="cob-top">{item.contact_seller}</div>
                                    )}
                                </div>
                            </div>
                        )
                    })}
                </>
            ) : null}
        </>
    )
}

const mapStateToProps = (state) => {
    return {
        checkoutItems: state.CartReducer.getCartData || [],
    }
}

const CheckoutCard = connect(mapStateToProps, null)(CheckoutCardCompound)

export default CheckoutCard
