import React from 'react'
import Layout from '../Layout'
import PostProductComponent from '../../../utils/CommonFunctionality/PostProduct'
const PostBuynow = (props) => {
    return (
        <>
            <Layout props={props}>
                <div className="postProductContainer">
                    <h2 className="ppTitle">Post a Product</h2>
                    <PostProductComponent
                        withoutAuction={1}
                        addressSection={1}
                        imageValidation={1}
                        buynow={1}
                        removeAuctionSection={1}
                        redirectRoute="dashboard/buynow"
                        pickupDetails={1}
                        theme={'multiseller_two'}
                        local_picup_text={'Free Shipping'}
                        need_buyerpremium={
                            parseInt(global.storeConfigration?.global_buyer_premium?.value) == 0
                                ? 1
                                : 0
                        }
                    />
                </div>
            </Layout>
        </>
    )
}

export default PostBuynow
