import React, { useEffect, useState } from 'react'
import Popup from '../../Product/components/organisms/Popup'
import { GoogleMap, Marker, useJsApiLoader } from '@react-google-maps/api'
import { fromAddress, setKey } from 'react-geocode'
import PrimaryButton from '../../Product/components/atoms/PrimaryButton'
import './LocationHours.css'

const LocationHours = (props) => {
    const getCenterForGridMap = (loc) => {
        return { lat: loc.lat, lng: loc.lng, zoom: 8 }
    }

    const locations = [
        {
            lat: 29.7120471,
            lng: -95.5167979,
            name: 'Houston TX',
            address: '102 Shaver St, Pasadena, TX 77506',
            redirectionURL: 'https://maps.app.goo.gl/FMMh2dQ5JsMRryk58',
            openHours: [
                { day: 'Monday', hours: '09:00 am - 05:00 pm' },
                { day: 'Tuesday', hours: '09:00 am - 05:00 pm' },
                { day: 'Wednesday', hours: '09:00 am - 05:00 pm' },
                { day: 'Thurdsay', hours: '09:00 am - 05:00 pm' },
                { day: 'Friday', hours: '09:00 am - 05:00 pm' },
                { day: 'Saturday', hours: 'Closed' },
                { day: 'Sunday', hours: 'Closed' },
            ],
        },
    ]

    const style = {
        height: '350px',
        maxWidth: '500px',
        width: '100%',
        border: '10px solid #fff',
        margin: '10px 0 0 auto',
    }

    const handleLocation = (address) => {
        window.open(address, '_blank')
    }

    const { isLoaded } = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: 'AIzaSyANJvSnxieaGRhq_Ndt1Fe4MKDkDYE-SOM',
    })

    setKey('AIzaSyANJvSnxieaGRhq_Ndt1Fe4MKDkDYE-SOM')

    const [map, setMap] = useState(null)

    const onUnmount = React.useCallback(function callback(map) {
        setMap(null)
    }, [])

    return (
        <Popup
            {...props}
            size="md"
            modalClass={`save-search-modal ${props.classNames}`}
            modaltitle={'Location & Hours'}
        >
            <>
                {isLoaded ? (
                    <div className="location_hours_card_container">
                        {locations.map((data, index) => (
                            <div className="location_hours_card">
                                <GoogleMap
                                    mapContainerStyle={style}
                                    mapView={true}
                                    center={getCenterForGridMap({
                                        lat: data.lat,
                                        lng: data.lng,
                                    })}
                                    zoom={8}
                                    onUnmount={onUnmount}
                                >
                                    <Marker
                                        position={{ lat: data.lat, lng: data.lng }}
                                        onClick={() => handleLocation(data.redirectionURL)}
                                    />
                                </GoogleMap>
                                <div className="location_card_content">
                                    <h4>{data.name}</h4>
                                    <p>{data.address}</p>
                                </div>
                                <div className="location_hours_card_box">
                                    <PrimaryButton
                                        label="Get Direction"
                                        onClick={() => handleLocation(data.redirectionURL)}
                                    />
                                    <ul className="hours_list">
                                        <li className="hours_title">Location Hours</li>
                                        <li className="hours_list_items">
                                            {data.openHours.map((d, i) => (
                                                <div>
                                                    <span>{d.day}:</span>
                                                    <span>{d.hours}</span>
                                                </div>
                                            ))}
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        ))}
                    </div>
                ) : null}
            </>
        </Popup>
    )
}

export default LocationHours
