import React, { useContext, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import QRCode from 'react-qr-code'
import PaginationComponent from '../Pagination'
import moment from 'moment-timezone'
import pluginContext from '../../Product/context/plugin/pluginContext'
import { Dialog, IconButton } from '@material-ui/core'
import { addWeekdays } from '../../utils/commonFunctions'
import Popup from '../../Product/components/organisms/Popup'
import SecondaryButton from '../../Product/components/atoms/SecondaryButton'

const OrderPickup = () => {
    const { getOrderPickup, pickup_orders } = useContext(pluginContext)
    const changePage = (number) => setPage(parseInt(number))
    const [totalItems, setTotalItem] = useState(0)
    const [page, setPage] = useState(0)
    const [Items, setData] = useState([])
    const [readyInvoices, setReadyInvoices] = useState('')
    const [orderStatusPopup, setOrderStatusPopup] = useState(false)
    const [qrOpen, setQrOpen] = useState(false)
    const [popupDetails, setPopupDetails] = useState({
        status: '',
        pickup_date: '',
        paid: 0,
    })
    useEffect(() => {
        getOrderPickup({ perlimit: 10, page: page })
    }, [page])

    useEffect(() => {
        setData(pickup_orders.result)
        setTotalItem(pickup_orders.length)
        setReadyInvoices(pickup_orders.ready_invoices)
    }, [pickup_orders])
    function handleOrderStatusPopup(days, status, scheduled_date, paid) {
        setPopupDetails({
            status,
            pickup_date: scheduled_date
                ? moment(addWeekdays(scheduled_date, days)).format('MM-DD-YYYY')
                : null,
            paid,
        })
        setOrderStatusPopup(!orderStatusPopup)
    }
    function handleOrderStatusPopupClose() {
        setOrderStatusPopup(!orderStatusPopup)
    }

    const handleQrCodeOpen = () => {
        setQrOpen(!qrOpen)
    }
    return (
        <>
            {/* {readyInvoices && (
                <div className="row d-flex justify-content-center mb-4">
                    <div className="col-md-6">
                        <SecondaryButton btnSize="minMaxContent" onClick={handleQrCodeOpen}>
                            Check-in for Pickup
                        </SecondaryButton>
                    </div>
                </div>
            )} */}
            <div className="table-responsive">
                <table className="table table-hover">
                    <thead>
                        <tr>
                            <th className="numCell">#</th>
                            <th>Auction Name</th>
                            <th>Date Paid</th>
                            <th>Quantity</th>
                            {/* <th>Last Pick Up Date</th> */}
                            {/* <th>Status</th> */}
                            <th>Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {Items.map((item, index) => {
                            return (
                                <tr key={index}>
                                    <td>{item.common_invoice}</td>
                                    <td>{item.auction_name}</td>
                                    <td>
                                        {item.paid !== 1
                                            ? 'N/A'
                                            : moment(item.paid_date).format('MMM Do YYYY')}
                                    </td>
                                    <td>{item.no_of_items}</td>
                                    {/* <td>{item.abandoned_left}</td> */}
                                    {/* <td>{item.status}</td> */}
                                    <td>
                                        <Link
                                            button
                                            className="mbv-btn"
                                            onClick={() =>
                                                handleOrderStatusPopup(
                                                    item.abandoned_left,
                                                    item.status,
                                                    item.scheduled_date,
                                                    item.paid,
                                                )
                                            }
                                        >
                                            View Status
                                        </Link>
                                    </td>
                                </tr>
                            )
                        })}
                    </tbody>
                </table>
            </div>
            <PaginationComponent
                onPageChange={changePage}
                totalProductCount={parseInt(totalItems)}
                currentPage={parseInt(page)}
                resultToShow={10}
            />
            <Dialog open={orderStatusPopup} className="orderStatus">
                <div className="orderPickupStatus">
                    <IconButton className="popClrBtn" onClick={handleOrderStatusPopupClose}>
                        <span className="material-icons">clear</span>
                    </IconButton>
                    <div className="primeBidsTracker position-relative">
                        <div className="bord">
                            <h1>Prime Bids Tracker</h1>
                        </div>
                        <div className="trackrecord">
                            <ul>
                                <li
                                    className={
                                        !popupDetails.status && !popupDetails.paid ? 'active' : ''
                                    }
                                >
                                    <div>
                                        <h2>1</h2>
                                        <p>ORDER PLACED</p>
                                    </div>
                                </li>
                                <li className="pickLine"></li>
                                <li
                                    className={
                                        !popupDetails.status && popupDetails.paid ? 'active' : ''
                                    }
                                >
                                    <div>
                                        <h2>2</h2>
                                        <p>ORDER PAID</p>
                                    </div>
                                </li>
                                <li className="pickLine"></li>
                                <li className={popupDetails.status === 'preparing' ? 'active' : ''}>
                                    <div>
                                        <h2>3</h2>
                                        <p>ORDER BEING PREPARED</p>
                                    </div>
                                </li>
                                <li className="pickLine"></li>
                                <li className={popupDetails.status === 'ready' ? 'active' : ''}>
                                    <div>
                                        <h2>4</h2>
                                        <p>
                                            ORDER READY FOR PICKUP{' '}
                                            {popupDetails.pickup_date
                                                ? `Pick by: ${popupDetails.pickup_date}`
                                                : ''}
                                        </p>
                                    </div>
                                </li>
                                <li className="pickLine"></li>
                                <li className={popupDetails.status === 'completed' ? 'active' : ''}>
                                    <div>
                                        <h2>5</h2>
                                        <p>ORDER COMPLETED</p>
                                    </div>
                                </li>
                            </ul>
                        </div>
                        <div className="bord"></div>
                    </div>
                </div>
            </Dialog>
            <Popup
                open={qrOpen}
                handleClose={() => {
                    handleQrCodeOpen()
                }}
                size="md"
                className="save-search-modal"
                modaltitle="Check-in Qr Code"
            >
                <div className="row d-flex justify-content-center ">
                    <div className="col-md-6 text-center">
                        <p>
                            Show this at the entrance of Prime Bids to collect all your{' '}
                            <b>Ready for Pick Up</b> invoices
                        </p>
                        <QRCode value={readyInvoices} />
                        <p>
                            <small>
                                *PRO TIP - Only Your <b>Ready for Pick Up</b> invoices will be
                                available to pickup today.
                            </small>
                        </p>
                    </div>
                </div>
            </Popup>
        </>
    )
}

export default OrderPickup
