import React, { useState, useEffect, useContext, useRef } from 'react'
// import openSocket from 'socket.io-client'
import { useHistory } from 'react-router-dom'
import SubLots from './SubLots'
import ProductAuctionTop from './ProductAuctionTop'
import SlidePanel from '../../../Component/SlidePanel'
import './product.css'
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton'
import _ from 'lodash'
import Toaster from '../../../Component/Toaster'

import { socket, socketAuction } from '../../../Product/common/socket'

import ProductContext from '../../../Product/context/product/productContext'
import AuthContext from '../../../Product/context/auth/authContext'
import AlertContext from '../../../Product/context/alert/alertContext'

import NoRecordsFound from '../../../Product/components/atoms/NoRecordsFound'
import { messageHandler } from '../../../Product/common/socketHandler'

import Layout from '../Layout'
import MultisellerSlider from '../../../Component/SlidePanel/MultisellerSlider'
import Loaders from '../../../Product/components/molecules/Loaders'
import { useFormik } from 'formik'
import { mapData } from '../../../Product/common/components'
const ProductAuctionComponent = (props) => {
    const authContext = useContext(AuthContext)
    const productContext = useContext(ProductContext)
    const alertContext = useContext(AlertContext)

    const { user, isAuthenticated } = authContext
    const { setAlert } = alertContext

    const {
        search_allauctions,
        search_allauctionproducts,
        edit_auction,
        getLotList,
        getEditAuction,
        auc_cat_value,
        getauctioncat_subcat,
    } = productContext

    let auctionid = props.match.params.id
    const [auctionData, setAuctionData] = useState({})
    const [minMaxDateClosed, setMinMaxDateClosed] = useState({})
    const [subLotData, setSubLotData] = useState([])
    const [parentcategories, setParentCategories] = useState([])
    const [childcategories, setChildcategories] = useState([])
    const [subLotDataCount, setSubLotDataCount] = useState(0)
    const [isRegistered, setIsRegistered] = useState(0)
    const [selectedProduct, setSelectedProduct] = useState(null)
    const [subLotSearch, setSubLotSearch] = useState({
        page: 1,
        sortBy: 7,
        lotof: auctionid,
        limit: 25,
        market_status: 'open',
        auction_market_status: 'open',
        auction_buynow: 'all',
        need_bid_status: 'yes',
    })
    const [display_type, setType] = useState('Grid')
    const [loading, setLoading] = useState(true)
    let history = useHistory()
    const formik = useFormik({
        initialValues: {
            category: [],
            subcategory: [],
        },
    })
    const myRef = useRef(null)
    const executeScroll = () => myRef.current.scrollIntoView({ behavior: 'smooth' })
    useEffect(() => {
        getEditAuction({ auctionid }, 'auctionView')
    }, [isAuthenticated, auctionid])

    useEffect(() => {
        const params = new URLSearchParams(window.location.search.substring(1))
        if (params.get('inv')) {
            var data = subLotSearch
            data.closed_inv_auc = 1
            getLotList(
                {
                    ...data,
                    ...{
                        cat_id_multiple_io:
                            formik.values.category.length > 0 ? formik.values.category : undefined,
                        sub_cat_id_multiple_io:
                            formik.values.subcategory.length > 0
                                ? formik.values.subcategory
                                : undefined,
                    },
                },
                'auctionView',
            )
        } else {
            getLotList(
                {
                    ...subLotSearch,
                    ...{
                        cat_id_multiple_io:
                            formik.values.category.length > 0 ? formik.values.category : undefined,
                        sub_cat_id_multiple_io:
                            formik.values.subcategory.length > 0
                                ? formik.values.subcategory
                                : undefined,
                    },
                },
                'auctionView',
            )
        }
        if (global?.storeConfigration?.auction_cat_fill?.value) {
            getauctioncat_subcat(
                { lotof: auctionid, site_id: global?.storeDetails?.site_id },
                'auction_sub',
            )
        }
    }, [subLotSearch, isAuthenticated])

    // useEffect(() => {
    //     setSubLotSearch({ ...subLotSearch, lotof: auctionid })
    // }, [auctionid])

    useEffect(() => {
        if (search_allauctionproducts.from === 'auctionView') {
            setLoading(true)
            search_allauctionproducts.results.map((data) => {
                data.auction_city = auctionData?.city
                data.auction_state = auctionData?.state
                if (data?.extra_fields?.includes('}')) {
                    let parse = JSON.parse(data.extra_fields)
                    Object.keys(parse).map((val) => {
                        data[val] = parse[val]
                    })
                }
            })
            setSubLotData(search_allauctionproducts.results)
            setSubLotDataCount(search_allauctionproducts.total_only)
            setTimeout(() => {
                setLoading(false)
            }, 1500)
        }
    }, [search_allauctionproducts])
    useEffect(() => {
        if (search_allauctionproducts.from === 'auctionView_from_sublot') {
            search_allauctionproducts.results.map((data) => {
                data.auction_city = auctionData?.city
                data.auction_state = auctionData?.state
                if (data?.extra_fields?.includes('}')) {
                    let parse = JSON.parse(data.extra_fields)
                    Object.keys(parse).map((val) => {
                        data[val] = parse[val]
                    })
                }
            })
            setSubLotData(search_allauctionproducts.results)
            setSubLotDataCount(search_allauctionproducts.total_only)
        }
    }, [search_allauctionproducts])

    useEffect(() => {
        setLoading(true)
        if (edit_auction.from === 'auctionView') {
            if (edit_auction.auction_details) {
                setAuctionData(edit_auction.auction_details)
                setMinMaxDateClosed(edit_auction.min_max_date_closed)
                setIsRegistered(edit_auction.is_registered)
                setTimeout(() => {
                    setLoading(false)
                }, 1500)
            }
        }
    }, [edit_auction])

    const handleClose = () => {
        setSelectedProduct(null)
    }
    const handleClick = async (id) => {
        setSelectedProduct(id)
    }

    const viewProductRef = useRef(subLotData)
    const userRef = useRef(user)

    // Sets product and user refrences on page load
    useEffect(() => {
        viewProductRef.current = subLotData
        userRef.current = user
    })

    const handler = (message, type) => {
        messageHandler(
            message,
            viewProductRef.current,
            userRef.current,
            setAlert,
            setSubLotData,
            type,
        )
    }
    useEffect(() => {
        socket.on('realclosedupdates', (data) => {
            handler(data, 'realclosedupdates')
        })
        socket.on('bidAddtime', (data) => {
            handler(data, 'bidAddtime')
        })

        socket.on('buyer_assurance', (data) => {
            handler(data, 'buyer_assurance')
        })

        socketAuction.on('bidAddtime', (data) => {
            handler(data, 'bidAddtime')
        })

        return () => {
            socket.off('realclosedupdates', (data) => {
                handler(data, 'realclosedupdates')
            })
            socket.off('bidAddtime', (data) => {
                handler(data, 'bidAddtime')
            })
            socket.off('buyer_assurance', (data) => {
                handler(data, 'buyer_assurance')
            })

            socketAuction.off('bidAddtime', (data) => {
                handler(data, 'bidAddtime')
            })
        }
    }, [])

    var filtervalues = [
        {
            label: 'Select Category',
            placeholder: 'Select Category',
            type: 'multiselect',
            class: `col-md-3`,
            options: parentcategories.map((data) => {
                return {
                    value: `${data.id}`,
                    show: `${data.name} (${data.cat_count})`,
                }
            }),
            defaultOptionValue: 'Select Category',
            name: 'category',
            formik: formik,
        },
        // {
        //     label: 'Select SubCategory',
        //     placeholder: 'Select SubCategory',
        //     type: 'multiselect',
        //     no_item: 'No Subcategories found',
        //     class: `col-md-3`,
        //     options: childcategories.map((data) => {
        //         return {
        //             value: `${data.id} (${data.cat_count})`,
        //             show: data.name,
        //         }
        //     }),
        //     defaultOptionValue: 'Select SubCategory',
        //     name: 'subcategory',
        //     formik: formik,
        // },
    ]

    useEffect(() => {
        if (auc_cat_value?.auc_cat.length > 0) {
            setParentCategories(auc_cat_value.auc_cat)
        } else {
            setParentCategories([])
        }
        if (auc_cat_value?.auc_sub_cat.length > 0) {
            setChildcategories(auc_cat_value.auc_sub_cat)
        } else {
            setChildcategories([])
        }
    }, [auc_cat_value])

    useEffect(() => {
        if (formik.values.category.length > 0 || formik.values.subcategory.length > 0) {
            const params_val = new URLSearchParams(window.location.search.substring(1))
            if (params_val.get('inv')) {
                var data = subLotSearch
                data.closed_inv_auc = 1
                getLotList(
                    {
                        ...data,
                        ...{
                            cat_id_multiple_io:
                                formik.values.category.length > 0
                                    ? formik.values.category
                                    : undefined,
                            sub_cat_id_multiple_io:
                                formik.values.subcategory.length > 0
                                    ? formik.values.subcategory
                                    : undefined,
                        },
                    },
                    'auctionView',
                )
            } else {
                getLotList(
                    {
                        ...subLotSearch,
                        ...{
                            cat_id_multiple_io:
                                formik.values.category.length > 0
                                    ? formik.values.category
                                    : undefined,
                            sub_cat_id_multiple_io:
                                formik.values.subcategory.length > 0
                                    ? formik.values.subcategory
                                    : undefined,
                        },
                    },
                    'auctionView',
                )
            }
        }
    }, [formik.values.category, formik.values.subcategory])

    return (
        <Layout props={props}>
            <div className="customContainer pt-3 auctionCnt">
                {loading ? (
                    <Loaders name="product_view" isLoading={loading} loop={1} />
                ) : (
                    <>
                        <ProductAuctionTop
                            auctionData={auctionData}
                            product_id={auctionid}
                            executeScroll={executeScroll}
                            sellerName={subLotData[0]?.seller_name}
                            is_registered={isRegistered}
                            hide_hosted_by={1}
                            minMaxDateClosed={minMaxDateClosed}
                        />
                        <div ref={myRef}></div>
                        <>
                            {global?.storeConfigration?.auction_cat_fill?.value ? (
                                <div className="row mt-5">{mapData(filtervalues)}</div>
                            ) : (
                                ''
                            )}
                            <SubLots
                                selectedPostion={handleClick}
                                handleClick={handleClick}
                                title={auctionData.title}
                                auctionid={auctionid}
                                sendType={(val) => {
                                    setType(val)
                                    getLotList(
                                        {
                                            ...subLotSearch,
                                            ...{
                                                cat_id_multiple_io:
                                                    formik.values.category.length > 0
                                                        ? formik.values.category
                                                        : undefined,
                                                sub_cat_id_multiple_io:
                                                    formik.values.subcategory.length > 0
                                                        ? formik.values.subcategory
                                                        : undefined,
                                            },
                                        },
                                        'auctionView_from_sublot',
                                    )
                                }}
                                lotData={subLotData}
                                subLotDataCount={subLotDataCount}
                                subLotSearch={subLotSearch}
                                setSubLotSearch={setSubLotSearch}
                                view_type={display_type}
                            />
                            <MultisellerSlider
                                type="right"
                                storeTheme="Liquidation_two"
                                selectedLot={selectedProduct}
                                handleClose={handleClose}
                                from={display_type == 'List' ? 'card' : ''}
                            />
                        </>
                    </>
                )}
            </div>
        </Layout>
    )
}

export default ProductAuctionComponent
