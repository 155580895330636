import React, { useState, useEffect } from 'react'
import axios from 'axios'
import './review.css'
import Tooltip from '@material-ui/core/Tooltip'
import { Swiper, SwiperSlide, useSwiper, useSwiperSlide } from 'swiper/react/swiper-react'
import 'swiper/swiper-bundle.css'
import { EffectCoverflow, Navigation, Autoplay, Pagination } from 'swiper'
import moment from 'moment'
const Reviews = (props) => {
    const [reviews, setReviews] = useState([])
    const [loading, setLoading] = useState(true)
    const [error, setError] = useState(null)

    useEffect(() => {
        const fetchReviews = async () => {
            try {
                var placeId = global?.pluginConfiguration?.google_reviews?.client_key
                var apiKey = global?.pluginConfiguration?.google_reviews?.service_key
                const response = await axios.post(`${global.site_url}place-details_review`, {
                    place_id: placeId,
                    api_key: apiKey,
                })

                if (response.data.result.reviews) {
                    setReviews(response.data.result.reviews)
                }
            } catch (err) {
                setError(err)
            } finally {
                setLoading(false)
            }
        }

        fetchReviews()
    }, [])

    if (loading) {
        return <p>Loading reviews...</p>
    }

    const formatDate = (dateString) => {
        const date = new Date(dateString)
        const options = {
            weekday: 'long',
            year: 'numeric',
            month: 'long',
            day: 'numeric',
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit',
        }
        // var { days, weeks, months, years } = getDifference(
        //     date.toLocaleDateString('en-US', options),
        // )
        return date.toLocaleDateString('en-US', options)
    }

    // if (error) {
    //     return <p>Error loading reviews: {error.message}</p>
    // }

    return (
        <div className="tstSlickSlider">
            {reviews.length > 0 ? (
                <Swiper
                    navigation={true}
                    speed={2500}
                    grabCursor={true}
                    slidesPerView={3}
                    slidesPerGroup={3}
                    showsPagination={false}
                    pagination={{
                        clickable: true,
                    }}
                    autoplay={{
                        delay: 2500,
                        disableOnInteraction: true,
                        pauseOnMouseEnter: true,
                    }}
                    modules={[Autoplay, EffectCoverflow, Navigation, Pagination]}
                    className="mySwiper"
                >
                    {reviews.map((data, index) => (
                        <SwiperSlide key={index}>
                            <div className="testMCard">
                                <div className="topCnt">
                                    <figure className="profileImg">
                                        <img src={data.profile_photo_url} alt="profile" />
                                    </figure>
                                    {console.log(data, 'data')}
                                    <div className="reviewUserTimeSpan">
                                        <span>{data.author_name}</span>
                                        <span>{data.relative_time_description}</span>
                                    </div>
                                    <div className="d-flex align-items-center justify-content-center">
                                        {[1, 2, 3, 4, 5].map((values, index) => (
                                            <span
                                                key={index}
                                                className={`material-icons ${
                                                    index + 1 <= data.rating ? 'active' : ''
                                                }`}
                                            >
                                                star
                                            </span>
                                        ))}
                                    </div>
                                    <img
                                        src="/image/googlefav.png"
                                        alt="googleReview"
                                        className="googleReview"
                                        width="50px"
                                        height="50px"
                                    />
                                    <p className="userContent">{data.text}</p>
                                    {/* <div className="col-md-5">
                                        <p>{formatDate(data.time)}</p>
                                    </div> */}
                                </div>
                                {/* <div className="rvwrCnt text-center">
                                    <div className="mrtIcnWrpr">
                                        <span className="material-icons">account_circle</span>
                                    </div>
                                    <p className="mb-0">{data.author_name}</p>
                                </div> */}
                            </div>
                        </SwiperSlide>
                    ))}
                </Swiper>
            ) : (
                ''
            )}
        </div>
    )
}

export default Reviews
