import React from 'react'
import './Registration.css'
import { Logo } from '../../../utils'
import SignupFunction from '../../../utils/CommonFunctionality/SignupFunction'

const Registration = () => {
    return (
        <>
            <div className="clearfix row regCnt">
                <div className="col-lg-3 d-none d-lg-block pad-0">
                    {/*<div className="loginImgBox d-flex justify-content-between flex-column"></div>*/}
                </div>
                <div className="col-lg-6 col-12">
                    <div className="loginFormBox pt-3 pb-3">
                        <Logo />
                        <SignupFunction
                            phone_country_code_must={true}
                            title="Signup"
                            subTitle="Create Your Free Account"
                        />
                    </div>
                </div>
            </div>
        </>
    )
}

export default Registration
