import React, { useContext, useEffect } from 'react'
import Layout from '../Layout'
import DashboardLayout from '../DashboardLayout'
import UserMessage from '../../../utils/CommonFunctionality/Message'
import buyerContext from '../../../Product/context/buyer/buyerContext'

const Message = (props) => {
    const { setGlobalChatUser } = useContext(buyerContext)
    useEffect(() => {
        return () => {
            console.log('++++++')
            setGlobalChatUser({})
        }
    }, [])
    return (
        <Layout props={props}>
            <DashboardLayout title="Messages">
                <div className="mt-4">
                    <UserMessage data={props} />
                </div>
            </DashboardLayout>
        </Layout>
    )
}

export default Message
