import React, { useContext } from 'react'
import { Link, NavLink } from 'react-router-dom'
import { Copyrightinfo, Logo, FooterLogo } from '../../../utils'
import useMediaQuery from '@material-ui/core/useMediaQuery'
// import { useMediaQuery } from 'react-responsive'
import AuthContext from '../../../Product/context/auth/authContext'
import './Footer.css'
import Footernav from '../../../utils/FooterNavigation/footernav'
import { useTranslation } from 'react-i18next'
import { ListItem } from '@material-ui/core'

function Footer() {
    const contactEmail = global?.storeDetails?.contact_email
    const contactPhone = global?.storeDetails?.phone
    const facebookLink = global?.storeDetails?.facebook
    const twitterLink = global?.storeDetails?.twitter
    const googleLink = global?.storeDetails?.google
    const instagramLink = global?.storeDetails?.instagram
    const { t } = useTranslation()
    const [hbid, setHBid] = React.useState(false)

    const { user, isAuthenticated, logout } = useContext(AuthContext)

    const isMobile = useMediaQuery('(max-width:700px)')
    const forMobile = useMediaQuery('(max-width:1024px)')
    // const isMobile = useMediaQuery({
    //     query: '(max-width: 700px)'
    // })

    return (
        <footer className="footer-main-standard noPrint">
            <div className="customContainer">
                <div className="row" data-gutter="60">
                    <div className="col-12 ftrDtlCntnr">
                        <div className="footLogoCnt">
                            <FooterLogo className="footerLogo" />
                            <ul className="footer-main-standard-social-list">
                                {facebookLink && (
                                    <li className="facebook">
                                        <a
                                            href={facebookLink}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            title="Facebook"
                                        >
                                            <i className="fab fa-facebook-f"></i>
                                        </a>
                                    </li>
                                )}
                                {instagramLink && (
                                    <li className="instagram">
                                        <a
                                            href={instagramLink}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            title="Instagram"
                                        >
                                            <i className="fab fa-instagram"></i>
                                        </a>
                                    </li>
                                )}
                                {googleLink && (
                                    <li className="instagram">
                                        <a
                                            href={googleLink}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            title="Linkedin"
                                        >
                                            <i className="fab fa-linkedin-in"></i>
                                        </a>
                                    </li>
                                )}
                                {twitterLink && (
                                    <li className="pinterest">
                                        <a
                                            href={twitterLink}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            title="Twitter"
                                        >
                                            <i className="fab fa-twitter"></i>
                                        </a>
                                    </li>
                                )}
                            </ul>
                            {global?.storeConfigration?.remove_footer?.value == 1 ? null : (
                                <ul className="footerLinks align-items-center">
                                    <li>
                                        <NavLink activeClassName="active" to="/contact_us">
                                            {t('contact_us')}
                                        </NavLink>
                                    </li>
                                    <li>
                                        <NavLink activeClassName="active" to="/how_it_works">
                                            {t('How It Works')}
                                        </NavLink>
                                    </li>
                                    {global.storeDetails?.privacy && (
                                        <li>
                                            <NavLink activeClassName="active" to="/aboutus">
                                                {t('about_us')}
                                            </NavLink>
                                        </li>
                                    )}
                                    {global.storeDetails?.terms && (
                                        <li>
                                            <NavLink activeClassName="active" to="/faq">
                                                {t('faq')}
                                            </NavLink>
                                        </li>
                                    )}
                                    {global.storeDetails?.terms_condition && (
                                        <li>
                                            <NavLink activeClassName="active" to="/termsOfService">
                                                {t('terms_conditions')}
                                            </NavLink>
                                        </li>
                                    )}
                                    {global.storeDetails?.privacy_policy && (
                                        <li>
                                            <NavLink activeClassName="active" to="/privacy_policy">
                                                {t('Privacy_Policy')}
                                            </NavLink>
                                        </li>
                                    )}
                                    {isMobile && (
                                        <li>
                                            {global.storeDetails?.footer_menu ? (
                                                <>
                                                    {global.storeDetails?.footer_menu.length > 0
                                                        ? global.storeDetails.footer_menu.map(
                                                              (val) =>
                                                                  val.subLinks.length > 0 ? (
                                                                      <li>
                                                                          <div className="nav-menu-from-store">
                                                                              <a
                                                                                  //   activeClassName="active"
                                                                                  href={val.path}
                                                                              >
                                                                                  {t(val.label)}
                                                                              </a>
                                                                          </div>
                                                                          {val.subLinks.map(
                                                                              (data, index) => (
                                                                                  <a
                                                                                      key={index}
                                                                                      rel="noreferrer"
                                                                                      //   activeClassName="active"
                                                                                      href={
                                                                                          data.path
                                                                                      }
                                                                                  >
                                                                                      <ListItem className="igive">
                                                                                          {
                                                                                              data.label
                                                                                          }
                                                                                      </ListItem>
                                                                                  </a>
                                                                              ),
                                                                          )}
                                                                      </li>
                                                                  ) : (
                                                                      <li>
                                                                          <a
                                                                              //   activeClassName="active"
                                                                              href={val.path}
                                                                          >
                                                                              {t(val.label)}
                                                                          </a>
                                                                      </li>
                                                                  ),
                                                          )
                                                        : ''}
                                                </>
                                            ) : (
                                                ''
                                            )}
                                        </li>
                                    )}
                                    {!isMobile && <Footernav />}
                                </ul>
                            )}
                            <Copyrightinfo />
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    )
}

export default Footer
