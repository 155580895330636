import React from 'react'
import DashboardLayout from '../DashboardLayout'
import Layout from '../Layout'
import Bucks from '../../../utils/CommonFunctionality/Referral/Bucks'

const Referral = (props) => {
    return (
        <Layout props={props}>
            <DashboardLayout title="REFERRAL">
                <Bucks />
            </DashboardLayout>
        </Layout>
    )
}

export default Referral
