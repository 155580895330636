import React from 'react'
import Popup from '../../Product/components/organisms/Popup'
import Terms from './terms'

const AuctionTerms = (props) => {
    return (
        <Popup
            {...props}
            size="md"
            modalClass={`save-search-modal ${props.classNames}`}
            modaltitle="Terms & Conditions"
        >
            <Terms auctionData={props.auctionData} />
        </Popup>
    )
}

export default AuctionTerms
