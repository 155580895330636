import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Stepper from '@material-ui/core/Stepper'
import Step from '@material-ui/core/Step'
import StepLabel from '@material-ui/core/StepLabel'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import './style.css'
import Step1 from './Step1'
import Step2 from './Step2'
import { useParams } from 'react-router-dom'
const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
    button: {
        marginRight: theme.spacing(1),
    },
    instructions: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },
}))

function getSteps() {
    return ['Auction Details', 'Item Details']
}

export default function HorizontalLinearStepper(props) {
    const auction_id = new URLSearchParams(window.location.search.substring(1)).get('id')
    const type = new URLSearchParams(window.location.search.substring(1)).get('type')
    const { id } = useParams()
    const classes = useStyles()
    const [activeStep, setActiveStep] = React.useState(id ? 1 : 0)
    const steps = getSteps()

    return (
        <div className={classes.root}>
            {type == 'repost' ? (
                <>
                    {' '}
                    <Step1
                        setActiveStep={setActiveStep}
                        auction_id={auction_id}
                        defaultCountryCode={
                            props.defaultCountryCode ? props.defaultCountryCode : 38
                        }
                        theme={props.theme}
                    ></Step1>
                </>
            ) : (
                <>
                    <Stepper activeStep={activeStep} alternativeLabel>
                        {steps.map((label) => (
                            <Step key={label}>
                                <StepLabel>{label}</StepLabel>
                            </Step>
                        ))}
                    </Stepper>

                    <div>
                        {/*eslint-disable*/}
                        {activeStep === 0 && (
                            <Step1
                                setActiveStep={setActiveStep}
                                auction_id={auction_id}
                                defaultCountryCode={
                                    props.defaultCountryCode ? props.defaultCountryCode : 38
                                }
                                theme={props.theme}
                            />
                        )}
                        {activeStep === 1 && (
                            <Step2
                                setActiveStep={setActiveStep}
                                auction_id={id}
                                addressSection={props.addressSection}
                                buynow={props.buynow}
                                extraSection={props.extraSection}
                                foodIndustryFields={props.foodIndustryFields}
                                theme={props.theme}
                                taxFree={props.taxFree}
                                low_and_high_est={props.low_and_high_est}
                                local_picup_text={props.local_pickup_text}
                                defaultCountryCode={
                                    props.defaultCountryCode ? props.defaultCountryCode : 38
                                }
                                bottomErrorField={props.bottomErrorField}
                            />
                        )}
                    </div>
                </>
            )}
        </div>
    )
}
