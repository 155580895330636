import { Button } from '@material-ui/core'
import React, { useContext } from 'react'
import { Link, NavLink } from 'react-router-dom'
import HowBidPopup from '../../../Component/HowBiddingWorks'
import { Copyrightinfo, Logo, FooterLogo } from '../../../utils'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import AuthContext from '../../../Product/context/auth/authContext'
import './Footer.css'
import Footernav from '../../../utils/FooterNavigation/footernav'
import LocationHours from '../../../Component/LocationHours'

function Footer() {
    const contactEmail = global?.storeDetails?.contact_email
    const contactPhone = global?.storeDetails?.phone
    const facebookLink = global?.storeDetails?.facebook
    const twitterLink = global?.storeDetails?.twitter
    const googleLink = global?.storeDetails?.google
    const instagramLink = global?.storeDetails?.instagram

    const [hbid, setHBid] = React.useState(false)
    const [locationHours, setLocationHours] = React.useState(false)

    const { user, isAuthenticated, logout } = useContext(AuthContext)

    const forMobile = useMediaQuery('(max-width:1024px)')
    const isBidDepotProject = document.querySelector('body').classList.contains('BidAuctionDepot')

    return (
        <>
            {isBidDepotProject ? (
                <footer>
                    <div className="footerInner">
                        <div className="footerLeft_Base">
                            <dl>
                                <dd className="navTitlt">Auctions</dd>
                                <dd>
                                    <NavLink activeClassName="active" to="/how_it_works">
                                        Locations
                                    </NavLink>
                                </dd>
                                <dd>
                                    <NavLink activeClassName="active" to="/how_it_works">
                                        Equipment Auctions
                                    </NavLink>
                                </dd>
                                <dd>
                                    <NavLink activeClassName="active" to="/how_it_works">
                                        Building Materials
                                    </NavLink>
                                </dd>
                                <dd>
                                    <NavLink activeClassName="active" to="/how_it_works">
                                        Truckloads
                                    </NavLink>
                                </dd>
                                <dd>
                                    <NavLink activeClassName="active" to="/how_it_works">
                                        Past Auctions
                                    </NavLink>
                                </dd>
                                <dd style={{ height: '25px' }}></dd>
                                <dd className="secondhalfTit">CONTACT BIDFTA</dd>
                                <dd>
                                    <NavLink
                                        activeClassName="active"
                                        to="/how_it_works"
                                        type="mail"
                                    >
                                        support@bidfta.com
                                    </NavLink>
                                </dd>
                                <dd>
                                    <NavLink
                                        activeClassName="active"
                                        to="/how_it_works"
                                        type="number"
                                    >
                                        (513) 771 1700
                                    </NavLink>
                                </dd>
                            </dl>
                            <dl>
                                <dd className="navTitlt">Categories</dd>
                                <dd>
                                    <NavLink activeClassName="active" to="/how_it_works">
                                        Appliances
                                    </NavLink>
                                </dd>
                                <dd>
                                    <NavLink activeClassName="active" to="/how_it_works">
                                        Automotive
                                    </NavLink>
                                </dd>
                                <dd>
                                    <NavLink activeClassName="active" to="/how_it_works">
                                        Clothing & Accessories
                                    </NavLink>
                                </dd>
                                <dd>
                                    <NavLink activeClassName="active" to="/how_it_works">
                                        Electronics
                                    </NavLink>
                                </dd>
                                <dd>
                                    <NavLink activeClassName="active" to="/how_it_works">
                                        Grocery
                                    </NavLink>
                                </dd>
                                <dd>
                                    <NavLink activeClassName="active" to="/how_it_works">
                                        Home & Kitchen
                                    </NavLink>
                                </dd>
                                <dd>
                                    <NavLink activeClassName="active" to="/how_it_works">
                                        Patio, Lawn & Garden
                                    </NavLink>
                                </dd>
                                <dd>
                                    <NavLink activeClassName="active" to="/how_it_works">
                                        Tools & Home Improvement
                                    </NavLink>
                                </dd>
                                <dd className="secondhalfTit">
                                    <NavLink activeClassName="active" to="/how_it_works">
                                        SHOP ALL CATEGORIES
                                    </NavLink>
                                </dd>
                            </dl>
                            <dl>
                                <dd className="navTitlt">Resources</dd>
                                <dd>
                                    <NavLink activeClassName="active" to="/how_it_works">
                                        Sell With Us
                                    </NavLink>
                                </dd>
                                <dd>
                                    <NavLink activeClassName="active" to="/how_it_works">
                                        How-To Guides
                                    </NavLink>
                                </dd>
                                <dd>
                                    <NavLink activeClassName="active" to="/how_it_works">
                                        Bin Store
                                    </NavLink>
                                </dd>
                                <dd>
                                    <NavLink activeClassName="active" to="/how_it_works">
                                        BidFTA+
                                    </NavLink>
                                </dd>
                                <dd style={{ height: '25px' }}></dd>
                                <dd>
                                    <NavLink activeClassName="active" to="/how_it_works">
                                        Privacy Policy
                                    </NavLink>
                                </dd>
                                <dd>
                                    <NavLink activeClassName="active" to="/how_it_works">
                                        Terms & Conditions
                                    </NavLink>
                                </dd>
                                <dd>
                                    <NavLink activeClassName="active" to="/how_it_works">
                                        Careers
                                    </NavLink>
                                </dd>
                            </dl>
                        </div>
                        <div className="footerRight_Base">
                            <FooterLogo className="footerLogo" />
                            <span className="d-md-block d-none">
                                <Copyrightinfo />
                            </span>
                            <div className="socialButton_Base">
                                <a href="#">
                                    <img src="/assets/images/appStore.png" alt="AppStore Link" />
                                </a>
                                <a href="#">
                                    <img
                                        src="/assets/images/googleStore.png"
                                        alt="googleStore Link"
                                    />
                                </a>
                            </div>
                        </div>
                    </div>
                </footer>
            ) : (
                <footer className="footer-main-standard noPrint">
                    <div className="customContainer">
                        <div className="row" data-gutter="60">
                            <div className="col-12 ftrDtlCntnr">
                                <div className="footLogoCnt">
                                    <FooterLogo className="footerLogo" />
                                    <span className="d-md-block d-none">
                                        <Copyrightinfo />
                                    </span>
                                </div>
                                <div className="footerMisc">
                                    <ul
                                        id={
                                            global.storeDetails?.footer_theme == '#0d0c0c' ||
                                            global.storeDetails.footer_theme == '#000000'
                                                ? 'change-footer-hov-color'
                                                : ''
                                        }
                                        className="footerLinks"
                                    >
                                        {/* <li onClick={() => setHBid(true)}>
                                            <a className="cursorDecoy">How does bidding work</a>
                                        </li> */}
                                        <li onClick={() => setLocationHours(true)}>
                                            <a className="cursorDecoy">Location & Hours</a>
                                        </li>
                                        <li>
                                            <NavLink activeClassName="active" to="/how_it_works">
                                                How It Works
                                            </NavLink>
                                        </li>
                                        <li>
                                            <NavLink activeClassName="active" to="/termsOfService">
                                                Terms & Conditions
                                            </NavLink>
                                        </li>
                                        <li>
                                            <NavLink activeClassName="active" to="/privacy_policy">
                                                Privacy Policy
                                            </NavLink>
                                        </li>
                                        <li>
                                            <NavLink activeClassName="active" to="/faq">
                                                FAQs
                                            </NavLink>
                                        </li>
                                        <li>
                                            <NavLink activeClassName="active" to="/aboutus">
                                                About Us
                                            </NavLink>
                                        </li>
                                        <li>
                                            <NavLink activeClassName="active" to="/contact_us">
                                                Contact Us
                                            </NavLink>
                                        </li>
                                        <Footernav />
                                    </ul>

                                    <ul className="footer-main-standard-social-list">
                                        {facebookLink && (
                                            <li className="facebook">
                                                <a
                                                    href={facebookLink}
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                    title="Facebook"
                                                >
                                                    <i className="fab fa-facebook"></i>
                                                </a>
                                            </li>
                                        )}
                                        {twitterLink && (
                                            <li className="pinterest">
                                                <a
                                                    href={twitterLink}
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                    title="Twitter"
                                                >
                                                    <i className="fab fa-twitter"></i>
                                                </a>
                                            </li>
                                        )}
                                        {googleLink && (
                                            <li className="instagram">
                                                <a
                                                    href={googleLink}
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                    title="Instagram"
                                                >
                                                    <i className="fab fa-linkedin"></i>
                                                </a>
                                            </li>
                                        )}
                                        {instagramLink && (
                                            <li className="instagram">
                                                <a
                                                    href={instagramLink}
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                    title="Instagram"
                                                >
                                                    <i className="fab fa-instagram"></i>
                                                </a>
                                            </li>
                                        )}
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <span className="d-md-none d-block">
                        <Copyrightinfo />
                    </span>
                    <HowBidPopup open={hbid} handleClose={() => setHBid(false)} />
                    <LocationHours
                        modaltitle="Location & Hours"
                        open={locationHours}
                        handleClose={() => setLocationHours(false)}
                    />
                </footer>
            )}
        </>
    )
}

export default Footer
