import axios from 'axios'
import { config } from './common.header'

const getInvoice = async (id) => {
    try {
        const response = await axios.post(
            `${process.env.REACT_APP_URL}view-invoice`,
            { id, isAuction: 0, hasShipping: 0 },
            config(),
        )
        return response
    } catch (err) {
        //console.log(err)
    }
}

const getTransactions = async (body) => {
    try {
        const response = await axios.post(
            `${process.env.REACT_APP_URL}buyerTransaction`,
            { ...body },
            config(),
        )
        return response
    } catch (error) {
        //console.log(error)
    }
}

export { getInvoice, getTransactions }
