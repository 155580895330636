import React, { useEffect, useState, useContext } from 'react'
import Layout from '../Layout'
import SearchAuctionList from '../../../Component/Search/SearchAuctionList'
import ProductContext from '../../../Product/context/product/productContext'
import NoRecordsFound from '../../../Product/components/atoms/NoRecordsFound'
import './search.css'
const SearchComponent = (props) => {
    const productContext = useContext(ProductContext)
    const { search_allauctions, getAllSearchAuctions } = productContext
    const [loading, setLoading] = useState(true)
    const [viewAuction, setViewAuction] = useState([])
    const [viewClosedAuction, setViewClosedAuction] = useState([])
    const [listview, setlistview] = useState(false)

    const [auctionSearch, setAuctionSearch] = useState({
        page: 1,
        sh_limit: '10',
        orderby: '2',
        searchbar: '',
        is_auction: 1,
    })
    const [closedSearch] = useState({
        page: 1,
        sh_limit: '50',
        orderby: '1',
        searchbar: '',
        type: 'closed',
        is_auction: 1,
    })

    useEffect(() => {
        getAllSearchAuctions(auctionSearch, 'activeHome')
    }, [auctionSearch])

    useEffect(() => {
        getAllSearchAuctions(closedSearch, 'closedHome')
    }, [closedSearch])

    useEffect(() => {
        setLoading(false)
        if (search_allauctions.from === 'closedHome') {
            setViewClosedAuction(
                search_allauctions.results.length ? search_allauctions.results : [],
            )
        }
        if (search_allauctions.from === 'activeHome') {
            setViewAuction(search_allauctions.results.length ? search_allauctions.results : [])
        }
    }, [search_allauctions])

    useEffect(() => {
        setViewAuction([])
        setViewClosedAuction([])
        return () => {
            setViewAuction([])
            setViewClosedAuction([])
        }
    }, [])

    const onHandlePage = (event, value) => {
        setAuctionSearch({ ...search, page: value })
    }

    // useEffect(() => {
    //     setLoading(true)
    //     getAllSearchAuctions(closedSearch)
    // }, [closedSearch])

    return (
        <Layout props={props}>
            <div className="container auctionListCnt">
                {/* <Filters /> */}
                <h2 className="auctionListTitle">Active Auctions</h2>
                <div className="search-wrapper">
                    {loading ? (
                        <div className="row"></div>
                    ) : viewAuction.length === 0 ? (
                        <NoRecordsFound />
                    ) : (
                        <SearchAuctionList listview={listview} allsearchitems={viewAuction} />
                    )}
                </div>
                <h2 className="auctionListTitle">Closed Auctions</h2>
                <div className="search-wrapper">
                    {loading ? (
                        <div className="row"></div>
                    ) : viewClosedAuction.length === 0 ? (
                        <NoRecordsFound />
                    ) : (
                        <SearchAuctionList listview={listview} allsearchitems={viewClosedAuction} />
                    )}
                </div>
                <hr></hr>
            </div>
        </Layout>
    )
}

export default SearchComponent
