import React from 'react'
import { useContext } from 'react'
import { useState } from 'react'
import PosContext from '../../../../Product/context/plugin/pos/posContext'
import AuthContext from '../../../../Product/context/auth/authContext'
import PrimaryButton from '../../../../Product/components/atoms/PrimaryButton'
import CustomDialog from '../../../../Product/components/organisms/Dialog'
import { Button } from '@material-ui/core'
import { useEffect } from 'react'
import alertContext from '../../../../Product/context/alert/alertContext'
const Delete = ({ id, label }) => {
    const { user } = useContext(AuthContext)
    const { deleteLocation, responseStatus } = useContext(PosContext)
    const { setAlert } = useContext(alertContext)
    const toggleOpen = () => setOpen(!open)
    const [btnLoading, setBtnLoading] = useState(false)
    const [open, setOpen] = useState(false)
    const deleteItem = () => {
        setBtnLoading(true)
        deleteLocation({
            user_id: user?.id,
            site_id: global?.storeDetails?.site_id,
            location_id: id,
        })
    }

    useEffect(() => {
        if (responseStatus) {
            if (responseStatus.from === 'deleteLocation') {
                if (responseStatus.status === 'success') {
                    setOpen(false)
                    setBtnLoading(false)
                } else {
                    setOpen(false)
                    setBtnLoading(false)
                    setAlert(responseStatus.message, 'error')
                }
            }
        }
    }, [responseStatus])
    return (
        <div className="deleteAction">
            <Button variant="outlined" className="ml-2" onClick={toggleOpen}>
                {label ? label : 'Delete'}
            </Button>
            <CustomDialog title={'Delete'} open={open} function={toggleOpen}>
                <h5>Are you sure ? Do you want to delete this location ?</h5>
                <div className="actionWrapper">
                    <Button onClick={toggleOpen} disabled={btnLoading}>
                        {'Cancel'}
                    </Button>
                    <PrimaryButton
                        onClick={deleteItem}
                        type="button"
                        disabled={btnLoading}
                        label={btnLoading ? 'Loading...' : 'Confirm'}
                    />
                </div>
            </CustomDialog>
        </div>
    )
}

export default Delete
