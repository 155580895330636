import React, { useContext, useState, useEffect, useRef } from 'react'
import BuyerContext from '../../Product/context/buyer/buyerContext'
import AuthContext from '../../Product/context/auth/authContext'
import PluginContext from '../../Product/context/plugin/pluginContext'
import ProductContext from '../../Product/context/product/productContext'
import NoRecordsFound from '../../Product/components/atoms/NoRecordsFound'
import SlidePanel from '../SlidePanel'
import { connect } from 'react-redux'
import {
    currencyFormat,
    dateFormatFrontDay,
    handleRedirectInternal,
} from '../../Product/common/components'
import { Pagination } from '@material-ui/lab'
import { Link, useHistory } from 'react-router-dom'
import Timer from '../../Product/common/timer'
import PrimaryButton from '../../Product/components/atoms/PrimaryButton'
import { imageError, getImageurl } from '../../utils/commonFunctions'
import MultisellerSlider from '../SlidePanel/MultisellerSlider'
import Loader from '../../assets/loader'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import 'react-lazy-load-image-component/src/effects/blur.css'
import SecondaryButton from '../../Product/components/atoms/SecondaryButton'
import axios from 'axios'
import AlertContext from '../../Product/context/alert/alertContext'
import RetractBid from './RetractBid'
import CurrencyConverterFormat from '../../utils/CommonFunctionality/CurrencyConverter/CurrencyConverterFormat'
import { useTranslation } from 'react-i18next'
import moment from 'moment'
import Popup from '../../Product/components/organisms/Popup'
import UserContext from '../../Product/context/user/userContext'
import Dispute from './Dispute'
import RequestRefund from './RequestRefund'
import Requestplugin from './Requestplugin'
import AddFeedback from '../../utils/CommonFunctionality/Feedback/AddFeedback'
import AdditionalPayment from './AdditionalPayment'
import CustomDialog from '../../Product/components/organisms/Dialog'
import InvoiceMessage from '../../utils/CommonFunctionality/Message/InvoiceMessage'
import { Chip } from '@material-ui/core'
import { socket } from '../../Product/common/socket'
import CheckBox from '../../Product/components/atoms/CheckBox'
import MultiPickup from '../../utils/CommonFunctionality/Scheduler/MultiPickup'
import CustomSelect from '../../Product/components/atoms/Inputs/CustomSelect'
import LotListProduct from '../ProductCard/lotListProduct'
import './Style.css'
const InvoiceRecords = (props) => {
    const buyerContext = useContext(BuyerContext)
    const productContext = useContext(ProductContext)
    const pluginContext = useContext(PluginContext)
    const userContext = useContext(UserContext)
    const authContext = useContext(AuthContext)
    const params = new URLSearchParams(window.location.search.substring(1))
    const [auction_list, setAuctionlist] = useState([])
    const [locationList, setLocationList] = useState([])
    const {
        getAllShippingAddress,
        all_shipping_address,
        static_page,
        getStaticPage,
        responseStatus: responseStatusUser,
    } = userContext
    const { getAbandonedOrders, responseStatus: pluginResponseStatus } = pluginContext

    const history = useHistory()
    const { t } = useTranslation()
    const {
        buyer_allproducts,
        buyer_allproducts_temp,
        getAllBuyerProducts,
        updateVideoCount,
        product_view,
        getViewProduct,
        check_get_shipping_rate_api,
        shipping_rate_calculation,
        clear_ship_station_amount,
        getAllMakeRequest,
        list_make_request,
        markasrecived,
        getAllAuctionProducts,
        getAllWarehouseLocations,
        all_auctions,
        all_locations,
        responseStatus: buyerresponsestatus,
        clearResponse,
    } = buyerContext
    const { isAuthenticated, user, updateMessageCount, userCount } = authContext
    const buyerAllProductsRef = useRef(buyer_allproducts)
    const userCountRef = useRef(userCount)
    const userRef = useRef(user)
    useEffect(() => {
        buyerAllProductsRef.current = buyer_allproducts
        userCountRef.current = userCount
        userRef.current = user
    })
    const [btnloading, setBtnloading] = useState(false)

    const [loading, setLoading] = useState(true)
    const [Items, setData] = useState([])
    const [selectedProduct, setSelectedProduct] = useState(null)
    const [abandonedOrders, setAbandonedOrders] = useState({
        products: [],
        page: 1,
        limit: 10,
        totaProducts: 0,
    })
    const [totalItems, setTotalItem] = useState(0)
    const [toInvoice, settoInvoice] = useState(false)
    const [toInvoiceID, settoInvoiceID] = useState(false)
    const [popup_open, setPopupopup] = useState(false)
    const [refundTrigger, setRefundTrigger] = useState(false)
    const [feedbackOpen, setFeedbackOpen] = useState(false)
    const [feedbackItem, setFeedbackItem] = useState({})
    const [additionalPayOpen, setAdditionalPayOpen] = useState(false)
    const [additionalPayItem, setAdditionalPayItem] = useState({})
    const [popupchecked, setPopupchecked] = useState(false)
    const [termscondtions, setTermscondition] = useState('')
    const [selectAddress, setSelectAddress] = useState([])
    const [display_address, setDisplayAddress] = useState({})
    const [messageChecked, setMessageChecked] = useState(false)
    const [date_closed, setDateClosed] = useState(new Date())
    const [paid_date, setPaidDate] = useState(new Date())
    const [lotof, setLotof] = useState('')
    const [showInvoice, setShowInvoice] = useState('')
    const [buynowId, setBuynowId] = useState({})
    const { responseStatus, similar_products_qty, getSimilarProductsQty, clearSimilarQty } =
        productContext
    const alertContext = useContext(AlertContext)
    const { setAlert } = alertContext
    const [search, setSearch] = useState({
        status: props.page,
        sh_limit: 100,
        page: 1,
        order: props.page == 'bidlost' ? 2 : 1,
        ftpImage: true,
        buynow_only_io: props.page == 'buynow' ? 1 : undefined,
        auction_only_io:
            props.page == 'bidwon' && global.pluginConfiguration?.make_an_offer?.enable
                ? 1
                : undefined,
        search: '',
        buynow_with_qty:
            props.page == 'buynow' && global?.storeConfigration?.buy_now_with_qty?.value
                ? 1
                : undefined,
        get_msg_count: global?.storeConfigration?.unique_slot4?.value == 1 ? 1 : 0,
        sch_type: '',
        auction_lot_filter: global.storeConfigration?.auction_dashboard_with_filter?.value ? 1 : 0,
        paid: 'all',
        lotof: '',
        from: 'invoice',
        warehouse_location: '',
    })

    const [searchValue, setSearchValue] = useState('')
    const [invoiceId, setInvoiceId] = useState([])
    const [scheduleInvoiceId, setScheduleInvoiceId] = useState([])
    const [scheduleLocationId, setScheduleLocationId] = useState([])
    const [printInvoiceId, setPrintInvoiceId] = useState([])
    const [trigger, setTrigger] = useState(false)
    const [similarQty, setSimilarQty] = useState([])
    const [initial, setInitial] = useState(true)
    const [checkoutLength, setCheckoutLength] = useState(0)
    const [scheduleLength, setScheduleLength] = useState(0)
    const [openSchedule, setOpenSchedule] = useState(false)

    const onHandlePage = (event, value) => {
        if (props.abandoned) {
            getAbandonedOrders({
                user_id: user?.id,
                limit: 10,
                page: value,
            })
            setAbandonedOrders({
                ...abandonedOrders,
                page: value,
            })
        } else {
            setSearch({ ...search, page: value })
            if (props.page == 'make_an_offer') {
                getAllMakeRequest({
                    user_id: user?.id,
                    status: 'Accepted',
                    limit: 10,
                    page: value,
                })
            }
        }
    }
    const categoryTypes = {}
    global.allCategories.map((catgeory) => (categoryTypes[catgeory.id] = catgeory))
    useEffect(() => {
        if (props.page != 'make_an_offer') {
            setLoading(false)
            setData(buyer_allproducts.results)
            setTotalItem(buyer_allproducts.total_pagecnt)
            setScheduleInvoiceId([])
            setScheduleLength(0)
        }
    }, [buyer_allproducts])
    useEffect(() => {
        if (responseStatus && responseStatus !== '') {
            if (responseStatus === 'buynow_updated_successfull') {
                getAllBuyerProducts(search)
            } else {
            }
        } else {
        }
    }, [responseStatus])
    // useEffect(() => {
    //     // if (user) {
    //     socket.on('insertMessages_count', (data) => {
    //         if (buyerAllProductsRef.current?.results?.length) {
    //             buyerAllProductsRef.current?.results.map((val) => {
    //                 if (
    //                     userRef.current?.id == data.to_id &&
    //                     data.project_id == val.common_invoice
    //                 ) {
    //                     console.log(val, 'val')
    //                     val.msg_count += 1
    //                 }
    //             })
    //             updateVideoCount(buyerAllProductsRef.current)
    //         }
    //     })
    //     // }

    //     return () => {
    //         console.log('000000000000')
    //         socket.off('insertMessages_count', (data) => {
    //             return data
    //         })
    //     }
    // }, [])

    useEffect(() => {
        if (buyerresponsestatus) {
            if (buyerresponsestatus.from == 'marke_as_recived') {
                setBtnloading(false)
                if (buyerresponsestatus.status == 'error') {
                    setAlert(buyerresponsestatus?.message?.message, buyerresponsestatus.status)
                } else {
                    if (buyerresponsestatus?.result?.message) {
                        setAlert(buyerresponsestatus.result.message, buyerresponsestatus.status)
                        getAllBuyerProducts(search)
                    }
                }
                clearResponse()
            }
        }
    }, [buyerresponsestatus])

    useEffect(() => {
        if (props.page != 'make_an_offer') {
            setLoading(true)
            if (isAuthenticated) {
                if (props.cancelRequest) {
                    props.cancelRequest.cancel()
                }

                if (props.setCancelRequest) {
                    props.setCancelRequest(props?.cancelToken)
                }
                var sen_buyer_data = search
                getAllBuyerProducts(sen_buyer_data, props?.cancelToken)
                getAllBuyerProducts({ ...sen_buyer_data, from: undefined, store_another: 1 })
            }
        }
    }, [search, isAuthenticated, refundTrigger, trigger])
    useEffect(() => {
        if (isAuthenticated && search.warehouse_location) {
            getAllAuctionProducts({
                site_id: global?.storeDetails?.site_id ? global.storeDetails.site_id : '',
                type: 2,
                user_id: user?.id,
                sch_type: props.sch_type,
                warehouse_location: search.warehouse_location,
            })
            var sen_buyer_data = search

            sen_buyer_data.lotof = localStorage.getItem('lotof')
                ? localStorage.getItem('lotof')
                : undefined
            setLotof(localStorage.getItem('lotof') ? localStorage.getItem('lotof') : '')
            localStorage.removeItem('lotof')
        }
    }, [isAuthenticated, search.warehouse_location])
    useEffect(() => {
        if (isAuthenticated) {
            getAllWarehouseLocations({
                site_id: global?.storeDetails?.site_id ? global.storeDetails.site_id : '',
                type: 2,
                user_id: user?.id,
                sch_type: props.sch_type,
            })
        }
    }, [isAuthenticated])

    useEffect(() => {
        if (all_auctions.length) {
            var reverse_auction = all_auctions.reverse()
            setAuctionlist([...reverse_auction])
        } else {
            setAuctionlist([])
        }
    }, [all_auctions])

    useEffect(() => {
        if (all_locations.length) {
            setLocationList(all_locations)
            if (!params.get('loc'))
                // setSearch({
                //     ...search,
                //     warehouse_location: all_locations[0]?.id,
                // })
                handleRedirectInternal(
                    history,
                    `dashboard/transactions?loc=${
                        global.storeDetails?.theme == 19 ? '1' : all_locations[0]?.id
                    }`,
                )
        } else {
            setLocationList([])
        }
    }, [all_locations])

    useEffect(() => {
        if (props.page == 'make_an_offer' && user) {
            setLoading(true)
            getAllMakeRequest({
                user_id: user?.id,
                limit: 10,
                page: 1,
            })
        }
    }, [user])

    useEffect(() => {
        if (props.page == 'bidwon' && props.abandoned && user) {
            console.log('came here.....')
            setLoading(true)
            getAbandonedOrders({
                user_id: user?.id,
                page: 1,
                limit: 10,
            })
        }
    }, [user])

    useEffect(() => {
        if (props.abandoned && pluginResponseStatus?.from == 'getAbandonedOrder') {
            if (pluginResponseStatus.status == 'error') {
                setAlert(pluginResponseStatus?.message?.message, pluginResponseStatus.status)
            } else {
                setAbandonedOrders({
                    ...abandonedOrders,
                    products:
                        pluginResponseStatus?.results !== null &&
                        pluginResponseStatus?.results.length > 0
                            ? pluginResponseStatus.results
                            : [],
                    totaProducts:
                        pluginResponseStatus?.total_count !== null &&
                        pluginResponseStatus?.total_count.length > 0
                            ? pluginResponseStatus.total_count[0].count
                            : 0,
                })
            }
        }
    }, [pluginResponseStatus])

    useEffect(() => {
        setLoading(false)
        if (props.page == 'make_an_offer') {
            if (list_make_request?.data?.length > 0) {
                setData(list_make_request.data)
                setTotalItem(list_make_request.totalcount)
            } else {
                setData([])
                setTotalItem(0)
            }
        }
    }, [list_make_request])

    const handleClose = () => {
        getAllBuyerProducts(search)
        setSelectedProduct(null)
    }

    const handleClick = async (id) => {
        if (props.storeTheme == 'Liquidation_three' || props.storeTheme == 'Lotsofauctions') {
            handleRedirectInternal(history, `product/${id}`)
        } else {
            setSelectedProduct(id)
        }
    }

    const handleInvoiceChange = (id) => {
        var arr = invoiceId
        var arr2 = Items
        var temp = []
        var temp2 = []
        if (arr.includes(id)) {
            temp = arr.filter((val) => val != id)
        } else {
            temp = [...arr, id]
        }
        temp.map((inv) => {
            arr2.filter((val) => val?.common_invoice == inv).map((value) => temp2.push(value))
        })
        setInvoiceId(temp)
        setCheckoutLength(temp2.length)
    }
    const handleScheduleInvoiceChange = (id, location) => {
        var arr = scheduleInvoiceId
        var arr2 = Items
        var temp = []
        var temp2 = []
        var stop = null
        var date_close = new Date()
        var date_paid = new Date()
        var loc_id = null
        if (arr.includes(id)) {
            temp = arr.filter((val) => val != id)
        } else {
            temp = [...arr, id]
        }
        temp.map((inv) => {
            arr2.filter((val) => val?.common_invoice == inv).map((value) => {
                loc_id = value.location_id
                temp2.push(value)
            })
        })
        temp2.map((val, i) => {
            console.log(val.date_closed, 'val.date_closed')
            if (i === 0) {
                date_close = val.date_closed
                date_paid = val.buynowpaid_date
            }
        })
        if (scheduleInvoiceId.length) {
            temp2.map((val, i) => {
                if (val.location_id != location) {
                    stop = true
                }
            })
        }
        if (stop) {
            return setAlert('Kindly add same location invoice!', 'error')
        }
        setDateClosed(date_close)
        setPaidDate(date_paid)
        setScheduleLocationId(loc_id)
        setScheduleInvoiceId(temp)
        setScheduleLength(temp2.length)
    }

    const handlePrintInvoiceChange = (id) => {
        var arr = printInvoiceId
        var arr2 = Items
        var temp = []
        if (arr.includes(id)) {
            temp = arr.filter((val) => val != id)
        } else {
            temp = [...arr, id]
        }
        temp.map((inv) => {
            arr2.filter((val) => val?.common_invoice == inv)
        })

        setPrintInvoiceId(temp)
    }
    const handleScheduleCheckinChange = (id, location) => {
        var arr = scheduleInvoiceId
        var arr2 = Items
        var temp = []
        var temp2 = []
        var stop = null
        if (arr.includes(id)) {
            temp = arr.filter((val) => val != id)
        } else {
            temp = [...arr, id]
        }
        temp.map((inv) => {
            arr2.filter((val) => val?.scheduled == inv).map((value) => temp2.push(value))
        })
        if (scheduleInvoiceId.length) {
            temp2.map((val) => {
                if (val.schedule_location_id != location) {
                    stop = true
                }
            })
        }
        if (stop) {
            return setAlert('Kindly add same location invoice!', 'error')
        }
        setScheduleInvoiceId(temp)
        setScheduleLength(temp2.length)
    }

    const handleSeeVideo = (item) => {
        setMessageChecked(true)
        setBuynowId(item)
        if (parseInt(item.msg_count) > 0) {
            updateMessageCount(
                parseInt(userCountRef?.current?.messageCount),
                parseInt(userCountRef?.current?.messageCountProjects) - parseInt(item.msg_count),
            )
            item.msg_count = 0
        }
    }
    const handleInvoiceChangeupdate = (id) => {
        var arr = invoiceId
        var arr2 = Items
        var temp = []
        var temp2 = []
        if (arr.includes(id)) {
            temp = arr.filter((val) => val != id)
        } else {
            temp = [...arr, id]
        }
        temp.map((inv) => {
            arr2.filter((val) => val?.common_invoice == inv && val?.status == 'Accepted').map(
                (value) => temp2.push(value),
            )
        })
        setInvoiceId(temp)
        setCheckoutLength(temp2.length)
    }

    useEffect(() => {
        var arr = invoiceId
        var temp = []
    }, [invoiceId])

    useEffect(() => {
        if (Items.length && initial) {
            let temp_arr = []
            Items.map((val) => {
                if (val.sallertype) {
                    temp_arr.push(val.sallertype)
                }
            })
            if (temp_arr.length) getSimilarProductsQty({ id: temp_arr })
            setInitial(true)
        }
    }, [Items])
    useEffect(() => {
        if (similarQty?.length && initial) {
            let temp_pro = Items
            temp_pro.map((val) => {
                similarQty?.map((qty) => {
                    if (val.sallertype == qty.id) {
                        val.contact_seller = (
                            <Link to="/contact_us" target="_blank" rel="noopener noreferrer">
                                {qty.available_qty} Qty available. If you are still intrested,
                                Kindly contact seller.
                            </Link>
                        )
                    }
                })
            })
            setData([...temp_pro])
            console.log(temp_pro, 'temp_pro')
            setInitial(false)
        }
    }, [similarQty, Items])
    useEffect(() => {
        if (Items?.length) setSimilarQty(similar_products_qty)
    }, [similar_products_qty, Items])

    const retractBid = async (bid) => {
        // console.log('retractBid', bid)
        if (bid.mybidid && user?.id) {
            const formdata = new FormData()

            formdata.append('pid', bid.id)
            formdata.append('id', bid.mybidid)
            formdata.append('uid', user?.id)
            formdata.append('price', bid.mybid)
            formdata.append('auctionid', bid.auctionid)
            formdata.append('lotof', bid.lotof)
            formdata.append('is_buyer', true)
            // console.log('retractBid', formdata)
            const res = await axios.post(
                `${process.env.REACT_APP_BASE_URL}api_bidding/cancelbid_process`,
                formdata,
            )
            if (res.data.msg === 'yes') {
                getAllBuyerProducts(search)
                setAlert('Bid Retracted Successfully', 'success')
            } else {
                setAlert(res.data.msg, 'error')
            }
        }
    }

    useEffect(() => {
        if (global.storeConfigration?.ship_track_info?.value == 1 && props.page == 'bidwon') {
            getAllShippingAddress()
        }
    }, [])
    useEffect(() => {
        if (all_shipping_address.length) {
            setSelectAddress(all_shipping_address)
        }
    }, [all_shipping_address])
    useEffect(() => {
        if (global?.storeConfigration?.checkout_terms_and_condtions?.value == 1) {
            getStaticPage(
                {
                    page_id: 'checkout_terms_and_condtions',
                },
                1,
            )
        }
    }, [])
    useEffect(() => {
        if (global?.storeConfigration?.checkout_terms_and_condtions?.value == 1) {
            if (static_page.content) {
                setTermscondition(static_page.content)
            }
        }
    }, [static_page])

    const openshippingtracking = (data) => {
        selectAddress.map((val) => {
            if (parseInt(val.id) === parseInt(data.shipping_addr_id)) {
                setDisplayAddress({ ...val, ...data })
            }
        })
        setPopupopup(true)
    }

    const handleSearch = (event) => {
        event.preventDefault()
        setSearch({
            ...search,
            search: searchValue,
            page: 1,
        })
    }
    // useEffect(() => {
    //     console.log('99999999999999999999999999999999999999999999999999999999999', display_address)
    //     if (Object.keys(display_address).length > 0) {
    //         setPopupopup(true)
    //     }
    // }, [display_address])
    console.log(buyer_allproducts_temp, 'buyer_allproducts_temp')

    const redirect_checkout = () => {
        if (global.storeConfigration?.auction_dashboard_with_filter?.value) {
            localStorage.setItem('lotof', lotof)
        }
        if (global.pluginConfiguration?.shipping?.enable) {
            var check_hasshipping = 1,
                seller_zip = '',
                get_invoice_id = [],
                seller_zipcode = '',
                no_call_api = 1

            Items.map((data, index) => {
                if (invoiceId.includes(data.common_invoice) && parseInt(data.hasshipping)) {
                    if (data.custom_field_8) {
                        if (
                            seller_zipcode != JSON.parse(data.custom_field_8).zipcode &&
                            seller_zipcode != ''
                        ) {
                            no_call_api = 0
                        } else {
                            seller_zipcode = JSON.parse(data.custom_field_8).zipcode
                        }
                    }
                    check_hasshipping = 0
                    if (data.sell_zipcode != seller_zip && seller_zip != '') {
                        no_call_api = 0
                    } else {
                        seller_zip = data.sell_zipcode
                    }
                    if (
                        (data.weight != 0 &&
                            data.height != 0 &&
                            data.length != 0 &&
                            data.width != 0) ||
                        (data.custom_field_5 != '' &&
                            data.custom_field_6 != '' &&
                            data.custom_field_7 != '' &&
                            data.custom_field_8 != '')
                    ) {
                        get_invoice_id.push(data.common_invoice)
                    }
                }
            })
            if (check_hasshipping) {
                handleRedirectInternal(history, `checkout/auction?id=${invoiceId.join('&id=')}`)
            } else {
                if (no_call_api == 1) {
                    if (get_invoice_id.length > 0) {
                        check_get_shipping_rate_api({
                            invoiceID: get_invoice_id,
                            toPostalCode: user.zip,
                            fromPostalCode: seller_zip ? seller_zip : seller_zipcode,
                            toCountry: user.country,
                        })
                    } else {
                        handleRedirectInternal(
                            history,
                            `checkout/auction?id=${invoiceId.join('&id=')}`,
                        )
                    }
                } else {
                    setAlert(
                        'Your Selected Items Sellers Are Different.We are unable to update shippingcost.please select same sellers item and checkout',
                        'error',
                    )
                    return false
                }
            }
        } else {
            handleRedirectInternal(history, `checkout/auction?id=${invoiceId.join('&id=')}`)
        }
    }

    useEffect(() => {
        if (shipping_rate_calculation.status) {
            if (shipping_rate_calculation.status == 'success') {
                if (shipping_rate_calculation.data?.message?.shipping_error == '') {
                    handleRedirectInternal(history, `checkout/auction?id=${invoiceId.join('&id=')}`)
                } else {
                    if (shipping_rate_calculation.data?.message?.shipping_status == 500) {
                        setAlert(
                            'Product dimensional or Zip Code incorrect.Please check your Zip Code or contact seller.',
                            'error',
                        )
                    } else {
                        setAlert('Please Try Again Later!', 'error')
                    }
                }
            } else {
                setAlert('Please Try Again Later!', 'error')
            }
            clear_ship_station_amount()
        }
    }, [shipping_rate_calculation])
    const getBidText = (data) => {
        if (isAuthenticated && data.latestbid != null && user?.id != undefined) {
            if (data.auction) {
                if (
                    (data.highbid || data.highest_bidder_user_id == user?.id) &&
                    data.latestbid >= data.rprice
                ) {
                    if (data.market_status == 'open') {
                        return 'Winning'
                    } else if (data.market_status == 'sold') {
                        return 'Won'
                    }
                } else if (!data.highbid || data.highest_bidder_user_id != user?.id) {
                    if (data.market_status == 'open') {
                        return 'Outbid'
                    } else if (data.market_status == 'sold') {
                        return 'Lost'
                    }
                } else {
                    return ''
                }
            }
        }
    }

    const makemarkasrecived = (data) => {
        var send_data = {
            invoice_id: data.common_invoice,
            site_id: global?.storeDetails?.site_id ? global.storeDetails.site_id : '',
        }
        markasrecived(send_data)
    }

    const sortbyauction = (event) => {
        setLotof(event.target.value)
        var send_data = search
        send_data.lotof = parseInt(event.target.value)
        getAllBuyerProducts(send_data)
    }
    const Component = () => (
        <div className="table-responsive otherInvoice">
            <table className="table table-borderlsss">
                <tbody>
                    {Items.filter(
                        (val) => !val.scheduled && val.common_invoice !== scheduleInvoiceId[0],
                    ).length !== 0 ? (
                        Items.filter(
                            (val) => !val.scheduled && val.location_id === scheduleLocationId,
                        ).map((item, index) => {
                            return (
                                <>
                                    {item && item.common_invoice !== scheduleInvoiceId[0] ? (
                                        <>
                                            <tr key={index}>
                                                <td style={{ width: '40px' }}>
                                                    <CheckBox
                                                        checked={scheduleInvoiceId.includes(
                                                            item.common_invoice,
                                                        )}
                                                        name="schedule"
                                                        label=""
                                                        onChange={(e) =>
                                                            handleScheduleInvoiceChange(
                                                                item.common_invoice,
                                                                item.location_id,
                                                            )
                                                        }
                                                        disabled={item.scheduled}
                                                    />
                                                </td>

                                                {/* <td>
                                                                ID: {item.lotof} -
                                                                {moment(item.date_added)
                                                                    .tz(moment.tz.guess())
                                                                    .format('MM/DD/YYYY')}
                                                                , {item.city},{item.state},
                                                                {item.zipcode}{' '}
                                                                {item.auctionlot_title}
                                                            </td> */}
                                                <td
                                                    style={{ cursor: 'pointer' }}
                                                    onClick={() => {
                                                        if (showInvoice == item.common_invoice) {
                                                            setShowInvoice('')
                                                        } else {
                                                            setShowInvoice(item.common_invoice)
                                                        }
                                                    }}
                                                >
                                                    Invoice #{item.common_invoice}
                                                </td>
                                            </tr>
                                            {buyer_allproducts_temp?.results
                                                ?.filter(
                                                    (val) =>
                                                        val.common_invoice == item.common_invoice,
                                                )
                                                .map((val, index) => (
                                                    <tr
                                                        className={`show_${item.common_invoice} ${
                                                            showInvoice == item.common_invoice
                                                                ? 'tableRow'
                                                                : 'd-none'
                                                        }`}
                                                        key={index}
                                                    >
                                                        <td colSpan="2">
                                                            <ol>
                                                                <li>
                                                                    <Link
                                                                        to={`/product/${val.id}`}
                                                                        target="_blank"
                                                                        rel="noreferrer"
                                                                        className="aHref"
                                                                    >
                                                                        LOT #{val.lot_number}:{' '}
                                                                        {val.title}
                                                                    </Link>
                                                                </li>
                                                            </ol>
                                                        </td>
                                                    </tr>
                                                ))}
                                        </>
                                    ) : null}
                                </>
                            )
                        })
                    ) : (
                        <NoRecordsFound />
                    )}
                </tbody>
            </table>
        </div>
    )

    useEffect(() => {
        if (params.get('loc')) {
            setSearch({
                ...search,
                warehouse_location: params.get('loc'),
            })
        }
    }, [window.location.search])
    useEffect(() => {
        if (user) {
            socket.on('schedule_pickup_status', (data) => {
                if (data) {
                    let socket_data = data?.data.length ? data?.data[0] : {}
                    let tempItems = buyerAllProductsRef.current?.results
                    let socket_inv = tempItems.findIndex(
                        (val) => val.common_invoice == socket_data.invoice_id,
                    )
                    if (socket_inv) {
                        tempItems[socket_inv]['status'] = data.status
                    }
                    setData([...tempItems])
                }
                console.log(data, 'schedule_pickup_status+++das')
            })
        }

        return () => {
            socket.off('schedule_pickup_status', (data) => {
                return data
            })
        }
    }, [user])
    return (
        <>
            <div className="row mb-3">
                <div className="col-12 col-md-2 col-sm-6 form-group">
                    <form className="nav-standard-top-area-search">
                        <div className="nav-standard-top-area-search-inner">
                            <input
                                type="text"
                                onChange={(e) => setSearchValue(e.target.value)}
                                name="searchValue"
                                value={searchValue}
                                className="nav-standard-top-area-search-input open-sans onlyLotPlace"
                                placeholder={'Id/Title'}
                            />
                            <button
                                onClick={handleSearch}
                                className="lin lin-magnifier subLotSearch nav-standard-top-area-search-sumbit onlyLotSearch"
                                type={t('submit')}
                            >
                                <span className="material-icons">search</span>
                            </button>
                        </div>
                    </form>
                </div>

                {global?.storeConfigration?.new_schedule_pickup?.value == 1 &&
                (props.page === 'bidwon' || props.page === 'buynow') ? (
                    scheduleLength ? (
                        <div className="col-12 col-md-5 col-sm-6 ml-auto schedule-pickup-btn d-none form-group">
                            <MultiPickup
                                invoice={{
                                    site_id: global?.storeDetails?.site_id,
                                    common_invoice: scheduleInvoiceId,
                                    date_closed: date_closed,
                                    paid_date: paid_date,
                                }}
                                pickupData={{}}
                                auctionData={{
                                    location_id: Items.find(
                                        (val) => val.common_invoice === scheduleInvoiceId[0],
                                    )?.location_id,
                                }}
                                sellerData={{
                                    seller_name: global?.storeDetails?.name,
                                    email: global?.storeDetails?.contact_email,
                                }}
                                trigger={trigger}
                                setTrigger={setTrigger}
                                sch_type={props.sch_type}
                                openSchedule={openSchedule}
                                multipleInvoice={1}
                                component={<Component />}
                                clearData={() => {
                                    setScheduleInvoiceId([])
                                    setOpenSchedule(false)
                                }}
                                location_id={search.warehouse_location || locationList[0]?.id}
                            />
                            {/* <MultiPickup
                                invoice={{
                                    site_id: global?.storeDetails?.site_id,
                                    id: scheduleInvoiceId,
                                    schedule_location_id: Items.find(
                                        (val) => val.scheduled === scheduleInvoiceId[0],
                                    )?.schedule_location_id,
                                    common_invoice: Items.filter(
                                        (val) => val.scheduled === scheduleInvoiceId[0],
                                    ).map((val) => val.common_invoice),
                                    date_closed: date_closed,
                                    paid_date: paid_date,
                                }}
                                pickupData={{}}
                                auctionData={{
                                    location_id: Items.find(
                                        (val) => val.scheduled === scheduleInvoiceId[0],
                                    )?.location_id,
                                }}
                                sellerData={{
                                    seller_name: global?.storeDetails?.name,
                                    email: global?.storeDetails?.contact_email,
                                }}
                                trigger={trigger}
                                setTrigger={setTrigger}
                                sch_type={props.sch_type}
                            /> */}
                        </div>
                    ) : null
                ) : null}
                <div className="col-md-2 col-sm-4 auctionTit form-group">
                    <CustomSelect
                        label="Location"
                        value={search.warehouse_location}
                        size="small"
                        selectType="noBorder"
                        name="location"
                        onChange={(event, editor) => {
                            handleRedirectInternal(
                                history,
                                `dashboard/transactions?loc=${event.target.value}`,
                            )
                        }}
                    >
                        {global.storeDetails?.theme == 19 ? <option value="1">All</option> : ''}
                        {locationList.map((opt, optindex) => (
                            <option value={opt.id}>{opt.location_name}</option>
                        ))}
                    </CustomSelect>
                </div>
                <div className="col-md-2 col-sm-4 auctionTit form-group">
                    <CustomSelect
                        label="Auction Title"
                        value={lotof}
                        size="small"
                        selectType="noBorder"
                        name="lotof"
                        onChange={(event, editor) => sortbyauction(event)}
                    >
                        <option value={''}>All Auctions</option>
                        {auction_list.map((opt, optindex) => (
                            <option value={opt.id}>{opt.title}</option>
                        ))}
                    </CustomSelect>
                </div>
                <div className="col-md-2 col-sm-4 auctionTit form-group">
                    <CustomSelect
                        label="Paid Status"
                        value={search.paid}
                        size="small"
                        selectType="noBorder"
                        name="paid"
                        onChange={(event, editor) =>
                            setSearch({
                                ...search,
                                paid: event.target.value,
                            })
                        }
                    >
                        <option value={'all'}>All</option>
                        <option value={'paid'}>Paid</option>
                        <option value={'unpaid'}>Un Paid</option>
                    </CustomSelect>
                </div>
                <div className="col-md-2 col-sm-4 auctionTit form-group">
                    <CustomSelect
                        label="Schedule Status"
                        value={search.sch_type}
                        size="small"
                        selectType="noBorder"
                        name="schedule"
                        onChange={(event, editor) =>
                            setSearch({
                                ...search,
                                sch_type: event.target.value,
                            })
                        }
                    >
                        <option value={''}>All</option>
                        <option value={'1'}>Un Scheduled</option>
                        <option value={'2'}>Scheduled</option>
                        <option value={'4'}>Completed</option>
                    </CustomSelect>
                </div>
                <div className="col-12 col-md-2 col-sm-6 ml-auto checkout-button form-group">
                    <SecondaryButton
                        label={`Checkout (${checkoutLength})`}
                        className="mbv-btn"
                        btnSize="small"
                        onClick={() => redirect_checkout()}
                        disabled={!checkoutLength}
                    />
                </div>
            </div>

            {loading ? (
                <div className="text-center">
                    <Loader />
                </div>
            ) : Items?.length > 0 ? (
                <>
                    <div className="table-responsive">
                        {!props.gridView && (
                            <table className="table table-hover mobile-table responisveTable">
                                <thead className="hidden-mobile">
                                    <tr>
                                        {/* <th></th> */}
                                        <th className="numCell" role="id">
                                            ID
                                        </th>
                                        <th role="Title">Title</th>
                                        <th role="Items"># Items</th>
                                        <th role="Total">Total</th>
                                        <th role="Balance Due">Balance Due</th>
                                        <th role="Status">Status</th>
                                        <th role="Pickup Appointment">Pickup Appointment</th>
                                        <th role="Actions" className="text-right">
                                            Actions
                                        </th>
                                    </tr>
                                </thead>

                                <tbody>
                                    {Items.length !== 0 &&
                                        Items.map((item, index) => {
                                            return (
                                                <>
                                                    {item ? (
                                                        <tr key={index}>
                                                            {/* <td data-label="">
                                                                <CheckBox
                                                                    checked={printInvoiceId.includes(
                                                                        item.common_invoice,
                                                                    )}
                                                                    name="print"
                                                                    label=""
                                                                    onChange={(e) =>
                                                                        handlePrintInvoiceChange(
                                                                            item.common_invoice,
                                                                        )
                                                                    }
                                                                />
                                                            </td> */}
                                                            <td data-label="ID">
                                                                {item.common_invoice}
                                                            </td>
                                                            {/* <td>
                                                                ID: {item.lotof} -
                                                                {moment(item.date_added)
                                                                    .tz(moment.tz.guess())
                                                                    .format('MM/DD/YYYY')}
                                                                , {item.city},{item.state},
                                                                {item.zipcode}{' '}
                                                                {item.auctionlot_title}
                                                            </td> */}
                                                            <td data-label="Title">
                                                                {item.auctionlot_title}
                                                            </td>
                                                            <td data-label="Items">
                                                                {item.total_lots_count}
                                                            </td>

                                                            <td
                                                                data-label="Total"
                                                                className="amount "
                                                            >
                                                                {item.paid
                                                                    ? currencyFormat(
                                                                          parseFloat(
                                                                              item.total_invoice,
                                                                          ),
                                                                          props.currency,
                                                                      )
                                                                    : currencyFormat(
                                                                          parseFloat(
                                                                              item.total_wprice,
                                                                          ) +
                                                                              (parseFloat(
                                                                                  item.total_wprice,
                                                                              ) *
                                                                                  parseFloat(
                                                                                      item.buyerpremium_percentage,
                                                                                  )) /
                                                                                  100 +
                                                                              ((parseFloat(
                                                                                  item.total_wprice,
                                                                              ) +
                                                                                  (parseFloat(
                                                                                      item.total_wprice,
                                                                                  ) *
                                                                                      parseFloat(
                                                                                          item.buyerpremium_percentage,
                                                                                      )) /
                                                                                      100 +
                                                                                  parseFloat(
                                                                                      global
                                                                                          ?.storeConfigration
                                                                                          ?.line_item_fee
                                                                                          ?.value ||
                                                                                          0,
                                                                                  ) *
                                                                                      parseInt(
                                                                                          item.total_lots_count,
                                                                                      )) *
                                                                                  parseFloat(
                                                                                      user?.tax_excempt ==
                                                                                          1
                                                                                          ? 0
                                                                                          : global
                                                                                                ?.storeConfigration
                                                                                                ?.sales_tax
                                                                                                ?.value,
                                                                                  )) /
                                                                                  100 +
                                                                              parseFloat(
                                                                                  global
                                                                                      ?.storeConfigration
                                                                                      ?.line_item_fee
                                                                                      ?.value || 0,
                                                                              ) *
                                                                                  parseInt(
                                                                                      item.total_lots_count,
                                                                                  ),
                                                                          props.currency,
                                                                      )}
                                                            </td>
                                                            <td
                                                                data-label="Balance Due"
                                                                className="amount "
                                                            >
                                                                {item.paid
                                                                    ? currencyFormat(
                                                                          0,
                                                                          props.currency,
                                                                      )
                                                                    : currencyFormat(
                                                                          parseFloat(
                                                                              item.total_wprice,
                                                                          ) +
                                                                              (parseFloat(
                                                                                  item.total_wprice,
                                                                              ) *
                                                                                  parseFloat(
                                                                                      item.buyerpremium_percentage,
                                                                                  )) /
                                                                                  100 +
                                                                              ((parseFloat(
                                                                                  item.total_wprice,
                                                                              ) +
                                                                                  (parseFloat(
                                                                                      item.total_wprice,
                                                                                  ) *
                                                                                      parseFloat(
                                                                                          item.buyerpremium_percentage,
                                                                                      )) /
                                                                                      100 +
                                                                                  parseFloat(
                                                                                      global
                                                                                          ?.storeConfigration
                                                                                          ?.line_item_fee
                                                                                          ?.value ||
                                                                                          0,
                                                                                  ) *
                                                                                      parseInt(
                                                                                          item.total_lots_count,
                                                                                      )) *
                                                                                  parseFloat(
                                                                                      user?.tax_excempt ==
                                                                                          1
                                                                                          ? 0
                                                                                          : global
                                                                                                ?.storeConfigration
                                                                                                ?.sales_tax
                                                                                                ?.value,
                                                                                  )) /
                                                                                  100 +
                                                                              parseFloat(
                                                                                  global
                                                                                      ?.storeConfigration
                                                                                      ?.line_item_fee
                                                                                      ?.value || 0,
                                                                              ) *
                                                                                  parseInt(
                                                                                      item.total_lots_count,
                                                                                  ),
                                                                          props.currency,
                                                                      )}
                                                            </td>

                                                            <td data-label="Status" className="">
                                                                {parseInt(item.buynowpaid) == 0
                                                                    ? 'Unpaid'
                                                                    : 'Paid'}
                                                            </td>
                                                            <td
                                                                data-label="Pickup Appointment"
                                                                className="pickUpAppont"
                                                            >
                                                                {item.scheduled ? (
                                                                    item.status === 'completed' ? (
                                                                        `Completed on ${moment
                                                                            .utc(
                                                                                item?.sche_completed_date,
                                                                            )
                                                                            .local()
                                                                            .format(
                                                                                'dddd, MMM Do [at] h:mm A',
                                                                            )}`
                                                                    ) : (
                                                                        <>
                                                                            Scheduled on{' '}
                                                                            {moment
                                                                                .utc(
                                                                                    item?.sche_localpick_local,
                                                                                )
                                                                                .local()
                                                                                .format(
                                                                                    'dddd, MMM Do [at] h:mm A',
                                                                                )}
                                                                            <p className="text-capitalize">
                                                                                {item.status}
                                                                            </p>
                                                                        </>
                                                                    )
                                                                ) : (
                                                                    <PrimaryButton
                                                                        label={
                                                                            'Schedule Pickup Appointment'
                                                                        }
                                                                        className=""
                                                                        btnSize="smallBtnCss"
                                                                        onClick={() => {
                                                                            handleScheduleInvoiceChange(
                                                                                item.common_invoice,
                                                                                item.location_id,
                                                                            )
                                                                            setOpenSchedule(true)
                                                                        }}
                                                                        disabled={item.scheduled}
                                                                    />
                                                                )}
                                                                {/* <CheckBox
                                                                    checked={scheduleInvoiceId.includes(
                                                                        item.common_invoice,
                                                                    )}
                                                                    name="schedule"
                                                                    label=""
                                                                    onChange={(e) =>
                                                                        handleScheduleInvoiceChange(
                                                                            item.common_invoice,
                                                                            item.location_id,
                                                                        )
                                                                    }
                                                                    disabled={item.scheduled}
                                                                /> */}
                                                            </td>
                                                            <td
                                                                data-label="Actions"
                                                                className="text-right"
                                                            >
                                                                {item.buynowpaid ? (
                                                                    <button
                                                                        type="button"
                                                                        className="btn btn-link text-black box-shadow-unset"
                                                                        style={{
                                                                            boxShadow: 'unset',
                                                                        }}
                                                                        onClick={() => {
                                                                            if (
                                                                                global
                                                                                    .storeConfigration
                                                                                    ?.auction_dashboard_with_filter
                                                                                    ?.value
                                                                            ) {
                                                                                localStorage.setItem(
                                                                                    'lotof',
                                                                                    lotof,
                                                                                )
                                                                            }
                                                                            handleRedirectInternal(
                                                                                history,
                                                                                `invoice/auction?id=${item.common_invoice}`,
                                                                            )
                                                                        }}
                                                                    >
                                                                        View
                                                                    </button>
                                                                ) : (
                                                                    <div
                                                                        className="d-flex align-items-center justify-content-end"
                                                                        style={{ gap: '10px' }}
                                                                    >
                                                                        <button
                                                                            type="button"
                                                                            className="btn btn-link text-black"
                                                                            style={{
                                                                                boxShadow: 'unset',
                                                                            }}
                                                                            onClick={() => {
                                                                                if (
                                                                                    global
                                                                                        .storeConfigration
                                                                                        ?.auction_dashboard_with_filter
                                                                                        ?.value
                                                                                ) {
                                                                                    localStorage.setItem(
                                                                                        'lotof',
                                                                                        lotof,
                                                                                    )
                                                                                }
                                                                                handleRedirectInternal(
                                                                                    history,
                                                                                    `invoice/auction?id=${item.common_invoice}`,
                                                                                )
                                                                            }}
                                                                        >
                                                                            View
                                                                        </button>
                                                                        <button
                                                                            type="button"
                                                                            className="btn btn-link text-black"
                                                                            style={{
                                                                                boxShadow: 'unset',
                                                                            }}
                                                                            onClick={() =>
                                                                                handleInvoiceChange(
                                                                                    item.common_invoice,
                                                                                )
                                                                            }
                                                                        >
                                                                            {invoiceId.includes(
                                                                                item.common_invoice,
                                                                            )
                                                                                ? 'Remove'
                                                                                : 'Pay'}
                                                                        </button>
                                                                    </div>
                                                                )}
                                                            </td>
                                                        </tr>
                                                    ) : null}
                                                </>
                                            )
                                        })}
                                </tbody>
                            </table>
                        )}
                        {props.gridView && Items.length !== 0 && (
                            <div
                                className="table-responsive lineItemBidTable mt-2 sublots-table mySubmitTable"
                                id="sublot"
                            >
                                <LotListProduct
                                    listview={false}
                                    searchResult={Items}
                                    className="liquidationBuyCard"
                                    cardType={'grid'}
                                    hideQuickBid={global.storeDetails?.theme == 19 ? true : false}
                                />
                            </div>
                        )}
                    </div>
                    {props.abandoned ? (
                        <Pagination
                            count={Math.ceil(abandonedOrders.totaProducts / 10)}
                            page={abandonedOrders.page}
                            onChange={onHandlePage}
                        />
                    ) : (
                        <Pagination
                            count={Math.ceil(totalItems / search.sh_limit)}
                            page={search.page}
                            onChange={onHandlePage}
                        />
                    )}
                </>
            ) : (
                <NoRecordsFound />
            )}

            {props.sliderTheme == 'multiseller' ? (
                <MultisellerSlider
                    storeTheme={props.storeTheme}
                    selectedLot={selectedProduct}
                    handleClose={handleClose}
                    currency={props.currency}
                    sliderTheme={props.sliderTheme}
                    itemDetails={Items}
                    from={'card'}
                    getAllProducts={(e) => {
                        return true
                    }}
                />
            ) : (
                <SlidePanel
                    type="right"
                    selectedLot={selectedProduct}
                    temDetails={Items}
                    handleClose={handleClose}
                    from={'card'}
                    getAllProducts={() => {
                        return true
                    }}
                />
            )}

            <Popup
                open={popup_open}
                handleClose={(e) => setPopupopup(false)}
                size="md"
                className="save-search-modal"
                modaltitle="Shipping Info"
            >
                {Object.keys(display_address).length > 0 ? (
                    <>
                        <div className="row">
                            <div className="col-6">
                                <b>{'Shipping User Info: '}</b>
                                {display_address.first_name + ' ' + display_address.last_name}
                            </div>
                            <div className="col-6">
                                <b>{'Shipping Address: '}</b>
                                {display_address.address}
                            </div>
                        </div>
                        <br></br>
                        <div className="row">
                            <div className="col-6">
                                <b>{'Email: '}</b>
                                {display_address.email}
                            </div>
                            <div className="col-6">
                                <b>{'Tracking Number: '}</b>
                                {display_address.tracking_number
                                    ? display_address.tracking_number
                                    : '-'}
                            </div>
                        </div>
                        <br></br>
                        <div className="row">
                            <div className="col-6">
                                <b>{'Phone: '}</b>
                                {display_address.phone}
                            </div>
                        </div>
                        <br></br>
                        <div className="row">
                            <div className="col-6">
                                <b>{'Tracking Service: '}</b>
                                {global.pluginConfiguration?.shipping?.service_key
                                    ? global.pluginConfiguration.shipping.service_key
                                    : '-'}
                            </div>
                        </div>
                        <br></br>
                        <div className="row">
                            <div className="col-6">
                                <b>{'Tracking Info: '}</b>
                                {display_address.localpickup ? 'Local-Pick-Up' : 'Shipping'}
                            </div>
                        </div>
                        <br></br>
                        <div className="actionWrapper">
                            <div className="row">
                                <div className="col-3">
                                    {' '}
                                    <PrimaryButton
                                        label={`Close`}
                                        className="mbv-btn"
                                        onClick={(e) => setPopupopup(false)}
                                    />
                                </div>
                            </div>
                        </div>
                    </>
                ) : (
                    <>Loading...</>
                )}
            </Popup>
            <Popup
                open={feedbackOpen}
                handleClose={(e) => setFeedbackOpen(false)}
                size="md"
                className="save-search-modal"
                modaltitle="Feedback"
            >
                <AddFeedback
                    item={feedbackItem}
                    closePopup={() => setFeedbackOpen(false)}
                    clearSelectedItem={() => setFeedbackItem({})}
                />
            </Popup>
            <Popup
                open={additionalPayOpen}
                handleClose={(e) => setAdditionalPayOpen(false)}
                size="md"
                className="save-search-modal"
                modaltitle="Additional Pay"
            >
                <AdditionalPayment
                    item={additionalPayItem}
                    closePopup={() => setAdditionalPayOpen(false)}
                    clearSelectedItem={() => setAdditionalPayItem({})}
                    trigger={refundTrigger}
                    setTrigger={setRefundTrigger}
                />
            </Popup>
            <CustomDialog
                open={popupchecked}
                function={() => setPopupchecked(false)}
                size="md"
                className="save-search-modal"
                title="Terms and Conditions"
                closeBtn={true}
            >
                <div
                    dangerouslySetInnerHTML={{
                        __html: `${global?.storeDetails?.terms_condition}${termscondtions}<p><b>Terms and conditions accepted by the ${user?.first_name} ${user?.last_name}.</b</p>`,
                    }}
                ></div>
            </CustomDialog>
            <Popup
                open={messageChecked}
                handleClose={() => {
                    setMessageChecked(false)
                    setBuynowId({})
                }}
                size="md"
                className="save-search-modal"
                modaltitle="Upload Files"
            >
                <InvoiceMessage
                    project_id={buynowId?.common_invoice}
                    chatUser={{
                        first_name: `${buynowId?.title} -`,
                        last_name: ` ${buynowId?.common_invoice}`,
                        id: buynowId?.user_id,
                    }}
                    hide_header={1}
                    hide_message_box={1}
                />
            </Popup>
        </>
    )
}

export default InvoiceRecords
