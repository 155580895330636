import React, { useEffect, useState, useContext, useRef } from 'react'
import './home.css'
import '../AuctionSearch/searchItems.css'
import { getParameterByName } from '../../../utils/commonFunctions'
import ProductContext from '../../../Product/context/product/productContext'
import AuthContext from '../../../Product/context/auth/authContext'
import NoRecordsFound from '../../../Product/components/atoms/NoRecordsFound'
import SearchProductList from '../../../Component/ProductCard/product'
import GridProduct from '../../../Component/ProductCard/gridProduct'
import Layout from '../Layout'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import FilterPanel from '../../../Product/components/organisms/FilterPanel'
import Loaders from '../../../Product/components/molecules/Loaders'
import { Button, Drawer, SwipeableDrawer, ListItem, IconButton } from '@material-ui/core'
import clsx from 'clsx'
import { makeStyles } from '@material-ui/core/styles'
import SlidePanel from '../../../Component/SlidePanel'
import MultisellerListCard from '../../../Component/ProductCard/MultisellerListCard'
import MultisellerSlider from '../../../Component/SlidePanel/MultisellerSlider'
import CustomSelect from '../../../Product/components/atoms/Inputs/CustomSelect'
import { messageHandler } from '../../../Product/common/socketHandler'
import AlertContext from '../../../Product/context/alert/alertContext'
// import { Pagination } from '@material-ui/lab'
import { socket, socketAuction } from '../../../Product/common/socket'
import Slider from 'react-slick'
import PrimaryButton from '../../../Product/components/atoms/PrimaryButton'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import { handleRedirectInternal } from '../../../Product/common/components'
import { useHistory } from 'react-router-dom'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import HeaderSearchComponent from '../Header/HeaderSearch'
import { sustainabilityOptions } from '../../../utils'
import Timer from '../../../Product/common/timer'
import moment from 'moment'
import SlidePopup from '../../../Component/SlidePanel/SlidePopup'
import { Swiper, SwiperSlide, useSwiper, useSwiperSlide } from 'swiper/react/swiper-react'
import 'swiper/swiper-bundle.css'
import { EffectCoverflow, Navigation, Autoplay, Pagination } from 'swiper'
import { Logo } from '../../../utils/index'

const useStyles = makeStyles({
    fullList: {
        width: 'auto',
    },
})

const AuctionSearchComponent = (props) => {
    // const [cookies, setCookie] = useCookies(['user_session'])
    const iOS = process.browser && /iPad|iPhone|iPod/.test(navigator.userAgent)
    const history = useHistory()

    const productContext = useContext(ProductContext)
    const alertContext = useContext(AlertContext)
    const authContext = useContext(AuthContext)
    const [state, setState] = useState({
        right: false,
        bottom: false,
        data: {},
    })
    const {
        search_allproducts,
        getAllSearchProducts,
        search_allauctions,
        getAllSearchAuctions,
        getUpcomingList,
        upcoming_list,
    } = productContext
    const [auctionView, setAuctionView] = useState('Grid')

    const { isAuthenticated, user } = authContext
    const { setAlert } = alertContext

    const classes = useStyles()

    const [isLoading, setLoading] = useState(true)
    const [viewProduct, setViewProduct] = useState([])
    const [buyProduct, setBuyProduct] = useState([])
    const [listview, setlistview] = useState(false)
    const [totalItems, setTotalItem] = useState(0)
    const [selectedProduct, setSelectedProduct] = useState(null)
    const viewProductRef = useRef(viewProduct)
    const userRef = useRef(user)
    const [viewAuction, setViewAuction] = useState([])

    const [upcomingProducts, setUpcomingProducts] = useState([])

    const forMobile = useMediaQuery('(max-width:1024px)')

    const [upcomingView, setUpcomingview] = useState(true)

    // Sets product and user refrences on page load
    useEffect(() => {
        viewProductRef.current = viewProduct
        userRef.current = user
    })

    const handler = (message, type) => {
        messageHandler(
            message,
            viewProductRef.current,
            userRef.current,
            setAlert,
            setViewProduct,
            type,
        )
    }
    useEffect(() => {
        socket.on('realclosedupdates', (data) => {
            handler(data, 'realclosedupdates')
        })
        socket.on('bidAddtime', (data) => {
            handler(data, 'bidAddtime')
        })

        socketAuction.on('realclosedupdates', (data) => {
            handler(data, 'realclosedupdates')
        })
        socketAuction.on('bidAddtime', (data) => {
            handler(data, 'bidAddtime')
        })

        return () => {
            socket.off('realclosedupdates', (data) => {
                handler(data, 'realclosedupdates')
            })
            socket.off('bidAddtime', (data) => {
                handler(data, 'bidAddtime')
            })

            socketAuction.off('realclosedupdates', (data) => {
                handler(data, 'realclosedupdates')
            })
            socketAuction.off('bidAddtime', (data) => {
                handler(data, 'bidAddtime')
            })
        }
    }, [])

    const active_sorts = {
        sorts: {
            sortcategoryTypes: ['2', '4', '5', '7', '8'],
            sortconditionTypes: ['2', '3', '5', '6'],
            sortsubCategoryTypes: ['1', '2', '3'],
            sortlocations: ['1', '2', '3', '18', '20'],
            sortall_notifications: [],
            sortbusiness_category: [],
            sortvat_registration: [],
        },
    }
    const [searchbar] = useState(new URLSearchParams(window.location.search.substring(1)).get('q'))
    const [catg] = useState(new URLSearchParams(window.location.search.substring(1)).get('cat'))
    const params = new URLSearchParams(window.location.search.substring(1))

    const formik = useFormik({
        initialValues: {
            sh_limit: 12,
            page: 1,
            orderby: '6',
            location: [],
            state: [],
            city: [],
            category: catg ? [catg] : [],
            contenthead5: [],
            subcategory: [],
            condition: [],
            storedate: [],
            enddate: null,
            fromprice: 0,
            toprice: 0,
            totalpage: 5,
            lotof: '',
            searchbar: searchbar ? searchbar : '',
            miles: params.get('m') ? params.get('m') : '',
            from: 'auction_only',
            closed_auction_list: 0,
            view_type: 0,
            auction_io: 1,
            current: 1,
            upcoming: 0,
        },
    })

    const buyformik = useFormik({
        initialValues: {
            sh_limit: 12,
            page: 1,
            orderby: '6',
            location: [],
            state: [],
            city: [],
            category: catg ? [catg] : [],
            contenthead5: [],
            subcategory: [],
            condition: [],
            storedate: [],
            enddate: null,
            fromprice: 0,
            toprice: 0,
            totalpage: 5,
            lotof: '',
            searchbar: searchbar ? searchbar : '',
            miles: params.get('m') ? params.get('m') : '',
            from: 'buynow',
            closed_auction_list: 0,
            view_type: 0,
            auction_io: 1,
            current: 1,
            upcoming: 0,
        },
    })

    const [auctionSearch, setAuctionSearch] = useState({
        page: 1,
        sh_limit: '20',
        orderby: '2',
        searchbar: '',
        auc_type: '0',
        type: '',
        current: 1,
        upcoming: 0,
    })

    const [upcomingSearch, setUpcomingSearch] = useState({
        sh_limit: 20,
        from: 'auction_only',
        page: 1,
        orderby: '12',
        //checkupcomming: 1,
        upcoming: 1,
        // searchbar: '',
        // auc_type: '0',
        // type: '',
        // current: 0,
        // upcoming: 1,
    })

    const filterValues = [
        {
            title: 'View',
            type: 'radio',
            noLabel: true,
            name: 'view_type',
            int: 1,
            class: 'col-12',
            item: [
                { id: 0, description: 'All Items' },
                { id: 1, description: 'Single Lots' },
                { id: 2, description: 'Buy Now' },
            ],
            formik: formik,
        },

        {
            title: 'Category',
            type: 'check',
            name: 'category',
            options: global.searchCategories
                .sort((a, b) => a.description.localeCompare(b.description))
                .filter((main) => {
                    return true
                }),
            formik: formik,
        },
        // {
        //     title: 'Sustainability',
        //     type: 'check',
        //     name: 'contenthead5',
        //     options: sustainabilityOptions
        //         .map((buscat) => {
        //             let busCategoryChanged = {}
        //             busCategoryChanged.description = buscat.description
        //             busCategoryChanged.name = buscat.description
        //             busCategoryChanged.value = buscat.id
        //             busCategoryChanged.id = buscat.id
        //             busCategoryChanged.active = 1
        //             return busCategoryChanged
        //         })
        //         .sort((a, b) => a.description.localeCompare(b.description))
        //         .filter((main) => {
        //             return true
        //         }),
        //     formik: formik,
        // },

        {
            title: 'Auction Status',
            type: 'radio',
            noLabel: true,
            name: 'closed_auction_list',
            int: 1,
            class: 'col-12',
            item: [
                { id: 0, description: 'Live Items' },
                { id: 1, description: 'Closed Items' },
            ],
            formik: formik,
        },

        // {
        //     title: 'Sub category',
        //     type: 'check',
        //     name: 'subcategory',
        //     options: global.searchSubCategories.filter((subcat) => {
        //         // if (
        //         //     formik &&
        //         //     formik.values &&
        //         //     formik.values.category &&
        //         //     formik.values.category.length
        //         //         ? formik.values.category.includes(subcat.level_id.toString())
        //         //         : false
        //         // ) {
        //         return true
        //         //}
        //     }),
        //     formik: formik,
        // },
    ]

    useEffect(() => {
        setLoading(false)

        if (search_allproducts.from === 'activeAuctions') {
            //console.log('viewproduct activeAuctions results', search_allproducts?.results)
            setViewProduct(search_allproducts.results.length ? search_allproducts.results : [])
        }

        if (search_allproducts.from === 'buynowProducts') {
            //console.log('viewproduct buynowProducts results', search_allproducts?.results)
            setBuyProduct(search_allproducts.results.length ? search_allproducts.results : [])
        }

        if (search_allproducts.from === 'upcomingProducts') {
            //console.log('upcoming upcomingProducts results', search_allproducts?.results)
            setUpcomingProducts(search_allproducts.results.length ? search_allproducts.results : [])
        }
    }, [search_allproducts, formik.values])

    useEffect(() => {
        setViewProduct([])
        return () => {
            setViewProduct([])
        }
    }, [])

    useEffect(() => {
        if (upcoming_list.length) {
            setViewAuction(upcoming_list)
        }
    }, [upcoming_list])
    useEffect(() => {
        getUpcomingList({ date: new Date() })
    }, [])

    useEffect(() => {
        getAllSearchAuctions(auctionSearch, 'upcomingHeader')
    }, [auctionSearch])

    useEffect(() => {
        getAllSearchProducts(upcomingSearch, 'upcomingProducts')
    }, [upcomingSearch])

    const onHandlePage = (event, value) => {
        formik.setFieldValue('page', value)
    }
    useEffect(() => {
        setLoading(true)
        if (formik.values.closed_auction_list) {
            formik.values.orderby = '7'
        }

        // if (formik.values.view_type == 1) {
        //     formik.setFieldValue('from', 'auction_only')
        // } else if (formik.values.view_type == 2) {
        //     formik.setFieldValue('from', 'buynow')
        // } else formik.setFieldValue('from', '')

        getAllSearchProducts(formik.values, 'activeAuctions')
        getAllSearchProducts(buyformik.values, 'buynowProducts')
    }, [isAuthenticated, formik.values, buyformik.values])
    useEffect(() => {
        const params = new URLSearchParams(window.location.search.substring(1))
        formik.setFieldValue('miles', params.get('m') ? params.get('m') : '')
        formik.setFieldValue('searchbar', params.get('q') ? params.get('q') : '')
    }, [window.location.search])

    const toggleDrawer = (anchor, open, data) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return
        }
        if (open) {
            setState({ ...state, [anchor]: open, data: data })
        } else {
            setState({ ...state, [anchor]: open, data: {} })
        }
    }

    const handleClose = () => setSelectedProduct(null)

    const handleClick = async (id) => {
        if (isAuthenticated) {
            setSelectedProduct(id)
        } else {
            handleRedirectInternal(history, 'login')
        }
    }

    const pageOptions = [
        {
            value: '12',
            show: 'Results 12 per page',
        },
        {
            value: '24',
            show: 'Results 24 per page',
        },
        {
            value: '36',
            show: 'Results 36 per page',
        },
    ]

    const sortShow = [
        {
            value: '',
            show: 'None',
        },
        {
            value: 3,
            show: 'Current price low to high',
        },
        {
            value: 11,
            show: 'Current price high to low',
        },
        {
            value: 6,
            show: 'Time remaining',
        },
        {
            value: 2,
            show: 'Recently listed',
        },
    ]

    const settings = {
        dots: true,
        fade: false,
        arrows: false,
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        speed: 1000,
        autoplaySpeed: 4000,
        cssEase: 'linear',
    }
    const customHomeGot = [
        {
            name: 'slots',
            value: (
                <div className="footer-top">
                    <div className="container">
                        <h4>GOT QUESTIONS?</h4>
                        <div className="row">
                            <div className="col-md-4">
                                <h6 className="qns-head">Is every slot machine authentic?</h6>
                                <p className="qns-ans">
                                    Absolutely! All our slot machines were once located in a real
                                    casino.
                                </p>
                            </div>
                            <div className="col-md-4">
                                <h6 className="qns-head">Do you offer any warranty?</h6>
                                <p className="qns-ans">
                                    We offer a 90-day warranty on parts for all our slot machines.
                                </p>
                            </div>
                            <div className="col-md-4">
                                <h6 className="qns-head">What about delivery?</h6>
                                <p className="qns-ans">
                                    We ship to states where we can legally sell residents a real
                                    slot machine for use in their homes. Our shipments will arrive
                                    at your door and be unloaded for your convenience.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            ),
        },
    ]
    useEffect(() => {
        if (forMobile) {
            setUpcomingview(false)
        } else {
            setUpcomingview(true)
        }
    }, [forMobile])

    useEffect(() => {
        const hash = window.location.hash
        if (hash) {
            const targetDiv = document.querySelector(hash)
            if (targetDiv) {
                targetDiv.scrollIntoView({ behavior: 'smooth' })
            }
        }
    }, [])

    const customHome = [
        {
            name: 'slots',
            value: (
                <>
                    <div className="bannerContent banner-top">
                        <div className="banner-logo">
                            <Logo className="headerLogo" />
                            <div className="banner-logo-text">FOR YOUR HOME</div>
                        </div>
                        <PrimaryButton
                            label="SHOP NOW"
                            onClick={() => handleRedirectInternal(history, 'search')}
                        />
                    </div>

                    <div className="bannerContent banner-blue">
                        <h2>
                            Your home, your rules, and your jackpots! Transform your space into the
                            ultimate haven for big jackpots and slot machine entertainment. Look for
                            your favorite real slot machine from the casino at ownaslot.com
                        </h2>
                        <a
                            onClick={() =>
                                handleRedirectInternal(
                                    history,
                                    `${isAuthenticated ? 'search' : 'sign-up'}`,
                                )
                            }
                        >
                            OWNASLOT.COM
                        </a>
                    </div>
                    <div className="siteBanner banner-bottom">
                        <img
                            src={global?.storeDetails?.bannerValue}
                            alt={`${global?.storeDetails?.name} banner`}
                            // effect="blur"
                            height="100%"
                            width="100%"
                        />
                        <div className="container">
                            <div className="bb-box">
                                <h3>REAL SLOT MACHINE EXPERIENCE</h3>
                                <p>
                                    Immerse yourself in the genuine thrill of a real casino
                                    experience at home with our slot machines, directly sourced from
                                    renowned casinos worldwide. Each machine brings its distinct
                                    entertainment charm, mirroring the diverse experiences found in
                                    a casino setting. Enjoy the authentic touch of casino gaming
                                    from the comfort of your own space!
                                </p>
                            </div>
                            <div className="bb-box">
                                <h3>Refurbished Brilliance, Buy with Confidence:</h3>
                                <p>
                                    Spin confidently, knowing that our slot machines are being
                                    refurbished by industry slot experts who care as much for the
                                    machine as you do! Our thorough inspection process ensures
                                    top-quality refurbished slot machines for your enjoyment.
                                </p>
                            </div>
                        </div>
                    </div>

                    <div className="bannerContent banner-blue" id="whyUsPart">
                        <h2>Why Us?</h2>
                        <h5>
                            SlotCycle, LLC owns and operates ownaslot.com. SlotCycle works with
                            large casino operators and slot manufacturers around the world. Helping
                            to connect buyers and sellers of gaming equipment. Casinos trust us with
                            their needs related to Slot Machines. If they trust us, you should as
                            well. Shop at ownaslot.com with confidence that you receive the highest
                            quality slot machines for your home entertainment.
                        </h5>
                    </div>
                    {
                        customHomeGot.find(
                            (val) =>
                                val.name == global.storeConfigration?.custom_home_page_name?.value,
                        )?.value
                    }
                </>
            ),
        },
    ]
    return (
        <Layout props={props}>
            {
                customHome.find(
                    (val) => val.name == global.storeConfigration?.custom_home_page_name?.value,
                )?.value
            }
        </Layout>
    )
}

export default AuctionSearchComponent
