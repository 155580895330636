import React from 'react'
import Layout from '../Layout'
const ProductConditions = () => {
    return (
        <Layout>
            <div style={{ padding: '20px', lineHeight: '1.6' }}>
                <h4 style={{ fontWeight: 'bold' }}>Product Conditions:</h4>
                <div style={{ marginBottom: '20px' }}>
                    <h6 style={{ fontWeight: 'bold' }}>Brand New</h6>
                    <p>
                        Item is unused and in the original retail packaging. Packaging may be
                        damaged, but the item is guaranteed for functionality and completeness.
                        Please refer to item notes and pictures for what you will receive. If the
                        item is misrepresented, a refund or credit will be issued at the
                        manager&apos;s discretion as long as we are notified before you leave the
                        premises. No exceptions.
                    </p>
                </div>
                <div style={{ marginBottom: '20px' }}>
                    <h6 style={{ fontWeight: 'bold' }}>Appears New</h6>
                    <p>
                        Item may be open box but appears to be in new condition without any major
                        damage or defects. We do our best to examine each item for completeness and
                        functionality, but it is not guaranteed on Appears New items. Please refer
                        to item notes and pictures for what you will receive. We highly suggest
                        using auction preview times to examine the item in person before bidding. No
                        returns, credits, or exchanges on Appears New items.
                    </p>
                </div>
                <div style={{ marginBottom: '20px' }}>
                    <h6 style={{ fontWeight: 'bold' }}>Used</h6>
                    <p>
                        Item shows signs of previous use. Please see notes and pictures for
                        condition details. Although used items should be functional and complete, it
                        is not guaranteed. We highly suggest using auction preview times to examine
                        the item in person before bidding. No returns, credits, or exchanges on used
                        items.
                    </p>
                </div>
                <div style={{ marginBottom: '20px' }}>
                    <h6 style={{ fontWeight: 'bold' }}>As-Is/Damaged</h6>
                    <p>
                        Item could be damaged, missing parts, or not functional. Item has not been
                        deeply inspected and is sold as is. No returns, credits, or exchanges on
                        as-is/for parts only items. Please see notes and pictures for condition
                        details. We highly suggest using auction preview times to examine the item
                        in person before bidding.
                    </p>
                </div>
                <h4 style={{ fontWeight: 'bold' }}>Returns:</h4>
                <p>
                    We all make mistakes, so if an item is severely misrepresented, we will refund.
                    Example: A brand new item is used and broken. Buyer must notify us BEFORE
                    LEAVING the property if there is a significant discrepancy between the item
                    description and its actual condition.
                </p>
            </div>
        </Layout>
    )
}

export default ProductConditions
