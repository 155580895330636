import React, { useContext, useEffect } from 'react'
import Layout from '../Layout'
import SlidePanel from '../../../Component/LotsView'

const ProductView = () => {
    return (
        <Layout>
            <div className="pv-main-container">
                <SlidePanel getAllProducts={() => {}} viewPage={true} enableZoomOnHover={true} />
            </div>
        </Layout>
    )
}

export default ProductView
