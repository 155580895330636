import axios from 'axios'
import React, { useState } from 'react'
import AllProducts from '../../../Component/MyBids/AllProducts'

const CommonAuctionCart = (props) => {
    const cancelToken = axios.CancelToken.source()
    const [cancelRequest, setCancelRequest] = useState(undefined)
    return (
        <div>
            <AllProducts
                page="bidwon"
                currency={props.currency}
                sliderTheme={props.sliderTheme}
                shippingauction={props.shippingauction}
                storeTheme={props.storeTheme}
                checkout_but_pos={props.checkout_but_pos}
                redirect_product_view={props.redirect_product_view}
                pickupCode={props.pickupCode}
                searchbar={false}
                cancelRequest={cancelRequest}
                cancelToken={cancelToken}
                setCancelRequest={setCancelRequest}
                unpaid={1}
            />
        </div>
    )
}

export default CommonAuctionCart
