/* eslint-disable */
import React, { useEffect, useState, useContext } from 'react'
import HeaderSearch from './HeaderSearch'
import './Header.css'
import axios from 'axios'
import { Link, NavLink, useHistory, useLocation } from 'react-router-dom'
import {
    Button,
    Popover,
    ListItem,
    Divider,
    SwipeableDrawer,
    MenuItem,
    IconButton,
    Collapse,
} from '@material-ui/core'

import { withStyles } from '@material-ui/core/styles'
import MuiAccordion from '@material-ui/core/Accordion'
import MuiAccordionSummary from '@material-ui/core/AccordionSummary'
import MuiAccordionDetails from '@material-ui/core/AccordionDetails'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import { Badge } from '@material-ui/core'
import { handleRedirectInternal } from '../../../Product/common/components'
import AuthContext from '../../../Product/context/auth/authContext'
import AlertContext from '../../../Product/context/alert/alertContext'
import { useMediaQuery } from 'react-responsive'
import { FooterLogo, Logo } from '../../../utils/index'
import PrimaryButton from '../../../Product/components/atoms/PrimaryButton'
import BecomeSeller from '../../../utils/CommonFunctionality/BecomeSeller'
import CustomDialog from '../../../Product/components/organisms/Dialog'
import CartIcon from '../../../utils/CommonFunctionality/Cart/CartIcon'
import ProductContext from '../../../Product/context/product/productContext'
import Timer from '../../../Product/common/timer'
import url from 'socket.io-client-latest/lib/url'
import LoginComponent from '../Login'
import LoginFunction from '../../../utils/CommonFunctionality/LoginFunction'
import QuickSignupFunction from '../../../utils/CommonFunctionality/QuickSignup'
import ForgotPasswordFunction from '../../../utils/CommonFunctionality/ForgotPasswordFunction'
import PaymentPendingHeader from '../../../utils/CommonFunctionality/PaymentPendingHeader'
import Headernavmenu from '../../../utils/CommonFunctionality/HeaderNavigaton/headernavigation'
import GuideChimp from 'guidechimp'
import 'guidechimp/dist/guidechimp.min.css'
import CurrencyConverter from '../../../utils/CommonFunctionality/CurrencyConverter'
import LanguageManer from '../../../utils/CommonFunctionality/LanguageManager'
import { useTranslation } from 'react-i18next'
import CustomSelect from '../../../Product/components/atoms/Inputs/CustomSelect'
import CustomInput from '../../../Product/components/atoms/Inputs/CustomInput'

const Header = (props) => {
    const iOS = process.browser && /iPad|iPhone|iPod/.test(navigator.userAgent)
    const contactPhone = global?.storeDetails?.phone
    const facebookLink = global?.storeDetails?.facebook
    const twitterLink = global?.storeDetails?.twitter
    const googleLink = global?.storeDetails?.google
    const instagramLink = global?.storeDetails?.instagram
    const { t } = useTranslation()
    const location = useLocation()

    const history = useHistory()
    const [anchormenu, setanchormenu] = useState(null)
    const [auctionMenu, setAuctionMenu] = useState(null)
    const [open, setOpen] = useState(false)

    const [searchCat, setSearchCat] = useState('')

    const { user, isAuthenticated, logout, responseStatus, userCount } = useContext(AuthContext)
    const productContext = useContext(ProductContext)
    const {
        search_allauctions,
        getAllSearchAuctions,
        search_allauctionproducts,
        getLotList,
        getOverAllCategories,
        categories,
    } = productContext

    const [viewAuction, setViewAuction] = useState([])

    const [auctionSearch, setAuctionSearch] = useState({
        page: 1,
        sh_limit: '10',
        orderby: '2',
        searchbar: '',
        auc_type: '',
        type: '',
    })

    const { setAlert } = useContext(AlertContext)
    const isMobile = useMediaQuery({
        query: '(max-width: 700px)',
    })
    const isDesktop = useMediaQuery({
        query: '(min-width: 991px)',
    })
    const [state, setState] = useState({
        top: false,
        right: false,
        left: false,
    })

    const [listingAnchor, setListingAnchor] = useState(null)
    const listingOpen = Boolean(listingAnchor)

    const [userType, setUserType] = useState('')
    const [logPopup, setLogPopup] = useState(false)
    const [regPopup, setRegPopup] = useState(false)
    const [forgotOpen, setForgotPopup] = useState(false)

    const [sellerDrop, setSellerDrop] = useState(false)
    const Accordion = withStyles({
        root: {
            border: '1px solid rgba(0, 0, 0, .125)',
            boxShadow: 'none',
            '&:not(:last-child)': {
                borderBottom: 0,
            },
            '&:before': {
                display: 'none',
            },
            '&$expanded': {
                margin: 'auto',
            },
        },
        expanded: {},
    })(MuiAccordion)

    const AccordionSummary = withStyles({
        root: {
            backgroundColor: '#fff',
            marginBottom: -1,
            minHeight: 40,
            '&$expanded': {
                minHeight: 40,
            },
        },
        content: {
            '&$expanded': {
                margin: '0',
            },
        },
        expanded: {},
    })(MuiAccordionSummary)

    const AccordionDetails = withStyles((theme) => ({
        root: {
            padding: '10px 16px',
        },
    }))(MuiAccordionDetails)

    const [expanded, setExpanded] = useState('panel0')

    useEffect(() => {
        if (window.location.hostname === 'localhost') {
            axios.defaults.headers.common['domain'] = 'auction_preview.ecommerce.auction'
        }
    }, [])

    useEffect(() => {
        getAllSearchAuctions(auctionSearch, 'activeHeader')
    }, [auctionSearch])

    useEffect(() => {
        if (search_allauctions.from === 'activeHeader') {
            setViewAuction(search_allauctions.results.length ? search_allauctions.results : [])
        }
    }, [search_allauctions])

    const handleMenu = (event) => {
        setanchormenu(event.currentTarget)
    }

    const handleMenuAuction = (event) => {
        if (!user.card_profileid) {
            setAlert('Kindly add account id to continue!', 'warning')
            return handleRedirectInternal(history, 'dashboard/profile')
        }
        setAuctionMenu(event.currentTarget)
    }

    const handleClose = (history, path) => {
        setanchormenu(null)
        setAuctionMenu(null)
        if (history && path && path !== 'backdropClick') {
            handleRedirectInternal(history, path)
        }
    }

    const handleListingClick = (event) => {
        if (!user.card_profileid) {
            setAlert('Kindly add account id to continue!', 'warning')
            return handleRedirectInternal(history, 'dashboard/profile')
        }
        setListingAnchor(event.currentTarget)
    }

    const handleListingClose = () => {
        setListingAnchor(null)
    }
    const toggleOpen = () => {
        setOpen(!open)
    }
    const toggleLogOpen = () => {
        setLogPopup(!logPopup)
        if (window.location.hash.includes('#login')) {
            handleRedirectInternal(history, '')
        }
    }

    const toggleForgotOpen = () => {
        setForgotPopup(!forgotOpen)
        if (window.location.hash.includes('#forgot_password')) {
            handleRedirectInternal(history, '')
        }
    }

    const toggleRegOpen = () => {
        setRegPopup(!regPopup)
    }

    const filterValues = [
        {
            title: 'Category',
            type: 'check',
            name: 'category',
            options: global.searchCategories.filter((main) => {
                return true
            }),
        },
    ]

    const logoutUser = () => {
        logout()
        history.push('/login')
        window.location.reload()
    }

    const toggleDrawer = (anchor, open) => (event) => {
        setState({ ...state, [anchor]: open })
    }

    const handleClick = (catId) => {
        handleRedirectInternal(history, `search/product-buyer-auction/${catId}`)
    }
    const handleSellClick = (path) => {
        if (isAuthenticated) {
            // if (global.pluginConfiguration?.reseller_subscription?.enable) {
            //     if (!user?.subscribed) {
            //         setAlert('Kindly subscribe to proceed further!', 'warning')
            //         return handleRedirectInternal(history, 'dashboard/subscription')
            //     }
            // }
            if (user && user.role == 1) {
                return handleRedirectInternal(history, path)
            } else {
                return handleRedirectInternal(history, 'becomeSeller')
            }
        } else {
            return handleRedirectInternal(history, 'becomeSeller')
        }
    }

    let newUrl = new URLSearchParams(location.search)

    useEffect(() => {
        setUserType('buyer')
    }, [])

    const handleTypeChange = (type) => {
        setUserType(type)
        setOpen(false)
        setLogPopup(true)
    }

    const setSignUp = () => {
        setRegPopup(true)
        setLogPopup(false)
    }

    const setLoginPopup = () => {
        setRegPopup(false)
        setLogPopup(true)
    }

    useEffect(() => {
        if (window.location.hash.includes('#login')) {
            setLogPopup(true)
        } else if (window.location.hash.includes('#forgot_password')) {
            setForgotPopup(true)
        } else if (window.location.hash.includes('#sign-up')) {
            setRegPopup(true)
        }
    }, [window.location.pathname])

    useEffect(() => {
        if (!localStorage.getItem('newUser')) {
            setOpen(true)
            localStorage.setItem('newUser', true)
        }
    }, [])

    useEffect(() => {
        if (responseStatus) {
            if (responseStatus.from === 'login') {
                if (responseStatus.status === 'success') {
                    setLogPopup(false)
                }
            }
        }
    }, [responseStatus])

    const userGuide = [
        {
            element: '#step1',
            title: 'Welcome!',
            description:
                'To post a product click this menu button and then click on the next arrow',
            // custombuttons: [
            //     {
            //         title: 'See more',
            //         class: 'tour-button',
            //         onClick: function () {
            //             alert('Step button click')
            //         },
            //     },
            // ],
        },
        {
            element: '#step2',
            title: 'Click Post Single Lot',
            description: 'Click this post single lot option to open post listing page',
        },
    ]

    const guidechimp = new GuideChimp(userGuide)

    // guidechimp.on('onNext', toggleDrawer('left', true))

    useEffect(() => {
        if (isAuthenticated) {
            if (localStorage.getItem('guide') != 'true') {
                if (user?.role == 1) {
                    guidechimp.start()
                    localStorage.setItem('guide', 'true')
                }
            }
        }
    }, [isAuthenticated, user])

    const handleSellerDrop = () => {
        setSellerDrop(!sellerDrop)
    }

    useEffect(() => {
        getOverAllCategories({}, global?.storeConfigration?.cat_mis_match?.value)
    }, [])

    const handleCatChange = (e) => {
        history.push(`/?cat=${e.target.value}`)
    }

    const handleSellCard = () => {
        setUserType('seller')
        setRegPopup(true)
    }

    return (
        <>
            {(global?.pluginConfiguration?.currency_converter?.enable == 1 ||
                global?.pluginConfiguration?.language_manager?.enable == 1) && (
                <div className="topAdtnFtrHeader">
                    <div className="customContainer d-flex align-items-center justify-content-end">
                        {global?.pluginConfiguration?.currency_converter?.enable == 1 ? (
                            <CurrencyConverter />
                        ) : null}
                        {global?.pluginConfiguration?.language_manager?.enable == 1 ? (
                            <LanguageManer />
                        ) : null}
                    </div>
                </div>
            )}
            <header className="mainHeader noPrint">
                <div className="customContainer d-flex align-items-center h-100">
                    <div className="col-md-4 pl-0 col-4">
                        <div className="d-flex align-items-center cateGory">
                            {/* <Button
                                id="step1"
                                className="hdrMenuIcon mr-2"
                                aria-controls="simple-menu"
                                aria-haspopup="true"
                                onClick={toggleDrawer('left', true)}
                            >
                                <span className="material-icons">menu</span>
                            </Button> */}
                            <CustomSelect
                                className="mb-0"
                                name="resultsPerPage"
                                selectType="noBorder"
                                value={newUrl.get('cat') ? newUrl.get('cat') : ''}
                                size="small"
                                onChange={(e) => handleCatChange(e)}
                            >
                                <option value="">All</option>
                                {categories.length > 0 &&
                                    categories.map((data, index) => (
                                        <option value={data.id}>{data.name}</option>
                                    ))}
                            </CustomSelect>
                            {isDesktop ? (
                                <>
                                    <HeaderSearch onClose={() => setState({ top: false })} />
                                </>
                            ) : null}

                            {/* <input type="text" /> */}
                        </div>
                    </div>
                    <div className="headLt d-flex justify-content-center align-items-center col-md-3 col-3">
                        <Logo className="headerLogo" />
                    </div>

                    <div className="rtPd col-md-5 text-right d-flex align-items-center justify-content-end col-5">
                        {isMobile ? (
                            <Button onClick={toggleDrawer('top', true)}>
                                <span className="respNavBtn material-icons mr-2">search</span>
                            </Button>
                        ) : (
                            ''
                        )}
                        <Button className="respNavBtn thisCart">
                            {global.storeConfigration?.disable_buynow?.value !== '1' ? (
                                <CartIcon />
                            ) : null}
                        </Button>

                        <Button
                            className="respNavBtn mnLftNav"
                            onClick={toggleDrawer('right', true)}
                        >
                            <span className="material-icons">menu</span>
                        </Button>
                        {isMobile ? (
                            <></>
                        ) : (
                            <>
                                {isAuthenticated ? (
                                    <>
                                        {user && user.role != '1' ? (
                                            <NavLink
                                                className="pr-4 nowrap"
                                                to={'/becomeSeller?u=1'}
                                            >
                                                Become a Seller
                                            </NavLink>
                                        ) : (
                                            ''
                                        )}
                                    </>
                                ) : (
                                    ''
                                    // <>
                                    //     <a
                                    //         className="pr-4 cursorDecoy nowrap"
                                    //         onClick={() => handleSellCard()}
                                    //     >
                                    //         Sell Cards
                                    //     </a>
                                    // </>
                                )}

                                {/* <NavLink className="pr-4 nowrap" to="/gallery">
                                    Buy Now
                                </NavLink> */}
                                <Headernavmenu type="desk" />
                                <NavLink
                                    exact
                                    className="pr-4 nowrap"
                                    to={isAuthenticated ? '/' : '/login'}
                                >
                                    Buy Cards
                                </NavLink>
                                {!isAuthenticated && (
                                    <NavLink
                                        className="pr-4"
                                        to=""
                                        onClick={() => setLogPopup(true)}
                                    >
                                        {t('login')}
                                    </NavLink>
                                )}
                                {isAuthenticated && (
                                    <>
                                        {/* <div className="d-flex align-items-center justify-content-end text-capitalize">
                                            <span className="material-icons mr-2">person</span>Hi,
                                            {user?.first_name}
                                        </div> */}
                                        {global.storeConfigration?.disable_buynow?.value !== '1' ? (
                                            <CartIcon />
                                        ) : null}
                                        <Button
                                            aria-controls="simple-menu"
                                            aria-haspopup="true"
                                            className="nowrap ps-2"
                                            onClick={handleMenu}
                                            style={{ padding: '0', fontSize: '600' }}
                                        >
                                            My Account
                                            <Badge
                                                style={{ paddingLeft: '10px' }}
                                                // badgeContent={
                                                //     userCount?.itemunPaidCount
                                                //         ? userCount.itemunPaidCount
                                                //         : 0
                                                // }
                                                color="primary"
                                            >
                                                <span className="material-icons">
                                                    account_circle
                                                </span>
                                            </Badge>
                                            {/* <Badge
                                                    badgeContent={
                                                        user.unpaid_invoice_count
                                                            ? user.unpaid_invoice_count
                                                            : 0
                                                    }
                                                    color="primary"
                                                >
                                                    <ShoppingCartOutlinedIcon />
                                                </Badge>*/}
                                        </Button>

                                        <Popover
                                            id={'simple-menu'}
                                            open={Boolean(anchormenu)}
                                            anchorEl={anchormenu}
                                            onClose={handleClose}
                                            anchorOrigin={{
                                                vertical: 'bottom',
                                                horizontal: 'right',
                                            }}
                                            transformOrigin={{
                                                vertical: 'top',
                                                horizontal: 'right',
                                            }}
                                        >
                                            <ul className="headerDropMenu">
                                                <ListItem
                                                    button
                                                    onClick={() =>
                                                        handleClose(
                                                            history,
                                                            `${
                                                                user && user.role == 1
                                                                    ? 'dashboard/overview'
                                                                    : 'dashboard/mybids'
                                                            }`,
                                                        )
                                                    }
                                                >
                                                    Dashboard
                                                </ListItem>
                                                <ListItem
                                                    button
                                                    onClick={() =>
                                                        handleClose(history, 'dashboard/profile')
                                                    }
                                                >
                                                    Profile
                                                </ListItem>
                                                <ListItem button onClick={logoutUser}>
                                                    Logout
                                                </ListItem>
                                            </ul>
                                        </Popover>
                                    </>
                                )}
                            </>
                        )}
                    </div>
                </div>
            </header>

            <PaymentPendingHeader />
            {isAuthenticated && user && user.role == 1 ? (
                <>
                    {userCount.itemsoldCount > 0 && user?.card_profileid == '' ? (
                        <div className="nocardBanner">
                            <p
                                className="m-0"
                                onClick={() => handleRedirectInternal(history, 'dashboard/profile')}
                            >
                                You Have {userCount.itemsoldCount} Sold Product. To Receive Payout
                                Please Click Here To Finalize Your Seller Account Setup.
                            </p>
                        </div>
                    ) : (
                        ''
                    )}
                </>
            ) : null}

            <React.Fragment>
                <SwipeableDrawer
                    className="mnSideMenu"
                    anchor={'right'}
                    open={state['right']}
                    disableBackdropTransition={!iOS}
                    disableDiscovery={iOS}
                    disableSwipeToOpen={false}
                    onClose={toggleDrawer('right', false)}
                    onOpen={toggleDrawer('right', true)}
                >
                    <div className="catgHead">
                        <Logo className="headerLogo" />
                        <Button onClick={toggleDrawer('right', false)}>
                            <span className="material-icons">chevron_right</span>
                        </Button>
                    </div>
                    <Divider />
                    <div className="navList">
                        <ul className="navRespLinks">
                            {/* {isAuthenticated && user && isMobile && (
                                <ListItem button className="text-center prsnNmTag">
                                    <span className="material-icons mr-2">person</span>Hi,
                                    {user?.first_name}
                                </ListItem>
                            )} */}
                            {/* <ListItem button>
                                <Accordion>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls={`pane1a-content`}
                                        id={`panel1a-header`}
                                    >
                                        All
                                    </AccordionSummary>

                                    <p
                                        className="dscrpnCtnrArt"
                                    ></p>
                                    {categories.length > 0 &&
                                        categories.map((data, index) => (
                                            <AccordionDetails>
                                                <option value={data.id}>{data.name}</option>
                                            </AccordionDetails>
                                        ))}
                                </Accordion>
                            </ListItem> */}

                            <ListItem button onClick={toggleDrawer('right', false)}>
                                <NavLink activeClassName="active" to="/" exact>
                                    <span className="material-icons">home</span>
                                    {t('home')}
                                </NavLink>
                            </ListItem>
                            {isAuthenticated ? (
                                <>
                                    <ListItem button onClick={toggleDrawer('right', false)}>
                                        <NavLink
                                            activeClassName="active"
                                            to="/dashboard/profile"
                                            exact
                                        >
                                            <span className="material-icons my-main-acc">
                                                person
                                            </span>
                                            {t('my_account')}
                                        </NavLink>
                                    </ListItem>
                                    {user && user.role == 1 && (
                                        <ListItem
                                            button
                                            onClick={() =>
                                                handleClose(
                                                    history,
                                                    `${
                                                        user && user.role == 1
                                                            ? 'dashboard/overview'
                                                            : 'dashboard/mybids'
                                                    }`,
                                                )
                                            }
                                        >
                                            <NavLink to="/dashboard/overview">
                                                {' '}
                                                <span className="material-icons my-main-acc">
                                                    dashboard
                                                </span>
                                                Dashboard
                                            </NavLink>
                                        </ListItem>
                                    )}
                                    {user && user.role != '1' ? (
                                        <ListItem button onClick={toggleDrawer('right', false)}>
                                            <NavLink className="pr-4" to={'/becomeSeller?u=1'}>
                                                <span className="material-icons">sell</span>
                                                Become a Seller
                                            </NavLink>
                                        </ListItem>
                                    ) : (
                                        ''
                                    )}
                                    {/* <ListItem button>
                                        <Accordion>
                                            <AccordionSummary
                                                expandIcon={<ExpandMoreIcon />}
                                                aria-controls={`pane1a-content`}
                                                id={`panel1a-header`}
                                            >
                                                <span className="material-icons my-main-acc">
                                                    person
                                                </span>
                                                {t('my_account')}
                                            </AccordionSummary>
                                            <AccordionDetails>
                                                <p
                                                    className="dscrpnCtnrArt"
                                                ></p>
                                                <ul className="headerDropMenu">
                                                    <ListItem
                                                        button
                                                        onClick={() =>
                                                            handleClose(
                                                                history,
                                                                `${
                                                                    user && user.role == 1
                                                                        ? 'dashboard/overview'
                                                                        : 'dashboard/mybids'
                                                                }`,
                                                            )
                                                        }
                                                    >
                                                        Dashboard
                                                    </ListItem>
                                                    <ListItem
                                                        button
                                                        onClick={() =>
                                                            handleClose(
                                                                history,
                                                                'dashboard/profile',
                                                            )
                                                        }
                                                    >
                                                        Profile
                                                    </ListItem>
                                                    <ListItem button onClick={logoutUser}>
                                                        Logout
                                                    </ListItem>
                                                </ul>
                                            </AccordionDetails>
                                        </Accordion>
                                    </ListItem> */}
                                    <hr className="sdBrDvdr" />
                                    {console.log(user, 'user_role')}
                                    {user && !user.role ? (
                                        <ListItem button>
                                            <Accordion>
                                                <AccordionSummary
                                                    expandIcon={<ExpandMoreIcon />}
                                                    aria-controls={`pane1a-content`}
                                                    id={`panel1a-header`}
                                                >
                                                    <span className="material-icons my-main-acc">
                                                        shop
                                                    </span>
                                                    Buyer
                                                </AccordionSummary>
                                                <AccordionDetails>
                                                    {/* <p className="dscrpnCtnrArt"></p> */}
                                                    <ul className="headerDropMenu">
                                                        {console.log(user, 'user role')}
                                                        <ListItem
                                                            button
                                                            onClick={() =>
                                                                handleClose(
                                                                    history,
                                                                    'dashboard/mybids',
                                                                )
                                                            }
                                                            className="under"
                                                        >
                                                            <span className="material-icons my-main-acc acc-under">
                                                                gavel
                                                            </span>
                                                            My Bids
                                                        </ListItem>
                                                        <ListItem
                                                            button
                                                            onClick={() =>
                                                                handleClose(
                                                                    history,
                                                                    'dashboard/favorites',
                                                                )
                                                            }
                                                            className="under"
                                                        >
                                                            <span className="material-icons my-main-acc acc-under">
                                                                favorite
                                                            </span>
                                                            My Favorites
                                                        </ListItem>
                                                        {/*<ListItem
                                                            button
                                                            onClick={() =>
                                                                handleClose(
                                                                    history,
                                                                    'dashboard/savedSearch',
                                                                )
                                                            }
                                                            className="under"
                                                        >
                                                            <span className="material-icons my-main-acc acc-under">
                                                                bookmark
                                                            </span>
                                                            Saved Search
                                                        </ListItem>*/}
                                                        <ListItem
                                                            button
                                                            onClick={() =>
                                                                handleClose(
                                                                    history,
                                                                    'dashboard/cards',
                                                                )
                                                            }
                                                            className="under"
                                                        >
                                                            <span className="material-icons my-main-acc acc-under">
                                                                credit_card
                                                            </span>
                                                            Saved Cards
                                                        </ListItem>
                                                        <ListItem
                                                            button
                                                            onClick={() =>
                                                                handleClose(
                                                                    history,
                                                                    'dashboard/preference',
                                                                )
                                                            }
                                                            className="under"
                                                        >
                                                            <span className="material-icons my-main-acc acc-under">
                                                                notifications
                                                            </span>
                                                            Preferences
                                                        </ListItem>
                                                        <ListItem
                                                            button
                                                            onClick={() =>
                                                                handleClose(
                                                                    history,
                                                                    'dashboard/transactions',
                                                                )
                                                            }
                                                            className="under"
                                                        >
                                                            <span className="material-icons my-main-acc acc-under">
                                                                receipt
                                                            </span>
                                                            Transactions
                                                        </ListItem>
                                                        <ListItem
                                                            button
                                                            onClick={() =>
                                                                handleClose(
                                                                    history,
                                                                    'dashboard/message',
                                                                )
                                                            }
                                                            className="under"
                                                        >
                                                            <span className="material-icons my-main-acc acc-under">
                                                                question_answer
                                                            </span>
                                                            Messages
                                                        </ListItem>
                                                    </ul>
                                                </AccordionDetails>
                                            </Accordion>
                                        </ListItem>
                                    ) : (
                                        <>
                                            <ListItem button>
                                                <Accordion>
                                                    <AccordionSummary
                                                        expandIcon={<ExpandMoreIcon />}
                                                        aria-controls={`pane1a-content`}
                                                        id={`panel1a-header`}
                                                    >
                                                        <span className="material-icons my-main-acc">
                                                            shop
                                                        </span>
                                                        Buyer
                                                    </AccordionSummary>
                                                    <AccordionDetails>
                                                        {/* <p className="dscrpnCtnrArt"></p> */}
                                                        <ul className="headerDropMenu">
                                                            {console.log(user, 'user role')}
                                                            <ListItem
                                                                button
                                                                onClick={() =>
                                                                    handleClose(
                                                                        history,
                                                                        `dashboard/mybids`,
                                                                    )
                                                                }
                                                                className="under"
                                                            >
                                                                <span className="material-icons my-main-acc acc-under">
                                                                    gavel
                                                                </span>
                                                                My Bids
                                                            </ListItem>
                                                            <ListItem
                                                                button
                                                                onClick={() =>
                                                                    handleClose(
                                                                        history,
                                                                        `dashboard/favorites`,
                                                                    )
                                                                }
                                                                className="under"
                                                            >
                                                                <span className="material-icons my-main-acc acc-under">
                                                                    favorite
                                                                </span>
                                                                My Favorites
                                                            </ListItem>
                                                            {/*} <ListItem
                                                                button
                                                                onClick={() =>
                                                                    handleClose(
                                                                        history,
                                                                        `dashboard/savedSearch`,
                                                                    )
                                                                }
                                                                className="under"
                                                            >
                                                                <span className="material-icons my-main-acc acc-under">
                                                                    bookmark
                                                                </span>
                                                                Saved Search
                                                            </ListItem> */}
                                                            <ListItem
                                                                button
                                                                onClick={() =>
                                                                    handleClose(
                                                                        history,
                                                                        `dashboard/cards`,
                                                                    )
                                                                }
                                                                className="under"
                                                            >
                                                                <span className="material-icons my-main-acc acc-under">
                                                                    credit_card
                                                                </span>
                                                                Saved Cards
                                                            </ListItem>
                                                            <ListItem
                                                                button
                                                                onClick={() =>
                                                                    handleClose(
                                                                        history,
                                                                        `dashboard/preference`,
                                                                    )
                                                                }
                                                                className="under"
                                                            >
                                                                <span className="material-icons my-main-acc acc-under">
                                                                    notifications
                                                                </span>
                                                                Preferences
                                                            </ListItem>
                                                            <ListItem
                                                                button
                                                                onClick={() =>
                                                                    handleClose(
                                                                        history,
                                                                        'dashboard/transactions',
                                                                    )
                                                                }
                                                                className="under"
                                                            >
                                                                <span className="material-icons my-main-acc acc-under">
                                                                    receipt
                                                                </span>
                                                                Transactions
                                                            </ListItem>
                                                        </ul>
                                                    </AccordionDetails>
                                                </Accordion>
                                            </ListItem>
                                            {user && user.role == 1 && (
                                                <ListItem button>
                                                    <Accordion>
                                                        <AccordionSummary
                                                            expandIcon={<ExpandMoreIcon />}
                                                            aria-controls={`pane1a-content`}
                                                            id={`panel1a-header`}
                                                        >
                                                            <span className="material-icons my-main-acc">
                                                                storefront
                                                            </span>
                                                            Seller
                                                        </AccordionSummary>
                                                        <AccordionDetails>
                                                            <ul className="headerDropMenu">
                                                                {console.log(user, 'user role')}
                                                                <ListItem
                                                                    button
                                                                    onClick={() =>
                                                                        handleClose(
                                                                            history,
                                                                            'post-project?type=auction',
                                                                        )
                                                                    }
                                                                    className="under"
                                                                >
                                                                    <span className="material-icons my-main-acc acc-under">
                                                                        inventory
                                                                    </span>
                                                                    Post Single Lot
                                                                </ListItem>

                                                                <ListItem
                                                                    button
                                                                    onClick={() =>
                                                                        handleClose(
                                                                            history,
                                                                            'dashboard/mylots',
                                                                        )
                                                                    }
                                                                    className="under"
                                                                >
                                                                    <span className="material-icons my-main-acc acc-under">
                                                                        store
                                                                    </span>
                                                                    My Lots
                                                                </ListItem>
                                                                <ListItem
                                                                    button
                                                                    onClick={() =>
                                                                        handleClose(
                                                                            history,
                                                                            'dashboard/invoices',
                                                                        )
                                                                    }
                                                                    className="under"
                                                                >
                                                                    <span className="material-icons my-main-acc acc-under">
                                                                        receipt
                                                                    </span>
                                                                    Invoices
                                                                </ListItem>
                                                                <ListItem
                                                                    button
                                                                    onClick={() =>
                                                                        handleClose(
                                                                            history,
                                                                            'Payoutseller',
                                                                        )
                                                                    }
                                                                    className="under"
                                                                >
                                                                    <span className="material-icons my-main-acc acc-under">
                                                                        receipt
                                                                    </span>
                                                                    Payout
                                                                </ListItem>
                                                                <ListItem
                                                                    button
                                                                    onClick={() =>
                                                                        handleClose(
                                                                            history,
                                                                            'dashboard/reports',
                                                                        )
                                                                    }
                                                                    className="under"
                                                                >
                                                                    <span className="material-icons my-main-acc acc-under">
                                                                        description
                                                                    </span>
                                                                    Reports
                                                                </ListItem>
                                                                <ListItem
                                                                    button
                                                                    onClick={() =>
                                                                        handleClose(
                                                                            history,
                                                                            'dashboard/message',
                                                                        )
                                                                    }
                                                                    className="under"
                                                                >
                                                                    <span className="material-icons my-main-acc acc-under">
                                                                        question_answer
                                                                    </span>
                                                                    Messages
                                                                </ListItem>
                                                            </ul>
                                                        </AccordionDetails>
                                                    </Accordion>
                                                </ListItem>
                                            )}
                                        </>
                                    )}
                                </>
                            ) : null}

                            {/* <ListItem button onClick={handleSellerDrop}>
                                <a
                                    className={
                                        window.location.pathname.includes('/seller') ? 'active' : ''
                                    }
                                >
                                    <span className="material-icons">palette</span>
                                    {t('sellers')}
                                </a>
                            </ListItem>
                            <Collapse
                                className="colpsLnk"
                                in={sellerDrop}
                                timeout="auto"
                                unmountOnExit
                            >
                                <ListItem button onClick={toggleDrawer('left', false)}>
                                    <NavLink activeClassName="active" to="/seller/artist">
                                        {t('artist')}
                                    </NavLink>
                                </ListItem>
                                <ListItem button onClick={toggleDrawer('left', false)}>
                                    <NavLink activeClassName="active" to="/seller/gallery">
                                        {t('gallery')}
                                    </NavLink>
                                </ListItem>
                                <ListItem button onClick={toggleDrawer('left', false)}>
                                    <NavLink activeClassName="active" to="/seller/reseller">
                                        {t('reseller')}
                                    </NavLink>
                                </ListItem>
                            </Collapse> */}
                            {!isAuthenticated ? (
                                <>
                                    <ListItem button onClick={() => setLogPopup(true)}>
                                        <a>
                                            <span class="material-icons">login</span>
                                            {t('login')}
                                        </a>
                                    </ListItem>
                                    <ListItem button onClick={() => setRegPopup(true)}>
                                        <a>
                                            <span class="material-icons">how_to_reg</span>
                                            {t('register')}
                                        </a>
                                    </ListItem>
                                </>
                            ) : (
                                <>
                                    {/* {user && user.role == 1 && (
                                        <ListItem button onClick={toggleDrawer('left', false)}>
                                            <NavLink
                                                activeClassName="active"
                                                to="/dashboard/overview"
                                            >
                                                <span className="material-icons">dashboard</span>
                                                {t('dashboard')}
                                            </NavLink>
                                        </ListItem>
                                    )} */}
                                    {/* <hr className="sdBrDvdr" />
                                    <h4 className="sdDvdrHdr">{t('buyer')}</h4>
                                    <ListItem button onClick={toggleDrawer('left', false)}>
                                        <NavLink activeClassName="active" to="/dashboard/mybids">
                                            <span className="material-icons">gavel</span>
                                            {t('my_bid')}
                                        </NavLink>
                                    </ListItem>

                                    <ListItem button onClick={toggleDrawer('left', false)}>
                                        <NavLink activeClassName="active" to="/dashboard/favorites">
                                            <span className="material-icons">favorite</span>
                                            {t('my_fav')}
                                        </NavLink>
                                    </ListItem>

                                    <ListItem button onClick={toggleDrawer('left', false)}>
                                        <NavLink
                                            activeClassName="active"
                                            to="/dashboard/savedSearch"
                                        >
                                            <span className="material-icons">bookmark</span>
                                            {t('saved_search')}
                                        </NavLink>
                                    </ListItem>
                                    <ListItem button onClick={toggleDrawer('left', false)}>
                                        <NavLink
                                            activeClassName="active"
                                            to="/dashboard/preference"
                                        >
                                            <span className="material-icons">notifications</span>
                                            {t('preferences')}
                                        </NavLink>
                                    </ListItem>

                                    <ListItem button onClick={toggleDrawer('left', false)}>
                                        <NavLink activeClassName="active" to="/dashboard/cards">
                                            <span className="material-icons">credit_card</span>
                                            {t('saved_cards')}
                                        </NavLink>
                                    </ListItem> */}

                                    {/* {user && user.role != 1 && (
                                        <>
                                            <hr className="sdBrDvdr mb-0" />
                                            <ListItem button onClick={toggleDrawer('right', false)}>
                                                <NavLink
                                                    activeClassName="active"
                                                    to="/becomeSeller?u=1"
                                                >
                                                    <span className="material-icons">paid</span>
                                                    {t('become_a_seller')}
                                                </NavLink>
                                            </ListItem>
                                        </>
                                    )} */}

                                    {user && user.role == 1 && (
                                        <>
                                            {/* <hr className="sdBrDvdr" />
                                            <h4 className="sdDvdrHdr">{t('seller')}</h4>
                                            <ListItem
                                                button
                                                id="step2"
                                                onClick={toggleDrawer('left', false)}
                                            >
                                                <a
                                                    className={
                                                        window.location.pathname.includes(
                                                            '/post-project',
                                                        ) && newUrl.get('type') == 'auction'
                                                            ? 'active'
                                                            : 'inactive'
                                                    }
                                                    onClick={() =>
                                                        handleSellClick('post-project?type=auction')
                                                    }
                                                >
                                                    <span class="material-icons">inventory</span>
                                                    {t('post_single_lot')}
                                                </a>
                                            </ListItem>
                                            <ListItem button>
                                                <NavLink
                                                    activeClassName="active"
                                                    to="/sellerdashboard"
                                                >
                                                    <span className="material-icons">store</span>
                                                    My Auctions
                                                </NavLink>
                                            </ListItem>
                                            <ListItem button onClick={toggleDrawer('left', false)}>
                                                <NavLink
                                                    activeClassName="active"
                                                    to="/dashboard/mylots"
                                                >
                                                    <span className="material-icons">store</span>
                                                    {t('my_lots')}
                                                </NavLink>
                                            </ListItem>
                                            <ListItem button onClick={toggleDrawer('left', false)}>
                                                <NavLink
                                                    activeClassName="active"
                                                    to="/dashboard/invoices"
                                                >
                                                    <span className="material-icons">receipt</span>
                                                    {t('Invoice')}
                                                </NavLink>
                                            </ListItem>
                                            <ListItem button onClick={toggleDrawer('left', false)}>
                                                <NavLink
                                                    activeClassName="active"
                                                    to="/dashboard/reports"
                                                >
                                                    <span className="material-icons">
                                                        description
                                                    </span>
                                                    {t('reports')}
                                                </NavLink>
                                            </ListItem> */}
                                        </>
                                    )}
                                </>
                            )}

                            <hr className="sdBrDvdr" />
                            {/* <NavLink className="pr-4 nowrap" to="/gallery">
                                <span className="material-icons">shopping_basket</span>
                                Buy Now
                            </NavLink> */}
                            <Headernavmenu type="mobile" />
                            {!isAuthenticated ? (
                                <>
                                    <ListItem button onClick={toggleDrawer('right', false)}>
                                        <NavLink
                                            exact
                                            className="pr-4"
                                            to={isAuthenticated ? '/' : '/login'}
                                        >
                                            <span className="material-icons">store</span>
                                            Buy Cards
                                        </NavLink>
                                    </ListItem>
                                </>
                            ) : null}
                            <ListItem button onClick={toggleDrawer('right', false)}>
                                <NavLink activeClassName="active" to="/how_it_works">
                                    <span className="material-icons">groups</span>
                                    {t('how_it_wors')}
                                </NavLink>
                            </ListItem>
                            {global.storeDetails?.terms && (
                                <ListItem button onClick={toggleDrawer('right', false)}>
                                    <NavLink activeClassName="active" to="/faq">
                                        <span className="material-icons">quiz</span>
                                        {t('faq')}
                                    </NavLink>
                                </ListItem>
                            )}
                            {global.storeDetails?.terms_condition && (
                                <ListItem button onClick={toggleDrawer('right', false)}>
                                    <NavLink activeClassName="active" to="/termsOfService">
                                        <span className="material-icons">auto_stories</span>
                                        {t('terms_conditions')}
                                    </NavLink>
                                </ListItem>
                            )}
                            {global.storeDetails?.privacy_policy && (
                                <ListItem button onClick={toggleDrawer('right', false)}>
                                    <NavLink activeClassName="active" to="/privacy_policy">
                                        <span className="material-icons">privacy_tip</span>
                                        {t('Privacy_Policy')}
                                    </NavLink>
                                </ListItem>
                            )}

                            {global.storeDetails?.privacy && (
                                <ListItem button onClick={toggleDrawer('right', false)}>
                                    <NavLink activeClassName="active" to="/aboutus">
                                        <span class="material-icons">info</span>
                                        {t('about_us')}
                                    </NavLink>
                                </ListItem>
                            )}

                            <ListItem button onClick={toggleDrawer('right', false)}>
                                <NavLink activeClassName="active" exact to="/contact_us">
                                    <span className="material-icons">contact_support</span>
                                    {t('contact_us')}
                                </NavLink>
                            </ListItem>

                            {isAuthenticated && (
                                <ListItem button onClick={toggleDrawer('right', false)}>
                                    <a className="cursorDecoy" onClick={logoutUser}>
                                        <span className="material-icons">power_settings_new</span>
                                        {t('logout')}
                                    </a>
                                </ListItem>
                            )}
                        </ul>
                    </div>
                </SwipeableDrawer>
            </React.Fragment>
            <>
                <SwipeableDrawer
                    className="mnSideMenu"
                    anchor={'top'}
                    open={state['top']}
                    disableBackdropTransition={!iOS}
                    disableDiscovery={iOS}
                    disableSwipeToOpen={false}
                    onClose={toggleDrawer('top', false)}
                    onOpen={toggleDrawer('top', true)}
                >
                    <HeaderSearch onClose={toggleDrawer('top', false)} />
                </SwipeableDrawer>
            </>
            {/* Login Signup Popups */}

            <CustomDialog
                title={
                    <IconButton className="popClrBtn" onClick={toggleOpen}>
                        <span className="material-icons">clear</span>
                    </IconButton>
                }
                open={open}
                function={toggleOpen}
                // maxWidth={'md'}
                className="lgnWrpr"
            >
                <div className="hrToWrpr">
                    <div className="text-center">
                        <FooterLogo className="popupLgo" />
                    </div>
                    <h2 className="text-center popHr">I'm here to</h2>
                    <div className="radCntnr d-flex align-items-center">
                        <div className="popRadio">
                            <input
                                type="radio"
                                name="hereTo"
                                id="buy"
                                onChange={() => handleTypeChange('buyer')}
                            />
                            <label for="buy">
                                <img src="/assets/svg/buyIcon.svg" />
                                <span>Buy</span>
                            </label>
                        </div>
                        <div className="popRadio">
                            <input
                                type="radio"
                                name="hereTo"
                                id="sell"
                                onChange={() => handleTypeChange('seller')}
                            />
                            <label for="sell">
                                <img src="/assets/svg/sellIcon.svg" />
                                <span>Sell</span>
                            </label>
                        </div>
                    </div>
                </div>
            </CustomDialog>
            <CustomDialog
                title={
                    <IconButton className="popClrBtn" onClick={toggleLogOpen}>
                        <span className="material-icons">clear</span>
                    </IconButton>
                }
                open={logPopup}
                function={toggleLogOpen}
                // maxWidth={'md'}
                className="lgnWrpr"
            >
                <div className="hrToWrpr">
                    <div className="text-center">
                        <FooterLogo className="popupLgo" />
                    </div>
                    <h2 className="text-center popHr">
                        <span>{t('log')}</span>
                        {t('in')}
                    </h2>
                    <p className="popTgLn text-center">{t('welcome') + ' ' + t('back')}</p>
                    {/* <div className="lgnWthSclMda">
                        <p className="text-center">LOGIN WITH</p>
                        <div className="d-flex align-items-center justify-content-center">
                            <img src="/assets/svg/facebook.svg" />
                            <img src="/assets/svg/google.svg" />
                            <img src="/assets/svg/apple_login.svg" />
                        </div>
                    </div> */}
                    {/* <p className="text-center orTxt">OR</p> */}
                    <div className="lgnCntnr mt-4">
                        <LoginFunction autoComplete={0} />
                    </div>
                    <div className="newAcc mt-3 text-center">
                        <p>
                            {t('need_account')}?{' '}
                            <a className="cursorDecoy" onClick={() => setSignUp()}>
                                {t('signup')}
                            </a>
                        </p>
                    </div>
                </div>
            </CustomDialog>
            <CustomDialog
                title={
                    <IconButton className="popClrBtn" onClick={toggleForgotOpen}>
                        <span className="material-icons">clear</span>
                    </IconButton>
                }
                open={forgotOpen}
                function={toggleForgotOpen}
                // maxWidth={'md'}
                className="lgnWrpr"
            >
                <div className="hrToWrpr">
                    <div className="text-center">
                        <FooterLogo className="popupLgo" />
                    </div>
                    <h2 className="text-center popHr">
                        <span>{t('reset')}</span> &nbsp;{t('password')}
                    </h2>
                    <div className="lgnCntnr mt-4">
                        <ForgotPasswordFunction />
                    </div>
                </div>
            </CustomDialog>
            <CustomDialog
                title={
                    <IconButton className="popClrBtn" onClick={toggleRegOpen}>
                        <span className="material-icons">clear</span>
                    </IconButton>
                }
                open={regPopup}
                function={toggleRegOpen}
                className="lgnWrpr"
            >
                <div className="hrToWrpr">
                    <div className="text-center">
                        <FooterLogo className="popupLgo" />
                    </div>
                    <h2 className="text-center popHr">
                        <span>{t('sign')}</span>
                        {t('up')}
                    </h2>
                    <p className="popTgLn text-center">{t('welcome')}!</p>
                    <div className="imHrTo">
                        <p>{t('i_here_to')}</p>
                        <div className="brSlrWrp">
                            <div className="bsrIpt">
                                <input
                                    type="radio"
                                    name="regType"
                                    id="buyReg"
                                    onChange={() => setUserType('buyer')}
                                    checked={userType === 'buyer' ? true : false}
                                />
                                <label for="buyReg">{t('buy')}</label>
                            </div>
                            <div className="bsrIpt">
                                <input
                                    type="radio"
                                    name="regType"
                                    id="selReg"
                                    onChange={() => setUserType('seller')}
                                    checked={userType === 'seller' ? true : false}
                                />
                                <label for="selReg">{t('sell')}</label>
                            </div>
                        </div>
                    </div>
                    {/*<div className="lgnWthSclMda">
                        <p className="text-center">REGISTER WITH</p>
                        <div className="d-flex align-items-center justify-content-center">
                            <img src="/assets/svg/facebook.svg" />
                            <img src="/assets/svg/google.svg" />
                            <img src="/assets/svg/apple_login.svg" />
                        </div> 
                    </div>*/}
                    {/* <p className="text-center orTxt">OR</p> */}
                    <div className="lgnCntnr mt-4">
                        <QuickSignupFunction autoComplete={1} userType={userType} />
                    </div>
                    <div className="newAcc mt-3 text-center">
                        <p>
                            {t('have_account')}?{' '}
                            <a className="cursorDecoy" onClick={() => setLoginPopup()}>
                                {t('login')}
                            </a>
                        </p>
                    </div>
                </div>
            </CustomDialog>
        </>
    )
}

export default React.memo(Header)
