import React, { useEffect, useState, useContext, useRef } from 'react'
import Slider from 'react-slick'
import { LazyLoadComponent, LazyLoadImage } from 'react-lazy-load-image-component'
import axios from 'axios'
import { useMediaQuery } from 'react-responsive'
import Layout from '../Layout'
import SearchAuctionList from '../../../Component/Search/SearchAuctionList'
import ProductContext from '../../../Product/context/product/productContext'
import AuthContext from '../../../Product/context/auth/authContext'
import { Link, useHistory } from 'react-router-dom'
import NoRecordsFound from '../../../Product/components/atoms/NoRecordsFound'
import './home.css'
import SecondaryButton from '../../../Product/components/atoms/SecondaryButton'
import SearchProductList from '../../../Component/ProductCard/product'
import PrimaryButton from '../../../Product/components/atoms/PrimaryButton'
import moment from 'moment'
import { getAdsImage, handleRedirectInternal } from '../../../Product/common/components'
import AlertContext from '../../../Product/context/alert/alertContext'
import BuyerContext from '../../../Product/context/buyer/buyerContext'
import UserContext from '../../../Product/context/user/userContext'
import { useLocation } from 'react-router-dom'
import { messageHandler } from '../../../Product/common/socketHandler'
import { socket, socketAuction } from '../../../Product/common/socket'
import LotsGridAuction from '../../../Component/ProductCard/lotsAuction'
import GridBuynow from '../../../Component/ProductCard/GridBuynow'
import { Button } from '@material-ui/core'
import Loaders from '../../../Product/components/molecules/Loaders'
import CategoryModal from '../../../Component/Categories/CategoryModal'
import { KeyboardDatePicker } from '@material-ui/pickers'
import { makeStyles } from '@material-ui/core/styles'
import LotProduct from '../../../Component/ProductCard/lotProduct'

const useStyles = makeStyles({
    hiddenTextField: {
        '& .MuiInputBase-input': {
            display: 'none',
        },
    },
})

const Home = (props) => {
    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' })
    const location = useLocation()
    const params = new URLSearchParams(location.search)
    const productContext = useContext(ProductContext)
    const alertContext = useContext(AlertContext)
    const userContext = useContext(UserContext)
    const { static_page, getStaticPage } = userContext
    const {
        search_allauctionproducts,
        search_allproducts,
        search_allauctions,
        getLotList,
        getAllSearchAuctions,
        productLocalState,
        getAllSearchProducts,
        getallcatanditems,
        get_all_cat,
        getallitemcount,
        get_all_count,
        setState: setProductState,
    } = productContext
    const authContext = useContext(AuthContext)
    const { isAuthenticated, user } = authContext
    const { setAlert } = alertContext

    const history = useHistory()

    const [lotsPageLoading, setlotsPageLoading] = useState(true)
    const [galleryPageLoading, setgalleryPageLoading] = useState(true)
    const [closedHomeLoading, setclosedHomeLoading] = useState(true)
    const [activeHomeLoading, setactiveHomeLoading] = useState(true)
    const [activereverseLoading, setactiveReverseLoading] = useState(true)
    const [viewProduct, setViewProduct] = useState([])
    const [buyNowProduct, setBuyNowProduct] = useState([])
    const [activeAuctions, setActiveAuctions] = useState([])
    const [activeAuctions1, setActiveAuctions1] = useState([])
    const [reverseAuctions, setReverseAuctions] = useState([])
    const [activeHome, setActiveHome] = useState([])
    const sessionTimeOutRef = useRef(null)
    const [viewClosedAuction, setViewClosedAuction] = useState([])
    const [listview, setlistview] = useState(false)
    const [clearRequest, setClearTimeout] = useState(null)
    const [cancelRequest, setCancelRequest] = useState(undefined)
    const [selectedDate, setSelectedDate] = useState(new Date())
    const [selectedDate1, setSelectedDate1] = useState(new Date())
    const cancelToken = axios.CancelToken.source()
    const [open2, setOpen2] = useState(false)
    const [lotof, setLotof] = useState(0)
    const [ProductLocation, setProductLocation] = useState([])
    const [filterValues, setFilterValues] = useState([])
    const [product_item, setProductItem] = useState([])
    const classes = useStyles()
    const [Loading, setLoading] = useState(false)
    const [filterDate, setFilterDate] = useState(null)
    const [filterDate2, setFilterDate2] = useState(null)
    const [filterDateList, setFilterDateList] = useState([])
    const [filterDateList2, setFilterDateList2] = useState([])
    const [auctionSearch, setAuctionsearch] = useState({
        page: 1,
        sh_limit: '10',
        orderby:
            isTabletOrMobile && global?.storeConfigration?.auction_close_soon_mobile?.value
                ? global?.storeConfigration?.auction_close_soon_mobile?.value
                : global.storeConfigration?.auction_search_order?.value
                ? global.storeConfigration.auction_search_order.value
                : '2',
        searchbar: '',
        is_auction: 1,
        between_date_io: undefined,
        between_date_io_new: undefined,
        filter_from_first: 0,
        filter_from_second: 0,
        need_lot_count: 1,
        lot_images: 1,
        auction_city:
            productLocalState.globalLocation != 'All' ? [productLocalState.globalLocation] : [],
    })
    const [filterBy, setFilterBy] = useState(false)

    const [closedSearch] = useState({
        page: 1,
        sh_limit: '10',
        orderby: '1',
        type: 'closed',
        is_auction: 1,
    })

    const [productSearch] = useState({
        sh_limit: 20,
        from: 'buynow',
        page: 1,
        orderby: '1',
    })

    const [lotSearch, setLotSearch] = useState({
        sh_limit: 20,
        from: 'auction_only',
        page: 1,
        orderby: '12',
        // most_viewed: 1,
        // viewers: 1,
        featured: 1,
        auction_city:
            productLocalState.globalLocation != 'All' ? [productLocalState.globalLocation] : [],
    })

    useEffect(() => {
        setProductLocation(productLocalState.globalLocations)
    }, [productLocalState.globalLocations])

    useEffect(() => {
        if (get_all_cat.from == 'get_all_items_count') {
            if (get_all_cat.data.length > 0) {
                setFilterValues(get_all_cat.data.sort((a, b) => a.name.localeCompare(b.name)))
            }
        }
    }, [get_all_cat])

    useEffect(() => {
        if (get_all_count.from == 'get_total_items_count') {
            if (get_all_count.data.length > 0) {
                setProductItem(get_all_count.data)
            }
        }
    }, [get_all_count])

    useEffect(() => {
        if (auctionSearch.auction_city != productLocalState.globalLocation) {
            setAuctionsearch({
                ...auctionSearch,
                auction_city:
                    productLocalState.globalLocation == 'All'
                        ? []
                        : [productLocalState.globalLocation],
            })
        }
        getallcatanditems({
            site_id: global?.storeDetails?.site_id,
            lotof: 0,
            city: productLocalState.globalLocation == 'All' ? '' : productLocalState.globalLocation,
            from: 'get_all_items_count',
            status: 'open',
        })
    }, [productLocalState.globalLocation])

    const handleDateChange = (date) => {
        if (date == 'all') {
            setFilterDate(null)
            setAuctionsearch({
                ...auctionSearch,
                between_date_io: undefined,
                between_date_io_new: undefined,
                filter_from_first: 0,
                filter_from_second: 1,
            })
        } else {
            setFilterDate(date)
            setAuctionsearch({
                ...auctionSearch,
                between_date_io: date,
                between_date_io_new: undefined,
                filter_from_first: 0,
                filter_from_second: 1,
            })
            setSelectedDate(date)
        }
    }

    const handleDateChange1 = (date) => {
        if (date == 'all') {
            setFilterDate2(null)
            setAuctionsearch({
                ...auctionSearch,
                between_date_io: undefined,
                between_date_io_new: undefined,
                filter_from: 1,
                filter_from_first: 1,
                filter_from_second: 0,
            })
        } else {
            setFilterDate2(date)
            setAuctionsearch({
                ...auctionSearch,
                between_date_io: undefined,
                between_date_io_new: date,
                filter_from: 1,
                filter_from_first: 1,
                filter_from_second: 0,
            })
            setSelectedDate1(date)
        }
    }

    const handleCategory = (id) => {
        setOpen2(true)
        setLotof(id)
    }

    useEffect(() => {
        setgalleryPageLoading(true)
        // getAllSearchProducts(productSearch, 'galleryPage')
        getallitemcount({
            locations: productLocalState.globalLocations,
            site_id: global?.storeDetails?.site_id,
            from: 'get_total_items_count',
        })
    }, [productSearch, isAuthenticated])

    useEffect(() => {
        if (search_allproducts.from === 'galleryPage') {
            setBuyNowProduct(search_allproducts.results.length ? search_allproducts.results : [])
            setgalleryPageLoading(false)
        }
        if (search_allproducts.from === 'lotsPage') {
            clearTimeout(sessionTimeOutRef.current)
            setViewProduct(search_allproducts.results.length ? search_allproducts.results : [])
            if (lotSearch.most_viewed_feature && !search_allproducts.results.length) {
                setLotSearch({
                    ...lotSearch,
                    most_viewed: 1,
                    most_viewed_feature: 0,
                })
            } else {
                setlotsPageLoading(false)
            }
        }
    }, [search_allproducts])

    useEffect(() => {
        setlotsPageLoading(true)
        if (cancelRequest) {
            cancelRequest.cancel()
        }

        setCancelRequest(cancelToken)
        getAllSearchProducts(lotSearch, 'lotsPage')
        // sessionTimeOutRef.current = setTimeout(() => {
        //     getAllSearchProducts(lotSearch, 'lotsPage')
        // }, 8000)
    }, [lotSearch, isAuthenticated])

    // useEffect(() => {
    //     setclosedHomeLoading(true)
    //     if (global?.storeConfigration?.remove_recently_closed?.value != 1) {
    //         getAllSearchAuctions(closedSearch, 'closedHome')
    //     }
    // }, [closedSearch])

    useEffect(() => {
        setactiveHomeLoading(true)
        if (productLocalState.globalLocation == 'All') {
            if (!auctionSearch.filter_from_first) {
                getAllSearchAuctions(auctionSearch, 'activeHome')
            }
            if (!auctionSearch.filter_from_second) {
                getAllSearchAuctions(auctionSearch, 'activeHome1')
            }
        } else {
            getAllSearchAuctions(auctionSearch, 'activeHome')
        }

        if (global?.storeConfigration?.buy_now_home_changes?.value) {
            var body_request = auctionSearch
            body_request.is_auction = 0
            body_request.is_buynow = 1
            getAllSearchAuctions(body_request, 'activeHome_buynow')
        }

        if (global?.pluginConfiguration?.reverse_auction?.enable) {
            var body_request = auctionSearch
            body_request.auction_io_type = 1
            getAllSearchAuctions(body_request, 'reverse_auction')
        }
    }, [auctionSearch])

    useEffect(() => {
        if (search_allauctions.from === 'activeHome') {
            var activeAuctions = search_allauctions.results.length ? search_allauctions.results : []
            setActiveAuctions(
                activeAuctions.filter(
                    (data) => data.market_status == 'open' && data.remainingDay >= 0,
                ),
            )
            // setTimeout(() => {
            setactiveHomeLoading(false)
            // }, 1500)
            if (params.get('dynamic') == 1) {
                if (search_allauctions.results.length)
                    history.push(
                        `/search/product-buyer-auction/${search_allauctions.results[0]?.id}?cat=0&rat=1&est=1`,
                    )
            }

            if (search_allauctions.auction_enddate_list.length > 0) {
                var unique_date_list = search_allauctions.auction_enddate_list
                    .map((item) => {
                        const obj = Object.assign({}, item)
                        obj.format_date = moment(item.date_closed).format('YYYY-MM-DD')
                        return obj
                    })
                    .filter(
                        (value, index, self) =>
                            self.map((x) => x.format_date).indexOf(value.format_date) == index,
                    )
                    .sort(function (a, b) {
                        return new Date(a.format_date) - new Date(b.format_date)
                    })
                console.log('unique_date_list', unique_date_list)
                setFilterDateList(unique_date_list)
            }
        }
        if (search_allauctions.from === 'activeHome1') {
            var activeAuctions1 = search_allauctions.results.length
                ? search_allauctions.results
                : []

            setActiveAuctions1(
                activeAuctions1.filter(
                    (data) => data.market_status == 'open' && data.remainingDay >= 0,
                ),
            )
            // setTimeout(() => {
            setactiveHomeLoading(false)
            // }, 1500)

            if (search_allauctions.auction_enddate_list.length > 0) {
                var unique_date_list = search_allauctions.auction_enddate_list
                    .map((item) => {
                        const obj = Object.assign({}, item)
                        obj.format_date = moment(item.date_closed).format('YYYY-MM-DD')
                        return obj
                    })
                    .filter(
                        (value, index, self) =>
                            self.map((x) => x.format_date).indexOf(value.format_date) == index,
                    )
                    .sort(function (a, b) {
                        return new Date(a.format_date) - new Date(b.format_date)
                    })
                console.log('unique_date_list', unique_date_list)
                setFilterDateList2(unique_date_list)
            }
        }
        if (search_allauctions.from === 'reverse_auction') {
            setReverseAuctions(search_allauctions.results.length ? search_allauctions.results : [])
            setactiveReverseLoading(false)
        }

        if (search_allauctions.from === 'closedHome') {
            setViewClosedAuction(
                search_allauctions.results.length ? search_allauctions.results : [],
            )
            setclosedHomeLoading(false)
        }

        if (search_allauctions.from == 'activeHome_buynow') {
            setActiveHome(search_allauctions.results.length ? search_allauctions.results : [])
        }
    }, [search_allauctions])

    const viewProductRef = useRef(viewProduct)
    const userRef = useRef(user)

    // Sets product and user refrences on page load
    useEffect(() => {
        viewProductRef.current = viewProduct
        userRef.current = user
    })

    const handler = (message, type) => {
        messageHandler(
            message,
            viewProductRef.current,
            userRef.current,
            setAlert,
            setViewProduct,
            type,
        )
    }

    const handleLocationChange = (locationValue) => {
        setLoading(true)
        localStorage.setItem('globalLocation', locationValue)
        setProductState({
            ...productLocalState,
            globalLocation: locationValue,
        })

        setTimeout(() => {
            setLoading(false)
            handleRedirectInternal(history, `auctions`)
        }, 1000)
    }

    useEffect(() => {
        socket.on('realclosedupdates', (data) => {
            handler(data, 'realclosedupdates')
        })
        socket.on('bidAddtime', (data) => {
            handler(data, 'bidAddtime')
        })

        socketAuction.on('bidAddtime', (data) => {
            handler(data, 'bidAddtime')
        })

        return () => {
            socket.off('realclosedupdates', (data) => {
                handler(data, 'realclosedupdates')
            })
            socket.off('bidAddtime', (data) => {
                handler(data, 'bidAddtime')
            })

            socketAuction.off('bidAddtime', (data) => {
                handler(data, 'bidAddtime')
            })
        }
    }, [])

    useEffect(() => {
        if (global?.storeConfigration?.liq_home_page_banner_changes?.value == 1) {
            getStaticPage(
                {
                    page_id: 'home_banner_text',
                },
                1,
            )
        }
    }, [])

    const settings = {
        dots: true,
        fade: false,
        arrows: false,
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        speed: 1000,
        autoplaySpeed: 4000,
        cssEase: 'linear',
    }

    const settings1 = {
        dots: false,
        fade: false,
        arrows: true,
        infinite: false,
        slidesToShow: 6,
        slidesToScroll: 1,
        initialSlide: 0,
        autoplay: false,
        speed: 1000,
        autoplaySpeed: 4000,
        responsive: [
            {
                breakpoint: 1200,
                settings: {
                    slidesToShow: 6,
                },
            },
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 4,
                },
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 3,
                },
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 2,
                },
            },
        ],
    }

    const settings2 = {
        dots: false,
        fade: false,
        arrows: true,
        infinite: false,
        slidesToShow: 3,
        slidesToScroll: 1,
        autoplay: false,
        speed: 1000,
        autoplaySpeed: 4000,
        cssEase: 'linear',
        responsive: [
            {
                breakpoint: 1200,
                settings: {
                    slidesToShow: 3,
                },
            },
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                },
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 2,
                },
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                },
            },
        ],
    }
    const settings3 = {
        dots: false,
        fade: false,
        arrows: true,
        infinite: false,
        slidesToShow: 4,
        slidesToScroll: 1,
        autoplay: false,
        speed: 1000,
        autoplaySpeed: 4000,
        cssEase: 'linear',
        responsive: [
            {
                breakpoint: 1200,
                settings: {
                    slidesToShow: 4,
                },
            },
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 4,
                },
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 2,
                },
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                },
            },
        ],
    }
    const imageChangeInterval = global?.pluginConfiguration?.advertisement?.refresh_key * 60000 // Convert minutes to milliseconds

    const isMobile = useMediaQuery({
        query: '(max-width: 767px)',
    })

    const [homeAds, setHomeAds] = useState({
        deskHomeTop: '',
        deskHomeMid: '',
        mobHomeTop: '',
        mobHomeMid: '',
        deskHomeBottom: '',
        mobHomeBottom: '',
    })

    const [activeHomeAdsIndex, setActiveHomeAdsIndex] = useState({
        deskHomeTop: 0,
        deskHomeMid: 0,
        mobHomeTop: 0,
        mobHomeMid: 0,
        deskHomeBottom: 0,
        mobHomeBottom: 0,
    })

    function updateRandomIndex(array) {
        const randomIndex = Math.floor(Math.random() * array.length)
        return randomIndex
    }

    useEffect(() => {
        setHomeAds((prevAucAds) => ({
            deskHomeTop: getAdsImage(1, 1, 1),
            deskHomeMid: getAdsImage(1, 1, 2),
            mobHomeTop: getAdsImage(2, 1, 1),
            mobHomeMid: getAdsImage(2, 1, 2),
            deskHomeBottom: getAdsImage(1, 1, 5),
            mobHomeBottom: getAdsImage(2, 1, 5),
        }))
    }, [])

    useEffect(() => {
        setActiveHomeAdsIndex((prevActiveIndex) => ({
            deskHomeTop: updateRandomIndex(homeAds?.deskHomeTop),
            deskHomeMid: updateRandomIndex(homeAds?.deskHomeMid),
            mobHomeTop: updateRandomIndex(homeAds?.mobHomeTop),
            mobHomeMid: updateRandomIndex(homeAds?.mobHomeMid),
            deskHomeBottom: updateRandomIndex(homeAds?.deskHomeBottom),
            mobHomeBottom: updateRandomIndex(homeAds?.mobHomeBottom),
        }))
    }, [homeAds])

    return (
        <Layout props={props}>
            <div className="home loa-home-wrapper">
                {global?.storeConfigration?.liq_home_page_banner_changes?.value == 1 ? (
                    <div className="siteBanner multiple">
                        <div
                            dangerouslySetInnerHTML={{
                                __html: static_page.content,
                            }}
                        ></div>
                    </div>
                ) : global.multipleBannersFields.length ? (
                    <div className="siteBanner multiple">
                        {
                            <Slider {...settings}>
                                {global.multipleBannersFields?.map((data, index) => (
                                    <div className="banner-item" key={index}>
                                        <img
                                            src={
                                                process.env.REACT_APP_AUCTION_IMAGE_URL +
                                                '/' +
                                                data?.image_name
                                            }
                                        />
                                        <div className="bannerContent">
                                            <h2 className="">
                                                {JSON.parse(data.fields ? data.fields : '{}').title}
                                            </h2>
                                            <p className="pb-4">
                                                {
                                                    JSON.parse(data.fields ? data.fields : '{}')
                                                        .description
                                                }
                                            </p>
                                            <div className="banner-btns-wrap">
                                                {JSON.parse(data.fields ? data.fields : '{}')
                                                    .btn_text?.length > 0 && (
                                                    <PrimaryButton
                                                        label={
                                                            JSON.parse(
                                                                data.fields ? data.fields : '{}',
                                                            ).btn_text
                                                        }
                                                        type="button"
                                                        disabled={Loading}
                                                        onClick={() =>
                                                            handleLocationChange(
                                                                productLocalState
                                                                    .globalLocations[0],
                                                            )
                                                        }
                                                    />
                                                )}
                                                {!isAuthenticated && (
                                                    <SecondaryButton
                                                        label="Register Now"
                                                        type="button"
                                                        onClick={() =>
                                                            handleRedirectInternal(
                                                                history,
                                                                'sign-up',
                                                            )
                                                        }
                                                    />
                                                )}
                                            </div>
                                        </div>
                                        {activeAuctions.length > 0 ? (
                                            <div className="new-auction">
                                                New Auctions on{' '}
                                                {moment(activeAuctions[0].date_added).format(
                                                    'dddd',
                                                )}{' '}
                                                {moment(activeAuctions[0].date_added).format(
                                                    'MMMM Do YYYY, h:mm A',
                                                )}{' '}
                                                <Link
                                                    to={`/search/product-buyer-auction/${activeAuctions[0].id}?cat=0&rat=1&est=1`}
                                                >
                                                    View Now
                                                </Link>
                                            </div>
                                        ) : (
                                            ''
                                        )}
                                    </div>
                                ))}
                            </Slider>
                        }
                    </div>
                ) : (
                    <div className="siteBanner">
                        <LazyLoadImage
                            src={global?.storeDetails?.bannerValue}
                            alt={`${global?.storeDetails?.name} banner`}
                            effect="blur"
                            height="100%"
                            width="100%"
                        />
                        <div className="bannerContent">
                            <h2>{global?.storeDetails?.description}</h2>
                            <PrimaryButton
                                label="Explore Now"
                                disabled={Loading}
                                onClick={() =>
                                    handleLocationChange(productLocalState.globalLocations[0])
                                }
                            />
                        </div>
                    </div>
                )}
                {/* <div className="siteBanner">
                    <img
                        src={global?.storeDetails?.bannerValue}
                        alt={`${global?.storeDetails?.name} banner`}
                    />
                    <div className="bannerContent">
                        <h2>{global?.storeDetails?.name}</h2>
                        <p>{global?.storeDetails?.description}</p>
                        <SecondaryButton
                            label="Get Started"
                            onClick={() =>
                                handleRedirectInternal(
                                    history,
                                    `${isAuthenticated ? 'search' : 'sign-up'}`,
                                )
                            }
                        />
                    </div>
                </div> */}

                <div className="container auctionListCnt">
                    {global?.pluginConfiguration?.advertisement?.enable == 1 ? (
                        <div className="siteAds adsHome">
                            {isMobile
                                ? homeAds?.mobHomeTop[activeHomeAdsIndex?.mobHomeTop]
                                : homeAds?.deskHomeTop[activeHomeAdsIndex?.deskHomeTop]}
                        </div>
                    ) : null}

                    <div className="shop-by-categories">
                        <h2 className="auctionListTitle">Shop By Category</h2>
                        {filterValues.length > 0 && (
                            <Slider {...settings1}>
                                {filterValues.map((data, index) => (
                                    <Link
                                        to={
                                            data?.category_id
                                                ? `/search?q=&cat=${data.category_id}&est=0&rat=0&view=0&sort=6&slimit=50`
                                                : '/search'
                                        }
                                    >
                                        <div className="sc-card" key={index}>
                                            <div className="sc-img-box">
                                                <img
                                                    src={
                                                        data.image
                                                            ? process.env
                                                                  .REACT_APP_PRODUCT_IMAGE_URL +
                                                              data.cat_image
                                                            : process.env
                                                                  .REACT_APP_AUCTION_IMAGE_URL +
                                                              global?.storeDetails?.logo
                                                    }
                                                    data-src="image.jpg"
                                                    onError={(e) => {
                                                        e.target.src = '/lots-images/Logo.png'
                                                    }}
                                                    alt={data.name}
                                                />
                                            </div>

                                            <p className="sc-label">{`${data.name} (${data.product_cnt})`}</p>
                                        </div>
                                    </Link>
                                ))}
                            </Slider>
                        )}
                    </div>
                </div>
                {viewProduct.length > 0 && (
                    <div className="container auctionListCnt">
                        <div>
                            {lotsPageLoading ? (
                                <Loaders
                                    name="product_grid_view"
                                    isLoading={lotsPageLoading}
                                    loop={4}
                                />
                            ) : (
                                <div className="search-wrapper">
                                    <h2 className="auctionListTitle">Featured Lots</h2>
                                    {viewProduct.length > 0 ? (
                                        <>
                                            <div className="gridContainer liquidationAuctions featuredLots">
                                                <Slider {...settings3}>
                                                    {viewProduct.map((item, index) => (
                                                        <LazyLoadComponent key={index}>
                                                            <div
                                                                className={'liquidationBuyCard'}
                                                                key={`gl-${index}`}
                                                            >
                                                                <>
                                                                    <LotProduct
                                                                        data={item}
                                                                        lotImages={item.lotImages}
                                                                        link={`/gallery/product-view/${item.id}`}
                                                                        handleClick={() =>
                                                                            handleRedirectInternal(
                                                                                history,
                                                                                `product/${id}?cat=`,
                                                                            )
                                                                        }
                                                                        timerTheme={
                                                                            props.timerTheme
                                                                        }
                                                                        type={
                                                                            props.type
                                                                                ? props.type
                                                                                : ''
                                                                        }
                                                                        cardTheme={props.cardTheme}
                                                                        noTimer={props.noTimer}
                                                                        getAllProducts={
                                                                            getAllSearchProducts
                                                                        }
                                                                        setViewProduct={
                                                                            setViewProduct
                                                                        }
                                                                        viewProduct={viewProduct}
                                                                        audio={props.audio}
                                                                        productViewLink={`/product/${item.id}?cat=`}
                                                                        bidStatus="true"
                                                                        timerIcon={true}
                                                                        hideQuickBid={
                                                                            props.hideQuickBid
                                                                                ? props.hideQuickBid
                                                                                : false
                                                                        }
                                                                    />
                                                                </>
                                                            </div>
                                                        </LazyLoadComponent>
                                                    ))}
                                                </Slider>
                                            </div>
                                        </>
                                    ) : (
                                        <div className="card card-body text-center">
                                            No items Found!
                                        </div>
                                    )}
                                </div>
                            )}
                        </div>
                    </div>
                )}
                <div className="we-at-wrap">
                    <div className="container">
                        <h2 className="wc-head">We Are in Two Cities!</h2>
                        <div className="row">
                            <div className="col-sm-6">
                                <div className="we-at-card">
                                    <img
                                        src="/lots-images/dallas.png"
                                        alt=""
                                        className="we-at-card-img"
                                    />
                                    <h4 className="we-at-card-head">Dallas, Texas</h4>
                                    <p className="we-at-card-descp">2832 Anode Ln, Dallas, TX</p>
                                    <p className="we-at-card-descp">
                                        <strong>
                                            {product_item.filter(
                                                (val) =>
                                                    val.name ==
                                                    productLocalState.globalLocations[1],
                                            ).length > 0
                                                ? product_item.filter(
                                                      (val) =>
                                                          val.name ==
                                                          productLocalState.globalLocations[1],
                                                  )[0].count
                                                : 0}{' '}
                                            Items
                                        </strong>
                                    </p>
                                    <PrimaryButton
                                        label="Explore"
                                        disabled={Loading}
                                        type="button"
                                        onClick={() =>
                                            handleLocationChange(
                                                productLocalState.globalLocations[1],
                                            )
                                        }
                                    />
                                </div>
                            </div>
                            <div className="col-sm-6">
                                <div className="we-at-card">
                                    <img
                                        src="/lots-images/denton.png"
                                        alt=""
                                        className="we-at-card-img"
                                    />
                                    <h4 className="we-at-card-head">Denton, Texas</h4>
                                    <p className="we-at-card-descp">
                                        Denton 4801 W University Dr Suite 104, Denton, TX
                                    </p>
                                    <p className="we-at-card-descp">
                                        <strong>
                                            {product_item.filter(
                                                (val) =>
                                                    val.name ==
                                                    productLocalState.globalLocations[2],
                                            ).length > 0
                                                ? product_item.filter(
                                                      (val) =>
                                                          val.name ==
                                                          productLocalState.globalLocations[2],
                                                  )[0].count
                                                : 0}{' '}
                                            Items
                                        </strong>
                                    </p>
                                    <PrimaryButton
                                        label="Explore"
                                        disabled={Loading}
                                        type="button"
                                        onClick={() =>
                                            handleLocationChange(
                                                productLocalState.globalLocations[2],
                                            )
                                        }
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container auctionListCnt">
                    {productLocalState.globalLocation == 'All' ? (
                        <>
                            <div>
                                {activeHomeLoading ? (
                                    <Loaders
                                        name="product_grid_view"
                                        isLoading={activeHomeLoading}
                                        loop={5}
                                    />
                                ) : (
                                    <div className="search-wrapper">
                                        <h2 className="auctionListTitle">
                                            {isTabletOrMobile &&
                                            global?.storeConfigration?.auction_close_soon_mobile
                                                ?.value
                                                ? 'Current Auctions'
                                                : `Featured Auctions in ${productLocalState.globalLocations[1]}`}
                                        </h2>
                                        <div className="home-fa-filters">
                                            <ul>
                                                <li>
                                                    <Button
                                                        className={
                                                            filterDate == null
                                                                ? 'active'
                                                                : 'dateFilter'
                                                        }
                                                        onClick={() => handleDateChange('all')}
                                                    >
                                                        All
                                                    </Button>
                                                </li>
                                                {filterDateList.filter(
                                                    (val) =>
                                                        val.city ===
                                                        productLocalState.globalLocations[1],
                                                ).length > 0 ? (
                                                    filterDateList
                                                        .filter(
                                                            (val) =>
                                                                val.city ===
                                                                productLocalState
                                                                    .globalLocations[1],
                                                        )
                                                        .map((data, index) => (
                                                            <li>
                                                                <Button
                                                                    className={
                                                                        filterDate ==
                                                                        moment(
                                                                            data.format_date,
                                                                        ).format('YYYY-MM-DD')
                                                                            ? 'active'
                                                                            : 'dateFilter'
                                                                    }
                                                                    onClick={() =>
                                                                        handleDateChange(
                                                                            moment(
                                                                                data.format_date,
                                                                            ).format('YYYY-MM-DD'),
                                                                        )
                                                                    }
                                                                >
                                                                    {moment(
                                                                        data.format_date,
                                                                    ).format('MMMM D (ddd)')}
                                                                </Button>
                                                            </li>
                                                        ))
                                                ) : (
                                                    <>
                                                        <li>
                                                            <Button
                                                                className={
                                                                    filterDate ==
                                                                    moment().format('YYYY-MM-DD')
                                                                        ? 'active'
                                                                        : 'dateFilter'
                                                                }
                                                                onClick={() =>
                                                                    handleDateChange(
                                                                        moment().format(
                                                                            'YYYY-MM-DD',
                                                                        ),
                                                                    )
                                                                }
                                                            >
                                                                {moment().format('MMMM D (ddd)')}
                                                            </Button>
                                                        </li>
                                                        <li>
                                                            <Button
                                                                className={
                                                                    filterDate ==
                                                                    moment()
                                                                        .add(1, 'days')
                                                                        .format('YYYY-MM-DD')
                                                                        ? 'active'
                                                                        : 'dateFilter'
                                                                }
                                                                onClick={() =>
                                                                    handleDateChange(
                                                                        moment()
                                                                            .add(1, 'days')
                                                                            .format('YYYY-MM-DD'),
                                                                    )
                                                                }
                                                            >
                                                                {moment()
                                                                    .add(1, 'days')
                                                                    .format('MMMM D (ddd)')}
                                                            </Button>
                                                        </li>
                                                        <li>
                                                            <Button
                                                                className={
                                                                    filterDate ==
                                                                    moment()
                                                                        .add(2, 'days')
                                                                        .format('YYYY-MM-DD')
                                                                        ? 'active'
                                                                        : 'dateFilter'
                                                                }
                                                                onClick={() =>
                                                                    handleDateChange(
                                                                        moment()
                                                                            .add(2, 'days')
                                                                            .format('YYYY-MM-DD'),
                                                                    )
                                                                }
                                                            >
                                                                {moment()
                                                                    .add(2, 'days')
                                                                    .format('MMMM D (ddd)')}
                                                            </Button>
                                                        </li>
                                                        <li>
                                                            <Button
                                                                className={
                                                                    filterDate ==
                                                                    moment()
                                                                        .add(3, 'days')
                                                                        .format('YYYY-MM-DD')
                                                                        ? 'active'
                                                                        : 'dateFilter'
                                                                }
                                                                onClick={() =>
                                                                    handleDateChange(
                                                                        moment()
                                                                            .add(3, 'days')
                                                                            .format('YYYY-MM-DD'),
                                                                    )
                                                                }
                                                            >
                                                                {moment()
                                                                    .add(3, 'days')
                                                                    .format('MMMM D (ddd)')}
                                                            </Button>
                                                        </li>
                                                    </>
                                                )}

                                                {auctionSearch.between_date_io != 'all' ? (
                                                    <li>
                                                        <KeyboardDatePicker
                                                            margin="0"
                                                            id="fa-auction-date"
                                                            inputVariant="outlined"
                                                            label=""
                                                            format="YYYY-MM-DD"
                                                            className={`selectDate ${
                                                                // auctionSearch.between_date_io
                                                                'customDatepicker'
                                                                // : classes.hiddenTextField
                                                            }`}
                                                            value={selectedDate}
                                                            onChange={handleDateChange}
                                                            placeholder="Select Date"
                                                            KeyboardButtonProps={{
                                                                'aria-label': 'change date',
                                                            }}
                                                            keyboardIcon={
                                                                <>
                                                                    Select Date
                                                                    <span className="material-icons-outlined">
                                                                        arrow_drop_down
                                                                    </span>
                                                                </>
                                                            }
                                                        />
                                                    </li>
                                                ) : (
                                                    ''
                                                )}
                                            </ul>
                                            {!isMobile && (
                                                <Button
                                                    className="viewAll"
                                                    onClick={() =>
                                                        handleRedirectInternal(history, `auctions`)
                                                    }
                                                >
                                                    View All
                                                </Button>
                                            )}
                                        </div>
                                        {activeAuctions.filter(
                                            (val) =>
                                                val.city === productLocalState.globalLocations[1],
                                        ).length > 0 ? (
                                            <>
                                                <div className="gridContainer liquidationAuctions">
                                                    {console.log(activeAuctions, 'activeAuctions')}
                                                    <Slider {...settings2}>
                                                        {activeAuctions
                                                            .filter(
                                                                (val) =>
                                                                    val.city ===
                                                                    productLocalState
                                                                        .globalLocations[1],
                                                            )
                                                            .map((data, index) => (
                                                                <LotsGridAuction
                                                                    key={index}
                                                                    data={data}
                                                                    details={`search/product-buyer-auction/${data.id}?cat=0&rat=1&est=1`}
                                                                    timerTheme="multiseller"
                                                                    theme="multiseller"
                                                                    openCategory={handleCategory}
                                                                />
                                                            ))}
                                                    </Slider>
                                                </div>
                                                {isMobile && (
                                                    <div className="home-fa-filters">
                                                        <Button
                                                            className="viewAll"
                                                            onClick={() =>
                                                                handleRedirectInternal(
                                                                    history,
                                                                    `auctions`,
                                                                )
                                                            }
                                                        >
                                                            View All
                                                        </Button>
                                                    </div>
                                                )}
                                            </>
                                        ) : (
                                            <div className="card card-body text-center">
                                                No items Found!
                                            </div>
                                        )}
                                    </div>
                                )}
                            </div>

                            <div>
                                <>
                                    {activeHomeLoading ? (
                                        <Loaders
                                            name="product_grid_view"
                                            isLoading={activeHomeLoading}
                                            loop={5}
                                        />
                                    ) : (
                                        <div className="search-wrapper">
                                            <h2 className="auctionListTitle">
                                                {isTabletOrMobile &&
                                                global?.storeConfigration?.auction_close_soon_mobile
                                                    ?.value
                                                    ? 'Current Auctions'
                                                    : `Featured Auctions in ${productLocalState.globalLocations[2]}`}
                                            </h2>
                                            <div className="home-fa-filters">
                                                <ul>
                                                    <li>
                                                        <Button
                                                            className={
                                                                filterDate2 == null
                                                                    ? 'active'
                                                                    : 'dateFilter'
                                                            }
                                                            onClick={() => handleDateChange1('all')}
                                                        >
                                                            All
                                                        </Button>
                                                    </li>
                                                    {filterDateList2.filter(
                                                        (val) =>
                                                            val.city ===
                                                            productLocalState.globalLocations[2],
                                                    ).length > 0 ? (
                                                        filterDateList2
                                                            .filter(
                                                                (val) =>
                                                                    val.city ===
                                                                    productLocalState
                                                                        .globalLocations[2],
                                                            )
                                                            .map((data, index) => (
                                                                <li>
                                                                    <Button
                                                                        className={
                                                                            filterDate2 ==
                                                                            moment(
                                                                                data.format_date,
                                                                            ).format('YYYY-MM-DD')
                                                                                ? 'active'
                                                                                : 'dateFilter'
                                                                        }
                                                                        onClick={() =>
                                                                            handleDateChange1(
                                                                                moment(
                                                                                    data.format_date,
                                                                                ).format(
                                                                                    'YYYY-MM-DD',
                                                                                ),
                                                                            )
                                                                        }
                                                                    >
                                                                        {moment(
                                                                            data.format_date,
                                                                        ).format('MMMM D (ddd)')}
                                                                    </Button>
                                                                </li>
                                                            ))
                                                    ) : (
                                                        <>
                                                            <li>
                                                                <Button
                                                                    className={
                                                                        filterDate2 ==
                                                                        moment().format(
                                                                            'YYYY-MM-DD',
                                                                        )
                                                                            ? 'active'
                                                                            : 'dateFilter'
                                                                    }
                                                                    onClick={() =>
                                                                        handleDateChange1(
                                                                            moment().format(
                                                                                'YYYY-MM-DD',
                                                                            ),
                                                                        )
                                                                    }
                                                                >
                                                                    {moment().format(
                                                                        'MMMM D (ddd)',
                                                                    )}
                                                                </Button>
                                                            </li>
                                                            <li>
                                                                <Button
                                                                    className={
                                                                        filterDate2 ==
                                                                        moment()
                                                                            .add(1, 'days')
                                                                            .format('YYYY-MM-DD')
                                                                            ? 'active'
                                                                            : 'dateFilter'
                                                                    }
                                                                    onClick={() =>
                                                                        handleDateChange1(
                                                                            moment()
                                                                                .add(1, 'days')
                                                                                .format(
                                                                                    'YYYY-MM-DD',
                                                                                ),
                                                                        )
                                                                    }
                                                                >
                                                                    {moment()
                                                                        .add(1, 'days')
                                                                        .format('MMMM D (ddd)')}
                                                                </Button>
                                                            </li>
                                                            <li>
                                                                <Button
                                                                    className={
                                                                        filterDate2 ==
                                                                        moment()
                                                                            .add(2, 'days')
                                                                            .format('YYYY-MM-DD')
                                                                            ? 'active'
                                                                            : 'dateFilter'
                                                                    }
                                                                    onClick={() =>
                                                                        handleDateChange1(
                                                                            moment()
                                                                                .add(2, 'days')
                                                                                .format(
                                                                                    'YYYY-MM-DD',
                                                                                ),
                                                                        )
                                                                    }
                                                                >
                                                                    {moment()
                                                                        .add(2, 'days')
                                                                        .format('MMMM D (ddd)')}
                                                                </Button>
                                                            </li>
                                                            <li>
                                                                <Button
                                                                    className={
                                                                        filterDate2 ==
                                                                        moment()
                                                                            .add(3, 'days')
                                                                            .format('YYYY-MM-DD')
                                                                            ? 'active'
                                                                            : 'dateFilter'
                                                                    }
                                                                    onClick={() =>
                                                                        handleDateChange1(
                                                                            moment()
                                                                                .add(3, 'days')
                                                                                .format(
                                                                                    'YYYY-MM-DD',
                                                                                ),
                                                                        )
                                                                    }
                                                                >
                                                                    {moment()
                                                                        .add(3, 'days')
                                                                        .format('MMMM D (ddd)')}
                                                                </Button>
                                                            </li>
                                                        </>
                                                    )}

                                                    {auctionSearch.between_date_io_new != 'all' ? (
                                                        <li>
                                                            <KeyboardDatePicker
                                                                margin="0"
                                                                id="fa-auction-date"
                                                                inputVariant="outlined"
                                                                label=""
                                                                format="YYYY-MM-DD"
                                                                className={`selectDate ${
                                                                    // auctionSearch.between_date_io_new
                                                                    'customDatepicker'
                                                                    // : classes.hiddenTextField
                                                                }`}
                                                                value={selectedDate1}
                                                                onChange={handleDateChange1}
                                                                placeholder="Select Date"
                                                                KeyboardButtonProps={{
                                                                    'aria-label': 'change date',
                                                                }}
                                                                keyboardIcon={
                                                                    <>
                                                                        Select Date
                                                                        <span className="material-icons-outlined">
                                                                            arrow_drop_down
                                                                        </span>
                                                                    </>
                                                                }
                                                            />
                                                        </li>
                                                    ) : (
                                                        ''
                                                    )}
                                                </ul>
                                                {!isMobile && (
                                                    <Button
                                                        className="viewAll"
                                                        onClick={() =>
                                                            handleRedirectInternal(
                                                                history,
                                                                `auctions`,
                                                            )
                                                        }
                                                    >
                                                        View All
                                                    </Button>
                                                )}
                                            </div>
                                            {activeAuctions1.filter(
                                                (val) =>
                                                    val.city ===
                                                    productLocalState.globalLocations[2],
                                            ).length > 0 ? (
                                                <>
                                                    <div className="gridContainer liquidationAuctions">
                                                        <Slider {...settings2}>
                                                            {activeAuctions1
                                                                .filter(
                                                                    (val) =>
                                                                        val.city ===
                                                                        productLocalState
                                                                            .globalLocations[2],
                                                                )
                                                                .map((data, index) => (
                                                                    <LotsGridAuction
                                                                        key={index}
                                                                        data={data}
                                                                        details={`search/product-buyer-auction/${data.id}?cat=0&rat=1&est=1`}
                                                                        timerTheme="multiseller"
                                                                        theme="multiseller"
                                                                        openCategory={
                                                                            handleCategory
                                                                        }
                                                                    />
                                                                ))}
                                                        </Slider>
                                                    </div>
                                                    {isMobile && (
                                                        <div className="home-fa-filters">
                                                            <Button
                                                                className="viewAll"
                                                                onClick={() =>
                                                                    handleRedirectInternal(
                                                                        history,
                                                                        `auctions`,
                                                                    )
                                                                }
                                                            >
                                                                View All
                                                            </Button>
                                                        </div>
                                                    )}
                                                </>
                                            ) : (
                                                <div className="card card-body text-center">
                                                    No Items Found!
                                                </div>
                                            )}
                                        </div>
                                    )}
                                </>
                            </div>
                        </>
                    ) : (
                        <div>
                            {activeHomeLoading ? (
                                <Loaders
                                    name="product_grid_view"
                                    isLoading={activeHomeLoading}
                                    loop={5}
                                />
                            ) : (
                                <div className="search-wrapper">
                                    <h2 className="auctionListTitle">
                                        {isTabletOrMobile &&
                                        global?.storeConfigration?.auction_close_soon_mobile?.value
                                            ? 'Current Auctions'
                                            : `Featured Auctions in ${productLocalState.globalLocation}`}
                                    </h2>
                                    <div className="home-fa-filters">
                                        <ul>
                                            <li>
                                                <Button
                                                    className={
                                                        auctionSearch.between_date_io == undefined
                                                            ? 'active'
                                                            : 'dateFilter'
                                                    }
                                                    onClick={() => handleDateChange('all')}
                                                >
                                                    All
                                                </Button>
                                            </li>

                                            {filterDateList.length > 0 ? (
                                                filterDateList.map((data, index) => (
                                                    <li>
                                                        <Button
                                                            className={
                                                                auctionSearch.between_date_io ==
                                                                moment(data.format_date).format(
                                                                    'YYYY-MM-DD',
                                                                )
                                                                    ? 'active'
                                                                    : 'dateFilter'
                                                            }
                                                            onClick={() =>
                                                                handleDateChange(
                                                                    moment(data.format_date).format(
                                                                        'YYYY-MM-DD',
                                                                    ),
                                                                )
                                                            }
                                                        >
                                                            {moment(data.format_date).format(
                                                                'MMMM D (ddd)',
                                                            )}
                                                        </Button>
                                                    </li>
                                                ))
                                            ) : (
                                                <>
                                                    <li>
                                                        <Button
                                                            className={
                                                                auctionSearch.between_date_io ==
                                                                moment().format('YYYY-MM-DD')
                                                                    ? 'active'
                                                                    : 'dateFilter'
                                                            }
                                                            onClick={() =>
                                                                handleDateChange(
                                                                    moment().format('YYYY-MM-DD'),
                                                                )
                                                            }
                                                        >
                                                            {moment().format('MMMM D (ddd)')}
                                                        </Button>
                                                    </li>
                                                    <li>
                                                        <Button
                                                            className={
                                                                auctionSearch.between_date_io ==
                                                                moment()
                                                                    .add(1, 'days')
                                                                    .format('YYYY-MM-DD')
                                                                    ? 'active'
                                                                    : 'dateFilter'
                                                            }
                                                            onClick={() =>
                                                                handleDateChange(
                                                                    moment()
                                                                        .add(1, 'days')
                                                                        .format('YYYY-MM-DD'),
                                                                )
                                                            }
                                                        >
                                                            {moment()
                                                                .add(1, 'days')
                                                                .format('MMMM D (ddd)')}
                                                        </Button>
                                                    </li>
                                                    <li>
                                                        <Button
                                                            className={
                                                                auctionSearch.between_date_io ==
                                                                moment()
                                                                    .add(2, 'days')
                                                                    .format('YYYY-MM-DD')
                                                                    ? 'active'
                                                                    : 'dateFilter'
                                                            }
                                                            onClick={() =>
                                                                handleDateChange(
                                                                    moment()
                                                                        .add(2, 'days')
                                                                        .format('YYYY-MM-DD'),
                                                                )
                                                            }
                                                        >
                                                            {moment()
                                                                .add(2, 'days')
                                                                .format('MMMM D (ddd)')}
                                                        </Button>
                                                    </li>
                                                    <li>
                                                        <Button
                                                            className={
                                                                auctionSearch.between_date_io ==
                                                                moment()
                                                                    .add(3, 'days')
                                                                    .format('YYYY-MM-DD')
                                                                    ? 'active'
                                                                    : 'dateFilter'
                                                            }
                                                            onClick={() =>
                                                                handleDateChange(
                                                                    moment()
                                                                        .add(3, 'days')
                                                                        .format('YYYY-MM-DD'),
                                                                )
                                                            }
                                                        >
                                                            {moment()
                                                                .add(3, 'days')
                                                                .format('MMMM D (ddd)')}
                                                        </Button>
                                                    </li>
                                                </>
                                            )}

                                            {auctionSearch.between_date_io != 'all' ? (
                                                <li>
                                                    <KeyboardDatePicker
                                                        margin="0"
                                                        id="fa-auction-date"
                                                        inputVariant="outlined"
                                                        label=""
                                                        format="YYYY-MM-DD"
                                                        className={`selectDate
                                                            ${
                                                                // auctionSearch.between_date_io
                                                                'customDatepicker'
                                                                // : classes.hiddenTextField
                                                            }`}
                                                        value={selectedDate}
                                                        onChange={handleDateChange}
                                                        placeholder="Select Date"
                                                        KeyboardButtonProps={{
                                                            'aria-label': 'change date',
                                                        }}
                                                        keyboardIcon={
                                                            <>
                                                                Select Date
                                                                <span className="material-icons-outlined">
                                                                    arrow_drop_down
                                                                </span>
                                                            </>
                                                        }
                                                    />
                                                </li>
                                            ) : (
                                                ''
                                            )}
                                        </ul>
                                        <Button
                                            className="viewAll"
                                            onClick={() =>
                                                handleRedirectInternal(history, `auctions`)
                                            }
                                        >
                                            View All
                                        </Button>
                                    </div>

                                    <div className="gridContainer liquidationAuctions">
                                        {activeAuctions.length > 0 ? (
                                            <Slider {...settings2}>
                                                {activeAuctions.map((data, index) => (
                                                    <LotsGridAuction
                                                        key={index}
                                                        data={data}
                                                        details={`search/product-buyer-auction/${data.id}?cat=0&rat=1&est=1`}
                                                        timerTheme="multiseller"
                                                        theme="multiseller"
                                                        openCategory={handleCategory}
                                                    />
                                                ))}
                                            </Slider>
                                        ) : (
                                            <div className="card card-body text-center">
                                                No Items Found!
                                            </div>
                                        )}
                                    </div>
                                </div>
                            )}
                        </div>
                    )}

                    {viewClosedAuction.length == 0 &&
                    buyNowProduct.length == 0 &&
                    viewProduct.length == 0 &&
                    activeAuctions.length == 0 ? (
                        <div className="noContent">
                            <img src="/assets/svg/noContent.svg" alt="No Content" />
                            <h3>We are getting our inventory ready.</h3>
                            <h3>Please visit back soon!</h3>
                        </div>
                    ) : (
                        ''
                    )}

                    {global?.pluginConfiguration?.advertisement?.enable == 1 ? (
                        <div className="siteAds adsHome">
                            {isMobile
                                ? homeAds?.mobHomeBottom[activeHomeAdsIndex?.mobHomeBottom]
                                : homeAds?.deskHomeBottom[activeHomeAdsIndex?.deskHomeBottom]}
                        </div>
                    ) : null}
                </div>
                <div className="why-choose">
                    <div className="container">
                        <h2 className="wc-head">Why Choose Lots of Auctions</h2>
                        <div className="row">
                            <div className="col-md-4">
                                <div className="wc-card">
                                    <img
                                        src="/lots-images/wc-time.png"
                                        alt=""
                                        className="wc-card-img"
                                    />
                                    <div>
                                        <h4 className="wc-card-head">
                                            Shop local, Pick up Next Day
                                        </h4>
                                        <p className="wc-card-descp">
                                            Shopping local means you can pick up as the next day.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="wc-card">
                                    <img
                                        src="/lots-images/wc-quality.png"
                                        alt=""
                                        className="wc-card-img"
                                    />
                                    <div>
                                        <h4 className="wc-card-head">Same Quality, Pay Less</h4>
                                        <p className="wc-card-descp">
                                            Pay a friction of retail with bids starting at $7
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="wc-card">
                                    <img
                                        src="/lots-images/wc-impact.png"
                                        alt=""
                                        className="wc-card-img"
                                    />
                                    <div>
                                        <h4 className="wc-card-head">Environmental Impact</h4>
                                        <p className="wc-card-descp">
                                            Give good quality items a second chance after
                                            they&apos;ve been returned.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <CategoryModal open={open2} lotof={lotof} handleClose={() => setOpen2(false)} />
        </Layout>
    )
}

export default Home
