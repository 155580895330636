import React from 'react'
import Layout from '../Layout'
import { Style } from '@material-ui/icons'

export default function businessLiquidations(props) {
    const baseStyle = {
        fontWeight: 700,
        marginBottom: '12px',
    }

    const h1Style = {
        ...baseStyle,
        // Additional or overriding styles for h2 can go here if needed
    }

    const h4Style = {
        ...baseStyle,
        // Additional or overriding styles for h4 can go here if needed
    }

    const pStyle = {
        fontWeight: 400,
        marginBottom: '24px',
    }
    return (
        <Layout props={props}>
            <div className="px-5 py-4 stctPgWrpr">
                <h1 style={h1Style}>Business Liquidations</h1>
                <p style={pStyle}>
                    Lots of Auctions provides business liquidation services to companies looking to
                    downsize or sell their business. Our experienced liquidation team works directly
                    with our commercial clients to help them fetch top dollar for their assets.
                </p>
                <p style={pStyle}>
                    Our liquidation auctions take place entirely online, eliminating the need for
                    business owners to keep their doors open while waiting for assets to sell. This
                    reduces overhead and stress. Business owners that decide to sell through our
                    online auction platform reach a high volume of buyers in a short amount of time.
                </p>
                <h4 style={h4Style}>Selling Merchandise Through Online Liquidation Auctions</h4>
                <p style={pStyle}>
                    At Lots of Auctions, we understand that business owners have many reasons for
                    downsizing or selling their business. Our team of professional liquidation
                    experts can help make the process as smooth and easy as possible. We are
                    committed to making our commercial clients feel at ease about their decision to
                    sell. We work hard to ensure sellers get adequate compensation for their assets.
                </p>
                <p style={pStyle}>
                    In addition to a wide range of merchandise, Lots of Auctions can help business
                    owners sell their fixtures, shelving, lighting, furniture, décor, and other
                    assets used to run the business.
                </p>
                <p style={pStyle}>
                    Although businesses can choose to sell their assets on their own, it’s
                    recommended that business owners contact an experienced liquidation
                    professional. This helps ensure the best return.
                </p>
                <h4 style={h4Style}>
                    Quality Merchandise and Fixtures for Businesses and Individuals
                </h4>
                <p style={pStyle}>
                    Our online liquidation auctions allow business owners and resellers to obtain
                    large amounts of quality merchandise without spending a lot of money up front.
                    Entrepreneurs can bid on a wide range of merchandise and fixtures. Bidders only
                    pay for the auctions they win.
                </p>
                <p style={pStyle}>
                    Our online liquidation auctions allow individuals to obtain unique fixtures and
                    décor pieces for their home or office. Collectors often bid on commercial
                    auctions. These auctions make it possible for individuals purchase recognizable
                    pieces from local businesses after they close. Many people enjoy the opportunity
                    to keep the memory of these places alive. In fact, it’s not uncommon for local
                    communities to come together to preserve and display signage or other pieces
                    from favorite restaurants or other local businesses. Many of these items are
                    purchased through liquidation sales.
                </p>
            </div>
        </Layout>
    )
}
