import React, { useState, useEffect } from 'react'
import moment from 'moment-timezone'
import { currencyFormat } from '../../Product/common/components'

const InvoiceTerms = (props) => {
    const auctionData = props.auctionData
    const loaAuction = document.body.classList == 'LotsAuction'
    return (
        <div className="date-time-container">
            <div className="dates-time-wrapper">
                <div className="loa-gray-box">
                    <p>
                        <strong>Main Pickup Location Is:</strong> {auctionData.address},{' '}
                        {auctionData.city}, {auctionData.state}
                    </p>
                </div>
                <div className="loa-gray-box">
                    <p>
                        <strong>Auction Rules:</strong>
                    </p>
                    <p>
                        This is an ONSITE auction. All winning bidders must remove items purchased
                        within the stated load out times. Items that are not removed from the
                        facility, within the loadout time range, will be considered forfeited. There
                        are NO REFUNDS on forfeited items. Winning bidders will be responsible for
                        the removal of items purchased and are required to bring their own help
                        tools and transportation to remove items purchased. All merchandise is sold
                        &#34;ASIS&#34;. We do try our absolute best to perform a basic functionality
                        test on each item to prevent any type of misrepresentation. There are no
                        guarantees that any parts or accessories are included with an item unless
                        they are photographed in the auction.
                    </p>
                    <p>
                        <strong>Returns Policy</strong>
                    </p>
                    <p>See auction for return specifics</p>
                    <p>
                        <strong>Location</strong>
                    </p>
                    <p>LOCAL, PICK-UP ONLY. Shipping is unavailable for this auction.</p>
                </div>
                <div className="loa-gray-box">
                    <p>
                        <strong>Buyers Premium and Fees:</strong> There will be{' '}
                        {auctionData?.la_seller_id}% Buyers Premium and{' '}
                        {currencyFormat(parseFloat(auctionData.lot_fee).toFixed(2))}
                        lot fee assessed in this auction. The Buyer Premium will be added to your
                        final invoice.
                    </p>
                    <p>
                        <strong>Sales Tax:</strong>
                    </p>
                    <p>
                        Bidder will be subject to pay{' '}
                        {global.storeConfigration?.sales_tax?.value !== undefined
                            ? global.storeConfigration?.sales_tax?.value
                            : 8.5}
                        % for Sales Tax which automatically be added to your final invoice. Sales
                        Tax applies to both the item purchase and Buyer&apos;s Permium.
                    </p>
                    <p>
                        <strong>Payment Collected:</strong>
                    </p>
                    <p>
                        Credit/Debit cards on file will automatically be charged within 4 hours
                        after the auction close time.
                    </p>
                    <p>
                        <strong>Payment Methods:</strong>
                    </p>
                    <p>Credit/Debit Card, Cash</p>
                    <p>
                        <strong>Non-Payment:</strong>
                    </p>
                    <p>
                        If payment for items is not received within the load out time bidders
                        account will be blacklisted. A 25% fee on unpaid items will be assessed if
                        the bidder choose to reinstate the account. Any future accounts created by a
                        blacklisted bidder will be automatically removed.
                    </p>
                </div>
                <div className="loa-gray-box">
                    <p>
                        <strong>Load Out Times:</strong> We are open for walkins Monday Through
                        Saturday from 9:00AM to 6:00PM.
                    </p>
                    <p>You can schedule pickups Monday Through Saturday from 9:00 AM to 4:30 PM</p>
                    <p>
                        Please schedule pickups two hours in advance so we can have your order ready
                        on time.
                    </p>
                    <p>
                        <strong>Location:</strong> {auctionData.address}, {auctionData.city},{' '}
                        {auctionData.state}
                    </p>
                    <p>
                        <strong>
                            Items must be picked up by{' '}
                            {loaAuction ? (
                                <>
                                    {moment(auctionData.date_closed)
                                        .tz(moment.tz.guess())
                                        .add(7, 'days')
                                        .format('MM/D/YYYY')}
                                </>
                            ) : (
                                <>
                                    {moment(auctionData.date_closed)
                                        .tz(moment.tz.guess())
                                        .add(7, 'days')
                                        .format('MMM Do YYYY, h:mm a z')}
                                </>
                            )}
                        </strong>
                    </p>
                    <p>
                        <strong>Removal Assistance:</strong> Winning bidders will be responsible for
                        the pickup of items purchased and are required to bring their own help,
                        tools and transportation to remove items purchased.
                    </p>
                    <p>
                        <strong>Abandonment and Forfeit of items:</strong> If items is not picked up
                        within the started loadout time, the item will be considered abandoned and
                        forfeited. No refund will be given for abandoned, forfeited items.
                    </p>
                    <p>
                        <strong>Picking Up with Unpaid Invoice:</strong> All invoices must be paid
                        in full at all locations before any items will be released.
                    </p>
                    <p>
                        <strong>
                            Returns must be received by{' '}
                            {loaAuction ? (
                                <>
                                    {moment(auctionData.date_closed)
                                        .tz(moment.tz.guess())
                                        .add(7, 'days')
                                        .format('MM/D/YYYY')}
                                </>
                            ) : (
                                <>
                                    {moment(auctionData.date_closed)
                                        .tz(moment.tz.guess())
                                        .add(7, 'days')
                                        .format('MMM Do YYYY, h:mm a z')}
                                </>
                            )}
                        </strong>
                    </p>
                </div>
                <div className="loa-gray-box">
                    <p>
                        <strong>Buyer&apos;s Premium and Fees:</strong> There will be a{' '}
                        {auctionData.la_seller_id}% Buyers Premium and a{' '}
                        {currencyFormat(parseFloat(auctionData.lot_fee).toFixed(2))}
                        lot fee assessed in this auction.
                    </p>
                </div>
                <div className="loa-gray-box">
                    <p>
                        <strong>Customer Service:</strong>
                    </p>
                    <p>Lots of Auctions</p>
                    <p>(800) 830-9381</p>
                    <p>customerservice@lotsofauctions.com</p>
                </div>
            </div>
        </div>
    )
}

export default InvoiceTerms
