import React from 'react'
import Layout from '../Layout'
import PreferenceForm from '../../../utils/CommonFunctionality/PreferenceFunction'
import DashboardHeader from '../DashboardHeader'
import { Container } from '@material-ui/core'

const Preference = (props) => {
    return (
        <Layout props={props}>
            <DashboardHeader />
            <Container className="db-bids-wrapper mt-4 lq-db-table">
                <h2 className="mp-head">NOTIFICATION PREFERENCES</h2>
                <PreferenceForm props={props} />
            </Container>
        </Layout>
    )
}

export default Preference
