import React from 'react'
import Layout from '../Layout'
import PreferenceForm from '../../../utils/CommonFunctionality/PreferenceFunction'
import DashboardHeader from '../DashboardHeader'
import { Container } from '@material-ui/core'
import BarcodeGenerator from './barcode'

const Barcode = (props) => {
    const barcodeCenter = {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%',
    }

    return (
        <Layout props={props}>
            <DashboardHeader />
            <Container className="db-bids-wrapper mt-4 lq-db-table">
                <h2 className="mp-head">Barcode</h2>
                <div style={barcodeCenter}>
                    <BarcodeGenerator />
                </div>
            </Container>
        </Layout>
    )
}

export default Barcode
