import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import ClearIcon from '@material-ui/icons/Clear'
const HeaderSearchComponent = (props) => {
    const [inputVal, setInputVal] = useState('')
    const handleChange = (e) => setInputVal(e.target.value)
    let history = useHistory()
    const handleClear = (e) => {
        e.preventDefault()
        setInputVal('')
        history.push(`/search?q=`)
    }
    const handleClick = (e) => {
        e.preventDefault()
        history.push(`/search?q=${inputVal}`)
    }
    useEffect(() => {
        const params = new URLSearchParams(window.location.search.substring(1))
        setInputVal(params.get('q') ? params.get('q') : '')
    }, [])
    return (
        <form className="nav-standard-top-area-search">
            <div className="nav-standard-top-area-search-inner">
                <input
                    type="text"
                    onChange={handleChange}
                    name="q"
                    value={inputVal}
                    className="nav-standard-top-area-search-input open-sans"
                    placeholder="Search"
                />
                <button
                    onClick={(e) => {
                        handleClick(e)
                        if (props.onClose) {
                            props.onClose()
                        }
                    }}
                    className="lin lin-magnifier nav-standard-top-area-search-sumbit"
                >
                    <span className="material-icons">search</span>
                </button>
            </div>
        </form>
    )
}

export default HeaderSearchComponent
