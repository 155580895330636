import React, { useEffect, useState, useContext, useRef } from 'react'
import Layout from '../Layout'
import SearchAuctionList from '../../../Component/Search/SearchAuctionList'
import ProductContext from '../../../Product/context/product/productContext'
import AuthContext from '../../../Product/context/auth/authContext'
import { useHistory, NavLink } from 'react-router-dom'
import NoRecordsFound from '../../../Product/components/atoms/NoRecordsFound'
import './home.css'
import SecondaryButton from '../../../Product/components/atoms/SecondaryButton'
import SearchProductList from '../../../Component/ProductCard/product'
import PrimaryButton from '../../../Product/components/atoms/PrimaryButton'
import moment from 'moment'
import { handleRedirectInternal, mapData } from '../../../Product/common/components'
import MultisellerListCard from '../../../Component/ProductCard/MultisellerListCard'
import FilterPanel from '../../../Product/components/organisms/FilterPanel'
import { useFormik } from 'formik'
import { Box, Button, ListItem, Tab, Tabs, Typography } from '@material-ui/core'
import PropTypes from 'prop-types'
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer'
import IconButton from '@material-ui/core/IconButton'
import List from '@material-ui/core/List'
import Loaders from '../../../Product/components/molecules/Loaders'
import GridAuction from '../../../Component/ProductCard/gridAuction'
import { useMediaQuery } from 'react-responsive'
import jsonp from 'jsonp'
import AlertContext from '../../../Product/context/alert/alertContext'
import queryString from 'query-string'
import GridProduct from '../../../Component/ProductCard/gridProduct'
import Slider from 'react-slick'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import axios from 'axios'
const TabPanel = ({ children, value, index, ...other }) => {
    return (
        <Typography
            component="div"
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && <Box>{children}</Box>}
        </Typography>
    )
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
}

function a11yProps(index) {
    return {
        id: `home-auctions-${index}`,
        'aria-controls': `home-auctions-tabpanel-${index}`,
    }
}

const Home = (props) => {
    const { user } = useContext(AuthContext)
    const alertContext = useContext(AlertContext)
    const productContext = useContext(ProductContext)
    const { search_allauctions, getAllSearchAuctions, search_allproducts, getAllSearchProducts } =
        productContext
    const { setAlert } = alertContext

    const authContext = useContext(AuthContext)
    const { isAuthenticated } = authContext
    const history = useHistory()
    const [loading, setLoading] = useState(true)
    const [viewAuction, setViewAuction] = useState([])
    const cancelToken = axios.CancelToken.source()
    const [viewProduct, setViewProduct] = useState([])
    const [viewProductmost, setViewProductmost] = useState([])
    const [viewProductsold, setViewProductsold] = useState([])
    const [viewBuyNow, setBuyNow] = useState([])
    const [lotsPageLoading, setlotsPageLoading] = useState(true)
    const [lotsPageLoadingview, setlotsPageLoadingview] = useState(true)
    const [states, setStates] = useState([])
    const [lotSearch, setLotSearch] = useState({
        sh_limit: 20,
        from: 'auction_only',
        page: 1,
        orderby: '12',
        most_viewed: 1,
        viewers: 1,
    })
    const formik = useFormik({
        initialValues: {
            state: '',
            fromprice: '',
            toprice: '',
        },
    })
    const [cancelRequest, setCancelRequest] = useState(undefined)
    const sessionTimeOutRef = useRef(null)

    const [value, setValue] = useState(
        global.storeConfigration?.disabled_auc_mulsel2?.value != 1 ? 0 : 1,
    )
    const [inpData, setinpData] = useState('')

    const [auctionSearch, setAuctionSearch] = useState({
        page: 1,
        sh_limit: '10',
        searchbar: '',
        auc_type: 2,
        lot_images: 1,
        type: '',
        orderby: '',
    })

    const [productSearch, setProductSearch] = useState({
        sh_limit: 12,
        from: 'buynow',
        page: 1,
        orderby: '1',
    })
    const [showFilter, setShowFilter] = useState(false)

    const isDesktop = useMediaQuery({
        query: '(min-width: 769px)',
    })
    useEffect(() => {
        getAllSearchProducts(
            {
                ...productSearch,
                state: formik.values.state,
                fromprice: formik.values.fromprice,
                toprice: formik.values.toprice,
            },
            'galleryPage',
        )
    }, [productSearch, isAuthenticated, formik.values])

    useEffect(() => {
        setLoading(false)
        if (search_allproducts.from === 'galleryPage') {
            setBuyNow(search_allproducts.results.length ? search_allproducts.results : [])
            setViewProduct(search_allproducts.results.length ? search_allproducts.results : [])
            setStates(search_allproducts.state.filter((val) => val.state != ''))
        }
    }, [search_allproducts])

    // useEffect(() => {
    //     setViewProduct([])
    //     return () => {
    //         setViewProduct([])
    //     }
    // }, [])

    useEffect(() => {
        if (value === 0) {
            setAuctionSearch({ ...auctionSearch, auc_type: 2, type: '' })
        }
        if (value === 1) {
            setAuctionSearch({ ...auctionSearch, auc_type: 1, type: '' })
        }
        if (value === 2) {
            setAuctionSearch({ ...auctionSearch, type: 'closed', auc_type: '' })
        }
    }, [value])

    useEffect(() => {
        setLoading(true)
        getAllSearchAuctions(
            {
                ...auctionSearch,
                auction_state: formik.values.state,
                fromprice: formik.values.fromprice,
                toprice: formik.values.toprice,
            },
            'activeHome',
        )
    }, [auctionSearch, formik.values])

    useEffect(() => {
        setLoading(false)
        if (search_allauctions.from === 'activeHome') {
            setViewAuction(search_allauctions.results.length ? search_allauctions.results : [])
        }
    }, [search_allauctions])

    const filterValues = [
        {
            title: 'Category',
            type: 'check',
            name: 'category',
            options: global.searchCategories.filter((main) => {
                return true
            }),
        },
    ]

    const handleChange = (event, newValue) => {
        setValue(newValue)
    }

    const handleClick = (catId) => {
        handleRedirectInternal(history, `search?cat=${catId}`)
    }

    const [state, setState] = React.useState({
        bottom: false,
    })

    const toggleDrawer = (anchor, open) => (event) => {
        setState({ ...state, [anchor]: open })
    }

    const subscribe = () => {
        // console.log(inpData, 'this is typeed adatra')
        if (inpData) {
            if (!/\S+@\S+\.\S+/.test(inpData)) {
                return setAlert('Invalid email address', 'error')
            }

            const data = {
                EMAIL: inpData,
            }

            jsonp(
                `//${global.storeConfigration?.subscribe_code.value}${queryString.stringify(data)}`,
                { param: 'c' },
                (err, data) => {
                    //console.log('display', data.result)
                    if (err) {
                        return false
                    }
                    if (data.result === 'success') {
                        setinpData('')
                        return setAlert(data.msg, 'success')
                    }
                    if (data.result === 'error') {
                        return setAlert(data.msg, 'warning')
                    }
                },
            )
        }
        setinpData('')
    }

    const list = (anchor) => (
        <div
            className=""
            role="presentation"
            onClick={toggleDrawer(anchor, false)}
            onKeyDown={toggleDrawer(anchor, false)}
        >
            <div className="filterPanel">
                <div className="d-flex justify-content-between align-items-center filterTitleCnt">
                    <h4 className="fpTitle">Categories</h4>
                    {props.clearSearch && (
                        <Button onClick={() => props.clearSearch()} className="filterClear">
                            <span className="material-icons">cancel</span>
                            Clear all
                        </Button>
                    )}
                </div>
                <div className="filterAcc">
                    {filterValues[0].options.map((data, index) => (
                        <>
                            {console.log(data, 'def')}
                            <ListItem
                                button
                                className="homeCatgInner"
                                key={index}
                                onClick={() => handleClick(data.id)}
                            >
                                {data.name}
                            </ListItem>
                        </>
                    ))}
                </div>
            </div>
        </div>
    )

    const settings = {
        dots: true,
        fade: false,
        arrows: false,
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        speed: 1000,
        autoplaySpeed: 4000,
        cssEase: 'linear',
    }
    const filterValues1 = [
        {
            title: 'Location',
            type: 'check',
            name: 'state',
            options: states
                .map((buscat) => {
                    let busCategoryChanged = {}
                    busCategoryChanged.description = buscat.state
                    busCategoryChanged.name = buscat.state
                    busCategoryChanged.value = buscat.state
                    busCategoryChanged.id = buscat.state
                    busCategoryChanged.active = 1
                    return busCategoryChanged
                })
                .sort()
                .filter((main) => {
                    return true
                }),
            formik: formik,
        },
    ]

    var extra_filter = [
        {
            type: 'text',
            placeholder: 'From Price',
            class: 'col-6',
            name: 'fromprice',
            formik: formik,
        },
        {
            type: 'text',
            placeholder: 'To Price',
            class: 'col-6',
            name: 'toprice',
            formik: formik,
        },
    ]
    useEffect(() => {
        if (global.pluginConfiguration?.most_viewed?.enable) {
            setlotsPageLoading(true)
            if (cancelRequest) {
                cancelRequest.cancel()
            }

            setCancelRequest(cancelToken)
            getAllSearchProducts(lotSearch, 'lotsPage')
            setlotsPageLoadingview(true)
            getAllSearchProducts(
                {
                    auction_io: 1,
                    category: [],
                    city: [],
                    closed_auction_list: '1',
                    condition: [],
                    enddate: null,
                    from: '',
                    fromprice: formik.values.fromprice,
                    getbidding: true,
                    location: [],
                    lotof: '',
                    orderby: '6',
                    page: 1,
                    searchbar: '',
                    sh_limit: 5,
                    state: formik.values.state,
                    storedate: [],
                    subcategory: [],
                    toprice: formik.values.toprice,
                    totalpage: 5,
                },
                'soldpage',
            )
            // sessionTimeOutRef.current = setTimeout(() => {
            //     getAllSearchProducts(lotSearch, 'lotsPage')
            // }, 8000)
        }
    }, [lotSearch, isAuthenticated, formik.values])

    useEffect(() => {
        if (search_allproducts.from === 'lotsPage') {
            // clearTimeout(sessionTimeOutRef.current)
            setViewProductmost(search_allproducts.results.length ? search_allproducts.results : [])
            if (lotSearch.most_viewed_feature && !search_allproducts.results.length) {
                setLotSearch({
                    ...lotSearch,
                    most_viewed: 1,
                    most_viewed_feature: 0,
                })
            } else {
                setlotsPageLoading(false)
            }
        }
        if (search_allproducts.from === 'soldpage') {
            setViewProductsold(search_allproducts.results.length ? search_allproducts.results : [])
            setlotsPageLoadingview(false)
        }
    }, [search_allproducts])

    const showfilter = () => {
        setShowFilter(!showFilter)
    }

    console.log('view auctions', viewAuction)
    console.log('view buynow', viewProduct)
    console.log('view sold', viewProductsold)
    return (
        <Layout props={props}>
            <div className="home">
                {global.multipleBannersFields.length ? (
                    <div className="siteBanner multiple">
                        {
                            <Slider {...settings}>
                                {global.multipleBannersFields?.map((data, index) => (
                                    <>
                                        <LazyLoadImage
                                            src={
                                                process.env.REACT_APP_AUCTION_IMAGE_URL +
                                                '/' +
                                                data?.image_name
                                            }
                                            effect="blur"
                                            height="100%"
                                            width="100%"
                                        />
                                        <div className="bannerContent">
                                            <h4 className="banerTit">
                                                {JSON.parse(data.fields ? data.fields : '{}').title}
                                            </h4>
                                            <h3 className="bannerHdr">
                                                {
                                                    JSON.parse(data.fields ? data.fields : '{}')
                                                        .description
                                                }
                                            </h3>
                                            <PrimaryButton
                                                btnSize={
                                                    JSON.parse(data.fields ? data.fields : '{}')
                                                        .btn_link
                                                }
                                                label={
                                                    isAuthenticated
                                                        ? JSON.parse(
                                                              data.fields ? data.fields : '{}',
                                                          ).btn_text
                                                        : 'Sign Up'
                                                }
                                                type="button"
                                                className="bannerButton"
                                                onClick={() =>
                                                    !isAuthenticated
                                                        ? handleRedirectInternal(history, 'sign-up')
                                                        : (window.location.href = JSON.parse(
                                                              data.fields ? data.fields : '{}',
                                                          ).btn_link)
                                                }
                                            />
                                        </div>
                                    </>
                                ))}
                            </Slider>
                        }
                    </div>
                ) : (
                    <div className="siteBanner">
                        <LazyLoadImage
                            src={global?.storeDetails?.bannerValue}
                            alt={`${global?.storeDetails?.name} banner`}
                            effect="blur"
                            height="100%"
                            width="100%"
                        />
                        <div className="bannerContent">
                            <h2>{global?.storeDetails?.description}</h2>
                            <PrimaryButton
                                label="Explore Now"
                                onClick={() =>
                                    handleRedirectInternal(
                                        history,
                                        `${isAuthenticated ? 'search' : 'sign-up'}`,
                                    )
                                }
                            />
                        </div>
                    </div>
                )}

                <div className="customContainer auctionListCnt">
                    <div className="homeCnt">
                        {/* HomePage Categories Drawer */}

                        <React.Fragment>
                            <Button
                                className="toggleRespDrawer"
                                variant="outlined"
                                onClick={toggleDrawer('bottom', true)}
                            >
                                <span className="material-icons mr-1">tune</span>
                                Categories
                            </Button>
                            <SwipeableDrawer
                                className="responsiveFilterDrawer"
                                anchor={'bottom'}
                                open={state['bottom']}
                                onClose={toggleDrawer('bottom', false)}
                                onOpen={toggleDrawer('bottom', true)}
                            >
                                {list('bottom')}
                            </SwipeableDrawer>
                        </React.Fragment>

                        {/* HomePage Categories Drawer end */}

                        <div className="homeRt">
                            <h2 className="auctionListTitle">Our Auctions</h2>
                            {global.storeConfigration?.home_page_filter.value == 1 ? (
                                <>
                                    <div
                                        className="d-flex align-items-center justify-content-start flex-wrap"
                                        style={{ gap: '12px', width: 'calc(100% - 230px)' }}
                                    >
                                        {showFilter ? (
                                            <>
                                                <FilterPanel
                                                    formik={formik}
                                                    filterData={
                                                        Object.keys(extra_filter).length > 0
                                                            ? extra_filter
                                                            : ''
                                                    }
                                                    filterValues={filterValues1}
                                                    label={'Price Filter'}
                                                />
                                            </>
                                        ) : (
                                            ''
                                        )}
                                    </div>
                                    <a
                                        onClick={(e) => showfilter()}
                                        className="advance-settings"
                                        style={{ cursor: 'pointer' }}
                                    >
                                        <span className="material-icons mr-1">tune</span>
                                    </a>
                                </>
                            ) : (
                                ''
                            )}
                            <Tabs value={value} onChange={handleChange} aria-label="home-auctions">
                                {global.storeConfigration?.disabled_auc_mulsel2?.value != 1 ? (
                                    <Tab label="Current Auctions" {...a11yProps(0)} />
                                ) : (
                                    ''
                                )}

                                {global.storeConfigration?.need_post_buynow?.value != 0 ? (
                                    <Tab label="Buy it now" {...a11yProps(1)} />
                                ) : (
                                    ''
                                )}
                                <Tab label="Recently Sold" {...a11yProps(2)} />
                            </Tabs>
                            <TabPanel value={value} index={0}>
                                {loading ? (
                                    <div className="homeLoader">
                                        {isDesktop ? (
                                            <Loaders
                                                name="product_list_view"
                                                isLoading={loading}
                                                loop={1}
                                            />
                                        ) : (
                                            <Loaders
                                                name="product_grid_view"
                                                isLoading={loading}
                                                loop={1}
                                            />
                                        )}
                                    </div>
                                ) : viewAuction.filter(
                                      (data) =>
                                          data.market_status == 'open' && data.remainingDay >= 0,
                                  ).length === 0 ? (
                                    <NoRecordsFound />
                                ) : (
                                    <div className={`homeAuctions ${!isDesktop && 'mobileView'}`}>
                                        {viewAuction
                                            .filter(
                                                (data) =>
                                                    data.market_status == 'open' &&
                                                    data.remainingDay >= 0,
                                            )
                                            .map((data, index) =>
                                                isDesktop ? (
                                                    <>
                                                        <MultisellerListCard
                                                            key={index}
                                                            data={data}
                                                            allsearchitems={viewAuction}
                                                            details={`search/product-buyer-auction/${data.id}`}
                                                            // lotCount={data.lotCount ? data.lotCount : 0}
                                                        />
                                                    </>
                                                ) : (
                                                    <GridAuction
                                                        key={index}
                                                        data={data}
                                                        details={`search/product-buyer-auction/${data.id}`}
                                                        timerTheme="multiseller"
                                                        cardTheme="multiseller_two"
                                                        // lotCount={data.lotCount ? data.lotCount : 0}
                                                    />
                                                ),
                                            )}
                                    </div>
                                )}
                            </TabPanel>
                            <TabPanel value={value} index={1}>
                                {loading ? (
                                    <div className="homeLoader">
                                        {isDesktop ? (
                                            <Loaders
                                                name="product_list_view"
                                                isLoading={loading}
                                                loop={1}
                                            />
                                        ) : (
                                            <Loaders
                                                name="product_grid_view"
                                                isLoading={loading}
                                                loop={1}
                                            />
                                        )}
                                    </div>
                                ) : viewBuyNow.length === 0 ? (
                                    <NoRecordsFound />
                                ) : (
                                    <SearchProductList
                                        // listview={data}
                                        // setViewProduct={setViewProduct}
                                        searchResult={viewBuyNow}
                                        className="liquidationBuyCard"
                                        cardType={isDesktop ? 'list' : 'grid'}
                                        type="buynow"
                                        timerTheme="multiseller"
                                        cardTheme="multiseller"
                                        sliderTheme="multiseller"
                                        fromComponent="home"
                                        showQty={true}
                                    />
                                )}
                            </TabPanel>
                            <TabPanel value={value} index={2}>
                                {loading ? (
                                    <div className="homeLoader">
                                        {isDesktop ? (
                                            <Loaders
                                                name="product_list_view"
                                                isLoading={loading}
                                                loop={1}
                                            />
                                        ) : (
                                            <Loaders
                                                name="product_grid_view"
                                                isLoading={loading}
                                                loop={1}
                                            />
                                        )}
                                    </div>
                                ) : viewProductsold.length === 0 ? (
                                    <NoRecordsFound />
                                ) : (
                                    <SearchProductList
                                        // listview={data}
                                        searchResult={viewProductsold}
                                        getAllProducts={() => getAllProducts('auctions')}
                                        className="liquidationBuyCard"
                                        cardType={isDesktop ? 'list' : 'grid'}
                                        type="auction"
                                        timerTheme="multiseller"
                                        cardTheme="multiseller"
                                        sliderTheme="multiseller"
                                        fromComponent="home"
                                        showQty={true}
                                    />
                                )}
                            </TabPanel>

                            <div
                                className="viewAllBtn"
                                onClick={() =>
                                    handleRedirectInternal(
                                        history,
                                        value == 0
                                            ? `auctions?open=${value}`
                                            : value == 2
                                            ? `search?q=&z=&m=&cat=&view=&ltype=1`
                                            : `gallery`,
                                    )
                                }
                            >
                                <hr />
                                <Button>View All</Button>
                                <hr />
                            </div>
                        </div>
                    </div>
                </div>
                {global.pluginConfiguration?.most_viewed?.enable ? (
                    <div className="container auctionListCnt">
                        {lotsPageLoading ? (
                            <Loaders
                                name="product_grid_view"
                                isLoading={lotsPageLoading}
                                loop={5}
                            />
                        ) : viewProductmost.length > 0 ? (
                            <div className="search-wrapper">
                                <>
                                    <h2 className="auctionListTitle">Most Viewed Lots</h2>
                                    <SearchProductList
                                        listview={false}
                                        searchResult={viewProductmost}
                                        className="liquidationBuyCard"
                                        getAllProducts={() => getAllProducts('auctions')}
                                        type="auction"
                                        fromComponent="home"
                                        sliderTheme="multiseller"
                                    />

                                    <div
                                        className="viewAllBtn"
                                        onClick={() => handleRedirectInternal(history, 'search')}
                                    >
                                        <hr />
                                        <Button>View All</Button>
                                        <hr />
                                    </div>
                                </>
                            </div>
                        ) : (
                            ''
                        )}
                    </div>
                ) : (
                    ''
                )}

                {global.pluginConfiguration?.most_viewed?.enable &&
                (global.storeConfigration?.home_page_filter.value === undefined ||
                    global.storeConfigration?.home_page_filter.value != 1) ? (
                    <div className="container auctionListCnt">
                        {lotsPageLoadingview ? (
                            <Loaders
                                name="product_grid_view"
                                isLoading={lotsPageLoadingview}
                                loop={5}
                            />
                        ) : viewProductsold.length > 0 ? (
                            <div className="search-wrapper">
                                <>
                                    <h2 className="auctionListTitle">Recently Sold Lots</h2>
                                    <SearchProductList
                                        listview={false}
                                        searchResult={viewProductsold}
                                        className="liquidationBuyCard"
                                        getAllProducts={() => getAllProducts('auctions')}
                                        type="auction"
                                        fromComponent="home"
                                        sliderTheme="multiseller"
                                    />
                                </>
                            </div>
                        ) : (
                            ''
                        )}
                    </div>
                ) : (
                    ''
                )}

                {global?.storeConfigration?.subscribe?.value && (
                    <div className="homeContact">
                        <h2>Get the latest deals</h2>
                        <p>Subscribe us to get latest updates</p>
                        <div className="subsBox">
                            <input
                                type="text"
                                name="q"
                                value={inpData}
                                placeholder="Enter email address"
                                onChange={(e) => {
                                    setinpData(e.target.value)
                                }}
                            />
                            <Button>
                                <span className="material-icons" onClick={subscribe}>
                                    send
                                </span>
                            </Button>
                        </div>
                    </div>
                )}
            </div>
        </Layout>
    )
}

export default Home
