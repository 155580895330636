import React, { useReducer } from 'react'
import PosContext from './posContext'
import PosReducer from './posReducer'
import { apiCall } from '../../../common/api'
import { response } from '../../common'
import {
    CLEAR_RESPONSE,
    GET_ALL_LOCATIONS,
    GET_ALL_READERS,
    GET_LOCATION,
    GET_READER,
    PAYMENT_STATUS,
    RESPONSE_STATUS,
} from './posTypes'

const PosState = (props) => {
    const initialState = {
        responseStatus: null,
        all_locations: [],
        location: {},
        all_readers: [],
        reader: {},
        payment_status: '',
    }

    const [state, dispatch] = useReducer(PosReducer, initialState)
    let resp = new response(dispatch, RESPONSE_STATUS)
    const baseUrl = 'plugin/pos/site/pos'

    /* Location States Start Here */
    const createLocation = async (formData) => {
        const from = 'createLocation'
        try {
            const [res] = await Promise.all([
                apiCall('post', 'createLocation', formData, '', baseUrl),
            ])
            if (res.data.status === 'success') {
                resp.commonResponse(
                    {
                        status: 'success',
                        message: res.data.data?.message,
                    },
                    from,
                )
            } else {
                resp.commonResponse({ status: 'error', message: res.data.message }, from)
            }
        } catch (err) {
            resp.commonErrorResponse(from)
        }
    }

    const allLocations = async (formData) => {
        try {
            const from = 'allLocations'
            const [res] = await Promise.all([
                apiCall('post', 'allLocations', formData, '', baseUrl),
            ])
            if (res.data.status === 'success') {
                dispatch({
                    type: GET_ALL_LOCATIONS,
                    payload: res.data.data?.result,
                })
            } else {
                resp.commonErrorResponse(from)
            }
        } catch (err) {
            dispatch({
                type: RESPONSE_STATUS,
                payload: 'Something went wrong!',
            })
        }
    }

    const retrieveLocation = async (formData) => {
        try {
            const from = 'retrieveLocation'
            const [res] = await Promise.all([
                apiCall('post', 'retrieveLocation', formData, '', baseUrl),
            ])
            if (res.data.status === 'success') {
                dispatch({
                    type: GET_LOCATION,
                    payload: res.data.data?.result,
                })
            } else {
                resp.commonErrorResponse(from)
            }
        } catch (err) {
            dispatch({
                type: RESPONSE_STATUS,
                payload: 'Something went wrong!',
            })
        }
    }

    const updateLocation = async (formData) => {
        const from = 'updateLocation'
        try {
            const [res] = await Promise.all([
                apiCall('post', 'updateLocation', formData, '', baseUrl),
            ])
            if (res.data.status === 'success') {
                resp.commonResponse(
                    {
                        status: 'success',
                        message: res.data.data?.message,
                    },
                    from,
                )
            } else {
                resp.commonResponse({ status: 'error', message: res.data.message }, from)
            }
        } catch (err) {
            resp.commonErrorResponse(from)
        }
    }

    const deleteLocation = async (formData) => {
        const from = 'deleteLocation'
        try {
            const [res] = await Promise.all([
                apiCall('post', 'deleteLocation', formData, '', baseUrl),
            ])
            if (res.data.status === 'success') {
                resp.commonResponse(
                    {
                        status: 'success',
                        message: res.data.data?.message,
                    },
                    from,
                )
            } else {
                resp.commonResponse({ status: 'error', message: res.data?.data?.message }, from)
            }
        } catch (err) {
            resp.commonErrorResponse(from)
        }
    }
    /* Location States End Here */

    /* Reader States Start Here */

    const createReader = async (formData) => {
        const from = 'createReader'
        try {
            const [res] = await Promise.all([
                apiCall('post', 'createReader', formData, '', baseUrl),
            ])
            if (res.data.status === 'success') {
                resp.commonResponse(
                    {
                        status: 'success',
                        message: res.data.data?.message,
                    },
                    from,
                )
            } else {
                resp.commonResponse({ status: 'error', message: res.data.message }, from)
            }
        } catch (err) {
            resp.commonErrorResponse(from)
        }
    }

    const allReaders = async (formData) => {
        try {
            const from = 'allReaders'
            const [res] = await Promise.all([apiCall('post', 'allReaders', formData, '', baseUrl)])
            if (res.data.status === 'success') {
                dispatch({
                    type: GET_ALL_READERS,
                    payload: res.data.data?.result,
                })
            } else {
                resp.commonErrorResponse(from)
            }
        } catch (err) {
            dispatch({
                type: RESPONSE_STATUS,
                payload: 'Something went wrong!',
            })
        }
    }

    const retrieveReader = async (formData) => {
        try {
            const from = 'retrieveReader'
            const [res] = await Promise.all([
                apiCall('post', 'retrieveReader', formData, '', baseUrl),
            ])
            if (res.data.status === 'success') {
                dispatch({
                    type: GET_READER,
                    payload: res.data.data?.result,
                })
            } else {
                resp.commonErrorResponse(from)
            }
        } catch (err) {
            dispatch({
                type: RESPONSE_STATUS,
                payload: 'Something went wrong!',
            })
        }
    }

    const updateReader = async (formData) => {
        const from = 'updateReader'
        try {
            const [res] = await Promise.all([
                apiCall('post', 'updateReader', formData, '', baseUrl),
            ])
            if (res.data.status === 'success') {
                resp.commonResponse(
                    {
                        status: 'success',
                        message: res.data.data?.message,
                    },
                    from,
                )
            } else {
                resp.commonResponse({ status: 'error', message: res.data.message }, from)
            }
        } catch (err) {
            resp.commonErrorResponse(from)
        }
    }

    const deleteReader = async (formData) => {
        const from = 'deleteReader'
        try {
            const [res] = await Promise.all([
                apiCall('post', 'deleteReader', formData, '', baseUrl),
            ])
            if (res.data.status === 'success') {
                resp.commonResponse(
                    {
                        status: 'success',
                        message: res.data.data?.message,
                    },
                    from,
                )
            } else {
                resp.commonResponse({ status: 'error', message: res.data.message }, from)
            }
        } catch (err) {
            resp.commonErrorResponse(from)
        }
    }

    const paymentStatus = async (formData) => {
        const from = 'paymentStatus'
        try {
            const [res] = await Promise.all([
                apiCall('post', 'payment_status', formData, '', baseUrl),
            ])
            if (res.data.status === 'success') {
                dispatch({
                    type: PAYMENT_STATUS,
                    payload: res.data.data.result.paymentIntent.status,
                })
            } else {
                resp.commonResponse({ status: 'error', message: res.data.message }, from)
            }
        } catch (err) {
            resp.commonErrorResponse(from)
        }
    }

    /* Reader States End Here */
    const clearResponse = () =>
        dispatch({
            type: CLEAR_RESPONSE,
        })
    return (
        <PosContext.Provider
            value={{
                responseStatus: state.responseStatus,
                all_locations: state.all_locations,
                location: state.location,
                all_readers: state.all_readers,
                reader: state.reader,
                payment_status: state.payment_status,
                createReader,
                allReaders,
                retrieveReader,
                updateReader,
                deleteReader,
                deleteLocation,
                updateLocation,
                retrieveLocation,
                createLocation,
                allLocations,
                paymentStatus,
                clearResponse,
            }}
        >
            {props.children}
        </PosContext.Provider>
    )
}

export default PosState
