import { Button } from '@material-ui/core'
import React from 'react'
import { useState } from 'react'
import { useEffect } from 'react'
import PrimaryButton from '../../../../Product/components/atoms/PrimaryButton'
import CustomDialog from '../../../../Product/components/organisms/Dialog'
import PosContext from '../../../../Product/context/plugin/pos/posContext'
import AuthContext from '../../../../Product/context/auth/authContext'
import { useContext } from 'react'
import { useFormik } from 'formik'
import { mapData } from '../../../../Product/common/components'
import { getLocation } from '../../..'
import * as Yup from 'yup'
const AddUpdate = ({ type, id, label }) => {
    const { user } = useContext(AuthContext)
    const { updateLocation, createLocation, retrieveLocation, location, responseStatus } =
        useContext(PosContext)
    const [open, setOpen] = useState(false)
    const [btnLoading, setBtnLoading] = useState(false)
    const [state, setState] = useState([])
    const [country, setCountry] = useState([])
    const toggleOpen = () => setOpen(!open)

    const validationArray = Yup.object({
        display_name: Yup.string()
            .trim()
            .matches(/^[a-zA-Z\s]*$/g, 'The special characters and numbers are not allowed!')
            .max(20, 'Maximum 20 characters')
            .required('Required'),
        country: Yup.string().trim().required('Required'),
        state: Yup.string().trim().required('Required'),
        address: Yup.string()
            .trim()
            .matches(/^[0-9a-zA-Z- ,.#()]*$/g, 'Special characters not allowed')
            .max(250, 'Maximum 250 characters')
            .required('Required'),
        city: Yup.string()
            .trim()
            .matches(/^[_A-zA-Z]*((-|\s)*[_A-zA-Z])*$/g, 'Only characters allowed')
            .required('Required'),
        zip: Yup.string()
            .trim()
            .matches(/^[0-9a-zA-Z- ,.#()]*$/g, 'Special characters not allowed')
            .max(6, 'Maximum 6 characters')
            .required('Required'),
    })

    const formik = useFormik({
        initialValues: {
            user_id: user?.id,
            site_id: global?.storeDetails?.site_id,
            location_id: id,
            display_name: '',
            city: '',
            country: 224,
            address: '',
            zip: '',
            state: '',
        },
        enableReinitialize: true,
        validationSchema: validationArray,
        onSubmit: (values) => {
            setBtnLoading(true)
            var data = { ...values }
            data.country = 'US'
            if (type === 'update') {
                updateLocation(data)
            } else {
                createLocation(data)
            }
        },
    })
    useEffect(() => {
        if (id && user && open) {
            retrieveLocation({
                location_id: id,
                user_id: user?.id,
                site_id: global?.storeDetails?.site_id,
            })
        }
    }, [user, open])

    useEffect(() => {
        if (Object.keys(location).length) {
            formik.setValues({
                ...formik.values,
                display_name: location.display_name,
                city: location.address?.city,
                address: location.address?.line1,
                zip: location.address?.postal_code,
                state: location.address?.state,
            })
        }
    }, [location])

    useEffect(() => {
        if (responseStatus) {
            if (
                responseStatus.from === 'createLocation' ||
                responseStatus.from === 'updateLocation'
            ) {
                if (responseStatus.status === 'success') {
                    formik.resetForm()
                    setOpen(false)
                    setBtnLoading(false)
                } else {
                    setBtnLoading(false)
                }
            }
        }
    }, [responseStatus])

    useEffect(() => {
        if (formik.values.country) {
            getLocation(formik.values.country, setState)
        }
    }, [formik.values.country])

    const fields = [
        {
            label: 'Display Name *',
            placeholder: 'Enter Display Name',
            type: 'text',
            class: 'col-sm-6 col-12',
            name: 'display_name',
            formik: formik,
        },
        {
            label: 'Address *',
            placeholder: 'Enter Address',
            type: 'text',
            class: 'col-sm-6 col-12',
            name: 'address',
            formik: formik,
        },
        {
            label: 'Country' + '*',
            placeholder: 'Select your country',
            class: 'col-sm-6 col-12',
            type: 'select',
            options: [
                {
                    show: 'United States',
                    value: 224,
                },
            ],
            name: 'country',
            formik: formik,
            disabled: true,
        },
        {
            label: 'State' + '*',
            placeholder: 'Select your state',
            class: 'col-sm-6 col-12',
            type: 'select',
            options: state,
            name: 'state',
            formik: formik,
            disabled: state.length > 0 ? false : true,
        },
        {
            label: 'City *',
            placeholder: 'Enter City',
            type: 'text',
            class: 'col-sm-6 col-12',
            name: 'city',
            formik: formik,
        },
        {
            label: 'Zip Code *',
            placeholder: 'Enter Zip Code',
            type: 'text',
            class: 'col-sm-6 col-12',
            name: 'zip',
            formik: formik,
        },
    ]
    return (
        <div className="updateAction">
            <Button variant="outlined" className="ml-2" onClick={toggleOpen}>
                {label ? label : `${type === 'update' ? 'Update Location' : 'Add Location'}}`}
            </Button>
            <CustomDialog
                title={`${type === 'update' ? 'Update' : 'Add'} Location`}
                open={open}
                function={toggleOpen}
            >
                <div className="row">{mapData(fields)}</div>
                <div className="actionWrapper">
                    <Button onClick={() => setOpen(false)} disabled={btnLoading}>
                        {'Cancel'}
                    </Button>
                    <PrimaryButton
                        onClick={formik.handleSubmit}
                        type="button"
                        disabled={btnLoading}
                        label={
                            btnLoading
                                ? 'Loading...'
                                : `${type === 'update' ? 'Update' : 'Add'} Location`
                        }
                    />
                </div>
            </CustomDialog>
        </div>
    )
}

export default AddUpdate
