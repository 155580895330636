import React, { useState, useEffect } from 'react'
import moment from 'moment-timezone'
import Popup from '../../Product/components/organisms/Popup'
import { currencyFormat } from '../../Product/common/components'
import ReactHTMLParser from 'react-html-parser'

const DateTime = (props) => {
    const { auctionData } = props
    return (
        <Popup
            {...props}
            size="md"
            modalClass={`save-search-modal ${props.classNames}`}
            modaltitle="Date & Times"
        >
            <div className="date-time-container">
                <div className="dates-time-wrapper">
                    <h3 className="pop-dt-head">Add to Calendar:</h3>
                    <div
                        className="d-flex gap-3 align-content-center justify-content-start"
                        style={{ gap: '15px' }}
                    >
                        <a
                            href="https://calendar.google.com/calendar/render?action=TEMPLATE&amp;text=09%2F17%2F2024+Dallas%2C+TX+1413+Items+Online+Auction+ALL+CATEGORIES&amp;location=2828+Anode+Ln%2C+Dallas%2C+TX++75220&amp;dates=20240917T175500/20240917T230700&amp;sprop=Lots+of+Auctions&amp;sprop=name:https://auction.lotsofauctions.com/auction/8203&amp;details=Start+Time%3A+Friday+09%2F13%2F2024+at+6%3A00+PM%0AEnd+Time%3A+Tuesday+09%2F17%2F2024%C2%A0at+6%3A00+PM%0AThis+online+auction+includes+cool+liquidation+home+improvement+supplies%2C+liquidation+kitchen+supplies%2C+liquidation+small+appliances%2C+liquidation+home+goods%2C+liquidation+electronics%2C+liquidation+furniture%2C+and+more+from+the+best+name+brands.%0APLEASE+NOTE%3A+WINNING+BIDDERS+MUST+PICK-UP+THEIR+ITEM%28S%29+IN+DALLAS%2C+TEXAS"
                            target="_blank"
                            rel="noreferrer"
                            className="google"
                        >
                            Google
                        </a>
                        <a
                            href="https://calendar.yahoo.com/?v=60&amp;VIEW=d&amp;in_loc=2828+Anode+Ln%2C+Dallas%2C+TX++75220&amp;type=20&amp;TITLE=09%2F17%2F2024+Dallas%2C+TX+1413+Items+Online+Auction+ALL+CATEGORIES&amp;ST=20240917T175500&amp;ET=20240917T230700&amp;URL=https://auction.lotsofauctions.com/auction/8203&amp;DESC=Start+Time%3A+Friday+09%2F13%2F2024+at+6%3A00+PM%0AEnd+Time%3A+Tuesday+09%2F17%2F2024%C2%A0at+6%3A00+PM%0AThis+online+auction+includes+cool+liquidation+home+improvement+supplies%2C+liquidation+kitchen+supplies%2C+liquidation+small+appliances%2C+liquidation+home+goods%2C+liquidation+electronics%2C+liquidation+furniture%2C+and+more+from+the+best+name+brands.%0APLEASE+NOTE%3A+WINNING+BIDDERS+MUST+PICK-UP+THEIR+ITEM%28S%29+IN+DALLAS%2C+TEXAS"
                            target="_blank"
                            rel="noreferrer"
                            className="yahoo"
                        >
                            Yahoo
                        </a>
                        <a
                            href="https://outlook.live.com/owa/?rru=addevent&amp;startdt={start}&amp;enddt={end}&amp;subject={title}&amp;location={location}&amp;body={description}&amp;allday=false&amp;path=/calendar/view/Month"
                            target="_blank"
                            rel="noreferrer"
                            className="outlook"
                        >
                            Outlook
                        </a>
                        <a href="/event/auction/8203" target="_blank" className="ical">
                            iCal
                        </a>
                    </div>
                </div>
                <div className="dates-time-wrapper">
                    <h3 className="pop-dt-head">Removal Terms:</h3>
                    {ReactHTMLParser(
                        auctionData?.store_comment_five
                            ?.replace(
                                '{{event.auction_location}}',
                                `${auctionData?.address} ${auctionData?.city} ${auctionData?.state} ${auctionData?.zipcode}`,
                            )
                            ?.replace(
                                '{{event.received_date}}',
                                moment(auctionData.date_closed)
                                    .add(7, 'days')
                                    .tz(moment.tz.guess())
                                    .format('MM/DD/YYYY'),
                            )
                            ?.replace(
                                '{{event.picked}}',
                                moment(auctionData.date_closed)
                                    .add(7, 'days')
                                    .tz(moment.tz.guess())
                                    .format('MM/DD/YYYY'),
                            ),
                    )}
                </div>
                <div className="dates-time-wrapper">
                    <h3 className="pop-dt-head">Inspection</h3>
                    {ReactHTMLParser(
                        auctionData?.store_comment_three
                            ?.replace(
                                '{{event.auction_location}}',
                                `${auctionData?.address} ${auctionData?.city} ${auctionData?.state} ${auctionData?.zipcode}`,
                            )
                            ?.replace(
                                '{{event.returns}}',
                                moment(auctionData.date_closed)
                                    .add(7, 'days')
                                    .tz(moment.tz.guess())
                                    .format('MM/DD/YYYY'),
                            ),
                    )}
                </div>

                <div className="dates-time-wrapper">
                    {ReactHTMLParser(
                        auctionData?.store_comment_two
                            ?.replace(
                                '{{event.start_date}}',
                                moment(auctionData?.date_added)
                                    .tz(moment.tz.guess())
                                    .format('MMM Do YYYY, h:mm a z'),
                            )
                            ?.replace(
                                '{{event.end_date}}',
                                moment(auctionData.date_closed)
                                    .tz(moment.tz.guess())
                                    .format('MMM Do YYYY, h:mm a z'),
                            ),
                    )}
                </div>
            </div>
        </Popup>
    )
}

export default DateTime
