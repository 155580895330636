import React from 'react'
import './Registration.css'
import { Logo } from '../../../utils'
import SignupFunction from '../../../utils/CommonFunctionality/SignupFunction'

const Registration = () => {
    return (
        <>
            <div className="clearfix row regCnt">
                <div className="col-lg-6 d-none d-lg-block pad-0">
                    {global.storeDetails?.site_id == 'AS0605' ||
                    global.storeDetails?.site_id == 'AS0732' ? (
                        <div className="loginImgBox loginImgBoxCincy d-flex justify-content-between flex-column"></div>
                    ) : (
                        <div className="loginImgBox d-flex justify-content-between flex-column"></div>
                    )}
                </div>
                <div className="col-lg-6 col-12">
                    <div className="loginFormBox">
                        <Logo />
                        <SignupFunction title="Signup" subTitle="Create Your Free Account" />
                    </div>
                </div>
            </div>
        </>
    )
}

export default Registration
