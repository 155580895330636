import React, { useState } from 'react'
import algoliasearch from 'algoliasearch/lite'
import {
    InstantSearch,
    SearchBox,
    Hits,
    Highlight,
    RefinementList,
    Pagination,
    Configure,
} from 'react-instantsearch'
import { useHistory } from 'react-router-dom'

const AlgoliaSearchBar = () => {
    const searchClient = algoliasearch(
        global?.pluginConfiguration?.algolia_search?.client_key,
        global?.pluginConfiguration?.algolia_search?.range_key,
    )
    const history = useHistory()
    const params = new URLSearchParams(window.location.search.substring(1))
    const [searchState, setSearchState] = useState(params.get('q') ? params.get('q') : '')
    const [hideHit, setHideHit] = useState(true)
    const HitComponent = ({ hit }) => {
        return (
            <li
                onClick={() => {
                    setHideHit(true)
                    setSearchState(hit.title)
                    history.push(`/search?q=${hit.title}`)
                }}
                style={{ cursor: 'pointer' }}
            >
                <Highlight attribute="title" hit={hit} />
            </li>
        )
    }

    console.log(searchState)
    const handleSubmit = (e) => {
        setHideHit(true)
        history.push(`/search?q=${searchState || ''}`)
    }

    return (
        <InstantSearch
            indexName={global?.pluginConfiguration?.algolia_search?.service_key}
            searchClient={searchClient}
            insights={true}
            initialUiState={{
                [global?.pluginConfiguration?.algolia_search?.service_key]: {
                    query: searchState,
                },
            }}
            onStateChange={(e) => {
                setHideHit(false)
                setSearchState(
                    e.uiState[global?.pluginConfiguration?.algolia_search?.service_key]?.query,
                )
            }}
        >
            <Configure hitsPerPage={40} />
            <SearchBox
                placeholder="What are you looking for?"
                onSubmit={handleSubmit}
                onResetCapture={handleSubmit}
            />
            <RefinementList attribute="title" />
            {searchState && !hideHit && <Hits hitComponent={HitComponent} />}
        </InstantSearch>
    )
}

export default AlgoliaSearchBar
