import React, { useState, useEffect, useContext } from 'react'
import { Link, useHistory } from 'react-router-dom'
import AlertContext from '../../../Product/context/alert/alertContext'
import AuthContext from '../../../Product/context/auth/authContext'
import { mapData, handleRedirectInternal } from '../../../Product/common/components'
import axios from 'axios'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import PrimaryButton from '../../../Product/components/atoms/PrimaryButton'
import PluginContext from '../../../Product/context/plugin/pluginContext'

const sellerOnboardFlutterwave = (props) => {
    const { getAllBanks, createBankAccount, all_banks, responseStatus, clearResponse } =
        useContext(PluginContext)
    const { setAlert } = useContext(AlertContext)
    const { isAuthenticated, user, loadUser } = useContext(AuthContext)
    const history = useHistory()

    const [bankList, setBankList] = useState([])
    const [stateList, setStateList] = useState([])
    const [loading, setLoading] = useState('')
    useEffect(() => {
        if (isAuthenticated) {
            if (user.card_profileid) {
                setAlert('Onboarding already completed!', 'success')
                handleRedirectInternal(history, 'dashboard/profile')
            }
        }
    }, [isAuthenticated])

    const validationArray = Yup.object({
        account_number: Yup.number('Only numbers allowed')
            .typeError('Invalid Account Number!')
            .required('Required!'),

        beneficiary_name: Yup.string()
            .trim()
            // .matches(/^[0-9a-zA-Z]*$/g, 'Special characters not allowed')
            .max(250, 'Maximum 250 characters')
            .required('Required'),

        account_bank: Yup.string().trim().required('Required'),
        user_id: '',
        site_id: '',
    })

    const formik = useFormik({
        initialValues: {
            account_number: '',
            beneficiary_name: '',
            currency: 'NGN',
            account_bank: '',
            user_id: '',
            site_id: '',
        },
        validationSchema: validationArray,
        onSubmit: async (values) => {
            setLoading('Loading...')
            createBankAccount(values)
        },
    })

    useEffect(() => {
        if (responseStatus) {
            if (responseStatus.from === 'createBankAccount') {
                if (responseStatus.status === 'error') {
                    setLoading('')
                    setAlert(responseStatus.message, 'error')
                }
                if (responseStatus.status === 'success') {
                    setAlert(responseStatus.message, 'success')
                    setLoading('')
                    clearResponse()
                    loadUser()
                    handleRedirectInternal(history, `dashboard/profile`)
                }
            }
        }
    }, [responseStatus])

    useEffect(() => {
        if (user) {
            formik.setFieldValue('user_id', user?.id)
            formik.setFieldValue('site_id', global?.storeDetails?.site_id)
        }
    }, [user])
    const productListing = [
        {
            label: 'Account Name *',
            type: 'text',
            placeholder: 'Enter your Account Name',
            class: `col-sm-6 col-12 ${
                formik.values.business_type === 'individual' ? 'd-none' : ''
            }`,
            name: 'beneficiary_name',
            formik: formik,
        },
        {
            label: 'Account Number *',
            type: 'text',
            placeholder: 'Enter your Account Number',
            class: `col-sm-6 col-12 ${
                formik.values.business_type === 'individual' ? 'd-none' : ''
            }`,
            name: 'account_number',
            formik: formik,
        },
        {
            label: 'Bank' + '*',
            placeholder: 'Select Bank',
            class: 'col-12 col-sm-6',
            type: 'select',
            options:
                bankList?.length > 0
                    ? bankList.map((country) => ({
                          show: country.name,
                          value: country.code,
                      }))
                    : [],
            name: 'account_bank',
        },
    ]

    useEffect(() => {
        setBankList(all_banks)
    }, [all_banks])

    useEffect(() => {
        getAllBanks({ country: 'NG', user_id: user?.id, site_id: global.storeDetails.site_id })
    }, [])

    return (
        <form className="pp-form" onSubmit={formik.handleSubmit}>
            <div>
                <div className="row">{mapData(productListing)}</div>
                <PrimaryButton
                    type="submit"
                    label={loading ? loading : 'Submit'}
                    disabled={loading ? true : false}
                />
            </div>
        </form>
    )
}

export default sellerOnboardFlutterwave
