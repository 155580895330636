import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { categories } from '../../utils/categories'
import './categories.css'

const CategoriesList = (props) => {
    let history = useHistory()
    const [filterValues, setFilterValues] = useState([])

    const handleClick = (value) => {
        preventDefault()
        history.push(`/auctions?category=${value}`)
    }

    useEffect(() => {
        setFilterValues(props.category)
    }, [props.category])

    return (
        <div className="categories-container">
            {filterValues.map((data, index) => (
                <div
                    className="category-card"
                    key={index}
                    onClick={() => props.onClick(data.category_id)}
                >
                    <div className="categories-img-box">
                        <img
                            src={
                                data.cat_image
                                    ? process.env.REACT_APP_PRODUCT_IMAGE_URL + data.cat_image
                                    : process.env.REACT_APP_AUCTION_IMAGE_URL +
                                      global?.storeDetails?.logo
                            }
                            data-src="image.jpg"
                            alt={data.name}
                        />
                    </div>
                    <h5>{`${data.name} (${data.product_cnt})`}</h5>
                </div>
            ))}
            {/* {categories.map((category) => (
                <div className="category-card" onClick={() => handleClick(category.category_name)}>
                    <h5>
                        {category.category_name} <br />({category.products_count})
                    </h5>
                    <div className="categories-img-box">
                        <img src={category.category_img} alt={category.category_name} />
                    </div>
                </div>
            ))} */}
        </div>
    )
}

export default CategoriesList
