import React from 'react'
import PropTypes from 'prop-types'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'

import Container from '@material-ui/core/Container'
import Layout from '../Layout'

import DashboardHeader from '../DashboardHeader'
import AllTransactions from '../../../Component/TransactionList/AllTransactions'
import InvoicePanel from '../../../utils/CommonFunctionality/MyBidsPanel/Invoices'

const TabPanel = ({ children, value, index, ...other }) => {
    return (
        <Typography
            component="div"
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && <Box p={3}>{children}</Box>}
        </Typography>
    )
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
}

function a11yProps(index) {
    return {
        id: `buyer-dashboard-${index}`,
        'aria-controls': `buyer-dashboard-tabpanel-${index}`,
    }
}

const Transactions = (props) => {
    const [value, setValue] = React.useState(0)

    const handleChange = (event, newValue) => {
        setValue(newValue)
    }

    return (
        <Layout props={props}>
            <DashboardHeader />
            <Container className="db-bids-wrapper mt-4 lq-db-table">
                <h2 className="mp-head" id="heading">
                    Invoices
                </h2>
                {/* <AllTransactions /> */}
                <InvoicePanel />
            </Container>
        </Layout>
    )
}

export default Transactions
