import React, { useEffect } from 'react'
import Header from '../Header'
import Footer from '../Footer'

const Layout = ({ props, hideComp, children, ...rest }) => {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    return (
        <div className="app-wrapper">
            {/* <h1>Hi {currentUser.name}!</h1> */}
            {!hideComp && <Header />}
            <div className="main-container">{children}</div>
            {!hideComp && <Footer />}
        </div>
    )
}

export default Layout
