import React, { useEffect, useState, useContext } from 'react'
import Layout from '../Layout'
import { useLocation, useHistory } from 'react-router-dom'
import SearchAuctionList from '../../../Component/Search/SearchAuctionList'
import ProductContext from '../../../Product/context/product/productContext'
import NoRecordsFound from '../../../Product/components/atoms/NoRecordsFound'
import './Auctions.css'
import { Button } from '@material-ui/core'
import MultisellerListCard from '../../../Component/ProductCard/MultisellerListCard'
import GridAuction from '../../../Component/ProductCard/gridAuction'
import CustomSelect from '../../../Product/components/atoms/Inputs/CustomSelect'
import Loader from '../../../assets/loader'
import Loaders from '../../../Product/components/molecules/Loaders'
import AlertContext from '../../../Product/context/alert/alertContext'
import { Pagination } from '@material-ui/lab'
const Auctions = (props) => {
    const productContext = useContext(ProductContext)
    const alertContext = useContext(AlertContext)
    const { setAlert, clearAlert, alerts } = alertContext
    const { search_allauctions, getAllSearchAuctions, search_allauctionproducts, getLotList } =
        productContext
    const [loading, setLoading] = useState(true)
    const [viewAuction, setViewAuction] = useState([])
    const [auctionView, setAuctionView] = useState('Grid')
    const [subLotData, setSubLotData] = useState([])
    const [auction_type, setAuction_type] = useState('')
    const { search } = useLocation()
    const history = useHistory()
    const type = new URLSearchParams(search)
    const [zipcode, setZipcode] = useState(type.get('zip') ? type.get('zip') : '')
    const [miles, setMiles] = useState(type.get('mil') ? type.get('mil') : '')
    const [totalItems, setTotalItem] = useState(0)
    const [auctionSearch, setAuctionSearch] = useState({
        page: 1,
        sh_limit: '12',
        orderby: '',
        searchbar: '',
        auc_type: type.get('closed') == 3 ? '3' : type.get('closed') == 2 ? '1' : '2',
        is_auction: 1,
        type: type.get('closed') ? 'closed' : '',
        multi_seller_closed: 'closed',
        zipcode: type.get('zip') ? type.get('zip') : '',
        miles: type.get('mil') ? type.get('mil') : '',
        //is_buynow: 1,
    })
    const handleClick = (e) => {
        e.preventDefault()
        setAuctionSearch({ ...auctionSearch, zipcode, miles })
        var str = `/auctions?zip=${zipcode}&mil=${miles}`
        if (type.get('closed')) {
            str += `&closed=${type.get('closed')}`
        }
        history.push(str)
    }
    useEffect(() => {
        setLoading(true)
        getAllSearchAuctions(auctionSearch, 'auctionSearch')
    }, [auctionSearch])

    useEffect(() => {
        if (loading) {
            setAlert('Please Wait', 'warnning')
        }
    }, [loading])

    useEffect(() => {
        if (search_allauctions.from === 'auctionSearch') {
            setViewAuction(search_allauctions.results.length ? search_allauctions.results : [])
            setTotalItem(search_allauctions.total_pagecnt)

            setTimeout(() => {
                setLoading(false)
            }, 1500)
        }
    }, [search_allauctions])

    const pageOptions = [
        {
            value: '12',
            show: 'Results 12 per page',
        },
        {
            value: '24',
            show: 'Results 24 per page',
        },
        {
            value: '36',
            show: 'Results 36 per page',
        },
    ]

    const sortShow = [
        {
            value: '',
            show: 'All Auctions',
        },
        {
            value: 1,
            show: 'Upcoming Auctions',
        },
        {
            value: 2,
            show: 'Live Auctions',
        },
        {
            value: 3,
            show: 'Completed Auctions',
        },
    ]
    useEffect(() => {
        const params = new URLSearchParams(window.location.search.substring(1))
        setAuctionView(params.get('view') ? params.get('view') : 'Grid')
    }, [window.location.search])
    const handleAuctionView = (type) => {
        let currentUrlParams = new URLSearchParams(window.location.search)
        currentUrlParams.set('view', type)
        history.push(history.location.pathname + '?' + currentUrlParams.toString())
    }
    const onHandlePage = (event, value) => {
        setAuctionSearch({
            ...auctionSearch,
            page: value,
        })
    }
    return (
        <Layout props={props}>
            <div className="customContainer auctionsSearch">
                <div className="d-flex justify-content-between align-items-center flex-wrap mt-5 mb-3 auctionSearchCnt">
                    <h2 className="auctionListTitle">
                        {auctionSearch.auc_type == 1
                            ? 'Upcoming'
                            : auctionSearch.auc_type == 2
                            ? 'Live'
                            : auctionSearch.auc_type == 3
                            ? 'Completed'
                            : 'All'}{' '}
                        Auctions
                    </h2>
                    <div className="auctionsMiscFilter">
                        {/* <form className="nav-standard-top-area-search">
                            <div className="nav-standard-top-area-search-inner zip-Search">
                                <div
                                    className={`inner-Search-form ${
                                        Boolean(global.storeConfigration?.disable_zip?.value)
                                            ? 'zipDisabled'
                                            : ''
                                    }`}
                                >
                                    <input
                                        type="text"
                                        onChange={handleChange}
                                        name="searchbar"
                                        value={searchValues.searchbar}
                                        className="nav-standard-top-area-search-input open-sans"
                                        placeholder="Search"
                                    />

                                    {Boolean(
                                        global.storeConfigration?.disable_zip?.value,
                                    ) ? null : (
                                        <input
                                            type="text"
                                            onChange={(e) => setZipcode(e.target.value)}
                                            name="zipcode"
                                            value={zipcode}
                                            className="nav-standard-top-area-search-input open-sans"
                                            placeholder="Zipcode"
                                        />
                                    )}
                                    <select
                                        className="custom-select"
                                        name="miles"
                                        onChange={(e) => setMiles(e.target.value)}
                                        value={miles}
                                    >
                                        <option selected>Kilometers</option>
                                        <option value="10">10</option>
                                        <option value="20">20</option>
                                        <option value="30">30</option>
                                        <option value="40">40</option>
                                        <option value="50">50</option>
                                        <option value="100">100</option>
                                    </select>
                                </div>
                                <button
                                    onClick={handleClick}
                                    className="lin lin-magnifier nav-standard-top-area-search-sumbit"
                                >
                                    <span className="material-icons">search</span>
                                </button>
                            </div>
                        </form> */}
                        <div className="gridListToggle">
                            <Button
                                className={auctionView === 'Grid' ? 'active' : ''}
                                onClick={() => handleAuctionView('Grid')}
                            >
                                <span className="material-icons">apps</span>
                            </Button>
                            <Button
                                className={auctionView === 'List' ? 'active' : ''}
                                onClick={() => handleAuctionView('List')}
                            >
                                <span className="material-icons">view_list</span>
                            </Button>
                        </div>
                        <CustomSelect
                            label="Results per page"
                            name="resultsPerPage"
                            selectType="noBorder"
                            value={auctionSearch.sh_limit}
                            size="small"
                            onChange={(event, editor) => {
                                setAuctionSearch({
                                    ...auctionSearch,
                                    sh_limit: event.target.value,
                                    page: 1,
                                })
                            }}
                        >
                            {pageOptions.map((opt, optindex) => (
                                <option value={opt.value}>{opt.show}</option>
                            ))}
                        </CustomSelect>
                        <CustomSelect
                            label="Auction Type"
                            value={auctionSearch.auc_type}
                            size="small"
                            selectType="noBorder"
                            name="auc_type"
                            // shrink={search.orderby !== '' ? true : false}
                            onChange={(event, editor) => {
                                if (event.target.value == '3') {
                                    setAuctionSearch({
                                        ...auctionSearch,
                                        auc_type: event.target.value,
                                        type: 'closed',
                                        orderby: 1,
                                        page: 1,
                                    })
                                } else if (event.target.value == '') {
                                    setAuctionSearch({
                                        ...auctionSearch,
                                        auc_type: event.target.value,
                                        type: 'all',
                                        orderby: 'status',
                                        page: 1,
                                    })
                                } else {
                                    setAuctionSearch({
                                        ...auctionSearch,
                                        auc_type: event.target.value,
                                        type: '',
                                        page: 1,
                                        orderby: 1,
                                    })
                                }
                            }}
                        >
                            {sortShow.map((opt, optindex) => (
                                <option value={opt.value}>{opt.show}</option>
                            ))}
                        </CustomSelect>
                    </div>
                </div>
                <>
                    <>
                        {loading ? (
                            <div className="text-center">
                                <Loader />
                            </div>
                        ) : viewAuction.length > 0 ? (
                            <div className={`searchResults ${auctionView}`}>
                                {viewAuction.map((data, index) =>
                                    auctionView === 'Grid' ? (
                                        <GridAuction
                                            key={index}
                                            data={data}
                                            details={`search/product-buyer-auction/${data.id}`}
                                            timerTheme="multiseller"
                                            theme="multiseller"
                                            // lotCount={data.lotCount ? data.lotCount : 0}
                                        />
                                    ) : (
                                        <MultisellerListCard
                                            key={index}
                                            data={data}
                                            allsearchitems={viewAuction}
                                            // lotCount={data.lotCount ? data.lotCount : 0}
                                            details={`search/product-buyer-auction/${data.id}`}
                                        />
                                    ),
                                )}
                                <Pagination
                                    count={Math.ceil(totalItems / auctionSearch.sh_limit)}
                                    page={auctionSearch.page}
                                    onChange={onHandlePage}
                                />
                            </div>
                        ) : (
                            <NoRecordsFound />
                        )}
                    </>
                </>
            </div>
        </Layout>
    )
}

export default Auctions
