import React from 'react'
import Container from '@material-ui/core/Container'
import DashboardHeader from '../DashboardHeader'
import Layout from '../Layout'
import MyBidsPanel from '../../../utils/CommonFunctionality/MyBidsPanel'
import CommonAuctionCart from '../../../utils/CommonFunctionality/DualCart/CommonAuctionCart'
import AllProducts from '../../../Component/MyBids/AllProducts'

const AuctionCart = (props) => {
    return (
        <Layout props={props}>
            <Container className="db-bids-wrapper mt-4">
                <h2 className="mp-head">AUCTION CART</h2>
                <CommonAuctionCart />
            </Container>
        </Layout>
    )
}

export default AuctionCart
