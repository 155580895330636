import React, { useState, useEffect, useContext, useRef, Fragment } from 'react'
import ReadMoreReact from 'read-more-react'
import ImageGallery from 'react-image-gallery'
import Toaster from '../Toaster'
import FavouriteCheckbox from '../../Product/components/atoms/FavoriteCheckbox'
import { Link, useHistory, useParams } from 'react-router-dom'
import moment from 'moment-timezone'
import Bidhistory from '../Bidhistory'
import 'react-confirm-alert/src/react-confirm-alert.css'
import BuyerContext from '../../Product/context/buyer/buyerContext'
import AuthContext from '../../Product/context/auth/authContext'
import CartContext from '../../Product/context/cart/cartContext'
import AlertContext from '../../Product/context/alert/alertContext'
import ProductContext from '../../Product/context/product/productContext'
import StripeCardContext from '../../Product/context/stripe/card/cardContext'
import DirectStripeContext from '../../Product/context/directStripe/directStripeContext'
import MessagePlugin from '../../utils/CommonFunctionality/MessagePlugin'
import UserContext from '../../Product/context/user/userContext'

import Bidding from '../../Product/components/molecules/Bidding/BiddingItem'
import Biddingforwardandreverse from '../../Product/components/molecules/Bidding/biddingforwardandreverse'
import Timer from '../../Product/common/timer'
import {
    messageHandlerSingle,
    timeHandler,
    make_an_offer_update_socket,
} from '../../Product/common/socketHandler'
import { socket, socketAuction } from '../../Product/common/socket'
import './slider.css'
import { Button, Drawer, Tooltip } from '@material-ui/core'
import Nodata from '../NoData/nodata'
import {
    currencyFormat,
    dateTimeFormatFunction,
    handleRedirectInternal,
    removeHTMLTags,
    mapData,
} from '../../Product/common/components'
import { withStyles } from '@material-ui/core/styles'
import MuiAccordion from '@material-ui/core/Accordion'
import MuiAccordionSummary from '@material-ui/core/AccordionSummary'
import MuiAccordionDetails from '@material-ui/core/AccordionDetails'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import Facebook from '../../utils/CommonFunctionality/Share/Facebook'
import Whatsapp from '../../utils/CommonFunctionality/Share/Whatsapp'
import Twitter from '../../utils/CommonFunctionality/Share/Twitter'
import Email from '../../utils/CommonFunctionality/Share/Email'
import Pinterest from '../../utils/CommonFunctionality/Share/Pinterest'
import {
    imageError,
    getunitsdetails,
    getdimensiondetails,
    get_reatil_price_per,
} from '../../utils/commonFunctions'
import Standalone from './Standalone'
import Loaders from '../../Product/components/molecules/Loaders'
import SecondaryButton from '../../Product/components/atoms/SecondaryButton'
import PrimaryButton from '../../Product/components/atoms/PrimaryButton'
import Popup from '../../Product/components/organisms/Popup'
import Popover from '@material-ui/core/Popover'
import BidStatus from '../../Product/components/molecules/Bidding/BidStatus'
import axios from 'axios'
import {
    allCondition,
    storageOptions,
    unitOptions,
    sustainabilityOptions,
    brandsliqutatios,
    collectibleConditions,
} from '../../utils'
import ShippingRates from '../../utils/CommonFunctionality/ShippingRate'
import Copy from '../../utils/CommonFunctionality/Share/Copy'
import HowBidPopup from '../HowBiddingWorks'
import ReactPlayer from 'react-player'
import publicIp from 'public-ip'
import CurrencyConverterFormat from '../../utils/CommonFunctionality/CurrencyConverter/CurrencyConverterFormat'
import { useTranslation } from 'react-i18next'
import NoRecordsFound from '../../Product/components/atoms/NoRecordsFound'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { NavLink } from 'react-router-dom/cjs/react-router-dom.min'
import Pickupfunction from '../Pickuppopup'
import GaugeChart from 'react-gauge-chart'
import { getBiddingHistory } from '../../Services/Bidding.service'
const Accordion = withStyles({
    root: {
        border: '1px solid rgba(0, 0, 0, .125)',
        boxShadow: 'none',
        '&:not(:last-child)': {
            borderBottom: 0,
        },
        '&:before': {
            display: 'none',
        },
        '&$expanded': {
            margin: 'auto',
        },
    },
    expanded: {},
})(MuiAccordion)

const AccordionSummary = withStyles({
    root: {
        backgroundColor: '#fff',
        marginBottom: -1,
        minHeight: 40,
        '&$expanded': {
            minHeight: 40,
        },
    },
    content: {
        '&$expanded': {
            margin: '0',
        },
    },
    expanded: {},
})(MuiAccordionSummary)

const AccordionDetails = withStyles((theme) => ({
    root: {
        padding: '10px 16px',
    },
}))(MuiAccordionDetails)

const MultisellerSlider = ({
    type,
    selectedLot,
    handleClose,
    viewPage,
    card_theme,
    itemDetails,
    currency,
    from,
    storeTheme,
    reverse_auction,
    pvTabList,
    getAllProducts,
    base_url,
    theme,
    conditions,
    enableNextBid,
    fromhomecomponants,
}) => {
    const buyerContext = useContext(BuyerContext)
    const cartContext = useContext(CartContext)
    const alertContext = useContext(AlertContext)
    const [calc_loading, setCalcLoading] = useState(false)
    const oneYear = new Date().setFullYear(new Date().getFullYear() + 1)
    const { t } = useTranslation()
    const authContext = useContext(AuthContext)
    const { get_all_card_details } = useContext(StripeCardContext)
    const { usercards } = useContext(DirectStripeContext)
    const [productcategory, setProductcategory] = useState('')
    const [productsubcategory, setProductsubcategory] = useState('')
    const [open_pickup, setOpenpickup] = useState('close')
    const { insertProductView, insert_make_proposels, getOverAllCategories, categories } =
        useContext(ProductContext)
    const [followornot, setFolloworNot] = useState(1)
    const { follow } = useContext(UserContext)
    const [addfav, setFav] = useState(0)
    const { setAlert } = alertContext
    const [expanded, setExpanded] = useState('panel0')
    const [watchlist, setWatchlist] = useState(true)
    const [artTheme, setArtTheme] = useState(null)
    const { user, isAuthenticated } = authContext
    const {
        buyer_allproducts,
        getAllBuyerProducts,
        product_view,
        getViewProduct,
        getPolicslist,
        get_ship_station_amount,
        shipping_rate_calculation,
        clear_ship_station_amount,
        product_view_more,
    } = buyerContext
    const { addToCart, buyer_cartitems, getUserCart, removeFromCart, responseStatus } = cartContext
    const history = useHistory()
    const [historyData, setHistoryData] = useState({})
    const [cards, setCards] = useState([])
    const [loading, setLoading] = useState(true)
    const [disabled, setDisabled] = useState(false)
    const [selectedProductImage, setSelectedProductImage] = useState([])
    const [productVideos, setProductVideos] = useState([])
    const [selectedProduct, setSelectedProduct] = useState({})
    const [product_view_more_detail, setProductviewmore] = useState({})
    const [showDescription, setShowDescription] = useState(false)
    const [showDescriptions, setShowDescriptions] = useState(false)
    const [showNotes, setShowNotes] = useState(false)
    const [descripText, setDescripText] = useState(null)
    const [descripTexts, setDescripTexts] = useState(null)
    const [notesText, setNotesText] = useState(null)
    const [shipping_amount, setShipping_amount] = useState(0)
    const [locCategory, setLocCategory] = useState([])
    const [state, setState] = useState({
        right: false,
        bottom: false,
        data: {},
    })
    const [homeDepot, setHomeDepot] = useState({
        price: 0,
        date: '',
        product_url: '',
    })
    const [ASIN, setASIN] = useState({
        price: 0,
        date: '',
        product_url: '',
    })
    const { id } = useParams()
    const [howBidPopup, setHowBidPopup] = useState(false)
    const [show_popup, setShowpopup] = useState(false)
    const [auctionTimer, setAuctionTimer] = useState('')
    const [openSocialShare, setOpenSocialShare] = useState(null)
    const [viewLot, setViewLot] = useState(true)
    const [activePolicy, setActivePolicy] = useState(false)
    const [pickupDetails, setPickupDetails] = useState(false)
    const [percentage, setPercentage] = useState(0)
    const [bidMeter, setBidMeter] = useState({ text: '', backgroundColor: '' })
    const [bidHistory, setBidHistory] = useState([])
    const [showMoreBidHistory, setShowMoreBidHistory] = useState(false)

    const getTextForPercentage = (percent) => {
        if (percent >= 0.8) {
            return { text: 'High', backgroundColor: '#EA4228' }
        } else if (percent > 0.5) {
            return { text: 'Medium', backgroundColor: '#F5CD19' }
        } else {
            return { text: 'Just a bit more will get you there', backgroundColor: '#5BE12C' }
        }
    }

    useEffect(() => {
        if (selectedProduct?.bidtopstatus) {
            var { _text, _backgroundColor } = getTextForPercentage(
                selectedProduct?.bidtopstatus ? 0.5 : 0.2,
            )
            setBidMeter({ text: _text, backgroundColor: _backgroundColor })
        }
    }, [])

    useEffect(() => {
        setPercentage(0.5)
    }, [])

    // console.log('test', selectedProduct?.bidtopstatus)
    // useEffect(() => {
    //     setPercentage(0.5)
    // }, [])
    const SocialShareClick = (event) => {
        setOpenSocialShare(event.currentTarget)
    }

    const SocialShareClock = () => {
        setOpenSocialShare(null)
    }

    const popoverid = open ? 'simple-popover' : undefined

    const isArtTheme = document.querySelector('body').classList.contains('artTheme')

    useEffect(() => {
        if (isArtTheme) {
            setArtTheme(isArtTheme)
        } else setArtTheme('')
    }, [isArtTheme])

    useEffect(() => {
        if (selectedLot) {
            setLoading(true)
            var data = {
                product_id: selectedLot,
                need_followflag: artTheme ? 1 : undefined,
            }
            if (parseInt(reverse_auction) == 1) {
                data.crop_auction_type = 'buyer'
            }
            data.get_auction_details =
                global.pluginConfiguration?.limit_pickup_days?.enable == 1 ? 1 : 0
            getViewProduct(data)
        } else if (id && !window.location.pathname.includes('product-buyer-auction')) {
            setLoading(true)
            getViewProduct({
                product_id: id,
                need_followflag: artTheme ? 1 : undefined,
                get_auction_details:
                    global.pluginConfiguration?.limit_pickup_days?.enable == 1 ? 1 : 0,
            })
        }
        if (global?.storeConfigration?.bodyClass?.value == 'artTheme') {
            getOverAllCategories({}, global?.storeConfigration?.cat_mis_match?.value)
        }
    }, [selectedLot, id])

    useEffect(() => {
        if (locCategory.length > 0) {
            console.log('++++++len', locCategory, selectedProduct?.category_id)
            if (
                locCategory.filter(
                    (val) =>
                        parseInt(val.id) ==
                        parseInt(selectedProduct?.category_id ? selectedProduct.category_id : 0),
                ).length > 0
            ) {
                console.log('++++++')
                setProductcategory(
                    locCategory.filter(
                        (val) =>
                            parseInt(val.id) ==
                            parseInt(
                                selectedProduct?.category_id ? selectedProduct.category_id : 0,
                            ),
                    )[0]?.name,
                )
                setProductsubcategory(
                    locCategory.filter(
                        (val) =>
                            parseInt(val.id) ==
                            parseInt(
                                selectedProduct?.category_id ? selectedProduct.category_id : 0,
                            ),
                    )[0]?.filters?.length > 0
                        ? locCategory
                              .filter(
                                  (val1) =>
                                      parseInt(val1.id) ==
                                      parseInt(
                                          selectedProduct?.category_id
                                              ? selectedProduct.category_id
                                              : 0,
                                      ),
                              )[0]
                              ?.filters.filter(
                                  (val1) =>
                                      parseInt(val1.id) ==
                                      parseInt(
                                          selectedProduct?.subcategory
                                              ? selectedProduct.subcategory
                                              : 0,
                                      ),
                              )[0]?.name
                        : '',
                )
            } else {
                setProductcategory('')
                setProductsubcategory('')
            }
        } else {
            setProductcategory('')
            setProductsubcategory('')
        }
    }, [locCategory])

    useEffect(() => {
        setLocCategory(categories)
        // return () => {
        //     console.log('_____')
        //     setLocCategory([])
        //     setProductcategory('')
        //     setProductsubcategory('')
        //     // setSelectedProduct({})
        // }
    }, [categories])

    const userFollow = (followed, user_id) => {
        if (!isAuthenticated) {
            return handleRedirectInternal(history, 'login')
        }
        //setFolloworNot(followed)
        follow({ follow: followed === 1 ? 0 : 1, user_id: user_id }, setFolloworNot)
    }

    const insertLotCount = async () => {
        const ip = await publicIp.v4()
        insertProductView({ project_id: product_view?.results?.id, user_id: ip })
    }

    useEffect(() => {
        setLoading(true)
        if (product_view.results) {
            if (product_view.results.custom_field_8) {
                const addr = JSON.parse(product_view?.results?.custom_field_8)
                product_view.results.address = addr.address
                product_view.results.zipcode = addr.zipcode
            }
            if (
                product_view.results.lotof &&
                global.storeConfigration?.hyper_micro_popup_more?.value == 1
            ) {
                getPolicslist({ auctionid: product_view.results.lotof })
            }
            if (product_view.results.custom_field_9) {
                const food =
                    typeof product_view?.results?.custom_field_9 != 'string'
                        ? JSON.parse(product_view?.results?.custom_field_9)
                        : product_view?.results?.custom_field_9
                product_view.results.storage = food.storage
                product_view.results.package_date = food.package_date
                product_view.results.selling_unit = food.selling_unit
                product_view.results.total_weight = food.total_weight
            }
            if (product_view.results.custom_field_9?.includes('http')) {
                product_view.results.videos = product_view.results.custom_field_9
            }
            if (product_view.results.extra_fields) {
                if (product_view.results.extra_fields?.includes('}')) {
                    const extra = JSON.parse(product_view?.results?.extra_fields)

                    if (global.storeConfigration?.more_details_page?.value == 1) {
                        product_view.results.athelet_name = extra.m_athelet
                        product_view.results.team_name = extra.m_team
                        product_view.results.brand_name = extra.m_brand
                        product_view.results.autograph_name = extra.m_autographed
                        product_view.results.year_name = extra.m_year
                    } else if (global.storeConfigration?.fishing_theme?.value != 1) {
                        product_view.results.condition_field = extra.condition_field
                        product_view.results.damaged_field = extra.damaged_field
                        product_view.results.functional_field = extra.functional_field
                        product_view.results.missing_parts_field = extra.missing_parts_field
                    } else {
                        product_view.results.extra_heading = extra.shift()
                        product_view.results.extra = extra
                    }
                }
            }
            if (
                product_view?.results?.follow != null &&
                product_view?.results?.follow != undefined
            ) {
                setFolloworNot(parseInt(product_view.results.follow))
            }
            setSelectedProduct({
                ...product_view.biddetails,
                ...product_view.results,
                incrementamt: product_view.incrementamt,
            })

            setShipping_amount(0)

            if (product_view?.attachments.length) {
                product_view?.attachments.forEach(function (data) {
                    data['original'] = `${
                        // product_view.results?.store_id == global?.storeDetails?.id ||
                        product_view.results?.store_id === 0
                            ? process.env.REACT_APP_BASE_URL + 'uploads/product/' + data.src
                            : process.env.REACT_APP_PRODUCT_IMAGE_URL + data.src
                    }`

                    data['thumbnail'] = `${
                        // product_view.results?.store_id == global?.storeDetails?.id ||
                        product_view.results?.store_id === 0
                            ? process.env.REACT_APP_BASE_URL + 'uploads/product/' + data.src
                            : process.env.REACT_APP_PRODUCT_IMAGE_URL + data.src
                    }`
                })
                setSelectedProductImage(product_view.attachments)
            } else {
                setSelectedProductImage([
                    {
                        original: global?.storeDetails?.logoValue,
                        thumbnail: global?.storeDetails?.logoValue,
                    },
                ])
            }

            if (`${product_view?.results.videos}`.trim()) {
                let temp_vid = []
                product_view?.results.videos.split(',').forEach(function (data) {
                    temp_vid.push(
                        data.includes('http')
                            ? data
                            : `${process.env.REACT_APP_BASE_URL + 'uploads/' + data}`,
                    )
                    // console.log(data, '++++++++++++++++++++')
                })
                // console.log(temp_vid)
                setProductVideos(temp_vid)
            }
            if (parseInt(product_view.results.buynow) === 1) {
                getUserCart()
            }

            setTimeout(() => {
                setLoading(false)
            }, 1500)
            insertLotCount()
        }
    }, [product_view.results])
    useEffect(() => {
        if (selectedProductImage.length > 0 && parseInt(fromhomecomponants) == 1) {
            var image_attachment = selectedProductImage
            image_attachment.map((data) => {
                if (parseInt(selectedProduct.store_id) == 0) {
                    data['original'] = `${
                        process.env.REACT_APP_BASE_URL + 'uploads/product/' + data.src
                    }`

                    data['thumbnail'] = `${
                        process.env.REACT_APP_BASE_URL + 'uploads/product/' + data.src
                    }`
                } else {
                    data['original'] = `${process.env.REACT_APP_PRODUCT_IMAGE_URL + data.src}`

                    data['thumbnail'] = `${process.env.REACT_APP_PRODUCT_IMAGE_URL + data.src}`
                }
            })
        }
    }, [selectedProductImage])

    useEffect(() => {
        const getHistory = async () => {
            // setLoading(true)
            let responce = await getBiddingHistory(selectedProduct.id)
            try {
                if (
                    responce.data &&
                    responce.data.success === 'yes' &&
                    Array.isArray(responce.data.results)
                ) {
                    setBidHistory([...responce.data.results])
                }
            } catch (err) {
                // console.log(err)
            } finally {
                // setLoading(false)
            }
        }
        selectedProduct.id && getHistory()
        // console.log('value===>', props.value)
    }, [selectedProduct])

    useEffect(() => {
        if (global?.storeConfigration?.hyper_micro_popup_more?.value == 1) {
            if (product_view_more.length > 0) {
                setProductviewmore(product_view_more[0])
            } else {
                setProductviewmore({})
            }
        }
    }, [product_view_more])

    useEffect(() => {
        let videoArr = []
        if (productVideos?.length) {
            videoArr = productVideos.map((val) => ({
                original: val,
                thumbnail: '/assets/images/videoCamera.png',
                embedUrl: val,
                renderItem: renderVideo.bind(this),
            }))[0]
            // console.log(videoArr, 'videoArr')
        }
        product_view?.attachments?.length
            ? setSelectedProductImage([...selectedProductImage?.flat(), videoArr])
            : setSelectedProductImage([videoArr])
    }, [productVideos])
    const renderVideo = (item) => {
        return (
            <div>
                <ReactPlayer
                    width="100%"
                    height="500px"
                    light={false}
                    controls={true}
                    url={item.embedUrl}
                />
            </div>
        )
    }

    useEffect(() => {
        if (buyer_cartitems.results) {
            if (selectedProduct.id) {
                let position = _.findIndex(buyer_cartitems.results, {
                    project_id: selectedProduct.id,
                })
                position === -1 ? setDisabled(false) : setDisabled(true)
            }
        }
    }, [buyer_cartitems.results])

    const handleClick = async (e) => {
        if (
            !cards.length &&
            global.storeDetails?.theme != 4 &&
            global?.storeConfigration?.credit_card_no_need?.value != 1
        ) {
            setAlert('Add a card to buy!', 'error')
            return handleRedirectInternal(history, 'dashboard/cards')
        }
        if (
            buyer_cartitems.results.find(
                (val) => parseInt(val.product_id) === parseInt(selectedProduct.id),
            )
        ) {
            removeFromCart({ id: selectedProduct.id })
        } else {
            addToCart({ id: selectedProduct.id, qty: selectedProduct.qty })
        }
    }
    useEffect(() => {
        if (get_all_card_details) {
            setCards(get_all_card_details.records)
        }
    }, [get_all_card_details])
    useEffect(() => {
        setDescripText(selectedProduct?.title)
        setDescripTexts(selectedProduct?.description)
        setNotesText(selectedProduct?.style_period)
        setShowDescription(false)
        setShowDescriptions(false)
        setShowNotes(false)
    }, [selectedProduct, descripText, descripTexts])
    useEffect(() => {
        if (usercards?.length) {
            setCards(usercards)
        }
    }, [usercards])

    useEffect(() => {
        if (selectedProduct) {
            if (
                selectedProduct.hd_product_id &&
                selectedProduct.mprice &&
                selectedProduct.retailer_link
            ) {
                setHomeDepot({
                    price: selectedProduct.mprice,
                    date: '',
                    product_url: selectedProduct.retailer_link,
                })
            } else {
                setHomeDepot({
                    price: 0,
                    date: '',
                    product_url: '',
                })
            }
            if (
                selectedProduct.asin_no &&
                selectedProduct.mprice &&
                selectedProduct.retailer_link
            ) {
                setASIN({
                    price: selectedProduct.mprice,
                    product_url: selectedProduct.retailer_link,
                })
            } else {
                setASIN({
                    price: 0,
                    date: '',
                    product_url: '',
                })
            }
        }
        setViewLot(true)
        setActivePolicy(false)
        setPickupDetails(false)
    }, [selectedProduct])
    // useEffect(() => {
    //     if (responseStatus) {
    //         if (responseStatus.from === 'addToCart') {
    //             if (responseStatus.status === 'success') {
    //                 getUserCart()
    //             }
    //         }
    //     }
    // }, [responseStatus])

    const viewProductRef = useRef(selectedProduct)
    const userRef = useRef(user)

    // Sets product and user refrences on page load
    useEffect(() => {
        viewProductRef.current = selectedProduct
        userRef.current = user
    })

    const handler = (message, type) => {
        messageHandlerSingle(
            message,
            viewProductRef.current,
            userRef.current,
            setAlert,
            setSelectedProduct,
            type,
        )
    }
    const timehandlersingle = (message, type) => {
        timeHandler(
            message,
            viewProductRef.current,
            userRef.current,
            setAlert,
            setSelectedProduct,
            type,
        )
    }

    const multiseller_handler = (message, type) => {
        if (parseInt(viewProductRef.current.content_head5) == 0) {
            messageHandlerSingle(
                message,
                viewProductRef.current,
                userRef.current,
                setAlert,
                setSelectedProduct,
                type,
            )
        }
    }

    const make_an_offer_update_new = (message, type) => {
        make_an_offer_update_socket(
            message,
            viewProductRef.current,
            userRef.current,
            setAlert,
            setSelectedProduct,
            type,
        )
    }

    useEffect(() => {
        socket.on('realclosedupdates', (data) => {
            if (global.pluginConfiguration?.reverse_auction?.enable) {
                data.id = data.pid
            }
            handler(data, 'realclosedupdates')
        })
        socket.on('bidAddtime', (data) => {
            if (global.pluginConfiguration?.reverse_auction?.enable) {
                multiseller_handler(data, 'bidAddtime')
            } else {
                handler(data, 'bidAddtime')
            }
        })
        socket.on('cancelbidemitted', (data) => {
            // console.log(data, 'cancellllllllllll')
            handler(data, 'cancelbidemitted')
        })
        socket.on('make_an_offer_update', (data) => {
            make_an_offer_update_new(data, 'make_an_offer')
        })

        socketAuction.on('realclosedupdates', (data) => {
            handler(data, 'realclosedupdates')
        })
        socketAuction.on('bidAddtime', (data) => {
            if (global.pluginConfiguration?.reverse_auction?.enable) {
                multiseller_handler(data, 'bidAddtime')
            } else {
                handler(data, 'bidAddtime')
            }
        })
        socketAuction.on('cancelbidemitted', (data) => {
            handler(data, 'cancelbidemitted')
        })
        socket.on('sliservertime', (data) => {
            if (moment(data.dTime).isValid()) {
                timehandlersingle(data, 'singletimehandler')
                setAuctionTimer(data.dTime)
            }
        })

        return () => {
            socket.off('realclosedupdates', (data) => {
                if (global.pluginConfiguration?.reverse_auction?.enable) {
                    data.id = data.pid
                }
                handler(data, 'realclosedupdates')
            })
            socket.off('bidAddtime', (data) => {
                if (global.pluginConfiguration?.reverse_auction?.enable) {
                    multiseller_handler(data, 'bidAddtime')
                } else {
                    handler(data, 'bidAddtime')
                }
            })
            socket.off('cancelbidemitted', (data) => {
                // console.log(data, 'cancellllllllllll')
                handler(data, 'cancelbidemitted')
            })
            socket.off('make_an_offer_update', (data) => {
                make_an_offer_update_new(data, 'make_an_offer')
            })

            socketAuction.off('realclosedupdates', (data) => {
                handler(data, 'realclosedupdates')
            })
            socketAuction.off('bidAddtime', (data) => {
                if (global.pluginConfiguration?.reverse_auction?.enable) {
                    multiseller_handler(data, 'bidAddtime')
                } else {
                    handler(data, 'bidAddtime')
                }
            })
            socketAuction.off('cancelbidemitted', (data) => {
                handler(data, 'cancelbidemitted')
            })
            socket.off('sliservertime', (data) => {
                timehandler(data, 'singletimehandler')
            })
        }
    }, [])

    const handleChange = (panel) => (event, newExpanded) => {
        setExpanded(newExpanded ? panel : false)
    }

    const lotInformation = [
        {
            title: 'Notes',
            description: selectedProduct?.style_period,
            display_need: 1,
        },
        {
            title: t('description'),
            description: selectedProduct?.description,
            display_need: 1,
        },
        {
            title: 'Condition Report',
            description: selectedProduct?.auctionnotes,
            display_need:
                storeTheme === 'ArtAuction' ||
                global.storeConfigration?.add_extra_fillter?.value == 1 ||
                global.storeConfigration?.show_condition?.value == 1
                    ? 0
                    : 1,
        },
        {
            title:
                storeTheme === 'foodindustry' || storeTheme === 'LiquidationPallet'
                    ? 'Delivery Details'
                    : storeTheme === 'ArtAuction'
                    ? t('ship_details')
                    : 'Pickup Details',
            description:
                selectedProduct?.auction_pickup_details ||
                selectedProduct?.extbidding ||
                (selectedProduct?.localpickup && selectedProduct?.hasshipping
                    ? 'Pickup or Shipping'
                    : selectedProduct?.localpickup
                    ? 'Pickup'
                    : selectedProduct?.hasshipping
                    ? ' Shipping'
                    : '') ||
                'Pick up details not specified',
            display_need: 1,
        },
    ]

    const [artauctionthemeinfo, setArtAuctionThemeInfo] = useState([])
    useEffect(() => {
        setArtAuctionThemeInfo([
            {
                title: t('description'),
                description: selectedProduct?.description,
                display_need: 1,
            },
            {
                title: t('product_details'),
                description: `${
                    productcategory ? '<p>Category : ' + productcategory + '</p>' : ''
                } ${productsubcategory ? '<p>SubCategory : ' + productsubcategory + '</p>' : ''}
                      <p>Width  : ${selectedProduct?.width}
                       (Inches)</p>
                       <p>Height  : ${selectedProduct?.height}
                       (Inches)</p>
                       <p>Length  : ${selectedProduct?.length} 
                       (Inches)</p>
                      <p>Weight  :  ${selectedProduct?.weight} 
                       (Pounds)</p>`,
                display_need: artTheme ? 1 : 0,
            },
            {
                title: t('payments'),
                description: 'Credit Cards',
                display_need: artTheme ? 1 : 0,
            },
            {
                title: t('shipping_details'),
                description: selectedProduct?.extbidding,
                display_need: 1,
            },
            {
                title: t('condition'),
                description: allCondition
                    .filter((condition) => condition.id == selectedProduct?.conditionunique)
                    .map((data) => data.description),
                display_need: artTheme ? 1 : 0,
            },
        ])
    }, [productcategory, productsubcategory])
    useEffect(() => {
        if (selectedProduct) {
            setExpanded('panel0')
            let temp = [
                {
                    title: t('description'),
                    description: selectedProduct?.description,
                    display_need: 1,
                },
                {
                    title: t('product_details'),
                    description: `${
                        productcategory ? '<p>Category : ' + productcategory + '</p>' : ''
                    } ${productsubcategory ? '<p>SubCategory : ' + productsubcategory + '</p>' : ''}
                          <p>Width  : ${selectedProduct?.width}
                           (Inches)</p>
                           <p>Height  : ${selectedProduct?.height}
                           (Inches)</p>
                           <p>Length  : ${selectedProduct?.length} 
                           (Inches)</p>
                          <p>Weight  :  ${selectedProduct?.weight} 
                           (Pounds)</p>`,
                    display_need: artTheme ? 1 : 0,
                },
                {
                    title: t('payments'),
                    description: 'Credit Cards',
                    display_need: artTheme ? 1 : 0,
                },
                {
                    title: t('shipping_details'),
                    description: selectedProduct?.extbidding,
                    display_need: 1,
                },
                {
                    title: t('condition'),
                    description: allCondition
                        .filter((condition) => condition.id == selectedProduct?.conditionunique)
                        .map((data) => data.description),
                    display_need: artTheme ? 1 : 0,
                },
            ]
            setArtAuctionThemeInfo([...temp])
        }
    }, [selectedProduct, productcategory, productsubcategory])

    const more_infor_popup_slider = [
        {
            title: 'Payments',
            description: product_view_more_detail.store_comment_one
                ? product_view_more_detail.store_comment_one
                : 'No Details Found',
            display_need: 1,
        },
        {
            title: 'Shipping',
            description: product_view_more_detail.store_comment_two
                ? product_view_more_detail.store_comment_two
                : 'No Details Found',
            display_need: 1,
        },
        {
            title: 'Pickup',
            description: product_view_more_detail.store_comment_three
                ? product_view_more_detail.store_comment_three
                : 'No Details Found',
            display_need: 1,
        },
        {
            title: 'Terms & Condition',
            description: product_view_more_detail.store_comment_four
                ? product_view_more_detail.store_comment_four
                : 'No Details Found',
            display_need: 1,
        },
        {
            title: 'Returns',
            description: product_view_more_detail.store_comment_five
                ? product_view_more_detail.store_comment_five
                : 'No Details Found',
            display_need: 1,
        },
    ]

    const new_lotInformation = [
        {
            title: 'Description',
            description: selectedProduct?.description,
            display_need: 1,
        },
        {
            title: selectedProduct?.auction_pickup_details ? 'Pickup Details' : '',
            description: selectedProduct?.auction_pickup_details
                ? selectedProduct.auction_pickup_details
                : '',
            display_need: 1,
        },
    ]

    const [countryList, setCountryList] = useState([])

    const getLocationData = () => {
        var body = {
            countryCode: 38,
        }

        return axios
            .post(`${process.env.REACT_APP_URL}fetchLocation`, body)
            .then(function (response) {
                if (response.data.success) {
                    setCountryList([
                        response.data.country.find((value) => parseInt(value.location_id) === 224),
                        ...response.data.country.filter(
                            (value) => parseInt(value.location_id) !== 224,
                        ),
                    ])
                }
            })
            .catch(function (error) {
                // console.log(error)
            })
    }

    useEffect(() => {
        getLocationData()
    }, [selectedProduct])

    const sliderRef1 = useRef(null)
    const sliderRef2 = useRef(null)
    const [rotate1Value, setRotate1Value] = useState(0)
    const [rotate2Value, setRotate2Value] = useState(0)

    const RenderImage1 = (type) => {
        return (
            <img
                src={selectedProductImage[sliderRef1?.current?.getCurrentIndex()]?.original}
                className="checkRotate1 checkRotate"
                id={`imgRef_${sliderRef1?.current?.getCurrentIndex()}`}
                onError={(e) => (e.target.src = `${global?.storeDetails?.logoValue}`)}
            />
        )
    }

    const RenderImage2 = (type) => {
        return (
            <img
                src={selectedProductImage[sliderRef2?.current?.getCurrentIndex()]?.original}
                className="checkRotate2 checkRotate"
                id={`imgRef_${sliderRef2?.current?.getCurrentIndex()}`}
                onError={(e) => (e.target.src = `${global?.storeDetails?.logoValue}`)}
            />
        )
    }

    const rotateImage1 = (index) => {
        let newValue = rotate1Value + 90
        setRotate1Value(newValue)

        if (newValue >= 450) {
            setRotate1Value(0)
        } else {
            setRotate1Value(newValue)
        }
        document.getElementsByClassName('checkRotate1')[
            sliderRef1?.current?.getCurrentIndex()
        ].style.transform = `rotate(${rotate1Value}deg)`
    }

    const RenderImageZoom1 = () => {
        return (
            <>
                <Button onClick={rotateImage1} className="rotateImage">
                    <span className="material-icons">rotate_right</span>
                </Button>
            </>
        )
    }

    const rotateImage2 = (index) => {
        let newValue = rotate2Value + 90
        setRotate2Value(newValue)

        if (newValue >= 450) {
            setRotate2Value(0)
        } else {
            setRotate2Value(newValue)
        }
        document.getElementsByClassName('checkRotate2')[
            sliderRef2?.current?.getCurrentIndex()
        ].style.transform = `rotate(${rotate2Value}deg)`
    }

    const RenderImageZoom2 = () => {
        return (
            <>
                <Button onClick={rotateImage2} className="rotateImage">
                    <span className="material-icons">rotate_right</span>
                </Button>
            </>
        )
    }

    const calculate_shipstation_amount = () => {
        if (
            selectedProduct.weight == 0 ||
            selectedProduct.height == 0 ||
            selectedProduct.length == 0 ||
            selectedProduct.width == 0
        ) {
            setAlert(
                'No Quote Available, Please Contact Us If You Need This Item Shipped.',
                'error',
            )
            setCalcLoading(false)
            return false
        }
        var seller_zipcode = ''
        if (selectedProduct.custom_field_8) {
            seller_zipcode = JSON.parse(selectedProduct.custom_field_8).zipcode
        }
        var calculation_data = {
            weight: selectedProduct.weight,
            height: selectedProduct.height,
            length: selectedProduct.length,
            width: selectedProduct.width,
            toPostalCode: user.zip,
            toCountry: user.country,
            fromPostalCode: selectedProduct.sell_zipcode
                ? selectedProduct.sell_zipcode
                : seller_zipcode,
            site_id: global.storeDetails?.site_id ? global.storeDetails.site_id : '',
            project_id: selectedProduct.id,
        }
        get_ship_station_amount(calculation_data)
    }

    const validationmake_formik = Yup.object({
        proposed_amount: Yup.number()
            .required('Please Enter Value')
            .min(1, 'Amount should be greater than 1 or equal'),
    })

    const make_formik = useFormik({
        initialValues: {
            proposed_amount: '',
            project_id: '',
            user_id: '',
            seller_id: '',
            status: '',
        },
        validationSchema: validationmake_formik,
        onSubmit: async (values) => {
            make_formik.values.project_id = selectedProduct.id
            make_formik.values.user_id = user.id
            make_formik.values.seller_id = selectedProduct.user_id
            make_formik.values.status = 'Pending'
            insert_make_proposels(values)
            make_formik.values.proposed_amount = ''
        },
    })

    var make_an_offer = [
        {
            label: 'Make Offer',
            placeholder: 'Enter Price',
            type: 'number',
            class: 'col-sm-6 col-12',
            name: 'proposed_amount',
            formik: make_formik,
        },
    ]

    useEffect(() => {
        if (shipping_rate_calculation.message) {
            if (shipping_rate_calculation.message.shipping_status) {
                if (shipping_rate_calculation.message.shipping_status == 200) {
                    // console.log('+++++++++++++++++++++++++++++++++++++++++++++++++++++')
                    setShipping_amount(
                        shipping_rate_calculation.message.rate[0]?.shipmentCost
                            ? parseFloat(shipping_rate_calculation.message.rate[0].shipmentCost)
                            : '0',
                    )
                } else if (shipping_rate_calculation.message.shipping_status == 500) {
                    setAlert(
                        'Product dimensional or Zip Code incorrect.Please check your Zip Code or contact seller.',
                        'error',
                    )
                } else {
                    setAlert('Please Try Again Later!', 'error')
                }
            } else {
                setAlert('Please Try Again Later!', 'error')
            }
            setCalcLoading(false)
            clear_ship_station_amount()
        }
    }, [shipping_rate_calculation])

    const checkThemeClassName = document.body.className == 'liquidationTwo primeBids'

    const handleTabClick = (tabData) => {
        switch (tabData) {
            case 'viewlot':
                setViewLot(true)
                setActivePolicy(false)
                setPickupDetails(false)
                break
            case 'auctionpolicy':
                setActivePolicy(true)
                setViewLot(false)
                setPickupDetails(false)
                break
            case 'pickingdetails':
                setPickupDetails(true)
                setActivePolicy(false)
                setViewLot(false)
                break
            default:
                console.log('Unknown tab')
                setViewLot(true)
        }
    }

    const render = (
        <>
            {loading ? (
                <div className="sliderLoader">
                    <Loaders name="product_view" isLoading={loading} loop={1} />
                </div>
            ) : (
                <>
                    {selectedProduct?.id ? (
                        <div
                            className={`msSliderInner ${
                                selectedProduct.bidtopstatus != '' && 'bidStatus'
                            }`}
                            style={{
                                background: '#fff',
                                height: 'auto',
                                overflowY: 'auto',
                                padding: '25px 30px',
                            }}
                        >
                            <div className="media-body">
                                <div
                                    className={`${
                                        checkThemeClassName ? 'primeBidsMuitabsContainer' : 'd-none'
                                    }`}
                                >
                                    {checkThemeClassName ? (
                                        <div className="pvTabViewBtnGrp">
                                            <div className="d-flex align-items-center justify-content-start">
                                                <Button
                                                    className={viewLot ? 'active' : null}
                                                    onClick={() => handleTabClick('viewlot')}
                                                >
                                                    View Lot
                                                </Button>
                                                <Button
                                                    className={activePolicy ? 'active' : null}
                                                    onClick={() => handleTabClick('auctionpolicy')}
                                                >
                                                    Auction Policies
                                                </Button>
                                                <Button
                                                    className={pickupDetails ? 'active' : null}
                                                    onClick={() => handleTabClick('pickingdetails')}
                                                >
                                                    Pickup Details
                                                </Button>
                                            </div>
                                        </div>
                                    ) : null}

                                    {viewLot && (
                                        <div className="tabAccordiantList">
                                            {storeTheme === 'ArtAuction' && (
                                                <div className="mpTop">
                                                    <div className="topNmCntnr">
                                                        <h5 className="lotNo">
                                                            {t('lot_id')}:{' '}
                                                            {selectedProduct.deed_document
                                                                ? selectedProduct.deed_document
                                                                : selectedProduct.id}
                                                        </h5>
                                                        <h3
                                                            className="plTitle"
                                                            dangerouslySetInnerHTML={{
                                                                __html: selectedProduct.title,
                                                            }}
                                                        ></h3>
                                                        <NavLink
                                                            to={`/productView/${selectedProduct.id}`}
                                                            className="fullDetils"
                                                        >
                                                            View Full Details
                                                        </NavLink>
                                                    </div>

                                                    {(selectedProduct.auction == 1 &&
                                                        selectedProduct.buynow == 0) ||
                                                    selectedProduct.auction == 1 ? (
                                                        <div className="mssTimer olp">
                                                            {selectedProduct.market_status !=
                                                            'draft' ? (
                                                                (selectedProduct.market_status !=
                                                                    'open' &&
                                                                    selectedProduct.market_status !=
                                                                        'future') ||
                                                                selectedProduct.productClosed ? (
                                                                    ''
                                                                ) : selectedProduct.extend ||
                                                                  selectedProduct.agent_id ? (
                                                                    <>
                                                                        {artTheme ? null : (
                                                                            <span className="material-icons-outlined">
                                                                                timer
                                                                            </span>
                                                                        )}
                                                                        <span className="tmm">
                                                                            <Timer
                                                                                date_added={
                                                                                    selectedProduct.date_added
                                                                                }
                                                                                date_closed={
                                                                                    selectedProduct.date_closed
                                                                                }
                                                                                withText={1}
                                                                                endText={
                                                                                    'Timed Out' +
                                                                                    ':'
                                                                                }
                                                                                startText={
                                                                                    'Starts in' +
                                                                                    ':'
                                                                                }
                                                                                // theme="multiseller"
                                                                            ></Timer>
                                                                        </span>
                                                                    </>
                                                                ) : (
                                                                    <>
                                                                        {artTheme ? null : (
                                                                            <span className="material-icons-outlined">
                                                                                timer
                                                                            </span>
                                                                        )}
                                                                        <span>
                                                                            <Timer
                                                                                date_added={
                                                                                    selectedProduct.date_added
                                                                                }
                                                                                date_closed={
                                                                                    selectedProduct.date_closed
                                                                                }
                                                                                withText={1}
                                                                                endText={
                                                                                    artTheme
                                                                                        ? t(
                                                                                              'TIME LEFT',
                                                                                          )
                                                                                        : t(
                                                                                              'ends_in',
                                                                                          ) + ':'
                                                                                }
                                                                                startText={
                                                                                    t('starts_in') +
                                                                                    ':'
                                                                                }
                                                                                theme="multiseller"
                                                                            ></Timer>
                                                                        </span>
                                                                    </>
                                                                )
                                                            ) : (
                                                                ''
                                                            )}
                                                        </div>
                                                    ) : (
                                                        ''
                                                    )}
                                                </div>
                                            )}
                                            {!viewPage && (
                                                <img
                                                    src="/image/close.png"
                                                    onClick={() => {
                                                        handleClose()
                                                    }}
                                                    className="close-img"
                                                    height="35"
                                                    width="35"
                                                    onError={(e) => (e.target.src = imageError())}
                                                />
                                            )}
                                            {!checkThemeClassName ? (
                                                <div
                                                    className="prodInfoSlider"
                                                    style={{ paddingTop: '20px' }}
                                                >
                                                    {isAuthenticated ? (
                                                        selectedProduct.auction ? (
                                                            type === 'buynow' ? null : (
                                                                <BidStatus
                                                                    bidTopStatus={
                                                                        selectedProduct.bidtopstatus
                                                                    }
                                                                />
                                                            )
                                                        ) : null
                                                    ) : null}
                                                    <span className="imgGlrWrpr">
                                                        {selectedProduct.market_status ===
                                                            'sold' && (
                                                            <div className="soldWrapper">
                                                                <p>SOLD</p>
                                                            </div>
                                                        )}

                                                        {/* {console.log(
                                                        selectedProductImage,
                                                        'selectedProductImage',
                                                    )} */}
                                                        {/* {console.log(productVideos, 'productVideos')} */}
                                                        <ImageGallery
                                                            items={selectedProductImage}
                                                            thumbnailPosition={
                                                                storeTheme === 'LiquidationPallet'
                                                                    ? 'left'
                                                                    : 'bottom'
                                                            }
                                                            showThumbnails={
                                                                storeTheme === 'foodindustry'
                                                                    ? false
                                                                    : true
                                                            }
                                                            showNav={true}
                                                            showBullets={false}
                                                            showFullscreenButton={true}
                                                            showPlayButton={false}
                                                            onErrorImageURL={imageError()}
                                                            originalClass="slidImg img-fluid"
                                                            renderItem={RenderImage1}
                                                            ref={sliderRef1}
                                                            renderCustomControls={RenderImageZoom1}
                                                        />
                                                    </span>
                                                    {/* {productVideos.map((val) => (
                                                <div className="vdoPlyrWrapper">
                                                    <ReactPlayer light={false} controls={true} url={val} />
                                                </div>
                                            ))} */}
                                                    {storeTheme != 'foodindustry' &&
                                                        storeTheme != 'LiquidationPallet' && (
                                                            <>
                                                                {!checkThemeClassName ? (
                                                                    <div
                                                                        className={`d-flex ${
                                                                            storeTheme !==
                                                                            'ArtAuction'
                                                                                ? 'justify-content-between'
                                                                                : 'justify-content-end'
                                                                        } align-items-center pvTitleContainer flex-wrap`}
                                                                    >
                                                                        {storeTheme !==
                                                                            'ArtAuction' &&
                                                                            storeTheme !==
                                                                                'Liquidation_two' && (
                                                                                <>
                                                                                    <div
                                                                                        className={
                                                                                            selectedProduct?.buynow &&
                                                                                            'd-none'
                                                                                        }
                                                                                    >
                                                                                        {storeTheme !=
                                                                                        undefined ? (
                                                                                            <h5 className="lotNo">
                                                                                                Lot#{' '}
                                                                                                {selectedProduct.sku
                                                                                                    ? selectedProduct.sku
                                                                                                    : selectedProduct.lot_number
                                                                                                    ? selectedProduct.lot_number
                                                                                                    : selectedProduct.deed_document
                                                                                                    ? selectedProduct.deed_document
                                                                                                    : selectedProduct.id}
                                                                                            </h5>
                                                                                        ) : (
                                                                                            <h5 className="lotNo">
                                                                                                Lot#{' '}
                                                                                                {selectedProduct.deed_document
                                                                                                    ? selectedProduct.deed_document
                                                                                                    : selectedProduct.id}
                                                                                            </h5>
                                                                                        )}
                                                                                        {parseInt(
                                                                                            selectedProduct.buynow,
                                                                                        ) == 0 &&
                                                                                        (parseInt(
                                                                                            selectedProduct.lowest,
                                                                                        ) > 0 ||
                                                                                            parseInt(
                                                                                                selectedProduct.highest,
                                                                                            ) >
                                                                                                0) ? (
                                                                                            <p>
                                                                                                <span>
                                                                                                    Est:
                                                                                                </span>
                                                                                                {`${
                                                                                                    ' ' +
                                                                                                    currencyFormat(
                                                                                                        selectedProduct.lowest,
                                                                                                        currency,
                                                                                                    ) +
                                                                                                    ' - ' +
                                                                                                    currencyFormat(
                                                                                                        selectedProduct.highest,
                                                                                                        currency,
                                                                                                    )
                                                                                                }`}
                                                                                            </p>
                                                                                        ) : (
                                                                                            ''
                                                                                        )}
                                                                                    </div>
                                                                                </>
                                                                            )}
                                                                        {artTheme ? null : (
                                                                            <div className="pvMiscActions">
                                                                                <div
                                                                                    className="cpyToClipDiv"
                                                                                    style={{
                                                                                        cursor: 'pointer',
                                                                                    }}
                                                                                >
                                                                                    <Copy
                                                                                        setAlert={
                                                                                            setAlert
                                                                                        }
                                                                                        text={`/${
                                                                                            storeTheme ==
                                                                                            'Liquidation_two'
                                                                                                ? 'search'
                                                                                                : ''
                                                                                        }?product=${
                                                                                            selectedProduct.id
                                                                                        }`}
                                                                                    />
                                                                                </div>
                                                                                {selectedProduct.market_status ==
                                                                                'open' ? (
                                                                                    <FavouriteCheckbox
                                                                                        watchlisted={
                                                                                            selectedProduct.wlistpop
                                                                                        }
                                                                                        data={
                                                                                            selectedProduct
                                                                                        }
                                                                                        project_id={
                                                                                            selectedProduct.id
                                                                                        }
                                                                                        from={from}
                                                                                        getAllProducts={
                                                                                            getAllProducts
                                                                                        }
                                                                                        setSelectedProduct={
                                                                                            setSelectedProduct
                                                                                        }
                                                                                    />
                                                                                ) : null}
                                                                                <Facebook
                                                                                    path={
                                                                                        storeTheme ==
                                                                                            'Heavyequipment' ||
                                                                                        storeTheme ==
                                                                                            'Liquidation_two'
                                                                                            ? `/${
                                                                                                  base_url
                                                                                                      ? base_url
                                                                                                      : 'search'
                                                                                              }?product=${
                                                                                                  selectedProduct.id
                                                                                              }`
                                                                                            : global
                                                                                                  .storeConfigration
                                                                                                  ?.product_query
                                                                                                  ?.value ==
                                                                                              1
                                                                                            ? `/?product=${selectedProduct.id}`
                                                                                            : `/product/${selectedProduct.id}`
                                                                                    }
                                                                                    title={
                                                                                        selectedProduct.title
                                                                                    }
                                                                                    selectedProduct={
                                                                                        selectedProduct
                                                                                    }
                                                                                />
                                                                                <Whatsapp
                                                                                    path={
                                                                                        storeTheme ==
                                                                                            'Heavyequipment' ||
                                                                                        storeTheme ==
                                                                                            'Liquidation_two'
                                                                                            ? `/${
                                                                                                  base_url
                                                                                                      ? base_url
                                                                                                      : 'search'
                                                                                              }?product=${
                                                                                                  selectedProduct.id
                                                                                              }`
                                                                                            : global
                                                                                                  .storeConfigration
                                                                                                  ?.product_query
                                                                                                  ?.value ==
                                                                                              1
                                                                                            ? `/?product=${selectedProduct.id}`
                                                                                            : `/product/${selectedProduct.id}`
                                                                                    }
                                                                                    title={
                                                                                        selectedProduct.title
                                                                                    }
                                                                                />
                                                                                <Twitter
                                                                                    path={
                                                                                        storeTheme ==
                                                                                            'Heavyequipment' ||
                                                                                        storeTheme ==
                                                                                            'Liquidation_two'
                                                                                            ? `/${
                                                                                                  base_url
                                                                                                      ? base_url
                                                                                                      : 'search'
                                                                                              }?product=${
                                                                                                  selectedProduct.id
                                                                                              }`
                                                                                            : global
                                                                                                  .storeConfigration
                                                                                                  ?.product_query
                                                                                                  ?.value ==
                                                                                              1
                                                                                            ? `/?product=${selectedProduct.id}`
                                                                                            : `/product/${selectedProduct.id}`
                                                                                    }
                                                                                    title={
                                                                                        selectedProduct.title
                                                                                    }
                                                                                />
                                                                                <Email
                                                                                    path={
                                                                                        storeTheme ==
                                                                                            'Heavyequipment' ||
                                                                                        storeTheme ==
                                                                                            'Liquidation_two'
                                                                                            ? `/${
                                                                                                  base_url
                                                                                                      ? base_url
                                                                                                      : 'search'
                                                                                              }?product=${
                                                                                                  selectedProduct.id
                                                                                              }`
                                                                                            : global
                                                                                                  .storeConfigration
                                                                                                  ?.product_query
                                                                                                  ?.value ==
                                                                                              1
                                                                                            ? `/?product=${selectedProduct.id}`
                                                                                            : `/product/${selectedProduct.id}`
                                                                                    }
                                                                                    subject={
                                                                                        selectedProduct.title
                                                                                    }
                                                                                    body={removeHTMLTags(
                                                                                        selectedProduct.description,
                                                                                    )}
                                                                                />
                                                                                <Pinterest
                                                                                    path={
                                                                                        storeTheme ==
                                                                                            'Heavyequipment' ||
                                                                                        storeTheme ==
                                                                                            'Liquidation_two'
                                                                                            ? `/${
                                                                                                  base_url
                                                                                                      ? base_url
                                                                                                      : 'search'
                                                                                              }?product=${
                                                                                                  selectedProduct.id
                                                                                              }`
                                                                                            : global
                                                                                                  .storeConfigration
                                                                                                  ?.product_query
                                                                                                  ?.value ==
                                                                                              1
                                                                                            ? `/?product=${selectedProduct.id}`
                                                                                            : `/product/${selectedProduct.id}`
                                                                                    }
                                                                                    title={
                                                                                        selectedProduct.title
                                                                                    }
                                                                                    media={
                                                                                        `${
                                                                                            selectedProduct.content_head1 ===
                                                                                            '0'
                                                                                                ? process
                                                                                                      .env
                                                                                                      .REACT_APP_BASE_URL +
                                                                                                  'uploads/product/'
                                                                                                : process
                                                                                                      .env
                                                                                                      .REACT_APP_PRODUCT_IMAGE_URL
                                                                                        }` +
                                                                                        selectedProduct.avatar
                                                                                    }
                                                                                />
                                                                            </div>
                                                                        )}
                                                                    </div>
                                                                ) : null}
                                                                {/* <Button
                                                        className="d-none productPageAct"
                                                        onClick={() =>
                                                            handleRedirectInternal(
                                                                history,
                                                                `productView/${selectedProduct.id}`,
                                                            )
                                                        }
                                                    >
                                                        View Full Details
                                                    </Button> */}
                                                            </>
                                                        )}
                                                    {storeTheme != 'foodindustry' &&
                                                        checkThemeClassName &&
                                                        storeTheme != 'LiquidationPallet' && (
                                                            <>
                                                                <div className="mpTop mb-0">
                                                                    {checkThemeClassName ? (
                                                                        <>
                                                                            <h3
                                                                                className={`plTitle primeTitl ${
                                                                                    showDescription
                                                                                        ? ''
                                                                                        : 'hide'
                                                                                }`}
                                                                                dangerouslySetInnerHTML={{
                                                                                    __html: selectedProduct.title,
                                                                                }}
                                                                            ></h3>
                                                                            <span
                                                                                className="mb-3 cursorDecoy shwMrBtn"
                                                                                onClick={() =>
                                                                                    setShowDescription(
                                                                                        !showDescription,
                                                                                    )
                                                                                }
                                                                            >
                                                                                <b>
                                                                                    {descripText?.length >
                                                                                        43 && (
                                                                                        <u>
                                                                                            Show{' '}
                                                                                            {showDescription
                                                                                                ? 'Less'
                                                                                                : 'More'}
                                                                                        </u>
                                                                                    )}
                                                                                </b>
                                                                            </span>
                                                                        </>
                                                                    ) : (
                                                                        <h3
                                                                            className="plTitle ppl"
                                                                            dangerouslySetInnerHTML={{
                                                                                __html: selectedProduct.title,
                                                                            }}
                                                                        ></h3>
                                                                    )}
                                                                </div>
                                                            </>
                                                        )}
                                                    {storeTheme === 'Liquidation_two' &&
                                                    checkThemeClassName ? (
                                                        <>
                                                            {selectedProduct.retailer_link &&
                                                            selectedProduct.mprice ? (
                                                                <div className="d-flex justify-content-between align-items-center">
                                                                    <h6 className="galleryPrice rtlrPrice">
                                                                        <span className="pecTxtLabl">
                                                                            Retail Price:{' '}
                                                                            <a
                                                                                href={
                                                                                    selectedProduct.retailer_link
                                                                                }
                                                                                target="_blank"
                                                                                rel="noreferrer"
                                                                            >
                                                                                (Retailer)
                                                                            </a>
                                                                        </span>
                                                                        &nbsp;
                                                                        <span className="prcTxtVal">
                                                                            {currencyFormat(
                                                                                parseFloat(
                                                                                    selectedProduct.mprice ||
                                                                                        0,
                                                                                ).toFixed(2),
                                                                            )}
                                                                            <CurrencyConverterFormat
                                                                                amount={
                                                                                    selectedProduct.mprice
                                                                                }
                                                                            />
                                                                        </span>
                                                                        {parseFloat(
                                                                            selectedProduct.mprice,
                                                                        ) >
                                                                            parseFloat(
                                                                                selectedProduct.wprice,
                                                                            ) &&
                                                                        checkThemeClassName ? (
                                                                            <p>
                                                                                Save
                                                                                {
                                                                                    <span
                                                                                        style={{
                                                                                            color: '#FCB43A',
                                                                                            fontWeight:
                                                                                                '700',
                                                                                        }}
                                                                                    >
                                                                                        {' '}
                                                                                        {get_reatil_price_per(
                                                                                            selectedProduct.mprice,
                                                                                            selectedProduct.wprice,
                                                                                        )}
                                                                                        %{' '}
                                                                                    </span>
                                                                                }
                                                                                off retail price.
                                                                            </p>
                                                                        ) : (
                                                                            ''
                                                                        )}
                                                                    </h6>
                                                                    <div
                                                                        className={`d-flex ${
                                                                            storeTheme !==
                                                                            'ArtAuction'
                                                                                ? 'justify-content-between'
                                                                                : 'justify-content-end'
                                                                        } align-items-center pvTitleContainer flex-wrap`}
                                                                    >
                                                                        {storeTheme !==
                                                                            'ArtAuction' &&
                                                                            storeTheme !==
                                                                                'Liquidation_two' && (
                                                                                <>
                                                                                    <div
                                                                                        className={
                                                                                            selectedProduct?.buynow &&
                                                                                            'd-none'
                                                                                        }
                                                                                    >
                                                                                        {storeTheme !=
                                                                                        undefined ? (
                                                                                            <h5 className="lotNo">
                                                                                                Lot#{' '}
                                                                                                {selectedProduct.sku
                                                                                                    ? selectedProduct.sku
                                                                                                    : selectedProduct.lot_number
                                                                                                    ? selectedProduct.lot_number
                                                                                                    : selectedProduct.deed_document
                                                                                                    ? selectedProduct.deed_document
                                                                                                    : selectedProduct.id}
                                                                                            </h5>
                                                                                        ) : (
                                                                                            <h5 className="lotNo">
                                                                                                Lot#{' '}
                                                                                                {selectedProduct.deed_document
                                                                                                    ? selectedProduct.deed_document
                                                                                                    : selectedProduct.id}
                                                                                            </h5>
                                                                                        )}
                                                                                        {parseInt(
                                                                                            selectedProduct.buynow,
                                                                                        ) == 0 &&
                                                                                        (parseInt(
                                                                                            selectedProduct.lowest,
                                                                                        ) > 0 ||
                                                                                            parseInt(
                                                                                                selectedProduct.highest,
                                                                                            ) >
                                                                                                0) ? (
                                                                                            <p>
                                                                                                <span>
                                                                                                    Est:
                                                                                                </span>
                                                                                                {`${
                                                                                                    ' ' +
                                                                                                    currencyFormat(
                                                                                                        selectedProduct.lowest,
                                                                                                        currency,
                                                                                                    ) +
                                                                                                    ' - ' +
                                                                                                    currencyFormat(
                                                                                                        selectedProduct.highest,
                                                                                                        currency,
                                                                                                    )
                                                                                                }`}
                                                                                            </p>
                                                                                        ) : (
                                                                                            ''
                                                                                        )}
                                                                                    </div>
                                                                                </>
                                                                            )}
                                                                    </div>
                                                                    {!isAuthenticated &&
                                                                    checkThemeClassName ? null : (
                                                                        <div
                                                                            className="pvMiscActions favIconBgChange"
                                                                            style={{
                                                                                width: 'fit-content',
                                                                            }}
                                                                        >
                                                                            {!checkThemeClassName ? (
                                                                                <div
                                                                                    className="cpyToClipDiv"
                                                                                    style={{
                                                                                        cursor: 'pointer',
                                                                                    }}
                                                                                >
                                                                                    <Copy
                                                                                        setAlert={
                                                                                            setAlert
                                                                                        }
                                                                                        text={`/${
                                                                                            storeTheme ==
                                                                                            'Liquidation_two'
                                                                                                ? 'search'
                                                                                                : ''
                                                                                        }?product=${
                                                                                            selectedProduct.id
                                                                                        }`}
                                                                                    />
                                                                                </div>
                                                                            ) : null}
                                                                            {selectedProduct.market_status ==
                                                                            'open' ? (
                                                                                <FavouriteCheckbox
                                                                                    watchlisted={
                                                                                        selectedProduct.wlistpop
                                                                                    }
                                                                                    data={
                                                                                        selectedProduct
                                                                                    }
                                                                                    project_id={
                                                                                        selectedProduct.id
                                                                                    }
                                                                                    from={from}
                                                                                    getAllProducts={
                                                                                        getAllProducts
                                                                                    }
                                                                                    setSelectedProduct={
                                                                                        setSelectedProduct
                                                                                    }
                                                                                />
                                                                            ) : null}
                                                                            {!checkThemeClassName ? (
                                                                                <>
                                                                                    <Facebook
                                                                                        path={
                                                                                            storeTheme ==
                                                                                                'Heavyequipment' ||
                                                                                            storeTheme ==
                                                                                                'Liquidation_two'
                                                                                                ? `/${
                                                                                                      base_url
                                                                                                          ? base_url
                                                                                                          : 'search'
                                                                                                  }?product=${
                                                                                                      selectedProduct.id
                                                                                                  }`
                                                                                                : global
                                                                                                      .storeConfigration
                                                                                                      ?.product_query
                                                                                                      ?.value ==
                                                                                                  1
                                                                                                ? `/?product=${selectedProduct.id}`
                                                                                                : `/product/${selectedProduct.id}`
                                                                                        }
                                                                                        title={
                                                                                            selectedProduct.title
                                                                                        }
                                                                                        selectedProduct={
                                                                                            selectedProduct
                                                                                        }
                                                                                    />
                                                                                    <Whatsapp
                                                                                        path={
                                                                                            storeTheme ==
                                                                                                'Heavyequipment' ||
                                                                                            storeTheme ==
                                                                                                'Liquidation_two'
                                                                                                ? `/${
                                                                                                      base_url
                                                                                                          ? base_url
                                                                                                          : 'search'
                                                                                                  }?product=${
                                                                                                      selectedProduct.id
                                                                                                  }`
                                                                                                : global
                                                                                                      .storeConfigration
                                                                                                      ?.product_query
                                                                                                      ?.value ==
                                                                                                  1
                                                                                                ? `/?product=${selectedProduct.id}`
                                                                                                : `/product/${selectedProduct.id}`
                                                                                        }
                                                                                        title={
                                                                                            selectedProduct.title
                                                                                        }
                                                                                    />
                                                                                    <Twitter
                                                                                        path={
                                                                                            storeTheme ==
                                                                                                'Heavyequipment' ||
                                                                                            storeTheme ==
                                                                                                'Liquidation_two'
                                                                                                ? `/${
                                                                                                      base_url
                                                                                                          ? base_url
                                                                                                          : 'search'
                                                                                                  }?product=${
                                                                                                      selectedProduct.id
                                                                                                  }`
                                                                                                : global
                                                                                                      .storeConfigration
                                                                                                      ?.product_query
                                                                                                      ?.value ==
                                                                                                  1
                                                                                                ? `/?product=${selectedProduct.id}`
                                                                                                : `/product/${selectedProduct.id}`
                                                                                        }
                                                                                        title={
                                                                                            selectedProduct.title
                                                                                        }
                                                                                    />
                                                                                    <Email
                                                                                        path={
                                                                                            storeTheme ==
                                                                                                'Heavyequipment' ||
                                                                                            storeTheme ==
                                                                                                'Liquidation_two'
                                                                                                ? `/${
                                                                                                      base_url
                                                                                                          ? base_url
                                                                                                          : 'search'
                                                                                                  }?product=${
                                                                                                      selectedProduct.id
                                                                                                  }`
                                                                                                : global
                                                                                                      .storeConfigration
                                                                                                      ?.product_query
                                                                                                      ?.value ==
                                                                                                  1
                                                                                                ? `/?product=${selectedProduct.id}`
                                                                                                : `/product/${selectedProduct.id}`
                                                                                        }
                                                                                        subject={
                                                                                            selectedProduct.title
                                                                                        }
                                                                                        body={removeHTMLTags(
                                                                                            selectedProduct.description,
                                                                                        )}
                                                                                    />
                                                                                    <Pinterest
                                                                                        path={
                                                                                            storeTheme ==
                                                                                                'Heavyequipment' ||
                                                                                            storeTheme ==
                                                                                                'Liquidation_two'
                                                                                                ? `/${
                                                                                                      base_url
                                                                                                          ? base_url
                                                                                                          : 'search'
                                                                                                  }?product=${
                                                                                                      selectedProduct.id
                                                                                                  }`
                                                                                                : global
                                                                                                      .storeConfigration
                                                                                                      ?.product_query
                                                                                                      ?.value ==
                                                                                                  1
                                                                                                ? `/?product=${selectedProduct.id}`
                                                                                                : `/product/${selectedProduct.id}`
                                                                                        }
                                                                                        title={
                                                                                            selectedProduct.title
                                                                                        }
                                                                                        media={
                                                                                            `${
                                                                                                selectedProduct.content_head1 ===
                                                                                                '0'
                                                                                                    ? process
                                                                                                          .env
                                                                                                          .REACT_APP_BASE_URL +
                                                                                                      'uploads/product/'
                                                                                                    : process
                                                                                                          .env
                                                                                                          .REACT_APP_PRODUCT_IMAGE_URL
                                                                                            }` +
                                                                                            selectedProduct.avatar
                                                                                        }
                                                                                    />
                                                                                </>
                                                                            ) : (
                                                                                <h6 className="mb-0 mr-3">
                                                                                    Add To Watchlist
                                                                                </h6>
                                                                            )}
                                                                        </div>
                                                                    )}
                                                                </div>
                                                            ) : (
                                                                <>
                                                                    {' '}
                                                                    {!selectedProduct.retailer_link &&
                                                                    !selectedProduct.mprice &&
                                                                    !isAuthenticated &&
                                                                    checkThemeClassName ? null : (
                                                                        <div
                                                                            className="pvMiscActions favIconBgChange"
                                                                            style={{
                                                                                width: 'fit-content',
                                                                            }}
                                                                        >
                                                                            {!checkThemeClassName ? (
                                                                                <div
                                                                                    className="cpyToClipDiv"
                                                                                    style={{
                                                                                        cursor: 'pointer',
                                                                                    }}
                                                                                >
                                                                                    <Copy
                                                                                        setAlert={
                                                                                            setAlert
                                                                                        }
                                                                                        text={`/${
                                                                                            storeTheme ==
                                                                                            'Liquidation_two'
                                                                                                ? 'search'
                                                                                                : ''
                                                                                        }?product=${
                                                                                            selectedProduct.id
                                                                                        }`}
                                                                                    />
                                                                                </div>
                                                                            ) : null}
                                                                            {selectedProduct.market_status ==
                                                                            'open' ? (
                                                                                <FavouriteCheckbox
                                                                                    watchlisted={
                                                                                        selectedProduct.wlistpop
                                                                                    }
                                                                                    data={
                                                                                        selectedProduct
                                                                                    }
                                                                                    project_id={
                                                                                        selectedProduct.id
                                                                                    }
                                                                                    from={from}
                                                                                    getAllProducts={
                                                                                        getAllProducts
                                                                                    }
                                                                                    setSelectedProduct={
                                                                                        setSelectedProduct
                                                                                    }
                                                                                />
                                                                            ) : null}
                                                                            {!checkThemeClassName ? (
                                                                                <>
                                                                                    <Facebook
                                                                                        path={
                                                                                            storeTheme ==
                                                                                                'Heavyequipment' ||
                                                                                            storeTheme ==
                                                                                                'Liquidation_two'
                                                                                                ? `/${
                                                                                                      base_url
                                                                                                          ? base_url
                                                                                                          : 'search'
                                                                                                  }?product=${
                                                                                                      selectedProduct.id
                                                                                                  }`
                                                                                                : global
                                                                                                      .storeConfigration
                                                                                                      ?.product_query
                                                                                                      ?.value ==
                                                                                                  1
                                                                                                ? `/?product=${selectedProduct.id}`
                                                                                                : `/product/${selectedProduct.id}`
                                                                                        }
                                                                                        title={
                                                                                            selectedProduct.title
                                                                                        }
                                                                                        selectedProduct={
                                                                                            selectedProduct
                                                                                        }
                                                                                    />
                                                                                    <Whatsapp
                                                                                        path={
                                                                                            storeTheme ==
                                                                                                'Heavyequipment' ||
                                                                                            storeTheme ==
                                                                                                'Liquidation_two'
                                                                                                ? `/${
                                                                                                      base_url
                                                                                                          ? base_url
                                                                                                          : 'search'
                                                                                                  }?product=${
                                                                                                      selectedProduct.id
                                                                                                  }`
                                                                                                : global
                                                                                                      .storeConfigration
                                                                                                      ?.product_query
                                                                                                      ?.value ==
                                                                                                  1
                                                                                                ? `/?product=${selectedProduct.id}`
                                                                                                : `/product/${selectedProduct.id}`
                                                                                        }
                                                                                        title={
                                                                                            selectedProduct.title
                                                                                        }
                                                                                    />
                                                                                    <Twitter
                                                                                        path={
                                                                                            storeTheme ==
                                                                                                'Heavyequipment' ||
                                                                                            storeTheme ==
                                                                                                'Liquidation_two'
                                                                                                ? `/${
                                                                                                      base_url
                                                                                                          ? base_url
                                                                                                          : 'search'
                                                                                                  }?product=${
                                                                                                      selectedProduct.id
                                                                                                  }`
                                                                                                : global
                                                                                                      .storeConfigration
                                                                                                      ?.product_query
                                                                                                      ?.value ==
                                                                                                  1
                                                                                                ? `/?product=${selectedProduct.id}`
                                                                                                : `/product/${selectedProduct.id}`
                                                                                        }
                                                                                        title={
                                                                                            selectedProduct.title
                                                                                        }
                                                                                    />
                                                                                    <Email
                                                                                        path={
                                                                                            storeTheme ==
                                                                                                'Heavyequipment' ||
                                                                                            storeTheme ==
                                                                                                'Liquidation_two'
                                                                                                ? `/${
                                                                                                      base_url
                                                                                                          ? base_url
                                                                                                          : 'search'
                                                                                                  }?product=${
                                                                                                      selectedProduct.id
                                                                                                  }`
                                                                                                : global
                                                                                                      .storeConfigration
                                                                                                      ?.product_query
                                                                                                      ?.value ==
                                                                                                  1
                                                                                                ? `/?product=${selectedProduct.id}`
                                                                                                : `/product/${selectedProduct.id}`
                                                                                        }
                                                                                        subject={
                                                                                            selectedProduct.title
                                                                                        }
                                                                                        body={removeHTMLTags(
                                                                                            selectedProduct.description,
                                                                                        )}
                                                                                    />
                                                                                    <Pinterest
                                                                                        path={
                                                                                            storeTheme ==
                                                                                                'Heavyequipment' ||
                                                                                            storeTheme ==
                                                                                                'Liquidation_two'
                                                                                                ? `/${
                                                                                                      base_url
                                                                                                          ? base_url
                                                                                                          : 'search'
                                                                                                  }?product=${
                                                                                                      selectedProduct.id
                                                                                                  }`
                                                                                                : global
                                                                                                      .storeConfigration
                                                                                                      ?.product_query
                                                                                                      ?.value ==
                                                                                                  1
                                                                                                ? `/?product=${selectedProduct.id}`
                                                                                                : `/product/${selectedProduct.id}`
                                                                                        }
                                                                                        title={
                                                                                            selectedProduct.title
                                                                                        }
                                                                                        media={
                                                                                            `${
                                                                                                selectedProduct.content_head1 ===
                                                                                                '0'
                                                                                                    ? process
                                                                                                          .env
                                                                                                          .REACT_APP_BASE_URL +
                                                                                                      'uploads/product/'
                                                                                                    : process
                                                                                                          .env
                                                                                                          .REACT_APP_PRODUCT_IMAGE_URL
                                                                                            }` +
                                                                                            selectedProduct.avatar
                                                                                        }
                                                                                    />
                                                                                </>
                                                                            ) : (
                                                                                <h6 className="mb-0 mr-3">
                                                                                    Add To Watchlist
                                                                                </h6>
                                                                            )}
                                                                        </div>
                                                                    )}
                                                                </>
                                                            )}
                                                        </>
                                                    ) : (
                                                        <></>
                                                    )}
                                                    {storeTheme === 'Liquidation_two' &&
                                                    checkThemeClassName ? (
                                                        <div className="mssBidItem mt-0">
                                                            <div className="mssBidMisc buyNowBidMisc">
                                                                {parseInt(selectedProduct.buynow) ==
                                                                    1 &&
                                                                parseInt(selectedProduct.auction) ==
                                                                    1 ? (
                                                                    <>
                                                                        {storeTheme !==
                                                                            'ArtAuction' && (
                                                                            <div className="mssBidInfo">
                                                                                <h5>
                                                                                    <span>
                                                                                        Buy Now
                                                                                        Price
                                                                                    </span>
                                                                                    {currencyFormat(
                                                                                        parseFloat(
                                                                                            selectedProduct.bprice,
                                                                                        ).toFixed(
                                                                                            2,
                                                                                        ),
                                                                                        currency,
                                                                                    )}
                                                                                    <CurrencyConverterFormat
                                                                                        amount={
                                                                                            selectedProduct.bprice
                                                                                        }
                                                                                    />
                                                                                </h5>
                                                                            </div>
                                                                        )}
                                                                        <div className="mssBidInfo">
                                                                            {selectedProduct.latestbid ? (
                                                                                <h5>
                                                                                    <span>
                                                                                        {selectedProduct.market_status ==
                                                                                        'sold'
                                                                                            ? 'Sold Bid'
                                                                                            : t(
                                                                                                  'current_bid',
                                                                                              )}
                                                                                    </span>
                                                                                    <span className="crncyValue">
                                                                                        {currencyFormat(
                                                                                            selectedProduct.wprice,
                                                                                            currency,
                                                                                        )}
                                                                                    </span>
                                                                                    <span className="crncyValue">
                                                                                        <CurrencyConverterFormat
                                                                                            amount={
                                                                                                selectedProduct.wprice
                                                                                            }
                                                                                        />
                                                                                    </span>
                                                                                </h5>
                                                                            ) : (
                                                                                <h5>
                                                                                    {selectedProduct.market_status ==
                                                                                    'closed' ? (
                                                                                        <span>
                                                                                            Unsold
                                                                                        </span>
                                                                                    ) : (
                                                                                        <>
                                                                                            <span>
                                                                                                Start
                                                                                                Bid
                                                                                            </span>
                                                                                            <span className="crncyValue">
                                                                                                {currencyFormat(
                                                                                                    selectedProduct.sprice,
                                                                                                    currency,
                                                                                                )}
                                                                                            </span>
                                                                                            <span className="crncyValue">
                                                                                                <CurrencyConverterFormat
                                                                                                    amount={
                                                                                                        selectedProduct.sprice
                                                                                                    }
                                                                                                />
                                                                                            </span>
                                                                                        </>
                                                                                    )}
                                                                                </h5>
                                                                            )}
                                                                            {/* <h5>
                                                            <span>Minimum Bid</span>
                                                            {currencyFormat(selectedProduct.next_bid)}
                                                        </h5> */}
                                                                        </div>
                                                                    </>
                                                                ) : type === 'buynow' ||
                                                                  parseInt(
                                                                      selectedProduct.buynow,
                                                                  ) == 1 ? (
                                                                    <div className="mssBidInfo">
                                                                        <h5>
                                                                            <span>
                                                                                Buy Now Price
                                                                            </span>
                                                                            {currencyFormat(
                                                                                parseFloat(
                                                                                    selectedProduct.bprice,
                                                                                ).toFixed(2),
                                                                                currency,
                                                                            )}
                                                                            <span>
                                                                                <CurrencyConverterFormat
                                                                                    amount={
                                                                                        selectedProduct.bprice
                                                                                    }
                                                                                />
                                                                            </span>
                                                                        </h5>
                                                                    </div>
                                                                ) : (
                                                                    <div className="mssBidInfo">
                                                                        {selectedProduct.latestbid ? (
                                                                            <h5>
                                                                                <span>
                                                                                    {selectedProduct.market_status ==
                                                                                    'sold'
                                                                                        ? 'Sold Bid'
                                                                                        : t(
                                                                                              'current_bid',
                                                                                          )}
                                                                                </span>
                                                                                <span className="crncyValue mainCurrency">
                                                                                    {currencyFormat(
                                                                                        selectedProduct.wprice,
                                                                                        currency,
                                                                                    )}
                                                                                </span>
                                                                                <span className="crncyValue makemycurrency">
                                                                                    <CurrencyConverterFormat
                                                                                        amount={
                                                                                            selectedProduct.wprice
                                                                                        }
                                                                                    />
                                                                                </span>
                                                                            </h5>
                                                                        ) : (
                                                                            <h5>
                                                                                {selectedProduct.market_status ==
                                                                                'closed' ? (
                                                                                    <span>
                                                                                        Unsold
                                                                                    </span>
                                                                                ) : (
                                                                                    <>
                                                                                        <span>
                                                                                            {t(
                                                                                                'start_bid',
                                                                                            )}
                                                                                        </span>
                                                                                        <span className="crncyValue mainCurrency">
                                                                                            {currencyFormat(
                                                                                                selectedProduct.sprice,
                                                                                                currency,
                                                                                            )}
                                                                                        </span>
                                                                                        <span className="crncyValue makemycurrency">
                                                                                            <CurrencyConverterFormat
                                                                                                amount={
                                                                                                    selectedProduct.sprice
                                                                                                }
                                                                                            />
                                                                                        </span>
                                                                                    </>
                                                                                )}
                                                                            </h5>
                                                                        )}
                                                                        {/* {selectedProduct.user_id == user?.id &&
                                                                global.storeConfigration?.listing_fee
                                                                    ?.value && (
                                                                    <h5>
                                                                        <span style={{ color: 'red' }}>
                                                                            Listing Fee
                                                                        </span>
                                                                        <span
                                                                            className="crncyValue"
                                                                            style={{ color: 'red' }}
                                                                        >
                                                                            {currencyFormat(
                                                                                global.storeConfigration
                                                                                    ?.listing_fee?.value,
                                                                                currency,
                                                                            )}
                                                                        </span>
                                                                    </h5>
                                                                )} */}
                                                                        {/* <h5>
                                                            <span>Minimum Bid</span>
                                                            {currencyFormat(selectedProduct.next_bid)}
                                                        </h5> */}
                                                                    </div>
                                                                )}
                                                                {checkThemeClassName ? (
                                                                    <>
                                                                        {(selectedProduct.auction ==
                                                                            1 &&
                                                                            selectedProduct.buynow ==
                                                                                0) ||
                                                                        selectedProduct.auction ==
                                                                            1 ? (
                                                                            <div className="mssTimer">
                                                                                {selectedProduct.market_status !=
                                                                                'draft' ? (
                                                                                    (selectedProduct.market_status !=
                                                                                        'open' &&
                                                                                        selectedProduct.market_status !=
                                                                                            'future') ||
                                                                                    selectedProduct.productClosed ? (
                                                                                        ''
                                                                                    ) : selectedProduct.extend ||
                                                                                      selectedProduct.agent_id ? (
                                                                                        <span className="tmm">
                                                                                            <Timer
                                                                                                date_added={
                                                                                                    selectedProduct.date_added
                                                                                                }
                                                                                                date_closed={
                                                                                                    selectedProduct.date_closed
                                                                                                }
                                                                                                withText={
                                                                                                    1
                                                                                                }
                                                                                                endText={
                                                                                                    'Ends in' +
                                                                                                    ':'
                                                                                                }
                                                                                                startText={
                                                                                                    'Starts in' +
                                                                                                    ':'
                                                                                                }
                                                                                                // theme="multiseller"
                                                                                            ></Timer>
                                                                                        </span>
                                                                                    ) : (
                                                                                        <span>
                                                                                            <Timer
                                                                                                date_added={
                                                                                                    selectedProduct.date_added
                                                                                                }
                                                                                                date_closed={
                                                                                                    selectedProduct.date_closed
                                                                                                }
                                                                                                withText={
                                                                                                    1
                                                                                                }
                                                                                                endText={
                                                                                                    'Ends in' +
                                                                                                    ':'
                                                                                                }
                                                                                                startText={
                                                                                                    'Starts in' +
                                                                                                    ':'
                                                                                                }
                                                                                                // theme="multiseller"
                                                                                            ></Timer>
                                                                                        </span>
                                                                                    )
                                                                                ) : (
                                                                                    ''
                                                                                )}
                                                                            </div>
                                                                        ) : (
                                                                            ''
                                                                        )}
                                                                    </>
                                                                ) : null}
                                                                {selectedProduct.market_status !=
                                                                'draft' ? (
                                                                    <>
                                                                        {selectedProduct.auction &&
                                                                        type !== 'buynow' ? (
                                                                            <>
                                                                                {global
                                                                                    ?.storeConfigration
                                                                                    ?.view_bid_history
                                                                                    ?.value ? null : (
                                                                                    <Button
                                                                                        className="mssBidHistory"
                                                                                        onClick={() =>
                                                                                            // selectedProduct.bids &&
                                                                                            // selectedProduct.bids !== 0 &&
                                                                                            // !selectedProduct.is_past &&
                                                                                            setHistoryData(
                                                                                                {
                                                                                                    product_id:
                                                                                                        selectedProduct.id,
                                                                                                    end: selectedProduct.date_closed,
                                                                                                },
                                                                                            )
                                                                                        }
                                                                                    >
                                                                                        <span className="material-icons">
                                                                                            history
                                                                                        </span>
                                                                                        {t(
                                                                                            'view_bid_history',
                                                                                        )}
                                                                                    </Button>
                                                                                )}
                                                                            </>
                                                                        ) : (
                                                                            ''
                                                                        )}
                                                                    </>
                                                                ) : (
                                                                    ''
                                                                )}
                                                            </div>

                                                            {selectedProduct.user_id == user?.id &&
                                                                global.storeConfigration
                                                                    ?.listing_fee?.value && (
                                                                    <h5>
                                                                        <span
                                                                            style={{ color: 'red' }}
                                                                        >
                                                                            Listing Fee
                                                                        </span>
                                                                        <span
                                                                            className="crncyValue"
                                                                            style={{ color: 'red' }}
                                                                        >
                                                                            {currencyFormat(
                                                                                global
                                                                                    .storeConfigration
                                                                                    ?.listing_fee
                                                                                    ?.value,
                                                                                currency,
                                                                            )}
                                                                        </span>
                                                                    </h5>
                                                                )}
                                                            {/* {selectedProduct.market_status != 'draft' &&
                                                selectedProduct.width ? ( */}
                                                            {/* // <ShippingRates */}
                                                            {/* //     width={selectedProduct.width}
                                                    //     height={selectedProduct.height}
                                                    //     length={selectedProduct.length}
                                                    //     weight={selectedProduct.weight}
                                                    //     fromzipcode={selectedProduct.zipcode}
                                                    // />
                                                // ) : null} */}

                                                            {/*Shipping Plugin Added Flow Start*/}

                                                            {parseInt(
                                                                selectedProduct.hasshipping,
                                                            ) &&
                                                            global.pluginConfiguration?.shipping
                                                                ?.enable &&
                                                            isAuthenticated &&
                                                            !parseInt(
                                                                selectedProduct.shippingcost,
                                                            ) > 0 ? (
                                                                <div className="pv-con">
                                                                    <p>
                                                                        {t('ship_update_info')}.{' '}
                                                                        {t('update')}{' '}
                                                                        <Link to="/dashboard/profile">
                                                                            {t('here')}
                                                                        </Link>
                                                                    </p>
                                                                    {shipping_amount ? (
                                                                        <>
                                                                            {/* <div className="pv-con"> */}
                                                                            <span>
                                                                                {t(
                                                                                    'shipping_amount',
                                                                                )}
                                                                            </span>

                                                                            {currencyFormat(
                                                                                parseFloat(
                                                                                    shipping_amount,
                                                                                ).toFixed(2),
                                                                            )}
                                                                            <span className="crncyValue">
                                                                                <CurrencyConverterFormat
                                                                                    amount={
                                                                                        shipping_amount
                                                                                    }
                                                                                />
                                                                            </span>
                                                                            {/* </div> */}
                                                                        </>
                                                                    ) : (
                                                                        <>
                                                                            {selectedProduct.weight !=
                                                                                0 &&
                                                                            selectedProduct.height !=
                                                                                0 &&
                                                                            selectedProduct.length !=
                                                                                0 &&
                                                                            selectedProduct.width !=
                                                                                0 ? (
                                                                                <PrimaryButton
                                                                                    label={t(
                                                                                        'calculate_shippin',
                                                                                    )}
                                                                                    btnSize="small"
                                                                                    disabled={
                                                                                        calc_loading
                                                                                    }
                                                                                    onClick={() => {
                                                                                        setCalcLoading(
                                                                                            true,
                                                                                        )
                                                                                        calculate_shipstation_amount()
                                                                                    }}
                                                                                />
                                                                            ) : (
                                                                                ''
                                                                            )}
                                                                        </>
                                                                    )}
                                                                </div>
                                                            ) : (
                                                                <>
                                                                    {parseInt(
                                                                        selectedProduct.shippingcost,
                                                                    ) > 0 ? (
                                                                        <>
                                                                            {/* <div className="pv-con"> */}
                                                                            <span>
                                                                                {t(
                                                                                    'shipping_amount',
                                                                                ) + ': '}
                                                                            </span>

                                                                            {currencyFormat(
                                                                                parseFloat(
                                                                                    selectedProduct.shippingcost,
                                                                                ).toFixed(2),
                                                                            )}
                                                                            <span className="crncyValue">
                                                                                <CurrencyConverterFormat
                                                                                    amount={
                                                                                        selectedProduct.shippingcost
                                                                                    }
                                                                                />
                                                                            </span>
                                                                            {/* </div> */}
                                                                        </>
                                                                    ) : (
                                                                        ''
                                                                    )}
                                                                </>
                                                            )}
                                                            {global.storeConfigration
                                                                ?.more_details_page?.value == 1 ? (
                                                                <button
                                                                    onClick={() =>
                                                                        setShowpopup(true)
                                                                    }
                                                                    type="button"
                                                                    className="more-details"
                                                                >
                                                                    More Details
                                                                </button>
                                                            ) : (
                                                                ''
                                                            )}

                                                            {/*Shipping Plugin Added Flow End*/}

                                                            {selectedProduct.market_status !=
                                                                'draft' &&
                                                            selectedProduct.market_status !=
                                                                'future' &&
                                                            global.storeDetails.theme != 9 ? (
                                                                <>
                                                                    {(selectedProduct.market_status !=
                                                                        'open' ||
                                                                        selectedProduct.productClosed ||
                                                                        (selectedProduct.date_closed &&
                                                                            moment(
                                                                                selectedProduct.date_closed,
                                                                            ).isValid() &&
                                                                            auctionTimer &&
                                                                            moment(
                                                                                auctionTimer,
                                                                            ).isValid() &&
                                                                            new Date(
                                                                                selectedProduct.date_closed,
                                                                            ).getTime() -
                                                                                new Date(
                                                                                    auctionTimer,
                                                                                ).getTime() <
                                                                                0)) &&
                                                                    parseInt(
                                                                        selectedProduct.buynow,
                                                                    ) == 0 ? (
                                                                        <b>Auction Closed</b>
                                                                    ) : (
                                                                        <div
                                                                            className={`sl-pbids slidView ${
                                                                                (!isAuthenticated ||
                                                                                    selectedProduct?.buynow) &&
                                                                                'notLogged'
                                                                            }`}
                                                                        >
                                                                            {isAuthenticated ? (
                                                                                <>
                                                                                    {selectedProduct.auction ? (
                                                                                        type ===
                                                                                        'buynow' ? null : (
                                                                                            <Fragment>
                                                                                                {selectedProduct?.user_id ==
                                                                                                user?.id ? (
                                                                                                    <p>
                                                                                                        {t(
                                                                                                            'do_not_bid',
                                                                                                        )}
                                                                                                    </p>
                                                                                                ) : (
                                                                                                    <>
                                                                                                        {global
                                                                                                            .storeConfigration
                                                                                                            ?.hard_bid
                                                                                                            ?.value !=
                                                                                                            '0' && (
                                                                                                            <div className="bidDet">
                                                                                                                {storeTheme !==
                                                                                                                    'ArtAuction' && (
                                                                                                                    <p className="slidViewBtnLabel">
                                                                                                                        <span className="material-icons">
                                                                                                                            info
                                                                                                                        </span>
                                                                                                                        Hard
                                                                                                                        Bid
                                                                                                                        -
                                                                                                                        Bid
                                                                                                                        on
                                                                                                                        the
                                                                                                                        current
                                                                                                                        price.
                                                                                                                    </p>
                                                                                                                )}
                                                                                                                {global
                                                                                                                    .pluginConfiguration
                                                                                                                    ?.reverse_auction
                                                                                                                    ?.enable ? (
                                                                                                                    <Biddingforwardandreverse
                                                                                                                        data={
                                                                                                                            selectedProduct
                                                                                                                        }
                                                                                                                        hard="1"
                                                                                                                    />
                                                                                                                ) : (
                                                                                                                    <>
                                                                                                                        <Bidding
                                                                                                                            data={
                                                                                                                                selectedProduct
                                                                                                                            }
                                                                                                                            hard="1"
                                                                                                                            inputSize={
                                                                                                                                storeTheme ===
                                                                                                                                'ArtAuction'
                                                                                                                                    ? 'small'
                                                                                                                                    : 'small'
                                                                                                                            }
                                                                                                                            // enableNextBid={
                                                                                                                            //     (storeTheme ===
                                                                                                                            //     'ArtAuction'
                                                                                                                            //         ? true
                                                                                                                            //         : false) ||
                                                                                                                            //     enableNextBid
                                                                                                                            // }
                                                                                                                        />
                                                                                                                        {artTheme && (
                                                                                                                            <>
                                                                                                                                <p className="bidderInfo">
                                                                                                                                    {t(
                                                                                                                                        'bid',
                                                                                                                                    )}{' '}
                                                                                                                                    $
                                                                                                                                    {selectedProduct?.next_bid
                                                                                                                                        ? selectedProduct?.next_bid
                                                                                                                                        : '-'}
                                                                                                                                    &nbsp;
                                                                                                                                    {selectedProduct?.next_bid ? (
                                                                                                                                        <CurrencyConverterFormat
                                                                                                                                            amount={
                                                                                                                                                selectedProduct?.next_bid
                                                                                                                                                    ? selectedProduct?.next_bid
                                                                                                                                                    : 0
                                                                                                                                            }
                                                                                                                                        />
                                                                                                                                    ) : (
                                                                                                                                        ''
                                                                                                                                    )}
                                                                                                                                    {t(
                                                                                                                                        'or_more',
                                                                                                                                    )}
                                                                                                                                </p>
                                                                                                                                {/* <div className="ArtpaymentOption">
                                                                                                                        <div className="Tit">
                                                                                                                            Payments
                                                                                                                            :{' '}
                                                                                                                        </div>
                                                                                                                        <div className="types">
                                                                                                                            <span>
                                                                                                                                Credit
                                                                                                                                Cards,
                                                                                                                            </span>
                                                                                                                            <span>
                                                                                                                                PayPal
                                                                                                                            </span>
                                                                                                                        </div>
                                                                                                                    </div> */}
                                                                                                                            </>
                                                                                                                        )}
                                                                                                                    </>
                                                                                                                )}
                                                                                                            </div>
                                                                                                        )}
                                                                                                        {global
                                                                                                            .storeConfigration
                                                                                                            ?.proxy_bid
                                                                                                            ?.value !=
                                                                                                            0 && (
                                                                                                            <div className="bidDet">
                                                                                                                <p className="slidViewBtnLabel">
                                                                                                                    <span className="material-icons">
                                                                                                                        info
                                                                                                                    </span>
                                                                                                                    Proxy
                                                                                                                    Bid
                                                                                                                    -
                                                                                                                    Enter
                                                                                                                    the
                                                                                                                    maximum
                                                                                                                    bid
                                                                                                                    you
                                                                                                                    would
                                                                                                                    like
                                                                                                                    to
                                                                                                                    automatically
                                                                                                                    bid
                                                                                                                    to.
                                                                                                                </p>
                                                                                                                {global
                                                                                                                    .pluginConfiguration
                                                                                                                    ?.reverse_auction
                                                                                                                    ?.enable ? (
                                                                                                                    <Biddingforwardandreverse
                                                                                                                        data={
                                                                                                                            selectedProduct
                                                                                                                        }
                                                                                                                        hard="0"
                                                                                                                        status={
                                                                                                                            1
                                                                                                                        }
                                                                                                                    />
                                                                                                                ) : (
                                                                                                                    <Bidding
                                                                                                                        data={
                                                                                                                            selectedProduct
                                                                                                                        }
                                                                                                                        hard="0"
                                                                                                                        status={
                                                                                                                            1
                                                                                                                        }
                                                                                                                    />
                                                                                                                )}
                                                                                                            </div>
                                                                                                        )}
                                                                                                    </>
                                                                                                )}
                                                                                            </Fragment>
                                                                                        )
                                                                                    ) : null}
                                                                                    {!checkThemeClassName ? (
                                                                                        <>
                                                                                            {(parseInt(
                                                                                                selectedProduct.buynow,
                                                                                            ) ==
                                                                                                1 &&
                                                                                                parseInt(
                                                                                                    selectedProduct.auction,
                                                                                                ) ==
                                                                                                    0) ||
                                                                                            (parseInt(
                                                                                                selectedProduct.wprice,
                                                                                            ) <=
                                                                                                parseInt(
                                                                                                    selectedProduct.bprice,
                                                                                                ) &&
                                                                                                parseInt(
                                                                                                    selectedProduct.buynow,
                                                                                                ) ==
                                                                                                    1 &&
                                                                                                parseInt(
                                                                                                    selectedProduct.auction,
                                                                                                ) ==
                                                                                                    1) ? (
                                                                                                <>
                                                                                                    <div className="">
                                                                                                        {selectedProduct?.user_id ==
                                                                                                        user?.id ? (
                                                                                                            <p>
                                                                                                                You
                                                                                                                cannot
                                                                                                                buy
                                                                                                                your
                                                                                                                own
                                                                                                                product!
                                                                                                            </p>
                                                                                                        ) : (
                                                                                                            <>
                                                                                                                {itemDetails
                                                                                                                    ?.filter(
                                                                                                                        (
                                                                                                                            i,
                                                                                                                        ) =>
                                                                                                                            i.id ==
                                                                                                                            selectedLot,
                                                                                                                    )
                                                                                                                    .map(
                                                                                                                        (
                                                                                                                            d,
                                                                                                                        ) =>
                                                                                                                            d.buynowpaid,
                                                                                                                    ) ==
                                                                                                                1 ? (
                                                                                                                    <h5 className="itemPurchased">
                                                                                                                        You
                                                                                                                        have
                                                                                                                        purchased
                                                                                                                        this
                                                                                                                        item
                                                                                                                    </h5>
                                                                                                                ) : (
                                                                                                                    <>
                                                                                                                        {global
                                                                                                                            .pluginConfiguration
                                                                                                                            ?.make_an_offer
                                                                                                                            ?.enable &&
                                                                                                                        selectedProduct?.make_an_offer_io ? (
                                                                                                                            <div className="row">
                                                                                                                                {mapData(
                                                                                                                                    make_an_offer,
                                                                                                                                )}
                                                                                                                                <div className="col-sm-6 col-12">
                                                                                                                                    <PrimaryButton
                                                                                                                                        onClick={(
                                                                                                                                            e,
                                                                                                                                        ) =>
                                                                                                                                            selectedProduct.market_status ==
                                                                                                                                            'open'
                                                                                                                                                ? make_formik.handleSubmit()
                                                                                                                                                : ''
                                                                                                                                        }
                                                                                                                                        disabled={
                                                                                                                                            selectedProduct.market_status !=
                                                                                                                                            'open'
                                                                                                                                        }
                                                                                                                                    >
                                                                                                                                        {selectedProduct.market_status ==
                                                                                                                                        'sold'
                                                                                                                                            ? 'Lot Sold'
                                                                                                                                            : selectedProduct.market_status ==
                                                                                                                                                  'unsold' ||
                                                                                                                                              selectedProduct.market_status ==
                                                                                                                                                  'closed'
                                                                                                                                            ? 'Lot Closed'
                                                                                                                                            : `Submit Offer`}
                                                                                                                                    </PrimaryButton>
                                                                                                                                </div>
                                                                                                                            </div>
                                                                                                                        ) : (
                                                                                                                            ''
                                                                                                                        )}

                                                                                                                        <PrimaryButton
                                                                                                                            onClick={
                                                                                                                                selectedProduct.market_status ==
                                                                                                                                'open'
                                                                                                                                    ? handleClick
                                                                                                                                    : ''
                                                                                                                            }
                                                                                                                            disabled={
                                                                                                                                selectedProduct.market_status !=
                                                                                                                                'open'
                                                                                                                            }
                                                                                                                        >
                                                                                                                            {selectedProduct.market_status ==
                                                                                                                            'sold'
                                                                                                                                ? t(
                                                                                                                                      'lot_sold',
                                                                                                                                  )
                                                                                                                                : selectedProduct.market_status ==
                                                                                                                                      'unsold' ||
                                                                                                                                  selectedProduct.market_status ==
                                                                                                                                      'closed'
                                                                                                                                ? t(
                                                                                                                                      'lot_closed',
                                                                                                                                  )
                                                                                                                                : disabled
                                                                                                                                ? t(
                                                                                                                                      'remove_from_cart',
                                                                                                                                  )
                                                                                                                                : `${t(
                                                                                                                                      'buy_now_for',
                                                                                                                                  )} ${currencyFormat(
                                                                                                                                      parseFloat(
                                                                                                                                          selectedProduct.bprice,
                                                                                                                                      ).toFixed(
                                                                                                                                          2,
                                                                                                                                      ),
                                                                                                                                      currency,
                                                                                                                                  )}`}
                                                                                                                        </PrimaryButton>
                                                                                                                    </>
                                                                                                                )}
                                                                                                            </>
                                                                                                        )}
                                                                                                    </div>
                                                                                                    <br />
                                                                                                </>
                                                                                            ) : null}
                                                                                        </>
                                                                                    ) : null}
                                                                                </>
                                                                            ) : (
                                                                                ''
                                                                            )}
                                                                            {!isAuthenticated &&
                                                                                !checkThemeClassName &&
                                                                                selectedProduct.market_status !==
                                                                                    'sold' && (
                                                                                    <PrimaryButton
                                                                                        className="my-2"
                                                                                        onClick={() =>
                                                                                            history.push(
                                                                                                '/login',
                                                                                            )
                                                                                        }
                                                                                    >
                                                                                        {selectedProduct?.buynow &&
                                                                                        selectedProduct?.auction
                                                                                            ? 'Login to Buy Or Bid'
                                                                                            : selectedProduct?.buynow
                                                                                            ? 'Login to Buy'
                                                                                            : t(
                                                                                                  'login_to_bid',
                                                                                              )}
                                                                                    </PrimaryButton>
                                                                                )}
                                                                            <>
                                                                                {storeTheme !==
                                                                                    'ArtAuction' && (
                                                                                    <>
                                                                                        {[
                                                                                            ...(global
                                                                                                .storeDetails
                                                                                                ?.theme ==
                                                                                            5
                                                                                                ? new_lotInformation
                                                                                                : lotInformation),
                                                                                        ].map(
                                                                                            (
                                                                                                data,
                                                                                                index,
                                                                                            ) => (
                                                                                                <>
                                                                                                    {data.title ==
                                                                                                        'Pickup Details' &&
                                                                                                    global
                                                                                                        .pluginConfiguration
                                                                                                        ?.limit_pickup_days
                                                                                                        ?.enable &&
                                                                                                    data.description ==
                                                                                                        'Pickup' &&
                                                                                                    parseInt(
                                                                                                        selectedProduct.loc_id,
                                                                                                    ) >
                                                                                                        0 ? (
                                                                                                        <>
                                                                                                            {checkThemeClassName ? (
                                                                                                                <div
                                                                                                                    className={`mssDesc`}
                                                                                                                >
                                                                                                                    <h6>
                                                                                                                        {
                                                                                                                            data.title
                                                                                                                        }
                                                                                                                    </h6>
                                                                                                                    <div className="pickTimeAdjCont d-flex align-items-center">
                                                                                                                        <Button
                                                                                                                            onClick={(
                                                                                                                                e,
                                                                                                                            ) =>
                                                                                                                                setOpenpickup(
                                                                                                                                    'open',
                                                                                                                                )
                                                                                                                            }
                                                                                                                        >
                                                                                                                            <span className="material-icons">
                                                                                                                                info
                                                                                                                            </span>
                                                                                                                        </Button>
                                                                                                                        <span>
                                                                                                                            Pickup
                                                                                                                            time
                                                                                                                            for
                                                                                                                            this
                                                                                                                            item
                                                                                                                        </span>
                                                                                                                    </div>
                                                                                                                    {global
                                                                                                                        .storeConfigration
                                                                                                                        ?.show_condition
                                                                                                                        ?.value ==
                                                                                                                        1 &&
                                                                                                                    storeTheme ===
                                                                                                                        'Liquidation_two' ? (
                                                                                                                        <h6 className="galleryPrice condition">
                                                                                                                            <span>
                                                                                                                                Condition:{' '}
                                                                                                                            </span>
                                                                                                                            <span
                                                                                                                                className={`prcTxtVal ${
                                                                                                                                    selectedProduct.auctionnotes
                                                                                                                                        ?.trim()
                                                                                                                                        ?.toLowerCase()
                                                                                                                                        ?.includes(
                                                                                                                                            'new',
                                                                                                                                        )
                                                                                                                                        ? 'badge badge-success text-white'
                                                                                                                                        : selectedProduct.auctionnotes
                                                                                                                                              ?.trim()
                                                                                                                                              ?.toLowerCase()
                                                                                                                                              ?.includes(
                                                                                                                                                  'open box',
                                                                                                                                              )
                                                                                                                                        ? 'badge badge-primary text-white'
                                                                                                                                        : selectedProduct.auctionnotes
                                                                                                                                              ?.trim()
                                                                                                                                              ?.toLowerCase()
                                                                                                                                              ?.includes(
                                                                                                                                                  'damage',
                                                                                                                                              )
                                                                                                                                        ? 'badge badge-danger text-white'
                                                                                                                                        : ''
                                                                                                                                }`}
                                                                                                                                title={
                                                                                                                                    selectedProduct.auctionnotes
                                                                                                                                        ? selectedProduct.auctionnotes
                                                                                                                                        : '-'
                                                                                                                                }
                                                                                                                            >
                                                                                                                                {selectedProduct.auctionnotes
                                                                                                                                    ? selectedProduct.auctionnotes
                                                                                                                                    : '-'}
                                                                                                                            </span>
                                                                                                                        </h6>
                                                                                                                    ) : null}
                                                                                                                </div>
                                                                                                            ) : (
                                                                                                                ''
                                                                                                            )}
                                                                                                        </>
                                                                                                    ) : data.description &&
                                                                                                      !checkThemeClassName &&
                                                                                                      data.display_need ==
                                                                                                          1 ? (
                                                                                                        <div
                                                                                                            key={
                                                                                                                index
                                                                                                            }
                                                                                                            className={`mssDesc`}
                                                                                                        >
                                                                                                            <h6>
                                                                                                                {
                                                                                                                    data.title
                                                                                                                }
                                                                                                            </h6>
                                                                                                            {data.title ===
                                                                                                            'Description' ? (
                                                                                                                <div
                                                                                                                    className={`shwMrBtnWrp myWrapping d-flex align-items-start justify-content-between`}
                                                                                                                >
                                                                                                                    <p
                                                                                                                        className={`mb-1 pdtDscrpnVlue ${
                                                                                                                            showDescriptions
                                                                                                                                ? ''
                                                                                                                                : 'hide'
                                                                                                                        }`}
                                                                                                                        dangerouslySetInnerHTML={{
                                                                                                                            __html: data.description,
                                                                                                                        }}
                                                                                                                    ></p>
                                                                                                                    <span
                                                                                                                        className="mb-3 cursorDecoy shwMrBtn"
                                                                                                                        onClick={() =>
                                                                                                                            setShowDescriptions(
                                                                                                                                !showDescriptions,
                                                                                                                            )
                                                                                                                        }
                                                                                                                    >
                                                                                                                        <b>
                                                                                                                            {descripTexts?.length >
                                                                                                                                55 && (
                                                                                                                                <u>
                                                                                                                                    Show
                                                                                                                                    {showDescriptions
                                                                                                                                        ? 'Less'
                                                                                                                                        : 'More'}
                                                                                                                                </u>
                                                                                                                            )}
                                                                                                                        </b>
                                                                                                                    </span>
                                                                                                                </div>
                                                                                                            ) : data.title ==
                                                                                                                  'Condition Report' &&
                                                                                                              global
                                                                                                                  .storeConfigration
                                                                                                                  ?.hyper_micro_popup_more
                                                                                                                  ?.value ==
                                                                                                                  1 &&
                                                                                                              global?.condition_data?.find(
                                                                                                                  (
                                                                                                                      val,
                                                                                                                  ) =>
                                                                                                                      val.condition ==
                                                                                                                      data.description,
                                                                                                              )
                                                                                                                  ?.content ? (
                                                                                                                <div
                                                                                                                    className={`shwMrBtnWrp d-flex align-items-center justify-content-start `}
                                                                                                                >
                                                                                                                    <>
                                                                                                                        <p
                                                                                                                            className="my-1 pdtDscrpnVlue mr-1"
                                                                                                                            dangerouslySetInnerHTML={{
                                                                                                                                __html: data.description,
                                                                                                                            }}
                                                                                                                        ></p>
                                                                                                                        <Tooltip
                                                                                                                            title={
                                                                                                                                global?.condition_data?.find(
                                                                                                                                    (
                                                                                                                                        val,
                                                                                                                                    ) =>
                                                                                                                                        val.condition ==
                                                                                                                                        data.description,
                                                                                                                                )
                                                                                                                                    ?.content
                                                                                                                            }
                                                                                                                            arrow
                                                                                                                            enterTouchDelay={
                                                                                                                                0
                                                                                                                            }
                                                                                                                            placement="top-start"
                                                                                                                            className="tooling"
                                                                                                                        >
                                                                                                                            <span>
                                                                                                                                i
                                                                                                                            </span>
                                                                                                                        </Tooltip>
                                                                                                                    </>
                                                                                                                </div>
                                                                                                            ) : (
                                                                                                                <div
                                                                                                                    className={`shwMrBtnWrp d-flex align-items-start justify-content-between `}
                                                                                                                >
                                                                                                                    <p
                                                                                                                        className="mb-1 pdtDscrpnVlue"
                                                                                                                        dangerouslySetInnerHTML={{
                                                                                                                            __html: data.description,
                                                                                                                        }}
                                                                                                                    ></p>
                                                                                                                </div>
                                                                                                            )}
                                                                                                        </div>
                                                                                                    ) : null}
                                                                                                </>
                                                                                            ),
                                                                                        )}

                                                                                        {!checkThemeClassName ? (
                                                                                            <>
                                                                                                {' '}
                                                                                                {(selectedProduct.auction ==
                                                                                                    1 &&
                                                                                                    selectedProduct.buynow ==
                                                                                                        0) ||
                                                                                                selectedProduct.auction ==
                                                                                                    1 ? (
                                                                                                    <div className="mssTimer">
                                                                                                        {selectedProduct.market_status !=
                                                                                                        'draft' ? (
                                                                                                            (selectedProduct.market_status !=
                                                                                                                'open' &&
                                                                                                                selectedProduct.market_status !=
                                                                                                                    'future') ||
                                                                                                            selectedProduct.productClosed ? (
                                                                                                                ''
                                                                                                            ) : selectedProduct.extend ||
                                                                                                              selectedProduct.agent_id ? (
                                                                                                                <span className="tmm">
                                                                                                                    <Timer
                                                                                                                        date_added={
                                                                                                                            selectedProduct.date_added
                                                                                                                        }
                                                                                                                        date_closed={
                                                                                                                            selectedProduct.date_closed
                                                                                                                        }
                                                                                                                        withText={
                                                                                                                            1
                                                                                                                        }
                                                                                                                        endText={
                                                                                                                            'Ends in' +
                                                                                                                            ':'
                                                                                                                        }
                                                                                                                        startText={
                                                                                                                            'Starts in' +
                                                                                                                            ':'
                                                                                                                        }
                                                                                                                        // theme="multiseller"
                                                                                                                    ></Timer>
                                                                                                                </span>
                                                                                                            ) : (
                                                                                                                <span>
                                                                                                                    <Timer
                                                                                                                        date_added={
                                                                                                                            selectedProduct.date_added
                                                                                                                        }
                                                                                                                        date_closed={
                                                                                                                            selectedProduct.date_closed
                                                                                                                        }
                                                                                                                        withText={
                                                                                                                            1
                                                                                                                        }
                                                                                                                        endText={
                                                                                                                            'Ends in' +
                                                                                                                            ':'
                                                                                                                        }
                                                                                                                        startText={
                                                                                                                            'Starts in' +
                                                                                                                            ':'
                                                                                                                        }
                                                                                                                        // theme="multiseller"
                                                                                                                    ></Timer>
                                                                                                                </span>
                                                                                                            )
                                                                                                        ) : (
                                                                                                            ''
                                                                                                        )}
                                                                                                    </div>
                                                                                                ) : (
                                                                                                    ''
                                                                                                )}
                                                                                            </>
                                                                                        ) : null}
                                                                                    </>
                                                                                )}
                                                                            </>
                                                                        </div>
                                                                    )}
                                                                </>
                                                            ) : (
                                                                ''
                                                            )}

                                                            {selectedProduct.market_status !=
                                                                'draft' &&
                                                            selectedProduct.market_status !=
                                                                'future' &&
                                                            global.storeDetails.theme == 9 ? (
                                                                <>
                                                                    {(selectedProduct.market_status !=
                                                                        'open' ||
                                                                        selectedProduct.productClosed ||
                                                                        (selectedProduct.date_closed &&
                                                                            moment(
                                                                                selectedProduct.date_closed,
                                                                            ).isValid() &&
                                                                            auctionTimer &&
                                                                            moment(
                                                                                auctionTimer,
                                                                            ).isValid() &&
                                                                            new Date(
                                                                                selectedProduct.date_closed,
                                                                            ).getTime() -
                                                                                new Date(
                                                                                    auctionTimer,
                                                                                ).getTime() <
                                                                                0)) &&
                                                                    parseInt(
                                                                        selectedProduct.buynow,
                                                                    ) == 0 ? (
                                                                        <b>Auction Closed</b>
                                                                    ) : (
                                                                        <div
                                                                            className={`sl-pbids slidView ${
                                                                                (!isAuthenticated ||
                                                                                    selectedProduct?.buynow) &&
                                                                                'notLogged'
                                                                            }`}
                                                                        >
                                                                            {isAuthenticated ? (
                                                                                <>
                                                                                    {selectedProduct.auction ? (
                                                                                        type ===
                                                                                        'buynow' ? null : (
                                                                                            <Fragment>
                                                                                                {selectedProduct?.user_id ==
                                                                                                user?.id ? (
                                                                                                    <p>
                                                                                                        {t(
                                                                                                            'do_not_bid',
                                                                                                        )}
                                                                                                    </p>
                                                                                                ) : (
                                                                                                    <>
                                                                                                        {global
                                                                                                            .storeConfigration
                                                                                                            ?.hard_bid
                                                                                                            ?.value && (
                                                                                                            <div className="bidDet">
                                                                                                                {parseInt(
                                                                                                                    selectedProduct.content_head5,
                                                                                                                ) ==
                                                                                                                    0 && (
                                                                                                                    <p className="slidViewBtnLabel">
                                                                                                                        <span className="material-icons">
                                                                                                                            info
                                                                                                                        </span>
                                                                                                                        Hard
                                                                                                                        Bid
                                                                                                                        -
                                                                                                                        Bid
                                                                                                                        on
                                                                                                                        the
                                                                                                                        current
                                                                                                                        price.
                                                                                                                    </p>
                                                                                                                )}
                                                                                                                {global
                                                                                                                    .pluginConfiguration
                                                                                                                    ?.reverse_auction
                                                                                                                    ?.enable ? (
                                                                                                                    <Biddingforwardandreverse
                                                                                                                        data={
                                                                                                                            selectedProduct
                                                                                                                        }
                                                                                                                        hard="1"
                                                                                                                    />
                                                                                                                ) : (
                                                                                                                    <Bidding
                                                                                                                        data={
                                                                                                                            selectedProduct
                                                                                                                        }
                                                                                                                        hard="1"
                                                                                                                        inputSize={
                                                                                                                            storeTheme ===
                                                                                                                            'ArtAuction'
                                                                                                                                ? 'large'
                                                                                                                                : 'small'
                                                                                                                        }
                                                                                                                        enableNextBid={
                                                                                                                            storeTheme ===
                                                                                                                            'ArtAuction'
                                                                                                                                ? true
                                                                                                                                : false
                                                                                                                        }
                                                                                                                    />
                                                                                                                )}
                                                                                                            </div>
                                                                                                        )}
                                                                                                        {global
                                                                                                            .storeConfigration
                                                                                                            ?.proxy_bid
                                                                                                            ?.value !=
                                                                                                            0 &&
                                                                                                            parseInt(
                                                                                                                selectedProduct.content_head5,
                                                                                                            ) ==
                                                                                                                0 && (
                                                                                                                <div className="bidDet">
                                                                                                                    <p className="slidViewBtnLabel">
                                                                                                                        <span className="material-icons">
                                                                                                                            info
                                                                                                                        </span>
                                                                                                                        Proxy
                                                                                                                        Bid
                                                                                                                        -
                                                                                                                        Enter
                                                                                                                        the
                                                                                                                        maximum
                                                                                                                        bid
                                                                                                                        you
                                                                                                                        would
                                                                                                                        like
                                                                                                                        to
                                                                                                                        automatically
                                                                                                                        bid
                                                                                                                        to.
                                                                                                                    </p>
                                                                                                                    {global
                                                                                                                        .pluginConfiguration
                                                                                                                        ?.reverse_auction
                                                                                                                        ?.enable ? (
                                                                                                                        <Biddingforwardandreverse
                                                                                                                            data={
                                                                                                                                selectedProduct
                                                                                                                            }
                                                                                                                            hard="0"
                                                                                                                            status={
                                                                                                                                1
                                                                                                                            }
                                                                                                                        />
                                                                                                                    ) : (
                                                                                                                        <Bidding
                                                                                                                            data={
                                                                                                                                selectedProduct
                                                                                                                            }
                                                                                                                            hard="0"
                                                                                                                            status={
                                                                                                                                1
                                                                                                                            }
                                                                                                                        />
                                                                                                                    )}
                                                                                                                </div>
                                                                                                            )}
                                                                                                    </>
                                                                                                )}
                                                                                            </Fragment>
                                                                                        )
                                                                                    ) : null}

                                                                                    {storeTheme !==
                                                                                        'ArtAuction' && (
                                                                                        <>
                                                                                            {(parseInt(
                                                                                                selectedProduct.buynow,
                                                                                            ) ==
                                                                                                1 &&
                                                                                                parseInt(
                                                                                                    selectedProduct.auction,
                                                                                                ) ==
                                                                                                    0) ||
                                                                                            (parseInt(
                                                                                                selectedProduct.wprice,
                                                                                            ) <=
                                                                                                parseInt(
                                                                                                    selectedProduct.bprice,
                                                                                                ) &&
                                                                                                parseInt(
                                                                                                    selectedProduct.buynow,
                                                                                                ) ==
                                                                                                    1 &&
                                                                                                parseInt(
                                                                                                    selectedProduct.auction,
                                                                                                ) ==
                                                                                                    1) ? (
                                                                                                <>
                                                                                                    <div className="">
                                                                                                        {selectedProduct?.user_id ==
                                                                                                        user?.id ? (
                                                                                                            <p>
                                                                                                                You
                                                                                                                cannot
                                                                                                                buy
                                                                                                                your
                                                                                                                own
                                                                                                                product!
                                                                                                            </p>
                                                                                                        ) : (
                                                                                                            <>
                                                                                                                {itemDetails
                                                                                                                    ?.filter(
                                                                                                                        (
                                                                                                                            i,
                                                                                                                        ) =>
                                                                                                                            i.id ==
                                                                                                                            selectedLot,
                                                                                                                    )
                                                                                                                    .map(
                                                                                                                        (
                                                                                                                            d,
                                                                                                                        ) =>
                                                                                                                            d.buynowpaid,
                                                                                                                    ) ==
                                                                                                                1 ? (
                                                                                                                    <h5 className="itemPurchased">
                                                                                                                        You
                                                                                                                        have
                                                                                                                        purchased
                                                                                                                        this
                                                                                                                        item
                                                                                                                    </h5>
                                                                                                                ) : (
                                                                                                                    <PrimaryButton
                                                                                                                        onClick={
                                                                                                                            selectedProduct.market_status ==
                                                                                                                            'open'
                                                                                                                                ? handleClick
                                                                                                                                : ''
                                                                                                                        }
                                                                                                                        disabled={
                                                                                                                            selectedProduct.market_status !=
                                                                                                                            'open'
                                                                                                                                ? true
                                                                                                                                : disabled
                                                                                                                        }
                                                                                                                    >
                                                                                                                        {selectedProduct.market_status ==
                                                                                                                        'sold'
                                                                                                                            ? 'Lot Sold'
                                                                                                                            : selectedProduct.market_status ==
                                                                                                                                  'unsold' ||
                                                                                                                              selectedProduct.market_status ==
                                                                                                                                  'closed'
                                                                                                                            ? 'Lot Closed'
                                                                                                                            : disabled
                                                                                                                            ? 'Added to cart'
                                                                                                                            : `Buy now for ${currencyFormat(
                                                                                                                                  parseFloat(
                                                                                                                                      selectedProduct.bprice,
                                                                                                                                  ).toFixed(
                                                                                                                                      2,
                                                                                                                                  ),
                                                                                                                                  currency,
                                                                                                                              )}`}
                                                                                                                    </PrimaryButton>
                                                                                                                )}
                                                                                                            </>
                                                                                                        )}
                                                                                                    </div>
                                                                                                    {storeTheme !==
                                                                                                        'ArtAuction' && (
                                                                                                        <br />
                                                                                                    )}
                                                                                                </>
                                                                                            ) : null}
                                                                                        </>
                                                                                    )}
                                                                                </>
                                                                            ) : null}
                                                                            {!isAuthenticated && (
                                                                                <PrimaryButton
                                                                                    className="my-2"
                                                                                    onClick={() =>
                                                                                        history.push(
                                                                                            '/login',
                                                                                        )
                                                                                    }
                                                                                >
                                                                                    Login to{' '}
                                                                                    {selectedProduct?.buynow &&
                                                                                    selectedProduct?.auction
                                                                                        ? 'Buy Or Bid'
                                                                                        : selectedProduct?.buynow
                                                                                        ? 'Buy'
                                                                                        : 'Bid'}
                                                                                </PrimaryButton>
                                                                            )}
                                                                        </div>
                                                                    )}
                                                                </>
                                                            ) : (
                                                                ''
                                                            )}
                                                        </div>
                                                    ) : (
                                                        <></>
                                                    )}

                                                    {storeTheme === 'ArtAuction' ? (
                                                        <>
                                                            {/* {selectedProduct.market_status == 'open' ? (
                                                        <FavouriteCheckbox
                                                            watchlisted={selectedProduct.wlistpop}
                                                            data={selectedProduct}
                                                            project_id={selectedProduct.id}
                                                            from={from}
                                                            getAllProducts={getAllProducts}
                                                            setSelectedProduct={setSelectedProduct}
                                                        />
                                                    ) : null} */}
                                                            {(parseInt(selectedProduct.buynow) ==
                                                                0 ||
                                                                isArtTheme) &&
                                                            (parseInt(selectedProduct.lowest) > 0 ||
                                                                parseInt(selectedProduct.highest) >
                                                                    0) ? (
                                                                <h5 className="estValue">
                                                                    <span>{t('est')}:</span>
                                                                    {`${
                                                                        ' ' +
                                                                        currencyFormat(
                                                                            selectedProduct.lowest,
                                                                            currency,
                                                                        ) +
                                                                        ' - ' +
                                                                        currencyFormat(
                                                                            selectedProduct.highest,
                                                                            currency,
                                                                        )
                                                                    }`}
                                                                </h5>
                                                            ) : (
                                                                ''
                                                            )}
                                                            {selectedProduct.conditionunique ? (
                                                                <div className="mssBidInfo">
                                                                    <h5>
                                                                        <span>
                                                                            {t('condition')}:
                                                                        </span>
                                                                        {conditions ===
                                                                        'Collectibles' ? (
                                                                            <>
                                                                                {collectibleConditions
                                                                                    .filter(
                                                                                        (
                                                                                            condition,
                                                                                        ) =>
                                                                                            condition.id ==
                                                                                            selectedProduct.conditionunique,
                                                                                    )
                                                                                    .map(
                                                                                        (data) =>
                                                                                            data.description,
                                                                                    )}
                                                                            </>
                                                                        ) : (
                                                                            <>
                                                                                {allCondition
                                                                                    .filter(
                                                                                        (
                                                                                            condition,
                                                                                        ) =>
                                                                                            condition.id ==
                                                                                            selectedProduct.conditionunique,
                                                                                    )
                                                                                    .map(
                                                                                        (data) =>
                                                                                            data.description,
                                                                                    )}
                                                                            </>
                                                                        )}
                                                                    </h5>
                                                                </div>
                                                            ) : null}
                                                            {artTheme ? null : (
                                                                <>
                                                                    {selectedProduct.content_head5 ? (
                                                                        <div className="mssBidInfo artisName">
                                                                            <h5>
                                                                                <span>
                                                                                    {t(
                                                                                        'artist_name',
                                                                                    )}
                                                                                    :
                                                                                </span>
                                                                                <span
                                                                                    style={{
                                                                                        color: '#0b81ff',
                                                                                        cursor: 'pointer',
                                                                                    }}
                                                                                    onClick={() => {
                                                                                        handleClose()
                                                                                        history.push(
                                                                                            `/bio/${selectedProduct.user_id}`,
                                                                                        )
                                                                                        window.scrollTo(
                                                                                            0,
                                                                                            0,
                                                                                        )
                                                                                    }}
                                                                                >
                                                                                    {
                                                                                        selectedProduct.content_head5
                                                                                    }
                                                                                </span>
                                                                            </h5>
                                                                        </div>
                                                                    ) : null}
                                                                </>
                                                            )}
                                                        </>
                                                    ) : (
                                                        <>
                                                            {isAuthenticated &&
                                                            checkThemeClassName ? (
                                                                <>
                                                                    {(parseInt(
                                                                        selectedProduct.buynow,
                                                                    ) == 1 &&
                                                                        parseInt(
                                                                            selectedProduct.auction,
                                                                        ) == 0) ||
                                                                    (parseInt(
                                                                        selectedProduct.wprice,
                                                                    ) <=
                                                                        parseInt(
                                                                            selectedProduct.bprice,
                                                                        ) &&
                                                                        parseInt(
                                                                            selectedProduct.buynow,
                                                                        ) == 1 &&
                                                                        parseInt(
                                                                            selectedProduct.auction,
                                                                        ) == 1) ? (
                                                                        <>
                                                                            <div className="">
                                                                                {selectedProduct?.user_id ==
                                                                                user?.id ? (
                                                                                    <p>
                                                                                        You cannot
                                                                                        buy your own
                                                                                        product!
                                                                                    </p>
                                                                                ) : (
                                                                                    <>
                                                                                        {itemDetails
                                                                                            ?.filter(
                                                                                                (
                                                                                                    i,
                                                                                                ) =>
                                                                                                    i.id ==
                                                                                                    selectedLot,
                                                                                            )
                                                                                            .map(
                                                                                                (
                                                                                                    d,
                                                                                                ) =>
                                                                                                    d.buynowpaid,
                                                                                            ) ==
                                                                                        1 ? (
                                                                                            <h5 className="itemPurchased">
                                                                                                You
                                                                                                have
                                                                                                purchased
                                                                                                this
                                                                                                item
                                                                                            </h5>
                                                                                        ) : (
                                                                                            <>
                                                                                                {global
                                                                                                    .pluginConfiguration
                                                                                                    ?.make_an_offer
                                                                                                    ?.enable &&
                                                                                                selectedProduct?.make_an_offer_io ? (
                                                                                                    <div className="row">
                                                                                                        {mapData(
                                                                                                            make_an_offer,
                                                                                                        )}
                                                                                                        <div className="col-sm-6 col-12">
                                                                                                            <PrimaryButton
                                                                                                                onClick={(
                                                                                                                    e,
                                                                                                                ) =>
                                                                                                                    selectedProduct.market_status ==
                                                                                                                    'open'
                                                                                                                        ? make_formik.handleSubmit()
                                                                                                                        : ''
                                                                                                                }
                                                                                                                disabled={
                                                                                                                    selectedProduct.market_status !=
                                                                                                                    'open'
                                                                                                                }
                                                                                                            >
                                                                                                                {selectedProduct.market_status ==
                                                                                                                'sold'
                                                                                                                    ? 'Lot Sold'
                                                                                                                    : selectedProduct.market_status ==
                                                                                                                          'unsold' ||
                                                                                                                      selectedProduct.market_status ==
                                                                                                                          'closed'
                                                                                                                    ? 'Lot Closed'
                                                                                                                    : `Submit Offer`}
                                                                                                            </PrimaryButton>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                ) : (
                                                                                                    ''
                                                                                                )}

                                                                                                <PrimaryButton
                                                                                                    onClick={
                                                                                                        selectedProduct.market_status ==
                                                                                                        'open'
                                                                                                            ? handleClick
                                                                                                            : ''
                                                                                                    }
                                                                                                    disabled={
                                                                                                        selectedProduct.market_status !=
                                                                                                        'open'
                                                                                                    }
                                                                                                >
                                                                                                    {selectedProduct.market_status ==
                                                                                                    'sold'
                                                                                                        ? t(
                                                                                                              'lot_sold',
                                                                                                          )
                                                                                                        : selectedProduct.market_status ==
                                                                                                              'unsold' ||
                                                                                                          selectedProduct.market_status ==
                                                                                                              'closed'
                                                                                                        ? t(
                                                                                                              'lot_closed',
                                                                                                          )
                                                                                                        : disabled
                                                                                                        ? t(
                                                                                                              'remove_from_cart',
                                                                                                          )
                                                                                                        : `${t(
                                                                                                              'buy_now_for',
                                                                                                          )} ${currencyFormat(
                                                                                                              parseFloat(
                                                                                                                  selectedProduct.bprice,
                                                                                                              ).toFixed(
                                                                                                                  2,
                                                                                                              ),
                                                                                                              currency,
                                                                                                          )}`}
                                                                                                </PrimaryButton>
                                                                                            </>
                                                                                        )}
                                                                                    </>
                                                                                )}
                                                                            </div>
                                                                        </>
                                                                    ) : null}
                                                                </>
                                                            ) : (
                                                                ''
                                                            )}

                                                            {!isAuthenticated &&
                                                            !checkThemeClassName ? (
                                                                <>
                                                                    {(parseInt(
                                                                        selectedProduct.buynow,
                                                                    ) == 1 &&
                                                                        parseInt(
                                                                            selectedProduct.auction,
                                                                        ) == 0) ||
                                                                    (parseInt(
                                                                        selectedProduct.wprice,
                                                                    ) <=
                                                                        parseInt(
                                                                            selectedProduct.bprice,
                                                                        ) &&
                                                                        parseInt(
                                                                            selectedProduct.buynow,
                                                                        ) == 1 &&
                                                                        parseInt(
                                                                            selectedProduct.auction,
                                                                        ) == 1) ? (
                                                                        <>
                                                                            <div className="">
                                                                                {selectedProduct?.user_id ==
                                                                                user?.id ? (
                                                                                    <p>
                                                                                        You cannot
                                                                                        buy your own
                                                                                        product!
                                                                                    </p>
                                                                                ) : (
                                                                                    <>
                                                                                        {itemDetails
                                                                                            ?.filter(
                                                                                                (
                                                                                                    i,
                                                                                                ) =>
                                                                                                    i.id ==
                                                                                                    selectedLot,
                                                                                            )
                                                                                            .map(
                                                                                                (
                                                                                                    d,
                                                                                                ) =>
                                                                                                    d.buynowpaid,
                                                                                            ) ==
                                                                                        1 ? (
                                                                                            <h5 className="itemPurchased">
                                                                                                You
                                                                                                have
                                                                                                purchased
                                                                                                this
                                                                                                item
                                                                                            </h5>
                                                                                        ) : (
                                                                                            <>
                                                                                                {global
                                                                                                    .pluginConfiguration
                                                                                                    ?.make_an_offer
                                                                                                    ?.enable &&
                                                                                                selectedProduct?.make_an_offer_io ? (
                                                                                                    <div className="row">
                                                                                                        {mapData(
                                                                                                            make_an_offer,
                                                                                                        )}
                                                                                                        <div className="col-sm-6 col-12">
                                                                                                            <PrimaryButton
                                                                                                                onClick={(
                                                                                                                    e,
                                                                                                                ) =>
                                                                                                                    selectedProduct.market_status ==
                                                                                                                    'open'
                                                                                                                        ? make_formik.handleSubmit()
                                                                                                                        : ''
                                                                                                                }
                                                                                                                disabled={
                                                                                                                    selectedProduct.market_status !=
                                                                                                                    'open'
                                                                                                                }
                                                                                                            >
                                                                                                                {selectedProduct.market_status ==
                                                                                                                'sold'
                                                                                                                    ? 'Lot Sold'
                                                                                                                    : selectedProduct.market_status ==
                                                                                                                          'unsold' ||
                                                                                                                      selectedProduct.market_status ==
                                                                                                                          'closed'
                                                                                                                    ? 'Lot Closed'
                                                                                                                    : `Submit Offer`}
                                                                                                            </PrimaryButton>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                ) : (
                                                                                                    ''
                                                                                                )}

                                                                                                <PrimaryButton
                                                                                                    onClick={
                                                                                                        selectedProduct.market_status ==
                                                                                                        'open'
                                                                                                            ? handleClick
                                                                                                            : ''
                                                                                                    }
                                                                                                    disabled={
                                                                                                        selectedProduct.market_status !=
                                                                                                        'open'
                                                                                                    }
                                                                                                >
                                                                                                    {selectedProduct.market_status ==
                                                                                                    'sold'
                                                                                                        ? t(
                                                                                                              'lot_sold',
                                                                                                          )
                                                                                                        : selectedProduct.market_status ==
                                                                                                              'unsold' ||
                                                                                                          selectedProduct.market_status ==
                                                                                                              'closed'
                                                                                                        ? t(
                                                                                                              'lot_closed',
                                                                                                          )
                                                                                                        : disabled
                                                                                                        ? t(
                                                                                                              'remove_from_cart',
                                                                                                          )
                                                                                                        : `${t(
                                                                                                              'buy_now_for',
                                                                                                          )} ${currencyFormat(
                                                                                                              parseFloat(
                                                                                                                  selectedProduct.bprice,
                                                                                                              ).toFixed(
                                                                                                                  2,
                                                                                                              ),
                                                                                                              currency,
                                                                                                          )}`}
                                                                                                </PrimaryButton>
                                                                                            </>
                                                                                        )}
                                                                                    </>
                                                                                )}
                                                                            </div>
                                                                        </>
                                                                    ) : null}
                                                                </>
                                                            ) : (
                                                                ''
                                                            )}
                                                            {!isAuthenticated &&
                                                                checkThemeClassName &&
                                                                selectedProduct.market_status !==
                                                                    'sold' && (
                                                                    <PrimaryButton
                                                                        className="my-2"
                                                                        onClick={() =>
                                                                            history.push('/login')
                                                                        }
                                                                    >
                                                                        {selectedProduct?.buynow &&
                                                                        selectedProduct?.auction
                                                                            ? 'Login to Buy Or Bid'
                                                                            : selectedProduct?.buynow
                                                                            ? 'Login to Buy'
                                                                            : t('login_to_bid')}
                                                                    </PrimaryButton>
                                                                )}
                                                            <div className="mpTop">
                                                                {!checkThemeClassName ? (
                                                                    <>
                                                                        <h3
                                                                            className={`plTitle primeTitl ${
                                                                                showDescription
                                                                                    ? ''
                                                                                    : 'hide'
                                                                            }`}
                                                                            dangerouslySetInnerHTML={{
                                                                                __html: selectedProduct.title,
                                                                            }}
                                                                        ></h3>
                                                                        <span
                                                                            className="mb-3 cursorDecoy shwMrBtn"
                                                                            onClick={() =>
                                                                                setShowDescription(
                                                                                    !showDescription,
                                                                                )
                                                                            }
                                                                        >
                                                                            <b>
                                                                                {descripText?.length >
                                                                                    43 && (
                                                                                    <u>
                                                                                        Show{' '}
                                                                                        {showDescription
                                                                                            ? 'Less'
                                                                                            : 'More'}
                                                                                    </u>
                                                                                )}
                                                                            </b>
                                                                        </span>
                                                                    </>
                                                                ) : null}
                                                            </div>
                                                            {storeTheme !== 'ArtAuction' &&
                                                                checkThemeClassName && (
                                                                    <>
                                                                        {[
                                                                            ...(global.storeDetails
                                                                                ?.theme == 5
                                                                                ? new_lotInformation
                                                                                : lotInformation),
                                                                        ].map((data, index) => (
                                                                            <>
                                                                                {data.title ==
                                                                                    'Pickup Details' &&
                                                                                global
                                                                                    .pluginConfiguration
                                                                                    ?.limit_pickup_days
                                                                                    ?.enable &&
                                                                                data.description ==
                                                                                    'Pickup' &&
                                                                                parseInt(
                                                                                    selectedProduct.loc_id,
                                                                                ) > 0 ? (
                                                                                    <>
                                                                                        {!checkThemeClassName ? (
                                                                                            <div
                                                                                                className={`mssDesc`}
                                                                                            >
                                                                                                <h6>
                                                                                                    {
                                                                                                        data.title
                                                                                                    }
                                                                                                </h6>
                                                                                                <div className="pickTimeAdjCont d-flex align-items-center">
                                                                                                    <Button
                                                                                                        onClick={(
                                                                                                            e,
                                                                                                        ) =>
                                                                                                            setOpenpickup(
                                                                                                                'open',
                                                                                                            )
                                                                                                        }
                                                                                                    >
                                                                                                        <span className="material-icons">
                                                                                                            info
                                                                                                        </span>
                                                                                                    </Button>
                                                                                                    <span>
                                                                                                        Pickup
                                                                                                        time
                                                                                                        for
                                                                                                        this
                                                                                                        item
                                                                                                    </span>
                                                                                                </div>
                                                                                            </div>
                                                                                        ) : (
                                                                                            ''
                                                                                        )}
                                                                                    </>
                                                                                ) : data.description &&
                                                                                  data.display_need ==
                                                                                      1 ? (
                                                                                    <div
                                                                                        key={index}
                                                                                        className={
                                                                                            checkThemeClassName
                                                                                                ? 'mssDesc'
                                                                                                : 'd-none'
                                                                                        }
                                                                                    >
                                                                                        <h6>
                                                                                            {
                                                                                                data.title
                                                                                            }
                                                                                        </h6>
                                                                                        {data.title ===
                                                                                        'Description' ? (
                                                                                            <div
                                                                                                className={`shwMrBtnWrp myWrapping d-flex align-items-start justify-content-between`}
                                                                                            >
                                                                                                <p
                                                                                                    className={`mb-1 pdtDscrpnVlue ${
                                                                                                        showDescriptions
                                                                                                            ? ''
                                                                                                            : 'hide'
                                                                                                    }`}
                                                                                                    dangerouslySetInnerHTML={{
                                                                                                        __html: data.description,
                                                                                                    }}
                                                                                                ></p>
                                                                                                <span
                                                                                                    className="mb-3 cursorDecoy shwMrBtn"
                                                                                                    onClick={() =>
                                                                                                        setShowDescriptions(
                                                                                                            !showDescriptions,
                                                                                                        )
                                                                                                    }
                                                                                                >
                                                                                                    <b>
                                                                                                        {descripTexts?.length >
                                                                                                            55 && (
                                                                                                            <u>
                                                                                                                Show
                                                                                                                {showDescriptions
                                                                                                                    ? 'Less'
                                                                                                                    : 'More'}
                                                                                                            </u>
                                                                                                        )}
                                                                                                    </b>
                                                                                                </span>
                                                                                            </div>
                                                                                        ) : data.title ===
                                                                                              'Notes' &&
                                                                                          checkThemeClassName ? (
                                                                                            <div
                                                                                                className={`shwMrBtnWrp myWrapping d-flex align-items-start justify-content-between`}
                                                                                            >
                                                                                                <p
                                                                                                    className={`mb-1 pdtDscrpnVlue ${
                                                                                                        showNotes
                                                                                                            ? ''
                                                                                                            : 'hide'
                                                                                                    }`}
                                                                                                    dangerouslySetInnerHTML={{
                                                                                                        __html: data.description,
                                                                                                    }}
                                                                                                ></p>
                                                                                                <span
                                                                                                    className="mb-3 cursorDecoy shwMrBtn"
                                                                                                    onClick={() =>
                                                                                                        setShowNotes(
                                                                                                            !showNotes,
                                                                                                        )
                                                                                                    }
                                                                                                >
                                                                                                    <b>
                                                                                                        {notesText?.length >
                                                                                                            55 && (
                                                                                                            <u>
                                                                                                                Show
                                                                                                                {showNotes
                                                                                                                    ? 'Less'
                                                                                                                    : 'More'}
                                                                                                            </u>
                                                                                                        )}
                                                                                                    </b>
                                                                                                </span>
                                                                                            </div>
                                                                                        ) : data.title ==
                                                                                              'Condition Report' &&
                                                                                          global
                                                                                              .storeConfigration
                                                                                              ?.hyper_micro_popup_more
                                                                                              ?.value ==
                                                                                              1 &&
                                                                                          global?.condition_data?.find(
                                                                                              (
                                                                                                  val,
                                                                                              ) =>
                                                                                                  val.condition ==
                                                                                                  data.description,
                                                                                          )
                                                                                              ?.content ? (
                                                                                            <div
                                                                                                className={`shwMrBtnWrp d-flex align-items-center justify-content-start `}
                                                                                            >
                                                                                                <>
                                                                                                    <p
                                                                                                        className="my-1 pdtDscrpnVlue mr-1"
                                                                                                        dangerouslySetInnerHTML={{
                                                                                                            __html: data.description,
                                                                                                        }}
                                                                                                    ></p>
                                                                                                    <Tooltip
                                                                                                        title={
                                                                                                            global?.condition_data?.find(
                                                                                                                (
                                                                                                                    val,
                                                                                                                ) =>
                                                                                                                    val.condition ==
                                                                                                                    data.description,
                                                                                                            )
                                                                                                                ?.content
                                                                                                        }
                                                                                                        arrow
                                                                                                        enterTouchDelay={
                                                                                                            0
                                                                                                        }
                                                                                                        placement="top-start"
                                                                                                        className="tooling"
                                                                                                    >
                                                                                                        <span>
                                                                                                            i
                                                                                                        </span>
                                                                                                    </Tooltip>
                                                                                                </>
                                                                                            </div>
                                                                                        ) : (
                                                                                            <div
                                                                                                className={`shwMrBtnWrp d-flex align-items-start justify-content-between `}
                                                                                            >
                                                                                                <p
                                                                                                    className="mb-1 pdtDscrpnVlue"
                                                                                                    dangerouslySetInnerHTML={{
                                                                                                        __html: data.description,
                                                                                                    }}
                                                                                                ></p>
                                                                                            </div>
                                                                                        )}
                                                                                    </div>
                                                                                ) : null}
                                                                            </>
                                                                        ))}

                                                                        {global.storeConfigration
                                                                            ?.show_condition
                                                                            ?.value == 1 &&
                                                                        storeTheme ===
                                                                            'Liquidation_two' &&
                                                                        !checkThemeClassName ? (
                                                                            <h6 className="galleryPrice condition">
                                                                                <span>
                                                                                    Condition:{' '}
                                                                                </span>
                                                                                <span
                                                                                    className={`prcTxtVal ${
                                                                                        selectedProduct.auctionnotes
                                                                                            ?.trim()
                                                                                            ?.toLowerCase()
                                                                                            ?.includes(
                                                                                                'new',
                                                                                            )
                                                                                            ? 'badge badge-success text-white'
                                                                                            : selectedProduct.auctionnotes
                                                                                                  ?.trim()
                                                                                                  ?.toLowerCase()
                                                                                                  ?.includes(
                                                                                                      'open box',
                                                                                                  )
                                                                                            ? 'badge badge-primary text-white'
                                                                                            : selectedProduct.auctionnotes
                                                                                                  ?.trim()
                                                                                                  ?.toLowerCase()
                                                                                                  ?.includes(
                                                                                                      'damage',
                                                                                                  )
                                                                                            ? 'badge badge-danger text-white'
                                                                                            : ''
                                                                                    }`}
                                                                                    title={
                                                                                        selectedProduct.auctionnotes
                                                                                            ? selectedProduct.auctionnotes
                                                                                            : '-'
                                                                                    }
                                                                                >
                                                                                    {selectedProduct.auctionnotes
                                                                                        ? selectedProduct.auctionnotes
                                                                                        : '-'}
                                                                                </span>
                                                                            </h6>
                                                                        ) : null}

                                                                        {(selectedProduct.auction ==
                                                                            1 &&
                                                                            selectedProduct.buynow ==
                                                                                0) ||
                                                                        selectedProduct.auction ==
                                                                            1 ? (
                                                                            <div className="mssTimer d-none">
                                                                                {selectedProduct.market_status !=
                                                                                'draft' ? (
                                                                                    (selectedProduct.market_status !=
                                                                                        'open' &&
                                                                                        selectedProduct.market_status !=
                                                                                            'future') ||
                                                                                    selectedProduct.productClosed ? (
                                                                                        ''
                                                                                    ) : selectedProduct.extend ||
                                                                                      selectedProduct.agent_id ? (
                                                                                        <span className="tmm">
                                                                                            <Timer
                                                                                                date_added={
                                                                                                    selectedProduct.date_added
                                                                                                }
                                                                                                date_closed={
                                                                                                    selectedProduct.date_closed
                                                                                                }
                                                                                                withText={
                                                                                                    1
                                                                                                }
                                                                                                endText={
                                                                                                    'Ends in' +
                                                                                                    ':'
                                                                                                }
                                                                                                startText={
                                                                                                    'Starts in' +
                                                                                                    ':'
                                                                                                }
                                                                                                // theme="multiseller"
                                                                                            ></Timer>
                                                                                        </span>
                                                                                    ) : (
                                                                                        <span>
                                                                                            <Timer
                                                                                                date_added={
                                                                                                    selectedProduct.date_added
                                                                                                }
                                                                                                date_closed={
                                                                                                    selectedProduct.date_closed
                                                                                                }
                                                                                                withText={
                                                                                                    1
                                                                                                }
                                                                                                endText={
                                                                                                    'Ends in' +
                                                                                                    ':'
                                                                                                }
                                                                                                startText={
                                                                                                    'Starts in' +
                                                                                                    ':'
                                                                                                }
                                                                                                // theme="multiseller"
                                                                                            ></Timer>
                                                                                        </span>
                                                                                    )
                                                                                ) : (
                                                                                    ''
                                                                                )}
                                                                            </div>
                                                                        ) : (
                                                                            ''
                                                                        )}
                                                                    </>
                                                                )}
                                                            {storeTheme == 'Liquidation_two' &&
                                                            checkThemeClassName ? (
                                                                <div className="mssDesc">
                                                                    {selectedProduct.custom_field_8 ||
                                                                    selectedProduct.custom_field_7 ||
                                                                    selectedProduct.custom_field_6 ? (
                                                                        <h5
                                                                            className="lotNo d-flex flex-column"
                                                                            style={{ gap: '5px' }}
                                                                        >
                                                                            Dimension
                                                                            {selectedProduct ? (
                                                                                <div
                                                                                    className="d-flex mb-3"
                                                                                    style={{
                                                                                        gap: '5px',
                                                                                    }}
                                                                                >
                                                                                    <span>
                                                                                        {
                                                                                            selectedProduct.custom_field_8
                                                                                        }
                                                                                        {
                                                                                            selectedProduct.custom_field_2
                                                                                        }
                                                                                    </span>
                                                                                    <span
                                                                                        style={{
                                                                                            textTransform:
                                                                                                'lowercase',
                                                                                        }}
                                                                                    >
                                                                                        x
                                                                                    </span>
                                                                                    <span>
                                                                                        {
                                                                                            selectedProduct.custom_field_6
                                                                                        }
                                                                                        {
                                                                                            selectedProduct.custom_field_2
                                                                                        }
                                                                                    </span>
                                                                                    <span
                                                                                        style={{
                                                                                            textTransform:
                                                                                                'lowercase',
                                                                                        }}
                                                                                    >
                                                                                        x
                                                                                    </span>
                                                                                    <span>
                                                                                        {
                                                                                            selectedProduct.custom_field_7
                                                                                        }
                                                                                        {
                                                                                            selectedProduct.custom_field_2
                                                                                        }
                                                                                    </span>
                                                                                </div>
                                                                            ) : null}
                                                                        </h5>
                                                                    ) : null}
                                                                    {ASIN.price &&
                                                                    ASIN.product_url ? (
                                                                        <h5 className="lotNo btnBgChange">
                                                                            <a
                                                                                className="rtlPrice"
                                                                                href={
                                                                                    ASIN.product_url
                                                                                }
                                                                                target="_blank"
                                                                                rel="noreferrer"
                                                                            >
                                                                                Research Product
                                                                                Online -{' '}
                                                                                {currencyFormat(
                                                                                    parseFloat(
                                                                                        ASIN.price,
                                                                                    ).toFixed(2),
                                                                                )}{' '}
                                                                            </a>
                                                                        </h5>
                                                                    ) : null}
                                                                </div>
                                                            ) : null}
                                                            {global?.storeConfigration
                                                                ?.show_bin_location?.value == 2 ? (
                                                                <h5 className="lotNo">
                                                                    Bin Location:{' '}
                                                                    <span>
                                                                        &nbsp;&nbsp;
                                                                        {selectedProduct.budget_type
                                                                            ? selectedProduct.budget_type
                                                                            : '-'}
                                                                    </span>
                                                                </h5>
                                                            ) : null}
                                                            {storeTheme == 'Liquidation_two' ? (
                                                                <div className="mssBidItem mssBidIntDet">
                                                                    <div className="mssBidMisc multisellertwo">
                                                                        <div className="mssBidInfo">
                                                                            <h5>
                                                                                <span>
                                                                                    Lot Number:
                                                                                </span>
                                                                                {selectedProduct.lot_number
                                                                                    ? selectedProduct.lot_number
                                                                                    : selectedProduct.sku
                                                                                    ? selectedProduct.sku
                                                                                    : selectedProduct.deed_document
                                                                                    ? selectedProduct.deed_document
                                                                                    : selectedProduct.id}
                                                                            </h5>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            ) : null}
                                                            <div className="mssBidItem mssBidIntDet">
                                                                <div className="mssBidMisc multisellertwo">
                                                                    {selectedProduct.qty ? (
                                                                        <div className="mssBidInfo">
                                                                            <h5>
                                                                                <span>
                                                                                    Quantity:
                                                                                </span>
                                                                                {
                                                                                    selectedProduct.qty
                                                                                }
                                                                            </h5>
                                                                        </div>
                                                                    ) : null}
                                                                    {selectedProduct.selling_unit ? (
                                                                        <div className="mssBidInfo">
                                                                            <h5>
                                                                                <span>Unit:</span>
                                                                                {
                                                                                    unitOptions.find(
                                                                                        (val) =>
                                                                                            val.id ==
                                                                                            selectedProduct.selling_unit,
                                                                                    ).description
                                                                                }
                                                                            </h5>
                                                                        </div>
                                                                    ) : null}
                                                                    {selectedProduct.total_weight ? (
                                                                        <div className="mssBidInfo">
                                                                            <h5>
                                                                                <span>
                                                                                    Total Weight:
                                                                                </span>
                                                                                {
                                                                                    selectedProduct.total_weight
                                                                                }
                                                                                {storeTheme ===
                                                                                    'foodindustry' ||
                                                                                storeTheme ===
                                                                                    'LiquidationPallet'
                                                                                    ? 'Kg'
                                                                                    : ''}
                                                                            </h5>
                                                                        </div>
                                                                    ) : null}
                                                                    {selectedProduct.location ? (
                                                                        <div className="mssBidInfo">
                                                                            <h5>
                                                                                <span>
                                                                                    Location:
                                                                                </span>
                                                                                {storeTheme ===
                                                                                    'foodindustry' ||
                                                                                storeTheme ===
                                                                                    'LiquidationPallet' ? (
                                                                                    <>
                                                                                        {
                                                                                            selectedProduct.city
                                                                                        }
                                                                                    </>
                                                                                ) : (
                                                                                    <>
                                                                                        {countryList
                                                                                            .filter(
                                                                                                (
                                                                                                    country,
                                                                                                ) =>
                                                                                                    country.location_id ==
                                                                                                    selectedProduct.location,
                                                                                            )
                                                                                            .map(
                                                                                                (
                                                                                                    data,
                                                                                                ) =>
                                                                                                    data.name,
                                                                                            )}
                                                                                    </>
                                                                                )}
                                                                            </h5>
                                                                        </div>
                                                                    ) : null}
                                                                    {checkThemeClassName
                                                                        ? ''
                                                                        : // <div className="mssBidInfo">
                                                                          //     <h5>
                                                                          //         <span>
                                                                          //             Bid History:
                                                                          //         </span>
                                                                          //         {!loading &&
                                                                          //             bidHistory.length ===
                                                                          //                 0 &&
                                                                          //             'No bids'}
                                                                          //     </h5>
                                                                          //     <>
                                                                          //         {!loading &&
                                                                          //         bidHistory.length !==
                                                                          //             0 ? (
                                                                          //             <>
                                                                          //                 <table className="table table-hover bidHistory">
                                                                          //                     <thead>
                                                                          //                         <tr>
                                                                          //                             {/* <th>
                                                                          //                             S.No
                                                                          //                         </th>
                                                                          //                         {global
                                                                          //                             .storeConfigration
                                                                          //                             ?.hide_bidder
                                                                          //                             ?.value !=
                                                                          //                             '1' && (
                                                                          //                             <th>
                                                                          //                                 {t(
                                                                          //                                     'username',
                                                                          //                                 )}
                                                                          //                             </th>
                                                                          //                         )} */}
                                                                          //                             {global
                                                                          //                                 .storeConfigration
                                                                          //                                 ?.hide_bidder_id
                                                                          //                                 ?.value !=
                                                                          //                                 '1' && (
                                                                          //                                 <th>
                                                                          //                                     {t(
                                                                          //                                         'bidderid',
                                                                          //                                     )}
                                                                          //                                 </th>
                                                                          //                             )}
                                                                          //                             <th>
                                                                          //                                 {t(
                                                                          //                                     'date',
                                                                          //                                 ) +
                                                                          //                                     ' & ' +
                                                                          //                                     t(
                                                                          //                                         'time',
                                                                          //                                     )}
                                                                          //                             </th>
                                                                          //                             <th>
                                                                          //                                 {t(
                                                                          //                                     'amount',
                                                                          //                                 )}
                                                                          //                             </th>
                                                                          //                         </tr>
                                                                          //                     </thead>
                                                                          //                     <tbody>
                                                                          //                         {(showMoreBidHistory
                                                                          //                             ? bidHistory.slice(
                                                                          //                                   0,
                                                                          //                                   10,
                                                                          //                               )
                                                                          //                             : bidHistory
                                                                          //                         ).map(
                                                                          //                             (
                                                                          //                                 item,
                                                                          //                                 index,
                                                                          //                             ) => {
                                                                          //                                 return (
                                                                          //                                     <tr
                                                                          //                                         key={
                                                                          //                                             index
                                                                          //                                         }
                                                                          //                                     >
                                                                          //                                         {/* <td
                                                                          //                                         className="clearfix responsiveView"
                                                                          //                                         data-title="S.No"
                                                                          //                                     >
                                                                          //                                         {index +
                                                                          //                                             1}
                                                                          //                                     </td>
                                                                          //                                     {global
                                                                          //                                         .storeConfigration
                                                                          //                                         ?.hide_bidder
                                                                          //                                         ?.value !=
                                                                          //                                         '1' && (
                                                                          //                                         <td
                                                                          //                                             className="clearfix responsiveView"
                                                                          //                                             data-title="username"
                                                                          //                                         >
                                                                          //                                             {global
                                                                          //                                                 .storeConfigration
                                                                          //                                                 ?.full_name_username
                                                                          //                                                 ?.value ==
                                                                          //                                             1
                                                                          //                                                 ? item.username
                                                                          //                                                 : item.namesec}
                                                                          //                                         </td>
                                                                          //                                     )} */}
                                                                          //                                         {global
                                                                          //                                             .storeConfigration
                                                                          //                                             ?.hide_bidder_id
                                                                          //                                             ?.value !=
                                                                          //                                             '1' && (
                                                                          //                                             <td
                                                                          //                                                 className="clearfix responsiveView"
                                                                          //                                                 data-title="Bidder Id"
                                                                          //                                             >
                                                                          //                                                 {item.bidder_id_vc
                                                                          //                                                     ? item.bidder_id_vc
                                                                          //                                                     : item.user_id}{' '}
                                                                          //                                                 {item.is_auctionio
                                                                          //                                                     ? '(Auction Io)'
                                                                          //                                                     : ''}
                                                                          //                                             </td>
                                                                          //                                         )}
                                                                          //                                         <td
                                                                          //                                             className="clearfix responsiveView"
                                                                          //                                             data-title="Date & Time"
                                                                          //                                         >
                                                                          //                                             {item.created_at
                                                                          //                                                 ? `${moment(
                                                                          //                                                       item.created_at,
                                                                          //                                                   ).format(
                                                                          //                                                       'MMM Do YYYY, h:mm a',
                                                                          //                                                   )} `
                                                                          //                                                 : '-'}
                                                                          //                                         </td>
                                                                          //                                         <td
                                                                          //                                             className="clearfix responsiveView nowrap"
                                                                          //                                             data-title="Amount"
                                                                          //                                         >
                                                                          //                                             {currencyFormat(
                                                                          //                                                 parseFloat(
                                                                          //                                                     item.realproposed_amount,
                                                                          //                                                 ).toFixed(
                                                                          //                                                     2,
                                                                          //                                                 ),
                                                                          //                                             )}
                                                                          //                                         </td>
                                                                          //                                     </tr>
                                                                          //                                 )
                                                                          //                             },
                                                                          //                         )}
                                                                          //                     </tbody>
                                                                          //                 </table>
                                                                          //                 {bidHistory.length >
                                                                          //                     10 && (
                                                                          //                     <span
                                                                          //                         className="mb-3 cursorDecoy shwMrBtn"
                                                                          //                         onClick={() =>
                                                                          //                             setShowMoreBidHistory(
                                                                          //                                 !showMoreBidHistory,
                                                                          //                             )
                                                                          //                         }
                                                                          //                     >
                                                                          //                         <b>
                                                                          //                             <u>
                                                                          //                                 Show
                                                                          //                                 {!showMoreBidHistory
                                                                          //                                     ? 'Less'
                                                                          //                                     : 'More'}
                                                                          //                             </u>
                                                                          //                         </b>
                                                                          //                     </span>
                                                                          //                 )}
                                                                          //             </>
                                                                          //         ) : (
                                                                          //             <></>
                                                                          //         )}
                                                                          //     </>
                                                                          // </div>
                                                                          null}
                                                                </div>
                                                                <div className="mssBidMisc">
                                                                    {selectedProduct.storage ? (
                                                                        <div className="mssBidInfo">
                                                                            <h5>
                                                                                <span>
                                                                                    Storage:
                                                                                </span>
                                                                                {
                                                                                    storageOptions.find(
                                                                                        (val) =>
                                                                                            val.id ==
                                                                                            selectedProduct.storage,
                                                                                    ).description
                                                                                }
                                                                            </h5>
                                                                        </div>
                                                                    ) : null}
                                                                    {selectedProduct.package_date ? (
                                                                        <div className="mssBidInfo">
                                                                            <h5>
                                                                                <span>
                                                                                    Package Date:
                                                                                </span>
                                                                                {
                                                                                    selectedProduct.package_date
                                                                                }
                                                                            </h5>
                                                                        </div>
                                                                    ) : null}
                                                                    {selectedProduct.conditionunique &&
                                                                    global.storeConfigration
                                                                        ?.add_extra_fillter
                                                                        ?.value != 1 ? (
                                                                        <div className="mssBidInfo">
                                                                            <h5>
                                                                                <span>
                                                                                    Condition:
                                                                                </span>
                                                                                {allCondition
                                                                                    .filter(
                                                                                        (
                                                                                            condition,
                                                                                        ) =>
                                                                                            condition.id ==
                                                                                            selectedProduct.conditionunique,
                                                                                    )
                                                                                    .map(
                                                                                        (data) =>
                                                                                            data.description,
                                                                                    )}
                                                                            </h5>
                                                                        </div>
                                                                    ) : null}

                                                                    {selectedProduct.auctionnotes &&
                                                                    global.storeConfigration
                                                                        ?.add_extra_fillter
                                                                        ?.value == 1 ? (
                                                                        <div className="mssBidInfo">
                                                                            <h5>
                                                                                <span>
                                                                                    Condition:
                                                                                </span>
                                                                                {allCondition
                                                                                    .filter(
                                                                                        (
                                                                                            condition,
                                                                                        ) =>
                                                                                            condition.id ==
                                                                                            selectedProduct.auctionnotes,
                                                                                    )
                                                                                    .map(
                                                                                        (data) =>
                                                                                            data.description,
                                                                                    )}
                                                                            </h5>
                                                                        </div>
                                                                    ) : null}

                                                                    {global.pluginConfiguration
                                                                        ?.most_viewed?.enable ==
                                                                        1 &&
                                                                    selectedProduct.viwers ? (
                                                                        <div className="mssBidInfo">
                                                                            <h5>
                                                                                <span>
                                                                                    Viewers:
                                                                                </span>
                                                                                {
                                                                                    selectedProduct.viwers
                                                                                }
                                                                            </h5>
                                                                        </div>
                                                                    ) : null}
                                                                </div>
                                                                {storeTheme === 'foodindustry' && (
                                                                    <div className="mssBidMisc">
                                                                        {selectedProduct.content_head5 ? (
                                                                            <div className="mssBidInfo">
                                                                                <h5>
                                                                                    <span>
                                                                                        Sustainability:
                                                                                    </span>
                                                                                    {sustainabilityOptions
                                                                                        .filter(
                                                                                            (
                                                                                                condition,
                                                                                            ) =>
                                                                                                selectedProduct.content_head5
                                                                                                    .split(
                                                                                                        ',',
                                                                                                    )
                                                                                                    .includes(
                                                                                                        `${condition.id}`,
                                                                                                    ),
                                                                                        )

                                                                                        .map(
                                                                                            (
                                                                                                data,
                                                                                            ) =>
                                                                                                data.description,
                                                                                        )
                                                                                        .join(', ')}
                                                                                </h5>
                                                                            </div>
                                                                        ) : null}
                                                                    </div>
                                                                )}
                                                                {storeTheme ===
                                                                    'LiquidationPallet' && (
                                                                    <>
                                                                        {selectedProduct.content_head5 &&
                                                                        brandsliqutatios
                                                                            .filter((condition) =>
                                                                                selectedProduct.content_head5
                                                                                    .split(',')
                                                                                    .includes(
                                                                                        `${condition.id}`,
                                                                                    ),
                                                                            )

                                                                            .map(
                                                                                (data) =>
                                                                                    data.description,
                                                                            )
                                                                            .join(', ') !== '' ? (
                                                                            <div className="mssBidMisc">
                                                                                <div className="mssBidInfo">
                                                                                    <h5>
                                                                                        <span>
                                                                                            Seller:
                                                                                        </span>
                                                                                        {brandsliqutatios
                                                                                            .filter(
                                                                                                (
                                                                                                    condition,
                                                                                                ) =>
                                                                                                    selectedProduct.content_head5
                                                                                                        .split(
                                                                                                            ',',
                                                                                                        )
                                                                                                        .includes(
                                                                                                            `${condition.id}`,
                                                                                                        ),
                                                                                            )

                                                                                            .map(
                                                                                                (
                                                                                                    data,
                                                                                                ) =>
                                                                                                    data.description,
                                                                                            )
                                                                                            .join(
                                                                                                ', ',
                                                                                            )}
                                                                                    </h5>
                                                                                </div>
                                                                            </div>
                                                                        ) : null}

                                                                        <div className="mssBidMisc">
                                                                            {selectedProduct.mprice ? (
                                                                                <>
                                                                                    <div className="mssBidInfo">
                                                                                        <h5>
                                                                                            <span>
                                                                                                Retail
                                                                                                Price:{' '}
                                                                                                {selectedProduct.retailer_link ? (
                                                                                                    <a
                                                                                                        rel={
                                                                                                            'noreferrer'
                                                                                                        }
                                                                                                        target="_blank"
                                                                                                        href={
                                                                                                            selectedProduct.retailer_link
                                                                                                        }
                                                                                                    >
                                                                                                        (Retailer)
                                                                                                    </a>
                                                                                                ) : (
                                                                                                    ''
                                                                                                )}
                                                                                            </span>

                                                                                            {currencyFormat(
                                                                                                parseFloat(
                                                                                                    selectedProduct.mprice,
                                                                                                ).toFixed(
                                                                                                    2,
                                                                                                ),
                                                                                            )}
                                                                                            <CurrencyConverterFormat
                                                                                                amount={
                                                                                                    selectedProduct.mprice
                                                                                                }
                                                                                            />
                                                                                        </h5>{' '}
                                                                                    </div>
                                                                                </>
                                                                            ) : null}
                                                                        </div>
                                                                    </>
                                                                )}
                                                                {global.storeConfigration
                                                                    ?.condition_field?.value ==
                                                                    1 && (
                                                                    <div className="pdtStsInfo mb-2 mx-0">
                                                                        <ul>
                                                                            {global
                                                                                .storeConfigration
                                                                                ?.condition_field
                                                                                ?.value == 1 ? (
                                                                                <li>
                                                                                    <label>
                                                                                        Condition:
                                                                                    </label>
                                                                                    <p
                                                                                        className={`m-0 ${
                                                                                            selectedProduct.condition_field?.includes(
                                                                                                'Used - Like New',
                                                                                            )
                                                                                                ? 'text-Yellow'
                                                                                                : selectedProduct.condition_field?.includes(
                                                                                                      'New',
                                                                                                  )
                                                                                                ? 'text-success'
                                                                                                : 'text-danger'
                                                                                        }`}
                                                                                    >
                                                                                        {selectedProduct.condition_field ||
                                                                                            '-'}
                                                                                    </p>
                                                                                </li>
                                                                            ) : null}

                                                                            {global
                                                                                .storeConfigration
                                                                                ?.functional_field
                                                                                ?.value == 1 ? (
                                                                                <li>
                                                                                    <label>
                                                                                        Functional:
                                                                                    </label>
                                                                                    <p
                                                                                        className={`m-0 ${
                                                                                            selectedProduct.functional_field?.includes(
                                                                                                'Yes',
                                                                                            )
                                                                                                ? 'text-success'
                                                                                                : 'text-danger'
                                                                                        }`}
                                                                                    >
                                                                                        {selectedProduct.functional_field ||
                                                                                            '-'}
                                                                                    </p>
                                                                                </li>
                                                                            ) : null}
                                                                        </ul>
                                                                        <ul>
                                                                            {global
                                                                                .storeConfigration
                                                                                ?.damaged_field
                                                                                ?.value == 1 ? (
                                                                                <li>
                                                                                    <label>
                                                                                        Damaged:
                                                                                    </label>
                                                                                    <p
                                                                                        className={`m-0 ${
                                                                                            selectedProduct.damaged_field?.includes(
                                                                                                'No',
                                                                                            )
                                                                                                ? 'text-success'
                                                                                                : 'text-danger'
                                                                                        }`}
                                                                                    >
                                                                                        {selectedProduct.damaged_field ||
                                                                                            '-'}
                                                                                    </p>
                                                                                </li>
                                                                            ) : null}

                                                                            {global
                                                                                .storeConfigration
                                                                                ?.missing_parts_field
                                                                                ?.value == 1 ? (
                                                                                <li>
                                                                                    <label>
                                                                                        Missing
                                                                                        Parts:
                                                                                    </label>
                                                                                    <p
                                                                                        className={`m-0 ${
                                                                                            selectedProduct.missing_parts_field?.includes(
                                                                                                'Yes',
                                                                                            )
                                                                                                ? 'text-danger'
                                                                                                : 'text-success'
                                                                                        }`}
                                                                                    >
                                                                                        {selectedProduct.missing_parts_field ||
                                                                                            '-'}
                                                                                    </p>
                                                                                </li>
                                                                            ) : null}
                                                                        </ul>
                                                                        <ul>
                                                                            {selectedProduct.weight ? (
                                                                                <li>
                                                                                    <label>
                                                                                        Weight:
                                                                                    </label>
                                                                                    <p
                                                                                        className={`m-0`}
                                                                                    >
                                                                                        {selectedProduct.weight ||
                                                                                            '-'}{' '}
                                                                                        &nbsp; (
                                                                                        {getunitsdetails(
                                                                                            selectedProduct.custom_field_1,
                                                                                        )}
                                                                                        )
                                                                                    </p>
                                                                                </li>
                                                                            ) : null}

                                                                            {selectedProduct.width ? (
                                                                                <li>
                                                                                    <label>
                                                                                        Width:
                                                                                    </label>
                                                                                    <p
                                                                                        className={`m-0`}
                                                                                    >
                                                                                        {selectedProduct.width ||
                                                                                            '-'}
                                                                                        &nbsp; (
                                                                                        {getdimensiondetails(
                                                                                            selectedProduct.custom_field_2,
                                                                                        )}
                                                                                        )
                                                                                    </p>
                                                                                </li>
                                                                            ) : null}
                                                                        </ul>
                                                                        <ul>
                                                                            {selectedProduct.length ? (
                                                                                <li>
                                                                                    <label>
                                                                                        Depth:
                                                                                    </label>
                                                                                    <p
                                                                                        className={`m-0`}
                                                                                    >
                                                                                        {selectedProduct.length ||
                                                                                            '-'}
                                                                                        &nbsp; (
                                                                                        {getdimensiondetails(
                                                                                            selectedProduct.custom_field_2,
                                                                                        )}
                                                                                        )
                                                                                    </p>
                                                                                </li>
                                                                            ) : null}

                                                                            {selectedProduct.height ? (
                                                                                <li>
                                                                                    <label>
                                                                                        Height:
                                                                                    </label>
                                                                                    <p
                                                                                        className={`m-0`}
                                                                                    >
                                                                                        {selectedProduct.height ||
                                                                                            '-'}
                                                                                        &nbsp; (
                                                                                        {getdimensiondetails(
                                                                                            selectedProduct.custom_field_2,
                                                                                        )}
                                                                                        )
                                                                                    </p>
                                                                                </li>
                                                                            ) : null}
                                                                        </ul>
                                                                    </div>
                                                                )}
                                                                {theme === 'charity' && (
                                                                    <>
                                                                        <div className="mssBidMisc">
                                                                            <div className="mssBidInfo">
                                                                                <h5>
                                                                                    <span>
                                                                                        Starting
                                                                                        Date:
                                                                                    </span>
                                                                                    {moment(
                                                                                        moment(
                                                                                            selectedProduct.date_added,
                                                                                        ).isValid()
                                                                                            ? selectedProduct.date_added
                                                                                            : oneYear,
                                                                                    ).format(
                                                                                        'MMM Do YYYY, h:mm:ss a',
                                                                                    )}
                                                                                </h5>
                                                                            </div>
                                                                        </div>
                                                                        <div className="mssBidMisc">
                                                                            <div className="mssBidInfo">
                                                                                <h5>
                                                                                    <span>
                                                                                        Closing
                                                                                        Date:
                                                                                    </span>
                                                                                    {moment(
                                                                                        moment(
                                                                                            selectedProduct.date_closed,
                                                                                        ).isValid()
                                                                                            ? selectedProduct.date_closed
                                                                                            : oneYear,
                                                                                    ).format(
                                                                                        'MMM Do YYYY, h:mm:ss a',
                                                                                    )}
                                                                                </h5>
                                                                            </div>
                                                                        </div>
                                                                        {type !== 'buynow' && (
                                                                            <>
                                                                                <div className="mssBidMisc">
                                                                                    <div className="mssBidInfo">
                                                                                        <span>
                                                                                            Total
                                                                                            Bids:{' '}
                                                                                        </span>
                                                                                        <a
                                                                                            className="tb-link"
                                                                                            onClick={() =>
                                                                                                selectedProduct.bids &&
                                                                                                selectedProduct.bids !==
                                                                                                    0 &&
                                                                                                !selectedProduct.is_past &&
                                                                                                setHistoryData(
                                                                                                    {
                                                                                                        product_id:
                                                                                                            selectedProduct.id,
                                                                                                        end: moment(
                                                                                                            selectedProduct.date_closed,
                                                                                                        ).isValid()
                                                                                                            ? selectedProduct.date_closed
                                                                                                            : oneYear,
                                                                                                    },
                                                                                                )
                                                                                            }
                                                                                        >
                                                                                            {selectedProduct.bids
                                                                                                ? selectedProduct.bids
                                                                                                : 0}
                                                                                        </a>
                                                                                    </div>
                                                                                </div>
                                                                            </>
                                                                        )}
                                                                    </>
                                                                )}
                                                            </div>
                                                        </>
                                                    )}
                                                    {storeTheme !== 'ArtAuction' && (
                                                        <>
                                                            {[
                                                                ...(global.storeDetails?.theme == 5
                                                                    ? new_lotInformation
                                                                    : lotInformation),
                                                            ].map((data, index) => (
                                                                <>
                                                                    {data.title ==
                                                                        'Pickup Details' &&
                                                                    global.pluginConfiguration
                                                                        ?.limit_pickup_days
                                                                        ?.enable &&
                                                                    data.description == 'Pickup' &&
                                                                    parseInt(
                                                                        selectedProduct.loc_id,
                                                                    ) > 0 ? (
                                                                        <>
                                                                            {!checkThemeClassName ? (
                                                                                <div
                                                                                    className={`mssDesc`}
                                                                                >
                                                                                    <h6>
                                                                                        {data.title}
                                                                                    </h6>
                                                                                    <div className="pickTimeAdjCont d-flex align-items-center">
                                                                                        <Button
                                                                                            onClick={(
                                                                                                e,
                                                                                            ) =>
                                                                                                setOpenpickup(
                                                                                                    'open',
                                                                                                )
                                                                                            }
                                                                                        >
                                                                                            <span className="material-icons">
                                                                                                info
                                                                                            </span>
                                                                                        </Button>
                                                                                        <span>
                                                                                            Pickup
                                                                                            time for
                                                                                            this
                                                                                            item
                                                                                        </span>
                                                                                    </div>
                                                                                </div>
                                                                            ) : (
                                                                                ''
                                                                            )}
                                                                        </>
                                                                    ) : data.description &&
                                                                      data.display_need == 1 ? (
                                                                        <div
                                                                            key={index}
                                                                            className={
                                                                                !checkThemeClassName
                                                                                    ? 'mssDesc'
                                                                                    : 'd-none'
                                                                            }
                                                                        >
                                                                            <h6>{data.title}</h6>
                                                                            {data.title ===
                                                                            'Description' ? (
                                                                                <div
                                                                                    className={`shwMrBtnWrp myWrapping d-flex align-items-start justify-content-between`}
                                                                                >
                                                                                    <p
                                                                                        className={`mb-1 pdtDscrpnVlue ${
                                                                                            showDescriptions
                                                                                                ? ''
                                                                                                : 'hide'
                                                                                        }`}
                                                                                        dangerouslySetInnerHTML={{
                                                                                            __html: data.description,
                                                                                        }}
                                                                                    ></p>
                                                                                    <span
                                                                                        className="mb-3 cursorDecoy shwMrBtn"
                                                                                        onClick={() =>
                                                                                            setShowDescriptions(
                                                                                                !showDescriptions,
                                                                                            )
                                                                                        }
                                                                                    >
                                                                                        <b>
                                                                                            {descripTexts?.length >
                                                                                                55 && (
                                                                                                <u>
                                                                                                    Show
                                                                                                    {showDescriptions
                                                                                                        ? 'Less'
                                                                                                        : 'More'}
                                                                                                </u>
                                                                                            )}
                                                                                        </b>
                                                                                    </span>
                                                                                </div>
                                                                            ) : data.title ==
                                                                                  'Condition Report' &&
                                                                              global
                                                                                  .storeConfigration
                                                                                  ?.hyper_micro_popup_more
                                                                                  ?.value == 1 &&
                                                                              global?.condition_data?.find(
                                                                                  (val) =>
                                                                                      val.condition ==
                                                                                      data.description,
                                                                              )?.content ? (
                                                                                <div
                                                                                    className={`shwMrBtnWrp d-flex align-items-center justify-content-start `}
                                                                                >
                                                                                    <>
                                                                                        <p
                                                                                            className="my-1 pdtDscrpnVlue mr-1"
                                                                                            dangerouslySetInnerHTML={{
                                                                                                __html: data.description,
                                                                                            }}
                                                                                        ></p>
                                                                                        <Tooltip
                                                                                            title={
                                                                                                global?.condition_data?.find(
                                                                                                    (
                                                                                                        val,
                                                                                                    ) =>
                                                                                                        val.condition ==
                                                                                                        data.description,
                                                                                                )
                                                                                                    ?.content
                                                                                            }
                                                                                            arrow
                                                                                            enterTouchDelay={
                                                                                                0
                                                                                            }
                                                                                            placement="top-start"
                                                                                            className="tooling"
                                                                                        >
                                                                                            <span>
                                                                                                i
                                                                                            </span>
                                                                                        </Tooltip>
                                                                                    </>
                                                                                </div>
                                                                            ) : (
                                                                                <div
                                                                                    className={`shwMrBtnWrp d-flex align-items-start justify-content-between `}
                                                                                >
                                                                                    <p
                                                                                        className="mb-1 pdtDscrpnVlue"
                                                                                        dangerouslySetInnerHTML={{
                                                                                            __html: data.description,
                                                                                        }}
                                                                                    ></p>
                                                                                </div>
                                                                            )}
                                                                        </div>
                                                                    ) : null}
                                                                </>
                                                            ))}

                                                            {global.storeConfigration
                                                                ?.show_condition?.value == 1 &&
                                                            storeTheme === 'Liquidation_two' &&
                                                            !checkThemeClassName ? (
                                                                <h6 className="galleryPrice condition">
                                                                    <span>Condition: </span>
                                                                    <span
                                                                        className={`prcTxtVal ${
                                                                            selectedProduct.auctionnotes
                                                                                ?.trim()
                                                                                ?.toLowerCase()
                                                                                ?.includes('new')
                                                                                ? 'badge badge-success text-white'
                                                                                : selectedProduct.auctionnotes
                                                                                      ?.trim()
                                                                                      ?.toLowerCase()
                                                                                      ?.includes(
                                                                                          'open box',
                                                                                      )
                                                                                ? 'badge badge-primary text-white'
                                                                                : selectedProduct.auctionnotes
                                                                                      ?.trim()
                                                                                      ?.toLowerCase()
                                                                                      ?.includes(
                                                                                          'damage',
                                                                                      )
                                                                                ? 'badge badge-danger text-white'
                                                                                : ''
                                                                        }`}
                                                                        title={
                                                                            selectedProduct.auctionnotes
                                                                                ? selectedProduct.auctionnotes
                                                                                : '-'
                                                                        }
                                                                    >
                                                                        {selectedProduct.auctionnotes
                                                                            ? selectedProduct.auctionnotes
                                                                            : '-'}
                                                                    </span>
                                                                </h6>
                                                            ) : null}

                                                            {!checkThemeClassName ? (
                                                                <>
                                                                    {(selectedProduct.auction ==
                                                                        1 &&
                                                                        selectedProduct.buynow ==
                                                                            0) ||
                                                                    selectedProduct.auction == 1 ? (
                                                                        <div className="mssTimer">
                                                                            {selectedProduct.market_status !=
                                                                            'draft' ? (
                                                                                (selectedProduct.market_status !=
                                                                                    'open' &&
                                                                                    selectedProduct.market_status !=
                                                                                        'future') ||
                                                                                selectedProduct.productClosed ? (
                                                                                    ''
                                                                                ) : selectedProduct.extend ||
                                                                                  selectedProduct.agent_id ? (
                                                                                    <span className="tmm">
                                                                                        <Timer
                                                                                            date_added={
                                                                                                selectedProduct.date_added
                                                                                            }
                                                                                            date_closed={
                                                                                                selectedProduct.date_closed
                                                                                            }
                                                                                            withText={
                                                                                                1
                                                                                            }
                                                                                            endText={
                                                                                                'Ends in' +
                                                                                                ':'
                                                                                            }
                                                                                            startText={
                                                                                                'Starts in' +
                                                                                                ':'
                                                                                            }
                                                                                            // theme="multiseller"
                                                                                        ></Timer>
                                                                                    </span>
                                                                                ) : (
                                                                                    <span>
                                                                                        <Timer
                                                                                            date_added={
                                                                                                selectedProduct.date_added
                                                                                            }
                                                                                            date_closed={
                                                                                                selectedProduct.date_closed
                                                                                            }
                                                                                            withText={
                                                                                                1
                                                                                            }
                                                                                            endText={
                                                                                                'Ends in' +
                                                                                                ':'
                                                                                            }
                                                                                            startText={
                                                                                                'Starts in' +
                                                                                                ':'
                                                                                            }
                                                                                            // theme="multiseller"
                                                                                        ></Timer>
                                                                                    </span>
                                                                                )
                                                                            ) : (
                                                                                ''
                                                                            )}
                                                                        </div>
                                                                    ) : (
                                                                        ''
                                                                    )}
                                                                </>
                                                            ) : null}
                                                        </>
                                                    )}
                                                    {global.storeConfigration?.fishing_theme
                                                        ?.value == 1 ? (
                                                        <div className="additionalDesc">
                                                            <h5>
                                                                {' '}
                                                                {
                                                                    selectedProduct.extra_heading
                                                                        ?.heading
                                                                }
                                                            </h5>
                                                            {selectedProduct.extra?.map((val) => (
                                                                <p>
                                                                    {val.heading}:{' '}
                                                                    <span>{val.value}</span>
                                                                </p>
                                                            ))}
                                                        </div>
                                                    ) : null}
                                                    {storeTheme === 'Liquidation_two' &&
                                                    !checkThemeClassName ? (
                                                        <>
                                                            {selectedProduct.retailer_link &&
                                                            selectedProduct.mprice ? (
                                                                <h6 className="galleryPrice rtlrPrice">
                                                                    <span className="pecTxtLabl">
                                                                        Retail Price:{' '}
                                                                        <a
                                                                            href={
                                                                                selectedProduct.retailer_link
                                                                            }
                                                                            target="_blank"
                                                                            rel="noreferrer"
                                                                        >
                                                                            (Retailer)
                                                                        </a>
                                                                    </span>
                                                                    &nbsp;
                                                                    <span className="prcTxtVal">
                                                                        {currencyFormat(
                                                                            parseFloat(
                                                                                selectedProduct.mprice ||
                                                                                    0,
                                                                            ).toFixed(2),
                                                                        )}
                                                                        <CurrencyConverterFormat
                                                                            amount={
                                                                                selectedProduct.mprice
                                                                            }
                                                                        />
                                                                    </span>
                                                                    {parseFloat(
                                                                        selectedProduct.mprice,
                                                                    ) >
                                                                        parseFloat(
                                                                            selectedProduct.wprice,
                                                                        ) && checkThemeClassName ? (
                                                                        <p>
                                                                            Save
                                                                            {
                                                                                <span
                                                                                    style={{
                                                                                        color: '#FCB43A',
                                                                                        fontWeight:
                                                                                            '700',
                                                                                    }}
                                                                                >
                                                                                    {' '}
                                                                                    {get_reatil_price_per(
                                                                                        selectedProduct.mprice,
                                                                                        selectedProduct.wprice,
                                                                                    )}
                                                                                    %{' '}
                                                                                </span>
                                                                            }
                                                                            off retail price.
                                                                        </p>
                                                                    ) : (
                                                                        ''
                                                                    )}
                                                                </h6>
                                                            ) : (
                                                                <h6 className="galleryPrice rtlrPrice"></h6>
                                                            )}
                                                        </>
                                                    ) : (
                                                        <></>
                                                    )}
                                                    {storeTheme === 'Liquidation_two' &&
                                                    !checkThemeClassName ? (
                                                        <div className="mssBidItem">
                                                            <div className="mssBidMisc buyNowBidMisc">
                                                                {parseInt(selectedProduct.buynow) ==
                                                                    1 &&
                                                                parseInt(selectedProduct.auction) ==
                                                                    1 ? (
                                                                    <>
                                                                        {storeTheme !==
                                                                            'ArtAuction' && (
                                                                            <div className="mssBidInfo">
                                                                                <h5>
                                                                                    <span>
                                                                                        Buy Now
                                                                                        Price
                                                                                    </span>
                                                                                    {currencyFormat(
                                                                                        parseFloat(
                                                                                            selectedProduct.bprice,
                                                                                        ).toFixed(
                                                                                            2,
                                                                                        ),
                                                                                        currency,
                                                                                    )}
                                                                                    <CurrencyConverterFormat
                                                                                        amount={
                                                                                            selectedProduct.bprice
                                                                                        }
                                                                                    />
                                                                                </h5>
                                                                            </div>
                                                                        )}
                                                                        <div className="mssBidInfo">
                                                                            {selectedProduct.latestbid ? (
                                                                                <h5>
                                                                                    <span>
                                                                                        {selectedProduct.market_status ==
                                                                                        'sold'
                                                                                            ? 'Sold Bid'
                                                                                            : t(
                                                                                                  'current_bid',
                                                                                              )}
                                                                                    </span>
                                                                                    <span className="crncyValue">
                                                                                        {currencyFormat(
                                                                                            selectedProduct.wprice,
                                                                                            currency,
                                                                                        )}
                                                                                    </span>
                                                                                    <span className="crncyValue">
                                                                                        <CurrencyConverterFormat
                                                                                            amount={
                                                                                                selectedProduct.wprice
                                                                                            }
                                                                                        />
                                                                                    </span>
                                                                                </h5>
                                                                            ) : (
                                                                                <h5>
                                                                                    {selectedProduct.market_status ==
                                                                                    'closed' ? (
                                                                                        <span>
                                                                                            Unsold
                                                                                        </span>
                                                                                    ) : (
                                                                                        <>
                                                                                            <span>
                                                                                                Start
                                                                                                Bid
                                                                                            </span>
                                                                                            <span className="crncyValue">
                                                                                                {currencyFormat(
                                                                                                    selectedProduct.sprice,
                                                                                                    currency,
                                                                                                )}
                                                                                            </span>
                                                                                            <span className="crncyValue">
                                                                                                <CurrencyConverterFormat
                                                                                                    amount={
                                                                                                        selectedProduct.sprice
                                                                                                    }
                                                                                                />
                                                                                            </span>
                                                                                        </>
                                                                                    )}
                                                                                </h5>
                                                                            )}
                                                                            {/* <h5>
                                                            <span>Minimum Bid</span>
                                                            {currencyFormat(selectedProduct.next_bid)}
                                                        </h5> */}
                                                                        </div>
                                                                    </>
                                                                ) : type === 'buynow' ||
                                                                  parseInt(
                                                                      selectedProduct.buynow,
                                                                  ) == 1 ? (
                                                                    <div className="mssBidInfo">
                                                                        <h5>
                                                                            <span>
                                                                                Buy Now Price
                                                                            </span>
                                                                            {currencyFormat(
                                                                                parseFloat(
                                                                                    selectedProduct.bprice,
                                                                                ).toFixed(2),
                                                                                currency,
                                                                            )}
                                                                            <span>
                                                                                <CurrencyConverterFormat
                                                                                    amount={
                                                                                        selectedProduct.bprice
                                                                                    }
                                                                                />
                                                                            </span>
                                                                        </h5>
                                                                    </div>
                                                                ) : (
                                                                    <div className="mssBidInfo">
                                                                        {selectedProduct.latestbid ? (
                                                                            <h5>
                                                                                <span>
                                                                                    {selectedProduct.market_status ==
                                                                                    'sold'
                                                                                        ? 'Sold Bid'
                                                                                        : t(
                                                                                              'current_bid',
                                                                                          )}
                                                                                </span>
                                                                                <span className="crncyValue mainCurrency">
                                                                                    {currencyFormat(
                                                                                        selectedProduct.wprice,
                                                                                        currency,
                                                                                    )}
                                                                                </span>
                                                                                <span className="crncyValue makemycurrency">
                                                                                    <CurrencyConverterFormat
                                                                                        amount={
                                                                                            selectedProduct.wprice
                                                                                        }
                                                                                    />
                                                                                </span>
                                                                            </h5>
                                                                        ) : (
                                                                            <h5>
                                                                                {selectedProduct.market_status ==
                                                                                'closed' ? (
                                                                                    <span>
                                                                                        Unsold
                                                                                    </span>
                                                                                ) : (
                                                                                    <>
                                                                                        <span>
                                                                                            {t(
                                                                                                'start_bid',
                                                                                            )}
                                                                                        </span>
                                                                                        <span className="crncyValue mainCurrency">
                                                                                            {currencyFormat(
                                                                                                selectedProduct.sprice,
                                                                                                currency,
                                                                                            )}
                                                                                        </span>
                                                                                        <span className="crncyValue makemycurrency">
                                                                                            <CurrencyConverterFormat
                                                                                                amount={
                                                                                                    selectedProduct.sprice
                                                                                                }
                                                                                            />
                                                                                        </span>
                                                                                    </>
                                                                                )}
                                                                            </h5>
                                                                        )}
                                                                        {/* {selectedProduct.user_id == user?.id &&
                                                                global.storeConfigration?.listing_fee
                                                                    ?.value && (
                                                                    <h5>
                                                                        <span style={{ color: 'red' }}>
                                                                            Listing Fee
                                                                        </span>
                                                                        <span
                                                                            className="crncyValue"
                                                                            style={{ color: 'red' }}
                                                                        >
                                                                            {currencyFormat(
                                                                                global.storeConfigration
                                                                                    ?.listing_fee?.value,
                                                                                currency,
                                                                            )}
                                                                        </span>
                                                                    </h5>
                                                                )} */}
                                                                        {/* <h5>
                                                            <span>Minimum Bid</span>
                                                            {currencyFormat(selectedProduct.next_bid)}
                                                        </h5> */}
                                                                    </div>
                                                                )}

                                                                {selectedProduct.market_status !=
                                                                'draft' ? (
                                                                    <>
                                                                        {selectedProduct.auction &&
                                                                        type !== 'buynow' ? (
                                                                            <>
                                                                                {global
                                                                                    ?.storeConfigration
                                                                                    ?.view_bid_history
                                                                                    ?.value ? null : (
                                                                                    <Button
                                                                                        className="mssBidHistory"
                                                                                        onClick={() =>
                                                                                            // selectedProduct.bids &&
                                                                                            // selectedProduct.bids !== 0 &&
                                                                                            // !selectedProduct.is_past &&
                                                                                            setHistoryData(
                                                                                                {
                                                                                                    product_id:
                                                                                                        selectedProduct.id,
                                                                                                    end: selectedProduct.date_closed,
                                                                                                },
                                                                                            )
                                                                                        }
                                                                                    >
                                                                                        <span className="material-icons">
                                                                                            history
                                                                                        </span>
                                                                                        {t(
                                                                                            'view_bid_history',
                                                                                        )}
                                                                                    </Button>
                                                                                )}
                                                                            </>
                                                                        ) : (
                                                                            ''
                                                                        )}
                                                                    </>
                                                                ) : (
                                                                    ''
                                                                )}
                                                            </div>
                                                            {selectedProduct.user_id == user?.id &&
                                                                global.storeConfigration
                                                                    ?.listing_fee?.value && (
                                                                    <h5>
                                                                        <span
                                                                            style={{ color: 'red' }}
                                                                        >
                                                                            Listing Fee
                                                                        </span>
                                                                        <span
                                                                            className="crncyValue"
                                                                            style={{ color: 'red' }}
                                                                        >
                                                                            {currencyFormat(
                                                                                global
                                                                                    .storeConfigration
                                                                                    ?.listing_fee
                                                                                    ?.value,
                                                                                currency,
                                                                            )}
                                                                        </span>
                                                                    </h5>
                                                                )}
                                                            {/* {selectedProduct.market_status != 'draft' &&
                                                selectedProduct.width ? ( */}
                                                            {/* // <ShippingRates */}
                                                            {/* //     width={selectedProduct.width}
                                                    //     height={selectedProduct.height}
                                                    //     length={selectedProduct.length}
                                                    //     weight={selectedProduct.weight}
                                                    //     fromzipcode={selectedProduct.zipcode}
                                                    // />
                                                // ) : null} */}

                                                            {/*Shipping Plugin Added Flow Start*/}

                                                            {parseInt(
                                                                selectedProduct.hasshipping,
                                                            ) &&
                                                            global.pluginConfiguration?.shipping
                                                                ?.enable &&
                                                            isAuthenticated &&
                                                            !parseInt(
                                                                selectedProduct.shippingcost,
                                                            ) > 0 ? (
                                                                <div className="pv-con">
                                                                    <p>
                                                                        {t('ship_update_info')}.{' '}
                                                                        {t('update')}{' '}
                                                                        <Link to="/dashboard/profile">
                                                                            {t('here')}
                                                                        </Link>
                                                                    </p>
                                                                    {shipping_amount ? (
                                                                        <>
                                                                            {/* <div className="pv-con"> */}
                                                                            <span>
                                                                                {t(
                                                                                    'shipping_amount',
                                                                                )}
                                                                            </span>

                                                                            {currencyFormat(
                                                                                parseFloat(
                                                                                    shipping_amount,
                                                                                ).toFixed(2),
                                                                            )}
                                                                            <span className="crncyValue">
                                                                                <CurrencyConverterFormat
                                                                                    amount={
                                                                                        shipping_amount
                                                                                    }
                                                                                />
                                                                            </span>
                                                                            {/* </div> */}
                                                                        </>
                                                                    ) : (
                                                                        <>
                                                                            {selectedProduct.weight !=
                                                                                0 &&
                                                                            selectedProduct.height !=
                                                                                0 &&
                                                                            selectedProduct.length !=
                                                                                0 &&
                                                                            selectedProduct.width !=
                                                                                0 ? (
                                                                                <PrimaryButton
                                                                                    label={t(
                                                                                        'calculate_shippin',
                                                                                    )}
                                                                                    btnSize="small"
                                                                                    disabled={
                                                                                        calc_loading
                                                                                    }
                                                                                    onClick={() => {
                                                                                        setCalcLoading(
                                                                                            true,
                                                                                        )
                                                                                        calculate_shipstation_amount()
                                                                                    }}
                                                                                />
                                                                            ) : (
                                                                                ''
                                                                            )}
                                                                        </>
                                                                    )}
                                                                </div>
                                                            ) : (
                                                                <>
                                                                    {parseInt(
                                                                        selectedProduct.shippingcost,
                                                                    ) > 0 ? (
                                                                        <>
                                                                            {/* <div className="pv-con"> */}
                                                                            <span>
                                                                                {t(
                                                                                    'shipping_amount',
                                                                                ) + ': '}
                                                                            </span>

                                                                            {currencyFormat(
                                                                                parseFloat(
                                                                                    selectedProduct.shippingcost,
                                                                                ).toFixed(2),
                                                                            )}
                                                                            <span className="crncyValue">
                                                                                <CurrencyConverterFormat
                                                                                    amount={
                                                                                        selectedProduct.shippingcost
                                                                                    }
                                                                                />
                                                                            </span>
                                                                            {/* </div> */}
                                                                        </>
                                                                    ) : (
                                                                        ''
                                                                    )}
                                                                </>
                                                            )}
                                                            {global.storeConfigration
                                                                ?.more_details_page?.value == 1 ? (
                                                                <button
                                                                    onClick={() =>
                                                                        setShowpopup(true)
                                                                    }
                                                                    type="button"
                                                                    className="more-details"
                                                                >
                                                                    More Details
                                                                </button>
                                                            ) : (
                                                                ''
                                                            )}

                                                            {/*Shipping Plugin Added Flow End*/}

                                                            {selectedProduct.market_status !=
                                                                'draft' &&
                                                            selectedProduct.market_status !=
                                                                'future' &&
                                                            global.storeDetails.theme != 9 ? (
                                                                <>
                                                                    {(selectedProduct.market_status !=
                                                                        'open' ||
                                                                        selectedProduct.productClosed ||
                                                                        (selectedProduct.date_closed &&
                                                                            moment(
                                                                                selectedProduct.date_closed,
                                                                            ).isValid() &&
                                                                            auctionTimer &&
                                                                            moment(
                                                                                auctionTimer,
                                                                            ).isValid() &&
                                                                            new Date(
                                                                                selectedProduct.date_closed,
                                                                            ).getTime() -
                                                                                new Date(
                                                                                    auctionTimer,
                                                                                ).getTime() <
                                                                                0)) &&
                                                                    parseInt(
                                                                        selectedProduct.buynow,
                                                                    ) == 0 ? (
                                                                        <b>Auction Closed</b>
                                                                    ) : (
                                                                        <div
                                                                            className={`sl-pbids slidView ${
                                                                                (!isAuthenticated ||
                                                                                    selectedProduct?.buynow) &&
                                                                                'notLogged'
                                                                            }`}
                                                                        >
                                                                            {isAuthenticated ? (
                                                                                <>
                                                                                    {selectedProduct.auction ? (
                                                                                        type ===
                                                                                        'buynow' ? null : (
                                                                                            <Fragment>
                                                                                                {selectedProduct?.user_id ==
                                                                                                user?.id ? (
                                                                                                    <p>
                                                                                                        {t(
                                                                                                            'do_not_bid',
                                                                                                        )}
                                                                                                    </p>
                                                                                                ) : (
                                                                                                    <>
                                                                                                        {global
                                                                                                            .storeConfigration
                                                                                                            ?.hard_bid
                                                                                                            ?.value !=
                                                                                                            '0' && (
                                                                                                            <div className="bidDet">
                                                                                                                {storeTheme !==
                                                                                                                    'ArtAuction' && (
                                                                                                                    <p className="slidViewBtnLabel">
                                                                                                                        <span className="material-icons">
                                                                                                                            info
                                                                                                                        </span>
                                                                                                                        Hard
                                                                                                                        Bid
                                                                                                                        -
                                                                                                                        Bid
                                                                                                                        on
                                                                                                                        the
                                                                                                                        current
                                                                                                                        price.
                                                                                                                    </p>
                                                                                                                )}
                                                                                                                {global
                                                                                                                    .pluginConfiguration
                                                                                                                    ?.reverse_auction
                                                                                                                    ?.enable ? (
                                                                                                                    <Biddingforwardandreverse
                                                                                                                        data={
                                                                                                                            selectedProduct
                                                                                                                        }
                                                                                                                        hard="1"
                                                                                                                    />
                                                                                                                ) : (
                                                                                                                    <>
                                                                                                                        <Bidding
                                                                                                                            data={
                                                                                                                                selectedProduct
                                                                                                                            }
                                                                                                                            hard="1"
                                                                                                                            inputSize={
                                                                                                                                storeTheme ===
                                                                                                                                'ArtAuction'
                                                                                                                                    ? 'small'
                                                                                                                                    : 'small'
                                                                                                                            }
                                                                                                                            // enableNextBid={
                                                                                                                            //     (storeTheme ===
                                                                                                                            //     'ArtAuction'
                                                                                                                            //         ? true
                                                                                                                            //         : false) ||
                                                                                                                            //     enableNextBid
                                                                                                                            // }
                                                                                                                        />
                                                                                                                        {artTheme && (
                                                                                                                            <>
                                                                                                                                <p className="bidderInfo">
                                                                                                                                    {t(
                                                                                                                                        'bid',
                                                                                                                                    )}{' '}
                                                                                                                                    $
                                                                                                                                    {selectedProduct?.next_bid
                                                                                                                                        ? selectedProduct?.next_bid
                                                                                                                                        : '-'}
                                                                                                                                    &nbsp;
                                                                                                                                    {selectedProduct?.next_bid ? (
                                                                                                                                        <CurrencyConverterFormat
                                                                                                                                            amount={
                                                                                                                                                selectedProduct?.next_bid
                                                                                                                                                    ? selectedProduct?.next_bid
                                                                                                                                                    : 0
                                                                                                                                            }
                                                                                                                                        />
                                                                                                                                    ) : (
                                                                                                                                        ''
                                                                                                                                    )}
                                                                                                                                    {t(
                                                                                                                                        'or_more',
                                                                                                                                    )}
                                                                                                                                </p>
                                                                                                                                {/* <div className="ArtpaymentOption">
                                                                                                                        <div className="Tit">
                                                                                                                            Payments
                                                                                                                            :{' '}
                                                                                                                        </div>
                                                                                                                        <div className="types">
                                                                                                                            <span>
                                                                                                                                Credit
                                                                                                                                Cards,
                                                                                                                            </span>
                                                                                                                            <span>
                                                                                                                                PayPal
                                                                                                                            </span>
                                                                                                                        </div>
                                                                                                                    </div> */}
                                                                                                                            </>
                                                                                                                        )}
                                                                                                                    </>
                                                                                                                )}
                                                                                                            </div>
                                                                                                        )}
                                                                                                        {global
                                                                                                            .storeConfigration
                                                                                                            ?.proxy_bid
                                                                                                            ?.value !=
                                                                                                            0 && (
                                                                                                            <div className="bidDet">
                                                                                                                <p className="slidViewBtnLabel">
                                                                                                                    <span className="material-icons">
                                                                                                                        info
                                                                                                                    </span>
                                                                                                                    Proxy
                                                                                                                    Bid
                                                                                                                    -
                                                                                                                    Enter
                                                                                                                    the
                                                                                                                    maximum
                                                                                                                    bid
                                                                                                                    you
                                                                                                                    would
                                                                                                                    like
                                                                                                                    to
                                                                                                                    automatically
                                                                                                                    bid
                                                                                                                    to.
                                                                                                                </p>
                                                                                                                {global
                                                                                                                    .pluginConfiguration
                                                                                                                    ?.reverse_auction
                                                                                                                    ?.enable ? (
                                                                                                                    <Biddingforwardandreverse
                                                                                                                        data={
                                                                                                                            selectedProduct
                                                                                                                        }
                                                                                                                        hard="0"
                                                                                                                        status={
                                                                                                                            1
                                                                                                                        }
                                                                                                                    />
                                                                                                                ) : (
                                                                                                                    <Bidding
                                                                                                                        data={
                                                                                                                            selectedProduct
                                                                                                                        }
                                                                                                                        hard="0"
                                                                                                                        status={
                                                                                                                            1
                                                                                                                        }
                                                                                                                    />
                                                                                                                )}
                                                                                                            </div>
                                                                                                        )}
                                                                                                    </>
                                                                                                )}
                                                                                            </Fragment>
                                                                                        )
                                                                                    ) : null}

                                                                                    {(parseInt(
                                                                                        selectedProduct.buynow,
                                                                                    ) == 1 &&
                                                                                        parseInt(
                                                                                            selectedProduct.auction,
                                                                                        ) == 0) ||
                                                                                    (parseInt(
                                                                                        selectedProduct.wprice,
                                                                                    ) <=
                                                                                        parseInt(
                                                                                            selectedProduct.bprice,
                                                                                        ) &&
                                                                                        parseInt(
                                                                                            selectedProduct.buynow,
                                                                                        ) == 1 &&
                                                                                        parseInt(
                                                                                            selectedProduct.auction,
                                                                                        ) == 1) ? (
                                                                                        <>
                                                                                            <div className="">
                                                                                                {selectedProduct?.user_id ==
                                                                                                user?.id ? (
                                                                                                    <p>
                                                                                                        You
                                                                                                        cannot
                                                                                                        buy
                                                                                                        your
                                                                                                        own
                                                                                                        product!
                                                                                                    </p>
                                                                                                ) : (
                                                                                                    <>
                                                                                                        {itemDetails
                                                                                                            ?.filter(
                                                                                                                (
                                                                                                                    i,
                                                                                                                ) =>
                                                                                                                    i.id ==
                                                                                                                    selectedLot,
                                                                                                            )
                                                                                                            .map(
                                                                                                                (
                                                                                                                    d,
                                                                                                                ) =>
                                                                                                                    d.buynowpaid,
                                                                                                            ) ==
                                                                                                        1 ? (
                                                                                                            <h5 className="itemPurchased">
                                                                                                                You
                                                                                                                have
                                                                                                                purchased
                                                                                                                this
                                                                                                                item
                                                                                                            </h5>
                                                                                                        ) : (
                                                                                                            <>
                                                                                                                {global
                                                                                                                    .pluginConfiguration
                                                                                                                    ?.make_an_offer
                                                                                                                    ?.enable &&
                                                                                                                selectedProduct?.make_an_offer_io ? (
                                                                                                                    <div className="row">
                                                                                                                        {mapData(
                                                                                                                            make_an_offer,
                                                                                                                        )}
                                                                                                                        <div className="col-sm-6 col-12">
                                                                                                                            <PrimaryButton
                                                                                                                                onClick={(
                                                                                                                                    e,
                                                                                                                                ) =>
                                                                                                                                    selectedProduct.market_status ==
                                                                                                                                    'open'
                                                                                                                                        ? make_formik.handleSubmit()
                                                                                                                                        : ''
                                                                                                                                }
                                                                                                                                disabled={
                                                                                                                                    selectedProduct.market_status !=
                                                                                                                                    'open'
                                                                                                                                }
                                                                                                                            >
                                                                                                                                {selectedProduct.market_status ==
                                                                                                                                'sold'
                                                                                                                                    ? 'Lot Sold'
                                                                                                                                    : selectedProduct.market_status ==
                                                                                                                                          'unsold' ||
                                                                                                                                      selectedProduct.market_status ==
                                                                                                                                          'closed'
                                                                                                                                    ? 'Lot Closed'
                                                                                                                                    : `Submit Offer`}
                                                                                                                            </PrimaryButton>
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                ) : (
                                                                                                                    ''
                                                                                                                )}

                                                                                                                <PrimaryButton
                                                                                                                    onClick={
                                                                                                                        selectedProduct.market_status ==
                                                                                                                        'open'
                                                                                                                            ? handleClick
                                                                                                                            : ''
                                                                                                                    }
                                                                                                                    disabled={
                                                                                                                        selectedProduct.market_status !=
                                                                                                                        'open'
                                                                                                                    }
                                                                                                                >
                                                                                                                    {selectedProduct.market_status ==
                                                                                                                    'sold'
                                                                                                                        ? t(
                                                                                                                              'lot_sold',
                                                                                                                          )
                                                                                                                        : selectedProduct.market_status ==
                                                                                                                              'unsold' ||
                                                                                                                          selectedProduct.market_status ==
                                                                                                                              'closed'
                                                                                                                        ? t(
                                                                                                                              'lot_closed',
                                                                                                                          )
                                                                                                                        : disabled
                                                                                                                        ? t(
                                                                                                                              'remove_from_cart',
                                                                                                                          )
                                                                                                                        : `${t(
                                                                                                                              'buy_now_for',
                                                                                                                          )} ${currencyFormat(
                                                                                                                              parseFloat(
                                                                                                                                  selectedProduct.bprice,
                                                                                                                              ).toFixed(
                                                                                                                                  2,
                                                                                                                              ),
                                                                                                                              currency,
                                                                                                                          )}`}
                                                                                                                </PrimaryButton>
                                                                                                            </>
                                                                                                        )}
                                                                                                    </>
                                                                                                )}
                                                                                            </div>
                                                                                            <br />
                                                                                        </>
                                                                                    ) : null}
                                                                                </>
                                                                            ) : null}
                                                                            {!isAuthenticated &&
                                                                                selectedProduct.market_status !==
                                                                                    'sold' && (
                                                                                    <PrimaryButton
                                                                                        className="my-2"
                                                                                        onClick={() =>
                                                                                            history.push(
                                                                                                '/login',
                                                                                            )
                                                                                        }
                                                                                    >
                                                                                        {selectedProduct?.buynow &&
                                                                                        selectedProduct?.auction
                                                                                            ? 'Login to Buy Or Bid'
                                                                                            : selectedProduct?.buynow
                                                                                            ? 'Login to Buy'
                                                                                            : t(
                                                                                                  'login_to_bid',
                                                                                              )}
                                                                                    </PrimaryButton>
                                                                                )}
                                                                        </div>
                                                                    )}
                                                                </>
                                                            ) : (
                                                                ''
                                                            )}

                                                            {selectedProduct.market_status !=
                                                                'draft' &&
                                                            selectedProduct.market_status !=
                                                                'future' &&
                                                            global.storeDetails.theme == 9 ? (
                                                                <>
                                                                    {(selectedProduct.market_status !=
                                                                        'open' ||
                                                                        selectedProduct.productClosed ||
                                                                        (selectedProduct.date_closed &&
                                                                            moment(
                                                                                selectedProduct.date_closed,
                                                                            ).isValid() &&
                                                                            auctionTimer &&
                                                                            moment(
                                                                                auctionTimer,
                                                                            ).isValid() &&
                                                                            new Date(
                                                                                selectedProduct.date_closed,
                                                                            ).getTime() -
                                                                                new Date(
                                                                                    auctionTimer,
                                                                                ).getTime() <
                                                                                0)) &&
                                                                    parseInt(
                                                                        selectedProduct.buynow,
                                                                    ) == 0 ? (
                                                                        <b>Auction Closed</b>
                                                                    ) : (
                                                                        <div
                                                                            className={`sl-pbids slidView ${
                                                                                (!isAuthenticated ||
                                                                                    selectedProduct?.buynow) &&
                                                                                'notLogged'
                                                                            }`}
                                                                        >
                                                                            {isAuthenticated ? (
                                                                                <>
                                                                                    {selectedProduct.auction ? (
                                                                                        type ===
                                                                                        'buynow' ? null : (
                                                                                            <Fragment>
                                                                                                {selectedProduct?.user_id ==
                                                                                                user?.id ? (
                                                                                                    <p>
                                                                                                        {t(
                                                                                                            'do_not_bid',
                                                                                                        )}
                                                                                                    </p>
                                                                                                ) : (
                                                                                                    <>
                                                                                                        {global
                                                                                                            .storeConfigration
                                                                                                            ?.hard_bid
                                                                                                            ?.value && (
                                                                                                            <div className="bidDet">
                                                                                                                {parseInt(
                                                                                                                    selectedProduct.content_head5,
                                                                                                                ) ==
                                                                                                                    0 && (
                                                                                                                    <p className="slidViewBtnLabel">
                                                                                                                        <span className="material-icons">
                                                                                                                            info
                                                                                                                        </span>
                                                                                                                        Hard
                                                                                                                        Bid
                                                                                                                        -
                                                                                                                        Bid
                                                                                                                        on
                                                                                                                        the
                                                                                                                        current
                                                                                                                        price.
                                                                                                                    </p>
                                                                                                                )}
                                                                                                                {global
                                                                                                                    .pluginConfiguration
                                                                                                                    ?.reverse_auction
                                                                                                                    ?.enable ? (
                                                                                                                    <Biddingforwardandreverse
                                                                                                                        data={
                                                                                                                            selectedProduct
                                                                                                                        }
                                                                                                                        hard="1"
                                                                                                                    />
                                                                                                                ) : (
                                                                                                                    <Bidding
                                                                                                                        data={
                                                                                                                            selectedProduct
                                                                                                                        }
                                                                                                                        hard="1"
                                                                                                                        inputSize={
                                                                                                                            storeTheme ===
                                                                                                                            'ArtAuction'
                                                                                                                                ? 'large'
                                                                                                                                : 'small'
                                                                                                                        }
                                                                                                                        enableNextBid={
                                                                                                                            storeTheme ===
                                                                                                                            'ArtAuction'
                                                                                                                                ? true
                                                                                                                                : false
                                                                                                                        }
                                                                                                                    />
                                                                                                                )}
                                                                                                            </div>
                                                                                                        )}
                                                                                                        {global
                                                                                                            .storeConfigration
                                                                                                            ?.proxy_bid
                                                                                                            ?.value !=
                                                                                                            0 &&
                                                                                                            parseInt(
                                                                                                                selectedProduct.content_head5,
                                                                                                            ) ==
                                                                                                                0 && (
                                                                                                                <div className="bidDet">
                                                                                                                    <p className="slidViewBtnLabel">
                                                                                                                        <span className="material-icons">
                                                                                                                            info
                                                                                                                        </span>
                                                                                                                        Proxy
                                                                                                                        Bid
                                                                                                                        -
                                                                                                                        Enter
                                                                                                                        the
                                                                                                                        maximum
                                                                                                                        bid
                                                                                                                        you
                                                                                                                        would
                                                                                                                        like
                                                                                                                        to
                                                                                                                        automatically
                                                                                                                        bid
                                                                                                                        to.
                                                                                                                    </p>
                                                                                                                    {global
                                                                                                                        .pluginConfiguration
                                                                                                                        ?.reverse_auction
                                                                                                                        ?.enable ? (
                                                                                                                        <Biddingforwardandreverse
                                                                                                                            data={
                                                                                                                                selectedProduct
                                                                                                                            }
                                                                                                                            hard="0"
                                                                                                                            status={
                                                                                                                                1
                                                                                                                            }
                                                                                                                        />
                                                                                                                    ) : (
                                                                                                                        <Bidding
                                                                                                                            data={
                                                                                                                                selectedProduct
                                                                                                                            }
                                                                                                                            hard="0"
                                                                                                                            status={
                                                                                                                                1
                                                                                                                            }
                                                                                                                        />
                                                                                                                    )}
                                                                                                                </div>
                                                                                                            )}
                                                                                                    </>
                                                                                                )}
                                                                                            </Fragment>
                                                                                        )
                                                                                    ) : null}

                                                                                    {storeTheme !==
                                                                                        'ArtAuction' && (
                                                                                        <>
                                                                                            {(parseInt(
                                                                                                selectedProduct.buynow,
                                                                                            ) ==
                                                                                                1 &&
                                                                                                parseInt(
                                                                                                    selectedProduct.auction,
                                                                                                ) ==
                                                                                                    0) ||
                                                                                            (parseInt(
                                                                                                selectedProduct.wprice,
                                                                                            ) <=
                                                                                                parseInt(
                                                                                                    selectedProduct.bprice,
                                                                                                ) &&
                                                                                                parseInt(
                                                                                                    selectedProduct.buynow,
                                                                                                ) ==
                                                                                                    1 &&
                                                                                                parseInt(
                                                                                                    selectedProduct.auction,
                                                                                                ) ==
                                                                                                    1) ? (
                                                                                                <>
                                                                                                    <div className="">
                                                                                                        {selectedProduct?.user_id ==
                                                                                                        user?.id ? (
                                                                                                            <p>
                                                                                                                You
                                                                                                                cannot
                                                                                                                buy
                                                                                                                your
                                                                                                                own
                                                                                                                product!
                                                                                                            </p>
                                                                                                        ) : (
                                                                                                            <>
                                                                                                                {itemDetails
                                                                                                                    ?.filter(
                                                                                                                        (
                                                                                                                            i,
                                                                                                                        ) =>
                                                                                                                            i.id ==
                                                                                                                            selectedLot,
                                                                                                                    )
                                                                                                                    .map(
                                                                                                                        (
                                                                                                                            d,
                                                                                                                        ) =>
                                                                                                                            d.buynowpaid,
                                                                                                                    ) ==
                                                                                                                1 ? (
                                                                                                                    <h5 className="itemPurchased">
                                                                                                                        You
                                                                                                                        have
                                                                                                                        purchased
                                                                                                                        this
                                                                                                                        item
                                                                                                                    </h5>
                                                                                                                ) : (
                                                                                                                    <PrimaryButton
                                                                                                                        onClick={
                                                                                                                            selectedProduct.market_status ==
                                                                                                                            'open'
                                                                                                                                ? handleClick
                                                                                                                                : ''
                                                                                                                        }
                                                                                                                        disabled={
                                                                                                                            selectedProduct.market_status !=
                                                                                                                            'open'
                                                                                                                                ? true
                                                                                                                                : disabled
                                                                                                                        }
                                                                                                                    >
                                                                                                                        {selectedProduct.market_status ==
                                                                                                                        'sold'
                                                                                                                            ? 'Lot Sold'
                                                                                                                            : selectedProduct.market_status ==
                                                                                                                                  'unsold' ||
                                                                                                                              selectedProduct.market_status ==
                                                                                                                                  'closed'
                                                                                                                            ? 'Lot Closed'
                                                                                                                            : disabled
                                                                                                                            ? 'Added to cart'
                                                                                                                            : `Buy now for ${currencyFormat(
                                                                                                                                  parseFloat(
                                                                                                                                      selectedProduct.bprice,
                                                                                                                                  ).toFixed(
                                                                                                                                      2,
                                                                                                                                  ),
                                                                                                                                  currency,
                                                                                                                              )}`}
                                                                                                                    </PrimaryButton>
                                                                                                                )}
                                                                                                            </>
                                                                                                        )}
                                                                                                    </div>
                                                                                                    {storeTheme !==
                                                                                                        'ArtAuction' && (
                                                                                                        <br />
                                                                                                    )}
                                                                                                </>
                                                                                            ) : null}
                                                                                        </>
                                                                                    )}
                                                                                </>
                                                                            ) : null}
                                                                            {!isAuthenticated && (
                                                                                <PrimaryButton
                                                                                    className="my-2"
                                                                                    onClick={() =>
                                                                                        history.push(
                                                                                            '/login',
                                                                                        )
                                                                                    }
                                                                                >
                                                                                    Login to{' '}
                                                                                    {selectedProduct?.buynow &&
                                                                                    selectedProduct?.auction
                                                                                        ? 'Buy Or Bid'
                                                                                        : selectedProduct?.buynow
                                                                                        ? 'Buy'
                                                                                        : 'Bid'}
                                                                                </PrimaryButton>
                                                                            )}
                                                                        </div>
                                                                    )}
                                                                </>
                                                            ) : (
                                                                ''
                                                            )}
                                                        </div>
                                                    ) : (
                                                        <></>
                                                    )}

                                                    {artTheme && (
                                                        <div className="followUp">
                                                            <div className="avatharInfo">
                                                                <div className="userCrd">
                                                                    <figure className="userPic m-0">
                                                                        <img
                                                                            src={
                                                                                selectedProduct.avatar
                                                                            }
                                                                            onError={(e) =>
                                                                                (e.target.src =
                                                                                    imageError())
                                                                            }
                                                                        />
                                                                    </figure>
                                                                    <div className="userIdenty">
                                                                        <h4
                                                                            className="userTit cursorDecoy"
                                                                            onClick={() =>
                                                                                history.push(
                                                                                    `/bio/${selectedProduct.user_id}`,
                                                                                )
                                                                            }
                                                                        >
                                                                            {
                                                                                selectedProduct.first_name
                                                                            }
                                                                            {
                                                                                selectedProduct.last_name
                                                                            }
                                                                        </h4>
                                                                        <p className="usrposi">
                                                                            {
                                                                                selectedProduct.company_name
                                                                            }
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                                {followornot == 0 ? (
                                                                    <PrimaryButton
                                                                        onClick={() =>
                                                                            userFollow(
                                                                                1,
                                                                                selectedProduct.user_id,
                                                                            )
                                                                        }
                                                                        className="followUp"
                                                                    >
                                                                        <span className="material-icons mr-2">
                                                                            remove
                                                                        </span>
                                                                        {t('unfollow')}
                                                                    </PrimaryButton>
                                                                ) : (
                                                                    <PrimaryButton
                                                                        onClick={() =>
                                                                            userFollow(
                                                                                0,
                                                                                selectedProduct.user_id,
                                                                            )
                                                                        }
                                                                        className="followUp"
                                                                    >
                                                                        <span className="material-icons mr-2">
                                                                            add
                                                                        </span>
                                                                        {t('follow')}
                                                                    </PrimaryButton>
                                                                )}
                                                            </div>
                                                            <div className="avatharIntractive sd">
                                                                {isAuthenticated && (
                                                                    <>
                                                                        {selectedProduct.market_status ==
                                                                        'open' ? (
                                                                            <PrimaryButton className="">
                                                                                <FavouriteCheckbox
                                                                                    watchlisted={
                                                                                        selectedProduct.wlistpop
                                                                                    }
                                                                                    data={
                                                                                        selectedProduct
                                                                                    }
                                                                                    project_id={
                                                                                        selectedProduct.id
                                                                                    }
                                                                                    from={from}
                                                                                    getAllProducts={
                                                                                        getAllProducts
                                                                                    }
                                                                                    setAddFav={
                                                                                        setFav
                                                                                    }
                                                                                    setSelectedProduct={
                                                                                        setSelectedProduct
                                                                                    }
                                                                                />

                                                                                {addfav
                                                                                    ? t(
                                                                                          'remove_from_favorites',
                                                                                      )
                                                                                    : t(
                                                                                          'add_to_favorites',
                                                                                      )}
                                                                            </PrimaryButton>
                                                                        ) : null}
                                                                    </>
                                                                )}
                                                                <SecondaryButton
                                                                    aria-describedby={id}
                                                                    onClick={SocialShareClick}
                                                                >
                                                                    <span className="material-icons mr-2">
                                                                        share
                                                                    </span>
                                                                    {t('share')}
                                                                </SecondaryButton>
                                                                <Popover
                                                                    id={popoverid}
                                                                    open={openSocialShare}
                                                                    anchorEl={openSocialShare}
                                                                    onClose={SocialShareClock}
                                                                    anchorOrigin={{
                                                                        vertical: 'bottom',
                                                                        horizontal: 'center',
                                                                    }}
                                                                    transformOrigin={{
                                                                        vertical: 'top',
                                                                        horizontal: 'center',
                                                                    }}
                                                                >
                                                                    {/* <MenuItem onClick={handleClose}>
                                                                FaceBook
                                                            </MenuItem>
                                                            <MenuItem onClick={handleClose}>
                                                                My account
                                                            </MenuItem>
                                                            <MenuItem onClick={handleClose}>
                                                                Logout
                                                            </MenuItem> */}
                                                                    <Facebook
                                                                        path={`/?product=${selectedProduct.id}`}
                                                                        title={
                                                                            selectedProduct.title
                                                                        }
                                                                    >
                                                                        Facebook
                                                                    </Facebook>
                                                                    <Whatsapp
                                                                        path={`/?product=${selectedProduct.id}`}
                                                                        title={
                                                                            selectedProduct.title
                                                                        }
                                                                    />
                                                                    {global.storeConfigration
                                                                        ?.disable_twitter_sharing
                                                                        ?.value != 1 ? (
                                                                        <Twitter
                                                                            path={`/?product=${selectedProduct.id}`}
                                                                            title={
                                                                                selectedProduct.title
                                                                            }
                                                                        />
                                                                    ) : null}
                                                                    <Email
                                                                        path={`/?product=${selectedProduct.id}`}
                                                                        subject={
                                                                            selectedProduct.title
                                                                        }
                                                                        body={
                                                                            selectedProduct.description
                                                                        }
                                                                    />
                                                                    {global.storeConfigration
                                                                        ?.disable_pinterest_sharing
                                                                        ?.value != 1 ? (
                                                                        <Pinterest
                                                                            path={`/?product=${selectedProduct.id}`}
                                                                            title={
                                                                                selectedProduct.title
                                                                            }
                                                                            media={
                                                                                `${
                                                                                    selectedProduct.content_head1 ===
                                                                                    '0'
                                                                                        ? process
                                                                                              .env
                                                                                              .REACT_APP_BASE_URL +
                                                                                          'uploads/product/'
                                                                                        : process
                                                                                              .env
                                                                                              .REACT_APP_PRODUCT_IMAGE_URL
                                                                                }` +
                                                                                selectedProduct.avatar
                                                                            }
                                                                        />
                                                                    ) : null}
                                                                    <div
                                                                        className="cpyToClipDiv"
                                                                        style={{
                                                                            cursor: 'pointer',
                                                                        }}
                                                                    >
                                                                        <Copy
                                                                            setAlert={setAlert}
                                                                            text={`/${
                                                                                storeTheme ==
                                                                                'Liquidation_two'
                                                                                    ? 'search'
                                                                                    : ''
                                                                            }?product=${
                                                                                selectedProduct.id
                                                                            }`}
                                                                        />
                                                                    </div>
                                                                </Popover>
                                                            </div>
                                                        </div>
                                                    )}
                                                    {storeTheme === 'ArtAuction' && (
                                                        <>
                                                            {artauctionthemeinfo.map(
                                                                (data, index) => (
                                                                    <>
                                                                        {data.display_need == 1 ? (
                                                                            <Accordion
                                                                                square
                                                                                expanded={
                                                                                    expanded ===
                                                                                    `panel${index}`
                                                                                }
                                                                                key={1}
                                                                                onChange={handleChange(
                                                                                    `panel${index}`,
                                                                                )}
                                                                            >
                                                                                <AccordionSummary
                                                                                    expandIcon={
                                                                                        <ExpandMoreIcon />
                                                                                    }
                                                                                    aria-controls={`panel${index}}d-content`}
                                                                                    id={`panel${index}}d-header`}
                                                                                >
                                                                                    <h6 className="accTitle">
                                                                                        {data.title}
                                                                                    </h6>
                                                                                </AccordionSummary>
                                                                                <AccordionDetails>
                                                                                    <p
                                                                                        className="dscrpnCtnrArt"
                                                                                        dangerouslySetInnerHTML={{
                                                                                            __html: data.description,
                                                                                        }}
                                                                                    ></p>
                                                                                </AccordionDetails>
                                                                            </Accordion>
                                                                        ) : (
                                                                            ''
                                                                        )}
                                                                    </>
                                                                ),
                                                            )}
                                                        </>
                                                    )}
                                                    {(storeTheme === 'foodindustry' ||
                                                        storeTheme === 'LiquidationPallet') && (
                                                        <>
                                                            <div>
                                                                <p
                                                                    className="howBidLink"
                                                                    onClick={() =>
                                                                        setHowBidPopup(true)
                                                                    }
                                                                >
                                                                    How Does Bidding Work?
                                                                </p>
                                                            </div>
                                                            <div className="d-flex justify-content-between align-items-center pvTitleContainer flex-wrap">
                                                                <div
                                                                    className={
                                                                        selectedProduct?.buynow &&
                                                                        'd-none'
                                                                    }
                                                                >
                                                                    <h5 className="lotNo">
                                                                        Lot#{' '}
                                                                        {selectedProduct.sku
                                                                            ? selectedProduct.sku
                                                                            : selectedProduct.lot_number
                                                                            ? selectedProduct.lot_number
                                                                            : selectedProduct.deed_document
                                                                            ? selectedProduct.deed_document
                                                                            : selectedProduct.id}
                                                                    </h5>
                                                                    {parseInt(
                                                                        selectedProduct.buynow,
                                                                    ) == 0 &&
                                                                    (parseInt(
                                                                        selectedProduct.lowest,
                                                                    ) > 0 ||
                                                                        parseInt(
                                                                            selectedProduct.highest,
                                                                        ) > 0) ? (
                                                                        <p>
                                                                            <span>Est:</span>
                                                                            {`${
                                                                                ' ' +
                                                                                currencyFormat(
                                                                                    selectedProduct.lowest,
                                                                                    currency,
                                                                                ) +
                                                                                ' - ' +
                                                                                currencyFormat(
                                                                                    selectedProduct.highest,
                                                                                    currency,
                                                                                )
                                                                            }`}
                                                                        </p>
                                                                    ) : (
                                                                        ''
                                                                    )}
                                                                </div>
                                                                <div className="pvMiscActions">
                                                                    {selectedProduct.market_status ==
                                                                    'open' ? (
                                                                        <FavouriteCheckbox
                                                                            watchlisted={
                                                                                selectedProduct.wlistpop
                                                                            }
                                                                            data={selectedProduct}
                                                                            project_id={
                                                                                selectedProduct.id
                                                                            }
                                                                            from={from}
                                                                            getAllProducts={
                                                                                getAllProducts
                                                                            }
                                                                            setSelectedProduct={
                                                                                setSelectedProduct
                                                                            }
                                                                        />
                                                                    ) : null}
                                                                    <Facebook
                                                                        path={`/product/${selectedProduct.id}`}
                                                                        title={
                                                                            selectedProduct.title
                                                                        }
                                                                    />
                                                                    <Whatsapp
                                                                        path={`/product${selectedProduct.id}`}
                                                                        title={
                                                                            selectedProduct.title
                                                                        }
                                                                    />
                                                                    {global.storeConfigration
                                                                        ?.disable_twitter_sharing
                                                                        ?.value != 1 ? (
                                                                        <Twitter
                                                                            path={`/product/${selectedProduct.id}`}
                                                                            title={
                                                                                selectedProduct.title
                                                                            }
                                                                        />
                                                                    ) : null}
                                                                    <Email
                                                                        path={`/product/${selectedProduct.id}`}
                                                                        subject={
                                                                            selectedProduct.title
                                                                        }
                                                                        body={
                                                                            selectedProduct.description
                                                                        }
                                                                    />
                                                                    {global.storeConfigration
                                                                        ?.disable_pinterest_sharing
                                                                        ?.value != 1 ? (
                                                                        <Pinterest
                                                                            path={`/product/${selectedProduct.id}`}
                                                                            title={
                                                                                selectedProduct.title
                                                                            }
                                                                            media={
                                                                                `${
                                                                                    selectedProduct.content_head1 ===
                                                                                    '0'
                                                                                        ? process
                                                                                              .env
                                                                                              .REACT_APP_BASE_URL +
                                                                                          'uploads/product/'
                                                                                        : process
                                                                                              .env
                                                                                              .REACT_APP_PRODUCT_IMAGE_URL
                                                                                }` +
                                                                                selectedProduct.avatar
                                                                            }
                                                                        />
                                                                    ) : null}
                                                                    <div
                                                                        className="cpyToClipDiv"
                                                                        style={{
                                                                            cursor: 'pointer',
                                                                        }}
                                                                    >
                                                                        <Copy
                                                                            setAlert={setAlert}
                                                                            text={`/productView/${selectedProduct.id}`}
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <Button
                                                                className="d-none productPageAct"
                                                                onClick={() =>
                                                                    handleRedirectInternal(
                                                                        history,
                                                                        `productView/${selectedProduct.id}`,
                                                                    )
                                                                }
                                                            >
                                                                View Full Details
                                                            </Button>
                                                        </>
                                                    )}
                                                </div>
                                            ) : (
                                                <div className="prodInfoSlider">
                                                    {isAuthenticated ? (
                                                        selectedProduct.auction ? (
                                                            type === 'buynow' ? null : (
                                                                <BidStatus
                                                                    bidTopStatus={
                                                                        selectedProduct.bidtopstatus
                                                                    }
                                                                />
                                                            )
                                                        ) : null
                                                    ) : null}
                                                    <div className="row">
                                                        <div className="col-6">
                                                            {' '}
                                                            <span className="imgGlrWrpr">
                                                                {selectedProduct.market_status ===
                                                                    'sold' && (
                                                                    <div className="soldWrapper">
                                                                        <p>SOLD</p>
                                                                    </div>
                                                                )}

                                                                <ImageGallery
                                                                    items={selectedProductImage}
                                                                    thumbnailPosition={
                                                                        storeTheme ===
                                                                        'LiquidationPallet'
                                                                            ? 'left'
                                                                            : 'bottom'
                                                                    }
                                                                    showThumbnails={
                                                                        storeTheme ===
                                                                        'foodindustry'
                                                                            ? false
                                                                            : true
                                                                    }
                                                                    showNav={true}
                                                                    showBullets={false}
                                                                    showFullscreenButton={true}
                                                                    showPlayButton={false}
                                                                    onErrorImageURL={imageError()}
                                                                    originalClass="slidImg img-fluid"
                                                                    renderItem={RenderImage1}
                                                                    ref={sliderRef1}
                                                                    renderCustomControls={
                                                                        RenderImageZoom1
                                                                    }
                                                                />
                                                            </span>
                                                        </div>
                                                        <div className="col-6">
                                                            <div className="mpTop mb-0">
                                                                <h3
                                                                    className={`plTitle primeTitl ${
                                                                        showDescription
                                                                            ? ''
                                                                            : 'hide'
                                                                    }`}
                                                                    dangerouslySetInnerHTML={{
                                                                        __html: selectedProduct.title,
                                                                    }}
                                                                ></h3>
                                                                <span
                                                                    className="mb-3 cursorDecoy shwMrBtn"
                                                                    onClick={() =>
                                                                        setShowDescription(
                                                                            !showDescription,
                                                                        )
                                                                    }
                                                                >
                                                                    <b>
                                                                        {descripText?.length >
                                                                            43 && (
                                                                            <u>
                                                                                Show{' '}
                                                                                {showDescription
                                                                                    ? 'Less'
                                                                                    : 'More'}
                                                                            </u>
                                                                        )}
                                                                    </b>
                                                                </span>
                                                            </div>

                                                            <div className="mssBidItem mt-0">
                                                                <div className="mssBidMisc buyNowBidMisc">
                                                                    {parseInt(
                                                                        selectedProduct.buynow,
                                                                    ) == 1 &&
                                                                    parseInt(
                                                                        selectedProduct.auction,
                                                                    ) == 1 ? (
                                                                        <>
                                                                            {storeTheme !==
                                                                                'ArtAuction' && (
                                                                                <div className="mssBidInfo">
                                                                                    <h5>
                                                                                        <span>
                                                                                            Buy Now
                                                                                            Price
                                                                                        </span>
                                                                                        {currencyFormat(
                                                                                            parseFloat(
                                                                                                selectedProduct.bprice,
                                                                                            ).toFixed(
                                                                                                2,
                                                                                            ),
                                                                                            currency,
                                                                                        )}
                                                                                        <CurrencyConverterFormat
                                                                                            amount={
                                                                                                selectedProduct.bprice
                                                                                            }
                                                                                        />
                                                                                    </h5>
                                                                                </div>
                                                                            )}
                                                                            <div className="mssBidInfo">
                                                                                {selectedProduct.latestbid ? (
                                                                                    <h5>
                                                                                        <span>
                                                                                            {selectedProduct.market_status ==
                                                                                            'sold'
                                                                                                ? 'Sold Bid'
                                                                                                : t(
                                                                                                      'current_bid',
                                                                                                  )}
                                                                                        </span>
                                                                                        <span className="crncyValue">
                                                                                            {currencyFormat(
                                                                                                selectedProduct.wprice,
                                                                                                currency,
                                                                                            )}
                                                                                        </span>
                                                                                        <span className="crncyValue">
                                                                                            <CurrencyConverterFormat
                                                                                                amount={
                                                                                                    selectedProduct.wprice
                                                                                                }
                                                                                            />
                                                                                        </span>
                                                                                    </h5>
                                                                                ) : (
                                                                                    <h5>
                                                                                        {selectedProduct.market_status ==
                                                                                        'closed' ? (
                                                                                            <span>
                                                                                                Unsold
                                                                                            </span>
                                                                                        ) : (
                                                                                            <>
                                                                                                <span>
                                                                                                    Start
                                                                                                    Bid
                                                                                                </span>
                                                                                                <span className="crncyValue">
                                                                                                    {currencyFormat(
                                                                                                        selectedProduct.sprice,
                                                                                                        currency,
                                                                                                    )}
                                                                                                </span>
                                                                                                <span className="crncyValue">
                                                                                                    <CurrencyConverterFormat
                                                                                                        amount={
                                                                                                            selectedProduct.sprice
                                                                                                        }
                                                                                                    />
                                                                                                </span>
                                                                                            </>
                                                                                        )}
                                                                                    </h5>
                                                                                )}
                                                                                {/* <h5>
            <span>Minimum Bid</span>
            {currencyFormat(selectedProduct.next_bid)}
        </h5> */}
                                                                            </div>
                                                                        </>
                                                                    ) : type === 'buynow' ||
                                                                      parseInt(
                                                                          selectedProduct.buynow,
                                                                      ) == 1 ? (
                                                                        <div className="mssBidInfo">
                                                                            <h5>
                                                                                <span>
                                                                                    Buy Now Price
                                                                                </span>
                                                                                {currencyFormat(
                                                                                    parseFloat(
                                                                                        selectedProduct.bprice,
                                                                                    ).toFixed(2),
                                                                                    currency,
                                                                                )}
                                                                                <span>
                                                                                    <CurrencyConverterFormat
                                                                                        amount={
                                                                                            selectedProduct.bprice
                                                                                        }
                                                                                    />
                                                                                </span>
                                                                            </h5>
                                                                        </div>
                                                                    ) : (
                                                                        <div className="mssBidInfo">
                                                                            {selectedProduct.latestbid ? (
                                                                                <div className="mssBidInfoCont d-flex justify-content-between align-items-center">
                                                                                    {selectedProduct.mprice &&
                                                                                    parseFloat(
                                                                                        selectedProduct.mprice,
                                                                                    ) >
                                                                                        parseFloat(
                                                                                            selectedProduct.wprice,
                                                                                        ) ? (
                                                                                        <span
                                                                                            style={{
                                                                                                color: '#FCB43A',
                                                                                                fontWeight:
                                                                                                    '700',
                                                                                            }}
                                                                                        >
                                                                                            -{' '}
                                                                                            {get_reatil_price_per(
                                                                                                selectedProduct.mprice,
                                                                                                selectedProduct.wprice,
                                                                                            )}
                                                                                            %{' '}
                                                                                        </span>
                                                                                    ) : null}
                                                                                    <h5>
                                                                                        <span className="crncyValue mainCurrency">
                                                                                            {currencyFormat(
                                                                                                selectedProduct.wprice,
                                                                                                currency,
                                                                                            )}
                                                                                        </span>
                                                                                        {/* <span className="crncyValue makemycurrency">
                                                                                            <CurrencyConverterFormat
                                                                                                amount={
                                                                                                    selectedProduct.wprice
                                                                                                }
                                                                                            />
                                                                                        </span> */}
                                                                                    </h5>
                                                                                    {selectedProduct.mprice ? (
                                                                                        <span className="prcTxtVal rtl-price">
                                                                                            {currencyFormat(
                                                                                                parseFloat(
                                                                                                    selectedProduct.mprice ||
                                                                                                        0,
                                                                                                ).toFixed(
                                                                                                    2,
                                                                                                ),
                                                                                            )}
                                                                                            <CurrencyConverterFormat
                                                                                                amount={
                                                                                                    selectedProduct.mprice
                                                                                                }
                                                                                            />
                                                                                        </span>
                                                                                    ) : null}
                                                                                </div>
                                                                            ) : (
                                                                                <h5>
                                                                                    {selectedProduct.market_status ==
                                                                                    'closed' ? (
                                                                                        <span>
                                                                                            Unsold
                                                                                        </span>
                                                                                    ) : (
                                                                                        <div className="mssBidInfoCont d-flex justify-content-between align-items-center">
                                                                                            {selectedProduct.mprice &&
                                                                                            parseFloat(
                                                                                                selectedProduct.mprice,
                                                                                            ) >
                                                                                                parseFloat(
                                                                                                    selectedProduct.wprice,
                                                                                                ) ? (
                                                                                                <span
                                                                                                    style={{
                                                                                                        color: '#FCB43A',
                                                                                                        fontWeight:
                                                                                                            '700',
                                                                                                    }}
                                                                                                >
                                                                                                    -{' '}
                                                                                                    {get_reatil_price_per(
                                                                                                        selectedProduct.mprice,
                                                                                                        selectedProduct.wprice,
                                                                                                    )}

                                                                                                    %{' '}
                                                                                                </span>
                                                                                            ) : null}

                                                                                            <span className="crncyValue mainCurrency">
                                                                                                {currencyFormat(
                                                                                                    selectedProduct.sprice,
                                                                                                    currency,
                                                                                                )}
                                                                                            </span>
                                                                                            {/* <span className="crncyValue makemycurrency">
                                                                                                <CurrencyConverterFormat
                                                                                                    amount={
                                                                                                        selectedProduct.sprice
                                                                                                    }
                                                                                                />
                                                                                            </span> */}
                                                                                            {selectedProduct.mprice ? (
                                                                                                <span className="prcTxtVal rtl-price">
                                                                                                    {currencyFormat(
                                                                                                        parseFloat(
                                                                                                            selectedProduct.mprice ||
                                                                                                                0,
                                                                                                        ).toFixed(
                                                                                                            2,
                                                                                                        ),
                                                                                                    )}
                                                                                                    <CurrencyConverterFormat
                                                                                                        amount={
                                                                                                            selectedProduct.mprice
                                                                                                        }
                                                                                                    />
                                                                                                </span>
                                                                                            ) : null}
                                                                                        </div>
                                                                                    )}
                                                                                </h5>
                                                                            )}
                                                                        </div>
                                                                    )}
                                                                    {checkThemeClassName ? (
                                                                        <>
                                                                            {(selectedProduct.auction ==
                                                                                1 &&
                                                                                selectedProduct.buynow ==
                                                                                    0) ||
                                                                            selectedProduct.auction ==
                                                                                1 ? (
                                                                                <div className="mssTimer">
                                                                                    {selectedProduct.market_status !=
                                                                                    'draft' ? (
                                                                                        (selectedProduct.market_status !=
                                                                                            'open' &&
                                                                                            selectedProduct.market_status !=
                                                                                                'future') ||
                                                                                        selectedProduct.productClosed ? (
                                                                                            ''
                                                                                        ) : selectedProduct.extend ||
                                                                                          selectedProduct.agent_id ? (
                                                                                            <span className="tmm">
                                                                                                <Timer
                                                                                                    date_added={
                                                                                                        selectedProduct.date_added
                                                                                                    }
                                                                                                    date_closed={
                                                                                                        selectedProduct.date_closed
                                                                                                    }
                                                                                                    withText={
                                                                                                        1
                                                                                                    }
                                                                                                    endText={
                                                                                                        'Ends in' +
                                                                                                        ':'
                                                                                                    }
                                                                                                    startText={
                                                                                                        'Starts in' +
                                                                                                        ':'
                                                                                                    }
                                                                                                    // theme="multiseller"
                                                                                                ></Timer>
                                                                                            </span>
                                                                                        ) : (
                                                                                            <span>
                                                                                                <Timer
                                                                                                    date_added={
                                                                                                        selectedProduct.date_added
                                                                                                    }
                                                                                                    date_closed={
                                                                                                        selectedProduct.date_closed
                                                                                                    }
                                                                                                    withText={
                                                                                                        1
                                                                                                    }
                                                                                                    endText={
                                                                                                        'Ends in' +
                                                                                                        ':'
                                                                                                    }
                                                                                                    startText={
                                                                                                        'Starts in' +
                                                                                                        ':'
                                                                                                    }
                                                                                                    // theme="multiseller"
                                                                                                ></Timer>
                                                                                            </span>
                                                                                        )
                                                                                    ) : (
                                                                                        ''
                                                                                    )}
                                                                                </div>
                                                                            ) : (
                                                                                ''
                                                                            )}
                                                                        </>
                                                                    ) : null}
                                                                    {selectedProduct.market_status !=
                                                                    'draft' ? (
                                                                        <>
                                                                            {selectedProduct.auction &&
                                                                            type !== 'buynow' ? (
                                                                                <>
                                                                                    {global
                                                                                        ?.storeConfigration
                                                                                        ?.view_bid_history
                                                                                        ?.value ? null : (
                                                                                        <Button
                                                                                            className="mssBidHistory"
                                                                                            onClick={() =>
                                                                                                // selectedProduct.bids &&
                                                                                                // selectedProduct.bids !== 0 &&
                                                                                                // !selectedProduct.is_past &&
                                                                                                setHistoryData(
                                                                                                    {
                                                                                                        product_id:
                                                                                                            selectedProduct.id,
                                                                                                        end: selectedProduct.date_closed,
                                                                                                    },
                                                                                                )
                                                                                            }
                                                                                        >
                                                                                            <span className="material-icons">
                                                                                                history
                                                                                            </span>
                                                                                            {t(
                                                                                                'view_bid_history',
                                                                                            )}
                                                                                        </Button>
                                                                                    )}
                                                                                </>
                                                                            ) : (
                                                                                ''
                                                                            )}
                                                                        </>
                                                                    ) : (
                                                                        ''
                                                                    )}
                                                                </div>

                                                                {selectedProduct.user_id ==
                                                                    user?.id &&
                                                                    global.storeConfigration
                                                                        ?.listing_fee?.value && (
                                                                        <h5>
                                                                            <span
                                                                                style={{
                                                                                    color: 'red',
                                                                                }}
                                                                            >
                                                                                Listing Fee
                                                                            </span>
                                                                            <span
                                                                                className="crncyValue"
                                                                                style={{
                                                                                    color: 'red',
                                                                                }}
                                                                            >
                                                                                {currencyFormat(
                                                                                    global
                                                                                        .storeConfigration
                                                                                        ?.listing_fee
                                                                                        ?.value,
                                                                                    currency,
                                                                                )}
                                                                            </span>
                                                                        </h5>
                                                                    )}

                                                                {parseInt(
                                                                    selectedProduct.hasshipping,
                                                                ) &&
                                                                global.pluginConfiguration?.shipping
                                                                    ?.enable &&
                                                                isAuthenticated &&
                                                                !parseInt(
                                                                    selectedProduct.shippingcost,
                                                                ) > 0 ? (
                                                                    <div className="pv-con">
                                                                        <p>
                                                                            {t('ship_update_info')}.{' '}
                                                                            {t('update')}{' '}
                                                                            <Link to="/dashboard/profile">
                                                                                {t('here')}
                                                                            </Link>
                                                                        </p>
                                                                        {shipping_amount ? (
                                                                            <>
                                                                                {/* <div className="pv-con"> */}
                                                                                <span>
                                                                                    {t(
                                                                                        'shipping_amount',
                                                                                    )}
                                                                                </span>

                                                                                {currencyFormat(
                                                                                    parseFloat(
                                                                                        shipping_amount,
                                                                                    ).toFixed(2),
                                                                                )}
                                                                                <span className="crncyValue">
                                                                                    <CurrencyConverterFormat
                                                                                        amount={
                                                                                            shipping_amount
                                                                                        }
                                                                                    />
                                                                                </span>
                                                                                {/* </div> */}
                                                                            </>
                                                                        ) : (
                                                                            <>
                                                                                {selectedProduct.weight !=
                                                                                    0 &&
                                                                                selectedProduct.height !=
                                                                                    0 &&
                                                                                selectedProduct.length !=
                                                                                    0 &&
                                                                                selectedProduct.width !=
                                                                                    0 ? (
                                                                                    <PrimaryButton
                                                                                        label={t(
                                                                                            'calculate_shippin',
                                                                                        )}
                                                                                        btnSize="small"
                                                                                        disabled={
                                                                                            calc_loading
                                                                                        }
                                                                                        onClick={() => {
                                                                                            setCalcLoading(
                                                                                                true,
                                                                                            )
                                                                                            calculate_shipstation_amount()
                                                                                        }}
                                                                                    />
                                                                                ) : (
                                                                                    ''
                                                                                )}
                                                                            </>
                                                                        )}
                                                                    </div>
                                                                ) : (
                                                                    <>
                                                                        {parseInt(
                                                                            selectedProduct.shippingcost,
                                                                        ) > 0 ? (
                                                                            <>
                                                                                {/* <div className="pv-con"> */}
                                                                                <span>
                                                                                    {t(
                                                                                        'shipping_amount',
                                                                                    ) + ': '}
                                                                                </span>

                                                                                {currencyFormat(
                                                                                    parseFloat(
                                                                                        selectedProduct.shippingcost,
                                                                                    ).toFixed(2),
                                                                                )}
                                                                                <span className="crncyValue">
                                                                                    <CurrencyConverterFormat
                                                                                        amount={
                                                                                            selectedProduct.shippingcost
                                                                                        }
                                                                                    />
                                                                                </span>
                                                                                {/* </div> */}
                                                                            </>
                                                                        ) : (
                                                                            ''
                                                                        )}
                                                                    </>
                                                                )}
                                                                {global.storeConfigration
                                                                    ?.more_details_page?.value ==
                                                                1 ? (
                                                                    <button
                                                                        onClick={() =>
                                                                            setShowpopup(true)
                                                                        }
                                                                        type="button"
                                                                        className="more-details"
                                                                    >
                                                                        More Details
                                                                    </button>
                                                                ) : (
                                                                    ''
                                                                )}

                                                                {/*Shipping Plugin Added Flow End*/}

                                                                {selectedProduct.market_status !=
                                                                    'draft' &&
                                                                selectedProduct.market_status !=
                                                                    'future' &&
                                                                global.storeDetails.theme != 9 ? (
                                                                    <>
                                                                        {(selectedProduct.market_status !=
                                                                            'open' ||
                                                                            selectedProduct.productClosed ||
                                                                            (selectedProduct.date_closed &&
                                                                                moment(
                                                                                    selectedProduct.date_closed,
                                                                                ).isValid() &&
                                                                                auctionTimer &&
                                                                                moment(
                                                                                    auctionTimer,
                                                                                ).isValid() &&
                                                                                new Date(
                                                                                    selectedProduct.date_closed,
                                                                                ).getTime() -
                                                                                    new Date(
                                                                                        auctionTimer,
                                                                                    ).getTime() <
                                                                                    0)) &&
                                                                        parseInt(
                                                                            selectedProduct.buynow,
                                                                        ) == 0 ? (
                                                                            <b>Auction Closed</b>
                                                                        ) : (
                                                                            <div
                                                                                className={`sl-pbids slidView ${
                                                                                    (!isAuthenticated ||
                                                                                        selectedProduct?.buynow) &&
                                                                                    'notLogged'
                                                                                }`}
                                                                            >
                                                                                {isAuthenticated ? (
                                                                                    <>
                                                                                        {selectedProduct.auction ? (
                                                                                            type ===
                                                                                            'buynow' ? null : (
                                                                                                <Fragment>
                                                                                                    {selectedProduct?.user_id ==
                                                                                                    user?.id ? (
                                                                                                        <p>
                                                                                                            {t(
                                                                                                                'do_not_bid',
                                                                                                            )}
                                                                                                        </p>
                                                                                                    ) : (
                                                                                                        <>
                                                                                                            {global
                                                                                                                .storeConfigration
                                                                                                                ?.hard_bid
                                                                                                                ?.value !=
                                                                                                                '0' && (
                                                                                                                <div className="bidDet">
                                                                                                                    {storeTheme !==
                                                                                                                        'ArtAuction' && (
                                                                                                                        <p className="slidViewBtnLabel">
                                                                                                                            <span className="material-icons">
                                                                                                                                info
                                                                                                                            </span>
                                                                                                                            Hard
                                                                                                                            Bid
                                                                                                                            -
                                                                                                                            Bid
                                                                                                                            on
                                                                                                                            the
                                                                                                                            current
                                                                                                                            price.
                                                                                                                        </p>
                                                                                                                    )}
                                                                                                                    {global
                                                                                                                        .pluginConfiguration
                                                                                                                        ?.reverse_auction
                                                                                                                        ?.enable ? (
                                                                                                                        <Biddingforwardandreverse
                                                                                                                            data={
                                                                                                                                selectedProduct
                                                                                                                            }
                                                                                                                            hard="1"
                                                                                                                        />
                                                                                                                    ) : (
                                                                                                                        <>
                                                                                                                            <Bidding
                                                                                                                                data={
                                                                                                                                    selectedProduct
                                                                                                                                }
                                                                                                                                hard="1"
                                                                                                                                inputSize={
                                                                                                                                    storeTheme ===
                                                                                                                                    'ArtAuction'
                                                                                                                                        ? 'small'
                                                                                                                                        : 'small'
                                                                                                                                }
                                                                                                                                // enableNextBid={
                                                                                                                                //     (storeTheme ===
                                                                                                                                //     'ArtAuction'
                                                                                                                                //         ? true
                                                                                                                                //         : false) ||
                                                                                                                                //     enableNextBid
                                                                                                                                // }
                                                                                                                            />
                                                                                                                            {artTheme && (
                                                                                                                                <>
                                                                                                                                    <p className="bidderInfo">
                                                                                                                                        {t(
                                                                                                                                            'bid',
                                                                                                                                        )}{' '}
                                                                                                                                        $
                                                                                                                                        {selectedProduct?.next_bid
                                                                                                                                            ? selectedProduct?.next_bid
                                                                                                                                            : '-'}
                                                                                                                                        &nbsp;
                                                                                                                                        {selectedProduct?.next_bid ? (
                                                                                                                                            <CurrencyConverterFormat
                                                                                                                                                amount={
                                                                                                                                                    selectedProduct?.next_bid
                                                                                                                                                        ? selectedProduct?.next_bid
                                                                                                                                                        : 0
                                                                                                                                                }
                                                                                                                                            />
                                                                                                                                        ) : (
                                                                                                                                            ''
                                                                                                                                        )}
                                                                                                                                        {t(
                                                                                                                                            'or_more',
                                                                                                                                        )}
                                                                                                                                    </p>
                                                                                                                                    {/* <div className="ArtpaymentOption">
                                                                        <div className="Tit">
                                                                            Payments
                                                                            :{' '}
                                                                        </div>
                                                                        <div className="types">
                                                                            <span>
                                                                                Credit
                                                                                Cards,
                                                                            </span>
                                                                            <span>
                                                                                PayPal
                                                                            </span>
                                                                        </div>
                                                                    </div> */}
                                                                                                                                </>
                                                                                                                            )}
                                                                                                                        </>
                                                                                                                    )}
                                                                                                                </div>
                                                                                                            )}
                                                                                                            {global
                                                                                                                .storeConfigration
                                                                                                                ?.proxy_bid
                                                                                                                ?.value !=
                                                                                                                0 && (
                                                                                                                <div className="bidDet">
                                                                                                                    <p className="slidViewBtnLabel">
                                                                                                                        <span className="material-icons">
                                                                                                                            info
                                                                                                                        </span>
                                                                                                                        Proxy
                                                                                                                        Bid
                                                                                                                        -
                                                                                                                        Enter
                                                                                                                        the
                                                                                                                        maximum
                                                                                                                        bid
                                                                                                                        you
                                                                                                                        would
                                                                                                                        like
                                                                                                                        to
                                                                                                                        automatically
                                                                                                                        bid
                                                                                                                        to.
                                                                                                                    </p>
                                                                                                                    {global
                                                                                                                        .pluginConfiguration
                                                                                                                        ?.reverse_auction
                                                                                                                        ?.enable ? (
                                                                                                                        <Biddingforwardandreverse
                                                                                                                            data={
                                                                                                                                selectedProduct
                                                                                                                            }
                                                                                                                            hard="0"
                                                                                                                            status={
                                                                                                                                1
                                                                                                                            }
                                                                                                                        />
                                                                                                                    ) : (
                                                                                                                        <Bidding
                                                                                                                            data={
                                                                                                                                selectedProduct
                                                                                                                            }
                                                                                                                            hard="0"
                                                                                                                            status={
                                                                                                                                1
                                                                                                                            }
                                                                                                                        />
                                                                                                                    )}
                                                                                                                </div>
                                                                                                            )}
                                                                                                        </>
                                                                                                    )}
                                                                                                </Fragment>
                                                                                            )
                                                                                        ) : null}
                                                                                        {!checkThemeClassName ? (
                                                                                            <>
                                                                                                {(parseInt(
                                                                                                    selectedProduct.buynow,
                                                                                                ) ==
                                                                                                    1 &&
                                                                                                    parseInt(
                                                                                                        selectedProduct.auction,
                                                                                                    ) ==
                                                                                                        0) ||
                                                                                                (parseInt(
                                                                                                    selectedProduct.wprice,
                                                                                                ) <=
                                                                                                    parseInt(
                                                                                                        selectedProduct.bprice,
                                                                                                    ) &&
                                                                                                    parseInt(
                                                                                                        selectedProduct.buynow,
                                                                                                    ) ==
                                                                                                        1 &&
                                                                                                    parseInt(
                                                                                                        selectedProduct.auction,
                                                                                                    ) ==
                                                                                                        1) ? (
                                                                                                    <>
                                                                                                        <div className="">
                                                                                                            {selectedProduct?.user_id ==
                                                                                                            user?.id ? (
                                                                                                                <p>
                                                                                                                    You
                                                                                                                    cannot
                                                                                                                    buy
                                                                                                                    your
                                                                                                                    own
                                                                                                                    product!
                                                                                                                </p>
                                                                                                            ) : (
                                                                                                                <>
                                                                                                                    {itemDetails
                                                                                                                        ?.filter(
                                                                                                                            (
                                                                                                                                i,
                                                                                                                            ) =>
                                                                                                                                i.id ==
                                                                                                                                selectedLot,
                                                                                                                        )
                                                                                                                        .map(
                                                                                                                            (
                                                                                                                                d,
                                                                                                                            ) =>
                                                                                                                                d.buynowpaid,
                                                                                                                        ) ==
                                                                                                                    1 ? (
                                                                                                                        <h5 className="itemPurchased">
                                                                                                                            You
                                                                                                                            have
                                                                                                                            purchased
                                                                                                                            this
                                                                                                                            item
                                                                                                                        </h5>
                                                                                                                    ) : (
                                                                                                                        <>
                                                                                                                            {global
                                                                                                                                .pluginConfiguration
                                                                                                                                ?.make_an_offer
                                                                                                                                ?.enable &&
                                                                                                                            selectedProduct?.make_an_offer_io ? (
                                                                                                                                <div className="row">
                                                                                                                                    {mapData(
                                                                                                                                        make_an_offer,
                                                                                                                                    )}
                                                                                                                                    <div className="col-sm-6 col-12">
                                                                                                                                        <PrimaryButton
                                                                                                                                            onClick={(
                                                                                                                                                e,
                                                                                                                                            ) =>
                                                                                                                                                selectedProduct.market_status ==
                                                                                                                                                'open'
                                                                                                                                                    ? make_formik.handleSubmit()
                                                                                                                                                    : ''
                                                                                                                                            }
                                                                                                                                            disabled={
                                                                                                                                                selectedProduct.market_status !=
                                                                                                                                                'open'
                                                                                                                                            }
                                                                                                                                        >
                                                                                                                                            {selectedProduct.market_status ==
                                                                                                                                            'sold'
                                                                                                                                                ? 'Lot Sold'
                                                                                                                                                : selectedProduct.market_status ==
                                                                                                                                                      'unsold' ||
                                                                                                                                                  selectedProduct.market_status ==
                                                                                                                                                      'closed'
                                                                                                                                                ? 'Lot Closed'
                                                                                                                                                : `Submit Offer`}
                                                                                                                                        </PrimaryButton>
                                                                                                                                    </div>
                                                                                                                                </div>
                                                                                                                            ) : (
                                                                                                                                ''
                                                                                                                            )}

                                                                                                                            <PrimaryButton
                                                                                                                                onClick={
                                                                                                                                    selectedProduct.market_status ==
                                                                                                                                    'open'
                                                                                                                                        ? handleClick
                                                                                                                                        : ''
                                                                                                                                }
                                                                                                                                disabled={
                                                                                                                                    selectedProduct.market_status !=
                                                                                                                                    'open'
                                                                                                                                }
                                                                                                                            >
                                                                                                                                {selectedProduct.market_status ==
                                                                                                                                'sold'
                                                                                                                                    ? t(
                                                                                                                                          'lot_sold',
                                                                                                                                      )
                                                                                                                                    : selectedProduct.market_status ==
                                                                                                                                          'unsold' ||
                                                                                                                                      selectedProduct.market_status ==
                                                                                                                                          'closed'
                                                                                                                                    ? t(
                                                                                                                                          'lot_closed',
                                                                                                                                      )
                                                                                                                                    : disabled
                                                                                                                                    ? t(
                                                                                                                                          'remove_from_cart',
                                                                                                                                      )
                                                                                                                                    : `${t(
                                                                                                                                          'buy_now_for',
                                                                                                                                      )} ${currencyFormat(
                                                                                                                                          parseFloat(
                                                                                                                                              selectedProduct.bprice,
                                                                                                                                          ).toFixed(
                                                                                                                                              2,
                                                                                                                                          ),
                                                                                                                                          currency,
                                                                                                                                      )}`}
                                                                                                                            </PrimaryButton>
                                                                                                                        </>
                                                                                                                    )}
                                                                                                                </>
                                                                                                            )}
                                                                                                        </div>
                                                                                                        <br />
                                                                                                    </>
                                                                                                ) : null}
                                                                                            </>
                                                                                        ) : null}
                                                                                    </>
                                                                                ) : (
                                                                                    ''
                                                                                )}
                                                                                {!isAuthenticated &&
                                                                                    !checkThemeClassName &&
                                                                                    selectedProduct.market_status !==
                                                                                        'sold' && (
                                                                                        <PrimaryButton
                                                                                            className="my-2"
                                                                                            onClick={() =>
                                                                                                history.push(
                                                                                                    '/login',
                                                                                                )
                                                                                            }
                                                                                        >
                                                                                            {selectedProduct?.buynow &&
                                                                                            selectedProduct?.auction
                                                                                                ? 'Login to Buy Or Bid'
                                                                                                : selectedProduct?.buynow
                                                                                                ? 'Login to Buy'
                                                                                                : t(
                                                                                                      'login_to_bid',
                                                                                                  )}
                                                                                        </PrimaryButton>
                                                                                    )}
                                                                                <>
                                                                                    {storeTheme !==
                                                                                        'ArtAuction' && (
                                                                                        <>
                                                                                            {[
                                                                                                ...(global
                                                                                                    .storeDetails
                                                                                                    ?.theme ==
                                                                                                5
                                                                                                    ? new_lotInformation
                                                                                                    : lotInformation),
                                                                                            ].map(
                                                                                                (
                                                                                                    data,
                                                                                                    index,
                                                                                                ) => (
                                                                                                    <>
                                                                                                        {data.title ==
                                                                                                            'Pickup Details' &&
                                                                                                        global
                                                                                                            .pluginConfiguration
                                                                                                            ?.limit_pickup_days
                                                                                                            ?.enable &&
                                                                                                        data.description ==
                                                                                                            'Pickup' &&
                                                                                                        parseInt(
                                                                                                            selectedProduct.loc_id,
                                                                                                        ) >
                                                                                                            0 ? (
                                                                                                            <>
                                                                                                                {checkThemeClassName ? (
                                                                                                                    <div
                                                                                                                        className={`mssDesc`}
                                                                                                                    >
                                                                                                                        <h6>
                                                                                                                            {
                                                                                                                                data.title
                                                                                                                            }
                                                                                                                        </h6>
                                                                                                                        <div className="pickTimeAdjCont d-flex align-items-center">
                                                                                                                            <Button
                                                                                                                                onClick={(
                                                                                                                                    e,
                                                                                                                                ) =>
                                                                                                                                    setOpenpickup(
                                                                                                                                        'open',
                                                                                                                                    )
                                                                                                                                }
                                                                                                                            >
                                                                                                                                <span className="material-icons">
                                                                                                                                    info
                                                                                                                                </span>
                                                                                                                            </Button>
                                                                                                                            <span>
                                                                                                                                Pickup
                                                                                                                                time
                                                                                                                                for
                                                                                                                                this
                                                                                                                                item
                                                                                                                            </span>
                                                                                                                        </div>
                                                                                                                        {global
                                                                                                                            .storeConfigration
                                                                                                                            ?.show_condition
                                                                                                                            ?.value ==
                                                                                                                            1 &&
                                                                                                                        storeTheme ===
                                                                                                                            'Liquidation_two' ? (
                                                                                                                            <h6 className="galleryPrice condition">
                                                                                                                                <span>
                                                                                                                                    Condition:{' '}
                                                                                                                                </span>
                                                                                                                                <span
                                                                                                                                    className={`prcTxtVal ${
                                                                                                                                        selectedProduct.auctionnotes
                                                                                                                                            ?.trim()
                                                                                                                                            ?.toLowerCase()
                                                                                                                                            ?.includes(
                                                                                                                                                'new',
                                                                                                                                            )
                                                                                                                                            ? 'badge badge-success text-white'
                                                                                                                                            : selectedProduct.auctionnotes
                                                                                                                                                  ?.trim()
                                                                                                                                                  ?.toLowerCase()
                                                                                                                                                  ?.includes(
                                                                                                                                                      'open box',
                                                                                                                                                  )
                                                                                                                                            ? 'badge badge-primary text-white'
                                                                                                                                            : selectedProduct.auctionnotes
                                                                                                                                                  ?.trim()
                                                                                                                                                  ?.toLowerCase()
                                                                                                                                                  ?.includes(
                                                                                                                                                      'damage',
                                                                                                                                                  )
                                                                                                                                            ? 'badge badge-danger text-white'
                                                                                                                                            : ''
                                                                                                                                    }`}
                                                                                                                                    title={
                                                                                                                                        selectedProduct.auctionnotes
                                                                                                                                            ? selectedProduct.auctionnotes
                                                                                                                                            : '-'
                                                                                                                                    }
                                                                                                                                >
                                                                                                                                    {selectedProduct.auctionnotes
                                                                                                                                        ? selectedProduct.auctionnotes
                                                                                                                                        : '-'}
                                                                                                                                </span>
                                                                                                                            </h6>
                                                                                                                        ) : null}
                                                                                                                    </div>
                                                                                                                ) : (
                                                                                                                    ''
                                                                                                                )}
                                                                                                            </>
                                                                                                        ) : data.description &&
                                                                                                          !checkThemeClassName &&
                                                                                                          data.display_need ==
                                                                                                              1 ? (
                                                                                                            <div
                                                                                                                key={
                                                                                                                    index
                                                                                                                }
                                                                                                                className={`mssDesc`}
                                                                                                            >
                                                                                                                <h6>
                                                                                                                    {
                                                                                                                        data.title
                                                                                                                    }
                                                                                                                </h6>
                                                                                                                {data.title ===
                                                                                                                'Description' ? (
                                                                                                                    <div
                                                                                                                        className={`shwMrBtnWrp myWrapping d-flex align-items-start justify-content-between`}
                                                                                                                    >
                                                                                                                        <p
                                                                                                                            className={`mb-1 pdtDscrpnVlue ${
                                                                                                                                showDescriptions
                                                                                                                                    ? ''
                                                                                                                                    : 'hide'
                                                                                                                            }`}
                                                                                                                            dangerouslySetInnerHTML={{
                                                                                                                                __html: data.description,
                                                                                                                            }}
                                                                                                                        ></p>
                                                                                                                        <span
                                                                                                                            className="mb-3 cursorDecoy shwMrBtn"
                                                                                                                            onClick={() =>
                                                                                                                                setShowDescriptions(
                                                                                                                                    !showDescriptions,
                                                                                                                                )
                                                                                                                            }
                                                                                                                        >
                                                                                                                            <b>
                                                                                                                                {descripTexts?.length >
                                                                                                                                    55 && (
                                                                                                                                    <u>
                                                                                                                                        Show
                                                                                                                                        {showDescriptions
                                                                                                                                            ? 'Less'
                                                                                                                                            : 'More'}
                                                                                                                                    </u>
                                                                                                                                )}
                                                                                                                            </b>
                                                                                                                        </span>
                                                                                                                    </div>
                                                                                                                ) : data.title ==
                                                                                                                      'Condition Report' &&
                                                                                                                  global
                                                                                                                      .storeConfigration
                                                                                                                      ?.hyper_micro_popup_more
                                                                                                                      ?.value ==
                                                                                                                      1 &&
                                                                                                                  global?.condition_data?.find(
                                                                                                                      (
                                                                                                                          val,
                                                                                                                      ) =>
                                                                                                                          val.condition ==
                                                                                                                          data.description,
                                                                                                                  )
                                                                                                                      ?.content ? (
                                                                                                                    <div
                                                                                                                        className={`shwMrBtnWrp d-flex align-items-center justify-content-start `}
                                                                                                                    >
                                                                                                                        <>
                                                                                                                            <p
                                                                                                                                className="my-1 pdtDscrpnVlue mr-1"
                                                                                                                                dangerouslySetInnerHTML={{
                                                                                                                                    __html: data.description,
                                                                                                                                }}
                                                                                                                            ></p>
                                                                                                                            <Tooltip
                                                                                                                                title={
                                                                                                                                    global?.condition_data?.find(
                                                                                                                                        (
                                                                                                                                            val,
                                                                                                                                        ) =>
                                                                                                                                            val.condition ==
                                                                                                                                            data.description,
                                                                                                                                    )
                                                                                                                                        ?.content
                                                                                                                                }
                                                                                                                                arrow
                                                                                                                                enterTouchDelay={
                                                                                                                                    0
                                                                                                                                }
                                                                                                                                placement="top-start"
                                                                                                                                className="tooling"
                                                                                                                            >
                                                                                                                                <span>
                                                                                                                                    i
                                                                                                                                </span>
                                                                                                                            </Tooltip>
                                                                                                                        </>
                                                                                                                    </div>
                                                                                                                ) : (
                                                                                                                    <div
                                                                                                                        className={`shwMrBtnWrp d-flex align-items-start justify-content-between `}
                                                                                                                    >
                                                                                                                        <p
                                                                                                                            className="mb-1 pdtDscrpnVlue"
                                                                                                                            dangerouslySetInnerHTML={{
                                                                                                                                __html: data.description,
                                                                                                                            }}
                                                                                                                        ></p>
                                                                                                                    </div>
                                                                                                                )}
                                                                                                            </div>
                                                                                                        ) : null}
                                                                                                    </>
                                                                                                ),
                                                                                            )}

                                                                                            {!checkThemeClassName ? (
                                                                                                <>
                                                                                                    {' '}
                                                                                                    {(selectedProduct.auction ==
                                                                                                        1 &&
                                                                                                        selectedProduct.buynow ==
                                                                                                            0) ||
                                                                                                    selectedProduct.auction ==
                                                                                                        1 ? (
                                                                                                        <div className="mssTimer">
                                                                                                            {selectedProduct.market_status !=
                                                                                                            'draft' ? (
                                                                                                                (selectedProduct.market_status !=
                                                                                                                    'open' &&
                                                                                                                    selectedProduct.market_status !=
                                                                                                                        'future') ||
                                                                                                                selectedProduct.productClosed ? (
                                                                                                                    ''
                                                                                                                ) : selectedProduct.extend ||
                                                                                                                  selectedProduct.agent_id ? (
                                                                                                                    <span className="tmm">
                                                                                                                        <Timer
                                                                                                                            date_added={
                                                                                                                                selectedProduct.date_added
                                                                                                                            }
                                                                                                                            date_closed={
                                                                                                                                selectedProduct.date_closed
                                                                                                                            }
                                                                                                                            withText={
                                                                                                                                1
                                                                                                                            }
                                                                                                                            endText={
                                                                                                                                'Ends in' +
                                                                                                                                ':'
                                                                                                                            }
                                                                                                                            startText={
                                                                                                                                'Starts in' +
                                                                                                                                ':'
                                                                                                                            }
                                                                                                                            // theme="multiseller"
                                                                                                                        ></Timer>
                                                                                                                    </span>
                                                                                                                ) : (
                                                                                                                    <span>
                                                                                                                        <Timer
                                                                                                                            date_added={
                                                                                                                                selectedProduct.date_added
                                                                                                                            }
                                                                                                                            date_closed={
                                                                                                                                selectedProduct.date_closed
                                                                                                                            }
                                                                                                                            withText={
                                                                                                                                1
                                                                                                                            }
                                                                                                                            endText={
                                                                                                                                'Ends in' +
                                                                                                                                ':'
                                                                                                                            }
                                                                                                                            startText={
                                                                                                                                'Starts in' +
                                                                                                                                ':'
                                                                                                                            }
                                                                                                                            // theme="multiseller"
                                                                                                                        ></Timer>
                                                                                                                    </span>
                                                                                                                )
                                                                                                            ) : (
                                                                                                                ''
                                                                                                            )}
                                                                                                        </div>
                                                                                                    ) : (
                                                                                                        ''
                                                                                                    )}
                                                                                                </>
                                                                                            ) : null}
                                                                                        </>
                                                                                    )}
                                                                                </>
                                                                            </div>
                                                                        )}
                                                                    </>
                                                                ) : (
                                                                    ''
                                                                )}

                                                                {selectedProduct.market_status !=
                                                                    'draft' &&
                                                                selectedProduct.market_status !=
                                                                    'future' &&
                                                                global.storeDetails.theme == 9 ? (
                                                                    <>
                                                                        {(selectedProduct.market_status !=
                                                                            'open' ||
                                                                            selectedProduct.productClosed ||
                                                                            (selectedProduct.date_closed &&
                                                                                moment(
                                                                                    selectedProduct.date_closed,
                                                                                ).isValid() &&
                                                                                auctionTimer &&
                                                                                moment(
                                                                                    auctionTimer,
                                                                                ).isValid() &&
                                                                                new Date(
                                                                                    selectedProduct.date_closed,
                                                                                ).getTime() -
                                                                                    new Date(
                                                                                        auctionTimer,
                                                                                    ).getTime() <
                                                                                    0)) &&
                                                                        parseInt(
                                                                            selectedProduct.buynow,
                                                                        ) == 0 ? (
                                                                            <b>Auction Closed</b>
                                                                        ) : (
                                                                            <div
                                                                                className={`sl-pbids slidView ${
                                                                                    (!isAuthenticated ||
                                                                                        selectedProduct?.buynow) &&
                                                                                    'notLogged'
                                                                                }`}
                                                                            >
                                                                                {isAuthenticated ? (
                                                                                    <>
                                                                                        {selectedProduct.auction ? (
                                                                                            type ===
                                                                                            'buynow' ? null : (
                                                                                                <Fragment>
                                                                                                    {selectedProduct?.user_id ==
                                                                                                    user?.id ? (
                                                                                                        <p>
                                                                                                            {t(
                                                                                                                'do_not_bid',
                                                                                                            )}
                                                                                                        </p>
                                                                                                    ) : (
                                                                                                        <>
                                                                                                            {global
                                                                                                                .storeConfigration
                                                                                                                ?.hard_bid
                                                                                                                ?.value && (
                                                                                                                <div className="bidDet">
                                                                                                                    {parseInt(
                                                                                                                        selectedProduct.content_head5,
                                                                                                                    ) ==
                                                                                                                        0 && (
                                                                                                                        <p className="slidViewBtnLabel">
                                                                                                                            <span className="material-icons">
                                                                                                                                info
                                                                                                                            </span>
                                                                                                                            Hard
                                                                                                                            Bid
                                                                                                                            -
                                                                                                                            Bid
                                                                                                                            on
                                                                                                                            the
                                                                                                                            current
                                                                                                                            price.
                                                                                                                        </p>
                                                                                                                    )}
                                                                                                                    {global
                                                                                                                        .pluginConfiguration
                                                                                                                        ?.reverse_auction
                                                                                                                        ?.enable ? (
                                                                                                                        <Biddingforwardandreverse
                                                                                                                            data={
                                                                                                                                selectedProduct
                                                                                                                            }
                                                                                                                            hard="1"
                                                                                                                        />
                                                                                                                    ) : (
                                                                                                                        <Bidding
                                                                                                                            data={
                                                                                                                                selectedProduct
                                                                                                                            }
                                                                                                                            hard="1"
                                                                                                                            inputSize={
                                                                                                                                storeTheme ===
                                                                                                                                'ArtAuction'
                                                                                                                                    ? 'large'
                                                                                                                                    : 'small'
                                                                                                                            }
                                                                                                                            enableNextBid={
                                                                                                                                storeTheme ===
                                                                                                                                'ArtAuction'
                                                                                                                                    ? true
                                                                                                                                    : false
                                                                                                                            }
                                                                                                                        />
                                                                                                                    )}
                                                                                                                </div>
                                                                                                            )}
                                                                                                            {global
                                                                                                                .storeConfigration
                                                                                                                ?.proxy_bid
                                                                                                                ?.value !=
                                                                                                                0 &&
                                                                                                                parseInt(
                                                                                                                    selectedProduct.content_head5,
                                                                                                                ) ==
                                                                                                                    0 && (
                                                                                                                    <div className="bidDet">
                                                                                                                        <p className="slidViewBtnLabel">
                                                                                                                            <span className="material-icons">
                                                                                                                                info
                                                                                                                            </span>
                                                                                                                            Proxy
                                                                                                                            Bid
                                                                                                                            -
                                                                                                                            Enter
                                                                                                                            the
                                                                                                                            maximum
                                                                                                                            bid
                                                                                                                            you
                                                                                                                            would
                                                                                                                            like
                                                                                                                            to
                                                                                                                            automatically
                                                                                                                            bid
                                                                                                                            to.
                                                                                                                        </p>
                                                                                                                        {global
                                                                                                                            .pluginConfiguration
                                                                                                                            ?.reverse_auction
                                                                                                                            ?.enable ? (
                                                                                                                            <Biddingforwardandreverse
                                                                                                                                data={
                                                                                                                                    selectedProduct
                                                                                                                                }
                                                                                                                                hard="0"
                                                                                                                                status={
                                                                                                                                    1
                                                                                                                                }
                                                                                                                            />
                                                                                                                        ) : (
                                                                                                                            <Bidding
                                                                                                                                data={
                                                                                                                                    selectedProduct
                                                                                                                                }
                                                                                                                                hard="0"
                                                                                                                                status={
                                                                                                                                    1
                                                                                                                                }
                                                                                                                            />
                                                                                                                        )}
                                                                                                                    </div>
                                                                                                                )}
                                                                                                        </>
                                                                                                    )}
                                                                                                </Fragment>
                                                                                            )
                                                                                        ) : null}

                                                                                        {storeTheme !==
                                                                                            'ArtAuction' && (
                                                                                            <>
                                                                                                {(parseInt(
                                                                                                    selectedProduct.buynow,
                                                                                                ) ==
                                                                                                    1 &&
                                                                                                    parseInt(
                                                                                                        selectedProduct.auction,
                                                                                                    ) ==
                                                                                                        0) ||
                                                                                                (parseInt(
                                                                                                    selectedProduct.wprice,
                                                                                                ) <=
                                                                                                    parseInt(
                                                                                                        selectedProduct.bprice,
                                                                                                    ) &&
                                                                                                    parseInt(
                                                                                                        selectedProduct.buynow,
                                                                                                    ) ==
                                                                                                        1 &&
                                                                                                    parseInt(
                                                                                                        selectedProduct.auction,
                                                                                                    ) ==
                                                                                                        1) ? (
                                                                                                    <>
                                                                                                        <div className="">
                                                                                                            {selectedProduct?.user_id ==
                                                                                                            user?.id ? (
                                                                                                                <p>
                                                                                                                    You
                                                                                                                    cannot
                                                                                                                    buy
                                                                                                                    your
                                                                                                                    own
                                                                                                                    product!
                                                                                                                </p>
                                                                                                            ) : (
                                                                                                                <>
                                                                                                                    {itemDetails
                                                                                                                        ?.filter(
                                                                                                                            (
                                                                                                                                i,
                                                                                                                            ) =>
                                                                                                                                i.id ==
                                                                                                                                selectedLot,
                                                                                                                        )
                                                                                                                        .map(
                                                                                                                            (
                                                                                                                                d,
                                                                                                                            ) =>
                                                                                                                                d.buynowpaid,
                                                                                                                        ) ==
                                                                                                                    1 ? (
                                                                                                                        <h5 className="itemPurchased">
                                                                                                                            You
                                                                                                                            have
                                                                                                                            purchased
                                                                                                                            this
                                                                                                                            item
                                                                                                                        </h5>
                                                                                                                    ) : (
                                                                                                                        <PrimaryButton
                                                                                                                            onClick={
                                                                                                                                selectedProduct.market_status ==
                                                                                                                                'open'
                                                                                                                                    ? handleClick
                                                                                                                                    : ''
                                                                                                                            }
                                                                                                                            disabled={
                                                                                                                                selectedProduct.market_status !=
                                                                                                                                'open'
                                                                                                                                    ? true
                                                                                                                                    : disabled
                                                                                                                            }
                                                                                                                        >
                                                                                                                            {selectedProduct.market_status ==
                                                                                                                            'sold'
                                                                                                                                ? 'Lot Sold'
                                                                                                                                : selectedProduct.market_status ==
                                                                                                                                      'unsold' ||
                                                                                                                                  selectedProduct.market_status ==
                                                                                                                                      'closed'
                                                                                                                                ? 'Lot Closed'
                                                                                                                                : disabled
                                                                                                                                ? 'Added to cart'
                                                                                                                                : `Buy now for ${currencyFormat(
                                                                                                                                      parseFloat(
                                                                                                                                          selectedProduct.bprice,
                                                                                                                                      ).toFixed(
                                                                                                                                          2,
                                                                                                                                      ),
                                                                                                                                      currency,
                                                                                                                                  )}`}
                                                                                                                        </PrimaryButton>
                                                                                                                    )}
                                                                                                                </>
                                                                                                            )}
                                                                                                        </div>
                                                                                                        {storeTheme !==
                                                                                                            'ArtAuction' && (
                                                                                                            <br />
                                                                                                        )}
                                                                                                    </>
                                                                                                ) : null}
                                                                                            </>
                                                                                        )}
                                                                                    </>
                                                                                ) : null}
                                                                                {!isAuthenticated && (
                                                                                    <PrimaryButton
                                                                                        className="my-2"
                                                                                        onClick={() =>
                                                                                            history.push(
                                                                                                '/login',
                                                                                            )
                                                                                        }
                                                                                    >
                                                                                        Login to{' '}
                                                                                        {selectedProduct?.buynow &&
                                                                                        selectedProduct?.auction
                                                                                            ? 'Buy Or Bid'
                                                                                            : selectedProduct?.buynow
                                                                                            ? 'Buy'
                                                                                            : 'Bid'}
                                                                                    </PrimaryButton>
                                                                                )}
                                                                            </div>
                                                                        )}
                                                                    </>
                                                                ) : (
                                                                    ''
                                                                )}
                                                            </div>
                                                            {!isAuthenticated &&
                                                                checkThemeClassName &&
                                                                selectedProduct.market_status !==
                                                                    'sold' && (
                                                                    <PrimaryButton
                                                                        className="my-2"
                                                                        onClick={() =>
                                                                            history.push('/login')
                                                                        }
                                                                    >
                                                                        {selectedProduct?.buynow &&
                                                                        selectedProduct?.auction
                                                                            ? 'Login to Buy Or Bid'
                                                                            : selectedProduct?.buynow
                                                                            ? 'Login to Buy'
                                                                            : t('login_to_bid')}
                                                                    </PrimaryButton>
                                                                )}

                                                            {!isAuthenticated ? null : (
                                                                <div
                                                                    className="pvMiscActions favIconBgChange"
                                                                    style={{
                                                                        width: 'fit-content',
                                                                    }}
                                                                >
                                                                    {selectedProduct.market_status ==
                                                                    'open' ? (
                                                                        <FavouriteCheckbox
                                                                            watchlisted={
                                                                                selectedProduct.wlistpop
                                                                            }
                                                                            data={selectedProduct}
                                                                            project_id={
                                                                                selectedProduct.id
                                                                            }
                                                                            from={from}
                                                                            getAllProducts={
                                                                                getAllProducts
                                                                            }
                                                                            watchListTextAdd={true}
                                                                            setSelectedProduct={
                                                                                setSelectedProduct
                                                                            }
                                                                        />
                                                                    ) : null}
                                                                </div>
                                                            )}
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-6">
                                                            <div className="prodInfo">
                                                                <div className="prodInfoHeader">
                                                                    <h5>
                                                                        {' '}
                                                                        <span className="material-icons-outlined">
                                                                            info
                                                                        </span>
                                                                        Product Information
                                                                    </h5>
                                                                </div>
                                                                <div className="prodInfoBody">
                                                                    <>
                                                                        {isAuthenticated &&
                                                                        checkThemeClassName ? (
                                                                            <>
                                                                                {(parseInt(
                                                                                    selectedProduct.buynow,
                                                                                ) == 1 &&
                                                                                    parseInt(
                                                                                        selectedProduct.auction,
                                                                                    ) == 0) ||
                                                                                (parseInt(
                                                                                    selectedProduct.wprice,
                                                                                ) <=
                                                                                    parseInt(
                                                                                        selectedProduct.bprice,
                                                                                    ) &&
                                                                                    parseInt(
                                                                                        selectedProduct.buynow,
                                                                                    ) == 1 &&
                                                                                    parseInt(
                                                                                        selectedProduct.auction,
                                                                                    ) == 1) ? (
                                                                                    <>
                                                                                        <div className="">
                                                                                            {selectedProduct?.user_id ==
                                                                                            user?.id ? (
                                                                                                <p>
                                                                                                    You
                                                                                                    cannot
                                                                                                    buy
                                                                                                    your
                                                                                                    own
                                                                                                    product!
                                                                                                </p>
                                                                                            ) : (
                                                                                                <>
                                                                                                    {itemDetails
                                                                                                        ?.filter(
                                                                                                            (
                                                                                                                i,
                                                                                                            ) =>
                                                                                                                i.id ==
                                                                                                                selectedLot,
                                                                                                        )
                                                                                                        .map(
                                                                                                            (
                                                                                                                d,
                                                                                                            ) =>
                                                                                                                d.buynowpaid,
                                                                                                        ) ==
                                                                                                    1 ? (
                                                                                                        <h5 className="itemPurchased">
                                                                                                            You
                                                                                                            have
                                                                                                            purchased
                                                                                                            this
                                                                                                            item
                                                                                                        </h5>
                                                                                                    ) : (
                                                                                                        <>
                                                                                                            {global
                                                                                                                .pluginConfiguration
                                                                                                                ?.make_an_offer
                                                                                                                ?.enable &&
                                                                                                            selectedProduct?.make_an_offer_io ? (
                                                                                                                <div className="row">
                                                                                                                    {mapData(
                                                                                                                        make_an_offer,
                                                                                                                    )}
                                                                                                                    <div className="col-sm-6 col-12">
                                                                                                                        <PrimaryButton
                                                                                                                            onClick={(
                                                                                                                                e,
                                                                                                                            ) =>
                                                                                                                                selectedProduct.market_status ==
                                                                                                                                'open'
                                                                                                                                    ? make_formik.handleSubmit()
                                                                                                                                    : ''
                                                                                                                            }
                                                                                                                            disabled={
                                                                                                                                selectedProduct.market_status !=
                                                                                                                                'open'
                                                                                                                            }
                                                                                                                        >
                                                                                                                            {selectedProduct.market_status ==
                                                                                                                            'sold'
                                                                                                                                ? 'Lot Sold'
                                                                                                                                : selectedProduct.market_status ==
                                                                                                                                      'unsold' ||
                                                                                                                                  selectedProduct.market_status ==
                                                                                                                                      'closed'
                                                                                                                                ? 'Lot Closed'
                                                                                                                                : `Submit Offer`}
                                                                                                                        </PrimaryButton>
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                            ) : (
                                                                                                                ''
                                                                                                            )}

                                                                                                            <PrimaryButton
                                                                                                                onClick={
                                                                                                                    selectedProduct.market_status ==
                                                                                                                    'open'
                                                                                                                        ? handleClick
                                                                                                                        : ''
                                                                                                                }
                                                                                                                disabled={
                                                                                                                    selectedProduct.market_status !=
                                                                                                                    'open'
                                                                                                                }
                                                                                                            >
                                                                                                                {selectedProduct.market_status ==
                                                                                                                'sold'
                                                                                                                    ? t(
                                                                                                                          'lot_sold',
                                                                                                                      )
                                                                                                                    : selectedProduct.market_status ==
                                                                                                                          'unsold' ||
                                                                                                                      selectedProduct.market_status ==
                                                                                                                          'closed'
                                                                                                                    ? t(
                                                                                                                          'lot_closed',
                                                                                                                      )
                                                                                                                    : disabled
                                                                                                                    ? t(
                                                                                                                          'remove_from_cart',
                                                                                                                      )
                                                                                                                    : `${t(
                                                                                                                          'buy_now_for',
                                                                                                                      )} ${currencyFormat(
                                                                                                                          parseFloat(
                                                                                                                              selectedProduct.bprice,
                                                                                                                          ).toFixed(
                                                                                                                              2,
                                                                                                                          ),
                                                                                                                          currency,
                                                                                                                      )}`}
                                                                                                            </PrimaryButton>
                                                                                                        </>
                                                                                                    )}
                                                                                                </>
                                                                                            )}
                                                                                        </div>
                                                                                    </>
                                                                                ) : null}
                                                                            </>
                                                                        ) : (
                                                                            ''
                                                                        )}

                                                                        {storeTheme !==
                                                                            'ArtAuction' &&
                                                                            checkThemeClassName && (
                                                                                <>
                                                                                    {[
                                                                                        ...(global
                                                                                            .storeDetails
                                                                                            ?.theme ==
                                                                                        5
                                                                                            ? new_lotInformation
                                                                                            : lotInformation),
                                                                                    ].map(
                                                                                        (
                                                                                            data,
                                                                                            index,
                                                                                        ) => (
                                                                                            <>
                                                                                                {data.title ==
                                                                                                    'Pickup Details' &&
                                                                                                global
                                                                                                    .pluginConfiguration
                                                                                                    ?.limit_pickup_days
                                                                                                    ?.enable &&
                                                                                                data.description ==
                                                                                                    'Pickup' &&
                                                                                                parseInt(
                                                                                                    selectedProduct.loc_id,
                                                                                                ) >
                                                                                                    0 ? (
                                                                                                    <>
                                                                                                        {!checkThemeClassName ? (
                                                                                                            <div
                                                                                                                className={`mssDesc`}
                                                                                                            >
                                                                                                                <h6>
                                                                                                                    {
                                                                                                                        data.title
                                                                                                                    }
                                                                                                                </h6>
                                                                                                                <div className="pickTimeAdjCont d-flex align-items-center">
                                                                                                                    <Button
                                                                                                                        onClick={(
                                                                                                                            e,
                                                                                                                        ) =>
                                                                                                                            setOpenpickup(
                                                                                                                                'open',
                                                                                                                            )
                                                                                                                        }
                                                                                                                    >
                                                                                                                        <span className="material-icons">
                                                                                                                            info
                                                                                                                        </span>
                                                                                                                    </Button>
                                                                                                                    <span>
                                                                                                                        Pickup
                                                                                                                        time
                                                                                                                        for
                                                                                                                        this
                                                                                                                        item
                                                                                                                    </span>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        ) : (
                                                                                                            ''
                                                                                                        )}
                                                                                                    </>
                                                                                                ) : data.description &&
                                                                                                  data.display_need ==
                                                                                                      1 ? (
                                                                                                    <div
                                                                                                        key={
                                                                                                            index
                                                                                                        }
                                                                                                        className={
                                                                                                            checkThemeClassName
                                                                                                                ? 'mssDesc'
                                                                                                                : 'd-none'
                                                                                                        }
                                                                                                    >
                                                                                                        <h6>
                                                                                                            {
                                                                                                                data.title
                                                                                                            }
                                                                                                        </h6>
                                                                                                        {data.title ===
                                                                                                        'Description' ? (
                                                                                                            <div
                                                                                                                className={`shwMrBtnWrp myWrapping d-flex align-items-start justify-content-between`}
                                                                                                            >
                                                                                                                <p
                                                                                                                    className={`mb-1 pdtDscrpnVlue ${
                                                                                                                        showDescriptions
                                                                                                                            ? ''
                                                                                                                            : 'hide'
                                                                                                                    }`}
                                                                                                                    dangerouslySetInnerHTML={{
                                                                                                                        __html: data.description,
                                                                                                                    }}
                                                                                                                ></p>
                                                                                                                <span
                                                                                                                    className="mb-3 cursorDecoy shwMrBtn"
                                                                                                                    onClick={() =>
                                                                                                                        setShowDescriptions(
                                                                                                                            !showDescriptions,
                                                                                                                        )
                                                                                                                    }
                                                                                                                >
                                                                                                                    <b>
                                                                                                                        {descripTexts?.length >
                                                                                                                            55 && (
                                                                                                                            <u>
                                                                                                                                Show
                                                                                                                                {showDescriptions
                                                                                                                                    ? 'Less'
                                                                                                                                    : 'More'}
                                                                                                                            </u>
                                                                                                                        )}
                                                                                                                    </b>
                                                                                                                </span>
                                                                                                            </div>
                                                                                                        ) : data.title ===
                                                                                                              'Notes' &&
                                                                                                          checkThemeClassName ? (
                                                                                                            <div
                                                                                                                className={`shwMrBtnWrp myWrapping d-flex align-items-start justify-content-between`}
                                                                                                            >
                                                                                                                <p
                                                                                                                    className={`mb-1 pdtDscrpnVlue ${
                                                                                                                        showNotes
                                                                                                                            ? ''
                                                                                                                            : 'hide'
                                                                                                                    }`}
                                                                                                                    dangerouslySetInnerHTML={{
                                                                                                                        __html: data.description,
                                                                                                                    }}
                                                                                                                ></p>
                                                                                                                <span
                                                                                                                    className="mb-3 cursorDecoy shwMrBtn"
                                                                                                                    onClick={() =>
                                                                                                                        setShowNotes(
                                                                                                                            !showNotes,
                                                                                                                        )
                                                                                                                    }
                                                                                                                >
                                                                                                                    <b>
                                                                                                                        {notesText?.length >
                                                                                                                            55 && (
                                                                                                                            <u>
                                                                                                                                Show
                                                                                                                                {showNotes
                                                                                                                                    ? 'Less'
                                                                                                                                    : 'More'}
                                                                                                                            </u>
                                                                                                                        )}
                                                                                                                    </b>
                                                                                                                </span>
                                                                                                            </div>
                                                                                                        ) : data.title ==
                                                                                                              'Condition Report' &&
                                                                                                          global
                                                                                                              .storeConfigration
                                                                                                              ?.hyper_micro_popup_more
                                                                                                              ?.value ==
                                                                                                              1 &&
                                                                                                          global?.condition_data?.find(
                                                                                                              (
                                                                                                                  val,
                                                                                                              ) =>
                                                                                                                  val.condition ==
                                                                                                                  data.description,
                                                                                                          )
                                                                                                              ?.content ? (
                                                                                                            <div
                                                                                                                className={`shwMrBtnWrp d-flex align-items-center justify-content-start `}
                                                                                                            >
                                                                                                                <>
                                                                                                                    <p
                                                                                                                        className="my-1 pdtDscrpnVlue mr-1"
                                                                                                                        dangerouslySetInnerHTML={{
                                                                                                                            __html: data.description,
                                                                                                                        }}
                                                                                                                    ></p>
                                                                                                                    <Tooltip
                                                                                                                        title={
                                                                                                                            global?.condition_data?.find(
                                                                                                                                (
                                                                                                                                    val,
                                                                                                                                ) =>
                                                                                                                                    val.condition ==
                                                                                                                                    data.description,
                                                                                                                            )
                                                                                                                                ?.content
                                                                                                                        }
                                                                                                                        arrow
                                                                                                                        enterTouchDelay={
                                                                                                                            0
                                                                                                                        }
                                                                                                                        placement="top-start"
                                                                                                                        className="tooling"
                                                                                                                    >
                                                                                                                        <span>
                                                                                                                            i
                                                                                                                        </span>
                                                                                                                    </Tooltip>
                                                                                                                </>
                                                                                                            </div>
                                                                                                        ) : (
                                                                                                            <div
                                                                                                                className={`shwMrBtnWrp d-flex align-items-start justify-content-between `}
                                                                                                            >
                                                                                                                <p
                                                                                                                    className="mb-1 pdtDscrpnVlue"
                                                                                                                    dangerouslySetInnerHTML={{
                                                                                                                        __html: data.description,
                                                                                                                    }}
                                                                                                                ></p>
                                                                                                            </div>
                                                                                                        )}
                                                                                                    </div>
                                                                                                ) : null}
                                                                                            </>
                                                                                        ),
                                                                                    )}

                                                                                    {global
                                                                                        .storeConfigration
                                                                                        ?.show_condition
                                                                                        ?.value ==
                                                                                        1 &&
                                                                                    storeTheme ===
                                                                                        'Liquidation_two' &&
                                                                                    !checkThemeClassName ? (
                                                                                        <h6 className="galleryPrice condition">
                                                                                            <span>
                                                                                                Condition:{' '}
                                                                                            </span>
                                                                                            <span
                                                                                                className={`prcTxtVal ${
                                                                                                    selectedProduct.auctionnotes
                                                                                                        ?.trim()
                                                                                                        ?.toLowerCase()
                                                                                                        ?.includes(
                                                                                                            'new',
                                                                                                        )
                                                                                                        ? 'badge badge-success text-white'
                                                                                                        : selectedProduct.auctionnotes
                                                                                                              ?.trim()
                                                                                                              ?.toLowerCase()
                                                                                                              ?.includes(
                                                                                                                  'open box',
                                                                                                              )
                                                                                                        ? 'badge badge-primary text-white'
                                                                                                        : selectedProduct.auctionnotes
                                                                                                              ?.trim()
                                                                                                              ?.toLowerCase()
                                                                                                              ?.includes(
                                                                                                                  'damage',
                                                                                                              )
                                                                                                        ? 'badge badge-danger text-white'
                                                                                                        : ''
                                                                                                }`}
                                                                                                title={
                                                                                                    selectedProduct.auctionnotes
                                                                                                        ? selectedProduct.auctionnotes
                                                                                                        : '-'
                                                                                                }
                                                                                            >
                                                                                                {selectedProduct.auctionnotes
                                                                                                    ? selectedProduct.auctionnotes
                                                                                                    : '-'}
                                                                                            </span>
                                                                                        </h6>
                                                                                    ) : null}

                                                                                    {(selectedProduct.auction ==
                                                                                        1 &&
                                                                                        selectedProduct.buynow ==
                                                                                            0) ||
                                                                                    selectedProduct.auction ==
                                                                                        1 ? (
                                                                                        <div className="mssTimer d-none">
                                                                                            {selectedProduct.market_status !=
                                                                                            'draft' ? (
                                                                                                (selectedProduct.market_status !=
                                                                                                    'open' &&
                                                                                                    selectedProduct.market_status !=
                                                                                                        'future') ||
                                                                                                selectedProduct.productClosed ? (
                                                                                                    ''
                                                                                                ) : selectedProduct.extend ||
                                                                                                  selectedProduct.agent_id ? (
                                                                                                    <span className="tmm">
                                                                                                        <Timer
                                                                                                            date_added={
                                                                                                                selectedProduct.date_added
                                                                                                            }
                                                                                                            date_closed={
                                                                                                                selectedProduct.date_closed
                                                                                                            }
                                                                                                            withText={
                                                                                                                1
                                                                                                            }
                                                                                                            endText={
                                                                                                                'Ends in' +
                                                                                                                ':'
                                                                                                            }
                                                                                                            startText={
                                                                                                                'Starts in' +
                                                                                                                ':'
                                                                                                            }
                                                                                                            // theme="multiseller"
                                                                                                        ></Timer>
                                                                                                    </span>
                                                                                                ) : (
                                                                                                    <span>
                                                                                                        <Timer
                                                                                                            date_added={
                                                                                                                selectedProduct.date_added
                                                                                                            }
                                                                                                            date_closed={
                                                                                                                selectedProduct.date_closed
                                                                                                            }
                                                                                                            withText={
                                                                                                                1
                                                                                                            }
                                                                                                            endText={
                                                                                                                'Ends in' +
                                                                                                                ':'
                                                                                                            }
                                                                                                            startText={
                                                                                                                'Starts in' +
                                                                                                                ':'
                                                                                                            }
                                                                                                            // theme="multiseller"
                                                                                                        ></Timer>
                                                                                                    </span>
                                                                                                )
                                                                                            ) : (
                                                                                                ''
                                                                                            )}
                                                                                        </div>
                                                                                    ) : (
                                                                                        ''
                                                                                    )}
                                                                                </>
                                                                            )}

                                                                        {global?.storeConfigration
                                                                            ?.show_bin_location
                                                                            ?.value == 2 ? (
                                                                            <h5 className="lotNo">
                                                                                Bin Location:{' '}
                                                                                <span>
                                                                                    &nbsp;&nbsp;
                                                                                    {selectedProduct.budget_type
                                                                                        ? selectedProduct.budget_type
                                                                                        : '-'}
                                                                                </span>
                                                                            </h5>
                                                                        ) : null}
                                                                        {storeTheme ==
                                                                        'Liquidation_two' ? (
                                                                            <div className="mssBidItem mssBidIntDet">
                                                                                <div className="mssBidMisc multisellertwo">
                                                                                    <div className="mssBidInfo">
                                                                                        <h5>
                                                                                            <span>
                                                                                                Lot
                                                                                                Number:
                                                                                            </span>
                                                                                            {selectedProduct.lot_number
                                                                                                ? selectedProduct.lot_number
                                                                                                : selectedProduct.sku
                                                                                                ? selectedProduct.sku
                                                                                                : selectedProduct.deed_document
                                                                                                ? selectedProduct.deed_document
                                                                                                : selectedProduct.id}
                                                                                        </h5>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        ) : null}
                                                                        {storeTheme ==
                                                                            'Liquidation_two' &&
                                                                        checkThemeClassName ? (
                                                                            <div className="mssBidItem mssBidIntDet">
                                                                                {selectedProduct.custom_field_8 ||
                                                                                selectedProduct.custom_field_7 ||
                                                                                selectedProduct.custom_field_6 ? (
                                                                                    <h5
                                                                                        className="lotNo"
                                                                                        // style={{
                                                                                        //     gap: '8px',
                                                                                        // }}
                                                                                    >
                                                                                        Dimension:&nbsp;&nbsp;
                                                                                        {selectedProduct ? (
                                                                                            <div
                                                                                                className="d-flex"
                                                                                                style={{
                                                                                                    gap: '5px',
                                                                                                }}
                                                                                            >
                                                                                                <span>
                                                                                                    {
                                                                                                        selectedProduct.length
                                                                                                    }
                                                                                                    {
                                                                                                        selectedProduct.custom_field_4
                                                                                                    }
                                                                                                </span>
                                                                                                <span
                                                                                                    style={{
                                                                                                        textTransform:
                                                                                                            'lowercase',
                                                                                                    }}
                                                                                                >
                                                                                                    x
                                                                                                </span>
                                                                                                <span>
                                                                                                    {
                                                                                                        selectedProduct.width
                                                                                                    }
                                                                                                    {
                                                                                                        selectedProduct.custom_field_4
                                                                                                    }
                                                                                                </span>
                                                                                                <span
                                                                                                    style={{
                                                                                                        textTransform:
                                                                                                            'lowercase',
                                                                                                    }}
                                                                                                >
                                                                                                    x
                                                                                                </span>
                                                                                                <span>
                                                                                                    {
                                                                                                        selectedProduct.height
                                                                                                    }
                                                                                                    {
                                                                                                        selectedProduct.custom_field_4
                                                                                                    }
                                                                                                </span>
                                                                                            </div>
                                                                                        ) : null}
                                                                                    </h5>
                                                                                ) : null}
                                                                            </div>
                                                                        ) : null}

                                                                        <div className="mssBidItem mssBidIntDet">
                                                                            <div className="mssBidMisc multisellertwo">
                                                                                {selectedProduct.qty ? (
                                                                                    <div className="mssBidInfo">
                                                                                        <h5>
                                                                                            <span>
                                                                                                Quantity:
                                                                                            </span>
                                                                                            {
                                                                                                selectedProduct.qty
                                                                                            }
                                                                                        </h5>
                                                                                    </div>
                                                                                ) : null}
                                                                                {selectedProduct.selling_unit ? (
                                                                                    <div className="mssBidInfo">
                                                                                        <h5>
                                                                                            <span>
                                                                                                Unit:
                                                                                            </span>
                                                                                            {
                                                                                                unitOptions.find(
                                                                                                    (
                                                                                                        val,
                                                                                                    ) =>
                                                                                                        val.id ==
                                                                                                        selectedProduct.selling_unit,
                                                                                                )
                                                                                                    .description
                                                                                            }
                                                                                        </h5>
                                                                                    </div>
                                                                                ) : null}
                                                                                {selectedProduct.total_weight ? (
                                                                                    <div className="mssBidInfo">
                                                                                        <h5>
                                                                                            <span>
                                                                                                Total
                                                                                                Weight:
                                                                                            </span>
                                                                                            {
                                                                                                selectedProduct.total_weight
                                                                                            }
                                                                                            {storeTheme ===
                                                                                                'foodindustry' ||
                                                                                            storeTheme ===
                                                                                                'LiquidationPallet'
                                                                                                ? 'Kg'
                                                                                                : ''}
                                                                                        </h5>
                                                                                    </div>
                                                                                ) : null}
                                                                                {selectedProduct.location ? (
                                                                                    <div className="mssBidInfo">
                                                                                        <h5>
                                                                                            <span>
                                                                                                Location:
                                                                                            </span>
                                                                                            {storeTheme ===
                                                                                                'foodindustry' ||
                                                                                            storeTheme ===
                                                                                                'LiquidationPallet' ? (
                                                                                                <>
                                                                                                    {
                                                                                                        selectedProduct.city
                                                                                                    }
                                                                                                </>
                                                                                            ) : (
                                                                                                <>
                                                                                                    {countryList
                                                                                                        .filter(
                                                                                                            (
                                                                                                                country,
                                                                                                            ) =>
                                                                                                                country.location_id ==
                                                                                                                selectedProduct.location,
                                                                                                        )
                                                                                                        .map(
                                                                                                            (
                                                                                                                data,
                                                                                                            ) =>
                                                                                                                data.name,
                                                                                                        )}
                                                                                                </>
                                                                                            )}
                                                                                        </h5>
                                                                                    </div>
                                                                                ) : null}
                                                                                {checkThemeClassName
                                                                                    ? ''
                                                                                    : // <div className="mssBidInfo">
                                                                                      //     <h5>
                                                                                      //         <span>
                                                                                      //             Bid History:
                                                                                      //         </span>
                                                                                      //         {!loading &&
                                                                                      //             bidHistory.length ===
                                                                                      //                 0 &&
                                                                                      //             'No bids'}
                                                                                      //     </h5>
                                                                                      //     <>
                                                                                      //         {!loading &&
                                                                                      //         bidHistory.length !==
                                                                                      //             0 ? (
                                                                                      //             <>
                                                                                      //                 <table className="table table-hover bidHistory">
                                                                                      //                     <thead>
                                                                                      //                         <tr>
                                                                                      //                             {/* <th>
                                                                                      //                             S.No
                                                                                      //                         </th>
                                                                                      //                         {global
                                                                                      //                             .storeConfigration
                                                                                      //                             ?.hide_bidder
                                                                                      //                             ?.value !=
                                                                                      //                             '1' && (
                                                                                      //                             <th>
                                                                                      //                                 {t(
                                                                                      //                                     'username',
                                                                                      //                                 )}
                                                                                      //                             </th>
                                                                                      //                         )} */}
                                                                                      //                             {global
                                                                                      //                                 .storeConfigration
                                                                                      //                                 ?.hide_bidder_id
                                                                                      //                                 ?.value !=
                                                                                      //                                 '1' && (
                                                                                      //                                 <th>
                                                                                      //                                     {t(
                                                                                      //                                         'bidderid',
                                                                                      //                                     )}
                                                                                      //                                 </th>
                                                                                      //                             )}
                                                                                      //                             <th>
                                                                                      //                                 {t(
                                                                                      //                                     'date',
                                                                                      //                                 ) +
                                                                                      //                                     ' & ' +
                                                                                      //                                     t(
                                                                                      //                                         'time',
                                                                                      //                                     )}
                                                                                      //                             </th>
                                                                                      //                             <th>
                                                                                      //                                 {t(
                                                                                      //                                     'amount',
                                                                                      //                                 )}
                                                                                      //                             </th>
                                                                                      //                         </tr>
                                                                                      //                     </thead>
                                                                                      //                     <tbody>
                                                                                      //                         {(showMoreBidHistory
                                                                                      //                             ? bidHistory.slice(
                                                                                      //                                   0,
                                                                                      //                                   10,
                                                                                      //                               )
                                                                                      //                             : bidHistory
                                                                                      //                         ).map(
                                                                                      //                             (
                                                                                      //                                 item,
                                                                                      //                                 index,
                                                                                      //                             ) => {
                                                                                      //                                 return (
                                                                                      //                                     <tr
                                                                                      //                                         key={
                                                                                      //                                             index
                                                                                      //                                         }
                                                                                      //                                     >
                                                                                      //                                         {/* <td
                                                                                      //                                         className="clearfix responsiveView"
                                                                                      //                                         data-title="S.No"
                                                                                      //                                     >
                                                                                      //                                         {index +
                                                                                      //                                             1}
                                                                                      //                                     </td>
                                                                                      //                                     {global
                                                                                      //                                         .storeConfigration
                                                                                      //                                         ?.hide_bidder
                                                                                      //                                         ?.value !=
                                                                                      //                                         '1' && (
                                                                                      //                                         <td
                                                                                      //                                             className="clearfix responsiveView"
                                                                                      //                                             data-title="username"
                                                                                      //                                         >
                                                                                      //                                             {global
                                                                                      //                                                 .storeConfigration
                                                                                      //                                                 ?.full_name_username
                                                                                      //                                                 ?.value ==
                                                                                      //                                             1
                                                                                      //                                                 ? item.username
                                                                                      //                                                 : item.namesec}
                                                                                      //                                         </td>
                                                                                      //                                     )} */}
                                                                                      //                                         {global
                                                                                      //                                             .storeConfigration
                                                                                      //                                             ?.hide_bidder_id
                                                                                      //                                             ?.value !=
                                                                                      //                                             '1' && (
                                                                                      //                                             <td
                                                                                      //                                                 className="clearfix responsiveView"
                                                                                      //                                                 data-title="Bidder Id"
                                                                                      //                                             >
                                                                                      //                                                 {item.bidder_id_vc
                                                                                      //                                                     ? item.bidder_id_vc
                                                                                      //                                                     : item.user_id}{' '}
                                                                                      //                                                 {item.is_auctionio
                                                                                      //                                                     ? '(Auction Io)'
                                                                                      //                                                     : ''}
                                                                                      //                                             </td>
                                                                                      //                                         )}
                                                                                      //                                         <td
                                                                                      //                                             className="clearfix responsiveView"
                                                                                      //                                             data-title="Date & Time"
                                                                                      //                                         >
                                                                                      //                                             {item.created_at
                                                                                      //                                                 ? `${moment(
                                                                                      //                                                       item.created_at,
                                                                                      //                                                   ).format(
                                                                                      //                                                       'MMM Do YYYY, h:mm a',
                                                                                      //                                                   )} `
                                                                                      //                                                 : '-'}
                                                                                      //                                         </td>
                                                                                      //                                         <td
                                                                                      //                                             className="clearfix responsiveView nowrap"
                                                                                      //                                             data-title="Amount"
                                                                                      //                                         >
                                                                                      //                                             {currencyFormat(
                                                                                      //                                                 parseFloat(
                                                                                      //                                                     item.realproposed_amount,
                                                                                      //                                                 ).toFixed(
                                                                                      //                                                     2,
                                                                                      //                                                 ),
                                                                                      //                                             )}
                                                                                      //                                         </td>
                                                                                      //                                     </tr>
                                                                                      //                                 )
                                                                                      //                             },
                                                                                      //                         )}
                                                                                      //                     </tbody>
                                                                                      //                 </table>
                                                                                      //                 {bidHistory.length >
                                                                                      //                     10 && (
                                                                                      //                     <span
                                                                                      //                         className="mb-3 cursorDecoy shwMrBtn"
                                                                                      //                         onClick={() =>
                                                                                      //                             setShowMoreBidHistory(
                                                                                      //                                 !showMoreBidHistory,
                                                                                      //                             )
                                                                                      //                         }
                                                                                      //                     >
                                                                                      //                         <b>
                                                                                      //                             <u>
                                                                                      //                                 Show
                                                                                      //                                 {!showMoreBidHistory
                                                                                      //                                     ? 'Less'
                                                                                      //                                     : 'More'}
                                                                                      //                             </u>
                                                                                      //                         </b>
                                                                                      //                     </span>
                                                                                      //                 )}
                                                                                      //             </>
                                                                                      //         ) : (
                                                                                      //             <></>
                                                                                      //         )}
                                                                                      //     </>
                                                                                      // </div>
                                                                                      null}
                                                                            </div>
                                                                            <div className="mssBidMisc">
                                                                                {selectedProduct.storage ? (
                                                                                    <div className="mssBidInfo">
                                                                                        <h5>
                                                                                            <span>
                                                                                                Storage:
                                                                                            </span>
                                                                                            {
                                                                                                storageOptions.find(
                                                                                                    (
                                                                                                        val,
                                                                                                    ) =>
                                                                                                        val.id ==
                                                                                                        selectedProduct.storage,
                                                                                                )
                                                                                                    .description
                                                                                            }
                                                                                        </h5>
                                                                                    </div>
                                                                                ) : null}
                                                                                {selectedProduct.package_date ? (
                                                                                    <div className="mssBidInfo">
                                                                                        <h5>
                                                                                            <span>
                                                                                                Package
                                                                                                Date:
                                                                                            </span>
                                                                                            {
                                                                                                selectedProduct.package_date
                                                                                            }
                                                                                        </h5>
                                                                                    </div>
                                                                                ) : null}
                                                                                {selectedProduct.conditionunique &&
                                                                                global
                                                                                    .storeConfigration
                                                                                    ?.add_extra_fillter
                                                                                    ?.value != 1 ? (
                                                                                    <div className="mssBidInfo">
                                                                                        <h5>
                                                                                            <span>
                                                                                                Condition:
                                                                                            </span>
                                                                                            {allCondition
                                                                                                .filter(
                                                                                                    (
                                                                                                        condition,
                                                                                                    ) =>
                                                                                                        condition.id ==
                                                                                                        selectedProduct.conditionunique,
                                                                                                )
                                                                                                .map(
                                                                                                    (
                                                                                                        data,
                                                                                                    ) =>
                                                                                                        data.description,
                                                                                                )}
                                                                                        </h5>
                                                                                    </div>
                                                                                ) : null}

                                                                                {selectedProduct.auctionnotes &&
                                                                                global
                                                                                    .storeConfigration
                                                                                    ?.add_extra_fillter
                                                                                    ?.value == 1 ? (
                                                                                    <div className="mssBidInfo">
                                                                                        <h5>
                                                                                            <span>
                                                                                                Condition:
                                                                                            </span>
                                                                                            {allCondition
                                                                                                .filter(
                                                                                                    (
                                                                                                        condition,
                                                                                                    ) =>
                                                                                                        condition.id ==
                                                                                                        selectedProduct.auctionnotes,
                                                                                                )
                                                                                                .map(
                                                                                                    (
                                                                                                        data,
                                                                                                    ) =>
                                                                                                        data.description,
                                                                                                )}
                                                                                        </h5>
                                                                                    </div>
                                                                                ) : null}

                                                                                {global
                                                                                    .pluginConfiguration
                                                                                    ?.most_viewed
                                                                                    ?.enable == 1 &&
                                                                                selectedProduct.viwers ? (
                                                                                    <div className="mssBidInfo">
                                                                                        <h5>
                                                                                            <span>
                                                                                                Viewers:
                                                                                            </span>
                                                                                            {
                                                                                                selectedProduct.viwers
                                                                                            }
                                                                                        </h5>
                                                                                    </div>
                                                                                ) : null}
                                                                            </div>
                                                                            {storeTheme ===
                                                                                'foodindustry' && (
                                                                                <div className="mssBidMisc">
                                                                                    {selectedProduct.content_head5 ? (
                                                                                        <div className="mssBidInfo">
                                                                                            <h5>
                                                                                                <span>
                                                                                                    Sustainability:
                                                                                                </span>
                                                                                                {sustainabilityOptions
                                                                                                    .filter(
                                                                                                        (
                                                                                                            condition,
                                                                                                        ) =>
                                                                                                            selectedProduct.content_head5
                                                                                                                .split(
                                                                                                                    ',',
                                                                                                                )
                                                                                                                .includes(
                                                                                                                    `${condition.id}`,
                                                                                                                ),
                                                                                                    )

                                                                                                    .map(
                                                                                                        (
                                                                                                            data,
                                                                                                        ) =>
                                                                                                            data.description,
                                                                                                    )
                                                                                                    .join(
                                                                                                        ', ',
                                                                                                    )}
                                                                                            </h5>
                                                                                        </div>
                                                                                    ) : null}
                                                                                </div>
                                                                            )}

                                                                            {global
                                                                                .storeConfigration
                                                                                ?.condition_field
                                                                                ?.value == 1 && (
                                                                                <div className="pdtStsInfo mb-2 mx-0">
                                                                                    <ul>
                                                                                        {global
                                                                                            .storeConfigration
                                                                                            ?.condition_field
                                                                                            ?.value ==
                                                                                        1 ? (
                                                                                            <li>
                                                                                                <label>
                                                                                                    Condition:
                                                                                                </label>
                                                                                                <p
                                                                                                    className={`m-0 ${
                                                                                                        selectedProduct.condition_field?.includes(
                                                                                                            'Used - Like New',
                                                                                                        )
                                                                                                            ? 'text-Yellow'
                                                                                                            : selectedProduct.condition_field?.includes(
                                                                                                                  'New',
                                                                                                              )
                                                                                                            ? 'text-success'
                                                                                                            : 'text-danger'
                                                                                                    }`}
                                                                                                >
                                                                                                    {selectedProduct.condition_field ||
                                                                                                        '-'}
                                                                                                </p>
                                                                                            </li>
                                                                                        ) : null}

                                                                                        {global
                                                                                            .storeConfigration
                                                                                            ?.functional_field
                                                                                            ?.value ==
                                                                                        1 ? (
                                                                                            <li>
                                                                                                <label>
                                                                                                    Functional:
                                                                                                </label>
                                                                                                <p
                                                                                                    className={`m-0 ${
                                                                                                        selectedProduct.functional_field?.includes(
                                                                                                            'Yes',
                                                                                                        )
                                                                                                            ? 'text-success'
                                                                                                            : 'text-danger'
                                                                                                    }`}
                                                                                                >
                                                                                                    {selectedProduct.functional_field ||
                                                                                                        '-'}
                                                                                                </p>
                                                                                            </li>
                                                                                        ) : null}
                                                                                    </ul>
                                                                                    <ul>
                                                                                        {global
                                                                                            .storeConfigration
                                                                                            ?.damaged_field
                                                                                            ?.value ==
                                                                                        1 ? (
                                                                                            <li>
                                                                                                <label>
                                                                                                    Damaged:
                                                                                                </label>
                                                                                                <p
                                                                                                    className={`m-0 ${
                                                                                                        selectedProduct.damaged_field?.includes(
                                                                                                            'No',
                                                                                                        )
                                                                                                            ? 'text-success'
                                                                                                            : 'text-danger'
                                                                                                    }`}
                                                                                                >
                                                                                                    {selectedProduct.damaged_field ||
                                                                                                        '-'}
                                                                                                </p>
                                                                                            </li>
                                                                                        ) : null}

                                                                                        {global
                                                                                            .storeConfigration
                                                                                            ?.missing_parts_field
                                                                                            ?.value ==
                                                                                        1 ? (
                                                                                            <li>
                                                                                                <label>
                                                                                                    Missing
                                                                                                    Parts:
                                                                                                </label>
                                                                                                <p
                                                                                                    className={`m-0 ${
                                                                                                        selectedProduct.missing_parts_field?.includes(
                                                                                                            'Yes',
                                                                                                        )
                                                                                                            ? 'text-danger'
                                                                                                            : 'text-success'
                                                                                                    }`}
                                                                                                >
                                                                                                    {selectedProduct.missing_parts_field ||
                                                                                                        '-'}
                                                                                                </p>
                                                                                            </li>
                                                                                        ) : null}
                                                                                    </ul>
                                                                                    <ul>
                                                                                        {selectedProduct.weight ? (
                                                                                            <li>
                                                                                                <label>
                                                                                                    Weight:
                                                                                                </label>
                                                                                                <p
                                                                                                    className={`m-0`}
                                                                                                >
                                                                                                    {selectedProduct.weight ||
                                                                                                        '-'}{' '}
                                                                                                    &nbsp;
                                                                                                    (
                                                                                                    {getunitsdetails(
                                                                                                        selectedProduct.custom_field_1,
                                                                                                    )}

                                                                                                    )
                                                                                                </p>
                                                                                            </li>
                                                                                        ) : null}

                                                                                        {selectedProduct.width ? (
                                                                                            <li>
                                                                                                <label>
                                                                                                    Width:
                                                                                                </label>
                                                                                                <p
                                                                                                    className={`m-0`}
                                                                                                >
                                                                                                    {selectedProduct.width ||
                                                                                                        '-'}
                                                                                                    &nbsp;
                                                                                                    (
                                                                                                    {getdimensiondetails(
                                                                                                        selectedProduct.custom_field_2,
                                                                                                    )}

                                                                                                    )
                                                                                                </p>
                                                                                            </li>
                                                                                        ) : null}
                                                                                    </ul>
                                                                                    <ul>
                                                                                        {selectedProduct.length ? (
                                                                                            <li>
                                                                                                <label>
                                                                                                    Depth:
                                                                                                </label>
                                                                                                <p
                                                                                                    className={`m-0`}
                                                                                                >
                                                                                                    {selectedProduct.length ||
                                                                                                        '-'}
                                                                                                    &nbsp;
                                                                                                    (
                                                                                                    {getdimensiondetails(
                                                                                                        selectedProduct.custom_field_2,
                                                                                                    )}

                                                                                                    )
                                                                                                </p>
                                                                                            </li>
                                                                                        ) : null}

                                                                                        {selectedProduct.height ? (
                                                                                            <li>
                                                                                                <label>
                                                                                                    Height:
                                                                                                </label>
                                                                                                <p
                                                                                                    className={`m-0`}
                                                                                                >
                                                                                                    {selectedProduct.height ||
                                                                                                        '-'}
                                                                                                    &nbsp;
                                                                                                    (
                                                                                                    {getdimensiondetails(
                                                                                                        selectedProduct.custom_field_2,
                                                                                                    )}

                                                                                                    )
                                                                                                </p>
                                                                                            </li>
                                                                                        ) : null}
                                                                                    </ul>
                                                                                </div>
                                                                            )}
                                                                            {theme ===
                                                                                'charity' && (
                                                                                <>
                                                                                    <div className="mssBidMisc">
                                                                                        <div className="mssBidInfo">
                                                                                            <h5>
                                                                                                <span>
                                                                                                    Starting
                                                                                                    Date:
                                                                                                </span>
                                                                                                {moment(
                                                                                                    moment(
                                                                                                        selectedProduct.date_added,
                                                                                                    ).isValid()
                                                                                                        ? selectedProduct.date_added
                                                                                                        : oneYear,
                                                                                                ).format(
                                                                                                    'MMM Do YYYY, h:mm:ss a',
                                                                                                )}
                                                                                            </h5>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="mssBidMisc">
                                                                                        <div className="mssBidInfo">
                                                                                            <h5>
                                                                                                <span>
                                                                                                    Closing
                                                                                                    Date:
                                                                                                </span>
                                                                                                {moment(
                                                                                                    moment(
                                                                                                        selectedProduct.date_closed,
                                                                                                    ).isValid()
                                                                                                        ? selectedProduct.date_closed
                                                                                                        : oneYear,
                                                                                                ).format(
                                                                                                    'MMM Do YYYY, h:mm:ss a',
                                                                                                )}
                                                                                            </h5>
                                                                                        </div>
                                                                                    </div>
                                                                                    {type !==
                                                                                        'buynow' && (
                                                                                        <>
                                                                                            <div className="mssBidMisc">
                                                                                                <div className="mssBidInfo">
                                                                                                    <span>
                                                                                                        Total
                                                                                                        Bids:{' '}
                                                                                                    </span>
                                                                                                    <a
                                                                                                        className="tb-link"
                                                                                                        onClick={() =>
                                                                                                            selectedProduct.bids &&
                                                                                                            selectedProduct.bids !==
                                                                                                                0 &&
                                                                                                            !selectedProduct.is_past &&
                                                                                                            setHistoryData(
                                                                                                                {
                                                                                                                    product_id:
                                                                                                                        selectedProduct.id,
                                                                                                                    end: moment(
                                                                                                                        selectedProduct.date_closed,
                                                                                                                    ).isValid()
                                                                                                                        ? selectedProduct.date_closed
                                                                                                                        : oneYear,
                                                                                                                },
                                                                                                            )
                                                                                                        }
                                                                                                    >
                                                                                                        {selectedProduct.bids
                                                                                                            ? selectedProduct.bids
                                                                                                            : 0}
                                                                                                    </a>
                                                                                                </div>
                                                                                            </div>
                                                                                        </>
                                                                                    )}
                                                                                </>
                                                                            )}
                                                                        </div>
                                                                    </>
                                                                    <>
                                                                        {[
                                                                            ...(global.storeDetails
                                                                                ?.theme == 5
                                                                                ? new_lotInformation
                                                                                : lotInformation),
                                                                        ].map((data, index) => (
                                                                            <>
                                                                                {data.title ==
                                                                                    'Pickup Details' &&
                                                                                global
                                                                                    .pluginConfiguration
                                                                                    ?.limit_pickup_days
                                                                                    ?.enable &&
                                                                                data.description ==
                                                                                    'Pickup' &&
                                                                                parseInt(
                                                                                    selectedProduct.loc_id,
                                                                                ) > 0 ? (
                                                                                    <>
                                                                                        {!checkThemeClassName ? (
                                                                                            <div
                                                                                                className={`mssDesc`}
                                                                                            >
                                                                                                <h6>
                                                                                                    {
                                                                                                        data.title
                                                                                                    }
                                                                                                </h6>
                                                                                                <div className="pickTimeAdjCont d-flex align-items-center">
                                                                                                    <Button
                                                                                                        onClick={(
                                                                                                            e,
                                                                                                        ) =>
                                                                                                            setOpenpickup(
                                                                                                                'open',
                                                                                                            )
                                                                                                        }
                                                                                                    >
                                                                                                        <span className="material-icons">
                                                                                                            info
                                                                                                        </span>
                                                                                                    </Button>
                                                                                                    <span>
                                                                                                        Pickup
                                                                                                        time
                                                                                                        for
                                                                                                        this
                                                                                                        item
                                                                                                    </span>
                                                                                                </div>
                                                                                            </div>
                                                                                        ) : (
                                                                                            ''
                                                                                        )}
                                                                                    </>
                                                                                ) : data.description &&
                                                                                  data.display_need ==
                                                                                      1 ? (
                                                                                    <div
                                                                                        key={index}
                                                                                        className={
                                                                                            !checkThemeClassName
                                                                                                ? 'mssDesc'
                                                                                                : 'd-none'
                                                                                        }
                                                                                    >
                                                                                        <h6>
                                                                                            {
                                                                                                data.title
                                                                                            }
                                                                                        </h6>
                                                                                        {data.title ===
                                                                                        'Description' ? (
                                                                                            <div
                                                                                                className={`shwMrBtnWrp myWrapping d-flex align-items-start justify-content-between`}
                                                                                            >
                                                                                                <p
                                                                                                    className={`mb-1 pdtDscrpnVlue ${
                                                                                                        showDescriptions
                                                                                                            ? ''
                                                                                                            : 'hide'
                                                                                                    }`}
                                                                                                    dangerouslySetInnerHTML={{
                                                                                                        __html: data.description,
                                                                                                    }}
                                                                                                ></p>
                                                                                                <span
                                                                                                    className="mb-3 cursorDecoy shwMrBtn"
                                                                                                    onClick={() =>
                                                                                                        setShowDescriptions(
                                                                                                            !showDescriptions,
                                                                                                        )
                                                                                                    }
                                                                                                >
                                                                                                    <b>
                                                                                                        {descripTexts?.length >
                                                                                                            55 && (
                                                                                                            <u>
                                                                                                                Show
                                                                                                                {showDescriptions
                                                                                                                    ? 'Less'
                                                                                                                    : 'More'}
                                                                                                            </u>
                                                                                                        )}
                                                                                                    </b>
                                                                                                </span>
                                                                                            </div>
                                                                                        ) : data.title ==
                                                                                              'Condition Report' &&
                                                                                          global
                                                                                              .storeConfigration
                                                                                              ?.hyper_micro_popup_more
                                                                                              ?.value ==
                                                                                              1 &&
                                                                                          global?.condition_data?.find(
                                                                                              (
                                                                                                  val,
                                                                                              ) =>
                                                                                                  val.condition ==
                                                                                                  data.description,
                                                                                          )
                                                                                              ?.content ? (
                                                                                            <div
                                                                                                className={`shwMrBtnWrp d-flex align-items-center justify-content-start `}
                                                                                            >
                                                                                                <>
                                                                                                    <p
                                                                                                        className="my-1 pdtDscrpnVlue mr-1"
                                                                                                        dangerouslySetInnerHTML={{
                                                                                                            __html: data.description,
                                                                                                        }}
                                                                                                    ></p>
                                                                                                    <Tooltip
                                                                                                        title={
                                                                                                            global?.condition_data?.find(
                                                                                                                (
                                                                                                                    val,
                                                                                                                ) =>
                                                                                                                    val.condition ==
                                                                                                                    data.description,
                                                                                                            )
                                                                                                                ?.content
                                                                                                        }
                                                                                                        arrow
                                                                                                        enterTouchDelay={
                                                                                                            0
                                                                                                        }
                                                                                                        placement="top-start"
                                                                                                        className="tooling"
                                                                                                    >
                                                                                                        <span>
                                                                                                            i
                                                                                                        </span>
                                                                                                    </Tooltip>
                                                                                                </>
                                                                                            </div>
                                                                                        ) : (
                                                                                            <div
                                                                                                className={`shwMrBtnWrp d-flex align-items-start justify-content-between `}
                                                                                            >
                                                                                                <p
                                                                                                    className="mb-1 pdtDscrpnVlue"
                                                                                                    dangerouslySetInnerHTML={{
                                                                                                        __html: data.description,
                                                                                                    }}
                                                                                                ></p>
                                                                                            </div>
                                                                                        )}
                                                                                    </div>
                                                                                ) : null}
                                                                            </>
                                                                        ))}

                                                                        {global.storeConfigration
                                                                            ?.show_condition
                                                                            ?.value == 1 &&
                                                                        storeTheme ===
                                                                            'Liquidation_two' &&
                                                                        !checkThemeClassName ? (
                                                                            <h6 className="galleryPrice condition">
                                                                                <span>
                                                                                    Condition:{' '}
                                                                                </span>
                                                                                <span
                                                                                    className={`prcTxtVal ${
                                                                                        selectedProduct.auctionnotes
                                                                                            ?.trim()
                                                                                            ?.toLowerCase()
                                                                                            ?.includes(
                                                                                                'new',
                                                                                            )
                                                                                            ? 'badge badge-success text-white'
                                                                                            : selectedProduct.auctionnotes
                                                                                                  ?.trim()
                                                                                                  ?.toLowerCase()
                                                                                                  ?.includes(
                                                                                                      'open box',
                                                                                                  )
                                                                                            ? 'badge badge-primary text-white'
                                                                                            : selectedProduct.auctionnotes
                                                                                                  ?.trim()
                                                                                                  ?.toLowerCase()
                                                                                                  ?.includes(
                                                                                                      'damage',
                                                                                                  )
                                                                                            ? 'badge badge-danger text-white'
                                                                                            : ''
                                                                                    }`}
                                                                                    title={
                                                                                        selectedProduct.auctionnotes
                                                                                            ? selectedProduct.auctionnotes
                                                                                            : '-'
                                                                                    }
                                                                                >
                                                                                    {selectedProduct.auctionnotes
                                                                                        ? selectedProduct.auctionnotes
                                                                                        : '-'}
                                                                                </span>
                                                                            </h6>
                                                                        ) : null}

                                                                        {!checkThemeClassName ? (
                                                                            <>
                                                                                {(selectedProduct.auction ==
                                                                                    1 &&
                                                                                    selectedProduct.buynow ==
                                                                                        0) ||
                                                                                selectedProduct.auction ==
                                                                                    1 ? (
                                                                                    <div className="mssTimer">
                                                                                        {selectedProduct.market_status !=
                                                                                        'draft' ? (
                                                                                            (selectedProduct.market_status !=
                                                                                                'open' &&
                                                                                                selectedProduct.market_status !=
                                                                                                    'future') ||
                                                                                            selectedProduct.productClosed ? (
                                                                                                ''
                                                                                            ) : selectedProduct.extend ||
                                                                                              selectedProduct.agent_id ? (
                                                                                                <span className="tmm">
                                                                                                    <Timer
                                                                                                        date_added={
                                                                                                            selectedProduct.date_added
                                                                                                        }
                                                                                                        date_closed={
                                                                                                            selectedProduct.date_closed
                                                                                                        }
                                                                                                        withText={
                                                                                                            1
                                                                                                        }
                                                                                                        endText={
                                                                                                            'Ends in' +
                                                                                                            ':'
                                                                                                        }
                                                                                                        startText={
                                                                                                            'Starts in' +
                                                                                                            ':'
                                                                                                        }
                                                                                                        // theme="multiseller"
                                                                                                    ></Timer>
                                                                                                </span>
                                                                                            ) : (
                                                                                                <span>
                                                                                                    <Timer
                                                                                                        date_added={
                                                                                                            selectedProduct.date_added
                                                                                                        }
                                                                                                        date_closed={
                                                                                                            selectedProduct.date_closed
                                                                                                        }
                                                                                                        withText={
                                                                                                            1
                                                                                                        }
                                                                                                        endText={
                                                                                                            'Ends in' +
                                                                                                            ':'
                                                                                                        }
                                                                                                        startText={
                                                                                                            'Starts in' +
                                                                                                            ':'
                                                                                                        }
                                                                                                        // theme="multiseller"
                                                                                                    ></Timer>
                                                                                                </span>
                                                                                            )
                                                                                        ) : (
                                                                                            ''
                                                                                        )}
                                                                                    </div>
                                                                                ) : (
                                                                                    ''
                                                                                )}
                                                                            </>
                                                                        ) : null}
                                                                    </>
                                                                    {global.storeConfigration
                                                                        ?.fishing_theme?.value ==
                                                                    1 ? (
                                                                        <div className="additionalDesc">
                                                                            <h5>
                                                                                {' '}
                                                                                {
                                                                                    selectedProduct
                                                                                        .extra_heading
                                                                                        ?.heading
                                                                                }
                                                                            </h5>
                                                                            {selectedProduct.extra?.map(
                                                                                (val) => (
                                                                                    <p>
                                                                                        {
                                                                                            val.heading
                                                                                        }
                                                                                        :{' '}
                                                                                        <span>
                                                                                            {
                                                                                                val.value
                                                                                            }
                                                                                        </span>
                                                                                    </p>
                                                                                ),
                                                                            )}
                                                                        </div>
                                                                    ) : null}
                                                                    {ASIN.price &&
                                                                    ASIN.product_url ? (
                                                                        <h5 className="lotNo btnBgChange">
                                                                            <a
                                                                                className="rtlPrice"
                                                                                href={
                                                                                    ASIN.product_url
                                                                                }
                                                                                target="_blank"
                                                                                rel="noreferrer"
                                                                            >
                                                                                Research Product
                                                                                Online -{' '}
                                                                                {currencyFormat(
                                                                                    parseFloat(
                                                                                        ASIN.price,
                                                                                    ).toFixed(2),
                                                                                )}{' '}
                                                                            </a>
                                                                        </h5>
                                                                    ) : null}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-6">
                                                            <div className="prodInfo">
                                                                <div className="prodInfoHeader">
                                                                    <h5>
                                                                        <span className="material-icons-outlined">
                                                                            location_on
                                                                        </span>
                                                                        Pickup
                                                                    </h5>
                                                                </div>
                                                                <div className="prodInfoBody">
                                                                    <h5 className="lotNo">
                                                                        <span>
                                                                            102 S Shaver St.
                                                                            Pasadena, TX 77506
                                                                        </span>
                                                                    </h5>
                                                                    <em>
                                                                        *You have 5 days to pick up
                                                                        the item
                                                                    </em>
                                                                </div>
                                                                <div className="prodInfoHeader wo-bord">
                                                                    <h5>
                                                                        <span className="material-icons-outlined">
                                                                            sell
                                                                        </span>
                                                                        Buyer&apos;s premium
                                                                    </h5>
                                                                </div>
                                                                <div className="prodInfoBody">
                                                                    <h5 className="lotNo">
                                                                        <span>
                                                                            Invoices over $25 will
                                                                            have an 18% buyer&apos;s
                                                                            premium with no lot fee.
                                                                            Invoices under $25 will
                                                                            incur a $3 lot fee and a
                                                                            15% buyer&apos;s
                                                                            premium.
                                                                        </span>
                                                                    </h5>
                                                                </div>
                                                                <div className="prodInfoHeader wo-bord">
                                                                    <h5>
                                                                        <span className="material-icons-outlined">
                                                                            credit_card
                                                                        </span>
                                                                        Payment
                                                                    </h5>
                                                                </div>
                                                                <div className="prodInfoBody">
                                                                    <h5 className="lotNo">
                                                                        <span>
                                                                            Payment is accepted
                                                                            exclusively via credit
                                                                            card.
                                                                        </span>
                                                                    </h5>
                                                                    <em>
                                                                        *All charges will be
                                                                        automatically processed on
                                                                        your credit card at the
                                                                        conclusion of the auction.
                                                                    </em>
                                                                </div>
                                                                <div className="prodInfoHeader wo-bord">
                                                                    <h5>
                                                                        <span className="material-icons-outlined">
                                                                            local_shipping
                                                                        </span>
                                                                        Transfer
                                                                    </h5>
                                                                </div>
                                                                <div className="prodInfoBody">
                                                                    <h5 className="lotNo">
                                                                        <span>NO TRANSFERS</span>
                                                                    </h5>
                                                                    <em>
                                                                        *This item cannot be
                                                                        transferred.
                                                                    </em>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                    )}

                                    {activePolicy && !checkThemeClassName && (
                                        <>
                                            {global?.storeConfigration?.hyper_micro_popup_more
                                                ?.value == 1 && (
                                                <>
                                                    {more_infor_popup_slider.map((data, index) => (
                                                        <>
                                                            {data.display_need == 1 ? (
                                                                <Accordion
                                                                    square
                                                                    expanded={
                                                                        expanded === `panel${index}`
                                                                    }
                                                                    key={1}
                                                                    onChange={handleChange(
                                                                        `panel${index}`,
                                                                    )}
                                                                >
                                                                    <AccordionSummary
                                                                        expandIcon={
                                                                            <ExpandMoreIcon />
                                                                        }
                                                                        aria-controls={`panel${index}}d-content`}
                                                                        id={`panel${index}}d-header`}
                                                                    >
                                                                        <h6 className="accTitle">
                                                                            {data.title}
                                                                        </h6>
                                                                    </AccordionSummary>
                                                                    <AccordionDetails>
                                                                        <p
                                                                            className="dscrpnCtnrArt"
                                                                            dangerouslySetInnerHTML={{
                                                                                __html: data.description,
                                                                            }}
                                                                        ></p>
                                                                    </AccordionDetails>
                                                                </Accordion>
                                                            ) : (
                                                                ''
                                                            )}
                                                        </>
                                                    ))}
                                                </>
                                            )}
                                        </>
                                    )}
                                    {activePolicy && checkThemeClassName && (
                                        <>
                                            {global?.storeConfigration?.hyper_micro_popup_more
                                                ?.value == 1 && (
                                                <>
                                                    <div className="primeBidPlcyImg">
                                                        <img src="/assets/images/policyPrime.png" />
                                                    </div>
                                                </>
                                            )}
                                        </>
                                    )}
                                    {pickupDetails && checkThemeClassName && (
                                        <>
                                            {global?.storeConfigration?.hyper_micro_popup_more
                                                ?.value == 1 && (
                                                <>
                                                    <h2>
                                                        102 shaver st
                                                        <br /> Texas, Pasadena, 77506
                                                        <br />
                                                        United States
                                                    </h2>
                                                    <h2 className="timings">
                                                        Pick up Hours: Monday-Friday 9am-4:45PM
                                                    </h2>
                                                    <div className="primeBidPlcyImg">
                                                        <img src="/assets/images/pickUpLocation.png" />
                                                    </div>
                                                </>
                                            )}
                                        </>
                                    )}
                                </div>

                                {storeTheme === 'ArtAuction' && (
                                    <div className="mpTop">
                                        <div className="topNmCntnr">
                                            <h5 className="lotNo">
                                                {t('lot_id')}:{' '}
                                                {selectedProduct.deed_document
                                                    ? selectedProduct.deed_document
                                                    : selectedProduct.id}
                                            </h5>
                                            <h3
                                                className="plTitle"
                                                dangerouslySetInnerHTML={{
                                                    __html: selectedProduct.title,
                                                }}
                                            ></h3>
                                            <NavLink
                                                to={`/productView/${selectedProduct.id}`}
                                                className="fullDetils"
                                            >
                                                View Full Details
                                            </NavLink>
                                        </div>

                                        {(selectedProduct.auction == 1 &&
                                            selectedProduct.buynow == 0) ||
                                        selectedProduct.auction == 1 ? (
                                            <div className="mssTimer olp">
                                                {selectedProduct.market_status != 'draft' ? (
                                                    (selectedProduct.market_status != 'open' &&
                                                        selectedProduct.market_status !=
                                                            'future') ||
                                                    selectedProduct.productClosed ? (
                                                        ''
                                                    ) : selectedProduct.extend ||
                                                      selectedProduct.agent_id ? (
                                                        <>
                                                            {artTheme ? null : (
                                                                <span className="material-icons-outlined">
                                                                    timer
                                                                </span>
                                                            )}
                                                            <span className="tmm">
                                                                <Timer
                                                                    date_added={
                                                                        selectedProduct.date_added
                                                                    }
                                                                    date_closed={
                                                                        selectedProduct.date_closed
                                                                    }
                                                                    withText={1}
                                                                    endText={'Timed Out' + ':'}
                                                                    startText={'Starts in' + ':'}
                                                                    // theme="multiseller"
                                                                ></Timer>
                                                            </span>
                                                        </>
                                                    ) : (
                                                        <>
                                                            {artTheme ? null : (
                                                                <span className="material-icons-outlined">
                                                                    timer
                                                                </span>
                                                            )}
                                                            <span>
                                                                <Timer
                                                                    date_added={
                                                                        selectedProduct.date_added
                                                                    }
                                                                    date_closed={
                                                                        selectedProduct.date_closed
                                                                    }
                                                                    withText={1}
                                                                    endText={
                                                                        artTheme
                                                                            ? t('TIME LEFT')
                                                                            : t('ends_in') + ':'
                                                                    }
                                                                    startText={t('starts_in') + ':'}
                                                                    theme="multiseller"
                                                                ></Timer>
                                                            </span>
                                                        </>
                                                    )
                                                ) : (
                                                    ''
                                                )}
                                            </div>
                                        ) : (
                                            ''
                                        )}
                                    </div>
                                )}
                                {!viewPage && (
                                    <img
                                        src="/image/close.png"
                                        onClick={() => {
                                            handleClose()
                                        }}
                                        className="close-img"
                                        height="35"
                                        width="35"
                                        onError={(e) => (e.target.src = imageError())}
                                    />
                                )}
                                <div className="prodInfoSlider" style={{ paddingTop: '20px' }}>
                                    {isAuthenticated ? (
                                        selectedProduct.auction ? (
                                            type === 'buynow' ? null : (
                                                <BidStatus
                                                    bidTopStatus={selectedProduct.bidtopstatus}
                                                />
                                            )
                                        ) : null
                                    ) : null}
                                    <span className="imgGlrWrpr">
                                        {selectedProduct.market_status === 'sold' && (
                                            <div className="soldWrapper">
                                                <p>SOLD</p>
                                            </div>
                                        )}

                                        {/* {console.log(selectedProductImage, 'selectedProductImage')} */}
                                        {/* {console.log(productVideos, 'productVideos')} */}
                                        <ImageGallery
                                            items={selectedProductImage}
                                            thumbnailPosition={
                                                storeTheme === 'LiquidationPallet'
                                                    ? 'left'
                                                    : 'bottom'
                                            }
                                            showThumbnails={
                                                storeTheme === 'foodindustry' ? false : true
                                            }
                                            showNav={true}
                                            showBullets={false}
                                            showFullscreenButton={true}
                                            showPlayButton={false}
                                            onErrorImageURL={imageError()}
                                            originalClass="slidImg img-fluid"
                                            renderItem={RenderImage2}
                                            ref={sliderRef2}
                                            renderCustomControls={RenderImageZoom2}
                                        />
                                    </span>
                                    {/* {productVideos.map((val) => (
                                                <div className="vdoPlyrWrapper">
                                                    <ReactPlayer light={false} controls={true} url={val} />
                                                </div>
                                            ))} */}
                                    {storeTheme != 'foodindustry' &&
                                        storeTheme != 'LiquidationPallet' && (
                                            <>
                                                <div
                                                    className={`d-flex ${
                                                        storeTheme !== 'ArtAuction'
                                                            ? 'justify-content-between'
                                                            : 'justify-content-end'
                                                    } align-items-center pvTitleContainer flex-wrap`}
                                                >
                                                    {storeTheme !== 'ArtAuction' &&
                                                        storeTheme !== 'Liquidation_two' && (
                                                            <>
                                                                <div
                                                                    className={
                                                                        selectedProduct?.buynow &&
                                                                        'd-none'
                                                                    }
                                                                >
                                                                    {storeTheme != undefined ? (
                                                                        <h5 className="lotNo">
                                                                            Lot#{' '}
                                                                            {selectedProduct.sku
                                                                                ? selectedProduct.sku
                                                                                : selectedProduct.lot_number
                                                                                ? selectedProduct.lot_number
                                                                                : selectedProduct.deed_document
                                                                                ? selectedProduct.deed_document
                                                                                : selectedProduct.id}
                                                                        </h5>
                                                                    ) : (
                                                                        <h5 className="lotNo">
                                                                            Lot#{' '}
                                                                            {selectedProduct.deed_document
                                                                                ? selectedProduct.deed_document
                                                                                : selectedProduct.id}
                                                                        </h5>
                                                                    )}
                                                                    {parseInt(
                                                                        selectedProduct.buynow,
                                                                    ) == 0 &&
                                                                    (parseInt(
                                                                        selectedProduct.lowest,
                                                                    ) > 0 ||
                                                                        parseInt(
                                                                            selectedProduct.highest,
                                                                        ) > 0) ? (
                                                                        <p>
                                                                            <span>Est:</span>
                                                                            {`${
                                                                                ' ' +
                                                                                currencyFormat(
                                                                                    selectedProduct.lowest,
                                                                                    currency,
                                                                                ) +
                                                                                ' - ' +
                                                                                currencyFormat(
                                                                                    selectedProduct.highest,
                                                                                    currency,
                                                                                )
                                                                            }`}
                                                                        </p>
                                                                    ) : (
                                                                        ''
                                                                    )}
                                                                </div>
                                                            </>
                                                        )}
                                                    {artTheme ? null : (
                                                        <div className="pvMiscActions">
                                                            <div
                                                                className="cpyToClipDiv"
                                                                style={{
                                                                    cursor: 'pointer',
                                                                }}
                                                            >
                                                                <Copy
                                                                    setAlert={setAlert}
                                                                    text={`/${
                                                                        storeTheme ==
                                                                        'Liquidation_two'
                                                                            ? 'search'
                                                                            : ''
                                                                    }?product=${
                                                                        selectedProduct.id
                                                                    }`}
                                                                />
                                                            </div>
                                                            {selectedProduct.market_status ==
                                                            'open' ? (
                                                                <FavouriteCheckbox
                                                                    watchlisted={
                                                                        selectedProduct.wlistpop
                                                                    }
                                                                    data={selectedProduct}
                                                                    project_id={selectedProduct.id}
                                                                    from={from}
                                                                    getAllProducts={getAllProducts}
                                                                    setSelectedProduct={
                                                                        setSelectedProduct
                                                                    }
                                                                />
                                                            ) : null}
                                                            <Facebook
                                                                path={
                                                                    storeTheme ==
                                                                        'Heavyequipment' ||
                                                                    storeTheme == 'Liquidation_two'
                                                                        ? `/${
                                                                              base_url
                                                                                  ? base_url
                                                                                  : 'search'
                                                                          }?product=${
                                                                              selectedProduct.id
                                                                          }`
                                                                        : global.storeConfigration
                                                                              ?.product_query
                                                                              ?.value == 1
                                                                        ? `/?product=${selectedProduct.id}`
                                                                        : `/product/${selectedProduct.id}`
                                                                }
                                                                title={selectedProduct.title}
                                                                selectedProduct={selectedProduct}
                                                            />
                                                            <Whatsapp
                                                                path={
                                                                    storeTheme ==
                                                                        'Heavyequipment' ||
                                                                    storeTheme == 'Liquidation_two'
                                                                        ? `/${
                                                                              base_url
                                                                                  ? base_url
                                                                                  : 'search'
                                                                          }?product=${
                                                                              selectedProduct.id
                                                                          }`
                                                                        : global.storeConfigration
                                                                              ?.product_query
                                                                              ?.value == 1
                                                                        ? `/?product=${selectedProduct.id}`
                                                                        : `/product/${selectedProduct.id}`
                                                                }
                                                                title={selectedProduct.title}
                                                            />
                                                            <Twitter
                                                                path={
                                                                    storeTheme ==
                                                                        'Heavyequipment' ||
                                                                    storeTheme == 'Liquidation_two'
                                                                        ? `/${
                                                                              base_url
                                                                                  ? base_url
                                                                                  : 'search'
                                                                          }?product=${
                                                                              selectedProduct.id
                                                                          }`
                                                                        : global.storeConfigration
                                                                              ?.product_query
                                                                              ?.value == 1
                                                                        ? `/?product=${selectedProduct.id}`
                                                                        : `/product/${selectedProduct.id}`
                                                                }
                                                                title={selectedProduct.title}
                                                            />
                                                            <Email
                                                                path={
                                                                    storeTheme ==
                                                                        'Heavyequipment' ||
                                                                    storeTheme == 'Liquidation_two'
                                                                        ? `/${
                                                                              base_url
                                                                                  ? base_url
                                                                                  : 'search'
                                                                          }?product=${
                                                                              selectedProduct.id
                                                                          }`
                                                                        : global.storeConfigration
                                                                              ?.product_query
                                                                              ?.value == 1
                                                                        ? `/?product=${selectedProduct.id}`
                                                                        : `/product/${selectedProduct.id}`
                                                                }
                                                                subject={selectedProduct.title}
                                                                body={removeHTMLTags(
                                                                    selectedProduct.description,
                                                                )}
                                                            />
                                                            <Pinterest
                                                                path={
                                                                    storeTheme ==
                                                                        'Heavyequipment' ||
                                                                    storeTheme == 'Liquidation_two'
                                                                        ? `/${
                                                                              base_url
                                                                                  ? base_url
                                                                                  : 'search'
                                                                          }?product=${
                                                                              selectedProduct.id
                                                                          }`
                                                                        : global.storeConfigration
                                                                              ?.product_query
                                                                              ?.value == 1
                                                                        ? `/?product=${selectedProduct.id}`
                                                                        : `/product/${selectedProduct.id}`
                                                                }
                                                                title={selectedProduct.title}
                                                                media={
                                                                    `${
                                                                        selectedProduct.content_head1 ===
                                                                        '0'
                                                                            ? process.env
                                                                                  .REACT_APP_BASE_URL +
                                                                              'uploads/product/'
                                                                            : process.env
                                                                                  .REACT_APP_PRODUCT_IMAGE_URL
                                                                    }` + selectedProduct.avatar
                                                                }
                                                            />
                                                        </div>
                                                    )}
                                                </div>
                                                {/* <Button
                                                        className="d-none productPageAct"
                                                        onClick={() =>
                                                            handleRedirectInternal(
                                                                history,
                                                                `productView/${selectedProduct.id}`,
                                                            )
                                                        }
                                                    >
                                                        View Full Details
                                                    </Button> */}
                                            </>
                                        )}
                                    {storeTheme === 'ArtAuction' ? (
                                        <>
                                            {/* {selectedProduct.market_status == 'open' ? (
                                                        <FavouriteCheckbox
                                                            watchlisted={selectedProduct.wlistpop}
                                                            data={selectedProduct}
                                                            project_id={selectedProduct.id}
                                                            from={from}
                                                            getAllProducts={getAllProducts}
                                                            setSelectedProduct={setSelectedProduct}
                                                        />
                                                    ) : null} */}
                                            {(parseInt(selectedProduct.buynow) == 0 ||
                                                isArtTheme) &&
                                            (parseInt(selectedProduct.lowest) > 0 ||
                                                parseInt(selectedProduct.highest) > 0) ? (
                                                <h5 className="estValue">
                                                    <span>{t('est')}:</span>
                                                    {`${
                                                        ' ' +
                                                        currencyFormat(
                                                            selectedProduct.lowest,
                                                            currency,
                                                        ) +
                                                        ' - ' +
                                                        currencyFormat(
                                                            selectedProduct.highest,
                                                            currency,
                                                        )
                                                    }`}
                                                </h5>
                                            ) : (
                                                ''
                                            )}
                                            {selectedProduct.conditionunique ? (
                                                <div className="mssBidInfo">
                                                    <h5>
                                                        <span>{t('condition')}:</span>
                                                        {conditions === 'Collectibles' ? (
                                                            <>
                                                                {collectibleConditions
                                                                    .filter(
                                                                        (condition) =>
                                                                            condition.id ==
                                                                            selectedProduct.conditionunique,
                                                                    )
                                                                    .map(
                                                                        (data) => data.description,
                                                                    )}
                                                            </>
                                                        ) : (
                                                            <>
                                                                {allCondition
                                                                    .filter(
                                                                        (condition) =>
                                                                            condition.id ==
                                                                            selectedProduct.conditionunique,
                                                                    )
                                                                    .map(
                                                                        (data) => data.description,
                                                                    )}
                                                            </>
                                                        )}
                                                    </h5>
                                                </div>
                                            ) : null}
                                            {artTheme ? null : (
                                                <>
                                                    {selectedProduct.content_head5 ? (
                                                        <div className="mssBidInfo artisName">
                                                            <h5>
                                                                <span>{t('artist_name')}:</span>
                                                                <span
                                                                    style={{
                                                                        color: '#0b81ff',
                                                                        cursor: 'pointer',
                                                                    }}
                                                                    onClick={() => {
                                                                        handleClose()
                                                                        history.push(
                                                                            `/bio/${selectedProduct.user_id}`,
                                                                        )
                                                                        window.scrollTo(0, 0)
                                                                    }}
                                                                >
                                                                    {selectedProduct.content_head5}
                                                                </span>
                                                            </h5>
                                                        </div>
                                                    ) : null}
                                                </>
                                            )}
                                        </>
                                    ) : (
                                        <>
                                            <div className="mpTop">
                                                <h3
                                                    className="plTitle"
                                                    dangerouslySetInnerHTML={{
                                                        __html: selectedProduct.title,
                                                    }}
                                                ></h3>
                                            </div>
                                            {ASIN.price && ASIN.product_url ? (
                                                <div className="mssBidItem mssBidIntDet">
                                                    <div className="mssBidMisc multisellertwo">
                                                        <div className="mssBidInfo">
                                                            <h5>
                                                                <span>
                                                                    <a
                                                                        href={ASIN.product_url}
                                                                        target="_blank"
                                                                        rel="noreferrer"
                                                                    >
                                                                        Amazon Retail Price -{' '}
                                                                        {currencyFormat(
                                                                            parseFloat(
                                                                                ASIN.price,
                                                                            ).toFixed(2),
                                                                        )}{' '}
                                                                    </a>
                                                                </span>
                                                            </h5>
                                                        </div>
                                                    </div>
                                                </div>
                                            ) : null}
                                            {global?.storeConfigration?.show_bin_location?.value ==
                                            2 ? (
                                                <div className="mssBidItem mssBidIntDet">
                                                    <div className="mssBidMisc multisellertwo">
                                                        <div className="mssBidInfo">
                                                            <h5>
                                                                <span>Bin Location:</span>{' '}
                                                                {selectedProduct.budget_type
                                                                    ? selectedProduct.budget_type
                                                                    : '-'}
                                                            </h5>
                                                        </div>
                                                    </div>
                                                </div>
                                            ) : null}
                                            {storeTheme == 'Liquidation_two' ? (
                                                <div className="mssBidItem mssBidIntDet">
                                                    <div className="mssBidMisc multisellertwo">
                                                        <div className="mssBidInfo">
                                                            <h5>
                                                                <span>Lot Number:</span>
                                                                {selectedProduct.lot_number
                                                                    ? selectedProduct.lot_number
                                                                    : selectedProduct.sku
                                                                    ? selectedProduct.sku
                                                                    : selectedProduct.deed_document
                                                                    ? selectedProduct.deed_document
                                                                    : selectedProduct.id}
                                                            </h5>
                                                        </div>
                                                    </div>
                                                </div>
                                            ) : null}
                                            <div className="mssBidItem mssBidIntDet">
                                                <div className="mssBidMisc multisellertwo beachSellerTwo">
                                                    {selectedProduct.qty ? (
                                                        <div className="mssBidInfo">
                                                            <h5>
                                                                <span>Quantity:</span>
                                                                {selectedProduct.qty}
                                                            </h5>
                                                        </div>
                                                    ) : null}
                                                    {selectedProduct.selling_unit ? (
                                                        <div className="mssBidInfo">
                                                            <h5>
                                                                <span>Unit:</span>
                                                                {
                                                                    unitOptions.find(
                                                                        (val) =>
                                                                            val.id ==
                                                                            selectedProduct.selling_unit,
                                                                    ).description
                                                                }
                                                            </h5>
                                                        </div>
                                                    ) : null}
                                                    {selectedProduct.total_weight ? (
                                                        <div className="mssBidInfo">
                                                            <h5>
                                                                <span>Total Weight:</span>
                                                                {selectedProduct.total_weight}
                                                                {storeTheme === 'foodindustry' ||
                                                                storeTheme === 'LiquidationPallet'
                                                                    ? 'Kg'
                                                                    : ''}
                                                            </h5>
                                                        </div>
                                                    ) : null}
                                                    {selectedProduct.location ? (
                                                        <div className="mssBidInfo">
                                                            <h5>
                                                                <span>Location:</span>
                                                                {storeTheme === 'foodindustry' ||
                                                                storeTheme ===
                                                                    'LiquidationPallet' ? (
                                                                    <>{selectedProduct.city}</>
                                                                ) : (
                                                                    <>
                                                                        {countryList
                                                                            .filter(
                                                                                (country) =>
                                                                                    country.location_id ==
                                                                                    selectedProduct.location,
                                                                            )
                                                                            .map(
                                                                                (data) => data.name,
                                                                            )}
                                                                    </>
                                                                )}
                                                            </h5>
                                                        </div>
                                                    ) : null}
                                                </div>
                                                <div className="mssBidMisc">
                                                    {selectedProduct.storage ? (
                                                        <div className="mssBidInfo">
                                                            <h5>
                                                                <span>Storage:</span>
                                                                {
                                                                    storageOptions.find(
                                                                        (val) =>
                                                                            val.id ==
                                                                            selectedProduct.storage,
                                                                    ).description
                                                                }
                                                            </h5>
                                                        </div>
                                                    ) : null}
                                                    {selectedProduct.package_date ? (
                                                        <div className="mssBidInfo">
                                                            <h5>
                                                                <span>Package Date:</span>
                                                                {selectedProduct.package_date}
                                                            </h5>
                                                        </div>
                                                    ) : null}
                                                    {selectedProduct.conditionunique &&
                                                    global.storeConfigration?.add_extra_fillter
                                                        ?.value != 1 ? (
                                                        <div className="mssBidInfo">
                                                            <h5>
                                                                <span>Condition:</span>
                                                                {allCondition
                                                                    .filter(
                                                                        (condition) =>
                                                                            condition.id ==
                                                                            selectedProduct.conditionunique,
                                                                    )
                                                                    .map(
                                                                        (data) => data.description,
                                                                    )}
                                                            </h5>
                                                        </div>
                                                    ) : null}

                                                    {selectedProduct.auctionnotes &&
                                                    global.storeConfigration?.add_extra_fillter
                                                        ?.value == 1 ? (
                                                        <div className="mssBidInfo">
                                                            <h5>
                                                                <span>Condition:</span>
                                                                {allCondition
                                                                    .filter(
                                                                        (condition) =>
                                                                            condition.id ==
                                                                            selectedProduct.auctionnotes,
                                                                    )
                                                                    .map(
                                                                        (data) => data.description,
                                                                    )}
                                                            </h5>
                                                        </div>
                                                    ) : null}

                                                    {global.pluginConfiguration?.most_viewed
                                                        ?.enable == 1 && selectedProduct.viwers ? (
                                                        <div className="mssBidInfo">
                                                            <h5>
                                                                <span>Viewers:</span>
                                                                {selectedProduct.viwers}
                                                            </h5>
                                                        </div>
                                                    ) : null}
                                                </div>
                                                {storeTheme === 'foodindustry' && (
                                                    <div className="mssBidMisc">
                                                        {selectedProduct.content_head5 ? (
                                                            <div className="mssBidInfo">
                                                                <h5>
                                                                    <span>Sustainability:</span>
                                                                    {sustainabilityOptions
                                                                        .filter((condition) =>
                                                                            selectedProduct.content_head5
                                                                                .split(',')
                                                                                .includes(
                                                                                    `${condition.id}`,
                                                                                ),
                                                                        )

                                                                        .map(
                                                                            (data) =>
                                                                                data.description,
                                                                        )
                                                                        .join(', ')}
                                                                </h5>
                                                            </div>
                                                        ) : null}
                                                    </div>
                                                )}
                                                {storeTheme === 'LiquidationPallet' && (
                                                    <>
                                                        {selectedProduct.content_head5 &&
                                                        brandsliqutatios
                                                            .filter((condition) =>
                                                                selectedProduct.content_head5
                                                                    .split(',')
                                                                    .includes(`${condition.id}`),
                                                            )

                                                            .map((data) => data.description)
                                                            .join(', ') !== '' ? (
                                                            <div className="mssBidMisc">
                                                                <div className="mssBidInfo">
                                                                    <h5>
                                                                        <span>Seller:</span>
                                                                        {brandsliqutatios
                                                                            .filter((condition) =>
                                                                                selectedProduct.content_head5
                                                                                    .split(',')
                                                                                    .includes(
                                                                                        `${condition.id}`,
                                                                                    ),
                                                                            )

                                                                            .map(
                                                                                (data) =>
                                                                                    data.description,
                                                                            )
                                                                            .join(', ')}
                                                                    </h5>
                                                                </div>
                                                            </div>
                                                        ) : null}

                                                        <div className="mssBidMisc">
                                                            {selectedProduct.mprice ? (
                                                                <>
                                                                    <div className="mssBidInfo">
                                                                        <h5>
                                                                            <span>
                                                                                Retail Price:{' '}
                                                                                {selectedProduct.retailer_link ? (
                                                                                    <a
                                                                                        rel={
                                                                                            'noreferrer'
                                                                                        }
                                                                                        target="_blank"
                                                                                        href={
                                                                                            selectedProduct.retailer_link
                                                                                        }
                                                                                    >
                                                                                        (Retailer)
                                                                                    </a>
                                                                                ) : (
                                                                                    ''
                                                                                )}
                                                                            </span>

                                                                            {currencyFormat(
                                                                                parseFloat(
                                                                                    selectedProduct.mprice,
                                                                                ).toFixed(2),
                                                                            )}
                                                                            <CurrencyConverterFormat
                                                                                amount={
                                                                                    selectedProduct.mprice
                                                                                }
                                                                            />
                                                                        </h5>{' '}
                                                                    </div>
                                                                </>
                                                            ) : null}
                                                        </div>
                                                    </>
                                                )}
                                                {global.storeConfigration?.condition_field?.value ==
                                                    1 && (
                                                    <div className="pdtStsInfo mb-2 mx-0">
                                                        <ul>
                                                            {global.storeConfigration
                                                                ?.condition_field?.value == 1 ? (
                                                                <li>
                                                                    <label>Condition:</label>
                                                                    <p
                                                                        className={`m-0 ${
                                                                            selectedProduct.condition_field?.includes(
                                                                                'Used - Like New',
                                                                            )
                                                                                ? 'text-Yellow'
                                                                                : selectedProduct.condition_field?.includes(
                                                                                      'New',
                                                                                  )
                                                                                ? 'text-success'
                                                                                : 'text-danger'
                                                                        }`}
                                                                    >
                                                                        {selectedProduct.condition_field ||
                                                                            '-'}
                                                                    </p>
                                                                </li>
                                                            ) : null}

                                                            {global.storeConfigration
                                                                ?.functional_field?.value == 1 ? (
                                                                <li>
                                                                    <label>Functional:</label>
                                                                    <p
                                                                        className={`m-0 ${
                                                                            selectedProduct.functional_field?.includes(
                                                                                'Yes',
                                                                            )
                                                                                ? 'text-success'
                                                                                : 'text-danger'
                                                                        }`}
                                                                    >
                                                                        {selectedProduct.functional_field ||
                                                                            '-'}
                                                                    </p>
                                                                </li>
                                                            ) : null}
                                                        </ul>
                                                        <ul>
                                                            {global.storeConfigration?.damaged_field
                                                                ?.value == 1 ? (
                                                                <li>
                                                                    <label>Damaged:</label>
                                                                    <p
                                                                        className={`m-0 ${
                                                                            selectedProduct.damaged_field?.includes(
                                                                                'No',
                                                                            )
                                                                                ? 'text-success'
                                                                                : 'text-danger'
                                                                        }`}
                                                                    >
                                                                        {selectedProduct.damaged_field ||
                                                                            '-'}
                                                                    </p>
                                                                </li>
                                                            ) : null}

                                                            {global.storeConfigration
                                                                ?.missing_parts_field?.value ==
                                                            1 ? (
                                                                <li>
                                                                    <label>Missing Parts:</label>
                                                                    <p
                                                                        className={`m-0 ${
                                                                            selectedProduct.missing_parts_field?.includes(
                                                                                'Yes',
                                                                            )
                                                                                ? 'text-danger'
                                                                                : 'text-success'
                                                                        }`}
                                                                    >
                                                                        {selectedProduct.missing_parts_field ||
                                                                            '-'}
                                                                    </p>
                                                                </li>
                                                            ) : null}
                                                        </ul>
                                                        <ul>
                                                            {selectedProduct.weight ? (
                                                                <li>
                                                                    <label>Weight:</label>
                                                                    <p className={`m-0`}>
                                                                        {selectedProduct.weight ||
                                                                            '-'}{' '}
                                                                        &nbsp; (
                                                                        {getunitsdetails(
                                                                            selectedProduct.custom_field_1,
                                                                        )}
                                                                        )
                                                                    </p>
                                                                </li>
                                                            ) : null}

                                                            {selectedProduct.width ? (
                                                                <li>
                                                                    <label>Width:</label>
                                                                    <p className={`m-0`}>
                                                                        {selectedProduct.width ||
                                                                            '-'}
                                                                        &nbsp; (
                                                                        {getdimensiondetails(
                                                                            selectedProduct.custom_field_2,
                                                                        )}
                                                                        )
                                                                    </p>
                                                                </li>
                                                            ) : null}
                                                        </ul>
                                                        <ul>
                                                            {selectedProduct.length ? (
                                                                <li>
                                                                    <label>Depth:</label>
                                                                    <p className={`m-0`}>
                                                                        {selectedProduct.length ||
                                                                            '-'}
                                                                        &nbsp; (
                                                                        {getdimensiondetails(
                                                                            selectedProduct.custom_field_2,
                                                                        )}
                                                                        )
                                                                    </p>
                                                                </li>
                                                            ) : null}

                                                            {selectedProduct.height ? (
                                                                <li>
                                                                    <label>Height:</label>
                                                                    <p className={`m-0`}>
                                                                        {selectedProduct.height ||
                                                                            '-'}
                                                                        &nbsp; (
                                                                        {getdimensiondetails(
                                                                            selectedProduct.custom_field_2,
                                                                        )}
                                                                        )
                                                                    </p>
                                                                </li>
                                                            ) : null}
                                                        </ul>
                                                    </div>
                                                )}
                                                {theme === 'charity' && (
                                                    <>
                                                        <div className="mssBidMisc">
                                                            <div className="mssBidInfo">
                                                                <h5>
                                                                    <span>Starting Date:</span>
                                                                    {moment(
                                                                        moment(
                                                                            selectedProduct.date_added,
                                                                        ).isValid()
                                                                            ? selectedProduct.date_added
                                                                            : oneYear,
                                                                    ).format(
                                                                        'MMM Do YYYY, h:mm:ss a',
                                                                    )}
                                                                </h5>
                                                            </div>
                                                        </div>
                                                        <div className="mssBidMisc">
                                                            <div className="mssBidInfo">
                                                                <h5>
                                                                    <span>Closing Date:</span>
                                                                    {moment(
                                                                        moment(
                                                                            selectedProduct.date_closed,
                                                                        ).isValid()
                                                                            ? selectedProduct.date_closed
                                                                            : oneYear,
                                                                    ).format(
                                                                        'MMM Do YYYY, h:mm:ss a',
                                                                    )}
                                                                </h5>
                                                            </div>
                                                        </div>
                                                        {type !== 'buynow' && (
                                                            <>
                                                                <div className="mssBidMisc">
                                                                    <div className="mssBidInfo">
                                                                        <span>Total Bids: </span>
                                                                        <a
                                                                            className="tb-link"
                                                                            onClick={() =>
                                                                                selectedProduct.bids &&
                                                                                selectedProduct.bids !==
                                                                                    0 &&
                                                                                !selectedProduct.is_past &&
                                                                                setHistoryData({
                                                                                    product_id:
                                                                                        selectedProduct.id,
                                                                                    end: moment(
                                                                                        selectedProduct.date_closed,
                                                                                    ).isValid()
                                                                                        ? selectedProduct.date_closed
                                                                                        : oneYear,
                                                                                })
                                                                            }
                                                                        >
                                                                            {selectedProduct.bids
                                                                                ? selectedProduct.bids
                                                                                : 0}
                                                                        </a>
                                                                    </div>
                                                                </div>
                                                            </>
                                                        )}
                                                    </>
                                                )}
                                            </div>
                                        </>
                                    )}
                                    {storeTheme !== 'ArtAuction' && (
                                        <>
                                            {[
                                                ...(global.storeDetails?.theme == 5
                                                    ? new_lotInformation
                                                    : lotInformation),
                                            ].map((data, index) => (
                                                <>
                                                    {data.description && data.display_need == 1 ? (
                                                        <div key={index} className={`mssDesc`}>
                                                            <h6>{data.title}</h6>
                                                            {data.title === 'Description' ? (
                                                                <div
                                                                    className={`shwMrBtnWrp myWrapping d-flex align-items-start justify-content-between`}
                                                                >
                                                                    <p
                                                                        className={`mb-1 pdtDscrpnVlue ${
                                                                            showDescription
                                                                                ? ''
                                                                                : 'hide'
                                                                        }`}
                                                                        dangerouslySetInnerHTML={{
                                                                            __html: data.description,
                                                                        }}
                                                                    ></p>
                                                                    <span
                                                                        className="mb-3 cursorDecoy shwMrBtn"
                                                                        onClick={() =>
                                                                            setShowDescription(
                                                                                !showDescription,
                                                                            )
                                                                        }
                                                                    >
                                                                        <b>
                                                                            <u>
                                                                                Show{' '}
                                                                                {showDescription
                                                                                    ? 'Less'
                                                                                    : 'More'}
                                                                            </u>
                                                                        </b>
                                                                    </span>
                                                                </div>
                                                            ) : data.title == 'Condition Report' &&
                                                              global.storeConfigration
                                                                  ?.hyper_micro_popup_more?.value ==
                                                                  1 &&
                                                              global?.condition_data?.find(
                                                                  (val) =>
                                                                      val.condition ==
                                                                      data.description,
                                                              )?.content ? (
                                                                <div
                                                                    className={`shwMrBtnWrp d-flex align-items-center justify-content-start `}
                                                                >
                                                                    <>
                                                                        <p
                                                                            className={`my-1 pdtDscrpnVlue mr-1 ${
                                                                                data.description
                                                                                    ?.trim()
                                                                                    ?.toLowerCase()
                                                                                    ?.includes(
                                                                                        'new',
                                                                                    )
                                                                                    ? 'badge badge-success text-white'
                                                                                    : data.description
                                                                                          ?.trim()
                                                                                          ?.toLowerCase()
                                                                                          ?.includes(
                                                                                              'open box',
                                                                                          )
                                                                                    ? 'badge badge-primary text-white'
                                                                                    : data.description
                                                                                          ?.trim()
                                                                                          ?.toLowerCase()
                                                                                          ?.includes(
                                                                                              'damage',
                                                                                          )
                                                                                    ? 'badge badge-danger text-white'
                                                                                    : ''
                                                                            }`}
                                                                            dangerouslySetInnerHTML={{
                                                                                __html: data.description,
                                                                            }}
                                                                        ></p>
                                                                        <Tooltip
                                                                            title={
                                                                                global?.condition_data?.find(
                                                                                    (val) =>
                                                                                        val.condition ==
                                                                                        data.description,
                                                                                )?.content
                                                                            }
                                                                            arrow
                                                                            enterTouchDelay={0}
                                                                            placement="top-start"
                                                                            className="tooling"
                                                                        >
                                                                            <span>i</span>
                                                                        </Tooltip>
                                                                    </>
                                                                </div>
                                                            ) : data.title == 'Pickup Details' &&
                                                              global.pluginConfiguration
                                                                  ?.limit_pickup_days?.enable &&
                                                              data.description == 'Pickup' ? (
                                                                <div>
                                                                    <Button
                                                                        onClick={(e) =>
                                                                            setOpenpickup('open')
                                                                        }
                                                                        className="rotateImage"
                                                                    >
                                                                        <span className="material-icons">
                                                                            info
                                                                        </span>
                                                                    </Button>
                                                                    Pickup
                                                                </div>
                                                            ) : (
                                                                <div
                                                                    className={`shwMrBtnWrp d-flex align-items-start justify-content-between `}
                                                                >
                                                                    <p
                                                                        className="mb-1 pdtDscrpnVlue"
                                                                        dangerouslySetInnerHTML={{
                                                                            __html: data.description,
                                                                        }}
                                                                    ></p>
                                                                </div>
                                                            )}
                                                        </div>
                                                    ) : null}
                                                </>
                                            ))}
                                            {(selectedProduct.auction == 1 &&
                                                selectedProduct.buynow == 0) ||
                                            selectedProduct.auction == 1 ? (
                                                <div className="mssTimer">
                                                    {selectedProduct.market_status != 'draft' ? (
                                                        (selectedProduct.market_status != 'open' &&
                                                            selectedProduct.market_status !=
                                                                'future') ||
                                                        selectedProduct.productClosed ? (
                                                            ''
                                                        ) : selectedProduct.extend ||
                                                          selectedProduct.agent_id ? (
                                                            <span className="tmm">
                                                                <Timer
                                                                    date_added={
                                                                        selectedProduct.date_added
                                                                    }
                                                                    date_closed={
                                                                        selectedProduct.date_closed
                                                                    }
                                                                    withText={1}
                                                                    endText={'Ends in' + ':'}
                                                                    startText={'Starts in' + ':'}
                                                                    // theme="multiseller"
                                                                ></Timer>
                                                            </span>
                                                        ) : (
                                                            <span>
                                                                <Timer
                                                                    date_added={
                                                                        selectedProduct.date_added
                                                                    }
                                                                    date_closed={
                                                                        selectedProduct.date_closed
                                                                    }
                                                                    withText={1}
                                                                    endText={'Ends in' + ':'}
                                                                    startText={'Starts in' + ':'}
                                                                    // theme="multiseller"
                                                                ></Timer>
                                                            </span>
                                                        )
                                                    ) : (
                                                        ''
                                                    )}
                                                </div>
                                            ) : (
                                                ''
                                            )}
                                        </>
                                    )}
                                    {global.storeConfigration?.fishing_theme?.value == 1 ? (
                                        <div className="additionalDesc">
                                            <h5> {selectedProduct.extra_heading?.heading}</h5>
                                            {selectedProduct.extra?.map((val) => (
                                                <p>
                                                    {val.heading}: <span>{val.value}</span>
                                                </p>
                                            ))}
                                        </div>
                                    ) : null}
                                    {storeTheme === 'Liquidation_two' ? (
                                        <>
                                            {selectedProduct.retailer_link &&
                                            selectedProduct.mprice ? (
                                                <h6 className="galleryPrice rtlrPrice">
                                                    <span>
                                                        Retail Price:{' '}
                                                        <a
                                                            href={selectedProduct.retailer_link}
                                                            target="_blank"
                                                            rel="noreferrer"
                                                        >
                                                            (Retailer)
                                                        </a>
                                                    </span>
                                                    &nbsp;
                                                    <span className="prcTxtVal">
                                                        {currencyFormat(
                                                            parseFloat(
                                                                selectedProduct.mprice || 0,
                                                            ).toFixed(2),
                                                        )}
                                                        <CurrencyConverterFormat
                                                            amount={selectedProduct.mprice}
                                                        />
                                                    </span>
                                                </h6>
                                            ) : (
                                                <h6 className="galleryPrice rtlrPrice"></h6>
                                            )}
                                        </>
                                    ) : (
                                        <></>
                                    )}
                                    <div className="mssBidItem">
                                        <div className="mssBidMisc buyNowBidMisc">
                                            {selectedProduct.market_status != 'draft' ? (
                                                <>
                                                    {selectedProduct.auction &&
                                                    type !== 'buynow' ? (
                                                        <Button
                                                            className="mssBidHistory"
                                                            onClick={() =>
                                                                // selectedProduct.bids &&
                                                                // selectedProduct.bids !== 0 &&
                                                                // !selectedProduct.is_past &&
                                                                setHistoryData({
                                                                    product_id: selectedProduct.id,
                                                                    end: selectedProduct.date_closed,
                                                                })
                                                            }
                                                        >
                                                            <span className="material-icons">
                                                                history
                                                            </span>
                                                            {t('view_bid_history')}
                                                        </Button>
                                                    ) : (
                                                        ''
                                                    )}
                                                </>
                                            ) : (
                                                ''
                                            )}
                                            {parseInt(selectedProduct.buynow) == 1 &&
                                            parseInt(selectedProduct.auction) == 1 ? (
                                                <>
                                                    {storeTheme !== 'ArtAuction' && (
                                                        <div className="mssBidInfo">
                                                            <h5>
                                                                <span>Buy Now Price</span>
                                                                {currencyFormat(
                                                                    parseFloat(
                                                                        selectedProduct.bprice,
                                                                    ).toFixed(2),
                                                                    currency,
                                                                )}
                                                                <CurrencyConverterFormat
                                                                    amount={selectedProduct.bprice}
                                                                />
                                                            </h5>
                                                        </div>
                                                    )}
                                                    <div className="mssBidInfo">
                                                        {selectedProduct.latestbid ? (
                                                            <h5>
                                                                <span>
                                                                    {selectedProduct.market_status ==
                                                                    'sold'
                                                                        ? 'Sold Bid'
                                                                        : t('current_bid')}
                                                                </span>
                                                                <span className="crncyValue">
                                                                    {currencyFormat(
                                                                        selectedProduct.wprice,
                                                                        currency,
                                                                    )}
                                                                </span>
                                                                <span className="crncyValue">
                                                                    <CurrencyConverterFormat
                                                                        amount={
                                                                            selectedProduct.wprice
                                                                        }
                                                                    />
                                                                </span>
                                                            </h5>
                                                        ) : (
                                                            <h5>
                                                                {selectedProduct.market_status ==
                                                                'closed' ? (
                                                                    <span>Unsold</span>
                                                                ) : (
                                                                    <>
                                                                        <span>Start Bid</span>
                                                                        <span className="crncyValue">
                                                                            {currencyFormat(
                                                                                selectedProduct.sprice,
                                                                                currency,
                                                                            )}
                                                                        </span>
                                                                        <span className="crncyValue">
                                                                            <CurrencyConverterFormat
                                                                                amount={
                                                                                    selectedProduct.sprice
                                                                                }
                                                                            />
                                                                        </span>
                                                                    </>
                                                                )}
                                                            </h5>
                                                        )}
                                                        {/* <h5>
                                                            <span>Minimum Bid</span>
                                                            {currencyFormat(selectedProduct.next_bid)}
                                                        </h5> */}
                                                    </div>
                                                </>
                                            ) : type === 'buynow' ||
                                              parseInt(selectedProduct.buynow) == 1 ? (
                                                <div className="mssBidInfo">
                                                    <h5>
                                                        <span>Buy Now Price</span>
                                                        {currencyFormat(
                                                            parseFloat(
                                                                selectedProduct.bprice,
                                                            ).toFixed(2),
                                                            currency,
                                                        )}
                                                        <span>
                                                            <CurrencyConverterFormat
                                                                amount={selectedProduct.bprice}
                                                            />
                                                        </span>
                                                    </h5>
                                                </div>
                                            ) : (
                                                <div className="mssBidInfo">
                                                    {selectedProduct.latestbid ? (
                                                        <h5>
                                                            <span>
                                                                {selectedProduct.market_status ==
                                                                'sold'
                                                                    ? 'Sold Bid'
                                                                    : t('current_bid')}
                                                            </span>
                                                            <span className="crncyValue mainCurrency">
                                                                {currencyFormat(
                                                                    selectedProduct.wprice,
                                                                    currency,
                                                                )}
                                                            </span>
                                                            <span className="crncyValue makemycurrency">
                                                                <CurrencyConverterFormat
                                                                    amount={selectedProduct.wprice}
                                                                />
                                                            </span>
                                                        </h5>
                                                    ) : (
                                                        <h5>
                                                            {selectedProduct.market_status ==
                                                            'closed' ? (
                                                                <span>Unsold</span>
                                                            ) : (
                                                                <>
                                                                    <span>{t('start_bid')}</span>
                                                                    <span className="crncyValue mainCurrency">
                                                                        {currencyFormat(
                                                                            selectedProduct.sprice,
                                                                            currency,
                                                                        )}
                                                                    </span>
                                                                    <span className="crncyValue makemycurrency">
                                                                        <CurrencyConverterFormat
                                                                            amount={
                                                                                selectedProduct.sprice
                                                                            }
                                                                        />
                                                                    </span>
                                                                </>
                                                            )}
                                                        </h5>
                                                    )}
                                                    {/* {selectedProduct.user_id == user?.id &&
                                                                global.storeConfigration?.listing_fee
                                                                    ?.value && (
                                                                    <h5>
                                                                        <span style={{ color: 'red' }}>
                                                                            Listing Fee
                                                                        </span>
                                                                        <span
                                                                            className="crncyValue"
                                                                            style={{ color: 'red' }}
                                                                        >
                                                                            {currencyFormat(
                                                                                global.storeConfigration
                                                                                    ?.listing_fee?.value,
                                                                                currency,
                                                                            )}
                                                                        </span>
                                                                    </h5>
                                                                )} */}
                                                    {/* <h5>
                                                            <span>Minimum Bid</span>
                                                            {currencyFormat(selectedProduct.next_bid)}
                                                        </h5> */}
                                                </div>
                                            )}
                                        </div>
                                        {selectedProduct.user_id == user?.id &&
                                            global.storeConfigration?.listing_fee?.value && (
                                                <h5>
                                                    <span style={{ color: 'red' }}>
                                                        Listing Fee
                                                    </span>
                                                    <span
                                                        className="crncyValue"
                                                        style={{ color: 'red' }}
                                                    >
                                                        {currencyFormat(
                                                            global.storeConfigration?.listing_fee
                                                                ?.value,
                                                            currency,
                                                        )}
                                                    </span>
                                                </h5>
                                            )}
                                        {/* {selectedProduct.market_status != 'draft' &&
                                                selectedProduct.width ? ( */}
                                        {/* // <ShippingRates */}
                                        {/* //     width={selectedProduct.width}
                                                    //     height={selectedProduct.height}
                                                    //     length={selectedProduct.length}
                                                    //     weight={selectedProduct.weight}
                                                    //     fromzipcode={selectedProduct.zipcode}
                                                    // />
                                                // ) : null} */}

                                        {/*Shipping Plugin Added Flow Start*/}

                                        {parseInt(selectedProduct.hasshipping) &&
                                        global.pluginConfiguration?.shipping?.enable &&
                                        isAuthenticated &&
                                        !parseInt(selectedProduct.shippingcost) > 0 ? (
                                            <div className="pv-con">
                                                <p>
                                                    {t('ship_update_info')}. {t('update')}{' '}
                                                    <Link to="/dashboard/profile">{t('here')}</Link>
                                                </p>
                                                {shipping_amount ? (
                                                    <>
                                                        {/* <div className="pv-con"> */}
                                                        <span>{t('shipping_amount')}</span>

                                                        {currencyFormat(
                                                            parseFloat(shipping_amount).toFixed(2),
                                                        )}
                                                        <span className="crncyValue">
                                                            <CurrencyConverterFormat
                                                                amount={shipping_amount}
                                                            />
                                                        </span>
                                                        {/* </div> */}
                                                    </>
                                                ) : (
                                                    <>
                                                        {selectedProduct.weight != 0 &&
                                                        selectedProduct.height != 0 &&
                                                        selectedProduct.length != 0 &&
                                                        selectedProduct.width != 0 ? (
                                                            <PrimaryButton
                                                                label={t('calculate_shippin')}
                                                                btnSize="small"
                                                                disabled={calc_loading}
                                                                onClick={() => {
                                                                    setCalcLoading(true)
                                                                    calculate_shipstation_amount()
                                                                }}
                                                            />
                                                        ) : (
                                                            ''
                                                        )}
                                                    </>
                                                )}
                                            </div>
                                        ) : (
                                            <>
                                                {parseInt(selectedProduct.shippingcost) > 0 ? (
                                                    <>
                                                        {/* <div className="pv-con"> */}
                                                        <span>{t('shipping_amount') + ': '}</span>

                                                        {currencyFormat(
                                                            parseFloat(
                                                                selectedProduct.shippingcost,
                                                            ).toFixed(2),
                                                        )}
                                                        <span className="crncyValue">
                                                            <CurrencyConverterFormat
                                                                amount={
                                                                    selectedProduct.shippingcost
                                                                }
                                                            />
                                                        </span>
                                                        {/* </div> */}
                                                    </>
                                                ) : (
                                                    ''
                                                )}
                                            </>
                                        )}
                                        {global.storeConfigration?.more_details_page?.value == 1 ? (
                                            <button
                                                onClick={() => setShowpopup(true)}
                                                type="button"
                                                className="more-details"
                                            >
                                                More Details
                                            </button>
                                        ) : (
                                            ''
                                        )}

                                        {/*Shipping Plugin Added Flow End*/}

                                        {selectedProduct.market_status != 'draft' &&
                                        selectedProduct.market_status != 'future' &&
                                        global.storeDetails.theme != 9 ? (
                                            <>
                                                {(selectedProduct.market_status != 'open' ||
                                                    selectedProduct.productClosed ||
                                                    (selectedProduct.date_closed &&
                                                        moment(
                                                            selectedProduct.date_closed,
                                                        ).isValid() &&
                                                        auctionTimer &&
                                                        moment(auctionTimer).isValid() &&
                                                        new Date(
                                                            selectedProduct.date_closed,
                                                        ).getTime() -
                                                            new Date(auctionTimer).getTime() <
                                                            0)) &&
                                                parseInt(selectedProduct.buynow) == 0 ? (
                                                    <b>Auction Closed</b>
                                                ) : (
                                                    <div
                                                        className={`sl-pbids slidView ${
                                                            (!isAuthenticated ||
                                                                selectedProduct?.buynow) &&
                                                            'notLogged'
                                                        }`}
                                                    >
                                                        {isAuthenticated ? (
                                                            <>
                                                                {selectedProduct.auction ? (
                                                                    type === 'buynow' ? null : (
                                                                        <Fragment>
                                                                            {selectedProduct?.user_id ==
                                                                            user?.id ? (
                                                                                <p>
                                                                                    {t(
                                                                                        'do_not_bid',
                                                                                    )}
                                                                                </p>
                                                                            ) : (
                                                                                <>
                                                                                    {global
                                                                                        .storeConfigration
                                                                                        ?.hard_bid
                                                                                        ?.value !=
                                                                                        '0' && (
                                                                                        <div className="bidDet">
                                                                                            {storeTheme !==
                                                                                                'ArtAuction' && (
                                                                                                <p className="slidViewBtnLabel">
                                                                                                    <span className="material-icons">
                                                                                                        info
                                                                                                    </span>
                                                                                                    Hard
                                                                                                    Bid
                                                                                                    -
                                                                                                    Bid
                                                                                                    on
                                                                                                    the
                                                                                                    current
                                                                                                    price.
                                                                                                </p>
                                                                                            )}
                                                                                            {global
                                                                                                .pluginConfiguration
                                                                                                ?.reverse_auction
                                                                                                ?.enable ? (
                                                                                                <Biddingforwardandreverse
                                                                                                    data={
                                                                                                        selectedProduct
                                                                                                    }
                                                                                                    hard="1"
                                                                                                />
                                                                                            ) : (
                                                                                                <>
                                                                                                    <Bidding
                                                                                                        data={
                                                                                                            selectedProduct
                                                                                                        }
                                                                                                        hard="1"
                                                                                                        inputSize={
                                                                                                            storeTheme ===
                                                                                                            'ArtAuction'
                                                                                                                ? 'small'
                                                                                                                : 'small'
                                                                                                        }
                                                                                                        // enableNextBid={
                                                                                                        //     (storeTheme ===
                                                                                                        //     'ArtAuction'
                                                                                                        //         ? true
                                                                                                        //         : false) ||
                                                                                                        //     enableNextBid
                                                                                                        // }
                                                                                                    />
                                                                                                    {artTheme && (
                                                                                                        <>
                                                                                                            <p className="bidderInfo">
                                                                                                                {t(
                                                                                                                    'bid',
                                                                                                                )}{' '}
                                                                                                                $
                                                                                                                {selectedProduct?.next_bid
                                                                                                                    ? selectedProduct?.next_bid
                                                                                                                    : '-'}
                                                                                                                &nbsp;
                                                                                                                {selectedProduct?.next_bid ? (
                                                                                                                    <CurrencyConverterFormat
                                                                                                                        amount={
                                                                                                                            selectedProduct?.next_bid
                                                                                                                                ? selectedProduct?.next_bid
                                                                                                                                : 0
                                                                                                                        }
                                                                                                                    />
                                                                                                                ) : (
                                                                                                                    ''
                                                                                                                )}
                                                                                                                {t(
                                                                                                                    'or_more',
                                                                                                                )}
                                                                                                            </p>
                                                                                                            {/* <div className="ArtpaymentOption">
                                                                                                                        <div className="Tit">
                                                                                                                            Payments
                                                                                                                            :{' '}
                                                                                                                        </div>
                                                                                                                        <div className="types">
                                                                                                                            <span>
                                                                                                                                Credit
                                                                                                                                Cards,
                                                                                                                            </span>
                                                                                                                            <span>
                                                                                                                                PayPal
                                                                                                                            </span>
                                                                                                                        </div>
                                                                                                                    </div> */}
                                                                                                        </>
                                                                                                    )}
                                                                                                </>
                                                                                            )}
                                                                                        </div>
                                                                                    )}
                                                                                    {global
                                                                                        .storeConfigration
                                                                                        ?.proxy_bid
                                                                                        ?.value !=
                                                                                        0 && (
                                                                                        <div className="bidDet">
                                                                                            <p className="slidViewBtnLabel">
                                                                                                <span className="material-icons">
                                                                                                    info
                                                                                                </span>
                                                                                                Proxy
                                                                                                Bid
                                                                                                -
                                                                                                Enter
                                                                                                the
                                                                                                maximum
                                                                                                bid
                                                                                                you
                                                                                                would
                                                                                                like
                                                                                                to
                                                                                                automatically
                                                                                                bid
                                                                                                to.
                                                                                            </p>
                                                                                            {global
                                                                                                .pluginConfiguration
                                                                                                ?.reverse_auction
                                                                                                ?.enable ? (
                                                                                                <Biddingforwardandreverse
                                                                                                    data={
                                                                                                        selectedProduct
                                                                                                    }
                                                                                                    hard="0"
                                                                                                    status={
                                                                                                        1
                                                                                                    }
                                                                                                />
                                                                                            ) : (
                                                                                                <Bidding
                                                                                                    data={
                                                                                                        selectedProduct
                                                                                                    }
                                                                                                    hard="0"
                                                                                                    status={
                                                                                                        1
                                                                                                    }
                                                                                                />
                                                                                            )}
                                                                                        </div>
                                                                                    )}
                                                                                </>
                                                                            )}
                                                                        </Fragment>
                                                                    )
                                                                ) : null}

                                                                {(parseInt(
                                                                    selectedProduct.buynow,
                                                                ) == 1 &&
                                                                    parseInt(
                                                                        selectedProduct.auction,
                                                                    ) == 0) ||
                                                                (parseInt(selectedProduct.wprice) <=
                                                                    parseInt(
                                                                        selectedProduct.bprice,
                                                                    ) &&
                                                                    parseInt(
                                                                        selectedProduct.buynow,
                                                                    ) == 1 &&
                                                                    parseInt(
                                                                        selectedProduct.auction,
                                                                    ) == 1) ? (
                                                                    <>
                                                                        <div className="">
                                                                            {selectedProduct?.user_id ==
                                                                            user?.id ? (
                                                                                <p>
                                                                                    You cannot buy
                                                                                    your own
                                                                                    product!
                                                                                </p>
                                                                            ) : (
                                                                                <>
                                                                                    {itemDetails
                                                                                        ?.filter(
                                                                                            (i) =>
                                                                                                i.id ==
                                                                                                selectedLot,
                                                                                        )
                                                                                        .map(
                                                                                            (d) =>
                                                                                                d.buynowpaid,
                                                                                        ) == 1 ? (
                                                                                        <h5 className="itemPurchased">
                                                                                            You have
                                                                                            purchased
                                                                                            this
                                                                                            item
                                                                                        </h5>
                                                                                    ) : (
                                                                                        <>
                                                                                            {global
                                                                                                .pluginConfiguration
                                                                                                ?.make_an_offer
                                                                                                ?.enable &&
                                                                                            selectedProduct?.make_an_offer_io ? (
                                                                                                <div className="row">
                                                                                                    {mapData(
                                                                                                        make_an_offer,
                                                                                                    )}
                                                                                                    <div className="col-sm-6 col-12">
                                                                                                        <PrimaryButton
                                                                                                            onClick={(
                                                                                                                e,
                                                                                                            ) =>
                                                                                                                selectedProduct.market_status ==
                                                                                                                'open'
                                                                                                                    ? make_formik.handleSubmit()
                                                                                                                    : ''
                                                                                                            }
                                                                                                            disabled={
                                                                                                                selectedProduct.market_status !=
                                                                                                                'open'
                                                                                                            }
                                                                                                        >
                                                                                                            {selectedProduct.market_status ==
                                                                                                            'sold'
                                                                                                                ? 'Lot Sold'
                                                                                                                : selectedProduct.market_status ==
                                                                                                                      'unsold' ||
                                                                                                                  selectedProduct.market_status ==
                                                                                                                      'closed'
                                                                                                                ? 'Lot Closed'
                                                                                                                : `Submit Offer`}
                                                                                                        </PrimaryButton>
                                                                                                    </div>
                                                                                                </div>
                                                                                            ) : (
                                                                                                ''
                                                                                            )}

                                                                                            <PrimaryButton
                                                                                                onClick={
                                                                                                    selectedProduct.market_status ==
                                                                                                    'open'
                                                                                                        ? handleClick
                                                                                                        : ''
                                                                                                }
                                                                                                disabled={
                                                                                                    selectedProduct.market_status !=
                                                                                                    'open'
                                                                                                }
                                                                                            >
                                                                                                {selectedProduct.market_status ==
                                                                                                'sold'
                                                                                                    ? t(
                                                                                                          'lot_sold',
                                                                                                      )
                                                                                                    : selectedProduct.market_status ==
                                                                                                          'unsold' ||
                                                                                                      selectedProduct.market_status ==
                                                                                                          'closed'
                                                                                                    ? t(
                                                                                                          'lot_closed',
                                                                                                      )
                                                                                                    : disabled
                                                                                                    ? t(
                                                                                                          'remove_from_cart',
                                                                                                      )
                                                                                                    : `${t(
                                                                                                          'buy_now_for',
                                                                                                      )} ${currencyFormat(
                                                                                                          parseFloat(
                                                                                                              selectedProduct.bprice,
                                                                                                          ).toFixed(
                                                                                                              2,
                                                                                                          ),
                                                                                                          currency,
                                                                                                      )}`}
                                                                                            </PrimaryButton>
                                                                                        </>
                                                                                    )}
                                                                                </>
                                                                            )}
                                                                        </div>
                                                                        <br />
                                                                    </>
                                                                ) : null}
                                                            </>
                                                        ) : null}
                                                        {!isAuthenticated &&
                                                            selectedProduct.market_status !==
                                                                'sold' && (
                                                                <PrimaryButton
                                                                    className="my-2"
                                                                    onClick={() =>
                                                                        history.push('/login')
                                                                    }
                                                                >
                                                                    {selectedProduct?.buynow &&
                                                                    selectedProduct?.auction
                                                                        ? 'Login to Buy Or Bid'
                                                                        : selectedProduct?.buynow
                                                                        ? 'Login to Buy'
                                                                        : t('login_to_bid')}
                                                                </PrimaryButton>
                                                            )}
                                                    </div>
                                                )}
                                            </>
                                        ) : (
                                            ''
                                        )}

                                        {selectedProduct.market_status != 'draft' &&
                                        selectedProduct.market_status != 'future' &&
                                        global.storeDetails.theme == 9 ? (
                                            <>
                                                {(selectedProduct.market_status != 'open' ||
                                                    selectedProduct.productClosed ||
                                                    (selectedProduct.date_closed &&
                                                        moment(
                                                            selectedProduct.date_closed,
                                                        ).isValid() &&
                                                        auctionTimer &&
                                                        moment(auctionTimer).isValid() &&
                                                        new Date(
                                                            selectedProduct.date_closed,
                                                        ).getTime() -
                                                            new Date(auctionTimer).getTime() <
                                                            0)) &&
                                                parseInt(selectedProduct.buynow) == 0 ? (
                                                    <b>Auction Closed</b>
                                                ) : (
                                                    <div
                                                        className={`sl-pbids slidView ${
                                                            (!isAuthenticated ||
                                                                selectedProduct?.buynow) &&
                                                            'notLogged'
                                                        }`}
                                                    >
                                                        {isAuthenticated ? (
                                                            <>
                                                                {selectedProduct.auction ? (
                                                                    type === 'buynow' ? null : (
                                                                        <Fragment>
                                                                            {selectedProduct?.user_id ==
                                                                            user?.id ? (
                                                                                <p>
                                                                                    {t(
                                                                                        'do_not_bid',
                                                                                    )}
                                                                                </p>
                                                                            ) : (
                                                                                <>
                                                                                    {global
                                                                                        .storeConfigration
                                                                                        ?.hard_bid
                                                                                        ?.value && (
                                                                                        <div className="bidDet">
                                                                                            {parseInt(
                                                                                                selectedProduct.content_head5,
                                                                                            ) ==
                                                                                                0 && (
                                                                                                <p className="slidViewBtnLabel">
                                                                                                    <span className="material-icons">
                                                                                                        info
                                                                                                    </span>
                                                                                                    Hard
                                                                                                    Bid
                                                                                                    -
                                                                                                    Bid
                                                                                                    on
                                                                                                    the
                                                                                                    current
                                                                                                    price.
                                                                                                </p>
                                                                                            )}
                                                                                            {global
                                                                                                .pluginConfiguration
                                                                                                ?.reverse_auction
                                                                                                ?.enable ? (
                                                                                                <Biddingforwardandreverse
                                                                                                    data={
                                                                                                        selectedProduct
                                                                                                    }
                                                                                                    hard="1"
                                                                                                />
                                                                                            ) : (
                                                                                                <Bidding
                                                                                                    data={
                                                                                                        selectedProduct
                                                                                                    }
                                                                                                    hard="1"
                                                                                                    inputSize={
                                                                                                        storeTheme ===
                                                                                                        'ArtAuction'
                                                                                                            ? 'large'
                                                                                                            : 'small'
                                                                                                    }
                                                                                                    enableNextBid={
                                                                                                        storeTheme ===
                                                                                                        'ArtAuction'
                                                                                                            ? true
                                                                                                            : false
                                                                                                    }
                                                                                                />
                                                                                            )}
                                                                                        </div>
                                                                                    )}
                                                                                    {global
                                                                                        .storeConfigration
                                                                                        ?.proxy_bid
                                                                                        ?.value !=
                                                                                        0 &&
                                                                                        parseInt(
                                                                                            selectedProduct.content_head5,
                                                                                        ) == 0 && (
                                                                                            <div className="bidDet">
                                                                                                <p className="slidViewBtnLabel">
                                                                                                    <span className="material-icons">
                                                                                                        info
                                                                                                    </span>
                                                                                                    Proxy
                                                                                                    Bid
                                                                                                    -
                                                                                                    Enter
                                                                                                    the
                                                                                                    maximum
                                                                                                    bid
                                                                                                    you
                                                                                                    would
                                                                                                    like
                                                                                                    to
                                                                                                    automatically
                                                                                                    bid
                                                                                                    to.
                                                                                                </p>
                                                                                                {global
                                                                                                    .pluginConfiguration
                                                                                                    ?.reverse_auction
                                                                                                    ?.enable ? (
                                                                                                    <Biddingforwardandreverse
                                                                                                        data={
                                                                                                            selectedProduct
                                                                                                        }
                                                                                                        hard="0"
                                                                                                        status={
                                                                                                            1
                                                                                                        }
                                                                                                    />
                                                                                                ) : (
                                                                                                    <Bidding
                                                                                                        data={
                                                                                                            selectedProduct
                                                                                                        }
                                                                                                        hard="0"
                                                                                                        status={
                                                                                                            1
                                                                                                        }
                                                                                                    />
                                                                                                )}
                                                                                            </div>
                                                                                        )}
                                                                                </>
                                                                            )}
                                                                        </Fragment>
                                                                    )
                                                                ) : null}

                                                                {storeTheme !== 'ArtAuction' && (
                                                                    <>
                                                                        {(parseInt(
                                                                            selectedProduct.buynow,
                                                                        ) == 1 &&
                                                                            parseInt(
                                                                                selectedProduct.auction,
                                                                            ) == 0) ||
                                                                        (parseInt(
                                                                            selectedProduct.wprice,
                                                                        ) <=
                                                                            parseInt(
                                                                                selectedProduct.bprice,
                                                                            ) &&
                                                                            parseInt(
                                                                                selectedProduct.buynow,
                                                                            ) == 1 &&
                                                                            parseInt(
                                                                                selectedProduct.auction,
                                                                            ) == 1) ? (
                                                                            <>
                                                                                <div className="">
                                                                                    {selectedProduct?.user_id ==
                                                                                    user?.id ? (
                                                                                        <p>
                                                                                            You
                                                                                            cannot
                                                                                            buy your
                                                                                            own
                                                                                            product!
                                                                                        </p>
                                                                                    ) : (
                                                                                        <>
                                                                                            {itemDetails
                                                                                                ?.filter(
                                                                                                    (
                                                                                                        i,
                                                                                                    ) =>
                                                                                                        i.id ==
                                                                                                        selectedLot,
                                                                                                )
                                                                                                .map(
                                                                                                    (
                                                                                                        d,
                                                                                                    ) =>
                                                                                                        d.buynowpaid,
                                                                                                ) ==
                                                                                            1 ? (
                                                                                                <h5 className="itemPurchased">
                                                                                                    You
                                                                                                    have
                                                                                                    purchased
                                                                                                    this
                                                                                                    item
                                                                                                </h5>
                                                                                            ) : (
                                                                                                <PrimaryButton
                                                                                                    onClick={
                                                                                                        selectedProduct.market_status ==
                                                                                                        'open'
                                                                                                            ? handleClick
                                                                                                            : ''
                                                                                                    }
                                                                                                    disabled={
                                                                                                        selectedProduct.market_status !=
                                                                                                        'open'
                                                                                                            ? true
                                                                                                            : disabled
                                                                                                    }
                                                                                                >
                                                                                                    {selectedProduct.market_status ==
                                                                                                    'sold'
                                                                                                        ? 'Lot Sold'
                                                                                                        : selectedProduct.market_status ==
                                                                                                              'unsold' ||
                                                                                                          selectedProduct.market_status ==
                                                                                                              'closed'
                                                                                                        ? 'Lot Closed'
                                                                                                        : disabled
                                                                                                        ? 'Added to cart'
                                                                                                        : `Buy now for ${currencyFormat(
                                                                                                              parseFloat(
                                                                                                                  selectedProduct.bprice,
                                                                                                              ).toFixed(
                                                                                                                  2,
                                                                                                              ),
                                                                                                              currency,
                                                                                                          )}`}
                                                                                                </PrimaryButton>
                                                                                            )}
                                                                                        </>
                                                                                    )}
                                                                                </div>
                                                                                {storeTheme !==
                                                                                    'ArtAuction' && (
                                                                                    <br />
                                                                                )}
                                                                            </>
                                                                        ) : null}
                                                                    </>
                                                                )}
                                                            </>
                                                        ) : null}
                                                        {!isAuthenticated && (
                                                            <PrimaryButton
                                                                className="my-2"
                                                                onClick={() =>
                                                                    history.push('/login')
                                                                }
                                                            >
                                                                Login to{' '}
                                                                {selectedProduct?.buynow &&
                                                                selectedProduct?.auction
                                                                    ? 'Buy Or Bid'
                                                                    : selectedProduct?.buynow
                                                                    ? 'Buy'
                                                                    : 'Bid'}
                                                            </PrimaryButton>
                                                        )}
                                                    </div>
                                                )}
                                            </>
                                        ) : (
                                            ''
                                        )}
                                    </div>
                                    {artTheme && (
                                        <div className="followUp">
                                            <div className="avatharInfo">
                                                <div className="userCrd">
                                                    <figure className="userPic m-0">
                                                        <img
                                                            src={selectedProduct.avatar}
                                                            onError={(e) =>
                                                                (e.target.src = imageError())
                                                            }
                                                        />
                                                    </figure>
                                                    <div className="userIdenty">
                                                        <h4
                                                            className="userTit cursorDecoy"
                                                            onClick={() =>
                                                                history.push(
                                                                    `/bio/${selectedProduct.user_id}`,
                                                                )
                                                            }
                                                        >
                                                            {selectedProduct.first_name}
                                                            {selectedProduct.last_name}
                                                        </h4>
                                                        <p className="usrposi">
                                                            {selectedProduct.company_name}
                                                        </p>
                                                    </div>
                                                </div>
                                                {followornot == 0 ? (
                                                    <PrimaryButton
                                                        onClick={() =>
                                                            userFollow(1, selectedProduct.user_id)
                                                        }
                                                        className="followUp"
                                                    >
                                                        <span className="material-icons mr-2">
                                                            remove
                                                        </span>
                                                        {t('unfollow')}
                                                    </PrimaryButton>
                                                ) : (
                                                    <PrimaryButton
                                                        onClick={() =>
                                                            userFollow(0, selectedProduct.user_id)
                                                        }
                                                        className="followUp"
                                                    >
                                                        <span className="material-icons mr-2">
                                                            add
                                                        </span>
                                                        {t('follow')}
                                                    </PrimaryButton>
                                                )}
                                            </div>
                                            <div className="avatharIntractive sd">
                                                {isAuthenticated && (
                                                    <>
                                                        {selectedProduct.market_status == 'open' ? (
                                                            <PrimaryButton className="">
                                                                <FavouriteCheckbox
                                                                    watchlisted={
                                                                        selectedProduct.wlistpop
                                                                    }
                                                                    data={selectedProduct}
                                                                    project_id={selectedProduct.id}
                                                                    from={from}
                                                                    getAllProducts={getAllProducts}
                                                                    setAddFav={setFav}
                                                                    setSelectedProduct={
                                                                        setSelectedProduct
                                                                    }
                                                                />

                                                                {addfav
                                                                    ? t('remove_from_favorites')
                                                                    : t('add_to_favorites')}
                                                            </PrimaryButton>
                                                        ) : null}
                                                    </>
                                                )}
                                                <SecondaryButton
                                                    aria-describedby={id}
                                                    onClick={SocialShareClick}
                                                >
                                                    <span className="material-icons mr-2">
                                                        share
                                                    </span>
                                                    {t('share')}
                                                </SecondaryButton>
                                                <Popover
                                                    id={popoverid}
                                                    open={openSocialShare}
                                                    anchorEl={openSocialShare}
                                                    onClose={SocialShareClock}
                                                    anchorOrigin={{
                                                        vertical: 'bottom',
                                                        horizontal: 'center',
                                                    }}
                                                    transformOrigin={{
                                                        vertical: 'top',
                                                        horizontal: 'center',
                                                    }}
                                                >
                                                    {/* <MenuItem onClick={handleClose}>
                                                                FaceBook
                                                            </MenuItem>
                                                            <MenuItem onClick={handleClose}>
                                                                My account
                                                            </MenuItem>
                                                            <MenuItem onClick={handleClose}>
                                                                Logout
                                                            </MenuItem> */}
                                                    <Facebook
                                                        path={`/?product=${selectedProduct.id}`}
                                                        title={selectedProduct.title}
                                                    >
                                                        Facebook
                                                    </Facebook>
                                                    <Whatsapp
                                                        path={`/?product=${selectedProduct.id}`}
                                                        title={selectedProduct.title}
                                                    />
                                                    {global.storeConfigration
                                                        ?.disable_twitter_sharing?.value != 1 ? (
                                                        <Twitter
                                                            path={`/?product=${selectedProduct.id}`}
                                                            title={selectedProduct.title}
                                                        />
                                                    ) : null}
                                                    <Email
                                                        path={`/?product=${selectedProduct.id}`}
                                                        subject={selectedProduct.title}
                                                        body={selectedProduct.description}
                                                    />
                                                    {global.storeConfigration
                                                        ?.disable_pinterest_sharing?.value != 1 ? (
                                                        <Pinterest
                                                            path={`/?product=${selectedProduct.id}`}
                                                            title={selectedProduct.title}
                                                            media={
                                                                `${
                                                                    selectedProduct.content_head1 ===
                                                                    '0'
                                                                        ? process.env
                                                                              .REACT_APP_BASE_URL +
                                                                          'uploads/product/'
                                                                        : process.env
                                                                              .REACT_APP_PRODUCT_IMAGE_URL
                                                                }` + selectedProduct.avatar
                                                            }
                                                        />
                                                    ) : null}
                                                    <div
                                                        className="cpyToClipDiv"
                                                        style={{ cursor: 'pointer' }}
                                                    >
                                                        <Copy
                                                            setAlert={setAlert}
                                                            text={`/${
                                                                storeTheme == 'Liquidation_two'
                                                                    ? 'search'
                                                                    : ''
                                                            }?product=${selectedProduct.id}`}
                                                        />
                                                    </div>
                                                </Popover>
                                            </div>
                                        </div>
                                    )}
                                    {storeTheme === 'ArtAuction' && (
                                        <>
                                            {artauctionthemeinfo.map((data, index) => (
                                                <>
                                                    {data.display_need == 1 ? (
                                                        <Accordion
                                                            square
                                                            expanded={expanded === `panel${index}`}
                                                            key={1}
                                                            onChange={handleChange(`panel${index}`)}
                                                        >
                                                            <AccordionSummary
                                                                expandIcon={<ExpandMoreIcon />}
                                                                aria-controls={`panel${index}}d-content`}
                                                                id={`panel${index}}d-header`}
                                                            >
                                                                <h6 className="accTitle">
                                                                    {data.title}
                                                                </h6>
                                                            </AccordionSummary>
                                                            <AccordionDetails>
                                                                <p
                                                                    className="dscrpnCtnrArt"
                                                                    dangerouslySetInnerHTML={{
                                                                        __html: data.description,
                                                                    }}
                                                                ></p>
                                                            </AccordionDetails>
                                                        </Accordion>
                                                    ) : (
                                                        ''
                                                    )}
                                                </>
                                            ))}
                                        </>
                                    )}
                                    {global?.storeConfigration?.hyper_micro_popup_more?.value ==
                                        1 && (
                                        <>
                                            {more_infor_popup_slider.map((data, index) => (
                                                <>
                                                    {data.display_need == 1 ? (
                                                        <Accordion
                                                            square
                                                            expanded={expanded === `panel${index}`}
                                                            key={1}
                                                            onChange={handleChange(`panel${index}`)}
                                                        >
                                                            <AccordionSummary
                                                                expandIcon={<ExpandMoreIcon />}
                                                                aria-controls={`panel${index}}d-content`}
                                                                id={`panel${index}}d-header`}
                                                            >
                                                                <h6 className="accTitle">
                                                                    {data.title}
                                                                </h6>
                                                            </AccordionSummary>
                                                            <AccordionDetails>
                                                                <p
                                                                    className="dscrpnCtnrArt"
                                                                    dangerouslySetInnerHTML={{
                                                                        __html: data.description,
                                                                    }}
                                                                ></p>
                                                            </AccordionDetails>
                                                        </Accordion>
                                                    ) : (
                                                        ''
                                                    )}
                                                </>
                                            ))}
                                        </>
                                    )}
                                    {(storeTheme === 'foodindustry' ||
                                        storeTheme === 'LiquidationPallet') && (
                                        <>
                                            <div>
                                                <p
                                                    className="howBidLink"
                                                    onClick={() => setHowBidPopup(true)}
                                                >
                                                    How Does Bidding Work?
                                                </p>
                                            </div>
                                            <div className="d-flex justify-content-between align-items-center pvTitleContainer flex-wrap">
                                                <div
                                                    className={selectedProduct?.buynow && 'd-none'}
                                                >
                                                    <h5 className="lotNo">
                                                        Lot#{' '}
                                                        {selectedProduct.sku
                                                            ? selectedProduct.sku
                                                            : selectedProduct.lot_number
                                                            ? selectedProduct.lot_number
                                                            : selectedProduct.deed_document
                                                            ? selectedProduct.deed_document
                                                            : selectedProduct.id}
                                                    </h5>
                                                    {parseInt(selectedProduct.buynow) == 0 &&
                                                    (parseInt(selectedProduct.lowest) > 0 ||
                                                        parseInt(selectedProduct.highest) > 0) ? (
                                                        <p>
                                                            <span>Est:</span>
                                                            {`${
                                                                ' ' +
                                                                currencyFormat(
                                                                    selectedProduct.lowest,
                                                                    currency,
                                                                ) +
                                                                ' - ' +
                                                                currencyFormat(
                                                                    selectedProduct.highest,
                                                                    currency,
                                                                )
                                                            }`}
                                                        </p>
                                                    ) : (
                                                        ''
                                                    )}
                                                </div>
                                                <div className="pvMiscActions">
                                                    {selectedProduct.market_status == 'open' ? (
                                                        <FavouriteCheckbox
                                                            watchlisted={selectedProduct.wlistpop}
                                                            data={selectedProduct}
                                                            project_id={selectedProduct.id}
                                                            from={from}
                                                            getAllProducts={getAllProducts}
                                                            setSelectedProduct={setSelectedProduct}
                                                        />
                                                    ) : null}
                                                    <Facebook
                                                        path={`/product/${selectedProduct.id}`}
                                                        title={selectedProduct.title}
                                                    />
                                                    <Whatsapp
                                                        path={`/product${selectedProduct.id}`}
                                                        title={selectedProduct.title}
                                                    />
                                                    {global.storeConfigration
                                                        ?.disable_twitter_sharing?.value != 1 ? (
                                                        <Twitter
                                                            path={`/product/${selectedProduct.id}`}
                                                            title={selectedProduct.title}
                                                        />
                                                    ) : null}
                                                    <Email
                                                        path={`/product/${selectedProduct.id}`}
                                                        subject={selectedProduct.title}
                                                        body={selectedProduct.description}
                                                    />
                                                    {global.storeConfigration
                                                        ?.disable_pinterest_sharing?.value != 1 ? (
                                                        <Pinterest
                                                            path={`/product/${selectedProduct.id}`}
                                                            title={selectedProduct.title}
                                                            media={
                                                                `${
                                                                    selectedProduct.content_head1 ===
                                                                    '0'
                                                                        ? process.env
                                                                              .REACT_APP_BASE_URL +
                                                                          'uploads/product/'
                                                                        : process.env
                                                                              .REACT_APP_PRODUCT_IMAGE_URL
                                                                }` + selectedProduct.avatar
                                                            }
                                                        />
                                                    ) : null}
                                                    <div
                                                        className="cpyToClipDiv"
                                                        style={{ cursor: 'pointer' }}
                                                    >
                                                        <Copy
                                                            setAlert={setAlert}
                                                            text={`/productView/${selectedProduct.id}`}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <Button
                                                className="d-none productPageAct"
                                                onClick={() =>
                                                    handleRedirectInternal(
                                                        history,
                                                        `productView/${selectedProduct.id}`,
                                                    )
                                                }
                                            >
                                                View Full Details
                                            </Button>
                                        </>
                                    )}
                                </div>
                                {/* {console.log(storeTheme, pvTabList, 'storeTheme')} */}
                            </div>
                            <Bidhistory
                                modaltitle="Bid history"
                                open={Object.keys(historyData).length !== 0}
                                value={historyData}
                                handleClose={() => setHistoryData({})}
                            />
                            {global.pluginConfiguration?.limit_pickup_days?.enable ? (
                                <Pickupfunction
                                    status={open_pickup}
                                    date_closed={new Date(selectedProduct.auction_closed)}
                                    loc_id={selectedProduct.loc_id}
                                    onClose={(e) => {
                                        setOpenpickup('close')
                                    }}
                                />
                            ) : (
                                ''
                            )}
                            {user &&
                                global.pluginConfiguration?.messages?.enable == 1 &&
                                selectedProduct?.user_id != user?.id && (
                                    <MessagePlugin product={selectedProduct} />
                                )}
                        </div>
                    ) : (
                        <div className="container pt-5">
                            <NoRecordsFound />
                        </div>
                    )}
                </>
            )}
            <HowBidPopup open={howBidPopup} handleClose={() => setHowBidPopup(false)} />
            <Popup
                open={show_popup}
                handleClose={() => setShowpopup(false)}
                className="save-search-modal"
                modaltitle="More Details"
            >
                <>
                    {/* <div className="row">
                        <div className="col-md-6">Condition</div>
                        <div className="col-md-6">Mint</div>
                    </div> */}
                    <div className="row">
                        <div className="col-md-6">Athlete Name</div>
                        <div className="col-md-6">
                            {selectedProduct.athelet_name ? selectedProduct.athelet_name : '-'}
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-6">Team</div>
                        <div className="col-md-6">
                            {selectedProduct.team_name ? selectedProduct.team_name : '-'}
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-6">Brand</div>
                        <div className="col-md-6">
                            {selectedProduct.brand_name ? selectedProduct.brand_name : '-'}
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-6">Autograph</div>
                        <div className="col-md-6">
                            {selectedProduct.autograph_name ? selectedProduct.autograph_name : '-'}
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-6">Year</div>
                        <div className="col-md-6">
                            {selectedProduct.year_name ? selectedProduct.year_name : '-'}
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-6">Certificate of Authentication</div>
                        <div className="col-md-6">
                            {selectedProduct.work_loc ? (
                                <a
                                    target={'_blank'}
                                    rel="noreferrer"
                                    href={
                                        process.env.REACT_APP_BASE_URL +
                                        'uploads/' +
                                        selectedProduct.work_loc
                                    }
                                >
                                    View
                                </a>
                            ) : (
                                '-'
                            )}
                        </div>
                    </div>
                </>
            </Popup>
        </>
    )

    return viewPage ? (
        render
    ) : (
        <Drawer
            className="rightDrawer productViewDrawer multisellerProductDrawer"
            anchor={'right'}
            open={selectedLot}
            onClose={handleClose}
        >
            {render}
        </Drawer>
    )
}

export default MultisellerSlider
