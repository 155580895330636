import React from 'react'
import './Login.css'

import { Logo } from '../../../utils'
import LoginFunction from '../../../utils/CommonFunctionality/LoginFunction'

const LoginComponent = () => {
    console.log(global?.storeDetails?.logoValue, 'logo image')
    return (
        <div className="clearfix row vcaLogin">
            <div className="col-lg-3 d-none d-lg-block pad-0">
                {/*<div className="loginImgBox d-flex justify-content-between flex-column"></div>*/}
            </div>
            <div className="col-lg-6 col-12">
                <div className="loginFormBox">
                    <Logo className="m-auto" />
                    <LoginFunction
                        title="Login"
                        subTitle="Welcome Back"
                        after_login_redirect_path="auctions"
                    />
                </div>
            </div>
        </div>
    )
}

export default LoginComponent
